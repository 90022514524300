<template>
  <v-container>
    <v-navigation-drawer
      v-model="showDialog"
      fixed
      temporary
      right
      style="min-width:500px; z-index:203;"
      color="popupBackground"
    >
      <v-card-title class="mb-5 pb-0">
        {{ title }}<v-spacer></v-spacer>
        <v-icon id="searchDialogCloseBtn" @click.stop="showDialog = false">
          mdi-close</v-icon
        ></v-card-title
      >
      <p class="label mb-2 mx-4">Game Name</p>
      <v-text-field
        id="gameSearchTxtInput"
        v-model="search"
        :search-input.sync="search"
        hide-details
        color="inherit"
        autofocus
        outlined
        dense
        class="my-0 mx-4 pb-5 rounded"
      >
      </v-text-field>
      <template v-for="(game, index) in items">
        <GameRow
          :key="index"
          :game-id="game.gameId"
          class="px-4"
          @close="showDialog = false"
        ></GameRow>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import searchDialog from "@/mixins/shared/searchDialog";
import GameRow from "@/components/GameRow.vue";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
export default {
  components: {
    GameRow
  },
  mixins: [searchDialog, displayNameValidator],
  computed: {
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/search"].data.title, this.$str('SearchLabel'));
    },
    items() {
      return this.searchGames();
    }
  }
};
</script>
<style scoped>
.v-list {
  padding: 0 !important;
}

.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
