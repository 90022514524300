const state = {
  reconnecting: false,
  downtimeNotificationMessageURL: ""
};

const getters = {
  reconnecting: state => state.reconnecting,
  downtimeNotificationMessageURL: state => state.downtimeNotificationMessageURL
};

const actions = {
  async initialize({ commit }) {
    CDK.on(CDK.Events.systemShoutedDown, url => {
      commit("updateReconnecting", true);
      commit("updateDowntimeNotificationMessageURL", url);
    });
    CDK.on(CDK.Events.connectionRestored, () => {
      commit("updateReconnecting", false);
      commit("updateDowntimeNotificationMessageURL", "");
    });
  }
};

const mutations = {
  updateReconnecting(state, isReconnecting) {
    state.reconnecting = isReconnecting;
  },
  updateDowntimeNotificationMessageURL(state, url) {
    state.downtimeNotificationMessageURL = url;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
