<template>
  <v-dialog
    v-if="showDialog && loggedIn"
    v-model="showDialog"
    class="elevation-12"
    content-class="freeSpinModal"
  >
    <v-container class="headerContainer">
      <span class="title">{{ freeSpinsNotificationMessage }}</span>
      <v-icon
        class="pa-0 close-btn"
        :dark="true"
        @click.stop="showDialog = false"
      >
        mdi-close</v-icon
      >
    </v-container>
    <v-container
      class="gameContainer"
      :class="isSquareIcon ? 'square' : 'tall'"
    >
      <v-row>
        <div
          :style="imageUrl"
          class="imageStyle"
          :class="isSquareIcon ? 'square' : 'tall'"
        >
          <p v-if="showGameDescription" class="freeSpinText freeSpinTextSmall">
            {{ getGameName }}
          </p>
        </div>
      </v-row>
      <v-row>
        <v-btn
          style="display: flex; padding: 0px 16px; justify-content: center; align-items: center; border-radius: 4px; background: var(--v-button-base); color: var(--v-buttonTxt-base);"
          @click.stop="launchGame(gameId)"
        >
          {{ $str("FreeSpinsNotificationPlay") }}
        </v-btn>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "freeSpinNotification",
      errorMessage: "",
      launchedGame: false
    };
  },
  computed: {
    ...mapGetters("coupons", ["activeCoupon", "freeSpinCouponInfo"]),
    ...mapGetters("customizations", [
      "freeSpinBackgroundUrls",
      "lobbyIconType"
    ]),
    ...mapGetters("casinoJackpot", ["casinoJackpotUser"]),
    ...mapGetters("session", ["playForMoney", "loggedIn"]),
    ...mapGetters("games", ["games"]),
    gameId() {
      return (
        this.freeSpinCouponInfo?.Gameid.toString() +
        "-" +
        this.freeSpinCouponInfo?.Machid.toString() +
        "-0"
      );
    },
    selectedGame() {
      return this.games[this.gameId];
    },
    imageUrl() {
      const imageUrl = this.selectedGame.imageUrls[this.lobbyIconType];

      return {
        background: imageUrl
          ? `url(${imageUrl}) center / cover no-repeat`
          : "rgba(0, 0, 0, 0.87)"
      };
    },
    showGameDescription() {
      return this.selectedGame.imageUrls[this.lobbyIconType] ? false : true;
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    },
    getGameName() {
      return this.selectedGame.imageUrls[this.lobbyIconType]
        ? null
        : this.freeSpinCouponInfo.GameDescription;
    },
    FreeSpinsNotificationMessage() {
      if (this.freeSpinCouponInfo) {
        return `<p class='freeSpinText freeSpinTextSmall'>
              ${this.$str("FreeSpinsNotificationMessagePart1")}
            </p>
            <p class='freeSpinText freeSpinTextLarge'>
              ${this.$str("FreeSpinsNotificationMessagePart2").replace(
                "<NUM_SPINS>",
                this.freeSpinCouponInfo.SpinsRemaining
              )}
            </p>`;
      } else return null;
    },
    freeSpinsNotificationMessage() {
      if (this.freeSpinCouponInfo) {
        if (this.imageUrl) {
          return `${this.$str("FreeSpinsNotificationMessagePart1")} 
                  ${this.$str("FreeSpinsNotificationMessagePart2").replace(
                    "<NUM_SPINS>",
                    this.freeSpinCouponInfo.SpinsRemaining
                  )}`;
        } else {
          return `${this.$str("FreeSpinsNotificationMessage")
            .replace("<SPINS>", this.freeSpinCouponInfo.SpinsRemaining)
            .replace("<GAMENAME>", this.freeSpinCouponInfo.GameDescription)}`;
        }
      } else return null;
    },
    freeSpinBackgroundUrl() {
      return this.$vuetify.theme.dark
        ? this.freeSpinBackgroundUrls?.dark
        : this.freeSpinBackgroundUrls?.light;
    },
    hasFreeSpin() {
      return (
        (!this.casinoJackpotUser.UnclaimedHitsAmount > 0 &&
          this.activeCoupon &&
          this.activeCoupon.IsFreeSpin &&
          this.freeSpinCouponInfo &&
          this.freeSpinCouponInfo.SpinsRemaining > 0 &&
          this.freeSpinCouponInfo.DisplayNotification &&
          this.playForMoney &&
          !this.activeCoupon.ShowMultipleGames) ??
        false
      );
    }
  },
  watch: {
    hasFreeSpin(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.showFreeSpinNotification();
      } else if (oldVal === true && newVal === false) {
        this.showDialog = false;
      }
    }
  },
  methods: {
    ...mapActions("coupons", ["freeSpinNotificationClosed"]),
    ...mapActions("dialogs", ["showFreeSpinNotification"]),
    async launchGame(gameId) {
      this.$store.dispatch("games/launchGame", { gameId: gameId });
      this.launchedGame = true;
      this.showDialog = false;
    },
    onHidden() {
      this.freeSpinNotificationClosed(this.launchedGame);
    },
    onShown() {
      this.launchedGame = false;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .v-dialog.v-dialog--active.freeSpinModal {
  display: flex;
  width: 379px;
  padding: 20px 15px 50px 15px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: url(../../assets/couponfreespinbackground.png) center / cover
      no-repeat,
    linear-gradient(
      225deg,
      var(--v-body-base) 0%,
      var(--v-gradientColor-base) 100%
    );
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

::v-deep .headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

::v-deep .title {
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: var(--v-bodyTitle-base);
}

::v-deep .freeSpinTextSmall {
  font-size: 20px;
  -webkit-text-stroke-width: 0.1vw;
  color: var(--v-buttonTxt-base);
  position: absolute;
  text-align: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-transform: uppercase;
  line-height: 1;
  padding: 0px 10px;
}
::v-deep .freeSpinTextLarge {
  font-size: 4.5vw;
  margin-bottom: 0 !important;
  line-height: 1.3;
  -webkit-text-stroke-width: 0.14vw;
  word-break: break-all;
  text-align: center;
}

::v-deep .close-btn {
  color: var(--v-popupTxt-base);
}
::v-deep .imageBackground {
  opacity: 0.46;
  background-color: rgb(33, 33, 33);
  border-color: rgb(33, 33, 33);
}

::v-deep .btnPlay {
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--v-button-base);
  color: var(--v-buttonTxt-base);
}

::v-deep .imageStyle {
  width: 150px;
  height: 200px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  position: relative;

  &.square {
    width: 200px;
    height: 150px;
  }
}

::v-deep .gameContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  padding: 15px;
  gap: 20px;
  border-radius: 4px;
  background: var(--black-10, rgba(0, 0, 0, 0.1));
  transition: 0.5s;
  border: 1px solid;
  border-image: linear-gradient(
      var(--angle),
      #000,
      var(--v-buttonSecondary-base)
    )
    1;
  animation: 5s rotate linear infinite;

  &.square {
    width: 70%;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
</style>
