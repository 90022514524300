<template>
  <v-container class="ma-0 pa-0">
    <TournamentCreditsProgress
      v-if="
        isCreditsMode &&
          details.PlayerDetails.IsPlayerRegistered &&
          !creditsRequirementReached
      "
      :reached="details.PlayerDetails.ReachedAmount"
      :required="details.PlayerDetails.RequirementAmount"
    ></TournamentCreditsProgress>
    <v-simple-table
      v-if="details.PlayerDetails.IsPlayerRegistered"
      class="tournamentDetail tournamentDetailTxt--text mb-2 text-center"
      ><thead class="buttonSecondary buttonSecondaryTxt--text">
        <th class="text-center pr-0 px-2" colspan="2">
          {{ $str("TournamentPosition") }}
        </th>
        <th class="text-center">{{ $str("TournamentAlias") }}</th>
        <th class="text-center" colspan="2">
          {{ $str("TournamentScore") }}
        </th>
      </thead>
      <tbody>
        <tr
          v-for="player in leaderBoard"
          :key="player.Player"
          :class="player.PlayerId === details.Pid ? 'active' : ''"
        >
          <td class="px-1 text-left">
            <v-icon
              v-if="player.PlayerId === details.Pid"
              color="tournamentTabInactive"
              medium
              >mdi-navigation mdi-rotate-90</v-icon
            >
            <div v-else style="width:15px;"></div>
          </td>
          <td class="pa-0 text-left">
            {{ player.Rank }}
          </td>
          <td class="font-weight-bold text-break">{{ player.Player }}</td>
          <td class="px-1 text-right">
            <div>
              <v-icon
                v-if="player.Award > 0"
                color="tournamentTabInactive"
                class="pr-0"
                >mdi-gift</v-icon
              >
            </div>
          </td>
          <td class="pl-0 pr-8 text-right">
            <div>
              {{ player.Score }}
            </div>
          </td>
        </tr>
      </tbody></v-simple-table
    >
    <v-chip
      label
      color="tournamentTabInactive tournamentTabTxt--text"
      class="mt-1"
    >
      {{ $str("YourPosition") }}
      <v-avatar
        right
        tile
        style="border-radius: 1vw"
        class="title tournamentDetail tournamentDetailTxt--text px-8 text-right"
        >{{
          details.PlayerDetails.IsPlayerRegistered
            ? details.PlayerDetails.Position
            : "-"
        }}</v-avatar
      ></v-chip
    >
    <v-card color="tournament" style="border-radius: 1vw"
      ><v-card-text class="tournamentTxt--text"
        ><span class="subtitle-2">{{ $str("TournamentAlias") }}:</span
        ><span class="float-right">{{ playerAlias }}</span
        ><v-divider class="my-2"></v-divider
        ><span class="subtitle-2">{{ $str("TournamentCurrentScore") }}:</span
        ><v-chip
          color="tournamentDetail tournamentDetailTxt--text"
          class="float-right"
          small
          label
          >{{
            details.PlayerDetails.IsPlayerRegistered
              ? details.PlayerDetails.CurrentScore
              : "---"
          }}</v-chip
        ><v-spacer class="my-2"></v-spacer
        ><span v-show="details.PlayerDetails.RebuyClaimed" class="subtitle-2"
          >{{ $str("TournamentHighestScore") }}:</span
        ><v-chip
          v-show="details.PlayerDetails.RebuyClaimed"
          color="tournamentDetail tournamentDetailTxt--text"
          class="float-right"
          small
          label
          >{{
            details.PlayerDetails.IsPlayerRegistered
              ? details.PlayerDetails.MaxScore
              : "---"
          }}</v-chip
        ></v-card-text
      ></v-card
    >
  </v-container></template
>

<script>
/**
 * Leaderboard entry example:
 * {
 *   "Award": -1,
 *   "AwardSpecified": true,
 *   "Player": "Gamer9Alias",
 *   "PlayerId": "11522712",
 *   "Rank": 1,
 *   "RankSpecified": true,
 *   "RankAward": -1,
 *   "RankAwardSpecified": true,
 *   "Score": 0,
 *   "ScoreSpecified": true
 * }
 *
 */
import { mapGetters } from "vuex";
import TournamentCreditsProgress from "@/components/TournamentCreditsProgress.vue";
import { TournamentMode } from "@/services/casino";

export default {
  components: {
    TournamentCreditsProgress
  },
  props: {
    details: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("tournaments", ["playerAlias"]),
    leaderBoard() {
      return this.details.LeaderBoard.slice(0, 10);
    },
    isCreditsMode() {
      return this.details.Details.Mode === TournamentMode.Credits;
    },
    creditsRequirementReached() {
      return (
        this.details.PlayerDetails.ReachedAmount >=
        this.details.PlayerDetails.RequirementAmount
      );
    }
  }
};
</script>

<style scoped>
@media (min-width: 960px) {
  .container {
    max-width: 900px !important;
  }
}

.theme--dark.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid var(--v-buttonSecondary-base);
}

.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content),
.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  background: var(--v-tournamentInfo-base);
  color: var(--v-tournamentInfoTxt-base);
}
.active {
  background: var(--v-tournamentInfo-base) !important;
  color: var(--v-tournamentInfoTxt-base) !important;
}
::v-deep.v-data-table table {
  width: 101% !important;
}
</style>
