import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import store from "@/store";
import {
  getActionFromUrlCommand,
  objectKeysToLowerCase,
  restoreSessionCompleted
} from "../services/casino";
import {
  handleRequestParams,
  clearRequestParams
} from "../services/casino/requestParams";
import { LaunchLobbyAction, clientType } from "@/services/casino";

Vue.use(VueRouter);

/** To lazy load a route, define a function that dynamically imports the route
 * when needed, instead of importing it directly.
 *
 * Once we add any client type-specific elements, like instant play-only, etc.,
 * we can take advantage of lazy loading to make sure the payload is a small
 * as possible for each scenario - but especially for mobile.
 *
 * note: adding the webpackPrefetch: true comment will cause the lazy loaded
 * module to be prefetched as soon as the page is loaded
 *
 * see: https://router.vuejs.org/guide/advanced/lazy-loading.html
 */

// Lazy load the Games view
const Games = () => import(/* webpackPrefetch: true */ "@/views/Games");
// const ExternalLobbies = () =>
//   import(/* webpackPrefetch: true */ "@/views/ExternalLobbies");

const WCOLiveDealer = () => import("@/components/WCOLiveDealer");

// Lazy load the Tournaments view
const Tournaments = () =>
  import(/* webpackPrefetch: true */ "@/views/Tournaments");

const routes = [
  {
    path: "/",
    name: "customhome",
    component: Home,
    props: true
  },
  {
    path: "/",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/",
    name: "livedealer",
    component: WCOLiveDealer,
    props: true
  },
  {
    path: "/games/:menuId/:subMenuId?",
    name: "games",
    component: Games,
    props: true,

    /** Make sure we have the menu data before entering Games view, since
     *  we could be navigating here directly without loading Home first.
     *  (ex: www.casino.com/games/3)
     */

    beforeEnter: async (to, from, next) => {
      to.params.menuId = parseInt(to.params.menuId, 10);

      if (to.params.subMenuId) {
        to.params.subMenuId = parseInt(to.params.subMenuId, 10);
      } else {
        to.params.subMenuId = null;
      }

      await store.dispatch("externalLobbies/initialize");
      await store.dispatch("games/loadMenuData");

      // cancel navigation and go home if the menu doesn't exist
      const menu = store.getters["games/menus"][to.params.menuId];
      if (!menu) {
        next("/");
        return;
      }

      // ... or if the menu is empty (like recent games when you aren't logged in)
      const gamesList = store.getters["games/menuGamesLists"][to.params.menuId];
      const hasGames = gamesList && gamesList.length > 0;

      // HACK - Allow navigation to Live Dealer EVO menu if we are logged in,
      // even if it appears to have no games
      if (menu.name === "Live Dealer EVO") {
        if (!CDK.isLoggedIn()) {
          // If we aren't logged in, wait for session to be restored
          await restoreSessionCompleted;

          // If we still aren't logged in, redirect to home view
          if (!CDK.isLoggedIn()) {
            next("/");
            window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
              onSuccess: () => router.push({ name: "evo" })
            });
            return;
          }
        }
      } else if (!hasGames) {
        next("/");
        return;
      }

      // update page title based on menu
      to.meta.title = menu.displayName;

      next();
    }
  },
  {
    path: "/evo",
    name: "evo",
    component: Games,
    props: true,

    /** Make sure we have the menu data before entering Games view, since
     *  we could be navigating here directly without loading Home first.
     *  (ex: www.casino.com/games/3)
     */

    beforeEnter: async (to, from, next) => {
      await store.dispatch("externalLobbies/initialize");
      await store.dispatch("games/loadMenuData");

      const menus = store.getters["games/menus"];
      const menusArray = Object.values(menus);
      const evoMenu = menusArray?.find(menu => menu.name === "Live Dealer EVO");

      // cancel navigation and go home if the EVO menu doesn't exist
      if (!evoMenu) {
        next("/");
        return;
      }

      to.params.menuId = evoMenu.id;
      to.params.subMenuId = null;

      // Must be logged in to see EVO games
      if (!CDK.isLoggedIn()) {
        // If we aren't logged in, wait for session to be restored
        await restoreSessionCompleted;

        // If we still aren't logged in, redirect to home view
        if (!CDK.isLoggedIn()) {
          next("/home");
          window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
            onSuccess: () => router.push({ name: "evo" })
          });
          return;
        }
      }

      // update page title based on menu
      to.meta.title = evoMenu.displayName;

      next();
    }
  },
  {
    path: "/tournaments",
    name: "tournaments",
    component: Tournaments,

    /**
     * Tournaments view requires the player to be logged in and
     * the component enabled, otherwise redirect to Home view
     */
    beforeEnter: async (to, from, next) => {
      const returnHome = () => {
        const isTournamentsOnly =
          store.getters["customizations/isTournamentsOnly"];
        const exitUrl = store.getters["customizations/exitUrl"];

        isTournamentsOnly ? (window.location.href = exitUrl) : next("/");
      };

      if (!CDK.isLoggedIn()) {
        // If we aren't logged in, wait for session to be restored
        await restoreSessionCompleted;

        // If we still aren't logged in, redirect to home view
        if (!CDK.isLoggedIn()) {
          returnHome();
          return;
        }
      }

      const tournamentsActive = store.getters["tournaments/isActive"];

      if (tournamentsActive) {
        // Continue navigating to Tournaments view
        if (clientType === CDK.ClientTypes.Mobile) {
          next();
        } else {
          store.dispatch("dialogs/showTournamentsLobby");
        }
        return;
      }

      //tournaments not active, return home
      returnHome();
    }
  },
  /** Default path - if we get an unknown path, redirect to Home */
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",

  // Production builds expect to be served under /lobby
  base: process.env.NODE_ENV === "production" ? "/lobby" : process.env.BASE_URL,
  routes
});

const handleUrlCommand = command => {
  let queryString = new URLSearchParams(window.location.search);
  const [commandString, query] = command.split("&");
  if (query?.length > 0) {
    queryString = new URLSearchParams("?" + query);
  }
  const action = getActionFromUrlCommand(commandString, queryString);
  if (
    commandString !== CDK.LobbyURLCommands.loginUser &&
    commandString !== CDK.LobbyURLCommands.signUpForm
  )
    store.dispatch("messageCenter/setSupressForceOnEntrance", {
      supressForceOnEntrance: true
    });

  LaunchLobbyAction(store, action.type, action.params);
};

router.beforeEach(async (to, from, next) => {
  const isTournamentsOnly = store.getters["customizations/isTournamentsOnly"];

  if (
    to.name === "home" ||
    to.name === "customhome" ||
    (isTournamentsOnly && to.name === "tournaments")
  ) {
    await handleRequestParams(to.query);
  }
  window.scrollTo(0, 0);

  const affid = objectKeysToLowerCase(to.query).affid;
  if (affid) {
    CDK.setAffQueryStringFS(affid);
  }

  const urlCommand = CDK.checkForURLCommand();
  if (urlCommand) {
    CDK.clearLastURLCommand();
    store.dispatch("dialogs/updateURLHash", urlCommand);

    // Wait until the next frame so the router has finished navigating
    setTimeout(() => handleUrlCommand(urlCommand), 0);
    let newQuery = {};

    // Include all parameter values except skinId.
    if (Object.keys(to.query).length > 0) {
      newQuery = Object.keys(to.query)
        .filter(key => key.toLowerCase() !== "skinid")
        .filter(key => key.toLowerCase() !== "token")
        .filter(key => key.toLowerCase() !== "login")
        .reduce((obj, key) => {
          obj[key] = to.query[key];
          return obj;
        }, {});
    }

    // Scrub the command from the URL now that we've handled it
    // But keep the skinId if we have one, or the previous path had one
    const skinId =
      objectKeysToLowerCase(to.query).skinid ||
      objectKeysToLowerCase(from.query).skinid;

    newQuery = skinId ? { ...{ skinId }, ...newQuery } : newQuery;

    next({ path: to.path, query: newQuery, hash: "" });
  } else if (objectKeysToLowerCase(from.query).skinid && !to.query.skinId) {
    // Persist "skinId" param when navigating within the site
    const toWithSkinId = {
      ...to,
      ...{ query: { skinId: objectKeysToLowerCase(from.query).skinid } }
    };

    next(toWithSkinId);
  } else {
    next();
  }
  // Remove params from URL
  clearRequestParams();

  // Refresh favorites games
  store.dispatch("games/refreshFavoriteGames");
});

export default router;
