<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 48 48"
  >
    <g class="cls-4">
      <g class="cls-4">
        <g class="cls-4">
          <g class="cls-4">
            <g class="cls-4">
              <g class="cls-4">
                <g class="cls-4">
                  <g class="cls-4">
                    <text class="cls-3" transform="translate(960 540)">
                      <tspan x="0" y="0">Restart</tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g class="cls-4">
        <g class="cls-4">
          <g class="cls-4">
            <g class="cls-4">
              <g class="cls-4">
                <g class="cls-4">
                  <g class="cls-4">
                    <text class="cls-3" transform="translate(960 554.4)">
                      <tspan x="0" y="0">
                        Idol mientras jugadores hacen apuestas
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g class="cls-4">
      <g class="cls-4">
        <g class="cls-4">
          <g class="cls-4">
            <g class="cls-4">
              <g class="cls-4">
                <g class="cls-4">
                  <g class="cls-4">
                    <text class="cls-3" transform="translate(960 540)">
                      <tspan x="0" y="0">Restart</tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g class="cls-4">
        <g class="cls-4">
          <g class="cls-4">
            <g class="cls-4">
              <g class="cls-4">
                <g class="cls-4">
                  <g class="cls-4">
                    <text class="cls-3" transform="translate(960 554.4)">
                      <tspan x="0" y="0">
                        Idol mientras jugadores hacen apuestas
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
    <line class="cls-2" x1="14.5" y1="46.8" x2="14.5" y2="45.1" />
    <line class="cls-2" x1="10.7" y1="46.8" x2="10.7" y2="44.4" />
    <line class="cls-2" x1="18.5" y1="46.8" x2="18.5" y2="44.4" />
    <ellipse class="cls-1" cx="77.1" cy="3.7" rx="12" ry="12.4" />
    <path
      class="cls-2"
      d="M27.3,37.7l-.9,6.7-5.6-4.4s-1.2,2-7.1,2-6.1-2-6.1-2l-5.6,4.4-.9-6.7,6.1-8.6s0-8.6.6-15C8.2,7.9,13.9.7,13.9.7c0,0,5.6,7.1,6.4,13.5.6,6.4.6,15,.6,15l6.1,8.6h.3Z"
    />
    <g>
      <path
        class="cls-2"
        d="M36.2,20.9s-.3-2-2.3-2.3h0c-1.6-.3-3.3.2-4.2,1.3h0c-1.3,1.4-.6,3.3,1.7,3.7l3,.4c2.1.3,2.9,2,1.8,3.5h0c-.9,1.3-2.8,1.9-4.3,1.7h-.4c-1.4-.3-2.3-1.3-2.1-2.6v-.4"
      />
      <line class="cls-2" x1="32.8" y1="16.6" x2="32.8" y2="31.5" />
    </g>
    <path
      class="cls-2"
      d="M25.8,36.1c2.1,1.2,4.5,1.9,7.1,1.9,7.8,0,14.1-6.3,14.1-14.1s-6.3-14.1-14.1-14.1-9.9,2.9-12.4,7.3"
    />
  </svg>
</template>

<script>
export default {
  name: "CrashGames",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Crash Games" }
  }
};
</script>
<style scoped type="text/css">
.cls-1 {
  display: none;
}

.cls-1,
.cls-2 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.cls-3 {
  fill: none;
  font-family: ArialMT, Arial;
  font-size: 12px;
}

.cls-3,
.cls-4 {
  isolation: isolate;
}
</style>
