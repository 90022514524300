<template>
  <v-snackbar
    v-model="showDialog"
    transition="slide-y-transition"
    top
    :light="!this.$vuetify.theme.dark"
    elevation="24"
    :timeout="slideInData.timeoutMs"
    style="padding: 0;"
    content-class="px-3 py-2"
    :color="slideInData.backgroundColor"
  >
    <!-- We need to inject the background style for the snackbar wrapper class, because we cannot style the wrapper directly -->
    <component :is="'style'" v-if="slideInData.backgroundImage">
      .v-snack__wrapper { background-image: url("{{
        slideInData.backgroundImage
      }}"); background-repeat: repeat; }
    </component>
    <!-- Desktop Layout
      ----------------------------
      CASINO  | Title            X
      LOGO    | ------------------
              | Text
      ----------------------------
    -->
    <v-container v-if="largeLayout" class="pa-0" style="max-width: 480px;">
      <v-row>
        <v-col v-if="showLogo" cols="3" class="py-0 pr-2" align-self="center">
          <img :src="logo" style="width: 100%;" />
        </v-col>
        <v-col cols="9" class="py-0">
          <div class="font-weight-bold mb-2" :style="titleStyle">
            {{ slideInData.title }}
          </div>
          <div class="text-caption" :style="textStyle">
            {{ slideInData.text }}
          </div>
          <v-icon
            class="pa-0"
            style="position: absolute; right: 6px; top: 8px;"
            @click.stop="showDialog = false"
          >
            {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
          >
        </v-col>
      </v-row>
    </v-container>

    <!-- Mobile Layout
      ----------------------------
      FAVICON | Title            X
      ----------------------------
      Text
      ----------------------------
    -->
    <v-container v-if="smallLayout" class="pa-0">
      <v-row class="">
        <v-col v-if="showFavicon" cols="1" class="py-0" align-self="center">
          <img :src="favicon" style="max-width: 20px;" />
        </v-col>
        <v-col cols="11" class="py-0">
          <div class="font-weight-bold" :style="titleStyle">
            {{ slideInData.title }}
          </div>
          <v-icon
            class="pa-0"
            style="position: absolute; right: 6px; top: 8px;"
            @click.stop="showDialog = false"
          >
            {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
          >
        </v-col>
      </v-row>
      <v-row class="mt-1 pr-1">
        <v-col class="py-0">
          <div class="text-caption" :style="textStyle">
            {{ slideInData.text }}
          </div></v-col
        >
      </v-row>
    </v-container>
  </v-snackbar>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "slideInDialog",
      scrollFix: false
    };
  },
  computed: {
    ...mapGetters("customizations", [
      "favicon",
      "casinoLogo",
      "casinoLogoLight"
    ]),
    /**
     * @typedef {object} SlideInData
     * @property {string} title Title text
     * @property {?string} titleColor (Optional) Text color for the title
     * @property {string} text Main text
     * @property {?string} textColor (Optional) Text color for the main text
     * @property {?string} backgroundImage (Optional) Background image
     * @property {?string} backgroundColor (Optional) Background color
     * @property {number} timeoutMs Timeout in milliseconds
     * @property {boolean} showCasinoLogo Whether to show the casino logo (Instant Play) / favicon (Mobile) in the slide-in dialog
     */
    /**
     * @returns {SlideInData}
     */
    slideInData() {
      return this.$store.getters["dialogs/slideInDialog"].data;
    },
    logo() {
      return this.$vuetify.theme.dark ? this.casinoLogo : this.casinoLogoLight;
    },
    showLogo() {
      // Show logo if it exists and if the slide-in dialog is configured to show it
      return this.slideInData.showCasinoLogo && this.logo;
    },
    showFavicon() {
      // Show favicon if it exists and if the slide-in dialog is configured to show it
      return this.slideInData.showCasinoLogo && this.favicon;
    },
    titleStyle() {
      const style = {};
      if (this.slideInData.titleColor) {
        style.color = this.slideInData.titleColor;
      }
      return style;
    },
    textStyle() {
      const style = {};
      if (this.slideInData.textColor) {
        style.color = this.slideInData.textColor;
      }
      return style;
    }
  },
  methods: {
    onShown() {
      window.addEventListener("beforeunload", this.beforeWindowUnload);
    },
    onHidden() {
      window.removeEventListener("beforeunload", this.beforeWindowUnload);

      if (this.slideInData.onCloseCallback instanceof Function) {
        this.slideInData.onCloseCallback();
      }
    },
    beforeWindowUnload() {
      this.onHidden();
    },
    beforeRouteLeave(to, from, next) {
      this.onHidden();
      next();
    }
  }
};
</script>
