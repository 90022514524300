<template>
  <v-card light flat>
    <v-card-subtitle
      class="pb-0 font-weight-black text-uppercase text-center black--text"
      >{{ $str("RequiredToQualify") }}</v-card-subtitle
    >
    <v-card-text justify="center">
      <v-row justify="center"
        ><v-col cols="10"
          ><v-progress-linear
            v-model="progressPercent"
            rounded
            dark
            color="green"
            height="25"
          >
            <strong>{{ reached }}/{{ required }}</strong></v-progress-linear
          ></v-col
        >
        <v-icon left>mdi-seal-variant</v-icon></v-row
      >
      <p class="text-center black--text font-weight-bold">
        {{
          $str("CreditsWagered")
            .replace("{0}", reached)
            .replace("{1}", required)
        }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    reached: { type: Number, default: 0 },
    required: { type: Number, default: 0 }
  },
  computed: {
    progressPercent() {
      return (this.reached / this.required) * 100;
    }
  }
};
</script>
<style scoped>
.v-progress-linear--rounded {
  border-radius: 1rem;
}
::v-deep .v-progress-linear__background {
  background-color: grey !important;
}
</style>
