<template>
  <v-tooltip
    v-if="!casinoJackpotUser.IsOptIn && loggedIn && playForMoney"
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-icon
        class="ml-2"
        :class="showblock ? 'info-btn-dialog' : ''"
        color="casinoJackpotInfoButton"
        v-on="on"
      >
        $Info
      </v-icon>
    </template>
    <!-- eslint-disable vue/no-v-html -->
    <span v-html="infoMessage"></span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    showblock: { type: Boolean, required: false }
  },
  computed: {
    ...mapGetters("session", ["loggedIn", "playForMoney"]),
    ...mapGetters("casinoJackpot", [
      "casinoJackpotUser",
      "casinoJackpot",
      "costToPlayer"
    ]),

    infoMessage() {
      const currencyCode = CDK.getCurrencyCode();
      return `<br /><span>${this.$str(
        "LobbyJackpot"
      ).toUpperCase()} </span><br /><br />
        <span>${this.$str("CasinoJackpotInfoMessage1")} </span><br /><br />
        <span>${this.$str("CasinoJackpotInfoMessage2")} </span><br /><br />
        <span>${this.$str("CasinoJackpotInfoMessage3")} </span><br /><br />`
        .replace("<Lobby Jackpot Name>", this.$str("LobbyJackpot"))
        .replace("<amount/currency>", `${this.costToPlayer} ${currencyCode}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-tooltip__content {
  color: var(--v-casinoJackpotInfoText-base);
  background-color: var(--v-casinoJackpotInfoBackground-base);
  line-height: 18px;
  max-width: 500px;
}

.info-btn {
  position: absolute;
  inset: auto auto calc(100% - 22px) calc(100%);
}

.info-btn-dialog {
  position: relative;
  top: 10px;
  margin-top: -15px;
}

.v-icon {
  background: var(--v-casinoJackpotInfoButtonBackground-base);
  height: 24px;
  min-width: 24px;
  line-height: 14px;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 281px) {
  .info-btn-dialog {
    right: -48% !important;
  }
}
@media only screen and (max-width: 419px) {
  .info-btn-dialog {
    right: -43%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 599px) {
  .info-btn-dialog {
    right: -43%;
  }
}

@media screen and (min-width: 600px) and (max-width: 801px) {
  .info-btn-dialog {
    right: -50%;
  }
}

@media only screen and (min-width: 802px) {
  .info-btn-dialog {
    right: -52%;
    top: 17px;
  }
}
</style>
