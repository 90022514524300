export const GameLaunchMode = {
  ThisWindow: 0,
  NewWindow: 1,
  Embedded: 2
};

export const GamesViewMode = {
  List: 0,
  Grid: 1
};
