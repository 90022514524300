var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"elevation-12 ",attrs:{"content-class":"freespin","width":_vm.largeLayout ? '80%' : '100%'},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-img',{staticClass:"white--text align-center",attrs:{"src":_vm.freeSpinBackgroundUrl || require('@/assets/freespinbackground.png'),"width":"100%","max-height":_vm.largeLayout ? '600' : '680'}},[_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"12px","z-index":"1"}},[_c('v-icon',{staticClass:"pa-0",attrs:{"dark":true},on:{"click":function($event){$event.stopPropagation();_vm.showDialog = false}}},[_vm._v(" mdi-close")])],1),_c('div',{staticStyle:{"text-align":"center","position":"relative"}},[_c('div',{staticStyle:{"padding":"2vw"}},[_c('v-container',{staticStyle:{"width":"fit-content","margin-right":"5%"}},[_c('v-row',[_c('v-col',[_c('div',[_c('span',{staticClass:"freeSpinText freeSpinTextSmall"},[_vm._v(" "+_vm._s(this.$str("FreeSpinsCompletedTitle"))+" ")]),_c('br'),_c('span',{staticClass:"freeSpinText freeSpinTextSmall"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.freeSpinWinningsMessage)}})])])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"left","font-size":"x-small","max-width":"425px","margin-left":"auto","margin-right":"auto","text-shadow":"2px 2px 5px black"}},[(
                  _vm.completedFreeSpinCouponInfo &&
                    _vm.completedFreeSpinCouponInfo.FreeSpinWinnings > 0
                )?_c('div',[(
                    _vm.completedFreeSpinCouponInfo &&
                      _vm.completedFreeSpinCouponInfo.FreeSpinBonusAdjustment > 0
                  )?_c('v-card-subtitle',{style:(_vm.largeLayout
                      ? 'padding-top: 16px !important;'
                      : 'padding-top: 10px !important;'),domProps:{"innerHTML":_vm._s(
                    _vm.$str('FreeSpinsCompletedAlongYourWinnings').replace(
                      '{0}',
                      _vm.completedFreeSpinCouponInfo.FreeSpinBonusAdjustmentFormatted
                    )
                  )}}):_vm._e(),(
                    _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit > 0 ||
                      _vm.completedFreeSpinCouponInfo.PlaythroughTotalAmount > 0
                  )?_c('v-card-subtitle',{staticStyle:{"padding-top":"0"},domProps:{"innerHTML":_vm._s(_vm.$str('FreeSpinsCompletedGetFreeSpinWinnings'))}}):_vm._e(),_c('ul',[(
                      _vm.completedFreeSpinCouponInfo &&
                        _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                          0
                    )?_c('li',{domProps:{"innerHTML":_vm._s(
                      _vm.$str('FreeSpinsCompletedMakeMinimumDeposit').replace(
                        '{0}',
                        _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                      )
                    )}}):_vm._e(),(
                      _vm.completedFreeSpinCouponInfo &&
                        _vm.completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment >
                          0
                    )?_c('li',{domProps:{"innerHTML":_vm._s(_vm.playthroughAdjustText)}}):_vm._e()])],1):_c('div',[(
                    _vm.completedFreeSpinCouponInfo &&
                      _vm.completedFreeSpinCouponInfo.FreeSpinBonusAdjustment > 0
                  )?_c('div',[_c('v-card-subtitle',{staticStyle:{"padding-top":"16px"},domProps:{"innerHTML":_vm._s(_vm.$str('FreeSpinsCompletedGetBonus'))}}),(
                      _vm.completedFreeSpinCouponInfo &&
                        _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                          0
                    )?_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(
                        _vm.$str('FreeSpinsCompletedMakeMinimumDeposit').replace(
                          '{0}',
                          _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                        )
                      )}}),_c('li',{domProps:{"innerHTML":_vm._s(
                        _vm.$str('FreeSpinsCompletedWagerX').replace(
                          '{0}',
                          _vm.completedFreeSpinCouponInfo.FreeSpinBonusAdjustmentFormatted
                        )
                      )}})]):_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(
                        _vm.$str('FreeSpinsCompletedWager').replace(
                          '{0}',
                          _vm.completedFreeSpinCouponInfo.PlaythroughTotalAmountFormated
                        )
                      )}})])],1):_c('div',[_c('v-card-title',{domProps:{"innerHTML":_vm._s(_vm.$str('FreeSpinsCompletedHeaderNoWinnings'))}}),(
                      _vm.completedFreeSpinCouponInfo &&
                        _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                          0
                    )?_c('div',[_c('v-card-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$str('FreeSpinsCompletedContinuePlaying'))}}),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(
                          _vm.$str(
                            'FreeSpinsCompletedMakeMinimumDeposit'
                          ).replace(
                            '{0}',
                            _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                          )
                        )}}),_c('li',{domProps:{"innerHTML":_vm._s(
                          _vm.$str(
                            'FreeSpinsCompletedWagerFreeSpinWinnings'
                          ).replace(
                            '{0}',
                            _vm.completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment
                          )
                        )}})])],1):_vm._e()],1)]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.$str("FreeSpinsCompletedThankYou")))]),(
                  _vm.completedFreeSpinCouponInfo &&
                    _vm.completedFreeSpinCouponInfo.ConversionLimit > 0
                )?_c('v-card-text',{domProps:{"innerHTML":_vm._s(
                  _vm.$str('FreeSpinsCompletedTransferLimit').replace(
                    '{0}',
                    _vm.completedFreeSpinCouponInfo.ConversionLimitFormatted
                  )
                )}}):_vm._e(),(
                  _vm.completedFreeSpinCouponInfo &&
                    _vm.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit > 0
                )?_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.$str('FreeSpinsCompletedClickingDiscard'))}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"ma-0 pa-0 mt-4",staticStyle:{"width":"18%","margin-bottom":"3vh","min-width":"120px"},attrs:{"color":"buttonSecondary buttonSecondaryTxt--text"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeposit($event)}}},[_vm._v(" "+_vm._s(_vm.$str("CouponsDeposit"))+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"ma-0 pa-0 mt-4",staticStyle:{"width":"10%","margin-bottom":"3vh","min-width":"80px","border-radius":"0.3vw","padding-top":"3px !important"},attrs:{"x-small":"","color":"buttonFreeSpins buttonFreeSpinsTxt--text"},on:{"click":function($event){$event.stopPropagation();return _vm.discardCoupon($event)}}},[_vm._v(" "+_vm._s(_vm.$str("FreeSpinsDiscard"))+" ")])],1)],1)],1)],1)]),_c('ConfirmDialog',{ref:"confirm"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }