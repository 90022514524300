/**
 * String translations
 *
 * @see CDK.ServerLogic/Resources/LobbyMessages.resx
 */

import {
  checkForMissingTranslations,
  setLanguage,
  cycleLanguage
} from "@/services/casino";

const state = {
  strings: {
    acceptPromotions:
      "I would like to receive information about bonuses, news and promotions",
    AccountHistoryHeader: "Account History",
    AccountVerified: "Account Verified",
    AccruedCompPoints: "Accrued Comp Points",
    ActiveCoupon: "Active Coupon",
    ActiveCouponMessage:
      "Coupon &lt;strong&gt;{0}&lt;/strong&gt; cannot be redeemed because you have an active coupon.<br /> In case of any questions, please contact Customer Support.",
    AddToFavorites: "Add to Favorites",
    AddOnConfirmationTitle: "Add-On Confirmation",
    All: "All",
    AllGames: "All Games",
    AlreadyHaveAccount: "Already have an account?&lt;br /&gt;Click here to {0}",
    AmountLabel: "Amount",
    AmountToRefund: "Amount to refund",
    Approved: "approved",
    AvailableCoupons: "Available Coupons",
    AwaitingDeposit: "Awaiting deposit",
    Balance: "Balance",
    Bonus: "Bonus",
    BonusBalance: "Bonus Balance",
    BonusType: "Bonus Type",
    buyIn: "Buy-In",
    BuyInConfirmationTitle: "Buy-In Confirmation",
    Cancel: "Cancel",
    CancelCoupon: "Cancel Coupon",
    Cancelled: "cancelled",
    changeMessage:
      "(Changing this field will update your Casino E-mail Address)",
    Chat: "Chat",
    ClearFilter: "Clear Filter",
    CompPoints: "Comp Points",
    CompPointsAmountToRedeem: "Please enter an amount to redeem",
    Confirmation: "Confirmation",
    ConfirmCancelCoupon: "Do you want to cancel the Coupon?",
    ConfirmCompPointCoupon:
      "Do you want to redeem {0} coupon? {1} comp points will be taken from your comps balance.",
    confirmEmail: "Confirm E-mail",
    confirmPassword: "Confirm Password",
    ContactUs: "Contact Us",
    ContinuePlayingTitle: "Continue Playing?",
    couponCode: "Coupon Code",
    CouponDeclined: "Coupon declined",
    CouponPage: "Coupon",
    Coupons: "Coupons",
    CouponsDeposit: "Deposit",
    CreateAliasCharLimits: "6 to 20 characters required",
    Cashier: "Cashier",
    CreateAliasCreate: "Create",
    CreateAliasErrorMessage:
      "Alias already in use. Please choose another Alias.",
    CreateAliasMainText1:
      "Create an Alias to represent you on tournament Leader Boards.",
    CreateAliasMainText2:
      "Select your Alias carefully as it cannot be changed.",
    CreateAliasSelectMsg: "Select your alias",
    CreateAliasSuccessMessage: "Alias Created",
    CreateAliasTitle: "Create an Alias",
    Credits: "Credits",
    Currency: "Currency",
    DarkMode: "Dark Mode",
    DateLabel: "Date",
    DaysMaximum: "31 days maximum",
    Declined: "declined",
    DeleteMessageConfirmation: "Do you want to delete the selected messages?",
    DepositPage: "Deposit",
    DetailViewAvailable: "Available",
    DetailViewCost: "Cost",
    DetailViewDuration: "Duration",
    DetailViewEnds: "Ends",
    DetailViewPrizePool: "Prize Pool",
    DetailViewStarts: "Starts",
    DetailViewStatus: "Status",
    DetailViewTime: "Time",
    DontHaveAnAccount: "Don't have an account yet?",
    EMailLabel: "Please enter the e-mail address associated with this account",
    emailNotVerified: "Your e-mail has not been verified",
    emailSentMsg: "E-mail sent",
    eMailAddress: "E-Mail Address",
    EnterCompsToRedeem: "Enter Comps to Redeem",
    EnterOtp: "Enter OTP",
    MinimumCompPointsToRedeem: "Minimum Comp Points to redeem:",
    EnterCouponCode: "Enter Coupon Code",
    ExcludedGames: "Excluded games",
    Favorites: "Favorites",
    FiatEquivalent: "Fiat Equivalent",
    FieldRequieredMessage: "Field Required",
    FixedPercentage: "Fixed Percentage.",
    FixedPercentageExample:
      "The bonus percentage received is dependent on the deposit method and amount.You will receive the bonus percentage defined for the deposit range matching your deposit amount.",
    ForgotPasswordHeader: "Forgot Password",
    ForgotUsername: "Forgot Username?",
    ForgotUsernameDescription:
      "Please enter the e-mail associated with this account. Your username will be sent to this e-mail address.",
    ForgotUsernameHeader: "Forgot Username",
    ForgotUsernameSucceed:
      "Your username was successfully sent to your e-mail address.",
    FreeSpins: "Free Spins",
    FreeSpinsCompletedAlongYourWinnings:
      "Along with your winnings, a &lt;strong&gt;{0} Courtesy Bonus&lt;/strong&gt; will also be credited to your balance!",
    FreeSpinsCompletedClickingDiscard:
      "Clicking Discard will forfeit your winnings, if any",
    FreeSpinsCompletedContinuePlaying: "To continue playing, simply:",
    FreeSpinsCompletedEndDlgRequiredPlaythrough: "Required playthrough:",
    FreeSpinsCompletedGetBonus: "To get your bonus, simply:",
    FreeSpinsCompletedGetFreeSpinWinnings:
      "To get your Free Spin winnings*, simply:",
    FreeSpinsCompletedHeaderNoWinnings:
      "No winnings from Free Spins this time.",
    FreeSpinsCompletedHeaderWinnings:
      "You’ve won &lt;strong&gt;{0}&lt;/strong&gt; Congratulations!",
    FreeSpinsCompletedMakeMinimumDeposit:
      "Make a &lt;strong&gt;{0}&lt;/strong&gt; minimum deposit.",
    FreeSpinsCompletedSubHeaderBonusNoWinnings:
      "&lt;strong&gt;{0}&lt;/strong&gt; Courtesy Bonus!",
    FreeSpinsCompletedThankYou: "Thank you for playing in our Casino!",
    FreeSpinsCompletedTitle: "Free Spins Completed",
    FreeSpinsCompletedTransferLimit:
      "Transfer limit of {0}; excess will be lost.",
    FreeSpinsCompletedWager: "Wager* {0}",
    FreeSpinsCompletedWagerFreeSpinWinnings:
      "Wager* {0}x the total of your Free Spin winnings + your deposit",
    FreeSpinsCompletedWagerX: "Wager* the equivalent of {0}x your deposit",
    FreeSpinsCouponDiscarded: "Coupon and winnings have been discarded.",
    FreeSpinsDiscard: "Discard",
    Close: "Close",
    FreeSpinsNotificationMessage:
      "You have &lt;strong&gt;&lt;SPINS&gt; free spins&lt;/strong&gt; on &lt;GAMENAME&gt;",
    FreeSpinsNotificationMessagePart1: "You have",
    FreeSpinsNotificationMessagePart2: "<NUM_SPINS> Free Spins!",
    FreeSpinsNotificationPlay: "Play",
    FreeSpinsValid: "Free spins valid for: {0} ({1} lines at {2:F})",
    FreeSpinsValid2: "Free spins valid for: {0} ({1} AWP at {2:F})",
    FromLabel: "From:",
    FunBalance: "Fun Balance",
    Games: "Games",
    GamesIncluded: "Included",
    GuruPlay: "Play",
    GuruPlayForReal: "Play for Real",

    RecommendedGames: "Recommended Games",
    TrendingGames: "Trending Games",
    NowPlaying: "Now Playing",
    TemporarilyDisableThisMessage: "Temporarily disable this message",
    Game: "Game",
    GameNameLabel: "Game Name",
    GamesGuruWelcome:
      "Welcome to Games Guru! I’m here to recommend some games especially for you!",
    HomePage: "Home",
    ID: "ID",
    Info: "Info",
    Information: "Information",
    InsufficientFunds: "Insufficient Funds",
    Invalid: "Invalid",
    Jackpot: "Jackpot",
    Latest: "Latest",
    LastPlayed: "Last Played",
    LegalNotices: "Legal Notices",
    LessDetails: "Less Details",
    Lines: "Lines",
    LobbyGamesSeeAll: "+SEE ALL",
    LobbyGamesSeeAllButton: "+SEE ALL",
    LoginPage: "Log In",
    LoginText: "Login",
    LogoutPage: "Log Out",
    MaxCouponAmount: "(Max Coupon Amount: {0})",
    MaximumDeposit: "Maximum Deposit",
    MaximumWithdrawableAmount: "Maximum Withdrawable Amount",
    MinimumBonusBalance: "Minimum Bonus Balance",
    MessageCenterPage: "Messages",
    MessageTitle: "Message",
    NoMessages: "No messages in your inbox",
    MinimumDeposit: "Minimum Deposit",
    Minutes: "Minutes",
    MoreDetails: "More Details",
    Name: "Name",
    NEXT: "Next",
    NoEnrollmentsText: "You are not enrolled in any tournament at this moment",
    NoLabel: "No",
    noteMessage:
      "Check your e-mail and click on the verification e-mail link. If you are unable to locate it please check your SPAM/JUNK mail",
    NoTransactions: "No Transactions",
    OKButton: "OK",
    Or: "OR",
    OTPNoValid: "OTP is not valid. Please try again.",
    password: "Password",
    PasswordNotMatch: "Passwords do not match.",
    Play: "Play",
    PlayableBalance: "Playable Balance",
    PlayForRealMoney: "Play for Real",
    Playthrough: "Playthrough",
    PlaythroughAmounts: "Playthrough amounts per {0} wagered",
    PlaythroughDetails: "Playthrough details",
    PlaythroughNeeded: "Needed",
    PlaythroughPlayed: "Played",
    PlaythroughRemaining: "Playthrough Remaining",
    PointsCanNotBeDecimal: "Points to redeem cannot be a decimal",
    PopupBlockerWarningTitle: "Alert",
    PracticeMode: "Practice Mode",
    PrivacyPolicyLink: "Privacy Policy",
    PREVIOUS: "Previous",
    ReBuyConfirmationTitle: "Re-Buy Confirmation",
    Redeem: "Redeem",
    RedeemCompPointsHeader: "Redeem Comp Points",
    Redeemed: "redeemed",
    Refund: "Refund",
    RefundAmount: "Refund Amount",
    RefundConfirmationTitle: "Refund Confirmation",
    RegisterButton: "Register",
    RemoveFromFavorites: "Remove From Favorites",
    RequestedLowercase: "requested",
    RequestedUppercase: "Requested",
    RequestNewCode: "Request new code",
    RegistrationSuccessMessage:
      "You have successfully registered your account.",
    resend: "Resend",
    ResetPasswordHeader: "Reset Password",
    SearchLabel: "Search",
    securityCode: "Security Code",
    SecurityCodeAttemptsErrorMessage:
      "There were too many failed attempts.  A new security code has been sent to your email",
    SecurityCodeErrorMessage:
      "The security code entered was incorrect. Validate you are using the latest security code sent to your email",
    SelectDateRangeMessage: "Please select a date range between 1 and 31 days",
    SessionEnd: "Session end",
    SessionStart: "Session start",
    SignUp: "Sign Up",
    SignUpPage: "Sign Up",
    SignUpTermsCheckbox:
      "I agree to the {a1} {0} {span1} and the {a2} {1} {a3}",
    SignUpTermsCheckboxError:
      "You must read and accept the Terms and Conditions and the Privacy Policy",
    PhoneFieldsError:
      "Phone number format is incorrect, please correct the number and try again",
    SMSMessages: "Accept SMS Messages",
    StatusAnnounced: "Announced",
    StatusCancelled: "Cancelled",
    StatusCompleted: "Completed",
    StatusNotAnnounced: "NotAnnounced",
    StatusRegistering: "Registering",
    StatusRestricted: "Restricted",
    StatusRunning: "Running",
    StatusUpdating: "Updating",
    SortAndFilter: "Sort and Filter",
    SortAndGames: "Games",
    SortAndAll: "Clear All",
    SortAndApplyFilter: "Apply Filter",
    SortAndOnly: "Show Free Rools Only",
    SortAndOptions: "Options",
    SortBuyInHighLow: "Buy-In: High to Low",
    SortBuyInLowHigh: "Buy-In: Low to High",
    SortBy: "Sort by",
    SortTournamentEndDate: "Tournament End Date",
    SortTournamentStartDate: "Tournament Start Date",
    SwitchTournamentCurrency: "Switch to <currency>",
    TermsNConditions: "I accept",
    TermsNConditionsError: "You must read and accept Terms and Conditions",
    TermsNConditionsLink: "Terms and Conditions",
    ToLabel: "To:",
    TournamentAddOn: "Add-On",
    tournamentAddOnSuccess: "Successful Add-On",
    TournamentAlias: "Alias",
    TournamentAward: "Award",
    tournamentBuyInSuccess: "Successful Buy-In",
    TournamentCompleted: "Tournament Completed",
    TournamentContinueOptions:
      "Continue to move up the leaderboard with the following options:",
    TournamentCurrentScore: "Current Score",
    TournamentEnrolled: "Enrolled",
    tournamentFullError:
      "Sorry, this tournament has reached its maximum capacity.",
    TournamentHighestScore: "Highest Score",
    TournamentInProgress: "In Progress",
    TournamentLeaderboard: "Leader Board",
    TournamentLeaderboardResultsNotice:
      "Leaderboard will show results for 24 hours starting on:",
    TournamentOutOfCredits: "You've run out of <strong>credits</strong>",
    TournamentOutOfTime: "You've run out of <strong>time</strong>",
    TournamentPleaseCheckAgain: "Please check again soon",
    TournamentPosition: "Position",
    TournamentPrizes: "Prizes",
    TournamentReBuy: "Re-Buy",
    tournamentReBuySuccess: "Successful Re-Buy",
    TournamentRefundCompleted:
      "Once the refund is completed, you will no longer be registered into the tournament",
    tournamentRefundSuccess: "Refunded",
    TournamentsRegStartIn: "Registration starts in:",
    TournamentReminder: "Tournament Reminder",
    TournamentReminderTapPlay:
      "Tap Play to be taken directly to the tournament",
    TournamentScore: "Score",
    TournamentNoAvailable: "No available tournaments",
    TournamentsNoAvailableFilter:
      "No available tournaments. Please use a different filter option.",
    TournamentsNoAvailableSearch:
      "No tournaments matched the search criteria. Please use a different filter.",
    tournamentSystemError:
      "There was a problem on the server. Please contact customer service for more information.",
    TournamentsTourEndIn: "Tournament ends in:",
    TournamentsTourStartIn: "Tournament starts in:",
    TournamentUpcoming: "Upcoming",
    TournamentWinner: "Winner",
    TransactionLabel: "Transaction",
    TryItButton: "Try It!",
    Unlimited: "Unlimited",
    Unlock: "Unlock",
    UserName: "Username",
    UserNameQuestion: "Username?",
    Verify: "Verify",
    verifyEmail: "Verify E-mail",
    VerifyOTP:
      "<p>To verify your email, we've sent a One Time Password (OTP) to &lt;Email&gt;. &lt;Change&gt;.</p><p> Type the OTP in the field below.</p><p>For your convinience, we have also included a link on the email, click it and you're all set.</p><p>If you are unable to locate it, please check your SPAM/JUNK mail.</p>",
    DoItLater: "Do it later",
    Withdrawable: "Withdrawable",
    WithdrawableBalance: "Withdrawable Balance",
    WithdrawPage: "Withdraw",
    YesLabel: "Yes",
    YourPosition: "Your position",
    YourRankText: "Your Rank:",
    Password_Weak: "Weak Password",
    Password_Medium: "Medium Password",
    Password_Strong: "Strong Password",
    Password_Recomendations: "Recommendation for Strong Password:",
    RecomendationNCharacters: "At least {0} characters",
    RecomendationSmallLetter: "At least one small letter",
    RecomendationCapitalLetter: "At least one capital letter",
    RecomendationNumber_Symbol: "At least one number or symbol",

    FlashDeprecationMessagePart1:
      "Due to the deprecation of Adobe Flash Player, unfortunately this game is no longer available on this platform.",
    FlashDeprecationMessagePart2: "This game continues to be available on:",
    FlashDeprecationMessagePart3:
      "In case of any question, please contact Customer Support.",
    PlatformMobile: "Mobile",
    TooManyGamesOpen:
      "The maximum number of games that can be played at one time has been reached.",
    Accept: "Accept",
    CurrentGameCouponRestricted:
      "This game has been restricted by a coupon.  Please return to the Lobby.",
    PendingGamesTitle: "Pending Games",
    PendingGamesMessage:
      "In order to continue with other games, it is necessary to complete these games:",
    NonHtml5PendingGameActiveMessage:
      "You currently have a pending game for [pendingMachDescription]. In order to continue with other games, it is necessary to complete the pending game.",
    GameUnavailableJackpotWinOnReview:
      "Your jackpot win on this game is still under review, therefore the game is currently unavailable. For more information, please contact Customer Support.",
    Tournaments: "Tournaments",
    PromotionsPage: "Promotions",
    LateRegistration: "Late Reg.",
    PopupBlockerWarningCashierText:
      "Your browser may be blocking pop-ups. To launch the Cashier, please confirm the action.",
    PopupBlockerWarningGameText:
      "Your browser may be blocking pop-ups. To launch the Game, please confirm the action.",
    PopupBlockerWarningLoadCashier: "Load Cashier",
    PopupBlockerWarningPlay: "Play Game",
    LateRegistrationMinutes: "Late Reg. {0} mins",
    LateRegistrationOneMinute: "Late Reg. 1 min",
    LateRegistrationLessThanOneMinute: "Late Reg. <1 min",
    ReferAFriend: "Refer a Friend",
    GenerateLink: "Generate Link",
    EarnMoneyReferring:
      "Earn money just by referring your friends to the casino! Enter up to 5 e-mail addresses below. If any of your friends deposit money and play at the casino, you have a chance to earn money!",
    EarnMoneyReferringBoth:
      "Earn money just by referring your friends to the casino! Either generate your referral link to share on social media sites, or enter up to 5 e-mail addresses below. If any of your friends deposit money and play at the casino, you have a chance to earn money!",
    EarnMoneyReferringLink:
      "Earn money just by referring your friends to the casino! Generate your referral link to share on social media sites. If any of your friends deposit money and play at the casino, you have a chance to earn money!",
    Program: "Program",
    ReferLinkHeading: "Referral Link",
    ReferLinkHelpBubble:
      "You can use the Referral Link option to generate your referral URL to share with friends via social media sites.",
    CopyPasteBubbleDesktop: "Click and press 'Ctrl+C' to copy...",
    ReferEmailsHeading: "Referral Emails",
    ReferEmailsHelpBubble:
      "You can use the Referral Email option to refer up to 5 friends to the casino using their e-mail addresses.",
    FriendNumber: "Friend #{0}",
    SendEmails: "Send Emails",
    CopyLink: "Copy Link",
    isRequired: "{0} is required",
    EmailConfimationReferAFriend:
      "Congrats! Here is the list of friends you have referred to the casino. If your friends enrol and play in the casino, you have a chance to earn some money!",
    EmailSentTo: "Your email has been sent to the following recipients",
    ReferAFriendMobileDescription:
      "Earn money just by referring your friends to the casino!",
    ResetPasswordAddInfo:
      "Enter the security code you received via email and your new password.",
    TournamentsMaintenanceMessage:
      "Slot Tournaments are currently undergoing scheduled maintenance. Normal service will be restored soon. Thanks for your patience.",
    RecentWinners: "Recent Winners",
    LobbyJackpot: "Lobby Jackpot",
    lblCongratulations: "Congratulations!",
    YouWon: "You won",
    ClaimJackpotPrize: "Claim Prize",
    CasinoJackpotInfoMessage1:
      "To participate in the <Lobby Jackpot Name> select Opt-In.",
    CasinoJackpotInfoMessage2:
      "Once enrolled, you will have a chance to win the accumulated jackpot amount as a compulsory <amount/currency> bet will be placed on every spin.",
    CasinoJackpotInfoMessage3:
      "To opt-out at any moment, select the Lobby Jackpot menu option.",
    OptInConfirmationMessage:
      "By selecting to opt-in into the Lobby Jackpot, <AMOUNT> will be deducted from your balance after every spin.",
    OptOutConfirmationMessage:
      "Are you sure you want to opt-out from the <Jackpot Name>?",
    JackpotOptIn: "Opt-In Confirmation",
    JackpotOptOut: "<JackpotName> Opt-Out",
    OptIn: "Opt-In",
    OptOut: "Opt-Out",
    SwitchToRealMode: "Switch to Real Mode",
    CantBePlayedInFunMode: "Tournaments can't be played in Practice Mode.",
    WouldYouLikeToSwitchToRealMode: "Would you like to switch to Real Mode?",
    daytimePhone: "Daytime Phone",
    eveningPhone: "Evening Phone",
    cellPhone: "Mobile / Cell Phone",
    Mode: "Mode",
    Points: "Points",
    CreditsToQualify: "Credits to Qualify",
    RequiredToQualify: "Required to Qualify",
    CreditsWagered: "{0} out of {1} credits wagered",
    ContinuePlayingOptions: "Continue playing with the following options:",
    Purchase: "Purchase",
    PendingQualify: "Pending to Qualify",
    InsertPassword: "Insert Password",
    SearchTournaments: "Search Tournaments",
    MyProfile: "My Profile",
    Save: "Save",
    TournamentPlayer: "Player",
    Step: "Step {0}",
    ForgotPassword: "Forgot Password?",
    InvalidCredentialsError: "Invalid user name and/or password",
    MoreGames: "More Games"
  },
  stringsResourceService: {
    AtLeastMinCharacters: "At least {0} characters",
    OneLowerCaseLetter: "One lowercase letter",
    OneUpperCaseLetter: "One uppercase letter",
    OneSpecialCharacter: "One special character",
    OneNumber: "One number",
    PasswordRequirements: "Your password must contain:",
    ConfirmCompPointCoupon:
      "Are you sure you want to redeem {0} coupon? {1} comp points will be deducted from your comps balance.",
    CompPoints: "Comp Points",
    Payout: "Payout",
    Coupon: "Coupon",
    CashPrize: "Cash Prize",
    FieldRequired: "Field Required",
    EmailForgotPassword:
      "Please enter the email address associated with this account",
    Cancel: "Cancel",
    EmailAddress: "Email",
    ForgotUserName:
      "Please enter the email associated with this account. Your username will be sent to this email address.",
    ConfirmationPassword: "Confirm Password",
    Next: "Next",
    AlreadyHaveAccount: "Already have an account?<br />Click here to {0}",
    PasswordNoMatch: "Passwords do not match",
    Previous: "Previous",
    SmsMessages: "Accept SMS Messages",
    AcceptPromotions:
      "I would like to receive information about bonuses, news and promotions",
    SignUpTermsCheckbox:
      "I agree to the {a1} {0} {span1} and the {a2} {1} {a3}",
    SignUpTermsCheckboxError:
      "You must read and accept the Terms and Conditions and the Privacy Policy",
    Register: "Register",
    TermsNConditionsError: "You must read and accept Terms and Conditions",
    PlayForReal: "Play for Real",
    Alert: "Alert",
    Currency: "Currency",
    Login: "Log In",
    TermAndConditions: "Terms and Conditions",
    TournamentCoupons: "Tournament Coupons",
    MyPrizes: "My Prizes",
    PendingCoupons: "Pending Coupons",
    MaxBetAllowed: "Max Bet Allowed",
    MaxBetAllowedUnlimited: "Unlimited",
    CouponFSMultiGamesChoiceOneGame:
      "Free spins valid for your choice of one of the following games:",
    CouponFSMultiGamesLines: "Lines",
    CouponFSMultiGamesBetPerLine: "Bet per line",
    CouponFSMultiGamesSelect: "Select",
    CouponFSMultiGamesConfirmMsg:
      "Are you sure you want to redeem your free spins for <Game Name>?",
    CouponFSMultiGamesTitle: "Free Spins!",
    CouponFSMultiGamesChooseGameMsg: "Choose a game:",
    CouponFSMultiGamesTotalBet: "Total bet",
    Error: "Error",
    LiveDealerNotAvailable: "Live Dealer not available at this time. Please try again later.",
    MaximumAmountToConvert: "Maximum Amount to Convert",
    MaximumAmountToConvertTooltip: "Maximum amount to be converted from Bonus to Withdrawable Balance once playthrough requirements are met.",
    AllDepositMethods: "All Deposit Methods",
    AllOtherDepositMethods: "All Other Deposit Methods",
    ResetPasswordInfo: "Enter the security code you received via email and your new password",
    ForgotPasswordHeader: "Forgot Password",
    EmailSentMessage: "Email sent",
    ChangeEmailMessage: "(Changing this field will update your Casino email address)",
    CheckVerificationEmailMessage: "Check your email and click on the verification email link. If you are unable to locate it please check your SPAM/JUNK mail",
    ConfirmEmail: "Confirm Email",
    VerifyEmail: "Verify Email",
    ForgotUsernameSucceed: "Your username was successfully sent to your email address.",
    
  }
};

const getters = {
  getStringByKey: state => key => {
    if (state.strings.hasOwnProperty(key)) {
      return state.strings[key];
    } else {
      return "#" + key;
    }
  },
  getStringByKeyResourceService: state => key => {
    if (state.stringsResourceService.hasOwnProperty(key)) {
      return state.stringsResourceService[key];
    } else {
      return "#" + key;
    }
  }
};

const actions = {
  async initialize({ dispatch }) {
    dispatch("requestStrings");
    dispatch("requestStringsFromResourceService");

    if (process.env.NODE_ENV !== "production" && window.checkTranslations) {
      checkForMissingTranslations(Object.keys(state.strings));
    }

    window.setLobbyLanguage = async langCode => {
      await setLanguage(langCode);
      await dispatch("requestStrings");
      await dispatch("requestStringsFromResourceService");
      dispatch("accountFields/loadAccountFields", null, { root: true });
      dispatch("games/requestRecentGamesMenuData", null, { root: true });
    };

    window.cycleLobbyLanguage = async () => {
      await cycleLanguage();
      await dispatch("requestStrings");
      await dispatch("requestStringsFromResourceService");
      dispatch("accountFields/loadAccountFields", null, { root: true });
      dispatch("games/requestRecentGamesMenuData", null, { root: true });
    };
  },
  requestStrings({ commit, state }) {
    CDK.getStringTable(Object.keys(state.strings)).then(strings => {
      // Check result
      if (!strings || Object.keys(strings).length === 0) {
        window.console.warn(
          "stringTable: received empty list of strings from server!"
        );
        return;
      } else {
        // Update components in our store
        commit("updateStrings", strings);
      }
    });
  },
  requestStringsFromResourceService({ commit, state }) {
    CDK.getStringTableFromResourceService(
      Object.keys(state.stringsResourceService)
    ).then(strings => {
      // Check result
      if (!strings || Object.keys(strings).length === 0) {
        window.console.warn(
          "stringTable: received empty list of strings from server!"
        );
        return;
      } else {
        commit("updateStringsResourceService", strings);
      }
    });
  }
};

const mutations = {
  updateStrings(state, strings) {
    state.strings = strings;
  },
  updateStringsResourceService(state, strings) {
    Object.entries(strings)
      .filter(([, value]) => value !== "")
      .forEach(([key, value]) => (state.stringsResourceService[key] = value));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
