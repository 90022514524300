<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" fullscreen>
    <v-overlay>
      <v-icon color="light-green lighten-1">mdi-check-box-outline</v-icon>
      <h2 class="text-center mt-n8 light-green--text text--lighten-1">
        {{ successMessage }}
      </h2>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "success",
      successMessage: "Success"
    };
  },

  methods: {
    onShown() {
      const successMessage = this.$store.getters["dialogs/success"].data
        .successMessage;
      if (successMessage) {
        this.successMessage = successMessage;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-dialog--fullscreen {
  background: rgba(0, 0, 0, 0.75);
  overflow: hidden;
}

@media only screen and (min-width: 600px) {
  ::v-deep .v-dialog:not(.downTimeDialog):not(.rounded-0) {
    background: rgba(0, 0, 0, 0.75) !important;
  }
}

.v-icon {
  font-size: 18rem;
  width: 100vw;
}
</style>
