<template>
  <v-container width="100vw" class="pa-0">
    <v-card
      flat
      class="d-flex align-center"
      color="transparent"
      :disabled="isExcluded"
      @click="onGameClicked"
    >
      <v-card-title class="pa-0 ">
        <v-img
          class="rounded"
          :src="gameIcon(game)"
          :lazy-src="lazyLoadingImage"
          :width="isSquareIcon ? '106.666px' : '75px'"
          :contain="!gameIcon(game)"
          height="100px"
        >
          <v-overlay v-if="isExcluded" absolute>
            <v-icon large>mdi-lock</v-icon>
          </v-overlay>
        </v-img>
      </v-card-title>
      <v-card-actions class="pa-0 mr-2">
        <p
          class="gameName ma-0"
          style="padding-left: 10px;"
          v-text="gameName"
        ></p>
      </v-card-actions>
      <v-spacer></v-spacer>
      <v-card-actions v-if="loggedIn" class="pa-0">
        <p
          v-if="jackpot"
          class="gameJackpot mb-0 px-3"
          v-text="$cur(jackpot)"
        ></p>
        <i
          v-if="showFavoriteStar(game)"
          class="fi fi-sr-star favorite"
          :class="{
            active: isFavorite,
            starOff: !isFavorite
          }"
          @click.stop="toggleFavorite"
        >
        </i>
      </v-card-actions>
    </v-card>
    <v-divider></v-divider>
  </v-container>
</template>

<script>
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { mapGetters } from "vuex";

export default {
  mixins: [toggleFavorite],
  props: {
    gameId: { type: String, required: true }
  },
  computed: {
    ...mapGetters("games", [
      "games",
      "gameIsExcluded",
      "gameJackpots",
      "gameIcon",
      "showFavoriteStar",
      "showDetailsButton"
    ]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    game() {
      return this.games[this.gameId];
    },
    isExcluded() {
      return this.gameIsExcluded[this.gameId];
    },
    jackpot() {
      return this.gameJackpots[this.gameId];
    },
    gameName() {
      return this.game?.name;
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    launchGame() {
      this.$store.dispatch("games/launchGame", {
        gameId: this.gameId,
        externalLobbyId: this.game.externalLobbyId
      });
    },
    showGameDetailDialog() {
      this.$store.dispatch("dialogs/showGameDetail", { id: this.gameId });
      this.$emit("close");
    },
    onGameClicked() {
      if (this.game?.isExternalGame) {
        this.launchGame();
      } else {
        this.showGameDetailDialog();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.favorite {
  margin-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: normal;
  color: white;
  cursor: pointer;

  &.active {
    color: #ffd600;
  }
}

::v-deep .v-ripple__container {
  display: none !important;
}
.gameName {
  text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gameJackpot {
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 140% */
  letter-spacing: 0.4px;
}
</style>
