<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" max-width="900">
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar
        :title="confirmLoadGame.data.title"
        @close="showDialog = false"
      />
      <v-card-text
        class="text--primary text-center pt-1 pb-3"
        style="font-size: 1.1rem"
        >{{ $str("PopupBlockerWarningGameText") }}</v-card-text
      >

      <v-card-actions class="d-flex justify-space-around pb-7">
        <v-btn
          style="font-size: 1.1rem"
          min-width="30%"
          color="buttonSecondary buttonSecondaryTxt--text"
          @click="loadGame"
          >{{ $str("PopupBlockerWarningPlay") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "confirmLoadGame"
    };
  },
  computed: {
    ...mapGetters("dialogs", ["confirmLoadGame"])
  },
  methods: {
    loadGame() {
      this.gameWindow = window.open(
        "",
        "Game",
        `height=${this.confirmLoadGame.data.height},width=${this.confirmLoadGame.data.width}, scrollbars=no`
      );
      this.gameWindow.location = this.confirmLoadGame.data.url;
      this.showDialog = false;
    }
  }
};
</script>

<style scoped></style>
