<template>
  <v-card
    v-if="isVisible"
    class="gametile"
    :class="lobbyIconType"
    color="header"
    flat
    :disabled="isExcluded"
    style="border-radius: 6px;"
  >
    <v-img
      :src="gameIcon(game)"
      :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
      contain
      @click="onGameClicked"
    >
      <i
        v-if="showFavoriteStar(game)"
        class="fi fi-sr-star favorite"
        :class="{
          active: isFavorite,
          starOff: !isFavorite
        }"
        @click.stop="toggleFavorite"
      >
      </i>
      <span v-if="jackpot" class="game-jackpot">
        <strong>
          {{ $cur(jackpot) }}
        </strong>
      </span>
      <v-overlay v-if="isExcluded" absolute>
        <v-icon large>mdi-lock</v-icon>
      </v-overlay>
    </v-img>
    <span
      v-if="isSquareIcon || !gameIcon(game)"
      class="name"
      :class="{ square: isSquareIcon, tall: !isSquareIcon }"
    >
      <strong class="gameName">{{ gameName }}</strong>
    </span>
  </v-card>
</template>

<script>
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { mapGetters } from "vuex";

export default {
  mixins: [toggleFavorite],
  props: {
    gameId: { type: String, required: true }
  },
  computed: {
    ...mapGetters("games", [
      "games",
      "gameIsExcluded",
      "gameJackpots",
      "gameIcon",
      "showFavoriteStar",
      "showDetailsButton"
    ]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    ...mapGetters("externalLobbies", ["lobbies"]),
    game() {
      return this.games[this.gameId];
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    },
    isExcluded() {
      return this.gameIsExcluded[this.gameId];
    },
    jackpot() {
      return this.gameJackpots[this.gameId];
    },
    gameName() {
      return this.game?.name;
    },
    isVisible() {
      return this.lobbies[this.games[this.gameId].gameId]?.isVisible ?? true;
    }
  },
  methods: {
    launchGame() {
      if (!this.isExcluded) {
        this.$store.dispatch("games/launchGame", {
          gameId: this.gameId,
          externalLobbyId: this.game.externalLobbyId
        });
      }
    },
    showGameDetailDialog() {
      if (!this.isExcluded)
        this.$store.dispatch("dialogs/showGameDetail", { id: this.gameId });
    },
    onGameClicked() {
      if (this.game?.isExternalGame) {
        this.launchGame();
      } else {
        this.showGameDetailDialog();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.v-card {
  line-height: 0;
}

.gametile {
  transition: ease-in 0.25s;

  &:hover {
    transform: translate(0, -10px);
  }

  & .v-image {
    width: 100%;
    position: relative;
    display: block;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.game-jackpot {
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 12px;
  left: 0;

  strong {
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 20px;
    line-height: normal;
  }

  .tall & strong {
    color: white;
    background-color: transparent;
  }

  .square & strong {
    color: black;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.favorite {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  line-height: normal;
  color: white;
  cursor: pointer;

  &.active {
    color: #ffd600;
  }
}

.name {
  display: block;
  padding: 0 12px;
  border-radius: 0px 0px 6px 6px;
  min-height: 25px;

  &.tall {
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 6px;
    padding: 0;

    strong {
      width: 100%;
      padding: 0 10px;
      position: absolute;
      bottom: 35px;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      white-space: normal;
      overflow: hidden;
    }
  }
}

.name strong {
  font-size: clamp(13px, 1vw, 15px);
  font-weight: bold;
  color: var(--v-bodyTitle-base);
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

@media (max-width: 650px) {
  .name strong {
    line-height: 1.8;
  }
}
@media (min-width: 651px) {
  .name strong {
    line-height: 2.2;
  }
}

.v-btn--icon.v-size--small .v-icon,
.v-btn {
  min-width: 0;
  height: 18px;
}
</style>
