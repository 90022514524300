<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <g id="live">
      <path
        class="st0"
        d="M24,2.8c-8.6,0-9,5.2-8.4,11.5s4.6,11.5,8.4,11.5s7.8-5.2,8.4-11.5S32.6,2.8,24,2.8z"
      />
      <path class="st1" d="M26.5,7.8c0,0-0.9,4.2-7.2,4.3" />
      <path class="st1" d="M25.8,9.6c2,1.3,4.2,2.3,6.6,3" />
      <path class="st0" d="M18.9,27.5L24,31l-5.1,3.5V27.5z" />
      <path class="st0" d="M29.1,34.5L24,31l5.1-3.5V34.5z" />
      <path
        class="st1"
        d="M4.2,45.2l0.7-9.9c0.7-5.4,4-6,4-6c1.8-0.6,3.7-1,5.6-1.3l1.7,2.8"
      />
      <path class="st1" d="M10.3,29c0,0,2,5.7,1.5,16.2" />
      <path
        class="st1"
        d="M43.8,45.2l-0.7-9.9c-0.7-5.4-4-6-4-6c-1.8-0.6-3.7-1-5.5-1.3l-1.7,2.8"
      />
      <path class="st1" d="M37.7,29c0,0-2,5.7-1.5,16.2" />
      <polyline class="st1" points="20,37.2 24,43.7 28,37.2 	" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "LiveDealer",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Live" }
  }
};
</script>

<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
