<template>
  <v-dialog
    v-if="!playForMoney"
    v-model="dialog"
    max-width="500"
    :style="{ zIndex: 500 }"
    @keydown.esc="cancel"
  >
    <v-card class="elevation-12 pa-2 pb-5" style="border-radius:12px">
      <DialogTitleBar color="transparent" @close="cancel" />
      <v-card-title
        class="pt-0 justify-center font-weight-bold"
        :class="smallLayout ? 'text-h6' : 'text-h5'"
        v-text="title"
      ></v-card-title>

      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        class="text--primary text-center pt-1 pb-3"
        v-html="message"
      ></v-card-text>
      <!--eslint-enable-->
      <v-card-actions class="d-flex justify-space-around">
        <v-btn
          color="buttonSecondary buttonSecondaryTxt--text"
          width="49%"
          @click="cancel"
          >{{ $str("NoLabel") }}</v-btn
        >
        <v-btn color="button buttonTxt--text" width="49%" @click="agree">{{
          $str("YesLabel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogTitleBar from "@/components/Dialogs/DialogTitleBar";

export default {
  components: { DialogTitleBar },
  data() {
    return {
      dialog: true
    };
  },
  computed: {
    ...mapGetters("session", ["playForMoney"]),
    ...mapActions("session", ["switchToReal"]),
    message() {
      return (
        this.$str("CantBePlayedInFunMode") +
        "<BR>" +
        this.$str("WouldYouLikeToSwitchToRealMode")
      );
    },
    title() {
      return this.$str("SwitchToRealMode");
    }
  },
  methods: {
    agree() {
      this.switchToReal;
    },
    cancel() {
      this.goHome();
    }
  }
};
</script>
