<template>
  <v-container
    dense
    :fluid="largeLayout"
    class="pa-0 ma-0"
    :class="{ 'container--fluid': smallLayout }"
  >
    <template v-if="!isTournamentsOnly">
      <template v-if="!hasCustomHome(showAllGames)">
        <HorizontalGameList
          v-for="(menuId, index) in populatedMainMenus"
          v-show="!hasCustomHome(showAllGames)"
          :key="index"
          :menu-id="menuId"
        />
      </template>
    </template>
    <CustomHome v-if="hasCustomHome(showAllGames)" />
  </v-container>
</template>

<script>
import HorizontalGameList from "@/components/HorizontalGameList";
import CustomHome from "@/components/CustomHome";
import { mapGetters } from "vuex";

export default {
  components: {
    HorizontalGameList,
    CustomHome
  },
  props: {
    showAllGames: { type: Boolean, required: false }
  },
  computed: {
    ...mapGetters("games", [
      "populatedMenuIds",
      "populatedMainMenuIds",
      "populatedGameFamilyMenus"
    ]),
    ...mapGetters("customizations", ["customHomeURL", "customHomeChannel"]),
    ...mapGetters("customizations", ["isTournamentsOnly"]),
    ...mapGetters("session", ["playForMoney"]),
    populatedMainMenus() {
      //Do not show EVO live dealer unless playing for real
      if (
        !this.playForMoney &&
        this.populatedGameFamilyMenus.find(
          menu => menu.name === "Live Dealer EVO"
        )
      ) {
        const liveDealerId = this.populatedGameFamilyMenus.find(
          menu => menu.name === "Live Dealer EVO"
        ).id;
        return this.populatedMainMenuIds.filter(
          menuId => menuId !== liveDealerId
        );
      }
      return this.populatedMainMenuIds;
    }
  }
};
</script>

<style src="../styles/custom.scss" lang="scss" />
