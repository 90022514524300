/**
 * State and actions that drive the showing of dialogs.
 *
 * @see @/mixins/dialogToggle.js
 */
import { customScriptHandlers, clientType } from "@/services/casino";
import store from "@/store";
/**
 * State property names correspond to dialog component names.
 * Each entry has an 'isVisible' property, and optionally a 'data'
 * object used to pass metadata to the dialog when toggling it.
 */
const state = {
  accountHistory: {
    isVisible: false,
    data: {
      title: ""
    }
  },
  balanceDetail: { isVisible: false },
  compPoints: {
    isVisible: false
  },
  contactUs: { isVisible: false },
  referFriend: { isVisible: false },
  cashier: {
    isVisible: false,
    data: {
      options: "",
      title: "",
      cashierMode: ""
    }
  },
  externalUrl: {
    isVisible: false,
    data: {
      title: "",
      source: "",
      HTML: null,
      onClose: "",
      onCloseCallback: null,
      showTitleBar: true,
      height: "",
      width: ""
    }
  },
  customUrl: {
    isVisible: false,
    data: {
      title: "",
      source: ""
    }
  },
  gameLauncher: {
    isVisible: false,
    data: {
      gameId: ""
    }
  },
  search: {
    isVisible: false,
    data: {
      title: "",
    }
  },
  signIn: {
    isVisible: false,
    data: { onSuccess: null, login: null, password: null, confirmMessage: "" }
  },
  signUp: {
    isVisible: false,
    data: {
      title: "",
    }
  },
  forgotPassword: { isVisible: false, data: { login: null, email: null } },
  forgotUsername: { isVisible: false },
  changePassword: {
    isVisible: false,
    data: { login: null, email: null, confirmMessage: "" }
  },
  coupons: { isVisible: false, data: { couponCode: null } },
  chooseMultiGame: { isVisible: false },
  freeSpinNotification: { isVisible: false },
  completedFreeSpin: { isVisible: false },
  messageCenterList: { isVisible: false },
  messageCenterMessage: {
    isVisible: false,
    data: {
      id: -1,
      isForceOnExit: false
    }
  },
  gameDetail: { isVisible: false, data: { id: -1 } },
  adminPlayerMessage: { isVisible: false },
  verifyEmail: { isVisible: false },
  openedSignUpDialog: { isVisible: false },
  activeCoupon: {
    isVisible: false,
    data: {
      title: "",
      source: ""
    }
  },
  gamesGuru: {
    isVisible: false,
    data: {
      gameId: null,
      balanceChanged: false,
      adminOption: false
    }
  },
  success: { isVisible: false },
  buyIn: { isVisible: false },
  tournamentReBuyAddOn: {
    isVisible: false,
    data: { tournament: null, actionType: "" }
  },
  refund: { isVisible: false },
  sortAndFilters: { isVisible: false },
  tournamentReminder: { isVisible: false, data: { tournaments: [] } },
  pendingGames: {
    isVisible: false,
    data: {
      showForSingleMode: false,
      pendingGames: []
    }
  },
  confirmLoadCashier: {
    isVisible: false,
    data: { url: null, width: null, height: null }
  },
  confirmLoadGame: {
    isVisible: false,
    data: { url: null, width: null, height: null }
  },
  tournamentsLobby: { isVisible: false },
  casinoJackpot: {
    isVisible: false,
    data: {
      title: ""
    }
  },
  casinoJackpotWonPrize: {
    isVisible: false,
    data: {
      title: ""
    }
  },
  emailVerification: {
    isVisible: false,
    data: { login: null, password: null, hash: "", playerData: null }
  },
  resendOTPVerificationEmail: { isVisible: false },
  confirmOTP: {
    isVisible: false,
    data: { optResult: false, login: null, password: null }
  },

  URL: { hash: "" },
  myProfile: {
    isVisible: false,
    data: {
      title: ""
    }
  },
  slideInDialog: {
    isVisible: false,
    data: {
      title: "",
      titleColor: null,
      text: "",
      textColor: null,
      backgroundImage: null,
      backgroundColor: null,
      timeoutMs: 5000,
      showCasinoLogo: false,
      onCloseCallback: null
    }
  },
  couponPrizes: { isVisible: false }
};

const getters = {
  accountHistory: state => state.accountHistory,
  balanceDetail: state => state.balanceDetail,
  compPoints: state => state.compPoints,
  cashier: state => state.cashier,
  contactUs: state => state.contactUs,
  referFriend: state => state.referFriend,
  externalUrl: state => state.externalUrl,
  customUrl: state => state.customUrl,
  gameLauncher: state => state.gameLauncher,
  search: state => state.search,
  signIn: state => state.signIn,
  signUp: state => state.signUp,
  forgotPassword: state => state.forgotPassword,
  forgotUsername: state => state.forgotUsername,
  changePassword: state => state.changePassword,
  coupons: state => state.coupons,
  freeSpinNotification: state => state.freeSpinNotification,
  chooseMultiGame: state => state.chooseMultiGame,
  completedFreeSpin: state => state.completedFreeSpin,
  messageCenterList: state => state.messageCenterList,
  messageCenterMessage: state => state.messageCenterMessage,
  gameDetail: state => state.gameDetail,
  adminPlayerMessage: state => state.adminPlayerMessage,
  verifyEmail: state => state.verifyEmail,
  openedSignUpDialog: state => state.openedSignUpDialog,
  activeCoupon: state => state.activeCoupon,
  gamesGuru: state => state.gamesGuru,
  tournamentsLobby: state => state.tournamentsLobby,
  isAnyDialogOpen: state => {
    const anyVisible = Object.values(state).some(
      dialog => dialog.isVisible === true
    );

    return anyVisible;
  },
  success: state => state.success,
  buyIn: state => state.buyIn,
  tournamentReBuyAddOn: state => state.tournamentReBuyAddOn,
  continuePlaying: state => state.continuePlaying,
  refund: state => state.refund,
  sortAndFilters: state => state.sortAndFilters,
  tournamentReminder: state => state.tournamentReminder,
  pendingGames: state => state.pendingGames,
  confirmLoadCashier: state => state.confirmLoadCashier,
  confirmLoadGame: state => state.confirmLoadGame,
  casinoJackpot: state => state.casinoJackpot,
  casinoJackpotWonPrize: state => state.casinoJackpotWonPrize,
  emailVerification: state => state.emailVerification,
  resendOTPVerificationEmail: state => state.resendOTPVerificationEmail,
  confirmOTP: state => state.confirmOTP,
  URLHash: state => state.URLHash,
  slideInDialog: state => state.slideInDialog,
  couponPrizes: state => state.couponPrizes,
  myProfile: state => state.myProfile
};

const requiredLoggedIn = (context, callback) => {
  const loggedIn = context.rootGetters["session/loggedIn"];
  if (!loggedIn) {
    window.LobbyCore.raise(
      window.LobbyCore.Events.showSignInPage,
      { onSuccess: callback },
      { root: true }
    );
  } else {
    callback();
  }
};

/**
 * Actions are used to display the dialogs, and manage things that override the
 * default behaviors like custom signup URLs, etc.
 */
const actions = {
  showAccountHistory(context, displayName) {
    context.dispatch("showDialog", {
      name: "accountHistory",
      data: {
        title: displayName
      }
    });
  },
  showBalanceDetail(context) {
    context.dispatch("showDialog", { name: "balanceDetail" });
  },
  showCompPoints(context) {
    const compPointUrl = context.rootGetters["cashier/compPointSettings"];
    if (compPointUrl.CompPointURL) {
      context.dispatch("showExternalUrl", {
        title: context.rootGetters["stringTable/getStringByKey"]("CompPoints"),
        source: compPointUrl.CompPointURL,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: ""
      });
    } else {
      context.dispatch("showDialog", { name: "compPoints" });
    }
  },
  showDeposit(context, { displayName } = {}) {
    const showDepositPage = () => {
      const isTournamentsOnly =
        context.rootGetters["customizations/isTournamentsOnly"];
      if (isTournamentsOnly) {
        const customCashierUrl =
          context.rootGetters["customizations/cashierUrl"];
        location.href = customCashierUrl;
      } else {
        context.dispatch("showDialog", {
          name: "cashier",
          data: {
            title: displayName,
            options: "depositOptions",
            cashierMode: CDK.CashierModes.Cashier
          }
        });
      }
    };
    requiredLoggedIn(context, showDepositPage);
  },
  showDepositWithRedirect(context, { redirectUrl } = {}) {
    const showDepositWithRedirectPage = () => {
      if (context.rootGetters["customizations/isTournamentsOnly"]) {
        location.href = context.rootGetters["customizations/cashierUrl"];
      } else {
        const params = {
          title: "DepositPage",
          options: "depositOptions",
          cashierMode: CDK.CashierModes.Cashier
        };

        if (redirectUrl && typeof redirectUrl !== "undefined") {
          params.redirectUrl = redirectUrl;
        }

        context.dispatch("showDialog", {
          data: params,
          name: "cashier"
        });
      }
    };
    requiredLoggedIn(context, showDepositWithRedirectPage);
  },
  showWithdraw(context, { displayName } = {}) {
    context.dispatch("showDialog", {
      name: "cashier",
      data: {
        title: displayName,
        options: "withdrawOptions",
        cashierMode: CDK.CashierModes.Withdraw
      }
    });
  },
  showWithdrawWithRedirect(context, { redirectUrl } = {}) {
    const params = {
      title: "WithdrawPage",
      options: "withdrawOptions",
      cashierMode: CDK.CashierModes.Withdraw
    };

    if (redirectUrl && typeof redirectUrl !== "undefined") {
      params.redirectUrl = redirectUrl;
    }

    context.dispatch("showDialog", {
      data: params,
      name: "cashier"
    });
  },
  showReferAFriendInCashier(context) {
    context.dispatch("showDialog", {
      name: "cashier",
      data: {
        title: "DepositPage",
        options: "depositOptions",
        cashierMode: CDK.CashierModes.ReferAFriend
      }
    });
  },
  hideCashier(context) {
    context.dispatch("hideDialog", { name: "cashier" });
  },
  showContactUs(context, displayName) {
    context.dispatch("showDialog", {
      name: "contactUs",
      data: {
        title: displayName
      }
    });
  },
  showReferAFriend(context, displayName) {
    context.dispatch("showDialog", {
      name: "referFriend",
      data: {
        title: displayName
      }
    });
  },
  showExternalUrl(
    context,
    {
      title,
      source,
      HTML,
      onClose,
      onCloseCallback,
      showTitleBar,
      height,
      width
    }
  ) {
    context.dispatch("showDialog", {
      name: "externalUrl",
      data: {
        title,
        source,
        HTML,
        onClose,
        onCloseCallback,
        showTitleBar,
        height,
        width
      }
    });
  },
  hideExternalURL(context) {
    context.dispatch("hideDialog", { name: "externalUrl" });
  },
  showCustomUrl(
    context,
    { title, source, newTab, isSandwichMenuRequest = false }
  ) {
    if (!newTab) {
      context.dispatch("showDialog", {
        name: "customUrl",
        data: { title, source, isSandwichMenuRequest }
      });
    } else {
      const windowReference = window.open();
      const resultURL = CDK.getReplacedURLParameters(source);
      windowReference.location = resultURL;
    }
  },
  getRedirectUrl(context, { customUrl }) {
    try {
      const redirectUrl = new URL(customUrl);
      redirectUrl.searchParams.set(
        "returnUrl",
        window.location.href + context.state.URL.hash
      );
      context.commit("updateURLHash", "");
      return redirectUrl.toString();
    } catch (err) {
      window.console.warn(err.message);
    }
  },
  showGameLauncher(context, { gameId, gameLauncherParams = {} }) {
    const forcedParams = context.rootGetters["session/forcedGameParams"];
    context.dispatch("showDialog", {
      name: "gameLauncher",
      data: {
        gameId,
        gameLauncherParams: { ...gameLauncherParams, ...forcedParams }
      }
    });
  },
  exitGame(context) {
    context.dispatch("hideDialog", { name: "gameLauncher" });
  },
  showGamesGuru(context, { gameId, balanceChanged, adminOption }) {
    if (context.getters.gamesGuru.isVisible) {
      context.dispatch("hideDialog", { name: "gamesGuru" });
    }
    setTimeout(
      () =>
        context.dispatch("showDialog", {
          name: "gamesGuru",
          data: { gameId, balanceChanged, adminOption }
        }),
      0.001
    );
  },
  showSearch(context, displayName) {
    context.dispatch("showDialog", {
      name: "search",
      data: {
        title: displayName
      }
    });
  },
  async showSignIn(
    context,
    { onSuccess, login, password, confirmMessage, requestedFromParam, displayName } = {}
  ) {
    if (customScriptHandlers.logIn) {
      return customScriptHandlers.logIn();
    }
    const componentUrl = context.rootGetters["lobbyComponents/componentUrl"];
    const customUrl = componentUrl("Custom Login Form");
    if (customUrl) {
      const retUrl = await context.dispatch("getRedirectUrl", {
        customUrl: customUrl
      });
      if (retUrl) location.href = retUrl;
    } else {
      context.dispatch("showDialog", {
        name: "signIn",
        data: { onSuccess, login, password, confirmMessage, requestedFromParam, title: displayName }
      });
    }
  },
  hideSignIn(context) {
    context.dispatch("hideDialog", { name: "signIn" });
  },
  async showSignUp(
    context,
    { displayName } = {}
  ) {
    context.dispatch("isSignUpDialogOpened", true);
    if (customScriptHandlers.signUp) {
      return customScriptHandlers.signUp();
    }

    const componentUrl = context.rootGetters["lobbyComponents/componentUrl"];
    const customUrl = componentUrl("Custom Signup Wizard Form");
    if (customUrl) {
      const retUrl = await context.dispatch("getRedirectUrl", {
        customUrl: customUrl
      });
      if (retUrl) location.href = retUrl;
    } else {
      context.dispatch("showDialog", {
        name: "signUp",
        data: {
          title: displayName
        }
      });
    }
  },
  hideSignUp(context) {
    context.dispatch("hideDialog", { name: "signUp" });
  },
  async showForgotPassword(context, { login, email }) {
    const componentUrl = context.rootGetters["lobbyComponents/componentUrl"];
    const customUrl = componentUrl("Custom Forgot Password Form");
    if (customUrl) {
      const retUrl = await context.dispatch("getRedirectUrl", {
        customUrl: customUrl
      });
      if (retUrl) location.href = retUrl;
    } else {
      context.dispatch("showDialog", {
        name: "forgotPassword",
        data: { login, email }
      });
    }
  },
  async showForgotUsername(context) {
    const componentUrl = context.rootGetters["lobbyComponents/componentUrl"];
    const customUrl = componentUrl("Custom Forgot Username Form");
    if (customUrl) {
      const retUrl = await context.dispatch("getRedirectUrl", {
        customUrl: customUrl
      });
      if (retUrl) location.href = retUrl;
    } else {
      context.dispatch("showDialog", { name: "forgotUsername" });
    }
  },
  showChangePassword(context, { login, email, confirmMessage, securityCode }) {
    context.dispatch("showDialog", {
      name: "changePassword",
      data: { login, email, confirmMessage, securityCode }
    });
  },
  async showCoupons(context, { couponCode, displayName } = {}) {
    const componentUrl = context.rootGetters["lobbyComponents/componentUrl"];
    const customUrl = componentUrl("Custom Coupon Page");
    if (customUrl) {
      const retUrl = await context.dispatch("getRedirectUrl", {
        customUrl: customUrl
      }); // may need replacePlaceholders() in CouponJS in Spark - HB
      if (retUrl) location.href = retUrl;
    } else {
      if (clientType === CDK.ClientTypes.InstantPlay) {
        context.dispatch("showDialog", {
          name: "cashier",
          data: {
            couponCode: couponCode,
            title: displayName,
            options: "couponOptions",
            cashierMode: CDK.CashierModes.Promotions
          }
        });
      } else {
        context.dispatch("showDialog", {
          name: "coupons",
          data: { couponCode, title: displayName }
        });
      }
    }
  },
  showVerifyEmail(context) {
    context.dispatch("showDialog", { name: "verifyEmail" });
  },
  showRedeemCoupon(context, { couponCode }) {
    const showRedeemCouponPage = () => {
      if (clientType !== CDK.ClientTypes.InstantPlay) {
        context.dispatch("showDialog", {
          name: "coupons",
          data: { couponCode }
        });
      } else {
        const cashierMode = couponCode
          ? CDK.CashierModes.Coupons
          : CDK.CashierModes.Promotions;

        context.dispatch("showDialog", {
          name: "cashier",
          data: {
            couponCode: couponCode,
            title: "CouponPage",
            options: "couponOptions",
            cashierMode: cashierMode
          }
        });
      }
    };

    requiredLoggedIn(context, showRedeemCouponPage);
  },
  showChooseMultiGame(context) {
    context.dispatch("showDialog", { name: "chooseMultiGame" });
  },
  showFreeSpinNotification(context) {
    context.dispatch("showDialog", { name: "freeSpinNotification" });
  },
  showCompletedFreeSpin(context) {
    context.dispatch("showDialog", { name: "completedFreeSpin" });
  },
  async showLiveChat(context, { location, displayName }) {
    if (customScriptHandlers.liveChat) {
      return customScriptHandlers.liveChat();
    }
    const liveChatInfo = context.rootGetters["session/liveChatInfo"];

    if (liveChatInfo.OpenInNewTab === true) {
      const windowReference = window.open();
      await CDK.getLobbyCoreLiveChatUrl(location).then(function (liveChatUrl) {
        windowReference.location = liveChatUrl.Url;
      });
    } else {
      store.dispatch("session/showCustomUrlWithHistory", {
        show: true,
        displayName: displayName
      });
    }
  },

  showMessageCenterList(context) {
    context.dispatch("showDialog", { name: "messageCenterList" });
  },
  showTournamentsLobby(context, displayName) {
    context.dispatch("showDialog", { name: "tournamentsLobby", data: { title: displayName } });
  },
  showMessageCenterMessage(context, { id, title = "", isForceOnExit = false }) {
    const data = { id, title, isForceOnExit };
    context.dispatch("showDialog", { name: "messageCenterMessage", data });
  },
  showGameDetail(context, { id }) {
    context.dispatch("showDialog", { name: "gameDetail", data: { id } });
  },
  showSlideInDialog(
    context,
    {
      title,
      titleColor = null,
      text,
      textColor = null,
      backgroundImage = null,
      backgroundColor = null,
      timeoutMs = 5000, // TODO: make this configurable
      showCasinoLogo = false,
      onCloseCallback = null
    }
  ) {
    context.dispatch("showDialog", {
      name: "slideInDialog",
      data: {
        title,
        titleColor,
        text,
        textColor,
        backgroundImage,
        backgroundColor,
        timeoutMs,
        showCasinoLogo,
        onCloseCallback
      }
    });
  },
  showAdminPlayerMessage(context) {
    context.dispatch("showDialog", { name: "adminPlayerMessage" });
  },
  openWebsiteInNewWindow(context, { url }) {
    window.open(url);
  },
  showSuccess(context, { successMessage, scrollFix = true }) {
    const data = { successMessage };
    context.dispatch("showDialog", { name: "success", data, scrollFix });
    setTimeout(() => {
      context.dispatch("hideSuccess", { scrollFix });
    }, 1000);
  },
  hideSuccess(context, { scrollFix }) {
    context.dispatch("hideDialog", { name: "success", scrollFix });
  },
  showBuyIn(context, { tournament }) {
    const data = { tournament };
    context.dispatch("showDialog", { name: "buyIn", data, scrollFix: false });
  },
  showTournamentReBuyAddOn(context, { tournament, actionType }) {
    const data = { tournament, actionType };
    context.dispatch("showDialog", {
      name: "tournamentReBuyAddOn",
      data,
      scrollFix: false
    });
  },
  showRefund(context, { tournament }) {
    const data = { tournament };
    context.dispatch("showDialog", {
      name: "refund",
      data,
      scrollFix: false
    });
  },
  showCasinoJackpot(context, displayName) {
    context.dispatch("showDialog", {
      name: "casinoJackpot",
      data: {
        title: displayName === undefined || displayName === "" ? context.rootGetters["stringTable/getStringByKey"]("LobbyJackpot") : displayName
      }
    });
  },
  showCasinoJackpotWonPrize(context) {
    context.dispatch("showDialog", {
      name: "casinoJackpotWonPrize",
      data: {
        title: context.rootGetters["stringTable/getStringByKey"]("LobbyJackpot")
      }
    });
  },
  showSortAndFilters(context, { statusDisabled } = false) {
    context.dispatch("showDialog", {
      name: "sortAndFilters",
      data: { statusDisabled }
    });
  },
  showTournamentReminder(context, { tournaments }) {
    context.commit("updateDialogData", {
      name: "tournamentReminder",
      data: { tournaments }
    });
    context.dispatch("showDialog", {
      name: "tournamentReminder",
      scrollFix: false
    });
  },
  showPendingGames(context, { showForSingleMode, pendingGames }) {
    const data = { showForSingleMode, pendingGames };
    context.dispatch("showDialog", { name: "pendingGames", data });
  },
  showDialog(context, { name, data, scrollFix = true }) {
    context.commit("updateDialog", {
      name,
      isVisible: true,
      data: data,
      scrollFix
    });
  },
  isSignUpDialogOpened(context, isOpened) {
    context.commit("updateOpenedSignUp", isOpened);
  },
  showActiveCouponMessage(context, { title, source }) {
    context.dispatch("showDialog", {
      name: "activeCoupon",
      data: { title, source }
    });
  },
  updateDialogData(context, { name, data }) {
    context.commit("updateDialogData", { name, data });
  },
  confirmLoadCashier(context, { url, width, height }) {
    context.dispatch("showDialog", {
      name: "confirmLoadCashier",
      data: { url, width, height }
    });
  },
  confirmLoadGame(context, { url, width, height, title }) {
    context.dispatch("showDialog", {
      name: "confirmLoadGame",
      data: { url, width, height, title }
    });
  },
  showPromotions(context, { displayName } = {}) {
    context.dispatch("showExternalUrl", {
      title: displayName,
      source: context.rootGetters["lobbyComponents/componentUrl"](
        "*Menu/Lobby-Promotions"
      ),
      HTML: null,
      onClose: window.LobbyCore.Events.closePromotionsPage,
      showTitleBar: true,
      height: "",
      width: ""
    });
  },
  showPrivacyPolicy(context, displayName) {
    context.dispatch("showExternalUrl", {
      title: displayName !== null || displayName.trim() !== "" ? displayName :
        context.rootGetters["stringTable/getStringByKey"]("PrivacyPolicyLink"),
      source: context.rootGetters["lobbyComponents/privacyPolicyURL"]
    });
  },
  showTermsConditions(context, displayName) {
    context.dispatch("showExternalUrl", {
      title: displayName,
      source: context.rootGetters["lobbyComponents/termsConditionsURL"],
      onClose: "",
      HTML: null,
      showTitleBar: true,
      height: "",
      width: ""
    });
  },
  showLegalNotices(context, displayName) {
    context.dispatch("showExternalUrl", {
      title: displayName,
      source: context.rootGetters["lobbyComponents/legalNoticesUrl"],
      HTML: null,
      onClose: "",
      showTitleBar: true,
      height: "",
      width: ""
    });
  },
  hideDialog(context, { name, scrollFix = true }) {
    context.commit("updateDialog", { name, isVisible: false, scrollFix });
  },
  hideAll(context) {
    context.commit("hideAllDialogs");
  },
  showEmailVerification(context, { login, password, hash = "", playerData = null }) {
    context.dispatch("showDialog", {
      name: "emailVerification",
      data: { login, password, hash, playerData }
    });
  },
  hideEmailVerification(context) {
    context.dispatch("hideDialog", { name: "emailVerification" });
  },
  showResendOTPVerificationEmail(context) {
    context.dispatch("showDialog", { name: "resendOTPVerificationEmail" });
  },
  hideResendOTPVerificationEmail(context) {
    context.dispatch("hideDialog", { name: "resendOTPVerificationEmail" });
  },
  showConfirmOTP(context, { optResult, login, password }) {
    context.dispatch("showDialog", {
      name: "confirmOTP",
      data: { optResult, login, password }
    });
  },
  hideConfirmOTP(context) {
    context.dispatch("hideDialog", { name: "confirmOTP" });
  },
  updateURLHash(context, hash) {
    context.commit("updateURLHash", hash);
  },
  showMyProfile(context, displayName) {
    context.dispatch("showDialog", {
      name: "myProfile",
      data: {
        title: displayName
      }
    });
  },
  hideMyProfile(context) {
    context.dispatch("hideDialog", { name: "myProfile" });
  },
  showTournamentsCouponPrizes(context) {
    context.dispatch("showDialog", { name: "couponPrizes" });
  },
  hideTournamentsCouponPrizes(context) {
    context.dispatch("hideDialog", { name: "couponPrizes" });
  }
};

const mutations = {
  hideAllDialogs(state) {
    Object.keys(state).forEach(dlgName => {
      state[dlgName].isVisible = false;
    });
  },
  updateDialog(state, { name, isVisible, data, scrollFix }) {
    if (state.hasOwnProperty(name)) {
      state[name].isVisible = isVisible;
      state[name].scrollFix = scrollFix;
      if (data) state[name].data = data;
    } else {
      window.console.warn("dialog.js: Dialog not found! Name = " + name);
    }
  },
  updateOpenedSignUp: (state, openedSignUpDialog) => {
    state.openedSignUpDialog.isVisible = openedSignUpDialog;
  },
  updateDialogData(state, { name, data }) {
    if (state.hasOwnProperty(name)) {
      if (data) state[name].data = data;
    } else {
      window.console.warn("dialog.js: Dialog not found! Name = " + name);
    }
  },
  updateURLHash: (state, hash) => {
    state.URL.hash = hash;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
