<template>
  <v-card color="tournamentDetail">
    <v-card-title
      class="tournamentSuccess--text text-uppercase font-weight-bold"
      style="flex-wrap: nowrap; word-break: break-word;"
      ><v-icon color="tournamentSuccess" x-large class="ml-n2 mr-2"
        >mdi-checkbox-outline</v-icon
      >
      {{ title }}</v-card-title
    >
    <v-card-subtitle
      class="tournamentDetailTxt--text text-left pt-2 pb-0 font-weight-bold"
      >{{ tournament.name }}</v-card-subtitle
    >
    <v-card-text class="tournamentDetailTxt--text py-1">
      <v-container>
        <v-row align="center">
          <v-col class="px-0 d-flex justify-center" md="7" cols="5">
            <v-img
              :max-width="isSquareIcon ? '100%' : '150px'"
              class="rounded"
              :src="gameIcon(tournament.game)"
              :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
              contain
            ></v-img
          ></v-col>
          <v-col class="px-0">
            <v-container class="pr-0">
              <v-row dense justify="end">
                <v-col cols="6" class="font-weight-bold text-uppercase">{{
                  $str("DetailViewCost")
                }}</v-col>
                <v-col cols="6" class="px-0"
                  ><v-chip
                    class="justify-end"
                    label
                    color="tournamentInfo tournamentInfoTxt--text"
                    >{{ $tournamentCur(action.details.Cost.value) }}</v-chip
                  ></v-col
                >
                <v-col cols="6" class="font-weight-bold text-uppercase">{{
                  $str("Credits")
                }}</v-col>
                <v-col cols="6" class="px-0"
                  ><v-chip
                    class="justify-end"
                    label
                    color="tournamentInfo tournamentInfoTxt--text"
                    >{{ action.details.Credits.value }}</v-chip
                  ></v-col
                >
                <v-col cols="6" class="font-weight-bold text-uppercase">{{
                  $str("DetailViewTime")
                }}</v-col>
                <v-col cols="6" class="px-0"
                  ><v-chip
                    class="justify-end"
                    label
                    color="tournamentInfo tournamentInfoTxt--text"
                    >{{ action.details.Time.value }}</v-chip
                  ></v-col
                >
              </v-row>
              <v-row v-if="tournament.Mode === 2" dense justify="end">
                <v-col cols="6" class="font-weight-bold text-uppercase">{{
                  $str("CreditsToQualify")
                }}</v-col>
                <v-col cols="6" class="px-0"
                  ><v-chip
                    class="justify-end"
                    label
                    color="tournamentInfo tournamentInfoTxt--text"
                    >{{ action.details.CreditsQualify.value }}</v-chip
                  ></v-col
                >
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container></v-card-text
    >
    <slot></slot>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tournament: { type: Object, required: true },
    action: { type: Object, required: true },
    title: { type: String, required: true }
  },
  data() {
    return {
      name: "tournamentReminder"
    };
  },
  computed: {
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  }
};
</script>
<style scoped>
.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 14px;
  min-height: 44px;
  white-space: normal;
}
</style>
