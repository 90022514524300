export default {
  computed: {
    $getColorByKey() {
      const themes = this.$vuetify.theme.themes;
      return key =>
        themes[this.$mode][key] ? themes[this.$mode][key] : "#CECECE";
    },
    $mode() {
      return this.$vuetify.theme.isDark ? "dark" : "light";
    }
  }
};
