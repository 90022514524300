/**
 * Attach a Lobby Custom Script to the DOM, if configured
 *
 * @see: Casino Parameter "Lobby.CustomScript"
 */

import { casinoConnected } from "./connection";

/**
 * Remove the <script> tags from an html string, returning the inner text only
 * ex:
 *   "<script>console.log('hello world')</script>"
 * returns:
 *   "console.log('hello world')"
 */
const getScriptText = customScript => {
  const parsedDoc = new DOMParser().parseFromString(customScript, "text/html");
  const parsedScript = parsedDoc.getElementsByTagName("script");
  return Array.from(parsedScript);
};
/**
 * Create a new script element from an html string
 * ex: "<script>console.log('hello world')</script>"
 */
const createScriptElement = scriptText => {
  const script = document.createElement("script");
  if (scriptText.src) {
    script.src = scriptText.src;
  }
  script.appendChild(document.createTextNode(scriptText.textContent));
  return script;
};

export const attachCustomScript = async () => {
  await casinoConnected;
  const customScript = CDK.getLobbyCustomScript();
  if (customScript) {
    try {
      const parsedScript = getScriptText(customScript);
      parsedScript.forEach(element => {
        const scriptElement = createScriptElement(element);
        document.body.appendChild(scriptElement);
      });
    } catch (e) {
      window.console.error(`Unable to attach Lobby Custom Script: ${e}`);
    }
  }
};
