/**
 * External lobby data, if configured on the casino
 */

import Vue from "vue";
import { casinoConnected } from "@/services/casino";

const state = {
  lobbies: {},
  displayName: "",
  isVisible: false,
  externalLobbyKey: ""
};

const externalGamesKey = "__ExternalGames__";
const liveDealerKey = "LiveDealer";

const uniqueKeys = [externalGamesKey, liveDealerKey];

const getters = {
  lobbies: state => state.lobbies,
  displayName: state => state.displayName,
  isVisible: state => state.isVisible,
  externalLobbyKey: state => state.externalLobbyKey
};

const actions = {
  async initialize({ commit, dispatch }) {
    await casinoConnected;
    CDK.getMenuData().then(menus => {
      dispatch("setExternalLobbies", { menus });
    });

    // CDK will let us know when the visibility of the external
    //  lobbies is changed
    CDK.on(CDK.Events.externalLobbiesUpdated, items => {
      // Update visibility on the external lobbies component
      commit(
        "updateVisibility",
        items.some(item => item.IsVisible)
      );
      // Update visibility on each lobby item
      items.forEach(item => {
        commit("updateLobbyVisibility", item);
      });
    });
  },
  setExternalLobbies(context, { menus }) {
    // External lobbies are sub menu items of that menu
    const extGamesMenu = menus.filter(({ uniqueId }) =>
      uniqueKeys.includes(uniqueId)
    );
    if (extGamesMenu) {
      extGamesMenu.forEach(extGameMenu => {
        if (extGameMenu.subMenuItems?.length > 0) {
          extGameMenu.subMenuItems?.forEach(lobby => {
            context.commit("addLobby", lobby);
          });
        } else {
          context.commit("addLobby", extGameMenu);
        }
      });
      let extGameMenu = extGamesMenu[0];
      if (extGameMenu) {
        if (extGamesMenu.length > 1)
          extGameMenu = extGamesMenu.find(
            ({ uniqueId }) => uniqueId === externalGamesKey
          );
        context.commit("updateDisplayName", extGameMenu.displayName);
        context.commit("updateExternalLobbyKey", extGameMenu.uniqueId);
      }
    }
  },
  async launchLobby(context, { externalLobbyId }) {
    const windowReference = window.open();
    CDK.getExternalLobbyUrl(externalLobbyId, true).then(url => {
      windowReference.location = url;
    });
  }
};

const mutations = {
  addLobby(state, lobby) {
    Vue.set(state.lobbies, lobby.uniqueId, {
      displayName: lobby.displayName,
      externalLobbyId: lobby.externalLobbyId,
      uniqueId: lobby.uniqueId,
      isVisible: state.lobbies[lobby.uniqueId]?.isVisible ?? lobby.isVisible
    });
  },
  updateDisplayName(state, name) {
    state.displayName = name;
  },
  updateVisibility(state, isVisible) {
    state.isVisible = isVisible;
  },
  updateLobbyVisibility(state, item) {
    state.lobbies[item.UniqueId].isVisible = item.IsVisible;
  },
  updateExternalLobbyKey(state, key) {
    state.externalLobbyKey = key;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
