<template>
  <v-dialog v-model="showDialog" width="500" persistent>
    <v-card v-if="showDialog" overlay-opacity="0.9" class="elevation-12">
      <v-toolbar color="navbar" flat>
        <v-toolbar-title>{{ $str("MessageTitle") }}</v-toolbar-title>
        <v-icon>{{ iconName }}</v-icon>
        <v-spacer></v-spacer>
        <v-icon id="adminMsgDialogCloseBtn" @click.stop="close"
          >{{
            smallLayout ? " mdi-close-circle" : " mdi-close"
          }}mdi-close-circle</v-icon
        >
      </v-toolbar>
      <v-card-text class="pa-4">
        {{ message.text }}
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="message.buttons.includes('yes')" @click="accept(4)">{{
          $str("YesLabel")
        }}</v-btn>
        <v-btn v-if="message.buttons.includes('no')" @click="close(8)">{{
          $str("NoLabel")
        }}</v-btn>
        <v-btn v-if="message.buttons.includes('ok')" @click="accept(1)">{{
          $str("OKButton")
        }}</v-btn>
        <v-btn v-if="message.buttons.includes('cancel')" @click="close(2)">{{
          $str("Cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions } from "vuex";
import { LaunchLobbyAction } from "@/services/casino";

const iconNames = {
  [CDK.AdminPlayerMessageIcon.info]: "mdi-information",
  [CDK.AdminPlayerMessageIcon.question]: "mdi-help",
  [CDK.AdminPlayerMessageIcon.warning]: "mdi-alert",
  [CDK.AdminPlayerMessageIcon.error]: "mdi-alpha-x-circle"
};

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "adminPlayerMessage"
    };
  },
  computed: {
    message() {
      return this.$store.getters["adminPlayerMessage/currentMessage"];
    },
    iconName() {
      return iconNames[this.message.icon];
    }
  },
  watch: {
    message(newMessage) {
      if (newMessage) {
        this.showAdminPlayerMessage();
      }
    }
  },
  methods: {
    ...mapActions("dialogs", ["showAdminPlayerMessage"]),
    ...mapActions("adminPlayerMessage", ["messageClosed"]),
    accept(response) {
      this.executeAction();
      this.close(response);
    },
    executeAction() {
      const action = this.message.action;
      if (action?.type) {
        LaunchLobbyAction(this.$store, action.type, action.params);
      }
    },
    close(response) {
      this.showDialog = false;
      this.playerMessageResponse(response);
    },
    playerMessageResponse(response) {
      this.messageClosed({
        messageId: this.message.messageId,
        playerResponse: response,
        masterId: this.message.masterId
      });
    }
  }
};
</script>
