<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background:new 0 0 24 24;"
    xml:space="preserve"
  >
    <g>
      <path
        :fill="iconColor"
        d="M13.5,7c-.229,0-.43,.156-.485,.379l-.525,2.103-2.102,.485c-.224,.052-.384,.249-.388,.479s.148,.432,.37,.491l2.116,.569,.528,2.115c.056,.223,.256,.379,.485,.379s.43-.156,.485-.379l.526-2.109,2.109-.526c.223-.056,.379-.256,.379-.485s-.156-.43-.379-.485l-2.109-.526-.526-2.109c-.056-.223-.256-.379-.485-.379Zm10.186-2.964l-2.658-1.063-1.063-2.658c-.15-.381-.777-.381-.928,0l-1.062,2.653-2.651,1.003c-.191,.072-.319,.254-.323,.459-.004,.204,.117,.391,.306,.47l2.665,1.123,1.065,2.663c.075,.19,.26,.314,.464,.314s.389-.124,.464-.314l1.063-2.658,2.658-1.063c.19-.075,.314-.26,.314-.464s-.124-.389-.314-.464Zm-3.229,1.143c-.127,.051-.228,.151-.278,.278l-.679,1.696-.679-1.696c-.049-.124-.146-.224-.27-.275l-1.705-.718,1.688-.639c.131-.05,.235-.152,.287-.282l.679-1.696,.679,1.696c.051,.127,.151,.228,.278,.278l1.696,.679-1.696,.679Zm.472,13.632l-.663-.552c1.767-2.008,2.735-4.563,2.735-7.258,0-.888-.105-1.769-.314-2.619-.065-.268-.331-.432-.604-.366-.269,.065-.433,.336-.366,.604,.189,.772,.285,1.573,.285,2.381,0,2.633-1.015,5.118-2.857,6.999H4.859c-1.845-1.879-2.859-4.356-2.859-6.999C2,5.486,6.486,1,12,1c1.492,0,2.927,.32,4.264,.952,.249,.115,.548,.012,.666-.238s.011-.548-.238-.666c-1.473-.695-3.051-1.048-4.691-1.048C5.935,0,1,4.935,1,11c0,2.704,.965,5.251,2.734,7.259l-.663,.552c-1.002,.835-1.723,1.995-2.028,3.268-.112,.467-.006,.952,.293,1.33,.296,.376,.74,.592,1.219,.592H21.445c.479,0,.923-.216,1.219-.592,.299-.378,.405-.863,.293-1.331-.306-1.271-1.026-2.432-2.028-3.267Zm.95,3.979c-.105,.134-.264,.211-.434,.211H2.555c-.17,0-.328-.077-.434-.211-.107-.136-.146-.311-.105-.478,.255-1.064,.857-2.034,1.696-2.733l.694-.578h15.188l.694,.578c.839,.699,1.441,1.669,1.696,2.732,.04,.168,.002,.343-.105,.479Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "GamesGuruIcon",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "GamesGuruIcon" }
  }
};
</script>
