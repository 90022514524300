import { mapGetters } from "vuex";
import { dialogToggle } from "@/mixins/dialogToggle";

export default {
  mixins: [dialogToggle],

  data() {
    return {
      name: "search",
      search: null
    };
  },
  computed: {
    ...mapGetters("games", ["gameSearchData", "gameIsExcluded"]),
    ...mapGetters("customizations", ["lazyLoadingImage"])
  },
  methods: {
    searchGames() {
      const results = [];

      if (this.search) {
        const searchString = this.search.toLowerCase();
        let match = false;
        this.gameSearchData.forEach(game => {
          match = game.name.toLowerCase().includes(searchString);
          if (!match && game.familyNames) {
            game.familyNames.forEach(family => {
              match =
                family.toLowerCase().includes(searchString) &&
                family.toLowerCase() !== "favorites"
                  ? true
                  : match;
            });
          }
          if (!match && game.tagLabels) {
            game.tagLabels.forEach(label => {
              if (!match && label.toLowerCase().includes(searchString)) {
                match = true;
              }
            });
          }

          if (match) results.push(game);
        });
      }
      return results.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
    },
    gameAndFamilyFilter(game, queryText) {
      const searchText = queryText.toLowerCase();
      const gameName = game.name.toLowerCase();
      const gameFamilyNames = game.familyNames.map(familyName =>
        familyName.toLowerCase()
      );
      const labels = game.tagLabels.map(label => label.toLowerCase());

      return (
        gameName.includes(searchText) ||
        gameFamilyNames.filter(familyName => familyName.includes(searchText))
          .length > 0 ||
        labels.filter(label => label.includes(searchText)).length > 0
      );
    },
    compareGames(a, b) {
      const aExcluded = this.gameIsExcluded[a.gameId];
      const bExcluded = this.gameIsExcluded[b.gameId];
      if (!aExcluded && bExcluded) {
        return -1;
      } else if (aExcluded && !bExcluded) {
        return 1;
      } else {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }
    },
    launchGame(game) {
      const isExternalGame = game.isExternalGame;
      const externalLobbyId = game.externalLobbyId;
      const gameId = game.gameId;
      const openAndClose = () => {
        this.$store.dispatch("dialogs/hideAll");
        if (isExternalGame) {
          this.$store.dispatch("games/launchGame", { gameId, externalLobbyId });
        } else {
          this.$store.dispatch("games/launchGame", { gameId });
        }
      };
      setTimeout(() => openAndClose(), 1);
    },
    onHidden() {
      this.search = "";
    }
  }
};
