<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <path
      d="M34.13,40.58V34.8a8.25,8.25,0,0,0-2.43-5.86l-5.86-5.86,5.86-5.86a8.27,8.27,0,0,0,2.43-5.86V7.42"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M13.87,7.42v3.94a8.27,8.27,0,0,0,2.43,5.86l5.86,5.86L16.3,28.94a8.25,8.25,0,0,0-2.43,5.86v5.78"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M36.89,2.82H11.11V5.58A1.84,1.84,0,0,0,13,7.42h22.1a1.84,1.84,0,0,0,1.84-1.84Z"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M35.05,40.58H13a1.84,1.84,0,0,0-1.84,1.84v2.76H36.89V42.42A1.84,1.84,0,0,0,35.05,40.58Z"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M30.45,38.73c0-1.84-6.45-4.6-6.45-4.6s-6.45,2.76-6.45,4.6"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <line
      x1="24"
      y1="25.84"
      x2="24"
      y2="27.68"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <line
      x1="24"
      y1="29.53"
      x2="24"
      y2="31.37"
      fill="none"
      stroke="#fff"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
  </svg>
</template>

<script>
export default {
  name: "CountDownIcon",
  props: {
    iconStroke: {
      type: String,
      default: "currentColor"
    },
    displayName: { type: String, default: "CountDownIcon" }
  }
};
</script>
