<template>
  <div style="width: fit-content;display: inline-table;">
    <v-menu
      v-if="loggedIn"
      id="walletMenu"
      v-model="isOpened"
      offset-y
      content-class="balanceMenu"
      :close-on-content-click="false"
      @change="toggleScroll"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="walletBtn"
          value="wallet"
          max-width="390px"
          class="align-self-center"
          color="button buttonTxt--text"
          :class="isOpened ? 'openMenu' : ''"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small :color="btnTxtColor">$Deposit</v-icon>
          <span id="mainViewBalanceAmount" class="pl-1">{{
            isTournaments
              ? getBaseCur(withdrawable)
              : getCur(balance)
          }}</span>
        </v-btn>
      </template>
      <v-card color="body">
        <v-container class="pt-0">
          <v-card-text class="pa-1">
            <v-row v-if="!isTournaments">
              <v-col class="col-5"
                ><h2>
                  <strong>{{ $str("PlayableBalance") }}</strong>
                  <strong v-if="!playForMoney"
                    >({{ $str("FunBalance") }})</strong
                  >:
                </h2></v-col
              >
              <v-col class="col-7 right px-0">
                <h2 class="px-0">
                  <strong>{{ getCur(balance) }}</strong>
                </h2>
                <div class="mr-3">
                  <v-btn
                    class="rounded-1"
                    color="button buttonTxt--text"
                    @click="openDeposit"
                    >{{ $str("DepositPage") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row v-if="playForMoney">
              <v-col class="col-6">{{ $str("WithdrawableBalance") }}: </v-col>
              <v-col class="col-6 right">
                {{
                  isTournaments
                    ? getBaseCur(withdrawable)
                    : getCur(withdrawable)
                }}
              </v-col>
            </v-row>
            <v-row v-if="playForMoney && activeCoupon && !isTournaments">
              <v-col class="col-5"> {{ $str("BonusBalance") }}: </v-col>
              <v-col class="col-7 right">
                {{ getCur(bonus) }}
              </v-col>
            </v-row>

            <v-row v-if="playForMoney && activeCoupon && !isTournaments">
              <v-col class="col-5">{{ $str("PlaythroughRemaining") }}: </v-col>
              <v-col class="col-7 right">
                {{ getCur(playthrough) }}
              </v-col>
            </v-row>

            <v-row v-if="compPointSettings.ShowCompPoints && playForMoney">
              <v-col class="col-5">{{ $str("CompPoints") }}:</v-col>
              <v-col class="col-7 right">
                {{ $num(compPoints, 0) }}
                <div class="mr-3">
                  <v-btn
                    class="rounded-1"
                    color="button buttonTxt--text"
                    :disabled="!(compPoints > 0) || compPointsDisabled"
                    @click.stop.prevent="shouldShowCompPoints"
                    >{{ $str("Redeem") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row v-if="checkIsCurrencyToDisplayActive(currenciesToDisplay)">
              <v-col align-self="center" class="col-5">
                {{ $str("FiatEquivalent") }}:
              </v-col>
              <v-col class="col-7 right">
                <div class="fiatsContainer">
                  <v-select
                    class="fiatsSelectBox"
                    outlined
                    :items="formatDropdownCurrencies(currenciesToDisplay)"
                    :value="
                      defaultCurrencyToDisplay
                        ? defaultCurrencyToDisplay.Value
                        : null
                    "
                    @change="handleCurrencyToDisplay"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
        <ConfirmDialog ref="errorDialog" />
      </v-card>
    </v-menu>
    <v-overlay v-if="isOpened && loggedIn" class="customOverlay"></v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dialogToggle } from "@/mixins/dialogToggle";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle],
  props: {
    btnTxtColor: { type: String, required: false, default: undefined },
    iconColor: { type: String, required: false, default: undefined },
    isTournaments: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      name: "balanceDetail",
      isOpened: false
    };
  },
  computed: {
    ...mapGetters("coupons", ["activeCoupon"]),
    ...mapGetters("balances", [
      "balance",
      "withdrawable",
      "bonus",
      "playthrough",
      "compPoints",
      "compPointsDisabled",
      "currenciesToDisplay",
      "defaultCurrencyToDisplay"
    ]),
    ...mapGetters("session", ["loggedIn", "playForMoney"]),
    ...mapGetters("cashier", ["compPointSettings", "isCashierEnabled"]),
    toggleScroll() {
      if (this.isOpened) {
        document.documentElement.style.overflow = "hidden";
        return false;
      } else {
        document.documentElement.style.overflow = "auto";
        return true;
      }
    }
  },
  methods: {
    ...mapActions("dialogs", ["showCompPoints"]),
    ...mapActions("balances", ["updatePlayerCurrency"]),
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    async shouldShowCompPoints() {
      const canRedeemCompPoints = await CDK.getCanRedeemCompPoints();

      if (canRedeemCompPoints) {
        this.isOpened = false;
        this.showCompPoints();
      } else {
        const message = await CDK.getCannotRedeemCompPointsMessage();
        this.$refs.errorDialog.openAlert({
          title: this.$strResourceService("Alert"),
          message
        });
      }
    },
    getBaseCur(amount){
      return this.$cur(amount);
    },
    getCur(amount) {
      if (this.isTournaments) {
        return this.$tournamentCur(amount, true);
      }
      return this.$cur(amount);
    },
    checkIsCurrencyToDisplayActive(currencies) {
      return currencies.length > 0;
    },
    formatDropdownCurrencies(currencies) {
      return currencies.map(currencyInfo => currencyInfo.Value);
    },
    handleCurrencyToDisplay(currencyToUpdate) {
      this.updatePlayerCurrency(currencyToUpdate);
    }
  }
};
</script>

<style scoped lang="scss">
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}

.balanceMenu {
  border-radius: 0 0 10px 10px !important;

  /* Prevent scrollbar that appears when Fiat dropdown is displayed and there is a blank
     space below the menu that creates a scrollbar */
  overflow: hidden;
}

.openMenu {
  height: 80px !important;
  background-image: linear-gradient(
    0deg,
    var(--v-body-base) 10%,
    var(--v-body-lighten4) 100%
  ) !important;
  z-index: 200;
  border-radius: 10px 10px 0 0 !important;
  color: #fff !important;
}

.customOverlay {
  width: 1000vw;
  height: 1000vh;
  margin-left: -40vw;
  margin-top: -50px;
  overflow: hidden;
}
*,
span {
  font-weight: 500;
}
strong {
  font-weight: 700;
}

.fiatsContainer {
  display: flex;
  justify-content: flex-end;
}

.fiatsSelectBox {
  max-width: 120px;
  max-height: 3.8rem;
}

#walletBtn:before,
#walletBtn:focus,
#walletBtn:focus::before,
#walletBtn:hover::before,
#walletBtn:hover {
  opacity: 1 !important;
  background: none;
}
.right {
  text-align: right;
}

.v-btn--contained {
  box-shadow: none;
}

@media screen and (min-width: 1150px) {
  .balanceIcon {
    width: 2.7rem !important;
    height: 2.7rem !important;
    max-width: 40px;
  }
  #walletBtn {
    max-height: 40px;
    min-height: 40px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }
  .balanceMenu {
    * {
      font-size: 1rem;
    }
    h3 strong {
      font-size: 1.1rem !important;
    }
    button {
      font-size: 0.8rem !important;
      height: 26px !important;
      min-width: 50px !important;
      padding: 0 12.4444444444px !important;
    }
    .v-card {
      min-width: 370px !important;
    }
  }
}
@media screen and (max-width: 1149px) and (min-width: 801px) {
  .balanceIcon {
    width: 2.1rem !important;
    height: 2.1rem !important;
    max-width: 40px;
  }
  #walletBtn {
    max-height: 40px;
    min-height: 40px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }
  .balanceMenu {
    * {
      font-size: 0.9rem;
    }
    h3 strong {
      font-size: 1rem !important;
    }
    button {
      font-size: 0.7rem !important;
      height: 20px !important;
      min-width: 36px !important;
      padding: 0 8.8888888889px !important;
    }
    .v-card {
      min-width: 350px !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .balanceIcon {
    width: 1.6rem !important;
    height: 1.6rem !important;
    max-width: 40px;
  }
  #walletBtn {
    max-height: 40px;
    min-height: 40px;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }
  .balanceMenu {
    * {
      font-size: 0.7rem;
    }
    h3 strong {
      font-size: 0.8rem !important;
    }
    button {
      font-size: 0.6rem !important;
      height: 20px !important;
      min-width: 36px !important;
      padding: 0 8.8888888889px !important;
    }
    .v-card {
      min-width: 310px !important;
    }
  }
}
</style>
