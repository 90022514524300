var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticStyle:{"text-align":"right"},attrs:{"temporary":"","right":"","app":"","color":"popupBackground","overlay-opacity":"0.9","width":"260","height":"100%"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-layout',{attrs:{"align-center":"","justify-end":""}},[_c('v-btn',{staticClass:"mt-6 mx-4",attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.hide($event)}}},[_c('v-icon',{staticClass:"mr-n1",attrs:{"small":""}},[_vm._v("$Close")])],1)],1),_c('v-list',{staticClass:"sandwichMenu-option",nativeOn:{"click":function($event){return _vm.hide($event)}}},[_vm._l((_vm.sandwichMenu),function(item){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
        ((_vm.loggedIn &&
          item.AfterLogin &&
          (item.SessionGame === 1 ||
            (item.SessionGame === 2 && _vm.playForMoney) ||
            (item.SessionGame === 3 && !_vm.playForMoney)) &&
          _vm.showAditionalValidationAfterLogin(item.Name)) ||
          (!_vm.loggedIn &&
            item.PriorToLogin &&
            _vm.showAditionalValidationBeforeLogin(item.Name))) &&
          (item.ClientType === 10 || item.ClientType === _vm.getClientType())
      ),expression:"\n        ((loggedIn &&\n          item.AfterLogin &&\n          (item.SessionGame === 1 ||\n            (item.SessionGame === 2 && playForMoney) ||\n            (item.SessionGame === 3 && !playForMoney)) &&\n          showAditionalValidationAfterLogin(item.Name)) ||\n          (!loggedIn &&\n            item.PriorToLogin &&\n            showAditionalValidationBeforeLogin(item.Name))) &&\n          (item.ClientType === 10 || item.ClientType === getClientType())\n      "}],key:item.ID,staticClass:"py-0 justify-end",attrs:{"id":("sandwichMenu" + (item.Name.split(' ').join('')) + "Btn")},on:{"click":function($event){item.Type === 6 ? _vm.showOption(item) : _vm.showLink(item)}}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.DisplayName))]),_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getIcon(item)))])],1)}),(_vm.enabledThemesVisible)?_c('v-list-item',{staticClass:"py-0 justify-end",on:{"click":_vm.toggleDarkMode}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$str("DarkMode")))]),_c('v-switch',{staticClass:"ma-0 mr-n4 pa-0 switch",attrs:{"color":"grey","readonly":"","dense":"","inset":""},model:{value:(this.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(this.$vuetify.theme, "dark", $$v)},expression:"this.$vuetify.theme.dark"}})],1):_vm._e(),(_vm.loggedIn)?_c('v-list-item',{staticClass:"py-0 justify-end",on:{"click":_vm.requestSignOut}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$str("LogoutPage")))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("$Logout")])],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }