<template>
  <v-container
    class="pa-0 mx-0"
    style="height: 100%;max-width: 100%;background-color: white;"
    :style="largeLayout ? 'background-color: white;' : ''"
  >
    <v-layout v-if="loading" align-center justify-center fill-height>
      <v-progress-circular color="grey" indeterminate></v-progress-circular>
    </v-layout>
    <iframe
      :id="iframeId"
      :class="largeLayout ? 'iFrameLoaded' : ''"
      src="about:blank"
      :style="largeLayout && isSpecificId ? '' : iframeCss"
      @load="iFrameLoaded"
    />
  </v-container>
</template>

<script>
function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

export default {
  props: {
    id: { type: Number, required: true },
    isSpecificId: { type: Boolean, required: false, default: false },
    height: { type: String, required: false, default: "100%" },
    width: { type: String, required: false, default: "100%" }
  },
  data() {
    return {
      loading: true,
      maxWidth: 800,
      maxHeight: 600
    };
  },
  computed: {
    iframeId() {
      const idBase = "messageFrame";
      return this.isSpecificId ? `${idBase}_${this.id}` : idBase;
    },
    iframeCss() {
      return this.smallLayout
        ? this.isLandscape
          ? `width: 100%; height: 79%;position: absolute;border: none;`
          : `width: 100%; height: 100%;padding-bottom: 79px;position: absolute;border: none;`
        : `    width: ${this.width};height: ${this.height};position: absolute;border: none;`;
    },
    messageCss() {
      // Message frame style copied from MessageLESS.less in SparkLobby2
      return this.largeLayout
        ? `body {
          background: #fff;
          font-family: Arial, sans-serif;
          font-size: 14px;
          color: #646464;
          word-break: break-word;
          margin: 0;
          overflow: overlay;
      }
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent; 
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #08080880; 
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
      ::-webkit-scrollbar-track {
          margin-top: 100px;
          margin-bottom: 100px;

      }`
        : `body {
          background: #fff;
          font-family: Arial, sans-serif;
          font-size: 14px;
          color: #646464;
          word-break: break-word;
          margin: 0;
      }
        body iframe{
          width: 100%;
          height: 100%;
        }
      `;
    },
    messageHtml() {
      return this.$store.getters["messageCenter/playerMessageText"][this.id];
    }
  },
  watch: {
    id() {
      this.$forceUpdate();
      this.loading = true;
      this.iFrameLoaded();
    }
  },
  mounted() {
    window.addEventListener("message", this.listenPostMessage);
  },
  methods: {
    listenPostMessage(event) {
      switch (event.data.type) {
        case "RedeemCoupon":
          window.LobbyCore.raise(window.LobbyCore.Events.showRedeemCouponPage, {
            couponCode: event.data.couponCode
          });
          break;
        case "LaunchGame":
          event.data.gameId = +(event.data.gameId || 0);
          event.data.machId = +(event.data.machId || 0);
          event.data.hands = +(event.data.hands || 0);

          this.$store.dispatch("games/launchGame", {
            gameId:
              event.data.gameId +
              "-" +
              event.data.machId +
              "-" +
              event.data.hands
          });
          break;
        case "OpenCashier":
          window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
          break;
        case "Promotions":
          window.LobbyCore.raise(window.LobbyCore.Events.showPromotionsPage);
          break;
        case "VerifyEmail":
          window.LobbyCore.raise(window.LobbyCore.Events.showVerifyEmailPage);
          break;
        case "EmailVerification":
          window.LobbyCore.raise(
            window.LobbyCore.Events.showEmailVerificationPage
          );
          break;
        case "ResendOTPVerificationEmail":
          window.LobbyCore.raise(
            window.LobbyCore.Events.showResendOTPVerificationEmailPage
          );
          break;
      }
    },
    async iFrameLoaded() {
      if (this.loading) {
        // Trigger request for message text
        await this.$store.dispatch(
          "messageCenter/requestPlayerMessageText",
          this.id
        );

        // Replace in message calls to launch game, go to coupon, and go to cashier.
        const newMsgHtml = this.messageHtml.replace(
          new RegExp(escapeRegExp("javascript:$root."), "g"),
          "javascript:parent.$root."
        );

        // Write message text (html) to the iframe
        const frame = document.getElementById(this.iframeId);
        frame.contentDocument.open("text/html", "replace");
        frame.contentDocument.write(newMsgHtml);

        // Write message styles to the iframe
        const style = frame.contentDocument.createElement("style");
        style.type = "text/css";
        style.appendChild(
          frame.contentDocument.createTextNode(this.messageCss)
        );

        const currentFirstStyle = Array.from(
          frame.contentDocument.head.children
        ).find(x => x.nodeName === "STYLE");

        if (currentFirstStyle) {
          frame.contentDocument.head.insertBefore(style, currentFirstStyle);
        } else {
          frame.contentDocument.head.appendChild(style);
        }

        frame.contentDocument.close();
        this.setHeightAndWeight(frame);
        this.setMessageToFrame(frame);
        // iFrameLoaded will trigger again after writing our content to the frame,
        // so set loading to false so we only do it once
        this.loading = false;
      }
    },

    setMessageToFrame(frame) {
      const functions = frame.contentWindow.document.createElement("script");
      functions.type = "text/javascript";
      functions.text = `  function LaunchGame(gameId, machId, denomination, handCount) {
                            parent.postMessage({
                              "type":"LC-LobbyAction",
                              "action":"LaunchGame", 
                              "params": { gameId: \`\${gameId}-\${machId}-\${handCount}\` },
                              "hideAll": true 
                            }, "${window.location.href}"); 
                          }`;
      functions.text += ` function OpenCashier(coupon) {
                            var action = coupon ? "RedeemCoupon" : "Cashier";
                            parent.postMessage({ 
                              "type":"LC-LobbyAction", 
                              "action":action,
                              "params":{couponCode: coupon, isIP:${
                                this.largeLayout ? "true" : "false"
                              }} 
                            }, "${window.location.href}"); 
                          }`;
      frame.append(functions);
    },

    setHeightAndWeight(frame) {
      const biggerModal = document.querySelector(
        ".v-dialog.v-dialog--active.biggerModal"
      );
      const iFrameLoaded = document.querySelector(".iFrameLoaded");
      if (biggerModal && this.largeLayout) {
        frame.onload = () => {
          const width = Math.min(
            frame.contentDocument.documentElement.scrollWidth,
            this.maxWidth
          );
          const height = Math.min(
            frame.contentDocument.documentElement.scrollHeight,
            this.maxHeight
          );
          biggerModal.style.height = `${height}px`;
          biggerModal.style.width = `${width}px`;
          if (this.isSpecificId) {
            iFrameLoaded.style.width = `${width}px`;
            iFrameLoaded.style.height = `${height}px`;
          }
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog.v-dialog--active.biggerModal iframe {
  width: 100%;
  height: 100%;
}

.v-dialog.v-dialog--active.biggerModal .v-icon.mdi-close {
  color: darkgray;
}

.v-dialog.v-dialog--active.biggerModal {
  border: none !important;

  iframe {
    border: none !important;
  }

  .biggerModal .mdi-close {
    color: #666 !important;
  }
}
</style>
