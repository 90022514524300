/**
 * Helpers exposed globally for Lobby Custom Scripts to use
 */

import { clientType } from "./clientType";

/** Custom handlers to override default lobby behaviors */
const handlers = {
  liveChat: null,
  signUp: null,
  logIn: null,
  customNavBar: [],
  customSandwichMenu: []
};

/** Read-only access to the custom script handlers */
export const customScriptHandlers = {
  get liveChat() {
    return handlers.liveChat;
  },
  get signUp() {
    return handlers.signUp;
  },
  get logIn() {
    return handlers.logIn;
  },
  get customNavBarOptionHandler() {
    return handlers.customNavBar;
  },
  get customSandwichMenuOptionHandler() {
    return handlers.customSandwichMenu;
  }
};

/** Interface between lobby custom scripts and the lobby application */
export const customScriptApi = {
  /** Get the client type
   * @returns {number} CDK.ClientTypes.Download(1), CDK.ClientTypes.InstantPlay(3), or CDK.ClientTypes.Mobile(6)
   */
  getClientType: () => clientType,

  /** Overrides the default behavior of the Live Chat button
   * @param {Function} handler the custom behavior, or _null_ to stop overriding this behavior
   */
  setCustomLiveChatHandler: handler => (handlers.liveChat = handler),

  /** Overrides the default behavior of the Sign Up button
   * @param {Function} handler the custom behavior, or _null_ to stop overriding this behavior
   */
  setCustomSignUpHandler: handler => (handlers.signUp = handler),

  /** Overrides the default behavior of the Log In button
   * @param {Function} handler the custom behavior, or _null_ to stop overriding this behavior
   */
  setCustomLogInHandler: handler => (handlers.logIn = handler),

  /** Overrides the default behavior of any button in navbar
   * @param {Function} handler the custom behavior, or _null_ to stop overriding this behavior
   */
  setCustomNavBarOptionHandler: (key, handler) =>
    handlers.customNavBar.push({ key: key, handler: handler }),

  /** Overrides the default behavior of any button in Sandwich menu
   * @param {Function} handler the custom behavior, or _null_ to stop overriding this behavior
   */
  setCustomSandwichMenuOptionHandler: (key, handler) =>
    handlers.customSandwichMenu.push({ key: key, handler: handler })
};
