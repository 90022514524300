<template>
  <div>
    <ConfirmLoadCashierDialog />
    <ConfirmLoadGameDialog />
    <BalanceDetailSmallDialog v-if="smallLayout" />
    <SignInDialog />
    <ShortSignUpDialog
      v-if="
        signupFormType.toLowerCase() === 'short' ||
          signupFormType.toLowerCase() === 'crypto'
      "
    />
    <LongSignUpDialog v-if="signupFormType.toLowerCase() === 'long'" />
    <GamesGuruDialog />
    <CashierDialog />
    <ContactUsDialog />
    <ReferFriendDialog />
    <ExternalUrlDialog />
    <CustomUrlDialog />
    <CompPointsDialog />
    <ChooseMultiGameDialog />
    <ForgotPasswordDialog />
    <ForgotUsernameDialog />
    <ChangePasswordDialog />
    <CouponDialog />
    <MessageCenterMessageDialog />
    <MessageCenterListDialog />
    <CasinoJackpotWonPrizeDialog />
    <FreeSpinNotificationDialog />
    <CompletedFreeSpinDialog />
    <GameDetailDialog />
    <AdminPlayerMessageDialog />
    <SearchDialog v-if="smallLayout" />
    <SearchSideDialog v-if="largeLayout" />
    <GameLauncherDialog />
    <VerifyEmailDialog />
    <AccountHistoryDialog />
    <ActiveCouponDialog />
    <SuccessDialog />
    <TournamentReBuyAddOnDialog />
    <RefundDialog />
    <SortAndFiltersDialog />
    <TournamentReminderDialog />
    <PendingGamesDialog />
    <TournamentsLobby v-if="largeLayout" />
    <CasinoJackpotDialog />
    <EmailVerificationDialog />
    <ResendOTPVerificationEmailDialog />
    <ConfirmOTPDialog />
    <MyProfileDialog />
    <SlideInDialog />
    <CouponPrizesDialog />
  </div>
</template>

<script>
/**
 * Dialog component container.
 *
 * Dialogs using the dialogToggle mixin must be created before they can be
 * toggled on, so they are added to the DOM here.
 *
 * Tip: To prevent adding too many unnecessary DOM nodes before we need them,
 * the Dialog component should conditionally render most of its content so that
 * it isn't added to the DOM until it needs to be shown.
 * @see https://confluence.its.work/confluence/display/DEV/Vue+Lobby+Best+Practices
 */

import AccountHistoryDialog from "@/components/Dialogs/AccountHistoryDialog";
import BalanceDetailSmallDialog from "@/components/Dialogs/BalanceDetailSmallDialog";
import SignInDialog from "@/components/Dialogs/SignInDialog";
import ShortSignUpDialog from "@/components/Dialogs/ShortSignUpDialog";
import LongSignUpDialog from "@/components/Dialogs/LongSignUpDialog";
import CashierDialog from "@/components/Dialogs/CashierDialog";
import ContactUsDialog from "@/components/Dialogs/ContactUsDialog";
import ReferFriendDialog from "@/components/Dialogs/ReferFriendDialog";
import ExternalUrlDialog from "@/components/Dialogs/ExternalUrlDialog";
import CustomUrlDialog from "@/components/Dialogs/CustomUrlDialog";
import CompPointsDialog from "@/components/Dialogs/CompPointsDialog";
import ChooseMultiGameDialog from "@/components/Dialogs/ChooseMultiGameDialog";
import ForgotPasswordDialog from "@/components/Dialogs/ForgotPasswordDialog";
import ForgotUsernameDialog from "@/components/Dialogs/ForgotUsernameDialog";
import ChangePasswordDialog from "@/components/Dialogs/ChangePasswordDialog";
import CouponDialog from "@/components/Dialogs/CouponDialog";
import MessageCenterListDialog from "@/components/Dialogs/MessageCenterListDialog";
import MessageCenterMessageDialog from "@/components/Dialogs/MessageCenterMessageDialog";
import CompletedFreeSpinDialog from "@/components/Dialogs/CompletedFreeSpinDialog";
import FreeSpinNotificationDialog from "@/components/Dialogs/FreeSpinNotificationDialog";
import GameDetailDialog from "@/components/Dialogs/GameDetailDialog";
import AdminPlayerMessageDialog from "@/components/Dialogs/AdminPlayerMessageDialog";
import SearchDialog from "@/components/Dialogs/SearchDialog";
import SearchSideDialog from "@/components/Dialogs/SearchSideDialog";
import GameLauncherDialog from "@/components/Dialogs/GameLauncherDialog";
import VerifyEmailDialog from "@/components/Dialogs/VerifyEmailDialog";
import ActiveCouponDialog from "@/components/Dialogs/ActiveCouponDialog";
import GamesGuruDialog from "@/components/Dialogs/GamesGuruDialog";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import TournamentReBuyAddOnDialog from "@/components/Dialogs/TournamentReBuyAddOnDialog";
import RefundDialog from "@/components/Dialogs/RefundDialog";
import SortAndFiltersDialog from "@/components/Dialogs/SortAndFiltersDialog";
import TournamentReminderDialog from "@/components/Dialogs/TournamentReminderDialog";
import PendingGamesDialog from "@/components/Dialogs/PendingGamesDialog";
import ConfirmLoadCashierDialog from "@/components/Dialogs/ConfirmLoadCashierDialog";
import ConfirmLoadGameDialog from "@/components/Dialogs/ConfirmLoadGameDialog";
import TournamentsLobby from "@/components/Dialogs/TournamentsLobby";
import CasinoJackpotDialog from "@/components/Dialogs/CasinoJackpotDialog";
import CasinoJackpotWonPrizeDialog from "@/components/Dialogs/CasinoJackpotWonPrizeDialog";
import EmailVerificationDialog from "@/components/Dialogs/EmailVerificationDialog";
import ResendOTPVerificationEmailDialog from "@/components/Dialogs/ResendOTPVerificationEmailDialog";
import ConfirmOTPDialog from "@/components/Dialogs/ConfirmOTPDialog";
import MyProfileDialog from "@/components/Dialogs/MyProfileDialog";
import SlideInDialog from "@/components/Dialogs/SlideInDialog";
import CouponPrizesDialog from "@/components/Dialogs/CouponPrizesDialog";
import { mapGetters } from "vuex";
export default {
  components: {
    AccountHistoryDialog,
    BalanceDetailSmallDialog,
    SignInDialog,
    ShortSignUpDialog,
    LongSignUpDialog,
    CashierDialog,
    ContactUsDialog,
    ReferFriendDialog,
    ExternalUrlDialog,
    CustomUrlDialog,
    CompPointsDialog,
    ChooseMultiGameDialog,
    ForgotPasswordDialog,
    ForgotUsernameDialog,
    ChangePasswordDialog,
    CouponDialog,
    MessageCenterListDialog,
    MessageCenterMessageDialog,
    CompletedFreeSpinDialog,
    FreeSpinNotificationDialog,
    GameDetailDialog,
    AdminPlayerMessageDialog,
    SearchDialog,
    SearchSideDialog,
    GameLauncherDialog,
    ActiveCouponDialog,
    VerifyEmailDialog,
    SuccessDialog,
    TournamentReBuyAddOnDialog,
    RefundDialog,
    SortAndFiltersDialog,
    TournamentReminderDialog,
    GamesGuruDialog,
    PendingGamesDialog,
    ConfirmLoadCashierDialog,
    ConfirmLoadGameDialog,
    TournamentsLobby,
    CasinoJackpotDialog,
    CasinoJackpotWonPrizeDialog,
    EmailVerificationDialog,
    ResendOTPVerificationEmailDialog,
    ConfirmOTPDialog,
    MyProfileDialog,
    SlideInDialog,
    CouponPrizesDialog
  },
  computed: {
    ...mapGetters("accountFields", ["signupFormType"])
  }
};
</script>
<style>
body:not(input) {
  user-select: none;
}
.theme--dark .v-label,
.theme--dark input {
  color: white !important;
  caret-color: white !important;
}
.theme--dark input:disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  caret-color: rgba(255, 255, 255, 0.5) !important;
}
.theme--light .v-label,
.theme--light input {
  color: black !important;
  caret-color: black !important;
}
.theme--light input:disabled {
  color: rgba(0, 0, 0, 0.7) !important;
  caret-color: rgba(0, 0, 0, 0.7) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 4px !important;
}
/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */

/* .theme--dark input[type="date"]::-webkit-calendar-picker-indicator {
  color-scheme: dark;
  height: 16px;
  width: 16px;
}

.theme--light input[type="date"]::-webkit-calendar-picker-indicator {
  color-scheme: light;
  height: 16px;
  width: 16px;
} */
</style>
