<template>
  <v-app>
    <LoadingScreen />
    <TopNavSmall v-if="smallLayout" app />
    <TopNavLarge v-if="largeLayout && !isTournamentsOnly" app />
    <DowntimeNotification />
    <CustomUrlWithHistoryDialog />
    <v-main class="body" :style="mainStyle">
      <TopCarousel
        v-if="!isTournamentsOnly && !isTournamentsView && !isGamesView"
      />
      <CasinoJackpotBanner v-if="!isTournamentsOnly && !isTournamentsView" />
      <GamesNavbarTabs v-if="!isTournamentsOnly && !isTournamentsView" />
      <router-view />
      <AppDialogs />
      <ConfirmDialog ref="confirm" />
    </v-main>
    <BottomNav
      v-if="smallLayout && !isTournamentsView && !isTournamentsOnly"
      app
    />
  </v-app>
</template>

<script>
import TopNavSmall from "@/components/TopNavSmall.vue";
import TopNavLarge from "@/components/TopNavLarge.vue";
import BottomNav from "@/components/BottomNav";
import AppDialogs from "@/components/AppDialogs";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import LoadingScreen from "@/components/LoadingScreen";
import DowntimeNotification from "@/components/DowntimeNotification";
import { LobbyActions, LaunchLobbyAction } from "@/services/casino";
import { mapGetters } from "vuex";
import { attachCustomScript, customScriptApi } from "@/services/casino";
import eventHandler from "@/mixins/eventHandler";
import { externalLobby } from "@/mixins/externalLobby";
import CasinoJackpotBanner from "@/components/casinoJackpot/CasinoJackpotBanner.vue";
import TopCarousel from "@/components/TopCarousel";
import GamesNavbarTabs from "@/components/GamesNavbarTabs";

import CustomUrlWithHistoryDialog from "@/components/Dialogs/CustomUrlWithHistoryDialog";
import {
  clientType,
  EventNotificationTypes,
  EventNotificationContentTypes
} from "@/services/casino";

export default {
  components: {
    TopNavSmall,
    TopNavLarge,
    BottomNav,
    AppDialogs,
    ConfirmDialog,
    LoadingScreen,
    TopCarousel,
    CasinoJackpotBanner,
    GamesNavbarTabs,
    DowntimeNotification,
    CustomUrlWithHistoryDialog
  },
  mixins: [eventHandler, externalLobby],
  computed: {
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", [
      "favicon",
      "themes",
      "tournamentThemes",
      "enabledThemes",
      "casinoName",
      "isTournamentsOnly",
      "specialThemes"
    ]),
    ...mapGetters("tournaments", ["showTournamentId"]),
    isTournamentsView() {
      return this.$route.name === "tournaments";
    },
    isGamesView() {
      return this.$route.name === "games";
    },
    mainStyle() {
      const background =
        this.specialThemes &&
        this.specialThemes[this.$mode] &&
        this.specialThemes[this.$mode].specialThemeGamesListsURL
          ? {
              backgroundImage: `url(${
                this.specialThemes[this.$mode].specialThemeGamesListsURL
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              height: "100%"
            }
          : {};
      return background;
    },
    gameLauncherShown() {
      return this.$store.getters["dialogs/gameLauncher"].isVisible;
    }
  },
  watch: {
    // Update the page title whenever the route changes
    $route: {
      immediate: true,
      handler(to) {
        document.title = this.getPageTitle(to);
      }
    },
    loggedIn(isLoggedIn, wasLoggedIn) {
      const isLogIn = isLoggedIn && !wasLoggedIn;
      const isLogOut = !isLoggedIn && wasLoggedIn;

      if (isLogIn) {
        window.scrollTo(0, 0);
      }

      if (isLogOut) {
        this.goHome();
      }
    },
    favicon(newFavicon) {
      this.updateFavicon(newFavicon);
    },
    themes(newThemes) {
      this.updateThemeColors(newThemes);
    },
    tournamentThemes(newThemes) {
      this.updateThemeColors(newThemes);
    },
    enabledThemes(newEnabledThemes) {
      if (newEnabledThemes.length === 1) {
        const theOnlyEnabledTheme = newEnabledThemes[0];
        this.updateActiveThemeType(theOnlyEnabledTheme);
      }
    },
    showTournamentId(tournamentId) {
      // If the "showTournament" lobby command has been called, navigate to the tournaments route (mobile),
      // or show the TournamentsLobby dialog (instant play) if necessary.
      if (this.smallLayout) {
        // Navigate to tournaments view if we are not already there
        if (tournamentId !== null && this.$route.name !== "tournaments") {
          this.$router.push("/tournaments");
        }
      } else {
        this.$store.dispatch("dialogs/showTournamentsLobby");
      }
    },
    gameLauncherShown(isVisible) {
      // only applies to single-game mode
      if (CDK.getMultipleGamesAtOnceEnabled()) {
        return;
      }

      // does not apply to mobile
      if (clientType === CDK.ClientTypes.Mobile) {
        return;
      }

      // In single-game mode, disable event notifications while the game launcher is visible
      if (isVisible) {
        CDK.suspendEventBasedNotifications();
      } else {
        CDK.resumeEventBasedNotifications();
      }
    }
  },
  created() {
    this.updateFavicon(this.favicon);

    // Install global handlers for messages and iFrames
    if (!window.$root) {
      window.$root = {
        navigateToGame: this.launchGame,
        navigateToCoupon: this.launchCoupon,
        navigateToCashier: this.launchCashier,
        navigateToSignUp: this.launchSignUp,
        navigateToTournament: this.launchTournament,
        navigateToCompPoints: this.launchCompPoints,
        navigateToExternalLobby: this.launchExternalLobby
      };
    }

    // Handle Lobby Actions via postMessage
    window.addEventListener(
      "message",
      event => {
        if (event.data.action) {
          const action = LobbyActions[event.data.action];
          if (action) {
            if (event.data.type === "LC-LobbyAction") {
              if (event.data.hideAll) {
                this.$store.dispatch("dialogs/hideAll");
              }
              LaunchLobbyAction(this.$store, action, event.data.params);
            }
          } else {
            window.console.warn(
              `Warning! received unknown lobby action: ${event.data.action}!`
            );
          }
        }
      },
      false
    );

    Object.assign(window.LobbyCore, customScriptApi);
    attachCustomScript();
  },
  mounted() {
    this.$store.dispatch("connectToCasino");
    this.listenForExpiredSession();
    this.listenForNotifications();
  },
  methods: {
    getPageTitle(route) {
      const prefix = this.casinoName || "Casino";
      const routeName = route.meta?.title;
      return routeName ? `${prefix} - ${routeName}` : prefix;
    },
    listenForNotifications() {
      CDK.on(CDK.Events.eventNotification, async (id, dataType, data) => {
        // Phase 1 - no notifications over games
        if (!this.gameLauncherShown) {
          this.showEventNotificationDialog(id, dataType, data);
        }
      });
    },
    listenForExpiredSession() {
      CDK.on(CDK.Events.sessionChanged, async sessionInfo => {
        if (!sessionInfo.IsValid) {
          this.$store.dispatch("session/signOut");
          this.$refs.confirm.openAlert({
            title: this.$strResourceService("Alert"),
            message: sessionInfo.Message
          });
        }
      });
    },
    updateFavicon(favicon) {
      const oldLink = document.querySelector("link[rel='icon']");
      if (oldLink) {
        document.head.removeChild(oldLink);
      }

      const link = document.createElement("link");
      link.rel = "icon";
      link.href = favicon;
      document.head.appendChild(link);
    },
    updateThemeColors(themes) {
      Object.entries(themes).forEach(([themeType, themeValues]) => {
        Object.entries(themeValues).forEach(([key, color]) => {
          this.$vuetify.theme.themes[themeType][key] = color;
        });
      });
    },
    updateActiveThemeType(themeType) {
      this.$vuetify.theme.dark = themeType === "dark";
    },
    launchGame(gameId, machId) {
      this.cancelSignOut = true;
      this.$store.dispatch("dialogs/hideAll");
      const fullGameId = gameId + "-" + machId + "-0";
      this.$store.dispatch("games/launchGame", { gameId: fullGameId });
    },
    launchCashier() {
      this.$store.dispatch("dialogs/hideAll");
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    launchCoupon(couponCode) {
      this.$store.dispatch("dialogs/hideAll");
      window.LobbyCore.raise(window.LobbyCore.Events.showRedeemCouponPage, {
        couponCode
      });
    },
    launchTournament(tournamentId) {
      this.$store.dispatch("dialogs/hideAll");
      if (typeof tournamentId === "string" || tournamentId instanceof String) {
        tournamentId = parseInt(tournamentId);
      }
      LaunchLobbyAction(this.$store, LobbyActions.ShowTournament, {
        tournamentId
      });
    },
    launchSignUp() {
      this.$store.dispatch("dialogs/hideAll");
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
    },
    launchCompPoints() {
      this.$store.dispatch("dialogs/hideAll");
      window.LobbyCore.raise(window.LobbyCore.Events.showCompPointsPage);
    },
    launchExternalLobby(externalLobbyId) {
      if (
        typeof externalLobbyId === "string" ||
        externalLobbyId instanceof String
      ) {
        externalLobbyId = parseInt(externalLobbyId);
      }
      if (this.isExternalLobbyVisible(externalLobbyId)) {
        this.$store.dispatch("dialogs/hideAll");
        LaunchLobbyAction(this.$store, LobbyActions.LaunchExternalLobby, {
          externalLobbyId
        });
      }
    },
    showEventNotificationDialog(id, dataType, notificationData) {
      const notificationRead = () => CDK.notificationRead(id);

      switch (dataType) {
        case EventNotificationTypes.PopUp: {
          if (
            notificationData.ContentType === EventNotificationContentTypes.URL
          ) {
            this.$store.dispatch("dialogs/showExternalUrl", {
              source: notificationData.Content,
              HTML: null,
              showTitleBar: clientType === CDK.ClientTypes.Mobile,
              onClose: "",
              onCloseCallback: notificationRead,
              height: "600px",
              width: "800px"
            });
          } else if (
            notificationData.ContentType === EventNotificationContentTypes.HTML
          ) {
            this.$store.dispatch("dialogs/showExternalUrl", {
              source: "",
              HTML: notificationData.Content,
              showTitleBar: clientType === CDK.ClientTypes.Mobile,
              onClose: "",
              onCloseCallback: notificationRead,
              height: "600px",
              width: "800px"
            });
          }
          break;
        }
        case EventNotificationTypes.SlideIn: {
          this.$store.dispatch("dialogs/showSlideInDialog", {
            title: notificationData.Title,
            titleColor: notificationData.TitleColor,
            backgroundImage: notificationData.BackgroundImage,
            backgroundColor: notificationData.BackgroundColor,
            text: notificationData.Text,
            textColor: notificationData.TextColor,
            showCasinoLogo: true,
            onCloseCallback: notificationRead
          });
          break;
        }
      }
    }
  }
};
</script>
<style>
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: var(--v-body-base) !important;
}
</style>
