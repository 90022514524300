import { mapActions } from "vuex";
import { eventify, mapArray } from "@/services/casino/eventify";

export default {
  created() {
    window.LobbyCore = {};
    eventify(window.LobbyCore);

    window.LobbyCore.Events = mapArray([
      "showPromotionsPage",
      "closePromotionsPage",
      "showSignInPage",
      "closeSignInPage",
      "showSignUpPage",
      "closeSignUpPage",
      "closeAllDialogs",
      "closeExternalDialogs",
      "showDepositPage",
      "showDepositWithRedirectPage",
      "showWithdrawPage",
      "showWithdrawWithRedirectPage",
      "showReferAFriendInCashierPage",
      "showCouponsPage",
      "hideCouponPage",
      "showRedeemCouponPage",
      "hideRedeemCouponPage",
      "hideCashierPage",
      "showVerifyEmailPage",
      "showForgotPassword",
      "showForgotUsername",
      "showChangePassword",
      "showEmailVerificationPage",
      "hideEmailVerificationPage",
      "showResendOTPVerificationEmailPage",
      "hideResendOTPVerificationEmailPage",
      "showConfirmOTPPage",
      "confirmLoadCashier",
      "confirmLoadGame",
      "hideConfirmOTPPage",
      "showCompPointsPage",
      "closeMyProfilePage"
    ]);

    /* Handler Promotions */
    window.LobbyCore.on(window.LobbyCore.Events.showPromotionsPage, data =>
      this.showPromotions(data)
    );
    window.LobbyCore.on(
      window.LobbyCore.Events.closePromotionsPage,
      this.hideExternalURL
    );

    /* Handler SignIn */
    window.LobbyCore.on(window.LobbyCore.Events.showSignInPage, data =>
      this.showSignIn(data)
    );
    window.LobbyCore.on(
      window.LobbyCore.Events.closeSignInPage,
      this.hideSignIn
    );

    /* Handler SignUp */
    window.LobbyCore.on(window.LobbyCore.Events.showSignUpPage,  data =>
      this.showSignUp(data)
    );
    window.LobbyCore.on(
      window.LobbyCore.Events.closeSignUpPage,
      this.hideSignUp
    );

    /* Handler ForgotPassword */
    window.LobbyCore.on(window.LobbyCore.Events.showForgotPassword, () =>
      this.showForgotPassword("")
    );

    /* Handler ForgotUsername */
    window.LobbyCore.on(window.LobbyCore.Events.showForgotUsername, () =>
      this.showForgotUsername("")
    );

    /* Handler ChangePassword */
    window.LobbyCore.on(window.LobbyCore.Events.showChangePassword, data =>
      this.showChangePassword(data)
    );

    /* Handler DepositPage */
    window.LobbyCore.on(
      window.LobbyCore.Events.showDepositPage,
      data => this.showDeposit(data)
    );

    window.LobbyCore.on(
      window.LobbyCore.Events.showDepositWithRedirectPage,
      data => this.showDepositWithRedirect(data)
    );

    /* Handler WithdrawPage */
    window.LobbyCore.on(
      window.LobbyCore.Events.showWithdrawPage,
      this.showWithdraw
    );

    window.LobbyCore.on(
      window.LobbyCore.Events.showWithdrawWithRedirectPage,
      data => this.showWithdrawWithRedirect(data)
    );

    /* Handler showReferAFriendInCashier */
    window.LobbyCore.on(
      window.LobbyCore.Events.showReferAFriendInCashierPage,
      this.showReferAFriendInCashier
    );
    /* Handler Show Verify Email */
    window.LobbyCore.on(
      window.LobbyCore.Events.showVerifyEmailPage,
      this.showVerifyEmail
    );

    /* Handler showCoupons */
    window.LobbyCore.on(window.LobbyCore.Events.showCouponsPage, data =>
      this.showCoupons(data)
    );

    /* Handler showRedeemCoupon */
    window.LobbyCore.on(window.LobbyCore.Events.showRedeemCouponPage, data =>
      this.showRedeemCoupon(data)
    );

    /* Handler Hide Cashier */
    window.LobbyCore.on(
      window.LobbyCore.Events.hideCashierPage,
      this.hideCashier
    );

    /* Handler External Dialogs */
    window.LobbyCore.on(
      window.LobbyCore.Events.closeExternalDialogs,
      this.hideExternalURL
    );

    /* Handler Show Email Verification */
    window.LobbyCore.on(
      window.LobbyCore.Events.showEmailVerificationPage,
      data => this.showEmailVerification(data)
    );

    /* Handler Show Email Verification */
    window.LobbyCore.on(
      window.LobbyCore.Events.hideEmailVerificationPage,
      this.hideEmailVerification
    );

    /* Handler Show Resend OTP Verification Email */
    window.LobbyCore.on(
      window.LobbyCore.Events.showResendOTPVerificationEmailPage,
      this.showResendOTPVerificationEmail
    );

    /* Handler Hide Resend OTP Verification Email */
    window.LobbyCore.on(
      window.LobbyCore.Events.hideResendOTPVerificationEmailPage,
      this.hideResendOTPVerificationEmail
    );

    /* Handler Show Confirm OTP */
    window.LobbyCore.on(window.LobbyCore.Events.showConfirmOTPPage, data =>
      this.showConfirmOTP(data)
    );

    /* Handler show confirm load cashier */
    window.LobbyCore.on(window.LobbyCore.Events.confirmLoadCashier, data =>
      this.confirmLoadCashier(data)
    );

    /* Handler show confirm load game */
    window.LobbyCore.on(window.LobbyCore.Events.confirmLoadGame, data =>
      this.confirmLoadGame(data)
    );

    /* Handler Hide Confirm OTP */
    window.LobbyCore.on(
      window.LobbyCore.Events.hideConfirmOTPPage,
      this.hideConfirmOTP
    );

    /* Handler Show CompPoints */
    window.LobbyCore.on(
      window.LobbyCore.Events.showCompPointsPage,
      this.showCompPoints
    );

    /* Handler Hide MyProfile */
    window.LobbyCore.on(
      window.LobbyCore.Events.closeMyProfilePage,
      this.hideMyProfile
    );

    /* Handler Close All */
    window.LobbyCore.on(window.LobbyCore.Events.closeAllDialogs, this.hideAll);
  },
  methods: {
    ...mapActions("dialogs", [
      "showPromotions",
      "hideAll",
      "hideExternalURL",
      "showSignIn",
      "hideSignIn",
      "showSignUp",
      "hideSignUp",
      "showDeposit",
      "showDepositWithRedirect",
      "showWithdraw",
      "showWithdrawWithRedirect",
      "hideCashier",
      "showReferAFriendInCashier",
      "showCoupons",
      "showRedeemCoupon",
      "showVerifyEmail",
      "showForgotPassword",
      "showForgotUsername",
      "showChangePassword",
      "showEmailVerification",
      "hideEmailVerification",
      "showResendOTPVerificationEmail",
      "hideResendOTPVerificationEmail",
      "showConfirmOTP",
      "hideConfirmOTP",
      "showCompPoints",
      "confirmLoadCashier",
      "confirmLoadGame",
      "hideMyProfile"
    ])
  }
};
