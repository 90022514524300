<template>
  <v-card class="d-flex flex-wrap justify-center" flat color="tournamentDetail">
    <v-card-text
      class="tournamentInfo tournamentInfoTxt--text d-flex justify-space-around align-center my-2 mx-4"
    >
      <h4>{{ $str("AmountToRefund") + ":" }}</h4>
      <v-chip color="tournamentDetail tournamentDetailTxt--text" small label>{{
        tournament.refundEnabled
          ? $tournamentCur(tournament.buyIn)
          : $tournamentCur(0)
      }}</v-chip>
    </v-card-text>
    <v-card-actions class="justify-center"
      ><v-btn
        depressed
        light
        color="buttonSecondary buttonSecondaryTxt--text"
        @click.stop="showRefund({ tournament })"
        >{{ $str("Refund") }}</v-btn
      ></v-card-actions
    ></v-card
  >
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    tournament: { type: Object, required: true }
  },
  methods: {
    ...mapActions("dialogs", ["showRefund"])
  }
};
</script>
<style scoped>
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
  border-radius: 0.25rem;
}
</style>
