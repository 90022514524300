<template>
  <v-container class="pa-0 container-title">
    <v-card flat color="transparent">
      <v-card-title class="pl-2 text-wrap tournamentTxt--text">
        <div class="title-tournament">
          {{ name }}
        </div>
      </v-card-title>
      <v-card-subtitle
        class="pl-2 pr-0 d-flex justify-space-between tournamentTxt--text"
        :class="{ biggerTile: isTileBig }"
        ><span class="caption">{{ start }}</span>

        <span class="caption">{{ status }}</span>
        <span class="caption">{{
          buyIn ? `${$str("buyIn")}: ${$tournamentCur(buyIn)}` : ""
        }}</span></v-card-subtitle
      >
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    start: { type: String, default: "" },
    status: { type: String, default: "" },
    buyIn: { type: Number, default: 0 }
  },
  computed: {
    isTileBig() {
      const allTile = `${this.start} ${this.status} ${this.buyIn}`;
      return allTile.length > 28;
    }
  }
};
</script>

<style lang="scss" scoped>
.biggerTile {
  flex-direction: row;
  flex-wrap: wrap;
  span {
    line-height: 1rem;
    flex-direction: column;
    flex: 1;
    flex-basis: 50%;
  }
}

@media (orientation: landscape) {
  .title-tournament {
    width: 100%;
  }

  .container-title {
    width: 85%;
  }
}

@media (orientation: portrait) {
  .title-tournament {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .container-title {
    width: 70%;
  }

  .v-expansion-panel-header--active
    > .container
    > .v-card
    > .v-card__title
    > .title-tournament {
    white-space: break-spaces !important;
  }
}
</style>
