import { render, staticRenderFns } from "./TournamentReBuyAddOnDialog.vue?vue&type=template&id=4c6dc468&scoped=true&"
import script from "./TournamentReBuyAddOnDialog.vue?vue&type=script&lang=js&"
export * from "./TournamentReBuyAddOnDialog.vue?vue&type=script&lang=js&"
import style0 from "./TournamentReBuyAddOnDialog.vue?vue&type=style&index=0&id=4c6dc468&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c6dc468",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCardActions,VDialog,VStepper,VStepperContent,VStepperItems})
