<template>
  <v-dialog v-model="showDialog" width="370" overlay-opacity="0.9">
    <v-card v-if="showDialog">
      <DialogTitleBar :title="$strResourceService('ForgotPasswordHeader')" @close="close" />
      <v-card-text class="px-4" style="font-size: 12px;">
        <v-alert
          v-show="errorMessage.length > 0"
          type="error"
          dense
          :icon="false"
          class="text-left mb-4"
          style="font-weight: 700;"
        >
          {{ errorMessage }}
        </v-alert>
        <div class="text-left mb-8" >
          {{ $strResourceService("EmailForgotPassword") }}
        </div>
        <v-form ref="form">
          <v-text-field
            v-if="!showEmailUsernameField"
            v-model="username"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="accountCreateFieldsAttributes.Login"
            :rules="validateFields ? accountCreateFieldsRules.Login : []"
            :label="$str('UserName')"
          ></v-text-field>
          <v-text-field
            v-model="email"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="accountCreateFieldsAttributes.EMailAddress"
            :rules="validateFields ? accountCreateFieldsRules.EMailAddress : []"
            :label="$strResourceService('EmailAddress')"
          ></v-text-field>
          <v-text-field
            v-if="showEmailUsernameField"
            v-model="username"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="accountCreateFieldsAttributes.Login"
            :label="$str('UserName')"
          ></v-text-field>
        </v-form>
        <v-row class="pt-1">
          <v-col cols="6" style="padding-right: 5px;">
            <v-btn
              color="buttonSecondary buttonSecondaryTxt--text"
              block
              @click="close()"
            >
              {{ $strResourceService("Cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" style="padding-left: 5px;">
            <v-btn
              class="mb-3"
              color="button buttonTxt--text"
              type="submit"
              block
              @click="retrievePassword()"
              >{{ $str("OKButton") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "forgotPassword",
      username: "",
      email: "",
      errorMessage: "",
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules",
      "showEmailUsernameField"
    ])
  },
  methods: {
    ...mapActions("dialogs", ["showChangePassword"]),
    onShown() {
      const login = this.$store.getters["dialogs/forgotPassword"].data.login;
      if (login) this.username = login;

      const email = this.$store.getters["dialogs/forgotPassword"].data.email;
      if (email) this.email = email;
    },
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form?.validate();
    },
    async retrievePassword() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }

      try {
        const response = await CDK.retrievePassword(this.username, this.email);
        this.showDialog = false;
        this.showChangePassword({
          email: this.email,
          login: this.username,
          confirmMessage: response
        });
      } catch (error) {
        this.errorMessage = error.Message;
      }
    },
    onHidden() {
      this.errorMessage = "";
      this.validateFields = false;
      this.$refs.form?.resetValidation();
      this.$refs.form?.reset();
    },
    close() {
      this.showDialog = false;
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 600px) {
  ::v-deep.v-alert--text:before {
    background-color: transparent !important;
  }
  ::v-deep.v-alert--text > .v-alert__wrapper {
    display: inline-flex !important;
  }
}
</style>
