<template>
  <v-dialog
    v-model="loading"
    transition="dialog-transition"
    fullscreen
    content-class="rounded-0"
  >
    <v-card height="100%">
      <v-container fill-height>
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <v-img
              :src="casinoLogo"
              class="ma-2"
              style="max-width:60vw"
              eager
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("session", ["switchingToRealFromGame"]),
    ...mapGetters("customizations", ["casinoLogo"]),
    loading() {
      return this.switchingToRealFromGame;
    }
  }
};
</script>

<style scoped>
/* shrink and leave towards top left corner */
::v-deep .dialog-transition-leave-to {
  transform-origin: left top;
  transform: scale(0.5) translateY(-50%) translateX(0%);
}

::v-deep .v-dialog {
  transition-duration: 1s;
}
</style>
