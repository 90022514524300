/**
 * Casino customizations such as logos, colors, etc.
 * @see casino admin -> configuration -> Lobby -> Lobby Settings -> Themes
 */

import {
  customizations,
  onCustomizationsChanged,
  clientType
} from "@/services/casino";

const state = {
  casinoLogo: customizations.casinoLogo,
  casinoLogoLight: customizations.casinoLogoLight,
  casinoName: customizations.casinoName,
  lobbyIconType: customizations.lobbyIconType || CDK.LobbyIconTypes.tall,
  favicon: customizations.favicon,
  lazyLoadingImage:
    "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=",
  themes: customizations.themes,
  tournamentThemes: customizations.tournamentThemes,
  customHeaderUrls: customizations.customHeaderUrls,
  enabledThemes: customizations.enabledThemes,
  newGamesBackground: customizations.newGamesBackground,
  freeSpinBackgroundUrls: customizations.freeSpinBackgroundUrls,
  tournamentRefreshRate: customizations.tournamentRefreshRate,
  tournamentLogo: customizations.tournamentLogo,
  customHomeURL: customizations.customHomeURL
    ? customizations.customHomeURL
    : "",
  customHomeURLBase: customizations.customHomeURL
    ? customizations.customHomeURL
    : "",
  customHomeChannel: customizations.customHomeChannel,
  specialThemes: customizations.specialThemes,
  isTournamentsOnly: () => {
    if (
      clientType === CDK.ClientTypes.Download &&
      (customizations.isTournamentsOnlyDownload ||
        customizations.isTournamentsOnlyDownload === false)
    ) {
      return customizations.isTournamentsOnlyDownload;
    } else if (
      clientType === CDK.ClientTypes.InstantPlay &&
      (customizations.isTournamentsOnlyInstaplay ||
        customizations.isTournamentsOnlyInstaplay === false)
    ) {
      return customizations.isTournamentsOnlyInstaplay;
    } else if (
      clientType === CDK.ClientTypes.Mobile &&
      (customizations.isTournamentsOnlyMobile ||
        customizations.isTournamentsOnlyMobile === false)
    ) {
      return customizations.isTournamentsOnlyMobile;
    }

    return customizations.isTournamentsOnly;
  },
  cashierUrl: () => {
    if (
      clientType === CDK.ClientTypes.Download &&
      customizations.cashierUrlDownload
    ) {
      return customizations.cashierUrlDownload;
    } else if (
      clientType === CDK.ClientTypes.InstantPlay &&
      customizations.cashierUrlInstaplay
    ) {
      return customizations.cashierUrlInstaplay;
    } else if (
      clientType === CDK.ClientTypes.Mobile &&
      customizations.cashierUrlMobile
    ) {
      return customizations.cashierUrlMobile;
    }

    return customizations.cashierUrl;
  },
  exitUrl: () => {
    if (
      clientType === CDK.ClientTypes.Download &&
      customizations.exitUrlDownload
    ) {
      return customizations.exitUrlDownload;
    } else if (
      clientType === CDK.ClientTypes.InstantPlay &&
      customizations.exitUrlInstaplay
    ) {
      return customizations.exitUrlInstaplay;
    } else if (
      clientType === CDK.ClientTypes.Mobile &&
      customizations.exitUrlMobile
    ) {
      return customizations.exitUrlMobile;
    }

    return customizations.exitUrl;
  }
};

const getters = {
  casinoLogo: state => state.casinoLogo,
  casinoLogoLight: state => state.casinoLogoLight || state.casinoLogo,
  casinoName: state => state.casinoName,
  lobbyIconType: state => state.lobbyIconType,
  favicon: state => state.favicon,

  // This image is not currently customized with the lobby theme, and a design
  // update specified that the casino logo should be used instead (re: LR-279)
  lazyLoadingImage: state => state.casinoLogo,

  customHeaderUrls: state => state.customHeaderUrls,
  freeSpinBackgroundUrls: state => state.freeSpinBackgroundUrls,

  /** Light and/or dark theme colors, @see @/plugins/vuetify.js */
  themes: state => state.themes,

  // Light and/or dark theme colors for tournaments lobby
  tournamentThemes: state => state.tournamentThemes,

  /** @values ["light"], ["dark"], or ["light", "dark"] */
  enabledThemes: state => state.enabledThemes,

  /** URL for an image or video to be shown behind the New Games grid */
  newGamesBackground: state => state.newGamesBackground,
  tournamentRefreshRate: state => state.tournamentRefreshRate,
  tournamentLogo: state => state.tournamentLogo,
  customHomeURL: state => state.customHomeURL,
  customHomeURLBase: state => state.customHomeURLBase,
  customHomeChannel: state => state.customHomeChannel,

  //Flag to show Tournaments lobby only
  isTournamentsOnly: state => state.isTournamentsOnly(),

  //Cashier Url for Custom lobbies using Tournaments lobby
  cashierUrl: state => state.cashierUrl(),

  //Exit Url to return to Custom lobbies from Tournaments lobby
  exitUrl: state => state.exitUrl(),
  specialThemes: state => state.specialThemes
};

const actions = {
  initialize(context) {
    // Used by the live preview feature in the admin
    onCustomizationsChanged(customizations =>
      context.dispatch("updateCustomizations", customizations)
    );
    CDK.on(CDK.Events.loggedIn, () => {
      if (state.customHomeURL) {
        context.commit(
          "updateCustomHomeURL",
          CDK.getReplacedURLParameters(state.customHomeURL)
        );
      }
    });
    CDK.on(CDK.Events.loggedOut, () => {
      if (state.customHomeURLBase) {
        context.commit(
          "updateCustomHomeURL",
          CDK.getReplacedURLParameters(state.customHomeURLBase)
        );
      }
    });
  },
  updateCustomizations(
    context,
    {
      casinoLogo,
      casinoLogoLight,
      casinoName,
      lobbyIconType,
      favicon,
      themes,
      tournamentThemes,
      customHeaderUrls,
      freeSpinBackgroundUrls,
      enabledThemes,
      tournamentRefreshRate,
      newGamesBackground,
      tournamentLogo,
      customHomeURL,
      customHomeChannel,
      specialThemes
    }
  ) {
    if (casinoLogo) {
      context.commit("updateCasinoLogo", casinoLogo);
    }

    if (casinoLogoLight) {
      context.commit("updateCasinoLogoLight", casinoLogoLight);
    }

    if (casinoName) {
      context.commit("updateCasinoName", casinoName);
    }

    if (lobbyIconType) {
      context.commit("updateLobbyIconType", lobbyIconType);
    }

    if (favicon) {
      context.commit("updateFavicon", favicon);
    }

    if (themes) {
      context.commit("updateThemes", themes);
    }

    if (tournamentThemes) {
      context.commit("updateTournamentThemes", tournamentThemes);
    }

    if (customHeaderUrls) {
      context.commit("updateCustomHeaderUrls", customHeaderUrls);
    }

    if (freeSpinBackgroundUrls) {
      context.commit("updateFreeSpinBackgroundUrls", freeSpinBackgroundUrls);
    }

    if (enabledThemes) {
      context.commit("updateEnabledThemes", enabledThemes);
    }

    if (tournamentRefreshRate) {
      context.commit("updateTournamentRefreshRate", tournamentRefreshRate);
    }

    if (newGamesBackground) {
      context.commit("updateNewGamesBackground", newGamesBackground);
    }

    if (tournamentLogo) {
      context.commit("updateTournamentLogo", tournamentLogo);
    }
    if (customHomeURL) {
      context.commit("updateCustomHomeURL", customHomeURL);
    }
    if (customHomeChannel) {
      context.commit("updateCustomHomeChannel", customHomeChannel);
    }
    if (specialThemes) {
      context.commit("updateSpecialThemes", specialThemes);
    }
  }
};

const mutations = {
  updateCasinoLogo(state, casinoLogo) {
    state.casinoLogo = casinoLogo;
  },
  updateCasinoLogoLight(state, casinoLogoLight) {
    state.casinoLogoLight = casinoLogoLight;
  },
  updateCasinoName(state, casinoName) {
    state.casinoName = casinoName;
  },
  updateLobbyIconType(state, lobbyIconType) {
    state.lobbyIconType = lobbyIconType;
  },
  updateFavicon(state, favicon) {
    state.favicon = favicon;
  },
  updateThemes(state, themes) {
    state.themes = themes;
  },
  updateTournamentThemes(state, tournamentThemes) {
    state.tournamentThemes = tournamentThemes;
  },
  updateCustomHeaderUrls(state, customHeaderUrls) {
    state.customHeaderUrls = customHeaderUrls;
  },
  updateFreeSpinBackgroundUrls(state, freeSpinBackgroundUrls) {
    state.freeSpinBackgroundUrls = freeSpinBackgroundUrls;
  },
  updateEnabledThemes(state, enabledThemes) {
    state.enabledThemes = enabledThemes;
  },
  updateNewGamesBackground(state, newGamesBackground) {
    state.newGamesBackground = newGamesBackground;
  },
  updateTournamentRefreshRate(state, tournamentRefreshRate) {
    state.tournamentRefreshRate = tournamentRefreshRate;
  },
  updateTournamentLogo(state, tournamentLogo) {
    state.tournamentLogo = tournamentLogo;
  },
  updateCustomHomeURL(state, customHomeURL) {
    state.customHomeURL = customHomeURL;
  },
  updateCustomHomeChannel(state, customHomeChannel) {
    state.customHomeChannel = customHomeChannel;
  },
  updateSpecialThemes(state, specialThemes) {
    state.specialThemes = specialThemes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
