<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" width="400">
    <v-card v-if="showDialog" class="elevation-12">
      <v-card-title
        class="justify-center"
        :class="largeLayout ? 'transparent' : 'navbar'"
      >
        <v-spacer></v-spacer>
        <span
          :style="largeLayout ? 'margin-top: 5%;' : ''"
          style="text-transform: uppercase;"
          >{{ $strResourceService("VerifyEmail") }}</span
        >
        <v-spacer></v-spacer>
        <v-icon
          class="pa-0"
          :style="largeLayout ? 'margin-top: -5%;' : ''"
          @click.stop="showDialog = false"
        >
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
        >
      </v-card-title>
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        class="pt-2"
        style="font-size: large; text-align: center"
        :min-height="largeLayout ? '405' : ''"
        v-html="ConfirmedOTPResult"
      ></v-card-text>
      <v-container>
        <v-form id="signin-form" ref="form" @submit="submit"> </v-form>
        <v-row justify="center">
          <v-btn
            class="col-11"
            color="buttonSecondary buttonSecondaryTxt--text"
            style="background-color: #ffd400 !important"
            form="signin-form"
            type="submit"
            @click="e => submit(e)"
            ><b style="color: black;">{{ $str("OKButton") }}</b>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "confirmOTP",
      fieldValues: {
        login: "",
        password: "",
        forMoney: true
      },
      otpResult: false,
      loggingIn: false,
      loginError: "",
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "loginFields",
      "loginFieldsAttributes",
      "loginFieldsRules"
    ]),
    ConfirmedOTPResult() {
      const votpResult = this.$store.getters["dialogs/confirmOTP"].data
        .optResult;
      if (votpResult === true) {
        return `${this.$str("AccountVerified")}`;
      }
      return `${this.$str("OTPNoValid")}`;
    },
    onSuccess() {
      return this.$store.getters["dialogs/signIn"].data.onSuccess;
    }
  },
  methods: {
    onShown() {
      this.$refs.form?.reset();
      const login = this.$store.getters["dialogs/confirmOTP"].data.login;
      if (login) {
        this.fieldValues.login = login;
      }
      const password = this.$store.getters["dialogs/confirmOTP"].data.password;
      if (password) {
        this.fieldValues.password = password;
      }
    },
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form?.validate();
    },
    async submit(e) {
      // Don't actually submit the form and cause the browser to navigate
      e.preventDefault();
      const login = this.$store.getters["dialogs/confirmOTP"].data.login;
      this.fieldValues.login = login;
      const password = this.$store.getters["dialogs/confirmOTP"].data.password;
      this.fieldValues.password = password;
      this.otpResult = this.$store.getters["dialogs/confirmOTP"].data.optResult;
      window.LobbyCore.raise(window.LobbyCore.Events.hideConfirmOTPPage);

      if (this.otpResult === true) {
        window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
      } else {
        window.LobbyCore.raise(
          window.LobbyCore.Events.showEmailVerificationPage,
          {
            login: login,
            password: password
          }
        );
      }
    },
    onHidden() {
      this.validateFields = false;
      this.fieldValues.login = "";
      this.fieldValues.password = "";
    }
  }
};
</script>
