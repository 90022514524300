<template>
  <!-- Generator: Adobe Illustrator 25.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <polygon
      class="st0"
      points="41.1,25 24.3,9.9 24.2,9.9 7.4,25 7.4,44.2 19.9,44.2 19.9,33.3 28.6,33.3 28.6,44.2 41.1,44.2 "
    />
    <polyline
      class="st1"
      points="0,23.8 7.6,17.1 7.6,6.2 12.8,6.2 12.8,12.5 24.2,2.6 48,23.8 "
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Home" }
  }
};
</script>
<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
</style>
