import { sampleTables } from "./evolutionGamesSampleTableList";
import { skinId } from "./connection";

export const getEvolutionTables = async () => {
  // During prototype period, fallback to static sample data
  // { HasErrors, Message, Id, Type, Players, CasinoId, Tables }
  const response = await CDK.getEvolutionTables();

  if (response.HasErrors) {
    window.console.error(`Error getting evolution tables: ${response.Message}`);

    if (process.env.NODE_ENV !== "production") {
      window.console.warn(
        `[DEV MODE] Falling back to static sample data for evolution tables`
      );
      return sampleTables;
    }
  }

  const tableCount = Object.keys(response.Tables).length;
  if (tableCount === 0) {
    window.console.warn(`Zero evolution tables returned`);

    if (process.env.NODE_ENV !== "production") {
      window.console.warn(
        `[DEV MODE] Falling back to static sample data for evolution tables`
      );
      return sampleTables;
    }
  }

  window.console.log(`Received ${tableCount} evolution tables`);
  return response.Tables;
};

const makeReturnUrl = () => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete("clientType");
  currentUrl.searchParams.set("skinId", skinId);
  return encodeURIComponent(currentUrl.toString());
};

export const getEvolutionGameLaunchUrl = async (tableId) => {
  const returnUrl = makeReturnUrl();
  const response = await CDK.getEvolutionGameLaunchUrl(tableId, returnUrl);
  window.console.warn(
    `evolution game url response: ${JSON.stringify(response)}`
  );

  // response = { HasErrors, Message, Url, Html5Url, Target }
  return response.Url;
};
