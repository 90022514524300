<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" width="370" height="334">
    <v-card v-if="showDialog" flat>
      <DialogTitleBar
        :title="$str('RedeemCompPointsHeader')"
        color="transparent"
        @close="showDialog = false"
      />
      <v-container class="pb-8">
        <v-row
          class="px-2 py-3 mx-1 mb-6 text-left"
          style="background-color: var(--v-buttonSecondary-base); border-radius: 0.6vh; font-size: 12px; font-weight-thin"
          >{{ $str("AccruedCompPoints") }}: {{ $num(accruedPoints, 0) }}</v-row
        >
        <v-form ref="form">
          <v-row>
            <v-card-text class="py-0">
              <v-text-field
                id="field-compPoints"
                v-model.number="pointsToRedeem"
                class="required pb-0"
                :filled="largeLayout"
                outlined
                dense
                hide-details="auto"
                :label="$str('EnterCompsToRedeem')"
                type="number"
                min="0"
                pattern="[0-9]*"
                :rules="[
                  input => !!input || $str('CompPointsAmountToRedeem'),
                  input => input % 1 === 0 || $str('PointsCanNotBeDecimal')
                ]"
                @keypress="isNumber($event)"
              ></v-text-field>
              <v-alert
                v-show="success"
                type="success"
                dense
                :icon="false"
                class="text-left"
                style="font-weight: 700;"
                >{{ success }}</v-alert
              >
              <v-alert
                v-show="error"
                type="error"
                class="text-left"
                dense
                :icon="false"
                style="font-weight:700;"
                >{{ error }}</v-alert
              >
              <span
                v-if="minimumCompPointsToRedeem"
                class="caption font-italic font-weight-thin"
                >{{
                  $str("MinimumCompPointsToRedeem") + minimumCompPointsToRedeem
                }}</span
              >
            </v-card-text>
          </v-row>
          <v-row justify="center" class="mx-0 mt-2">
            <v-card-actions class="px-1" style="width: 100%;">
              <v-btn
                id="btn-redeemCompPoints"
                color="button buttonTxt--text"
                type="submit"
                style="width: 100%;"
                @click="redeemPoints"
                >{{ $str("Redeem") }}</v-btn
              >
            </v-card-actions>
          </v-row>
        </v-form>
      </v-container>

      <div
        v-if="availableCompPointCoupons && availableCompPointCoupons.length > 0"
        style="margin-left: 2vw;"
      >
        {{ $str("AvailableCoupons") }}
      </div>
      <v-spacer></v-spacer>
      <v-container>
        <v-row dense style="width: inherit;">
          <CompPointsCouponTile
            v-for="availableCoupon in availableCompPointCoupons"
            :key="availableCoupon.CouponCode"
            :coupon="availableCoupon"
            :user-comp-points="accruedPoints"
          />
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { dialogToggle } from "@/mixins/dialogToggle";
import CompPointsCouponTile from "../CompPointsCouponTile";
export default {
  components: {
    CompPointsCouponTile
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "compPoints",
      pointsToRedeem: null,
      minimumCompPointsToRedeem: 0
    };
  },
  computed: {
    ...mapGetters("compPoints", ["accruedPoints", "success", "error"]),
    ...mapGetters("coupons", ["availableCompPointCoupons"])
  },
  methods: {
    ...mapActions("coupons", ["getAvailableCompPointsCoupons"]),
    redeemPoints(e) {
      e.preventDefault();
      this.$store.dispatch("compPoints/redeemPoints", {
        pointsToRedeem: this.pointsToRedeem
      });
      this.$refs.form.reset();
    },
    async onShown() {
      this.getMinimumCompPointsToRedeem();
      await this.getAvailableCompPointsCoupons();
    },
    onHidden() {
      this.pointsToRedeem = null;
      this.$store.dispatch("compPoints/clearMessages");
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getMinimumCompPointsToRedeem() {
      this.minimumCompPointsToRedeem = CDK.GetMinimumAllowedCompPointsToRedeem().then(
        result => {
          this.minimumCompPointsToRedeem = result;
        }
      );
    }
  }
};
</script>

<style scoped>
::v-deep.v-alert--text:before {
  background-color: transparent !important;
}
::v-deep.v-alert--text > .v-alert__wrapper {
  display: inline-flex !important;
}
</style>
