<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <g id="videopoker">
      <path
        class="st0"
        d="M18.7,7.3l0.8-2.4c0.5-1.7,2.3-2.6,4-2.1l17.3,5.6c1.7,0.6,2.6,2.4,2.1,4.1L38.2,27"
      />
      <path
        class="st1"
        d="M34.9,42.1c0,1.8-1.4,3.2-3.2,3.2H13.6c-1.8,0-3.2-1.5-3.2-3.2V13.5c0-1.8,1.4-3.2,3.2-3.2h18.1
		c1.8,0,3.2,1.5,3.2,3.2V42.1z"
      />
      <path
        class="st2"
        d="M34.9,42.1c0,1.8-1.4,3.2-3.2,3.2H13.6c-1.8,0-3.2-1.5-3.2-3.2V13.5c0-1.8,1.4-3.2,3.2-3.2h18.1
		c1.8,0,3.2,1.5,3.2,3.2V42.1z"
      />
      <line class="st1" x1="14.8" y1="14.1" x2="14.8" y2="17.5" />
      <line class="st1" x1="30.5" y1="38.1" x2="30.5" y2="41.5" />
      <path
        class="st2"
        d="M29.1,23.3c-1.5-1.5-4-1.5-5.5,0l0,0l-0.9,1l-0.9-1c-1.5-1.5-3.9-1.6-5.5-0.1l0,0c-1.5,1.6-1.4,4,0.1,5.5
		l6.4,6.4l6.4-6.4C30.5,27.3,30.5,24.9,29.1,23.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "VideoPoker",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Video Poker" }
  }
};
</script>

<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
.st2 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
