<template>
  <v-container v-if="smallLayout" class="winners justify-center px-6">
    <span class="text-center casinoJackpotRecentWinnersTitle--text mb-1">{{
      $str("RecentWinners")
    }}</span>

    <vue-marquee-slider
      id="marquee-slider-text"
      style="padding: 2px 0; width: 88vw"
      :speed="speed"
      :width="260"
      :space="20"
    >
      <p
        v-for="(winner, index) in winnersList"
        :key="index"
        style="color: var(--v-casinoJackpotRecentWinnersText-base);"
      >
        <strong>{{ winner.Amount }}</strong>
        {{ winner.casinoPlayerId }} {{ winner.wonDate }}
      </p>
    </vue-marquee-slider>
  </v-container>

  <v-container v-else fluid class="winners">
    <span class="casinoJackpotRecentWinnersTitle--text">
      {{ $str("RecentWinners") }}
    </span>
    <vue-marquee-slider
      id="marquee-slider-text"
      style="width: 100%"
      :speed="speed"
      :width="310"
      :space="20"
    >
      <p
        v-for="(winner, index) in winnersList"
        :key="index"
        style="color: var(--v-casinoJackpotRecentWinnersText-base); "
      >
        <strong>{{ winner.Amount }}</strong>
        {{ winner.casinoPlayerId }} {{ winner.wonDate }}
      </p>
    </vue-marquee-slider>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      speedFactor: 4000
    };
  },
  computed: {
    ...mapGetters("casinoJackpot", ["casinoJackpotWinners"]),
    winnersList() {
      return this.casinoJackpotWinners.map(winner => {
        return {
          Amount: this.$cur(winner.Amount),
          casinoPlayerId: winner.casinoPlayerId,
          wonDate: this.winnerDate(winner.wonDate)
        };
      });
    },
    speed() {
      return this.speedFactor * this.casinoJackpotWinners.length;
    }
  },
  methods: {
    winnerDate(date) {
      const wonDate = new Date(date);
      return (
        wonDate.toLocaleDateString() +
        " " +
        wonDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.winners {
  white-space: nowrap;
  overflow: auto;
  display: grid;
  align-items: center;
}

.winners > span {
  font-weight: bold;
  text-transform: uppercase;
}

.winnersRow {
  display: inline-block;
}

.winners ul {
  overflow: auto;
  display: flex;
}

@media (min-width: 751px) {
  .winners {
    height: inherit;
    display: inline-flex;
  }
  .winners > span {
    font-size: 14px;
    padding: 0 20px;
  }

  .winners p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media (max-width: 750px) {
  .winners > span {
    line-height: 20px;
    font-size: 12px;
    padding: 0 5px;
  }

  .winnersRow {
    font-size: 12px;
    line-height: 15px;
  }

  .winnerDate {
    font-size: 10px;
  }

  .winners p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 0;
  }
}

.recentwinners {
  padding: 0 10px;
  font-size: 12px;
  line-height: 60px;
  background-color: rgba(0, 0, 0, 0.25);
  font-weight: bold;
  text-transform: uppercase;
}

.recentwinnersCarousel {
  background: none !important;
  border: none;
  overflow-y: auto;
  overflow-x: visible;
  overscroll-behavior-x: none;
  overflow-y: visible;
}

/* width */
::-webkit-scrollbar {
  display: none;
}
</style>
