<template>
  <v-dialog
    v-model="showDialog"
    :content-class="
      largeLayout ? 'cashierDialog largeCashier customSize' : 'cashierDialog'
    "
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar
        :title="titleKey"
        :show-chat-icon="true"
        chat-location="Cashier"
        @close="close"
      />
      <v-card-actions
        v-if="smallLayout && !emailVerified"
        class="pa-0 justify-center"
      >
        <v-alert
          type="warning"
          dense
          class="pa-0 ma-0"
          style="text-align: center"
          >{{ $str("emailNotVerified")
          }}<v-btn color="button buttonTxt--text" @click="showVerifyEmail">{{
            $str("verifyEmail")
          }}</v-btn></v-alert
        >
      </v-card-actions>
      <v-card-text
        v-if="showCashierForm && showCashierComplete"
        class="pa-0 flex-grow-1 d-flex align-content-stretch"
      >
        <iframe
          :id="iFrameId"
          :name="uuid"
          seamless
          frameborder="0"
          style="width: 100%; min-height: 600px; background-color: white;"
          :allow="allowPermissions"
          :src="fullCashierUrl"
          @load="iFrameLoaded"
        />
      </v-card-text>
      <v-card-text
        v-if="showCashierForm && !showCashierComplete"
        class="pa-0 flex-grow-1 d-flex align-content-stretch"
      >
        <CashierForm
          ref="cashierForm"
          :cashier-options="cashierOptions"
          :unique-id="uuid"
        />
        <iframe
          :id="iFrameId"
          :name="uuid"
          seamless
          frameborder="0"
          style="width: 100%; min-height: 600px; background-color: white;"
          @load="submitForm"
        />
      </v-card-text>
      <v-overlay absolute :value="!showCashierForm">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import CashierForm from "../CashierForm";
import { dialogToggle } from "@/mixins/dialogToggle";
import { generateUuid } from "@/services/casino";
import { getStyleText, createStyleElement } from "@/services/casino/utils";
import { mapActions, mapGetters } from "vuex";
import { casinoConnected } from "@/services/casino";

export default {
  components: {
    CashierForm
  },
  mixins: [dialogToggle],
  data() {
    return {
      fullCashierUrl: "",
      name: "cashier",
      loading: true,
      uuid: generateUuid(),
      cashierWindow: {}
    };
  },
  async mounted() {
    await casinoConnected;
    this.attachCustomStyles();
  },
  computed: {
    ...mapGetters("cashier", ["allowProperties"]),
    iFrameId() {
      return "cashier-iframe-" + this.uuid;
    },
    optionsKey() {
      return this.$store.getters["dialogs/cashier"].data.options;
    },
    titleKey() {
      return this.smallLayout
        ? this.$store.getters["dialogs/cashier"].data.title
        : "Cashier";
    },
    cashierMode() {
      return this.$store.getters["dialogs/cashier"].data.cashierMode;
    },
    couponCode() {
      return this.$store.getters["dialogs/cashier"].data.couponCode;
    },
    redirectUrl() {
      return this.$store.getters["dialogs/cashier"].data.redirectUrl;
    },
    cashierOptions() {
      if (
        this.redirectUrl &&
        !this.showCashierComplete &&
        typeof this.redirectUrl !== "undefined"
      ) {
        try {
          const cashierOptsCopy = JSON.parse(
            JSON.stringify(this.$store.getters[`cashier/${this.optionsKey}`])
          );

          if (cashierOptsCopy.CashierSettings) {
            cashierOptsCopy.CashierSettings.Action = "";
            cashierOptsCopy.CashierSettings.RedirectURL = this.redirectUrl;

            return cashierOptsCopy;
          } else {
            return this.$store.getters[`cashier/${this.optionsKey}`];
          }
        } catch (error) {
          window.console.warn(`CashierDialog: Found this error: ${error}`);
          return this.$store.getters[`cashier/${this.optionsKey}`];
        }
      } else {
        return this.$store.getters[`cashier/${this.optionsKey}`];
      }
    },
    emailVerified() {
      return this.$store.getters["session/emailVerified"];
    },
    showCashierForm() {
      return Object.keys(this.cashierOptions).length > 0;
    },
    showCashierComplete() {
      let result = this.largeLayout;

      if (!this.largeLayout && this.tabletMode)
        result = !this.$store.getters["cashier/forceSmartPhoneCashierInTablet"];

      return result;
    },
    playerIsLoggedIn() {
      return this.$store.getters["session/loggedIn"];
    },
    allowPermissions() {
      const properties = this.allowProperties
        .split(",")
        .map(item => item + ";");

      return this.allowProperties ? properties.join("") : this.allowProperties;
    }
  },
  watch: {
    playerIsLoggedIn: function(loggedIn) {
      if (!loggedIn && Object.keys(this.cashierWindow).length)
        this.cashierWindow.close();
    }
  },
  methods: {
    ...mapActions("dialogs", ["showVerifyEmail", "confirmLoadCashier"]),
    async onShown() {
      const multipleGamesAtOnce = this.$store.getters[
        "session/multipleGamesAtOnce"
      ];
      if (multipleGamesAtOnce) this.showDialog = false;

      if (this.showCashierComplete) {
        try {
          await this.getCashierUrl(multipleGamesAtOnce);
        } catch (error) {
          this.showDialog = false;
        }
      }
    },
    submitForm() {
      if (this.loading) {
        setTimeout(() => {
          this.$refs.cashierForm.submit();
          this.loading = false;
        }, 1);
      }
    },
    async getCashierUrl(multipleGamesAtOnce) {
      const theme = "&theme=" + (this.$vuetify.theme.isDark ? "dark" : "light");
      this.fullCashierUrl = "";
      CDK.getCashierURL(this.cashierMode, this.couponCode).then(url => {
        if (this.redirectUrl && typeof this.redirectUrl !== "undefined") {
          url = url + `&redirectUrl=${this.redirectUrl}`;
        }
        url = url + theme;

        if (url) {
          if (multipleGamesAtOnce && this.largeLayout) {
            this.showCashierByWindow(url);
          } else {
            this.fullCashierUrl = url;
          }
        }
      });
    },

    async showCashierByWindow(url) {
      let CASHIER_WIDTH = await this.getCashierWidthMultipleGames();
      let CASHIER_HEIGHT = 735;
      if (window.screen.availWidth < CASHIER_WIDTH)
        CASHIER_WIDTH = window.screen.availWidth;
      if (window.screen.availHeight < CASHIER_HEIGHT)
        CASHIER_HEIGHT = window.screen.availHeight;

      this.cashierWindow = window.open(
        "",
        "Cashier",
        `height=${CASHIER_HEIGHT},width=${CASHIER_WIDTH}, scrollbars=no`
      );
      if (!this.cashierWindow) {
        window.LobbyCore.raise(window.LobbyCore.Events.confirmLoadCashier, {
          url: url,
          width: CASHIER_WIDTH,
          height: CASHIER_HEIGHT,
        });
      } else {
        this.cashierWindow.location = url;
      }
    },

    iFrameLoaded() {
      this.loading = false;
    },
    onHidden() {
      this.loading = true;
    },
    async close() {
      this.$store.dispatch("coupons/getActiveCoupon");
      this.$store.dispatch("coupons/updateActiveCouponMessage");
      window.LobbyCore.raise(
        window.LobbyCore.Events.hideCashierPage,
        this.cashierMode
      );
    },
    async attachCustomStyles() {
      const customStyles = await CDK.getCustomStylesTAG();
      if (customStyles) {
        try {
          const parsedStyle = getStyleText(customStyles);
          parsedStyle.forEach((element) => {
            const scriptElement = createStyleElement(element);
            document.body.appendChild(scriptElement);
          });
        } catch (exception) {
          window.console.error(`Unable to attach custom style: ${exception}`);
        }
      }
    },
    async getCashierWidthMultipleGames() {
      const cashierWidth = 920;
      const cashierConfiguration = await CDK.getTagConfiguration();
      if (cashierConfiguration && cashierConfiguration.CashierWidth)
        return cashierConfiguration.CashierWidth;
      return cashierWidth;
    },
  },
};
</script>

<style scoped>
::v-deep .customSize {
  width: 900px;
}
::v-deep .cashierDialog.largeCashier {
  min-height: 97vh;
}

@media screen and (min-width: 992px) {
  ::v-deep .cashierDialog.largeCashier {
    min-height: 80vh;
    width: 900px;
  }
}
@media screen and (min-width: 1400px) {
  ::v-deep .cashierDialog.largeCashier {
    width: 950px;
    min-height: 775px;
  }
}
@media screen and (min-width: 1600px) {
  ::v-deep .cashierDialog.largeCashier {
    width: 970px;
    min-height: 780px;
  }
}
@media screen and (min-width: 1920px) {
  ::v-deep .cashierDialog.largeCashier {
    width: 1000px;
    min-height: 790px;
  }
}
@media screen and (min-width: 2560px) {
  ::v-deep .cashierDialog.largeCashier {
    width: 1500px !important;
    min-height: 1290px !important;
  }
}
</style>
