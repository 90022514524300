<template>
  <v-app-bar class="pt-1" prominent color="tournamentBackground" flat>
    <v-img
      class="align-self-center"
      :src="tournamentLogo"
      max-width="20vmax"
      max-height="100%"
      contain
    />

    <v-container class="pa-1">
      <v-row justify="end">
        <v-icon v-if="!isDownloadClient" @click.stop="$emit('close')">
          mdi-close
        </v-icon>
      </v-row>
      <v-row align="center" no-gutters dense>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end mr-4">
          <div class="caption tournamentTxt--text">
            {{ $str("TournamentAlias") }}:
            <span class="font-weight-bold">{{ playerAlias }}</span>
          </div></v-col
        >
        <v-col cols="2" class="d-flex justify-end">
          <v-list-item class="pr-0">
            <v-list-item-content class="border-line text-center pa-1">
              <v-list-item-title
                v-if="displayServerTime"
                class="tournamentTime--text"
              >
                {{ displayServerTime }}
              </v-list-item-title>
              <v-list-item-title v-else class="tournamentTime--text">
                --:--
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters dense>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="d-flex justify-end mr-4">
          <v-menu
            v-if="showCouponPrizes"
            v-model="menu"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                max-width="390px"
                class="align-self-center"
                color="button buttonTxt--text"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>$vuetify.icons.Tournaments</v-icon>
                <span class="pl-1">{{ $strResourceService("MyPrizes") }}</span>
              </v-btn>
            </template>
            <CouponPrizes @close="menu = false" />
          </v-menu>
        </v-col>
        <v-col cols="auto" class="d-flex justify-end mr-4">
          <div class="body-1 tournamentTxt--text">
            <BalanceButton
              btn-txt-color="tournamentTxt--text"
              icon-color="
               tournamentTxt--text
              "
              :is-tournaments="true"
            /></div
        ></v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            class="align-self-center ml-2"
            color="tournamentDeposit tournamentDepositTxt--text"
            width="100%"
            :disabled="!playerAlias"
            @click="showDeposit"
            >{{ $str("DepositPage") }}</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BalanceButton from "@/components/BalanceButton";
import { clientType } from "@/services/casino";
import CouponPrizes from "@/components/TournamentsLobby/CouponPrizes";
export default {
  components: { BalanceButton, CouponPrizes },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapGetters("customizations", ["tournamentLogo"]),
    ...mapGetters("tournaments", [
      "playerAlias",
      "displayServerTime",
      "couponPrizes",
    ]),
    ...mapGetters("balances", ["withdrawable"]),
    isDownloadClient() {
      return clientType === CDK.ClientTypes.Download;
    },
    showCouponPrizes() {
      return this.couponPrizes.length > 0;
    },
  },
  methods: {
    ...mapActions("dialogs", ["showDeposit"]),
  },
};
</script>
<style scoped>
.border-line {
  border: solid 1px;
  border-radius: 3px;
  color: var(--v-tournamentTime-base);
}
</style>
