<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    persistent
    :content-class="isEmbedded ? 'rounded-0 dialogGL' : 'rounded-0'"
  >
    <div
      v-show="isEmbedded"
      id="bgGameLauncher"
      class="align-self-stretch grayBackground"
      style="height: 100%; width: 100%; position: absolute; overflow: hidden;"
      :style="imageBackgroundStyle"
      :class="backgroundUrl ? 'imageBackground' : ''"
    />
    <v-card
      v-if="isEmbedded"
      class="elevation-12 header align-self-stretch mt-16 gameContainer"
    >
      <TopNavSmall v-if="smallLayout" app />
      <TopNavLarge
        v-if="largeLayout && !isTournamentsOnly"
        app
        :show-games-menu="true"
        :close-game="closeModal"
      />
      <v-card-text class="pa-0" style="height: 100%;">
        <v-container
          v-show="iframe.isLoaded"
          id="gameButtonsContainer"
          fill-height
          allowfullscreen
          class="pa-0 justify-center"
        >
          <GameActionBar
            :width="iframe.style.width"
            :buttons="buttons"
            :is-fullscreen="isFullscreen"
          />
          <iframe
            id="gameIframe"
            allowfullscreen
            :src="iframe.url"
            :style="iframe.style"
            :class="!iframe.isLoaded ? 'noBorder' : ''"
            @load="iFrameLoaded"
          />
        </v-container>
        <v-overlay v-if="!iframe.isLoaded" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card-text>
    </v-card>
    <v-overlay absolute :value="!isEmbedded">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
/**
 * The component that manages the launching of games
 *
 * - The only visible part of this dialog is a progress indicator
 * - Games are launched using Game Launcher
 * - In mobile, the client navigates to the game URL in the same window
 * - In Instant Play, games will either display in an iframe or popup window
 */

import { dialogToggle } from "@/mixins/dialogToggle";
import {
  GameLaunchMode,
  openPopup,
  requestFullScreen,
  cancelFullScreen
} from "@/services/casino";
import { mapGetters, mapActions } from "vuex";
import TopNavSmall from "@/components/TopNavSmall.vue";
import TopNavLarge from "@/components/TopNavLarge.vue";
import GameActionBar from "@/components/GameActionBar.vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { toggleFavorite } from "@/mixins/toggleFavorite";
import { LobbyActions, LaunchLobbyAction, clientType } from "@/services/casino";

export default {
  components: { TopNavSmall, TopNavLarge, GameActionBar, ConfirmDialog },
  mixins: [dialogToggle, toggleFavorite],
  data() {
    return {
      name: "gameLauncher",
      gameUrl: "",
      token: "",
      intervalGamesVerifier: null,
      defaultIframeWidth: "70vw",
      defaultIframeHeight: "80vh",
      maxResolutionPercentage: 100,
      defaultResolutionPercentage: 75,
      isFullscreen: false,
      isMaximized: true,
      backgroundUrl: "",
      hasBalanceChange: false,
      displayGamesGuru: false,
      iframe: {
        url: undefined,
        isLoaded: false,
        style: {
          width: this.proccessDimensionByPercentage("Width"),
          height: this.proccessDimensionByPercentage("Height")
        }
      },
      maximizeButton: {
        icon: "mdi-window-maximize",
        action: this.resizeGame,
        tooltip: "Show 100%",
        order: 3
      },
      minimizeButton: {
        icon: "mdi-window-minimize",
        action: this.resizeGame,
        tooltip: "Show 75%",
        order: 3
      },
      openFullScreenButton: {
        icon: "mdi-fullscreen",
        action: this.requestFullScreen,
        tooltip: "Fullscreen",
        order: 2
      },
      closeFullScreenButton: {
        icon: "mdi-fullscreen-exit",
        action: cancelFullScreen,
        tooltip: "Close Fullscreen",
        order: 2
      },
      closeButton: {
        icon: "mdi-window-close",
        action: this.closeModal,
        tooltip: "Close Game",
        order: 1
      }
    };
  },
  computed: {
    ...mapGetters("session", [
      "gameLaunchMode",
      "openMultipleGamesAtOnceAllowed",
      "playForMoney",
      "skinId",
      "currentMultipleGamesOpened",
      "loggedIn",
      "mobileClient"
    ]),
    ...mapGetters("customizations", ["casinoLogo"]),
    favoriteButton() {
      return {
        icon: this.isFavorite ? "mdi-star" : "mdi-star-outline",
        action: this.toggleFavorite,
        color: this.isFavorite ? "yellow" : "grey",
        order: 4
      };
    },
    fullscreenButton() {
      return this.isFullscreen
        ? this.closeFullScreenButton
        : this.openFullScreenButton;
    },
    resizeButton() {
      return this.isMaximized ? this.minimizeButton : this.maximizeButton;
    },
    buttons() {
      return (this.loggedIn
        ? [
            this.favoriteButton,
            this.resizeButton,
            this.fullscreenButton,
            this.closeButton
          ]
        : [this.resizeButton, this.fullscreenButton, this.closeButton]
      ).sort((a, b) => b.order - a.order);
    },
    gameId() {
      // The gameId is set by the dialog system before the dialog is opened
      return this.$store.getters["dialogs/gameLauncher"].data.gameId;
    },
    game() {
      return this.$store.getters["games/games"][this.gameId];
    },
    isFlashGame() {
      return (
        this.game.downloadGameTechnology === 2 ||
        this.game.instantPlayGameType === CDK.EInstantPlayGameTypes.Flash
      );
    },
    isFlashEolMessageEnabled() {
      return (
        this.isFlashGame &&
        CDK.getFlashEolMessageEnabled() &&
        !this.mobileClient
      );
    },
    gameLauncherParams() {
      return (
        this.$store.getters["dialogs/gameLauncher"].data.gameLauncherParams ||
        {}
      );
    },
    gameSearchParams() {
      const queryString = new URL(this.gameUrl).search;
      return Object.fromEntries(new URLSearchParams(queryString));
    },
    // This can be a function or watcher,depending if it has to be reactive
    isEmbedded() {
      return this.gameLaunchMode === GameLaunchMode.Embedded;
    },
    imageBackgroundStyle() {
      let style = "";
      if (this.backgroundUrl) {
        style = {
          background: `url('${this.backgroundUrl}')`
        };
      }
      return style;
    },
    baseUrlParams() {
      const params = {
        cdkModule: "gameLauncher",
        forceFormFactor: this.gameSearchParams.forceFormFactor || "",
        gameId: this.game.gameId,
        height: this.gameSearchParams.height || "auto",
        integration: CDK.IntegrationMode.external,
        isLobbyCore: "true",
        Language: CDK.getLanguageCode(),
        machId: this.game.machId,
        numOfHands: this.game.hands,
        returnURL: this.makeReturnUrl(),
        skinId: CDK.getSkinID(),
        useExistingSession: "true",
        width: this.gameSearchParams.width || "auto",
        token: this.token ? this.token : this.gameSearchParams.token || "",
        isThemeDark: this.$vuetify.theme.dark
      };

      const refreshTokens = this.$store.getters["session/refreshTokens"];
      if (clientType === CDK.ClientTypes.InstantPlay || refreshTokens) {
        params.clearRefreshTokens = "true";
      }

      return {
        ...this.gameSearchParams,
        ...params,
        ...this.gameLauncherParams
      };
    },
    multipleGamesAtOnceOverwrittenParams() {
      const params = {
        height: this.gameSearchParams.height || "auto",
        width: this.gameSearchParams.width || "auto",
        integration: CDK.IntegrationMode.popup,
        useExistingSession: true,
        debug: this.gameSearchParams.debug || false,
        nofullscreen: this.gameSearchParams.nofullscreen || false,
        debugls: this.gameSearchParams.debugls || false,
        fps: this.gameSearchParams.fps || 0
      };
      return {
        ...this.baseUrlParams,
        ...params
      };
    },
    embeddedGameOverwrittenParams() {
      const params = {
        integration: CDK.IntegrationMode.embedded,
        useExistingSession: true,
        debug: this.gameSearchParams.debug || false,
        nofullscreen: this.gameSearchParams.nofullscreen || false,
        debugls: this.gameSearchParams.debugls || false,
        fps: this.gameSearchParams.fps || 0
      };
      return {
        ...this.baseUrlParams,
        ...params
      };
    }
  },
  methods: {
    ...mapActions("games", [
      "setLastPlayedGame",
      "showGamesGuru",
      "confirmLoadGame"
    ]),
    ...mapActions("session", [
      "gameLaunchFailed",
      "addCurrentMultipleGamesOpened",
      "updateHasBalanceChangeCurrentOpenedGame",
      "removeCurrentMultipleGamesOpened"
    ]),
    closeModal() {
      cancelFullScreen();
      this.showDialog = false;
      document.documentElement.style.overflow = "auto";
    },
    requestFullScreen() {
      requestFullScreen(document.getElementById("gameButtonsContainer"));
    },
    showFlashEolMessage() {
      const platformMsg = this.game.mobileEnabled
        ? `            
            <div>
              ${this.$str("FlashDeprecationMessagePart2")}
              <ul><li>${this.$str("PlatformMobile")}</li></ul>
            </div>`
        : "";
      const message = `
          <div class="flashDeprecationInfoModal">
            <div>${this.$str("FlashDeprecationMessagePart1")}</div>
            ${platformMsg}
            <div>${this.$str("FlashDeprecationMessagePart3")}</div>
          </div>`;
      this.$nextTick(() => {
        this.$refs.confirm.openAlert({
          title: this.$str("PopupBlockerWarningTitle"),
          message,
          buttonTxt: this.$str("Close"),
          width: "45vw"
        });
        this.showDialog = false;
      });
    },

    showJackpotWinPendingGameMessage() {
      this.$nextTick(() => {
        this.$refs.confirm.openAlert({
          title: this.$str("PopupBlockerWarningTitle"),
          message: this.$str("GameUnavailableJackpotWinOnReview"),
          buttonTxt: this.$str("Accept"),
          width: "40vw"
        });
        this.showDialog = false;
      });
    },

    showGameCouponRestrictedMessage() {
      this.$nextTick(() => {
        this.$refs.confirm.openAlert({
          title: this.$str("PopupBlockerWarningTitle"),
          message: this.$str("CurrentGameCouponRestricted"),
          buttonTxt: this.$str("Accept"),
          width: "37vw"
        });
        this.showDialog = false;
      });
    },

    async onShown() {
      Object.assign(this.$data, this.$options.data.apply(this));
      try {
        if (this.isFlashEolMessageEnabled) {
          this.showFlashEolMessage();
        } else {
          const validationResult = await this.ValidateGamePrerequisitesAndPendingGames();
          if (!validationResult.CanLaunchGame) {
            if (validationResult.PrerequisiteResult.ErrorCode === 4) {
              this.showGameCouponRestrictedMessage();
            } else if (validationResult.PrerequisiteResult.ErrorCode === 5) {
              this.showJackpotWinPendingGameMessage();
            } else if (validationResult.MultipleGamesAtOnceEnabled) {
              setTimeout(() => {
                this.showPendingGames(false, validationResult.PendingGames);
              }, 1);
            } else {
              setTimeout(() => {
                this.showPendingGames(true, validationResult.PendingGames);
              }, 1);
            }
          } else {
            document.documentElement.style.overflow = "hidden";
            await this.launchGame();
            if (this.isEmbedded) this.addListener();
            if (!this.loggedIn || (!this.playForMoney && this.isEmbedded))
              CDK.on(CDK.Events.loggedIn, this.onLogin);
            // As soon as the dialog is shown, start launching the game
          }
        }
      } catch (error) {
        this.showDialog = false;
        this.gameLaunchFailed();
      }
    },
    onLogin() {
      this.launchGame();
    },
    onHidden() {
      document.documentElement.style.overflow = "auto";
      if (this.isEmbedded) {
        if (this.displayGamesGuru) {
          this.showGamesGuru({
            gameId: this.gameId,
            balanceChanged: this.hasBalanceChange
          });
        }
        this.removeListener();
        if (this.game) {
          CDK.raise(CDK.Events.notifyGameClosed, this.game);
        }
      }
      CDK.off(CDK.Events.loggedIn, this.onLogin);
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    iFrameLoaded() {
      this.iframe.isLoaded = true;
    },
    addListener() {
      window.addEventListener("resize", this.syncIframeDimensions, {
        passive: true
      });
      window.addEventListener("message", this.gameEventsListeners, {
        passive: true
      });
    },
    removeListener() {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", this.syncIframeDimensions, {
          passive: true
        });
        window.removeEventListener("message", this.gameEventsListeners, {
          passive: true
        });
      }
    },
    gameEventsListeners(event) {
      const eventData = event.data;
      switch (eventData.request) {
        case "notifyGameLoading": {
          const generateId = id => {
            const idArray = id.split("-");
            idArray.pop();
            return idArray.join("-");
          };
          const oldGameId = generateId(this.gameId);
          const newGameId = generateId(eventData?.params?.uniqueId);
          if (oldGameId !== newGameId) {
            CDK.getExternalResource(
              eventData?.params?.uniqueId,
              "embed-background.jpg",
              CDK.FormFactors.desktop
            ).then(backgroundImageUrl => {
              this.checkBackgroundImg(backgroundImageUrl).then(
                isImageAssigned => {
                  if (!isImageAssigned) {
                    CDK.getExternalResource(
                      eventData?.params?.uniqueId,
                      "embed-background.jpg",
                      CDK.FormFactors.tablet
                    ).then(this.checkBackgroundImg);
                  }
                }
              );
            });
          }
          break;
        }
        case "launchGame": {
          this.displayGamesGuru = false;
          this.$store.dispatch("dialogs/hideAll");
          LaunchLobbyAction(this.$store, LobbyActions.LaunchGame, {
            gameId: eventData?.params?.uniqueId
          });
          break;
        }
        case "showDepositPage": {
          if (eventData?.params?.fromFreeSpinDialog)
            this.displayGamesGuru = false;

          const cashierMode =
            eventData?.params?.cashierMode || CDK.CashierModes.Cashier;

          switch (cashierMode) {
            case CDK.CashierModes.Coupons: {
              LaunchLobbyAction(this.$store, LobbyActions.RedeemCoupon, {
                couponCode: eventData?.params?.couponCode || ""
              });
              break;
            }
            case CDK.CashierModes.CompPoints: {
              LaunchLobbyAction(this.$store, LobbyActions.CompPoints);
              break;
            }
            case CDK.CashierModes.Cashier:
            default: {
              LaunchLobbyAction(this.$store, LobbyActions.Deposit);
              break;
            }
          }
          break;
        }
        case "exitGame": {
          LaunchLobbyAction(this.$store, LobbyActions.ExitGame);
          break;
        }
      }
    },

    syncIframeDimensions() {
      this.isFullscreen = document.fullscreenElement;
      if (document.fullscreenElement) {
        this.iframe.style.height = "100%";
        this.iframe.style.width = "100%";
      } else {
        this.iframe.style.height = !this.isMaximized
          ? this.defaultIframeHeight
          : this.proccessDimensionByPercentage("Height");
        this.iframe.style.width = !this.isMaximized
          ? this.defaultIframeWidth
          : this.proccessDimensionByPercentage("Width");
      }
    },
    async checkBackgroundImg(backgroundImageUrl) {
      const errorMsg = "Background doesn't exist. Applying default background.";
      if (backgroundImageUrl) {
        return fetch(backgroundImageUrl)
          .then(response => {
            if (!response.ok) {
              this.backgroundUrl = "";
              window.console.warn(errorMsg);
              return false;
            }
            this.backgroundUrl = backgroundImageUrl;
            return true;
          })
          .catch(() => {
            this.backgroundUrl = "";
            window.console.warn(errorMsg);
            return false;
          });
      } else {
        this.backgroundUrl = "";
        window.console.warn(errorMsg);
        return false;
      }
    },
    onSingleModeBalanceChangedConfig() {
      this.displayGamesGuru = true;
      CDK.on(CDK.Events.balanceChanged, balance => {
        window.console.log(
          `GameLauncher: Game: ${this.gameId} has change balance to: ${balance}`
        );
        this.hasBalanceChange = true;
      });
    },
    multipleGamesAtOnceBalanceListener(balanceData) {
      if (balanceData.GameId >= 0) {
        const gameUid = `${balanceData.GameId}-${balanceData.MachId}-0`;
        window.console.log(
          `GameLauncher: Game: ${gameUid} has change balance to: ${balanceData.Balance}`
        );
        this.updateHasBalanceChangeCurrentOpenedGame({
          gameUid: gameUid,
          hasBalanceChange: true
        });
      }
    },
    onMultipleModeBalanceChangedConfig() {
      this.displayGamesGuru = true;
      window.console.log(`GameLauncher: Preparing Balances listeners`);
      CDK.on(
        CDK.Events.balancesChanged,
        this.multipleGamesAtOnceBalanceListener
      );
    },
    async launchGame() {
      Object.assign(this.$data, this.$options.data.apply(this));
      let backgroundImageUrl = "";
      [this.gameUrl, this.token, backgroundImageUrl] = await Promise.all([
        CDK.getGameURL(this.gameId).then((URL, html5Url) => html5Url || URL),
        this.loggedIn && CDK.generateGameToken(),
        this.isEmbedded &&
          CDK.getExternalResource(
            this.gameId,
            "embed-background.jpg",
            CDK.FormFactors.desktop
          )
      ]);
      this.setLastPlayedGame({ gameId: this.gameId });
      switch (this.gameLaunchMode) {
        case GameLaunchMode.ThisWindow:
          this.launchGameInThisWindow();
          break;
        case GameLaunchMode.NewWindow:
          this.launchGameInNewWindow();
          break;
        case GameLaunchMode.Embedded:
          this.checkBackgroundImg(backgroundImageUrl).then(isImageAssigned => {
            if (!isImageAssigned) {
              CDK.getExternalResource(
                this.gameId,
                "embed-background.jpg",
                CDK.FormFactors.tablet
              ).then(this.checkBackgroundImg);
            }
          });
          this.launchGameInIFrame();
          break;
      }
    },
    launchGameInThisWindow() {
      window.location.href = this.makeGameLauncherUrl(this.baseUrlParams);
      this.showDialog = false;
    },
    launchGameInNewWindow() {
      this.showDialog = false;
      if (!this.getCurrentGameOpened()) {
        if (this.openMultipleGamesAtOnceAllowed) {
          const url = this.makeGameLauncherUrl(
            this.multipleGamesAtOnceOverwrittenParams
          );
          const gameWindow = openPopup(
            url,
            this.multipleGamesAtOnceOverwrittenParams.height,
            this.multipleGamesAtOnceOverwrittenParams.width,
            this.currentMultipleGamesOpened
          );

          if (!gameWindow) {
            window.LobbyCore.raise(window.LobbyCore.Events.confirmLoadGame, {
              url: url,
              title: this.game.name,
              width: this.multipleGamesAtOnceOverwrittenParams.width,
              height: this.multipleGamesAtOnceOverwrittenParams.height
            });
          }

          window.addEventListener("message", this.gameEventsListeners, {
            passive: true
          });
          setTimeout(() => {
            this.onMultipleModeBalanceChangedConfig();
          }, 1);
          const gameUid = this.game.uniqueId;

          this.startIntervalGameVerifier();
          this.addCurrentMultipleGamesOpened({ gameUid, gameWindow });
        } else {
          // TODO: Message to let user know that cannot open more games at once
          this.$refs.confirm.openAlert({
            message: this.$str("TooManyGamesOpen"),
            title: this.$str("PopupBlockerWarningTitle"),
            buttonTxt: this.$str("Accept"),
            width: "37vw"
          });
        }
      } else {
        this.getCurrentGameOpened().gameWindow.focus();
      }
    },
    launchGameInIFrame() {
      this.onSingleModeBalanceChangedConfig();
      this.iframe.url = this.makeGameLauncherUrl(
        this.embeddedGameOverwrittenParams
      );
      this.syncIframeDimensions();
    },
    makeGameLauncherUrl(params) {
      const baseUrl = this.gameUrl.substring(0, this.gameUrl.indexOf("?"));
      const newUrl = new URL(baseUrl);
      const newParams = newUrl.searchParams;
      for (const param in params) {
        newParams.set(param, params[param]);
      }
      return newUrl.toString();
    },
    makeReturnUrl() {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete("clientType");
      currentUrl.searchParams.set("skinId", this.skinId);
      return encodeURIComponent(currentUrl.toString());
    },
    getCurrentGameOpened() {
      return this.currentMultipleGamesOpened.find(
        x => x.gameUid === this.game.uniqueId
      );
    },
    startIntervalGameVerifier() {
      if (this.intervalGamesVerifier === null) {
        this.intervalGamesVerifier = setInterval(() => {
          for (const game of this.currentMultipleGamesOpened) {
            if (game.gameWindow?.closed) {
              CDK.off(CDK.Events.loggedIn, this.onLogin);
              if (this.displayGamesGuru) {
                setTimeout(() => {
                  this.showGamesGuru({
                    gameId: game.gameUid,
                    balanceChanged: game.hasBalanceChange
                  });
                }, 1);
              }
              this.removeCurrentMultipleGamesOpened(game.gameUid);
            }
          }
          this.clearIntervalGameVerifier();
        }, 500);
      }
    },
    clearIntervalGameVerifier() {
      if (
        this.currentMultipleGamesOpened.length === 0 &&
        this.intervalGamesVerifier
      ) {
        window.clearInterval(this.intervalGamesVerifier);
        this.intervalGamesVerifier = null;
        CDK.off(
          CDK.Events.balancesChanged,
          this.multipleGamesAtOnceBalanceListener
        );
      }
    },
    resizeGame() {
      this.isMaximized = !this.isMaximized;
      cancelFullScreen();
      this.syncIframeDimensions();
    },
    proccessDimensionByPercentage(measurement) {
      let pixels = window[`inner${measurement}`];
      if (measurement === "Height") pixels -= 185;
      return `${(this.maxResolutionPercentage * pixels) / 92}px`;
    },
    async showPendingGames(showForSingleMode, pendingGames) {
      this.$store.dispatch("dialogs/showPendingGames", {
        showForSingleMode: showForSingleMode,
        pendingGames: pendingGames
      });
      this.showDialog = false;
    },
    async GetPendingGames() {
      let pendingGamesResult = [];
      if (CDK.getMultipleGamesAtOnceEnabled()) {
        await CDK.getPendingGames().then(pendingGames => {
          pendingGamesResult = pendingGames;
        });
      }
      return pendingGamesResult;
    },
    async GetPendingGamesFromJackpotWins() {
      let pendingGamesJackpotResult = [];
      if (
        CDK.getMultipleGamesAtOnceEnabled() &&
        CDK.getAllowPlayerPlayAfterWonJackpot()
      ) {
        await CDK.getPendingGamesFromJackpotWins().then(pendingGamesJackpot => {
          pendingGamesJackpotResult = pendingGamesJackpot;
        });
      }
      return pendingGamesJackpotResult;
    },
    async ValidateGamePrerequisitesAndPendingGames() {
      const result = {
        PendingGames: [],
        PrerequisiteResult: null,
        MultipleGamesAtOnceEnabled: false,
        CanLaunchGame: true
      };

      // STIPD-69 If we are launching a tournament game in instant play, then we can ignore checks here
      if (
        clientType === CDK.ClientTypes.InstantPlay &&
        this.gameLauncherParams.tournamentId &&
        this.gameLauncherParams.tournamentId > 0
      ) {
        if (CDK.getMultipleGamesAtOnceEnabled()) {
          result.MultipleGamesAtOnceEnabled = true;
        }
        return result;
      }
      // END STIPD-69

      // Mobile does its own validation
      if (clientType === CDK.ClientTypes.InstantPlay && CDK.isLoggedIn()) {
        await Promise.all([
          CDK.CheckGamePrerequisite(this.game, 0),
          this.GetPendingGames(),
          this.GetPendingGamesFromJackpotWins()
        ]).then(values => {
          const filteredPendingGames = [];
          const prerequisiteResult = values[0];
          const pendingGames = values[1];
          const pendingJackpotWinsGames = values[2];
          let gameToLaunchIsPending = false;

          if (this.game.gameId === -1) {
            prerequisiteResult.ErrorCode = 0;
          }

          // Check for restricted game
          // Check for pending game related to jackpot Win(Malfunction set on)
          if (
            prerequisiteResult.ErrorCode === 4 ||
            prerequisiteResult.ErrorCode === 5
          ) {
            result.CanLaunchGame = false;
          }

          if (CDK.getMultipleGamesAtOnceEnabled() && result.CanLaunchGame) {
            result.MultipleGamesAtOnceEnabled = true;
            if (typeof game === "string") {
              this.game = CDK.getGameById(this.game);
            }
            pendingGames.forEach(g => {
              // If the game being launched is a game from the pending games list, allow it.
              // Watch out for null game param, which is seen when launching a multi-player roulette game
              // or a game that does not exist on the current platform (e.g., a download client only game).
              if (this.game && this.game.uniqueId === g.uniqueId) {
                gameToLaunchIsPending = true;
              }
              // If this game (from the pending games list) is not in the list of active games,
              // add it to filtered pending games list.
              else if (
                !this.currentMultipleGamesOpened ||
                this.currentMultipleGamesOpened.length === 0 ||
                !this.currentMultipleGamesOpened.find(
                  x => x.gameUid === g.uniqueId
                )
              ) {
                if (
                  pendingJackpotWinsGames.length === 0 ||
                  pendingJackpotWinsGames.indexOf(g.uniqueId) < 0
                ) {
                  filteredPendingGames.push(g);
                }
              }
            });

            if (gameToLaunchIsPending || filteredPendingGames.length === 0) {
              result.CanLaunchGame = true;
            } else {
              result.CanLaunchGame = false;
              result.PendingGames = filteredPendingGames;
            }
          } else {
            if (prerequisiteResult.ErrorCode > 0 && result.CanLaunchGame) {
              result.CanLaunchGame = false;
              result.PendingGames.push(
                CDK.getGameById(prerequisiteResult.UniqueID)
              );
            }
          }
          result.PrerequisiteResult = prerequisiteResult;
        });
      }

      return result;
    }
  }
};
</script>

<style scoped>
::v-deep .dialogGL {
  background-color: var(--v-popupBackground-base) !important;
}
@media only screen and (min-width: 600px) {
  ::v-deep .v-dialog {
    border: none !important;
    border-radius: 0 !important;
  }
}

.container.fill-height {
  max-width: 1600px;
}
.gameContainer.header {
  padding-top: 85px !important;
  margin-left: 25%;
  margin-top: 10vh;
  background: transparent !important;
}

.grayBackground {
  background: #b5bdc8;
  background: -moz-linear-gradient(top, #b5bdc8 0%, #28343b 100%);
  background: -webkit-linear-gradient(top, #b5bdc8 0%, #28343b 100%);
  background: linear-gradient(to bottom, #b5bdc8 0%, #28343b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5bdc8', endColorstr='#28343b',GradientType=0 );
}

.imageBackground {
  background-repeat: no-repeat !important;
  background-position: center 30% !important;
  background-attachment: unset !important;
  background-size: cover !important;
  filter: blur(3px) !important;
  -webkit-filter: blur(3px) !important;
}

.noBorder {
  border: none;
}
</style>
<style lang="scss">
.flashDeprecationInfoModal {
  text-align: left;
  div {
    margin-bottom: 1.5vh;
  }
}
</style>
