import { render, staticRenderFns } from "./TournamentTile.vue?vue&type=template&id=65619ec3&scoped=true&"
import script from "./TournamentTile.vue?vue&type=script&lang=js&"
export * from "./TournamentTile.vue?vue&type=script&lang=js&"
import style0 from "./TournamentTile.vue?vue&type=style&index=0&id=65619ec3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65619ec3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VContainer})
