/**
 * Games, menus, and jackpots
 */

import Vue from "vue";
import {
  casinoConnected,
  restoreSessionCompleted,
  GamesViewMode
} from "@/services/casino";

const state = {
  menuIds: [],
  menus: {},
  menuGamesLists: {},
  favoritesMenuId: -1,
  recentGamesMenuId: 99,
  recentGamesMenu: {},
  recentGamesList: [],
  gameIds: [],
  games: {},
  gameJackpots: {},
  gameIsFavorite: {},
  gameIsExcluded: {},
  gameSearchData: [],
  gamesViewMode: GamesViewMode.Grid,
  menuDataLoaded: false,
  minGamesToShowSeeAllButton: 11,
  lastPlayedGame: null,
  gameFamilyIcons: {
    Home: "$home",
    "All Games": "$allGames",
    "Recent Games": "$recentGames",
    Favorites: "$favorites",
    "Table Games": "$tableGames",
    "Crash Games": "$crashGames",
    Slots: "$slots",
    "Video Poker": "$videoPoker",
    Specialty: "$specialty",
    Progressives: "$progressives",
    "External Games": "$liveDealer",
    "Live Dealer": "$liveDealer",
    "New Games": "$newGames",
    Tournaments: "$Tournaments",
    Popular: "$popular",
    "Live Dealer EVO": "$liveDealer"
  },
  gameSortOptions: [
    {
      id: 1,
      attribute: "releaseDate",
      direction: "ASC",
      displayName: "Latest",
      icon: ""
    },
    {
      id: 2,
      attribute: "name",
      direction: "DESC",
      displayName: "Name",
      icon: "mdi-arrow-down"
    },
    {
      id: 3,
      attribute: "name",
      direction: "ASC",
      displayName: "Name",
      icon: "mdi-arrow-up"
    },
    {
      id: 4,
      attribute: "jackpot",
      direction: "DESC",
      displayName: "Jackpot",
      icon: "mdi-arrow-down"
    },
    {
      id: 5,
      attribute: "jackpot",
      direction: "ASC",
      displayName: "Jackpot",
      icon: "mdi-arrow-up"
    }
  ],
  refreshFavorites: true
};
const externalGamesKey = "__ExternalGames__";
const getters = {
  /** Whether the menu data has been loaded, either from cache or the server */
  menuDataLoaded: state => state.menuDataLoaded,

  /** An array containing all of the menuIds */
  menuIds: state => {
    // Insert 'fake' recent games menuId... it should always go after New Games
    const newGamesIndex = state.menuIds.findIndex(
      menuId => state.menus[menuId].name === "New Games"
    );

    const upToNewGames = state.menuIds.slice(0, newGamesIndex + 1);
    const afterNewGames = state.menuIds.slice(newGamesIndex + 1);

    return [...upToNewGames, state.recentGamesMenuId, ...afterNewGames];
  },

  /** An array containing menuIds of all menus that contain at least 1 game */
  populatedMenuIds: (state, getters) => {
    return CDK.isLoggedIn()
      ? getters.menuIds.filter(
          menuId =>
            getters.menuGamesLists[menuId].length > 0 ||
            state.menus[menuId]?.name === "Tournaments" ||
            state.menus[menuId]?.name === "Sportsbook" ||
            state.menus[menuId]?.name === "Live Dealer EVO"
        )
      : getters.menuIds.filter(
          menuId =>
            (getters.menuGamesLists[menuId].length > 0 &&
              menuId !== state.recentGamesMenuId &&
              menuId !== state.favoritesMenuId) ||
            state.menus[menuId]?.name === "Sportsbook"
        );
  },

  /** An array containing menu objects of all menus that contain at least 1 game */
  populatedMenus: (state, getters) => {
    const populatedMenus = getters.populatedMenuIds.map(
      menuId => getters.menus[menuId]
    );
    return populatedMenus;
  },

  populatedMainMenuIds: (state, getters) => {
    //Remove tournaments from showing in main lobby menu
    return getters.populatedMenuIds.filter(
      id => getters.menus[id].name !== "Tournaments"
    );
  },

  /** Game menu objects of game family menus that contain at least 1 game */
  populatedGameFamilyMenus: (state, getters, rootState, rootGetters) => {
    const menus = getters.populatedMenus
      .filter(menu => {
        switch (menu.name) {
          case "Recent Games":
          case "Favorites":
          case "New Games":
          case "Table Games":
          case "Slots":
          case "Video Poker":
          case "Specialty":
          case "Progressives":
          case "Crash Games":
            return true;
          case "Tournaments":
            return rootGetters["session/playForMoney"];
          case "External Games":
            return (
              rootGetters["session/loggedIn"] &&
              filterExternalLobbies(rootGetters)
            );
          case "Live Dealer":
            return (
              rootGetters["session/loggedIn"] &&
              rootGetters["externalLobbies/lobbies"]?.LiveDealer.isVisible
            );
          default:
            return true;
        }
      })
      .filter(menu => menu.isVisible);

    return menus.sort((menuA, menuB) => menuA.index - menuB.index);
  },

  /** A map from menuIds to menu objects */
  menus: state => ({
    [state.recentGamesMenuId]: state.recentGamesMenu,
    ...state.menus
  }),

  menuItemByName: (state, getters) => name => {
    return state.menuIds.find(i => getters.menus[i].name === name);
  },

  /** A map from menuIds to a list of gameIds contained in that menu */
  menuGamesLists: state => ({
    [state.recentGamesMenuId]: state.recentGamesList,
    ...state.menuGamesLists
  }),

  /** An array of all gameIds (game uniqueIds) */
  gameIds: state => state.gameIds,

  /** A map of gameIds to game objects */
  games: state => state.games,

  /** A map of gameIds to current jackpot amount for that game */
  gameJackpots: state => state.gameJackpots,

  /** A map of gameIds to whether or not that game is favorite */
  gameIsFavorite: state => state.gameIsFavorite,

  /** A map of gameIds to whether or not that game is excluded */
  gameIsExcluded: state => state.gameIsExcluded,

  /**
   * An array of game search data objects, which contain the data that makes
   * a game searchable (name, family names it belongs to, etc.)
   */
  gameSearchData: state => state.gameSearchData,

  /** The display mode for the Games view */
  gamesViewMode: state => state.gamesViewMode,

  /**
   * Returns a function that takes a menuId, and returns whether or not the
   * "see all" button should be shown for that game in the Home view.
   */
  showSeeAll: state => menuId => {
    const menu = state.menus[menuId];
    if (!menu) {
      return false;
    }

    switch (menu.name) {
      // Primary game families always show the button
      case "Table Games":
      case "Slots":
      case "Video Poker":
      case "Specialty":
      case "Progressives":
      case "Crash Games":
        return true;

      // Other menus only show the button if they have enough games
      case "More Games":
      case "Popular":
      case "Favorites":
      case "New Games":
      case "External Games":
      case "Live Dealer":
      case "Recent Games":
      default: {
        const numGames = state.menuGamesLists[menuId].length;
        return numGames >= state.minGamesToShowSeeAllButton;
      }
    }
  },

  /** Used by switchToRealFromGame to re-launch the game after switching */
  lastPlayedGame: state => state.lastPlayedGame,

  /** Map of game family names (menu.name) to vuetify icon names */
  gameFamilyIcons: state => state.gameFamilyIcons,

  gameSortOptions: state => state.gameSortOptions,

  /** Eval Menu if it has to be shown */
  menuIsVisible: (state, getters, rootState, rootGetters) => menuId => {
    //Find if last played is visible
    if (menuId === state.recentGamesMenuId) {
      const lastPlayedMenuId = state.menuIds.find(
        menuId => state.menus[menuId].name === "Last Played"
      );
      menuId = lastPlayedMenuId;
    }
    const isExternal =
      state.menus[menuId]?.uniqueId ===
      rootGetters["externalLobbies/externalLobbyKey"];
    return isExternal
      ? rootGetters["externalLobbies/isVisible"]
      : state.menus[menuId]
      ? state.menus[menuId].isVisible
      : true;
  },
  /** Eval game if it has to show the star */
  showFavoriteStar: (state, getters, rootState, rootGetters) => game => {
    const loggedIn = rootGetters["session/loggedIn"];
    return loggedIn && !game.isExternalGame;
  },
  /** Eval game if it has to show the details button */
  showDetailsButton: () => game => !game?.isExternalGame,
  /** Eval game if it has to show the try it button */
  showTryItButton: (state, getters, rootState, rootGetters) => () =>
    !rootGetters["session/loggedIn"],
  gameIcon: (state, getters, rootState, rootGetters) => game => {
    
    // HOTFIX: MRI-16591 - If no game ref, return empty string
    //   so as not to break the entire lobby
    if (!game) {
      return '';
    }
    
    // If a game doesn't have an image name, use the old imageURL
    // The "Live Dealer" game doesn't have an image name or new belly art
    if (!game.imageName) {
      return game.imageURL;
    }

    const iconType = rootGetters["customizations/lobbyIconType"];

    // Show some fallback belly arts in development mode
    if (process.env.NODE_ENV !== "production") {
      if (!game.imageURLs[iconType]) {
        if (iconType === CDK.LobbyIconTypes.square) {
          return require("@/assets/dev/BAAsgardDeluxe.jpg");
        } else if (iconType === CDK.LobbyIconTypes.tall) {
          return require("@/assets/dev/tall_BASmallFortune.jpg");
        }
      }
    }

    return game.imageURLs[iconType];
  }
};

/**
 * MenuData might be loaded from the cache or the server. This flag is set to
 * true only after the menu data from the server itself has loaded.
 */
let menuDataHydrated = false;

/** Currently, the way to identify the favorites menu is by checking the name */
const menuIsFavorite = menu => menu.name === "Favorites";

/**
 * Find the id of the favorites menu - it is treated differently than other
 * menus in a few places
 */
const getFavoritesMenuId = menus => {
  const favoritesMenu = menus.find(menuIsFavorite);
  return favoritesMenu ? favoritesMenu.id : -1;
};

/**
 * The menu objects returned from the server have lists of game objects, but we
 * want to just store lists of gameIds instead.
 */
const makeGamesListsByMenuId = (menus, games) => {
  const makeGamesList = menu => {
    if (menuIsFavorite(menu)) {
      /** The favorites menu is built from actual favorites flags on games */
      return games.filter(g => g.getIsFavorite()).map(g => g.uniqueId);
    } else {
      return menu.games.map(game => game.uniqueId);
    }
  };

  const gamesListsByMenuId = Object.fromEntries(
    menus.map(menu => [menu.id, makeGamesList(menu)])
  );

  return gamesListsByMenuId;
};

/**
 * Build the data consumed by the game search. You can search by game name,
 * family name, etc.
 *
 * ex: "Aladdin" or "Slots" or "Progressives"
 */

const makeGameSearchData = (games, menusById) => {
  return games.map(game => {
    const familyNames =
      game.subMenuIds
        .map(subMenuId =>
          menusById[subMenuId.split("-")[0]]?.displayName?.toLowerCase()
        )
        .filter(Boolean) || [];
    return {
      familyNames,
      name: game.name,
      imageURL: game.imageURL,
      gameId: game.uniqueId,
      tagLabels: game.TagLabels,
      isExternalGame: game.isExternalGame,
      externalLobbyId: game.externalLobbyId
    };
  });
};

/** Listen to CDK updates about game favorite status */
const registerGameEventListeners = (games, commit) => {
  games.forEach(game => {
    if (!game.isExternalGame) {
      game.on(CDK.LobbyGame.Events.isFavorite, () =>
        commit("updateSingleFavoriteGame", {
          gameId: game.uniqueId,
          isFavorite: true
        })
      );
      game.on(CDK.LobbyGame.Events.isNotFavorite, () =>
        commit("updateSingleFavoriteGame", {
          gameId: game.uniqueId,
          isFavorite: false
        })
      );
    }
  });
};

/**
 * Sort the menus returned from the server so we can force "New Games" to the top.
 */
const sortMenus = menus => {
  const newGamesMenu = menus.find(menu => menu.name === "New Games");
  const favMenu = menus.find(menu => menu.name === "Favorites");
  return newGamesMenu || favMenu
    ? [
        newGamesMenu ? newGamesMenu : [],
        favMenu ? favMenu : [],
        ...menus
          .filter(menu => menu !== newGamesMenu)
          .filter(menu => menu !== favMenu)
      ]
    : menus;
};

const getExtGameBellyArt = (context, uniqueId) => {
  try {
    const iconType = context.rootGetters["customizations/lobbyIconType"];
    if (iconType === CDK.LobbyIconTypes.tall) {
      return require("@/assets/extLobbies/tall_BA" + uniqueId + ".jpg");
    } else {
      return require("@/assets/extLobbies/" + uniqueId + ".jpg");
    }
  } catch (err) {
    return context.rootGetters["customizations/lazyLoadingImage"];
  }
};

const getImageURL = (context, uniqueId) => {
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      resolve(getExtGameBellyArt(context, uniqueId));
    }, 300);
    setTimeout(function() {
      reject("Error retrieving image Url (games module)");
    }, 500);
  });
};

const addExternalLobbiesInfo = (context, { menus, games }) => {
  const externalLobbies = context.rootGetters["externalLobbies/lobbies"];
  const externalLobbyKey =
    context.rootGetters["externalLobbies/externalLobbyKey"];
  if (externalLobbyKey) {
    const externalGames = Object.values(externalLobbies).map(lobby => ({
      isExternalGame: true,
      isNewGame: false,
      name: lobby.displayName,
      uniqueId: lobby.uniqueId,
      gameId: lobby.uniqueId,
      isVisible: lobby.isVisible,
      externalLobbyId: lobby.externalLobbyId,
      imageURL: getExtGameBellyArt(context, lobby.uniqueId),
      getGameURL: getImageURL(context, lobby.uniqueId),
      getIsFavorite: () => false,
      getIsCouponExcluded: () => false,
      getIsHidden: () => false,
      subMenuIds: [],
      TagLabels: []
    }));

    const externalIndex = menus.findIndex(x => x.uniqueId === externalLobbyKey);
    const externalMenu = menus[externalIndex];
    const newExternalMenu = {};

    Object.assign(newExternalMenu, externalMenu);
    newExternalMenu.games = externalGames;
    newExternalMenu.subMenuItems = [];
    if (externalLobbyKey === externalGamesKey) {
      menus = menus
        .filter(({ id }) => id !== newExternalMenu.id)
        .concat([newExternalMenu]);
    } else {
      menus[externalIndex] = newExternalMenu;
    }

    games = [...games, ...externalGames];
  }

  return { menus, games };
};

const filterExternalLobbies = rootGetters => {
  if (Object.keys(rootGetters["externalLobbies/lobbies"]).length > 0) {
    return Object.keys(
      Object.fromEntries(
        Object.entries(rootGetters["externalLobbies/lobbies"]).filter(
          ([key]) =>
            rootGetters["externalLobbies/lobbies"][key].isVisible === true
        )
      )
    ).length > 0
      ? true
      : false;
  }
  return false;
};

const insertMissingBellyarts = (games) => {
  const bellyArtLanguageCodes = [
    'ja',
    'th',
    'zh-cn'
  ];
  const cdkLanguageCode = CDK.getLanguageCode();
  const bellyArtLanguageCodeSuffix = bellyArtLanguageCodes.some((bellyArtLanguageCode) => bellyArtLanguageCode.localeCompare(cdkLanguageCode, undefined, { sensitivity: "base" }) === 0) ? `.${cdkLanguageCode.toLowerCase()}` : '';
  const bellyArtNameById = {
    '18-83-0': 'ReturnOfTheRudolph',
    '18-120-0': 'Henhouse',
    '18-213-0': 'DoubleYaLuck'
  };
  const bellyArtNameByIdKeys = Object.keys(bellyArtNameById);
  games.forEach((game) => {
    if (bellyArtNameByIdKeys.includes(game.uniqueId)) {
      if (!game.imageURLs[CDK.LobbyIconTypes.tall]) {
        game.imageURLs[
          CDK.LobbyIconTypes.tall
        ] = require(`@/assets/bellyart/tall_BA${bellyArtNameById[game.uniqueId]}${bellyArtLanguageCodeSuffix}.jpg`);
      }
    }
  });
};

const actions = {
  async initialize({ dispatch }) {
    await dispatch("validateMenuDataPreloaded");
    await restoreSessionCompleted;
    dispatch("loadMenuData");
    dispatch("requestJackpots");

    CDK.on(CDK.Events.loggedIn, () => {
      dispatch("requestRecentGamesMenuData");

      if (menuDataHydrated) {
        dispatch("refreshFavoriteGames");
      }
    });
    CDK.on(CDK.Events.loggedOut, () => {
      if (CDK.isConnected()) {
        dispatch("clearFavoriteGames");
        dispatch("clearExcludedGames");
        dispatch("clearRecentGamesMenuData");
      }
    });

    // Excluded games CDK listener
    CDK.on(CDK.Events.restrictedGamesChanged, restrictedGameIds => {
      dispatch("refreshExcludedGames", restrictedGameIds);
    });
  },
  async loadMenuData(context) {
    await casinoConnected;
    context.state.refreshFavorites = true;

    // Once we have "real" menu data, don't do the call again
    if (!menuDataHydrated) {
      await context.dispatch("requestMenuData");
    }
  },
  /** Sanity check for data preloaded from the cache */
  async validateMenuDataPreloaded(context) {
    const menuDataLoaded =
      context.state.menuIds?.length &&
      Object.keys(context.state.menus).length &&
      Object.keys(context.state.menuGamesLists).length &&
      Object.keys(context.state.gameIds).length &&
      Object.keys(context.state.games).length;

    context.commit("updateMenuDataLoaded", menuDataLoaded);
  },
  async requestRecentGamesMenuData({ commit, state, rootGetters }) {
    try {
      const recentGamesMenuItem = await CDK.getRecentGamesMenuItem(
        rootGetters["stringTable/getStringByKey"]("LastPlayed"),
        state.recentGamesMenuId,
        state.recentGamesMenuId,
        "Recent Games",
        []
      );

      // Filter out download-only games in mobile, etc.
      const validGames = recentGamesMenuItem.games.filter((uniqueId) =>
        state.gameIds.includes(uniqueId)
      );
      const lastPlayedMenuId = state.menuIds.find(
        (menuId) => state.menus[menuId].name === "Last Played"
      );
      const lastPlayedMenu = lastPlayedMenuId
        ? state.menus[lastPlayedMenuId]
        : null;
      const recentGamesMenuItemWithIndex = {
        ...recentGamesMenuItem,
        index: lastPlayedMenu.index,
      };
      commit(
        "updateRecentGamesMenu",
        lastPlayedMenu && recentGamesMenuItemWithIndex.isVisible
          ? recentGamesMenuItemWithIndex
          : []
      );
      commit("updateRecentGamesList", validGames);
    } catch (error) {
      window.console.warn(
        `There was an error getting the recent games: ${error.Message}`
      );
    }
  },
  async clearRecentGamesMenuData({ commit }) {
    commit("updateRecentGamesMenu", {});
    commit("updateRecentGamesList", []);
  },
  async requestMenuData(context) {
    return CDK.getMenuData().then((menus, games) => {
      ({ menus, games } = addExternalLobbiesInfo(context, {
        menus,
        games
      }));
      const sortedMenus = sortMenus(menus);

      const menuIds = sortedMenus.map(menu => menu.id);
      const favoritesMenuId = getFavoritesMenuId(sortedMenus);
      const menusById = Object.fromEntries(
        sortedMenus.map(menu => [menu.id, menu])
      );

      insertMissingBellyarts(games);

      const gameListsByMenuId = makeGamesListsByMenuId(sortedMenus, games);
      const gameIds = games.map(game => game.uniqueId);

      const gamesById = Object.fromEntries(
        games.map(game => [game.uniqueId, game])
      );

      const gameIsFavoriteById = Object.fromEntries(
        games.map(game => [game.uniqueId, game.getIsFavorite()])
      );

      const gameIsCouponExcludedById = Object.fromEntries(
        games.map(game => [game.uniqueId, game.getIsCouponExcluded()])
      );

      // Initialize excluded games list to all false
      context.dispatch("clearExcludedGames");

      const gameSearchData = makeGameSearchData(games, menusById);

      context.commit("updateMenuIds", menuIds);
      context.commit("updateFavoritesMenuId", favoritesMenuId);
      context.commit("updateMenus", menusById);
      context.commit("updateMenuGamesLists", gameListsByMenuId);
      context.commit("updateGameIds", gameIds);
      context.commit("updateGames", gamesById);
      context.commit("updateGameIsFavorite", gameIsFavoriteById);
      context.commit("updateGameIsExcluded", gameIsCouponExcludedById);
      context.commit("updateMenuDataLoaded", true);
      context.commit("updateGameSearchData", gameSearchData);

      menuDataHydrated = true;
      registerGameEventListeners(games, context.commit);
    });
  },
  refreshFavoriteGames(context) {
    context.commit("updateRefreshFavorites", true);

    const games = Object.values(context.state.games);
    const gameIsFavoriteById = Object.fromEntries(
      games.map(game => {
        if (!game.getIsFavorite) {
          game.getIsFavorite = () => false;
        }
        return [game.uniqueId, game.getIsFavorite()];
      })
    );

    const favoriteGames = games
      .filter(g => g.getIsFavorite())
      .map(g => g.uniqueId);

    context.commit("updateGameIsFavorite", gameIsFavoriteById);
    context.commit("updateFavoriteGamesList", favoriteGames);
  },
  clearFavoriteGames(context) {
    context.commit("updateGameIsFavorite", {});
    context.commit("updateFavoriteGamesList", []);
  },
  refreshExcludedGames(context, restrictedGameIds) {
    const gameIds = context.getters.gameIds;
    const gameIsExcludedById = Object.fromEntries(
      gameIds.map(gameId => [gameId, restrictedGameIds.includes(gameId)])
    );
    context.commit("updateGameIsExcluded", gameIsExcludedById);
  },
  clearExcludedGames(context) {
    context.dispatch("refreshExcludedGames", []);
  },
  async requestJackpots(context) {
    // flatten out the jackpot data to just include the amount
    const flatJackpots = jackpotData => {
      return Object.fromEntries(
        Object.entries(jackpotData || {}).map(([uniqueId, data]) => [
          uniqueId,
          data.Amount
        ])
      );
    };

    const jackpotData = await CDK.getJackpotData(CDK.isPlayingForMoney());
    context.commit("updateJackpots", flatJackpots(jackpotData));

    CDK.on(CDK.Events.jackpotsChanged, function(newJackpots) {
      context.commit("updateJackpots", flatJackpots(newJackpots));
    });
  },
  setGameIsFavorite(context, { gameId, isFavorite }) {
    // Set the state immediately, just assuming the async call will succeed
    // If not, the state will be updated again as a result of the call
    context.state.refreshFavorites = false;

    context.commit("updateSingleFavoriteGame", { gameId, isFavorite });

    CDK.setIsFavorite(gameId, isFavorite);
  },
  setGamesViewMode(context, gamesViewMode) {
    context.commit("updateGamesViewMode", gamesViewMode);
  },
  async launchGame(
    context,
    { gameId, externalLobbyId, gameLauncherParams, forceReal = false }
  ) {
    if (externalLobbyId) {
      context.dispatch(
        "externalLobbies/launchLobby",
        { externalLobbyId: externalLobbyId },
        { root: true }
      );
    } else {
      // Wait in case we are restoring a logged in session
      await restoreSessionCompleted;

      // Make sure the game/menu data has been retrieved
      await context.dispatch("loadMenuData");

      const showGameLauncher = () =>
        context.dispatch(
          "dialogs/showGameLauncher",
          { gameId, gameLauncherParams },
          { root: true }
        );

      const mustSignIn = forceReal && !context.rootGetters["session/loggedIn"];

      if (mustSignIn) {
        window.LobbyCore.raise(
          window.LobbyCore.Events.showSignInPage,
          { onSuccess: showGameLauncher },
          { root: true }
        );
      } else {
        showGameLauncher();
      }
    }
  },
  setLastPlayedGame(context, { gameId }) {
    context.commit("updateLastPlayedGame", { gameId });
  },
  async launchLastPlayedGame({ dispatch, state }) {
    await restoreSessionCompleted;
    if (state.lastPlayedGame) {
      dispatch("launchGame", { gameId: state.lastPlayedGame });
      return true;
    } else {
      return false;
    }
  },
  async showGamesGuru(context, { gameId, balanceChanged }) {
    context.dispatch(
      "dialogs/showGamesGuru",
      { gameId, balanceChanged },
      { root: true }
    );
  }
};

const mutations = {
  updateMenuIds(state, menuIds) {
    state.menuIds = menuIds;
  },
  updateFavoritesMenuId(state, id) {
    state.favoritesMenuId = id;
  },
  updateMenus(state, menus) {
    state.menus = menus;
  },
  updateMenuGamesLists(state, lists) {
    state.menuGamesLists = lists;
  },
  updateGameIds(state, ids) {
    state.gameIds = ids;
  },
  updateGames(state, games) {
    state.games = games;
  },
  updateGameIsFavorite(state, gameIsFavorite) {
    state.gameIsFavorite = gameIsFavorite;
  },
  updateGameIsExcluded(state, gameIsExcluded) {
    state.gameIsExcluded = gameIsExcluded;
  },
  updateGameSearchData(state, gameSearchData) {
    state.gameSearchData = gameSearchData;
  },
  updateFavoriteGamesList(state, favoriteGamesList) {
    Vue.set(state.menuGamesLists, state.favoritesMenuId, favoriteGamesList);
  },
  updateSingleFavoriteGame(state, { gameId, isFavorite }) {
    Vue.set(state.gameIsFavorite, gameId, isFavorite);
    if (state.refreshFavorites) {
      // Add or remove from the favorites menu gameids list if necessary
      const favoriteGames = state.menuGamesLists[state.favoritesMenuId];

      const favoriteGamesIndex = favoriteGames.indexOf(gameId);
      const isInFavorites = favoriteGamesIndex !== -1;

      if (isFavorite && !isInFavorites) {
        favoriteGames.push(gameId);
      } else if (!isFavorite && isInFavorites) {
        favoriteGames.splice(favoriteGamesIndex, 1);
      }
    }
  },
  updateRefreshFavorites(state, refreshFavorites) {
    state.refreshFavorites = refreshFavorites;
  },
  updateJackpots(state, jackpots) {
    state.gameJackpots = jackpots;
  },
  updateGamesViewMode(state, gamesViewMode) {
    state.gamesViewMode = gamesViewMode;
  },
  updateMenuDataLoaded(state, loaded) {
    state.menuDataLoaded = loaded;
  },
  updateRecentGamesMenu(state, recentGamesMenu) {
    state.recentGamesMenu = recentGamesMenu;
  },
  updateRecentGamesList(state, recentGamesList) {
    state.recentGamesList = recentGamesList;
  },
  updateLastPlayedGame(state, { gameId }) {
    state.lastPlayedGame = gameId;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
