/**
 * Staggered Items Mixin
 *
 * Supports adding items to a view one at a time with a specified delay.
 */
export default {
  data() {
    return {
      subMenuFilter: {},

      // This counter is incremented over time to control the number of items shown
      staggeredItemsCounter: 0,

      // Delay in ms between each item
      staggeredItemsDelay: 100,

      // The stagger counter stops when this number is reached, and then all items are shown
      maxItemsToStagger: 50
    };
  },
  mounted() {
    this.incrementStagger();
  },
  methods: {
    async incrementStagger() {
      this.staggeredItemsCounter++;
      if (this.staggeredItemsCounter < this.maxItemsToStagger) {
        await new Promise(r => setTimeout(r, this.staggeredItemsDelay));
        this.incrementStagger();
      } else {
        this.staggeredItemsCounter = 9999;
      }
    }
  }
};
