<template>
  <v-dialog v-if="showDialog && loggedIn" v-model="showDialog">
    <v-container class="header-container">
      <span class="title">{{
        $strResourceService("CouponFSMultiGamesTitle")
      }}</span>
      <v-icon
        class="pa-0 close-btn"
        :dark="true"
        @click.stop="showDialog = false"
      >
        mdi-close</v-icon
      >
    </v-container>
    <div class="gift-container">
      <v-img class="prize-image"></v-img>
      <v-container style=" align-self: stretch;">
        <p class="message-choose-game">
          {{ $strResourceService("CouponFSMultiGamesChooseGameMsg") }}
        </p>
      </v-container>
    </div>
    <div class="games-container">
      <div
        v-for="(currentGame, index) in activeCoupon.MultiGamesSettingList"
        :key="index"
        class="game-container"
      >
        <div class="card">
          <div class="content">
            <div class="details">
              <div
                :style="getImageURL(currentGame)"
                class="bellyart"
                :class="{ square: isSquareIcon, tall: !isSquareIcon }"
              >
                <p
                  v-if="showGameDescription(currentGame)"
                  class="free-spin-text-small"
                >
                  {{ currentGame.gameDescription }}
                </p>
              </div>
              <div class="info-game">
                <div class="spins-container">
                  <div class="number-spins-container">
                    <span class="number-spins-txt">{{
                      currentGame.spinsNeeded
                    }}</span>
                  </div>
                  <div class="msg-spins-container">
                    <span class="msg-spins-txt"> {{ $str("FreeSpins") }}</span>
                  </div>
                  <p v-if="currentGame.isLinesTotalBetGame" class="line-txt">
                    <span style="font-weight: bold;"
                      >{{
                        $strResourceService("CouponFSMultiGamesLines")
                      }}:</span
                    >
                    {{ currentGame.payLines }}<br />
                    <span style="font-weight: bold;"
                      >{{
                        $strResourceService("CouponFSMultiGamesTotalBet")
                      }}:</span
                    >
                    {{ $cur(currentGame.totalBet) }}
                  </p>
                  <p
                    v-if="currentGame.isLinesTotalBetGame == false"
                    class="line-txt"
                  >
                    <span style="font-weight: bold;"
                      >{{
                        $strResourceService("CouponFSMultiGamesLines")
                      }}:</span
                    >
                    {{ currentGame.numOfLinesForDisplay }}<br />
                    <span style="font-weight: bold;"
                      >{{
                        $strResourceService("CouponFSMultiGamesBetPerLine")
                      }}:</span
                    >
                    {{ $storageCur(currentGame.denominationForDisplay) }}
                  </p>
                </div>
                <v-btn
                  style=" display: flex; padding: 0px 16px;  justify-content: center; align-items: center; border-radius: 4px; background: var(--v-button-base); color: var(--v-buttonTxt-base); text-align: center; font-size: 14px; font-style: normal; font-weight: 700; line-height: 40px;"
                  @click.stop="launchGame(currentGame)"
                >
                  {{ $strResourceService("CouponFSMultiGamesSelect") }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>
<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "chooseMultiGame",
      launchedGame: false
    };
  },
  computed: {
    ...mapGetters("coupons", ["activeCoupon"]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    hasPendingGameChoise() {
      return (
        (this.activeCoupon && this.activeCoupon.ShowMultipleGames) ?? false
      );
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  watch: {
    hasPendingGameChoise(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.showChooseMultiGame();
      } else if (oldVal === true && newVal === false) {
        this.showDialog = false;
      }
    }
  },
  methods: {
    ...mapActions("coupons", ["chooseMultiGameClosed"]),
    ...mapActions("dialogs", ["showChooseMultiGame"]),
    async launchGame(currentGame) {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("Confirmation"),
        message: this.$strResourceService(
          "CouponFSMultiGamesConfirmMsg"
        ).replace("<Game Name>", currentGame?.gameDescription)
      });
      if (confirmed) {
        this.$store
          .dispatch("coupons/selectMultiGameRedeemedCoupon", {
            multiFreeSpinSettingId: currentGame?.couponMultiGameId.toString()
          })
          .then(() => {
            const gameId =
              currentGame?.gameid.toString() +
              "-" +
              currentGame?.machid.toString() +
              "-0";
            this.$store.dispatch("games/launchGame", {
              gameId: gameId
            });
            this.launchedGame = true;
            this.showDialog = false;
          });
      }
    },
    onHidden() {
      this.chooseMultiGameClosed(this.launchedGame);
    },
    onShown() {
      this.launchedGame = false;
    },
    getImageURL(currentGame) {
      const imageUrl = currentGame.imageUrls[this.lobbyIconType];

      return {
        background: imageUrl
          ? `url(${imageUrl}) center / cover no-repeat`
          : "rgba(0, 0, 0, 0.87)"
      };
    },
    showGameDescription(currentGame) {
      return currentGame.imageUrls[this.lobbyIconType] ? false : true;
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

::v-deep .title {
  font-size: 26px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: var(--v-bodyTitle-base);
}

::v-deep .gift-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

::v-deep .prize-image {
  width: 100px;
  height: 100px;
  background: url(../../assets/icons/Prize.svg) center / cover no-repeat;
}

::v-deep .message-choose-game {
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  color: var(--v-bodyTitle-base);
}

/* Media Query 1 */
@media screen and (min-height: 800px) and (min-width: 768px) {
  ::v-deep .v-dialog.v-dialog--active {
    margin: auto;
    width: 800px;
    min-width: 800px !important;
    padding: 15px 15px 50px 15px;
    gap: 10px;
    background: url(../../assets/couponfreespinbackground.png) center / cover
        no-repeat,
      linear-gradient(
        225deg,
        var(--v-body-base) 0%,
        var(--v-gradientColor-base) 100%
      );
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v-bodyTitle-base) rgba(0, 0, 0, 0.5);

    .games-container {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      align-self: stretch;
      justify-content: center;
      .game-container {
        display: flex;
        flex: 1 0 0;
        .card {
          --angle: 0deg;
          padding: 15px 15px 0px 15px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          background: rgba(0, 0, 0, 0.1);
          transition: 0.5s;
          border: 1px solid;
          border-image: linear-gradient(
              var(--angle),
              #000,
              var(--v-buttonSecondary-base)
            )
            1;
          animation: 5s rotate linear infinite;
          .content .details {
            display: flex;
            flex-direction: column;
            align-items: center;
            .bellyart {
              width: 150px;
              height: 200px;
              border-radius: 6px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;

              &.square {
                width: 190px;
                height: 143px;
              }
              .free-spin-text-small {
                font-size: 12px;
                -webkit-text-stroke-width: 0.1vw;
                color: var(--v-buttonTxt-base);
                position: absolute;
                text-align: center;
                bottom: 0px;
                left: 0px;
                right: 0px;
                text-transform: uppercase;
                line-height: 1;
              }
            }

            .info-game {
              display: flex;
              padding: 15px;
              flex-direction: column;
              align-items: center;
              gap: 15px;
              flex: 1 0 0;
              .spins-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                align-self: stretch;
                .number-spins-container .number-spins-txt {
                  color: var(--v-buttonSecondaryTxt-base);
                  font-size: 26px;
                  display: flex;
                  padding: 10px;
                  align-items: flex-start;
                  border-radius: 50px;
                  background: var(--v-buttonSecondary-base);
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  min-width: 50px;
                  justify-content: center;
                }

                .msg-spins-container .msg-spins-txt {
                  color: var(--v-buttonSecondary-base);
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }
            }
            .line-txt {
              color: var(--v-bodyTitle-base);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

/* Media Query 2 */
@media screen and (max-height: 800px) and (min-height: 680px) and (min-width: 768px) {
  ::v-deep .v-dialog.v-dialog--active {
    margin: auto;
    width: 800px;
    min-width: 800px !important;
    padding: 15px 15px 50px 15px;
    gap: 10px;
    background: url(../../assets/couponfreespinbackground.png) center / cover
        no-repeat,
      linear-gradient(
        225deg,
        var(--v-body-base) 0%,
        var(--v-gradientColor-base) 100%
      );
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v-bodyTitle-base) rgba(0, 0, 0, 0.5);

    .games-container {
      display: flex;
      align-items: flex-start;
      gap: 15px;
      align-self: stretch;
      justify-content: center;
      .game-container {
        display: flex;
        flex: 1 0 0;
        .card {
          --angle: 0deg;
          padding: 15px 15px 0px 15px;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          background: rgba(0, 0, 0, 0.1);
          transition: 0.5s;
          border: 1px solid;
          border-image: linear-gradient(
              var(--angle),
              #000,
              var(--v-buttonSecondary-base)
            )
            1;
          animation: 5s rotate linear infinite;
          .content .details {
            display: flex;
            flex-direction: column;
            align-items: center;
            .bellyart {
              width: 100px;
              height: 130px;
              border-radius: 6px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              position: relative;

              &.square {
                width: 120px;
                height: 80px;
              }
              .free-spin-text-small {
                font-size: 12px;
                -webkit-text-stroke-width: 0.1vw;
                color: var(--v-buttonTxt-base);
                position: absolute;
                text-align: center;
                bottom: 0px;
                left: 0px;
                right: 0px;
                text-transform: uppercase;
                line-height: 1;
              }
            }

            .info-game {
              display: flex;
              padding: 15px;
              flex-direction: column;
              align-items: center;
              gap: 15px;
              flex: 1 0 0;
              .spins-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
                align-self: stretch;
                .number-spins-container .number-spins-txt {
                  color: var(--v-buttonSecondaryTxt-base);
                  font-size: 26px;
                  display: flex;
                  padding: 10px;
                  align-items: flex-start;
                  border-radius: 50px;
                  background: var(--v-buttonSecondary-base);
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  min-width: 50px;
                  justify-content: center;
                }

                .msg-spins-container .msg-spins-txt {
                  color: var(--v-buttonSecondary-base);
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  text-transform: capitalize;
                }
              }
            }
            .line-txt {
              color: var(--v-bodyTitle-base);
              text-align: center;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  ::v-deep .title {
    font-size: 22px !important;
  }

  ::v-deep .prize-image {
    width: 100px;
    height: 100px;
  }

  ::v-deep .message-choose-game {
    font-size: 22px;
  }
}

/* Media Query 3 */
@media screen and (max-height: 680px), screen and (max-width: 768px) {
  ::v-deep .v-dialog.v-dialog--active {
    margin: 0;
    display: flex;
    max-width: 379px;
    padding: 20px 15px 50px 15px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: url(../../assets/couponfreespinbackground.png) center / cover
        no-repeat,
      linear-gradient(
        225deg,
        var(--v-body-base) 0%,
        var(--v-gradientColor-base) 100%
      );
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--v-bodyTitle-base) rgba(0, 0, 0, 0.5);

    .game-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      align-self: stretch;
      .card {
        --angle: 0deg;
        display: flex;
        align-self: stretch;
        position: relative;
        gap: 10px;
        background: rgba(0, 0, 0, 0.1);
        transition: 0.5s;
        border: 1px solid;
        border-image: linear-gradient(
            var(--angle),
            #000,
            var(--v-buttonSecondary-base)
          )
          1;
        animation: 5s rotate linear infinite;
        .content {
          width: 100%;
          padding: 1px;
          position: relative;
          display: flex;
          align-self: stretch;
          .details {
            display: flex;
            padding: 10px;
            width: 100%;
            gap: 10px;
            align-self: stretch;
            border-radius: 4px;
            .bellyart {
              width: 70px;
              height: 80px;
              border-radius: 6px;
              position: relative;

              &.square {
                width: 106.666px;
              }
              .free-spin-text-small {
                font-size: 12px;
                -webkit-text-stroke-width: 0.1vw;
                color: var(--v-buttonTxt-base);
                position: absolute;
                text-align: center;
                bottom: 0px;
                left: 0px;
                right: 0px;
                text-transform: uppercase;
                line-height: 1;
              }
            }
            .info-game {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1 0 0;
              .spins-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
                .number-spins-container .number-spins-txt {
                  color: var(--v-buttonSecondaryTxt-base);
                  font-size: 26px;
                  display: flex;
                  padding: 10px;
                  align-items: flex-start;
                  border-radius: 50px;
                  background: var(--v-buttonSecondary-base);
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                  min-width: 50px;
                  justify-content: center;
                }
                .msg-spins-container .msg-spins-txt {
                  color: var(--v-buttonSecondary-base);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                }
              }
            }
            .line-txt {
              color: var(--v-bodyTitle-base);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
</style>
