<template>
  <svg id="ss" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g id="DarkLight">
      <path
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        d="M44.76,31a10.68,10.68,0,0,1-2.54,10.29,9.78,9.78,0,0,1-14.2,0,10.45,10.45,0,0,1-1.81-2.56"
      />
      <path
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        d="M39.75,24.67a10,10,0,0,1,2.47,1.88A10.69,10.69,0,0,1,44,29.11"
      />
      <path
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        d="M26.21,38.71a9.78,9.78,0,0,0,11.73-1.88,10.72,10.72,0,0,0,1.81-12.16"
      />
      <path
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        d="M15.56,9.42a7,7,0,1,0,7,7A7,7,0,0,0,15.56,9.42Z"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="15.54"
        y1="6.94"
        x2="15.54"
        y2="3.68"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="15.54"
        y1="29.18"
        x2="15.54"
        y2="25.92"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="25.02"
        y1="16.43"
        x2="28.29"
        y2="16.43"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="2.79"
        y1="16.43"
        x2="6.05"
        y2="16.43"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="22.25"
        y1="9.72"
        x2="24.55"
        y2="7.41"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="6.52"
        y1="25.44"
        x2="8.83"
        y2="23.14"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="8.83"
        y1="9.72"
        x2="6.52"
        y2="7.41"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="24.55"
        y1="25.44"
        x2="22.25"
        y2="23.14"
      />
      <line
        fill="none"
        :stroke="iconStroke"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.44"
        x1="11.66"
        y1="44.32"
        x2="45.21"
        y2="10.77"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "DarkLightModeIcon",
  props: {
    iconStroke: {
      type: String,
      default: "currentColor"
    },
    displayName: { type: String, default: "DarkLightModeIcon" }
  }
};
</script>
