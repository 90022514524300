<template>
  <div v-if="show" class="d-flex .flex-row mt-1 mb-2 pa-0">
    <div
      id="tournament-switch-control"
      class="switch-button-control"
      @click.stop="toggle()"
    >
      <label
        id="tournament-switch-label"
        for="switchBaseCurrency"
        class="pa-0 pt-1 mr-2 body-2"
      >
        {{ switchCurrencyLabel }}
      </label>
      <div
        id="tournament-switch-button"
        class="switch-button"
        :class="{ enabled: convertToBaseCurrency }"
      >
        <div class="button"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("tournaments", ["convertToBaseCurrency"]),

    switchCurrencyLabel() {
      return this.$str("SwitchTournamentCurrency").replace(
        "<currency>",
        this.convertToBaseCurrency
          ? CDK.getCurrencyCode()
          : CDK.getBaseCurrencyCode()
      );
    },
    show() {
      return this.$showSwitchToBaseCurrency();
    }
  },
  methods: {
    ...mapActions("tournaments", ["switchToBaseCurrency"]),
    toggle() {
      this.switchToBaseCurrency(!this.convertToBaseCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-button-control .switch-button .button {
  height: calc(1.3em - (2 * 2px)) !important;
  width: calc(1.3em - (2 * 2px)) !important;
  margin-top: 0.05em !important;
  margin-left: 0.1em !important;
}

.v-application .button {
  background: white !important;
  border: none !important;
  border-color: white !important;
}
.switch-button-control {
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    cursor: pointer;
  }

  .switch-button {
    $switch-button-height: 1.6em;
    $switch-button-color: var(--color);
    $switch-button-border-thickness: 2px;
    $switch-transition: all 0.3s ease-in-out;
    $switch-is-rounded: true;

    height: 1.4em;
    width: calc(#{$switch-button-height} * 2);
    border: 1.8px solid white;
    box-shadow: inset 0px 0px $switch-button-border-thickness 0px
      rgba(0, 0, 0, 0.33);
    border-radius: if($switch-is-rounded, $switch-button-height, 0);

    transition: $switch-transition;

    $button-side-length: calc(
      #{$switch-button-height} - (2 * #{$switch-button-border-thickness})
    );

    cursor: pointer;

    .button {
      height: $button-side-length;
      width: $button-side-length;
      border: 2px solid #253db9;
      border-radius: if($switch-is-rounded, $button-side-length, 0);
      background: #253db9;

      transition: $switch-transition;
    }

    &.enabled {
      background-color: #253db9;
      box-shadow: none;

      .button {
        background: white;
        transform: translateX(
          calc(#{$button-side-length} + (2 *#{$switch-button-border-thickness}))
        );
      }
    }
  }
  .switch-button-label {
    margin-left: 10px;
  }
}
</style>
