<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 512 512"
  >
    <path
      class="st0"
      d="M382.7,118.8c-25.1-32.2-51-65.6-69.5-102.1-4.9-9.5-13.1-15.4-22.7-16.1-11-.7-21.7,5.1-29.1,15.7-17,24.3-26.7,57.2-26.7,90.3,0,47,13.8,75,26,99.8,10.7,21.7,19.1,38.7,16.7,64.5-2.6,27.8-28.1,49.4-57.6,49.1-60.9-.7-67.7-59.1-70.5-138.3-.5-15-10.3-27.2-24.9-31.1-14.7-4-29.4,1.9-37.4,14.7-21.4,34.3-44.2,88-44.3,135.3,0,116.5,96.6,211.3,213.4,211.3,117.6-.7,213.3-95.5,213.3-211.1,1.2-69-41.5-123.9-86.6-182h0ZM448,300.6c0,104.1-86.2,189.3-192,190-105.2,0-191.9-85.2-191.9-190s22.1-93.7,41-124.1c2.7-4.3,6.7-5.8,10.4-5.8s2.4.2,3.5.4c2.1.6,8.8,3,9.1,11.3,2.3,65.7,6.5,158,92.5,158.9,40.3,0,74.6-29.9,78.1-68.4,2.9-31.7-7.6-53.2-18.8-75.9-11.7-23.8-23.8-48.4-23.8-90.3s8.3-57.3,22.8-78.1c3.6-5.2,7.6-6.6,10.1-6.7,2,.1,3.7,1.6,5.2,4.5,19.5,38.4,46,72.5,71.7,105.5,42.8,55,83.2,107,82.2,168.7h0Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Slots",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Slots" }
  }
};
</script>

<style scoped type="text/css">
.st0 {
  fill: currentColor;
  stroke-width: 0px;
}
</style>
