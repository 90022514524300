<template>
  <!-- Extra div to fix issue with v-toolbar style when loaded from a child component -->
  <div class="py-4">
    <v-toolbar flat dense :color="color" class="">
      <v-row>
        <v-col
          v-if="showChatIcon && liveChatInfo.isEnabled"
          cols="1"
          class="d-flex"
        >
          <v-btn
            id="dialogTitleBarLiveChatBtn"
            small
            icon
            @click.stop="showLiveChat({location: chatLocation})"
          >
            <v-icon small>$LiveChat</v-icon>
          </v-btn>
        </v-col>
        <v-col class="d-flex justify-start align-center">
          <v-toolbar-title v-if="title" class="text-left">{{
            title
          }}</v-toolbar-title>
        </v-col>
        <v-col cols="1" class="d-flex justify-end align-center pl-0">
          <v-btn
            id="dialogTitleBarCloseBtn"
            x-small
            icon
            @click.stop="$emit('close')"
          >
            <v-icon small>$Close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
/**
 * Title bar for dialog components
 * - Displays the title left-justified
 * - Provides default close button
 * - Provides default live chat button
 * - Auto-included in dialogToggle mixin (so you can use it with include/)
 *
 * To handle close event, subscribe to "close":
 *   <DialogTitleBar @close="showDialog = false" :title="$str(titleKey)" />
 */
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    color: { type: String, required: false, default: "transparent" },
    title: { type: String, required: false, default: "" },
    showChatIcon: { type: Boolean, required: false, default: false },
    chatLocation: { type: String, required: false, default: "" }
  },
  computed: {
    ...mapGetters("session", ["liveChatInfo"])
  },
  methods: {
    ...mapActions("dialogs", ["showLiveChat"])
  }
};
</script>

<style scoped>
::v-deep .v-toolbar__title {
  font-size: 18px;
  font-weight: 700;
}
</style>
