export const toggleFavorite = {
  computed: {
    isFavorite() {
      return this.$store.getters["games/gameIsFavorite"][this.gameId];
    }
  },
  methods: {
    toggleFavorite() {
      this.$store.dispatch("games/setGameIsFavorite", {
        gameId: this.gameId,
        isFavorite: !this.isFavorite
      });
    }
  }
};
