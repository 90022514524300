/**
 * External lobby data, if configured on the casino
 */

import { casinoConnected, GameLaunchMode, openPopup } from "@/services/casino";
import {
  getEvolutionTables,
  getEvolutionGameLaunchUrl
} from "@/services/casino/evolutionGames";

const state = {
  tables: {},
  isEvolutionVisible: false
};

const getters = {
  tablesList: state => Object.values(state.tables),
  isEvolutionVisible: state => state.isEvolutionVisible
};

const actions = {
  async initialize({ commit, dispatch }) {
    await casinoConnected;

    CDK.on(CDK.Events.loggedIn, async () => {
      await dispatch("refreshVisible");
    });

    CDK.on(CDK.Events.loggedOut, async () => {
      commit("updateVisible", false);
    });
  },
  async refreshVisible({ commit, dispatch }) {
    const isVisible = await CDK.isEvolutionVisible();
    commit("updateVisible", isVisible);
    if (isVisible) {
      await dispatch("refreshTables");
    }
  },
  async refreshTables({ commit }) {
    const tables = await getEvolutionTables();
    commit("updateTables", tables);
  },
  async launchTable(context, { table }) {
    const url = await getEvolutionGameLaunchUrl(table.tableId);

    if (!url) {
      window.console.error("EvolutionGames: No URL to launch table");
      return;
    }

    const gameLaunchMode = context.rootGetters["session/gameLaunchMode"];
    switch (gameLaunchMode) {
      case GameLaunchMode.ThisWindow:
        window.location.href = url;
        break;
      case GameLaunchMode.NewWindow:
        // Instant Play + Multiple Games @ Once
        // TODO: This window is not tracked for the maximum number of open games
        openPopup(url, "720", "1080", 1);
        break;
      case GameLaunchMode.Embedded:
        // Instant Play + Single Game @ Once
        // TODO: Currently launches in a popup even in single game mode
        openPopup(url, "720", "1080", 1);
        break;
    }
  }
};

const mutations = {
  updateVisible(state, isVisible) {
    state.isEvolutionVisible = isVisible;
  },
  updateTables(state, tables) {
    state.tables = tables;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
