<template>
  <div
    justify="end"
    class="mx-0 d-flex flex-row-reverse"
    :style="{ width: width }"
  >
    <v-btn
      v-for="button in buttons"
      :key="button.order"
      depressed
      small
      :class="`order-${button.order}`"
      class="pa-0 grey lighten-4 roundTopBorders"
      @click="button.action"
    >
      <v-tooltip bottom content-class="gameTooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="button.color || defaultColor"
            class="ma-0 lighten-1"
            right
            :size="18"
            v-bind="attrs"
            v-on="on"
            >{{ button.icon }}
          </v-icon>
        </template>
        <span>{{ button.tooltip }}</span>
      </v-tooltip>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: String, default: "100%" },
    buttons: { type: Array, required: true }
  },
  data() {
    return {
      defaultColor: "grey"
    };
  },
  methods: {
    sendAction(event) {
      this.$emit(event);
    }
  }
};
</script>

<style scoped>
.roundTopBorders {
  min-width: 30px !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.roundTopBorders:first-child {
  border-top-left-radius: 5px !important;
}
.roundTopBorders:last-child {
  border-top-right-radius: 5px !important;
}

.gameTooltip {
  font-size: 11px;
  padding: 2px 5px;
}
</style>
