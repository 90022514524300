/**
 * Player comp point balance and redemption status
 */

const state = {
  accruedPoints: 0,
  success: "",
  error: ""
};

const getters = {
  accruedPoints: state => Math.trunc(state.accruedPoints),
  success: state => state.success,
  error: state => state.error
};

const actions = {
  async initialize({ commit }) {
    CDK.on(
      CDK.Events.compPointsChanged,
      async () =>
        await CDK.getCompPoints(true, true).then(compPoints => {
          commit("updateAccruedCompPoints", compPoints);
        })
    );
  },
  async redeemPoints({ commit, state }, { pointsToRedeem }) {
    try {
      await CDK.redeemCompPoints(pointsToRedeem).then(response => {
        commit("updateError", "");
        commit("updateSuccess", response.Message);
        commit(
          "balances/updateCompPoints",
          state.accruedPoints - pointsToRedeem,
          {
            root: true
          }
        );
        commit("updateAccruedCompPoints", state.accruedPoints - pointsToRedeem);
      });
    } catch (error) {
      commit("updateSuccess", "");
      commit("updateError", error.Message);
      window.console.warn(
        `There was an error redeeming comp points: ${error.Message}`
      );
    }
  },
  clearMessages({ commit }) {
    commit("updateSuccess", "");
    commit("updateError", "");
  }
};

const mutations = {
  updateAccruedCompPoints(state, accruedPoints) {
    state.accruedPoints = accruedPoints;
  },
  updateSuccess(state, success) {
    state.success = success;
  },
  updateError(state, error) {
    state.error = error;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
