<template>
  <v-dialog
    v-model="showDialog"
    :width="smallLayout ? 340 : 500"
    content-class="tournament"
  >
    <v-card v-if="showDialog" class="pa-2" color="tournamentDetail">
      <v-card-title
        class="tournamentSuccess--text text-uppercase font-weight-bold"
      >
        {{ $str("SortAndFilter") }}
        <v-icon
          color="tournamentSuccess"
          x-large
          class="flex justify-end"
          @click="showDialog = false"
          >mdi-close-box-outline</v-icon
        >
      </v-card-title>
      <v-card-subtitle
        v-show="false"
        class="pt-2 pb-0 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("SortBy") }}</h3>
      </v-card-subtitle>
      <v-card-text v-show="false">
        <v-chip-group v-model="selectedSort" column mandatory>
          <v-chip
            v-for="tag in tags"
            :key="tag.value"
            :value="tag"
            class="tournament tournamentDetailTxt--text"
            active-class="active"
            label
            outlined
            small
          >
            {{ $str(tag.name) }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-divider
        v-show="false"
        class="mb-2"
        color="tournamentInfo "
      ></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("buyIn") }}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          id="freeRollsCkb"
          v-model="filters.isFreeRolls"
          color="tournament"
          :label="$str('SortAndOnly')"
          class="mt-0"
        ></v-checkbox>
        <v-card-text
          v-if="hasTournaments"
          class="d-flex justify-space-between tournament--text px-0"
        >
          <span>{{ $tournamentCur(filters.buyInRange[0]) }}</span>
          <span>{{ $tournamentCur(filters.buyInRange[1]) }}</span>
        </v-card-text>
        <v-range-slider
          v-show="hasTournaments"
          id="buyInRangeSld"
          v-model="filters.buyInRange"
          :min="filters.min"
          :max="filters.max"
          :readonly="filters.isFreeRolls"
          step="0.01"
          color="tournament"
          track-color="tournament"
          hide-details
        ></v-range-slider>
      </v-card-text>
      <v-divider class="mb-2" color="tournamentInfo "></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("SortAndOptions") }}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          id="addOnCkb"
          v-model="filters.isAddOnSelected"
          :label="$str('TournamentAddOn')"
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="reBuyCkb"
          v-model="filters.isReBuySelected"
          :label="$str('TournamentReBuy')"
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          v-show="filters.isRestrictedEnabled"
          id="restrictedCkb"
          v-model="filters.isRestrictedSelected"
          :label="$str('StatusRestricted')"
          class="mt-0"
        ></v-checkbox>
      </v-card-text>
      <v-divider class="mb-2" color="tournamentInfo "></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("DetailViewStatus") }}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          id="announcedCkb"
          v-model="filters.statusSelected"
          :label="$str('StatusAnnounced')"
          :value="2"
          :disabled="statusDisabled"
          light
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="registeringCkb"
          v-model="filters.statusSelected"
          :label="$str('StatusRegistering')"
          :value="3"
          :disabled="statusDisabled"
          light
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="completedCkb"
          v-model="filters.statusSelected"
          :label="$str('StatusCompleted')"
          :value="5"
          :disabled="statusDisabled"
          light
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="runningCkb"
          v-model="filters.statusSelected"
          :label="$str('StatusRunning')"
          :value="4"
          :disabled="statusDisabled"
          light
          class="mt-0"
        ></v-checkbox>
      </v-card-text>
      <v-divider class="mb-2" color="tournamentInfo "></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("SortAndGames") }}</h3>
      </v-card-subtitle>
      <v-card-text class="pb-10">
        <v-checkbox
          v-for="(game, index) in availableGames"
          :key="index"
          v-model="filters.selectedGames"
          :name="game"
          :label="game"
          :value="game"
          class="ma-0"
        ></v-checkbox>
      </v-card-text>
      <v-divider class="mb-2" color="tournamentInfo "></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $str("Mode") }}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          id="pointsCkb"
          v-model="filters.modeSelected"
          :label="$str('Points')"
          :value="1"
          light
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="creditsCkb"
          v-model="filters.modeSelected"
          :label="$str('Credits')"
          :value="2"
          light
          class="mt-0"
        ></v-checkbox>
      </v-card-text>
      <v-divider class="mb-2" color="tournamentInfo "></v-divider>
      <v-card-subtitle
        class="pt-2 pb-0 mb-2 font-weight-bold tournamentTabInactive--text text-uppercase"
      >
        <h3>{{ $strResourceService("Payout") }}</h3>
      </v-card-subtitle>
      <v-card-text>
        <v-checkbox
          id="cashPrizeCkb"
          v-model="filters.payoutSelected"
          :label="$strResourceService('CashPrize')"
          :value="payoutDeposit"
          light
          class="mt-0"
        ></v-checkbox>
        <v-checkbox
          id="couponsCkb"
          v-model="filters.payoutSelected"
          :label="$strResourceService('Coupon')"
          :value="payoutCoupon"
          light
          class="mt-0"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="justify-center px-4 pt-1 applyAndClearFixed"
        ><v-btn
          depressed
          width="50%"
          color="buttonSecondary buttonSecondaryTxt--text"
          :style="{ fontSize: clearButtonFontSize }"
          @click="clearAll"
          >{{ $str("SortAndAll") }}</v-btn
        ><v-btn
          depressed
          width="50%"
          color="button buttonTxt--text"
          :style="{ fontSize: applyButtonFontSize }"
          @click="apply"
          >{{ $str("SortAndApplyFilter") }}</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";
import { PayoutType } from "@/services/casino";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "sortAndFilters",
      tags: [
        {
          name: "SortTournamentStartDate",
          filters: [
            { property: "startDate", direction: "ASC" },
            { property: "status", direction: "ASC" }
          ]
        },
        {
          name: "SortBuyInHighLow",
          filters: [{ property: "buyIn", direction: "DESC" }]
        },
        {
          name: "SortBuyInLowHigh",
          filters: [{ property: "buyIn", direction: "ASC" }]
        },
        {
          name: "SortTournamentEndDate",
          filters: [{ property: "TournamentEndDate", direction: "ASC" }]
        }
      ],
      selectedSort: null,
      filters: {
        min: 0,
        max: 100,
        isFreeRolls: false,
        buyInRange: [this.min, this.max],
        isAddOnSelected: false,
        isReBuySelected: false,
        isRestrictedSelected: false,
        isRestrictedEnabled: false,
        statusSelected: [],
        modeSelected: [],
        isCompletedSelected: false,
        isRunningSelected: false,
        selectedGames: [],
        payoutSelected: []
      },
      statusDisabled: false
    };
  },
  computed: {
    ...mapGetters("tournaments", [
      "tournaments",
      "availableGames",
      "tournamentFilters"
    ]),
    hasTournaments() {
      return this.tournaments.length > 0;
    },
    maxBuyIn() {
      if (!this.hasTournaments) {
        return Number.MAX_VALUE;
      }

      return Math.max(...this.tournaments.map(tournament => tournament.buyIn));
    },
    clearButtonFontSize() {
      switch (CDK.getLanguageCode()) {
        case "PL":
          return "0.8rem";
        default:
          return "0.875rem";
      }
    },
    applyButtonFontSize() {
      switch (CDK.getLanguageCode()) {
        case "FR":
        case "RU":
          return "0.8rem";
        default:
          return "0.875rem";
      }
    },
    payoutDeposit() {
      return PayoutType.Deposit;
    },
    payoutCoupon() {
      return PayoutType.Coupon;
    }
  },
  watch: {
    tournamentFilters() {
      if (this.tournamentFilters.statusSelected)
        this.filters.statusSelected = this.tournamentFilters.statusSelected;
    },
    showDialog() {
      if (this.showDialog) {
        this.statusDisabled = this.$store.getters[
          "dialogs/sortAndFilters"
        ].data.statusDisabled;
      }
    }
  },
  methods: {
    ...mapActions("tournaments", ["sortAndFilterTournaments"]),
    onShown() {
      if (!this.tournamentFilters) this.clearAll();
      this.closedTournaments = false;
      this.filters.max = this.maxBuyIn;
      this.filters.isRestrictedEnabled = this.tournaments.find(
        tournament => tournament.restricted
      );
      this.filters.buyInRange = [this.filters.min, this.filters.max];
    },
    apply() {
      if (this.filters && this.filters.statusSelected) {
        const isCompletedSelected = this.filters.statusSelected.some(
          status => status === 5
        );
        if (
          isCompletedSelected &&
          !this.filters.statusSelected.find(status => status === 6)
        )
          this.filters.statusSelected.push(6);
        else {
          const index = this.filters.statusSelected.indexOf(6);
          if (index > -1 && !isCompletedSelected) {
            this.filters.statusSelected.splice(index, 1);
          }
        }
      }
      this.sortAndFilterTournaments({
        filters: { ...this.filters },
        sortFilters: this.selectedSort.filters
      });
      this.showDialog = false;
    },
    clearAll() {
      this.selectedSort = null;
      const max = this.filters?.max || this.maxBuyIn;
      this.filters = {
        min: 0,
        max: max,
        isFreeRolls: false,
        buyInRange: [0, max],
        isAddOnSelected: false,
        isReBuySelected: false,
        isRestrictedSelected: false,
        isRestrictedEnable: false,
        isRegisteringSelected: false,
        isCompletedSelected: false,
        isRunningSelected: false,
        selectedGames: [],
        modeSelected: [],
        statusSelected: this.statusDisabled
          ? this.tournamentFilters.statusSelected
          : [],
        payoutSelected: []
      };
    }
  }
};
</script>

<style scoped>
::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 1vw;
}
.v-chip.v-chip--outlined.v-chip.v-chip.active {
  background-color: var(--v-tournament-base) !important;
  color: var(--v-tournamentTxt-base) !important;
}
::v-deep .v-input.v-input--checkbox .v-icon {
  color: var(--v-tournament-base) !important;
}
::v-deep .v-input.v-input--checkbox .v-label,
.v-input.v-input--checkbox v-label {
  color: var(--v-tournamentDetailTxt-base) !important;
}
.applyAndClearFixed {
  position: sticky;
  bottom: 0;
  z-index: 1;
  left: 3vw;
  background: var(--v-tournamentDetail-base) !important;
}
@media only screen and (min-height: 1025px) {
  .applyAndClearFixed {
    bottom: 9vh !important;
  }
}
</style>
