/**
 * Info about active & available coupons, and various actions (redeem, cancel, etc)
 */

const state = {
  activeCoupon: null,
  availableCoupons: [],
  availableCompPointCoupons: [],
  completedFreeSpinCouponInfo: null,
  freeSpinCouponInfo: null,
  couponMessage: "",
  error: "",
  includedTopLevelGames: [],
  includedChildGames: [],
  couponChangedFromLobby: false,

  /** @values 1 (waiting on server), 0 (no msg), 1 (msg shown) */
  freeSpinNotificationStatus: -1,
  chooseMultiGameStatus: -1,
  idInterval: null,
  isCouponSequenceEnabled: null,
  isCouponSequenceGraphicEnabled: null,
  couponSequenceGraphInfo: null
};

const getters = {
  activeCoupon: state => state.activeCoupon,
  availableCoupons: state => state.availableCoupons,
  availableCompPointCoupons: state => state.availableCompPointCoupons,
  completedFreeSpinCouponInfo: state => state.completedFreeSpinCouponInfo,
  freeSpinCouponInfo: state => state.freeSpinCouponInfo,
  chooseMultiGameStatus: state => state.chooseMultiGameStatus,
  couponMessage: state => state.couponMessage,
  error: state => state.error,
  activeCouponValidForDeposit: state => {
    if (state.activeCoupon) {
      if (
        state.activeCoupon.IsDepositPending &&
        !state.activeCoupon.IsFreeSpinsEarningsRestricted
      ) {
        return true;
      }
      return (
        state.completedFreeSpinInfo &&
        state.activeCoupon.IsFreeSpinsEarningsRestricted &&
        state.completedFreeSpinCouponInfo.FreeSpinAwaitingDeposit
      );
    }
  },
  includedTopLevelGames: state => state.includedTopLevelGames,
  includedChildGames: state => state.includedChildGames,
  freeSpinNotificationStatus: state => state.freeSpinNotificationStatus,
  isCouponSequenceEnabled: state => state.isCouponSequenceEnabled,
  couponSequenceGraphInfo: state => state.couponSequenceGraphInfo,
  isCouponSequenceGraphicEnabled: state => state.isCouponSequenceGraphicEnabled,
  couponChangedFromLobby: state => state.couponChangedFromLobby
};

const actions = {
  async initialize(context) {
    CDK.on(CDK.Events.loggedIn, async () => {
      const forMoney = await CDK.isPlayingForMoney();
      await context.dispatch("getCouponSequenceIsEnabled");
      await context.dispatch("getCouponSequenceGraphicIsEnabled");
      await context.dispatch("getActiveCoupon");
      await context.dispatch("getAvailableCoupons");
      await context.dispatch("getAvailableCompPointsCoupons");
      await context.dispatch("getCompletedFreeSpinCouponInfo");
      await context.dispatch("getFreeSpinCouponInfo");

      context.commit(
        "updateCouponMessage",
        context.getters.activeCoupon === null
          ? ""
          : context.getters.activeCoupon.Message
      );
      context.dispatch("validateNotificationStatus", forMoney);
    });
    CDK.on(CDK.Events.loggedOut, () => {
      context.commit("updateCouponMessage", "");
      context.commit("updateActiveCoupon", null);
      context.commit("updateIncludedTopLevelGames", []);
      context.commit("updateIncludedChildGames", []);
      context.commit("updateFreeSpinNotificationStatus", -1);
      context.commit("updateChooseMultiGameStatus", -1);
      actions.stoptimer();
    });
    CDK.on(CDK.Events.couponChanged, async () => {
      if (context.getters.couponChangedFromLobby === true) {
        context.commit("updateCouponChangedFromLobby", false);
        return;
      }
      await context.dispatch("getActiveCoupon");
      await context.dispatch("getFreeSpinCouponInfo");
    });
  },
  async redeem(context, { couponCode }) {
    try {
      const response = await CDK.redeemCoupon(couponCode);
      context.commit("updateCouponChangedFromLobby", true);
      context.commit("updateCouponMessage", response.CouponInfo.Message);
      context.dispatch("getActiveCoupon");
      await context.dispatch("getFreeSpinCouponInfo");
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error redeeming the coupon: ${error.Message}`
      );
    }
  },
  async selectMultiGameRedeemedCoupon(context, { multiFreeSpinSettingId }) {
    try {
      await CDK.selectMultiGameRedeemedCoupon(multiFreeSpinSettingId);
      context.commit("updateCouponChangedFromLobby", true);
      context.dispatch("getActiveCoupon");
      context.dispatch("updateActiveCouponMessage");
      await context.dispatch("getFreeSpinCouponInfo", false);
    } catch (error) {
      window.console.warn(
        `There was an error selecting the game: ${error.Message}`
      );
    }
  },
  async cancelCoupon(context) {
    try {
      await CDK.discardCoupon(context.getters.activeCoupon.CouponCode);
      context.commit("updateCouponChangedFromLobby", true);
      context.dispatch("getActiveCoupon");
      context.commit("updateCouponSequenceGraphInfo", null);
      context.commit("updateCouponMessage", "");
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error canceling the coupon: ${error.Message}`
      );
    }
  },
  async getActiveCoupon(context) {
    const response = await CDK.getActiveCoupon();

    if (response.CouponInfo && response.CouponInfo.GameList) {
      response.CouponInfo.GameList = response.CouponInfo.GameList.filter(
        game => game.IsParent || game.Playthrough > 0
      );
    }
    context.commit("updateActiveCoupon", response.CouponInfo);
    context.dispatch("createIncludedGameLists");
    await context.dispatch("getCouponSequenceGraphInfo");
  },
  getTime(context) {
    const availableCouponsAux = context.state.availableCoupons.map(a => {
      return { ...a };
    });
    const availableCompCouponsAux = context.state.availableCompPointCoupons.map(
      a => {
        return { ...a };
      }
    );
    context.commit(
      "updateAvailableCoupons",
      this.getCouponTime(availableCouponsAux)
    );
    context.commit(
      "updateAvailableCompPointsCoupons",
      this.getCouponTime(availableCompCouponsAux)
    );
  },
  getCouponTime(coupons) {
    coupons.forEach(element => {
      const time = element.MillisecondsToDisappear;
      if (time > 0) {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        let display = "";
        if (days > 0) display += days + "d ";
        if (hours > 0) display += hours + "h:";
        if (minutes > 0 || hours > 0) display += minutes + "m";
        if (days <= 0) display += ":" + seconds + "s";
        element.scrollTimer = display;
        element.MillisecondsToDisappear = time - 1000;
      }
    });
    return coupons;
  },
  async getAvailableCoupons(context) {
    try {
      const availableCoupons = await CDK.getAvailableCouponsInfo(true, false);
      context.commit(
        "updateAvailableCoupons",
        availableCoupons.AvailableCoupons
      );
      if (!state.idInterval)
        state.idInterval = setInterval(() => actions.getTime(context), 1000);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving the available coupons: ${error.Message}`
      );
    }
  },
  async getAvailableCompPointsCoupons(context) {
    try {
      const availableCoupons = await CDK.getAvailableCouponsInfo(true, true);
      context.commit(
        "updateAvailableCompPointsCoupons",
        availableCoupons.AvailableCoupons
      );
      if (!state.idInterval)
        state.idInterval = setInterval(() => actions.getTime(context), 1000);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving the available coupons: ${error.Message}`
      );
    }
  },
  stoptimer() {
    clearInterval(state.idInterval);
  },
  async getCompletedFreeSpinCouponInfo(context) {
    try {
      const info = await CDK.getCompletedFreeSpinCouponInfo();
      context.commit(
        "updateCompletedFreeSpinCouponInfo",
        info.CompletedFreeSpinInfo
      );
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving the completed free spin info: ${error.Message}`
      );
    }
  },
  async getFreeSpinCouponInfo(context, displayNotification = true) {
    try {
      const info = await CDK.getFreeSpinCouponInfo();
      if (info.FreeSpinInfo)
        info.FreeSpinInfo.DisplayNotification = displayNotification;
      context.commit("updateFreeSpinCouponInfo", info.FreeSpinInfo);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving the coupon free spin info: ${error.Message}`
      );
    }
  },
  createIncludedGameLists(context) {
    if (context.getters.activeCoupon) {
      // If GameList is null, clear out the included games and return
      if (!context.getters.activeCoupon.GameList) {
        context.commit("updateIncludedTopLevelGames", []);
        context.commit("updateIncludedChildGames", []);
        return;
      }

      let currentFamily = "";
      const tempTopLevelGames = [];
      const tempChildGames = [];
      context.getters.activeCoupon.GameList.forEach(game => {
        if (game.IsParent) {
          currentFamily = game.GameDescription;
          tempTopLevelGames.push(game);
        } else {
          if (game.GameDescription === currentFamily) {
            tempChildGames.push(game);
          } else {
            currentFamily = "";
            tempTopLevelGames.push(game);
          }
        }
      });

      const tempTopLevelGamesWithChildren = tempTopLevelGames.filter(
        game =>
          !game.IsParent ||
          (game.IsParent &&
            tempChildGames.some(
              child => child.GameDescription === game.GameDescription
            ))
      );

      context.commit(
        "updateIncludedTopLevelGames",
        tempTopLevelGamesWithChildren
      );
      context.commit("updateIncludedChildGames", tempChildGames);
    }
  },
  clearCouponMessage(context) {
    context.commit("updateCouponMessage", "");
  },
  freeSpinNotificationClosed(context, launchedGame) {
    if (!launchedGame) {
      context.commit("updateFreeSpinNotificationStatus", 0);
    }
  },
  chooseMultiGameClosed(context, launchedGame) {
    if (!launchedGame) {
      context.commit("updateChooseMultiGameStatus", 0);
    }
  },
  updateActiveCouponMessage(context) {
    context.commit(
      "updateCouponMessage",
      context.getters.activeCoupon === null
        ? ""
        : context.getters.activeCoupon.Message
    );
  },
  async getCouponSequenceIsEnabled(context) {
    try {
      const isEnabled = await CDK.getCouponSequenceIsEnabled();
      context.commit("updateIsCouponSequenceEnabled", isEnabled);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving if coupon sequence is enabled: ${error.Message}`
      );
    }
  },
  async getCouponSequenceGraphicIsEnabled(context) {
    try {
      const isEnabled = await CDK.getCouponSequenceGraphicIsEnabled();
      context.commit("updateIsCouponSequenceGraphicEnabled", isEnabled);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving if graphical representation of coupon sequence is enabled: ${error.Message}`
      );
    }
  },
  async getCouponSequenceGraphInfo(context) {
    try {
      const isCouponSequenceEnabled = context.getters.isCouponSequenceEnabled;
      const isCouponSequenceGraphicEnabled =
        context.getters.isCouponSequenceGraphicEnabled;
      const activeCoupon = context.getters.activeCoupon;
      if (
        isCouponSequenceEnabled &&
        isCouponSequenceGraphicEnabled &&
        activeCoupon
      ) {
        const result = await CDK.getCouponSequenceGraphInfo(
          activeCoupon.CouponId
        );
        context.commit(
          "updateCouponSequenceGraphInfo",
          result.CouponSequenceGraph
        );
      } else context.commit("updateCouponSequenceGraphInfo", null);
    } catch (error) {
      context.commit("updateError", error.Message);
      window.console.warn(
        `There was an error retrieving coupon sequecen graph info: ${error.Message}`
      );
    }
  },
  validateNotificationStatus(context, forMoney) {
    if (
      context.getters.activeCoupon &&
      context.getters.activeCoupon.IsFreeSpin &&
      !context.getters.activeCoupon.ShowMultipleGames &&
      context.getters.freeSpinCouponInfo &&
      context.getters.freeSpinCouponInfo.SpinsRemaining > 0 &&
      forMoney
    ) {
      context.commit("updateFreeSpinNotificationStatus", 1);
    } else {
      context.commit("updateFreeSpinNotificationStatus", 0);
    }
    if (
      context.getters.activeCoupon &&
      context.getters.activeCoupon.ShowMultipleGames
    ) {
      context.commit("updateChooseMultiGameStatus", 1);
    } else {
      context.commit("updateChooseMultiGameStatus", 0);
    }
    if (
      context.getters.completedFreeSpinCouponInfo &&
      context.getters.completedFreeSpinCouponInfo.FreeSpinAwaitingDeposit
    ) {
      context.dispatch("dialogs/showCompletedFreeSpin", null, {
        root: true
      });
    }
  }
};

const mutations = {
  updateActiveCoupon(state, couponCode) {
    state.activeCoupon = couponCode;
  },
  updateAvailableCoupons(state, availableCoupons) {
    state.availableCoupons = availableCoupons;
  },
  updateAvailableCompPointsCoupons(state, availableCoupons) {
    state.availableCompPointCoupons = availableCoupons;
  },
  updateCompletedFreeSpinCouponInfo(state, completedFreeSpinInfo) {
    state.completedFreeSpinCouponInfo = completedFreeSpinInfo;
  },
  updateFreeSpinCouponInfo(state, freeSpinCouponInfo) {
    state.freeSpinCouponInfo = freeSpinCouponInfo;
  },
  updateCouponMessage(state, couponMessage) {
    state.couponMessage = couponMessage;
  },
  updateError(state, error) {
    state.error = error;
  },
  updateIncludedTopLevelGames(state, games) {
    state.includedTopLevelGames = games;
  },
  updateIncludedChildGames(state, games) {
    state.includedChildGames = games;
  },
  updateFreeSpinNotificationStatus(state, status) {
    state.freeSpinNotificationStatus = status;
  },
  updateChooseMultiGameStatus(state, status) {
    state.chooseMultiGameStatus = status;
  },
  updateIsCouponSequenceEnabled(state, isEnabled) {
    state.isCouponSequenceEnabled = isEnabled;
  },
  updateIsCouponSequenceGraphicEnabled(state, isEnabled) {
    state.isCouponSequenceGraphicEnabled = isEnabled;
  },
  updateCouponSequenceGraphInfo(state, info) {
    state.couponSequenceGraphInfo = info;
  },
  updateCouponChangedFromLobby(state, isEnabled) {
    state.couponChangedFromLobby = isEnabled;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
