/**
 * Casino Lobby NavBar and Sandwich Menu, configured in the Admin
 * @see Admin -> Configuration -> Lobby -> Lobby Settings -> Navigation bar
 * @see Admin -> Configuration -> Lobby -> Lobby Settings -> Sandwich menu
 */

const state = {
  /**
   *
   * Menu data structure:
   * { ID, Index, Name, Icon, URL, DisplayName, PriorToLogin, AfterLogin, CustomIcon, NewTab, Type }
   */
  navBar: [],
  sandwichMenu: [],
  legalNoticesURL:
    "https://www.gamehelponline.com/A/EN/_common/LegalNotices.htm",
  termsConditionsURL:
    "https://www.gamehelponline.com/A/EN/_common/LegalNotices.htm",

  privacyPolicyURL: null,
  isGamesGuruEnabled: false,
  allowProperties: null
};

const getters = {
  navBar: state => state.navBar,
  sandwichMenu: state => state.sandwichMenu,
  navBarItemByName: state => name => {
    return state.navBar.find(i => i.Name === name);
  },
  sandwichMenuItemByName: state => name => {
    return state.sandwichMenu.find(i => i.Name === name);
  },
  isGamesGuruEnabled: state => state.isGamesGuruEnabled,
  allowProperties: state => state.allowProperties
};

const actions = {
  async initialize({ commit }) {
    CDK.getLobbyNavBarMenu().then(navBar => {
      // Check result
      if (!navBar || navBar.length === 0) {
        window.console.warn(
          "lobbyMenus: received empty list of navbar menu from server!"
        );
        return;
      }
      // Update navBar in our store
      commit("updateNavBar", navBar);
    });

    CDK.getLobbySandwichMenu().then(sandwichMenu => {
      // Check result
      if (!sandwichMenu || sandwichMenu.length === 0) {
        window.console.warn(
          "lobbyMenus: received empty list of sandwich menu from server!"
        );
        return;
      }
      // Update sandwichMenu in our store
      commit("updateSandwichMenu", sandwichMenu);
    });

    CDK.on(CDK.Events.loggedIn, async () => {
      try {
        const settingsPromise = await CDK.getGamesGuruSettings();
        const gamesRecommended = await CDK.getRecommendedGames(
          null,
          null,
          true
        );
        const isInstantPlayEnabled = settingsPromise.ClientTypes.some(
          client => client.clientID === CDK.ClientTypes.InstantPlay
        );
        const setting =
          !(
            !gamesRecommended.TrendingGames &&
            !gamesRecommended.NowPlayingGames &&
            !gamesRecommended.RecommendedGames
          ) || !isInstantPlayEnabled;
        commit("updateIsGamesGuruEnabled", setting);
      } catch (error) {
        window.console.warn(
          `There was an error retrieving games guru and/or recommended games: ${error.Message}`
        );
      }
    });
    commit("updateAllowProperties", await CDK.getMenuAllowPropertiesBySkin());
  }
};

const mutations = {
  updateNavBar(state, navBar) {
    const homeItem = navBar.find(i => i.Name.toLowerCase() === "home");
    if (homeItem) {
      const allGamesItem = {
        AfterLogin: homeItem.AfterLogin,
        CustomIcon: homeItem.CustomIcon,
        DisplayName: "AllGames",
        ID: 0,
        Icon: "play-circle",
        Index: homeItem.Index,
        Name: "AllGames",
        NewTab: homeItem.NewTab,
        PriorToLogin: homeItem.PriorToLogin,
        SessionGame: homeItem.SessionGame,
        Type: homeItem.Type
      };
      navBar.splice(homeItem.ID, 0, allGamesItem);
      state.navBar = navBar;
    }
  },
  updateSandwichMenu(state, sandwichMenu) {
    state.sandwichMenu = sandwichMenu;
  },
  updateIsGamesGuruEnabled(state, isGamesGuruEnabled) {
    state.isGamesGuruEnabled = isGamesGuruEnabled;
  },
  updateAllowProperties(state, allowProperties) {
    state.allowProperties = allowProperties;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
