<template>
  <v-dialog
    v-model="showDialog"
    persistent
    :width="smallLayout ? 340 : 500"
    content-class="tournament"
  >
    <v-card v-if="showDialog" flat color="tournamentDetail">
      <v-card-title
        class="tournamentSuccess--text text-uppercase font-weight-bold"
        style="flex-wrap: nowrap; word-break: break-word; font-size: 1.15em;"
      >
        <v-icon color="tournamentSuccess" x-large class="ml-n2 mr-2"
          >mdi-trophy</v-icon
        >{{ $str("TournamentReminder") }}</v-card-title
      >
      <v-container>
        <v-expansion-panels :value="0" flat>
          <v-expansion-panel
            v-for="(tournament, index) in tournaments"
            :key="index"
          >
            <v-expansion-panel-header>
              <v-img
                width="100"
                contain
                class="rounded"
                :src="gameIcon(tournament.game)"
                :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
                ><v-overlay v-if="tournament.restricted" z-index="0" absolute>
                  <v-icon large>mdi-lock</v-icon>
                </v-overlay></v-img
              >
              <TournamentTile
                :name="tournament.name"
                :start="tournament.startDate"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><v-card flat color="tournamentDetail">
                <v-card-text
                  class="tournamentDetailTxt--text text-center px-2"
                  >{{ $str("TournamentReminderTapPlay") }}</v-card-text
                >
                <v-card-actions class="justify-center">
                  <v-btn
                    depressed
                    width="50%"
                    color="buttonSecondary buttonSecondaryTxt--text"
                    @click="close"
                    >{{ $str("Cancel") }}</v-btn
                  >
                  <v-btn
                    depressed
                    width="50%"
                    color="button buttonTxt--text"
                    @click="
                      () =>
                        play({
                          ...tournament.Tournament,
                          gameUniqueId: tournament.gameUniqueId
                        })
                    "
                    >{{ $str("Play") }}</v-btn
                  >
                </v-card-actions>
              </v-card></v-expansion-panel-content
            >
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import TournamentTile from "@/components/TournamentTile.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    TournamentTile
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "tournamentReminder",
      scrollFix: false
    };
  },
  computed: {
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    },
    tournaments() {
      return this.$store.getters["dialogs/tournamentReminder"].data.tournaments;
    }
  },
  methods: {
    ...mapActions("tournaments", ["launchTournamentGame", "dismissTournament"]),
    play(tournament) {
      this.close();
      this.launchTournamentGame({ tournament });
    },
    dismissTournaments() {
      this.tournaments.forEach(tournament =>
        this.dismissTournament({
          tournament: tournament.Tournament
        })
      );
    },
    close() {
      this.showDialog = false;
      this.dismissTournaments();
    }
  }
};
</script>
<style scoped>
::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0.5rem;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background: rgba(30, 30, 30, 0.15);
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: var(--v-tournamentTabInactive-base);
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).active,
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).active {
  background: var(--v-tournamentTab-base);
}

.v-expansion-panels {
  z-index: 0;
}

.v-expansion-panel {
  padding: 1rem;
}

.v-expansion-panel-header {
  padding: 1rem;
  background: var(--v-tournament-base);
  color: var(--v-tournamentTxt-base);
  border-radius: 1rem;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
