<template>
  <v-dialog
    v-if="loggedIn && hasWonPrize"
    v-model="showDialog"
    content-class="casinoDialog"
    persistent
    style="border: none !important;"
  >
    <v-card class="pa-2" outlined tile style="z-index:1;">
      <p
        class="text-center casinoJackpotName mb-0"
        :class="smallLayout ? 'smallContainer' : 'bigContainer'"
      >
        {{ $str("LobbyJackpot") }}
      </p>
      <p
        class="text-center congratulationsLabel mb-0"
        :class="
          smallLayout ? 'smallcongratulationsLabel' : 'bigcongratulationsLabel'
        "
      >
        {{ $str("lblCongratulations") }}
      </p>
      <!-- eslint-disable vue/no-v-html -->
      <div class="prize" :class="smallLayout ? 'smallLayout' : ''">
        <p class="text-center youHaveWonlabel mb-0">{{ $str("YouWon") }}</p>
        <p class="text-center jackpotPrice mb-0" v-html="jackpotPrice"></p>
      </div>
      <div v-if="loggedIn" class="btnContainer">
        <v-btn
          class="mb-0 responsiveButton"
          :class="smallLayout ? 'smallLayout' : ''"
          color="button buttonTxt--text"
          form="form"
          block
          @click="Claim"
        >
          {{ $str("ClaimJackpotPrize") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "casinoJackpotWonPrize"
    };
  },
  computed: {
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("casinoJackpot", ["casinoJackpot", "casinoJackpotUser"]),
    jackpotPrice() {
      return this.$cur(this.casinoJackpotUser.UnclaimedHitsAmount);
    },
    hasWonPrize() {
      return this.casinoJackpotUser.UnclaimedHitsAmount > 0 ?? false;
    }
  },
  watch: {
    hasWonPrize(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        this.showCasinoJackpotWonPrize();
      }
    }
  },
  methods: {
    ...mapActions("dialogs", ["showCasinoJackpotWonPrize"]),
    ...mapActions("casinoJackpot", ["ClaimJackpotPrize"]),
    Claim() {
      this.ClaimJackpotPrize();
      this.showDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .casinoDialog.v-dialog:not(.downTimeDialog):not(.rounded-0) {
  border: none;
}

@media only screen and (min-width: 1400px) {
  ::v-deep .casinoDialog.v-dialog:not(.downTimeDialog):not(.rounded-0) {
    max-width: 30vw;
  }
}

@media screen and (min-width: 950px) and (max-width: 1400px) {
  ::v-deep .casinoDialog.v-dialog:not(.downTimeDialog):not(.rounded-0) {
    max-width: 40vw;
  }
}
@media screen and (min-width: 650px) and (max-width: 950px) {
  ::v-deep .casinoDialog.v-dialog:not(.downTimeDialog):not(.rounded-0) {
    max-width: 50vw;
  }
}
@media screen and (max-width: 650px) {
  ::v-deep .casinoDialog.v-dialog:not(.downTimeDialog):not(.rounded-0) {
    max-width: 75vw;
  }
}

::v-deep .casinoDialog {
  & > .v-dialog:not(.downTimeDialog):not(.rounded-0) {
    border: none;
  }
  & > .v-card {
    padding-top: 3vh !important;
    padding-bottom: 3vh !important;
    border-radius: 15px !important;
    background: -webkit-linear-gradient(
      -90deg,
      var(--v-casinoJackpotBackgroundGradientColor1-base),
      var(--v-casinoJackpotBackgroundGradientColor2-base) 99%
    );
    border: none !important;
  }

  .casinoJackpotName {
    background: -webkit-linear-gradient(
      -90deg,
      var(--v-casinoJackpotBackgroundGradientColor1-base),
      var(--v-casinoJackpotBackgroundGradientColor2-base) 75%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bolder;
    filter: drop-shadow(2px 2px #333);
    &.bigContainer {
      font-size: 5vh;
      line-height: 5vh;
    }
    &.smallContainer {
      font-size: 9vw;
      line-height: 10vw;
    }
    margin: 5px 0px !important;
  }

  .congratulationsLabel {
    color: #ffffff;
    margin: 5px 0px !important;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: bolder;
    filter: drop-shadow(0.5px 0.5px #333);
    &.bigcongratulationsLabel {
      font-size: 3vh;
      line-height: 3vh;
    }
    &.smallcongratulationsLabel {
      font-size: 6vw;
      line-height: 5.5vw;
    }
  }
  .prize {
    background: -webkit-linear-gradient(-90deg, #000, #000 80%);
    color: #ffffff;
    border: #fff solid 1px;
    border-radius: 15px !important;
    margin: auto 4vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    min-width: 120px;
    font-weight: bolder;
    font-size: 30px;
    margin-top: 5px !important;
    margin-bottom: 15px !important;
    &.smallLayout {
      padding-top: 1.5vh !important;
      padding-bottom: 1vh !important;
    }
    .youHaveWonlabel {
      font-weight: 100;
      font-size: 15px;
    }
  }
}

.btnContainer {
  .v-btn:not(.v-btn--round).v-size--default {
    height: 4vh;
    min-width: 12vw !important;
    padding: 5px 5px;
    font-size: 0.65em;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    span {
      margin: 5px;
    }
  }
}

::v-deep .casinoDialog:not(.v-dialog--fullscreen) {
  max-height: 95vh !important;
}

.responsiveButton {
  font-size: 1.1rem !important;
  padding-top: 8px !important;
  height: 35px !important;
  line-height: 35px !important;
  padding-bottom: 3px !important;

  &.smallLayout {
    font-size: 0.8rem !important;
    padding-bottom: 4px !important;
  }
}
</style>
