/**
 * Retrieves and parses messages sent directly from the player sheet in the Admin
 */

import { LobbyActions } from "./lobbyActions";

const getCashierActionFromParams = params => {
  const action = (params.get("action") || "").toLowerCase();

  switch (action) {
    case "deposit":
      return { type: LobbyActions.Deposit };
    case "withdraw":
      return { type: LobbyActions.Withdraw };
    case "redeemcoupon":
      return {
        type: LobbyActions.RedeemCoupon,
        params: { couponCode: params.get("coupon") }
      };
    default:
      return { type: LobbyActions.Deposit };
  }
};

const getGameIdFromParams = params => {
  const gameId = params.get("gameId");
  const machId = parseInt(params.get("machId"));
  const hands = params.get("hands") || 0;
  return `${gameId}-${machId}-${hands}`;
};

const getLobbyActionFromUri = uri => {
  try {
    const url = new URL(uri);
    const proto = url.protocol.toLowerCase();

    if (proto.startsWith("http")) {
      return { type: LobbyActions.Website, params: { url } };
    }

    if (proto.startsWith(CDK.getCasinoSchema()) || proto.startsWith("rtg")) {
      const params = url.searchParams;
      const packageParam = (params.get("package") || "").toLowerCase();

      if (packageParam.includes("cashier")) {
        return getCashierActionFromParams(params);
      }

      if (packageParam.includes("livechat")) {
        return { type: LobbyActions.LiveChat };
      }

      const path = url.pathname.toLowerCase();
      if (path.includes("game") && path.includes("exit")) {
        return { type: LobbyActions.ExitGame };
      }

      if (path.includes("game") && path.includes("launch")) {
        const gameId = getGameIdFromParams(params);
        return { type: LobbyActions.LaunchGame, params: { gameId } };
      }
    }
  } catch (e) {
    return "";
  }
};

const buttonsMap = {
  [CDK.AdminPlayerMessageButtons.ok]: ["ok"],
  [CDK.AdminPlayerMessageButtons.okcancel]: ["ok", "cancel"],
  [CDK.AdminPlayerMessageButtons.yesno]: ["yes", "no"],
  [CDK.AdminPlayerMessageButtons.yesnocancel]: ["yes", "no", "cancel"]
};

export const parseAdminPlayerMessage = message => {
  return {
    messageId: message.MessageID,
    text: message.Message,
    icon: message.Icon,
    buttons: buttonsMap[message.Buttons],
    action: getLobbyActionFromUri(message.Uri),
    masterId: message.MasterId
  };
};
