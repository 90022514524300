<template>
  <v-container v-if="casinoJackpot.Status" fluid class="pa-0 ma-0">
    <v-container
      v-if="smallLayout"
      id="CasinoJackpotBannerMobile"
      class="d-flex justify-center"
    >
      <v-card class="rounded mt-6 lobbyJackpot" :style="backgroundStyle">
        <v-img
          max-width="120px"
          max-height="70px"
          class="jackpotLogoTop"
          :src="require('@/assets/lobbyjackpot.png')"
          contain
        />
        <div class="optIn">
          <CasinoJackpotOptInButton v-if="canOptIn" />
          <CasinoJackpotInfoButton v-if="!playerIsRestricted" />
        </div>
        <v-row class="justify-center mt-n7">
          <p
            class="text-center jackpotPrizeFormatted casinoJackpotAmount--text"
          >
            <transition name="fade" mode="out-in">
              <div key="x">{{ jackpotPrizeFormatted }}</div>
            </transition>
          </p>
        </v-row>
        <v-row class="justify-center mt-n7">
          <CasinoJackpotRecentWinnerCarousel v-if="hasRecentWinners" />
        </v-row>
      </v-card>
    </v-container>
    <v-container
      v-else
      id="CasinoJackpotBanner"
      no-gutters
      fluid
      class="py-0 my-3"
    >
      <v-card class="rounded">
        <v-container
          fluid
          class="CasinoJackpotBanner mx-0 px-1 py-0 d-flex align-center rounded"
          :style="backgroundStyle"
        >
          <v-row class="d-flex flex-row flex-nowrap align-center content">
            <v-img
              max-width="120px"
              height="70px"
              :src="require('@/assets/lobbyjackpot.png')"
              contain
            />
            <div>
              <p
                class="text-left jackpotPrizeFormatted casinoJackpotAmount--text mb-0"
              >
                <transition name="fade" mode="out-in">
                  <div key="x">{{ jackpotPrizeFormatted }}</div>
                </transition>
              </p>
            </div>
            <CasinoJackpotRecentWinnerCarousel v-if="hasRecentWinners" />
            <v-spacer v-else></v-spacer>
            <CasinoJackpotOptInButton v-if="canOptIn" class="pa-0" />
            <CasinoJackpotInfoButton v-if="!playerIsRestricted" />
          </v-row> </v-container
      ></v-card> </v-container
  ></v-container>
</template>

<script>
import CasinoJackpotRecentWinnerCarousel from "@/components/casinoJackpot/CasinoJackpotRecentWinnerCarousel";
import CasinoJackpotInfoButton from "@/components/casinoJackpot/CasinoJackpotInfoButton.vue";
import CasinoJackpotOptInButton from "@/components/casinoJackpot/CasinoJackpotOptInButton.vue";
import { mapGetters } from "vuex";
import gsap from "gsap";

export default {
  components: {
    CasinoJackpotRecentWinnerCarousel,
    CasinoJackpotInfoButton,
    CasinoJackpotOptInButton
  },
  data() {
    return {
      intervalNumber: 0,
      display: 0,
      incrementTime: 0.95,
      time: 0,
      tween: null
    };
  },
  computed: {
    ...mapGetters("session", ["loggedIn", "playForMoney"]),
    ...mapGetters("casinoJackpot", [
      "casinoJackpot",
      "casinoJackpotWinners",
      "casinoJackpotUser",
      "jackpotPrizeDisplay",
      "playerIsRestricted"
    ]),
    ...mapGetters("customizations", ["specialThemes"]),
    canOptIn() {
      return (
        this.loggedIn &&
        this.playForMoney &&
        !this.casinoJackpotUser.IsOptIn &&
        !this.playerIsRestricted
      );
    },
    hasRecentWinners() {
      return this.casinoJackpotWinners.length > 0;
    },
    jackpotPrizeFormatted() {
      return this.$cur(this.display);
    },
    backgroundStyle() {
      const specialTheme = this.specialThemes && this.specialThemes[this.$mode];
      const imageUrl = this.hasRecentWinners
        ? specialTheme?.specialThemeLobbyJackpotWithRecentWinnersURL
        : specialTheme?.specialThemeLobbyJackpotNoRecentWinnersURL;

      if (imageUrl) {
        return {
          backgroundImage: `url(${imageUrl}), linear-gradient(var(--v-casinoJackpotBackgroundGradientColor1-base),
      var(--v-casinoJackpotBackgroundGradientColor2-base))`,
          backgroundSize: `${this.smallLayout ? "contain, auto" : "cover"} `,
          backgroundPosition: `${!this.smallLayout && "center center, center"}}`
        };
      } else {
        return `background-image: linear-gradient(var(--v-casinoJackpotBackgroundGradientColor1-base), var(--v-casinoJackpotBackgroundGradientColor2-base))`;
      }
    }
  },
  watch: {
    jackpotPrizeDisplay(value) {
      this.time = this.incrementTime;
      if (value) {
        this.incrementAnimation(value);
      }
    }
  },
  methods: {
    incrementAnimation(value) {
      const that = this;
      if (!this.tween || !this.tween.isActive()) {
        this.intervalNumber++;
        this.tween = gsap.to(this.$data, {
          duration: this.time,
          display: value,
          onComplete: () => {
            that.time = this.incrementTime;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.lobbyJackpot {
  width: 100%;
  min-height: 70px;
}
::v-deep .CasinoJackpotBanner {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
  display: flex;
  color: white;
  height: 70px;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);

  .v-card:not(.recentwinnersList) {
    border: none !important;
  }

  .row {
    height: 100%;
    width: 100%;
    padding: 0 5%;
  }
}

.responsiveButton {
  height: 14px !important;
  font-size: 0.75rem !important;
}

.jackpotPrizeFormatted {
  line-height: 1em;
  font-weight: 700;
  font-size: 20px;
}

@media (min-width: 751px) {
  .jackpotPrizeFormatted {
    font-size: 22px;
  }
}

.jackpotLogoTop {
  top: -30px;
  left: calc(50% - 60px);
  text-align: center;
}

.optIn {
  display: inline-flex;
  position: absolute;
  right: 10px;
  top: 10px;
  align-items: center;
}
</style>
