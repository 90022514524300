import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("dialogs", ["isAnyDialogOpen"]),
    ...mapGetters("customizations", [
      "customHomeURL",
      "customHomeChannel",
      "isTournamentsOnly",
      "exitUrl"
    ])
  },
  methods: {
    ...mapActions("dialogs", ["hideAll"]),
    goHome(showAllGames) {
      //Go to external lobby home if in tournaments only view
      if (this.isTournamentsOnly) {
        window.location.href = this.exitUrl;
        return;
      }

      const home = showAllGames === true ? "home" : "customhome";
      if (this.$route.name === home) {
        if (this.isAnyDialogOpen) {
          this.hideAll();
        }
        this.$store.dispatch("games/refreshFavoriteGames");
        setTimeout(() => window.scrollTo(0, 0), 1);
      } else {
        this.$router
          .push({
            name: home,
            params: {
              showAllGames: showAllGames === true
            }
          })
          .catch(() => {});
      }
    },
    hasCustomHome(showAllGames) {
      if (
        this.customHomeURL &&
        this.customHomeURL.length > 0 &&
        !showAllGames
      ) {
        switch (this.customHomeChannel.toLowerCase()) {
          case "instantplay":
            if (this.largeLayout) {
              return true;
            }
            break;
          case "mobile":
            if (this.smallLayout) {
              return true;
            }
            break;
          case "both":
            return true;
        }
        return false;
      }
    }
  }
};
