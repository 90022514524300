/*
 * Refer Friend.
 */
const state = {
  playerReferralSetting: 0,
  programs: [],
  referralLink: ""
};

const getters = {
  playerReferralSetting: state => state.playerReferralSetting,
  programs: state => state.programs,
  referralLink: state => state.referralLink
};

const actions = {
  async initialize(context) {
    CDK.on(CDK.Events.loggedIn, async () => {
      await context.dispatch("requestPrograms");
      await context.dispatch("getPlayerReferralSetting");
    });
  },
  async requestPrograms(context) {
    const programs = await CDK.getAffiliatePrograms();
    /* ************ */

    context.commit("updatePrograms", programs);
  },
  async generateReferAFriendLink(context, programId) {
    const referralLink = await CDK.generateReferAFriendLink(programId);
    /* ************ */

    context.commit("updateReferralLink", referralLink);
  },
  async getPlayerReferralSetting(context) {
    const playerReferralSetting = await CDK.getPlayerReferralSetting();
    /* ************ */
    context.commit("updatePlayerReferralSetting", playerReferralSetting);
  }
};

const mutations = {
  updatePrograms(state, programs) {
    state.programs = programs;
  },
  updateReferralLink(state, referralLink) {
    state.referralLink = referralLink;
  },
  updatePlayerReferralSetting(state, playerReferralSetting) {
    state.playerReferralSetting = playerReferralSetting;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
