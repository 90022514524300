/**
 * Dialog Toggle Mixin
 *
 * Standardizes the toggling of dialogs, backed by the 'dialogs' store module.
 * To create a dialog based on this mixin, you must do the following:
 *
 * 1) Define the 'name' property in the component's data object
 * 2) Add an entry into the 'dialogs' store with the same name
 * 3) Add the component to AppDialogs.vue
 *
 * @see @/store/modules/dialogs.js
 */
import DialogTitleBar from "@/components/Dialogs/DialogTitleBar";

export const dialogToggle = {
  components: { DialogTitleBar },
  data() {
    return {
      /** Name of the component, maps to a state object in the store */
      name: "",
      scrollFix: true
    };
  },
  computed: {
    /** Whether or not the dialog should be displayed right now  */
    showDialog: {
      get() {
        if (this.name === "") {
          window.console.warn("Warning, dialogToggle missing name!");
        } else {
          if (this.$store.state.dialogs.hasOwnProperty(this.name)) {
            return this.$store.state.dialogs[this.name].isVisible;
          } else {
            window.console.warn(
              `dialogToggle.js: Dialog not found in store! Name: ${this.name}`
            );
            return false;
          }
        }
      },
      set(value) {
        if (value) {
          this.$store.dispatch("dialogs/showDialog", {
            name: this.name,
            scrollFix: this.scrollFix
          });
        } else {
          this.$store.dispatch("dialogs/hideDialog", {
            name: this.name,
            scrollFix: this.scrollFix
          });
        }
      }
    }
  },
  watch: {
    // link to suggested fix for background scrolling:
    // https://github.com/vuetifyjs/vuetify/issues/3875#issuecomment-562538651
    //In order to fixed the problem related with MRI-6677 we changed the position fixed by overflow hidden. This fix works to scrolling problem too
    //We added position fixed again  with two settings more  to solve MRI-7248 and conserving the old fixes
    showDialog(value) {
      if (value) {
        if (this.scrollFix) {
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = "fixed";
          document.documentElement.style.overflow = "hidden";
          document.body.style.position = "fixed";
          document.body.style.width = "100%";
          document.body.style.left = "0";
          document.body.style.right = "0";
        }
        this.onShown();
      } else {
        if (
          (this.$store.getters["dialogs/openedSignUpDialog"] ||
            !this.$store.getters["dialogs/isAnyDialogOpen"]) &&
          this.scrollFix
        ) {
          const scrollY = document.body.style.top;
          document.body.style.position = "";
          document.body.style.top = "";
          document.documentElement.style.overflow = "";
          document.body.style.overflow = "";
          document.body.style.position = "";
          document.body.style.width = "";

          document.body.style.left = "";
          document.body.style.right = "";
          window.scrollTo({ top: parseInt(scrollY || "0") * -1 });
        }

        if (this.$store.getters["casinoJackpot/isCasinoJackpotAvailable"]) {
          this.$store.dispatch("casinoJackpot/requestCasinoJackpotUser", null, {
            root: true
          });
        }
        this.onHidden();
      }
    }
  },
  methods: {
    /** Override this to perform actions when the dialog is shown */
    onShown() {},

    /** Override this to perform actions when the dialog is hidden */
    onHidden() {}
  }
};
