<template>
  <v-card color="body" :min-width="smallLayout ? 325 : 400">
    <v-container class="pt-0">
      <v-card-text class="pa-1">
        <v-row>
          <v-col class="col-10"
            ><h3>
              {{ $strResourceService("PendingCoupons") }}
            </h3></v-col
          >
          <v-col class="col-2">
            <v-icon @click.stop="$emit('close')">{{ "mdi-close" }}</v-icon>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div v-for="(couponPrize, index) in couponPrizes" :key="index">
          <v-row>
            <v-col class="col-5">{{
              couponPrize.TournamentInformation.Name
            }}</v-col>
            <v-col class="col-4">{{
              $tournamentCur(couponPrize.Amount)
            }}</v-col>
            <v-col class="col-3">
              <v-btn
                text
                x-small
                style="font-size: 0.75rem"
                @click.stop="reedemCoupon(couponPrize)"
              >
                {{ $str("Redeem") }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {},
  data: () => ({}),
  computed: {
    ...mapGetters("tournaments", ["couponPrizes"])
  },
  methods: {
    ...mapActions("tournaments", ["requestTournamentCouponPrizes"]),
    async reedemCoupon(couponPrize) {
      this.$store.dispatch("coupons/redeem", {
        couponCode: couponPrize.CouponCode.replace(/\s+/g, "")
      });
      this.requestTournamentCouponPrizes();
      window.LobbyCore.raise(window.LobbyCore.Events.showCouponsPage);
      this.$emit("close");
    }
  }
};
</script>
