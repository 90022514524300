<template>
  <v-dialog
    v-if="accountFieldsLoaded"
    v-model="showDialog"
    persistent
    width="370"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
    :content-class="contentClass"
  >
    <DialogTitleBar :title="title" @close="close" />
    <v-stepper
      v-if="showDialog"
      id="signup-form"
      v-model="signupwizard"
      class="elevation-0"
      style="overflow-y: auto;overflow-x: hidden;"
    >
      <v-stepper-header
        class="mx-4"
        style="justify-content: normal; align-content: center; height: 50px; border-radius: 30px;"
      >
        <v-stepper-step
          :step="$str('Step').replace('{0}', 1)"
          :class="signupwizard == 1 ? 'active' : ''"
        ></v-stepper-step>

        <!-- <v-divider></v-divider> -->

        <v-stepper-step
          :step="$str('Step').replace('{0}', 2)"
          :class="signupwizard == 2 ? 'active' : ''"
          color="progressTracker progressTrackerTxt--text"
        ></v-stepper-step>

        <!-- <v-divider></v-divider> -->

        <v-stepper-step
          :step="$str('Step').replace('{0}', 3)"
          :class="signupwizard == 3 ? 'active' : ''"
          color="progressTracker progressTrackerTxt--text"
        ></v-stepper-step>
      </v-stepper-header>
      <br />
      <div v-if="getCustomHeader(signupwizard)" id="iframeCustomMessage">
        <embed
          id="iframeCustomHeader"
          ref="iFrameCustomHeader"
          :src="getCustomHeader(signupwizard)"
          :style="customHeaderStyle"
          :height="customHeaderHeight"
          scrolling="no"
          frameborder="0"
        />
      </div>
      <v-form id="long-signup-form" ref="form">
        <v-stepper-content step="1">
          <v-text-field
            id="longSignUpFirstName"
            ref="firstName"
            v-model.trim="fields.FirstName"
            :class="getFieldClass(accountCreateFields.FirstName)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.FirstName"
            :rules="accountCreateFieldsRules.FirstName"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            :success="success('FirstName')"
            :append-icon="iconToAppend(accountCreateFields.FirstName)"
            @blur="focused['FirstName'] = false"
            @focus="focused['FirstName'] = true"
          >
          </v-text-field>
          <v-text-field
            id="longSignUpLastName"
            ref="lastName"
            v-model.trim="fields.LastName"
            :class="getFieldClass(accountCreateFields.LastName)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.LastName"
            :rules="accountCreateFieldsRules.LastName"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            :success="success('LastName')"
            :append-icon="iconToAppend(accountCreateFields.LastName)"
            @blur="focused['LastName'] = false"
            @focus="focused['LastName'] = true"
          >
          </v-text-field>
          <v-text-field
            id="longSignUpEMail"
            ref="emailAddress"
            v-model.trim="fields.EMailAddress"
            :class="getFieldClass(accountCreateFields.EMailAddress)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.EMailAddress"
            :rules="accountCreateFieldsRules.EMailAddress"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            autocomplete="off"
            :success="success('EMailAddress')"
            :append-icon="iconToAppend(accountCreateFields.EMailAddress)"
            :error-messages="emailError"
            @blur="focused['EMailAddress'] = false"
            @focus="focused['EMailAddress'] = true"
          >
          </v-text-field>
          <v-text-field
            v-if="!showEmailUsernameField"
            id="longSignUpLogin"
            ref="login"
            v-model.trim="fields.Login"
            :class="getFieldClass(accountCreateFields.Login)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Login"
            :rules="accountCreateFieldsRules.Login"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            autocomplete="off"
            :success="success('Login')"
            :append-icon="iconToAppend(accountCreateFields.Login)"
            :error-messages="userNameError"
            @blur="focused['Login'] = false"
            @focus="focused['Login'] = true"
          >
          </v-text-field>
          <v-select
            v-if="hasMultipleCurrencies"
            id="longSignUpCurrency"
            ref="currency"
            v-model.trim="fields.playerCurrencyId"
            :success="!!fields.playerCurrencyId"
            class="v-select-custom"
            outlined
            dense
            :label="$strResourceService('Currency')"
            :items="currencyList"
            :rules="currencyRules"
            hide-details="auto"
            item-value="Key"
            item-text="Value"
          ></v-select>
          <v-text-field
            id="longSignUpPassword"
            ref="password"
            v-model.trim="fields.Password"
            :class="getFieldClass(accountCreateFields.Password)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Password"
            :rules="accountCreateFieldsRules.Password"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            autocomplete="new-password"
            :type="showPassword['Password'] ? 'text' : 'password'"
            :success="success('Password')"
            @blur="focused['Password'] = false"
            @focus="focused['Password'] = true"
          >
            <template slot="append">
              <!-- if focused, use show password button -->
              <v-btn
                v-if="focused['Password']"
                icon
                style="height:24px;width:24px;color:inherit;"
                class="ma-0"
                @click="showPassword['Password'] = !showPassword['Password']"
                ><v-icon>{{
                  showPassword["Password"] ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon></v-btn
              >
              <!-- otherwise use check -->
              <v-icon
                v-else-if="success('Password') && isPasswordValid"
                style="height:24px;width:24px;color:inherit;"
                >$Check</v-icon
              >
            </template>
          </v-text-field>
          <div
            v-if="
              passwordStrength > 0 &&
                strengthEnable &&
                !featurePasswordStrengthEnabled
            "
            id="password-strength"
            :class="'level-' + passwordStrength"
          >
            <span> {{ passwordLevelName }} </span>
            <v-tooltip v-if="passwordStrength < 3" bottom color="secondary">
              <template v-slot:activator="{ on }">
                <v-icon color="lighten-1 mx-0" right :size="13" v-on="on"
                  >mdi-help-circle
                </v-icon>
              </template>
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="recomendationsPassword"> </span>
            </v-tooltip>
          </div>
          <PasswordValidation
            v-if="strengthEnable && featurePasswordStrengthEnabled"
            ref="passwordValidation"
            :password="fields.Password"
            :min-password="minPaswwordLength"
            @passwordMounted="passwordMount"
          ></PasswordValidation>
          <v-text-field
            id="longSignUpConfirmPassword"
            ref="confirmPassword"
            v-model.trim="confirmPassword"
            :class="getFieldClass(accountCreateFields.Password)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Password"
            :label="$strResourceService('ConfirmationPassword')"
            name="ConfirmPassword"
            :rules="confirmPasswordRules"
            color="inherit"
            autocomplete="new-password"
            hide-details="auto"
            :type="showPassword['confirmPassword'] ? 'text' : 'password'"
            :success="!!confirmPassword && confirmPassword === fields.Password"
            @blur="focused['confirmPassword'] = false"
            @focus="focused['confirmPassword'] = true"
          >
            <template slot="append">
              <!-- if focused, use show password button -->
              <v-btn
                v-if="focused['confirmPassword']"
                icon
                style="height:24px;width:24px;color:inherit;"
                class="ma-0"
                @click="
                  showPassword['confirmPassword'] = !showPassword[
                    'confirmPassword'
                  ]
                "
                ><v-icon>{{
                  showPassword["confirmPassword"] ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon></v-btn
              >
              <!-- otherwise use check -->
              <v-icon
                v-else-if="!!confirmPassword"
                style="height:24px;width:24px;color:inherit;"
                >$Check</v-icon
              >
            </template></v-text-field
          >
          <v-alert
            v-show="signupError"
            type="error"
            dense
            :icon="false"
            class="text-left mb-4 error errorTxt--text"
            style="font-weight: 700;"
          >
            {{ signupError }}
          </v-alert>
          <v-btn
            id="longSignUpNext"
            color="button buttonTxt--text"
            :disabled="!isPasswordValid"
            @click="step1Next($event)"
          >
            {{ $strResourceService("Next") }}
          </v-btn>

          <!-- TODO: in the next release, these strings will need translations for ZH-CN and ZH-TW, 
        but there are none available right now.-->
          <!-- eslint-disable vue/no-v-html -->
          <v-card-text
            class="text-left pl-0"
            style="font-size: 12px; font-weight: 700;"
          >
            <span v-html="alreadyHaveAccountMessagePt1"> </span>
            <a
              class="hyperlink--text ma-0"
              @click.stop.prevent="login"
              v-html="alreadyHaveAccountMessagePt2"
            >
            </a>
          </v-card-text>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-text-field
            v-if="isShown(accountCreateFields.AddressLine1)"
            id="longSignUpAddressLine1"
            ref="addressLine1"
            v-model.trim="fields.AddressLine1"
            :class="getFieldClass(accountCreateFields.AddressLine1)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.AddressLine1"
            :rules="accountCreateFieldsRules.AddressLine1"
            color="inherit"
            validate-on-blur
            :success="success('AddressLine1')"
            :append-icon="iconToAppend(accountCreateFields.AddressLine1)"
            hide-details="auto"
            @blur="focused['AddressLine1'] = false"
            @focus="focused['AddressLine1'] = true"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.AddressLine2)"
            id="longSignUpAddressLine2"
            ref="addressLine2"
            v-model.trim="fields.AddressLine2"
            :class="getFieldClass(accountCreateFields.AddressLine2)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.AddressLine2"
            :rules="accountCreateFieldsRules.AddressLine2"
            color="inherit"
            validate-on-blur
            :success="success('AddressLine2')"
            :append-icon="iconToAppend(accountCreateFields.AddressLine2)"
            hide-details="auto"
            @blur="focused['AddressLine2'] = false"
            @focus="focused['AddressLine2'] = true"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.City)"
            id="longSignUpCity"
            ref="city"
            v-model.trim="fields.City"
            :class="getFieldClass(accountCreateFields.City)"
            autocomplete="off"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.City"
            :rules="accountCreateFieldsRules.City"
            color="inherit"
            validate-on-blur
            :success="success('City')"
            :append-icon="iconToAppend(accountCreateFields.City)"
            hide-details="auto"
            @blur="focused['City'] = false"
            @focus="focused['City'] = true"
          ></v-text-field>
          <v-select
            v-if="isShown(accountCreateFields.Country)"
            id="longSignUpCountry"
            ref="country"
            v-model.trim="fields.Country"
            class="v-select-custom"
            :class="getFieldClass(accountCreateFields.Country)"
            :success="!!fields.Country"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Country"
            :rules="accountCreateFieldsRules.Country"
            :items="getItems(accountCreateFields.Country)"
            :append-icon="iconToAppend(accountCreateFields.Country)"
            hide-details="auto"
            item-value="Value"
            item-text="Key"
            @change="setStateFieldAndPhoneCodes"
          >
          </v-select>

          <v-select
            v-if="showStateDropDown"
            id="longSignUpState"
            ref="state"
            v-model.trim="fields.State"
            :class="getFieldClass(accountCreateFields.State)"
            outlined
            dense
            :success="!!fields.State"
            class="v-select-custom"
            v-bind="accountCreateFieldsAttributes.State"
            :rules="accountCreateFieldsRules.State"
            :items="getItems(accountCreateFields.State)"
            :append-icon="iconToAppend(accountCreateFields.State)"
            hide-details="auto"
            item-value="Value"
            item-text="Key"
          >
          </v-select>
          <v-text-field
            v-else
            id="longSignUpState"
            ref="state"
            v-model.trim="fields.State"
            :class="getFieldClass(accountCreateFields.State)"
            :success="!!fields.State"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.State"
            :rules="accountCreateFieldsRules.State"
            :append-icon="iconToAppend(accountCreateFields.State)"
            hide-details="auto"
          >
          </v-text-field>

          <v-text-field
            v-if="isShown(accountCreateFields.Zip)"
            id="longSignUpZip"
            ref="zipCode"
            v-model.trim="fields.Zip"
            :class="getFieldClass(accountCreateFields.Zip)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Zip"
            :rules="accountCreateFieldsRules.Zip"
            color="inherit"
            :success="success('Zip')"
            :append-icon="iconToAppend(accountCreateFields.Zip)"
            hide-details="auto"
            @blur="focused['Zip'] = false"
            @focus="focused['Zip'] = true"
          ></v-text-field>
          <v-row dense>
            <v-col dense cols="6" class="pt-0"
              ><v-btn
                block
                color="buttonSecondary buttonSecondaryTxt--text"
                @click="signupwizard = 1"
              >
                {{ $strResourceService("Previous") }}
              </v-btn></v-col
            >
            <v-col dense cols="6 pt-0">
              <v-btn
                block
                color="button buttonTxt--text"
                @click="step2Next($event)"
              >
                {{ $strResourceService("Next") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-text-field
            v-if="isShown(accountCreateFields.Birthdate)"
            id="longSignUpBirthdate"
            ref="birthdate"
            v-model.trim="fields.Birthdate"
            :class="getFieldClass(accountCreateFields.Birthdate)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.Birthdate"
            :rules="accountCreateFieldsRules.Birthdate"
            :max="currentDate"
            :success="success('Birthdate')"
            :append-icon="iconToAppend(accountCreateFields.Birthdate)"
            hide-details="auto"
            @blur="focused['Birthdate'] = false"
            @focus="focused['Birthdate'] = true"
          ></v-text-field>
          <v-select
            v-if="isShown(accountCreateFields.gender)"
            id="longSignUpGender"
            ref="gender"
            v-model.trim="fields.gender"
            :class="getFieldClass(accountCreateFields.gender)"
            :success="!!fields.gender"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.gender"
            :rules="accountCreateFieldsRules.gender"
            :items="getItems(accountCreateFields.gender)"
            hide-details="auto"
            item-value="Value"
            item-text="Key"
            class="v-select-custom"
          >
          </v-select>

          <v-text-field
            v-if="isShown(accountCreateFields.cellPhone)"
            id="longSignUpCellPhone"
            ref="cellPhone"
            v-model.trim="fields.cellPhone"
            :class="getFieldClass(accountCreateFields.cellPhone)"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.cellPhone"
            :rules="CellPhoneCountryRules"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            autocomplete="off"
            :success="phoneRuleSuccess('cellPhone')"
            :append-icon="phoneIconAppend(accountCreateFields.cellPhone)"
            @blur="focused['cellPhone'] = false"
            @focus="focused['cellPhone'] = true"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.DaytimePhone)"
            id="longSignUpDaytimePhone"
            ref="dayTimePhone"
            v-model.trim="fields.DaytimePhone"
            :class="getFieldClass(accountCreateFields.DaytimePhone)"
            autocomplete="off"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.DaytimePhone"
            :rules="DaytimePhoneCountryRules"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            :success="phoneRuleSuccess('DaytimePhone')"
            :append-icon="phoneIconAppend(accountCreateFields.DaytimePhone)"
            @blur="focused['DaytimePhone'] = false"
            @focus="focused['DaytimePhone'] = true"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.EveningPhone)"
            id="longSignUpEveningPhone"
            ref="eveningPhone"
            v-model.trim="fields.EveningPhone"
            :class="getFieldClass(accountCreateFields.EveningPhone)"
            autocomplete="off"
            outlined
            dense
            v-bind="accountCreateFieldsAttributes.EveningPhone"
            :rules="EveningPhoneCountryRules"
            color="inherit"
            validate-on-blur
            hide-details="auto"
            :success="phoneRuleSuccess('EveningPhone')"
            :append-icon="phoneIconAppend(accountCreateFields.EveningPhone)"
            @blur="focused['EveningPhone'] = false"
            @focus="focused['EveningPhone'] = true"
          ></v-text-field>
          <v-overlay absolute :value="SigningUp">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div>
            <v-checkbox
              v-if="isShown(accountCreateFields.SMSMessages)"
              id="longSignUpSMSMessages"
              ref="SMSMessages"
              v-model="fields.SMSMessages"
              :class="getFieldClass(accountCreateFields.SMSMessages)"
              dense
              v-bind="accountCreateFieldsAttributes.SMSMessages"
              :rules="checkboxRules('SMSMessages')"
              class="ml-1 mt-0 pt-0"
              color="popupTxt popupTxt--text"
              :success="!!fields.SMSMessages"
              hide-details="auto"
              :disabled="isDefault(accountCreateFields.SMSMessages)"
              @blur="focused['SMSMessages'] = false"
              @focus="focused['SMSMessages'] = true"
            >
              <template v-slot:label>
                <div>
                  {{ $strResourceService("SmsMessages") }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="isShown(accountCreateFields.BonusesAndPromotions)"
              id="longSignUpPromotions"
              ref="BonusesAndPromotions"
              v-model="fields.BonusesAndPromotions"
              dense
              class="ml-1 mt-0 pt-0"
              color="popupTxt popupTxt--text"
              :class="getFieldClass(accountCreateFields.BonusesAndPromotions)"
              v-bind="accountCreateFieldsAttributes.BonusesAndPromotions"
              :rules="checkboxRules('BonusesAndPromotions')"
              :success="!!fields.BonusesAndPromotions"
              hide-details="auto"
              :disabled="isDefault(accountCreateFields.BonusesAndPromotions)"
              @blur="focused['BonusesAndPromotions'] = false"
              @focus="focused['BonusesAndPromotions'] = true"
            >
              <template v-slot:label>
                <div>
                  {{ $strResourceService("AcceptPromotions") }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
              v-if="isShown(accountCreateFields.TermsAndConditions)"
              id="longSignUpTermsNConditions"
              ref="TermsAndConditions"
              v-model="fields.TermsAndConditions"
              color="popupTxt popupTxt--text"
              dense
              class="ml-1 mt-0 pt-0"
              :class="getFieldClass(accountCreateFields.TermsAndConditions)"
              v-bind="accountCreateFieldsAttributes.TermsAndConditions"
              :rules="checkboxRules('TermsAndConditions')"
              :success="!!fields.TermsAndConditions"
              hide-details="auto"
              :disabled="isDefault(accountCreateFields.TermsAndConditions)"
              @blur="focused['TermsAndConditions'] = false"
              @focus="focused['TermsAndConditions'] = true"
            >
              <template v-slot:label>
                <div
                  @click.stop.prevent="handleConditionsClick"
                  v-html="termsNConditionsText"
                ></div>
              </template>
            </v-checkbox>
          </div>
          <v-alert
            v-show="signupError"
            type="error"
            dense
            :icon="false"
            class="mb-4 text-left error errorTxt--text"
            style="font-weight: 700;"
          >
            {{ signupError }}
          </v-alert>
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="buttonSecondary buttonSecondaryTxt--text"
                @click="signupwizard = 2"
              >
                {{ $strResourceService("Previous") }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block color="button buttonTxt--text" @click="submit()">
                {{ $strResourceService("Register") }}
              </v-btn></v-col
            >
          </v-row>
        </v-stepper-content>
      </v-form>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { VTextField } from "vuetify/lib";
import { VSelect } from "vuetify/lib";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions, mapGetters } from "vuex";
import { launchAction } from "@/services/casino";
import { clientType, isNullorWhitespace } from "@/services/casino";
import PasswordValidation from "@/components/common/PasswordValidation";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
export default {
  components: { VTextField, VSelect, PasswordValidation },
  mixins: [dialogToggle, displayNameValidator],
  data() {
    return {
      name: "signUp",
      iframeID: "#iframeCustomMessage",
      fields: {
        Login: "",
        Password: "",
        Birthdate: null,
        AddressLine1: "",
        AddressLine2: "",
        cellPhone: null,
        City: "",
        Country: 0,
        countryName: "",
        DaytimePhone: null,
        EMailAddress: "",
        EveningPhone: null,
        FirstName: "",
        gender: null,
        LastName: "",
        SMSMessages: null,
        State: "",
        Zip: null,
        BonusesAndPromotions: null,
        playerCurrencyId: "",
        emailVerified: false,
        TermsAndConditions: null
      },
      SigningUp: false,
      confirmPassword: "",
      signupError: "",
      signupwizard: 1,
      step1Valid: false,
      step2Valid: false,
      step3Valid: false,
      userNameError: "",
      emailError: "",
      customHeaderHeight: "27%",
      customHeaderStyle: "width: 90%; display: block;",
      phoneCountryCode: "",
      CellPhoneCountryRule: [],
      DaytimePhoneCountryRule: [],
      EveningPhoneCountryRule: [],
      loginValues: {
        login: "",
        password: "",
        forMoney: true
      },
      focused: {
        Login: false,
        Password: false,
        confirmPassword: false,
        Birthdate: false,
        AddressLine1: false,
        AddressLine2: false,
        cellPhone: false,
        City: false,
        Country: false,
        countryName: false,
        DaytimePhone: false,
        EMailAddress: false,
        EveningPhone: false,
        FirstName: false,
        gender: false,
        LastName: false,
        SMSMessages: false,
        State: false,
        Zip: false,
        BonusesAndPromotions: false,
        playerCurrencyId: false,
        emailVerified: false,
        TermsAndConditions: false
      },
      showPassword: {
        Password: false,
        confirmPassword: false
      },
      passwordMounted: false,
      hasCustomHeaders: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "accountCreateFields",
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules",
      "accountFieldsLoaded",
      "currencyList",
      "strengthEnable",
      "featurePasswordStrengthEnabled",
      "showEmailUsernameField"
    ]),
    ...mapGetters("lobbyComponents", [
      "termsConditionsURL",
      "privacyPolicyURL"
    ]),
    ...mapGetters("customizations", ["customHeaderUrls"]),
    ...mapGetters("session", ["onSuccessSignIn"]),
    alreadyHaveAccountMessage() {
      return this.$strResourceService("AlreadyHaveAccount").replace(
        "{0}",
        this.$str("LoginPage")
      );
    },
    alreadyHaveAccountMessagePt1() {
      // New design does not have a linebreak, and makes everything after the
      // old linebreak into a link. So split the message up into two parts.
      return this.alreadyHaveAccountMessage.split("<br />")[0] + " ";
    },
    alreadyHaveAccountMessagePt2() {
      // This part of the messages will be a link.
      return this.alreadyHaveAccountMessage.split("<br />")[1] || "";
    },
    confirmPasswordRules() {
      return [
        v => !!v || this.$strResourceService("FieldRequired"),
        v =>
          v === this.fields.Password ||
          this.$strResourceService("PasswordNoMatch")
      ];
    },
    currencyRules() {
      return [v => !!v || this.$strResourceService("FieldRequired")];
    },
    checkboxRules() {
      return field => [
        v =>
          !v && this.accountCreateFields[field].required.value
            ? this.accountCreateFields[field].field === "TermsAndConditions"
              ? this.privacyPolicyURL
                ? this.$strResourceService("SignUpTermsCheckboxError")
                : this.$strResourceService("TermsNConditionsError")
              : this.$str("isRequired").replace(
                  "{0}",
                  this.accountCreateFields[field].display
                )
            : true
      ];
    },
    currentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    hasMultipleCurrencies() {
      return this.currencyList && this.currencyList.length > 1;
    },
    success() {
      return field => !!this.fields[field] && !this.focused[field];
    },
    phoneRuleSuccess() {
      return field =>
        !!this.fields[field] &&
        !this.focused[field] &&
        this.fields[field].trim() !== this.phoneCountryCode.trim();
    },
    customHeaderUrl() {
      return this.$vuetify.theme.dark
        ? this.customHeaderUrls?.dark
        : this.customHeaderUrls?.light;
    },
    showStateDropDown() {
      return (
        this.fields.Country === "AU" ||
        this.fields.Country === "CA" ||
        this.fields.Country === "US"
      );
    },
    passwordStrength() {
      if (this.fields.Password === "" || this.fields.Password === undefined)
        return 0;

      if (this.StrengthPasswordValidation[0].test(this.fields.Password))
        return 3;
      if (this.StrengthPasswordValidation[1].test(this.fields.Password))
        return 2;
      if (this.StrengthPasswordValidation[2].test(this.fields.Password))
        return 1;

      return 0;
    },
    passwordLevelNames() {
      return [
        "",
        this.$str("Password_Weak"),
        this.$str("Password_Medium"),
        this.$str("Password_Strong")
      ];
    },
    passwordLevelName() {
      return this.passwordLevelNames[this.passwordStrength];
    },
    recomendationsPassword() {
      return (
        this.$str("Password_Recomendations") +
        "<ul><li>" +
        this.$str("RecomendationNCharacters").replace(
          "{0}",
          this.minPaswwordLength
        ) +
        "</li><li>" +
        this.$str("RecomendationSmallLetter") +
        "</li><li>" +
        this.$str("RecomendationCapitalLetter") +
        "</li><li>" +
        this.$str("RecomendationNumber_Symbol") +
        "</li></ul>"
      );
    },
    termsNConditionsText() {
      const privacyPolicyVisible = this.privacyPolicyURL
        ? ""
        : "privacy-policy-hide";

      let text = this.$strResourceService("SignUpTermsCheckbox")
        .replace("{0}", this.$strResourceService("TermAndConditions"))
        .replace("{1}", this.$str("PrivacyPolicyLink"))
        .replace(
          "{a1}",
          '<a class="hyperlink--text" id="showTermsNConditions">'
        )
        .replace("{span1}", '</a> <span class="{class}">')
        .replace(
          "{a2}",
          '</span> <a class="hyperlink--text {class}" id="showPrivacyPolicy">'
        )
        .replace("{a3}", "</a>");

      text = text.replace(/{class}/g, privacyPolicyVisible);

      return text;
    },
    minPaswwordLength() {
      return this.accountCreateFields.Password.minLength.value;
    },
    StrengthPasswordValidation() {
      const strongRegex = RegExp(
        // eslint-disable-next-line no-useless-escape
        `^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*/()]))(?=.{${this.minPaswwordLength},})`
      );
      const mediumRegex = RegExp(
        // eslint-disable-next-line no-useless-escape
        `^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[!@#$%^&*/()])(?=.*[a-z]))|((?=.*[!@#$%^&*/()])(?=.*[A-Z])))(?=.{${this.minPaswwordLength},})`
      );
      const weakRegex = RegExp(`^(?=.{${this.minPaswwordLength},})`);
      return [strongRegex, mediumRegex, weakRegex];
    },
    CellPhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.cellPhone,
        ...this.CellPhoneCountryRule
      ];
    },
    DaytimePhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.DaytimePhone,
        ...this.DaytimePhoneCountryRule
      ];
    },
    EveningPhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.EveningPhone,
        ...this.EveningPhoneCountryRule
      ];
    },
    isPasswordValid() {
      return this.strengthEnable && this.featurePasswordStrengthEnabled
        ? this.passwordMounted && this.$refs.passwordValidation?.isPasswordValid
        : true;
    },
    contentClass() {
      return this.largeLayout && this.hasCustomHeaders
        ? "customMessageLargeLayout"
        : "";
    },
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/signUp"].data.title, this.$str("SignUpPage"));
    }
  },
  watch: {
    accountFieldsLoaded(loaded) {
      if (loaded) {
        this.loadPlayerCurrencyId();
      }
    },
    signupwizard(loaded) {
      if (this.customHeaderUrl) {
        if (document.querySelector(`${this.iframeID}`))
          document.querySelector(`${this.iframeID}`).style.display = "none";
        if (loaded) this.updateCustomMessageURL(this.signupwizard);
      }
    }
  },
  updated() {
    // Ensure our computed properties for height and width change if the screen
    //  is resized (ie phone rotated, etc)
    window.addEventListener("resize", () => {
      this.resizeCustomHeader();
    });
  },
  methods: {
    ...mapActions("dialogs", [
      "showExternalUrl",
      "showPrivacyPolicy",
      "showTermsConditions"
    ]),
    close() {
      window.LobbyCore.raise(window.LobbyCore.Events.closeSignUpPage);
    },
    fieldComponent(field) {
      if (field.field === "Birthdate") {
        return "v-date-picker";
      } else if (field.dropDown || field.field === "State") {
        return "v-select";
      } else {
        return "v-text-field";
      }
    },
    loadPlayerCurrencyId() {
      if (!this.hasMultipleCurrencies) {
        this.playerCurrencyId = this.currencyList[0]?.Key;
      }
    },
    getItems(field) {
      return field.getDropdownValues(this.fields.Country);
    },
    isShown(field) {
      return !field.hidden;
    },
    isDefault(accountField) {
      const { field, default: defaultValue } = accountField;

      this.fields[field] = defaultValue ? defaultValue : !!this.fields[field];
      return defaultValue;
    },
    getFieldClass(field) {
      if (field?.required?.value) {
        return "required";
      }
      return "";
    },
    iconToAppend(field) {
      if (this.success(field.field)) {
        return "$Check";
      } else if (field.required.value) {
        // TODO: display an asterisk in the field label if the field is required
        //return "*";
      }
      return "";
    },
    appendIconOnPassword(field) {
      if (this.success(field.field) && this.isPasswordValid) {
        return "mdi-check-circle-outline";
      } else if (field.required.value) {
        if (this.focused[field.field] === true) {
          return this.showPassword[field.field] ? "mdi-eye" : "mdi-eye-off";
        }
      }

      return "";
    },
    showEyeIconOnPassword(field, iconDefault) {
      return this.focused[field]
        ? this.showPassword[field]
          ? "mdi-eye"
          : "mdi-eye-off"
        : iconDefault;
    },
    phoneIconAppend(field) {
      if (
        (field.required.value && !this.fields[field.field]) ||
        (field.required.value &&
          this.fields[field.field]?.trim() === this.phoneCountryCode.trim())
      ) {
        return "*";
      } else if (this.success(field.field)) {
        return "mdi-check";
      }
      return "";
    },
    login() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
      this.close();
    },
    async setStateFieldAndPhoneCodes() {
      this.fields.State = "";
      try {
        this.phoneCountryCode = await CDK.getPhoneCountryCode(
          this.fields.Country
        );
      } catch {
        this.phoneCountryCode = "";
      }

      if (this.phoneCountryCode !== "") {
        this.phoneCountryCode = "+" + this.phoneCountryCode + " ";
        this.fields.DaytimePhone = this.phoneCountryCode;
        this.fields.EveningPhone = this.phoneCountryCode;
        this.fields.cellPhone = this.phoneCountryCode;
        if (this.accountCreateFields.cellPhone.required.value) {
          this.CellPhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.cellPhone.required.errorString
          ];
        }
        if (this.accountCreateFields.DaytimePhone.required.value) {
          this.DaytimePhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.DaytimePhone.required.errorString
          ];
        }
        if (this.accountCreateFields.EveningPhone.required.value) {
          this.EveningPhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.EveningPhone.required.errorString
          ];
        }
      }
    },
    validatePhoneFields() {
      let arePhonesValid = true;
      if (this.phoneCountryCode === "") return arePhonesValid;

      const phoneFields = [
        [
          this.$str("daytimePhone"),
          this.fields.DaytimePhone,
          this.accountCreateFields.DaytimePhone.required.value
        ],
        [
          this.$str("eveningPhone"),
          this.fields.EveningPhone,
          this.accountCreateFields.EveningPhone.required.value
        ],
        [
          this.$str("cellPhone"),
          this.fields.cellPhone,
          this.accountCreateFields.cellPhone.required.value
        ]
      ];
      phoneFields.forEach(([phoneName, phoneField, isRequired]) => {
        if (phoneField.trim() === this.phoneCountryCode.trim()) {
          if (isRequired) {
            this.signupError = this.$str("isRequired").replace(
              "{0}",
              phoneName
            );
            arePhonesValid = false;
            return;
          } else {
            this.clearPhoneNumber(phoneName);
          }
        }
      });
      return arePhonesValid;
    },
    clearPhoneNumber(phoneField) {
      switch (phoneField) {
        case this.$str("daytimePhone"):
          this.fields.DaytimePhone = "";
          break;
        case this.$str("eveningPhone"):
          this.fields.EveningPhone = "";
          break;
        case this.$str("cellPhone"):
          this.fields.cellPhone = "";
          break;
      }
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$refs.form?.reset();
      if (!this.hasMultipleCurrencies) {
        this.fields.playerCurrencyId = this.currencyList[0]?.Key;
      }
      this.loadPlayerCurrencyId();
    },
    getCustomHeader(step) {
      let customHeaderSrc = "";
      if (this.$vuetify.theme.dark) {
        if (
          this.customHeaderUrls?.dark?.hasOwnProperty("instantPlay") &&
          this.customHeaderUrls?.dark?.hasOwnProperty("mobile")
        ) {
          switch (step) {
            case 1:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.dark.mobile.StepOne
                  : this.customHeaderUrls.dark.instantPlay.StepOne;
              break;
            case 2:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.dark.mobile.StepTwo
                  : this.customHeaderUrls.dark.instantPlay.StepTwo;
              break;
            case 3:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.dark.mobile.StepThree
                  : this.customHeaderUrls.dark.instantPlay.StepThree;
              break;
          }
        } else {
          customHeaderSrc = this.customHeaderUrls?.dark;
        }
      } else {
        if (
          this.customHeaderUrls?.light?.hasOwnProperty("instantPlay") &&
          this.customHeaderUrls?.light?.hasOwnProperty("mobile")
        ) {
          switch (step) {
            case 1:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.light.mobile.StepOne
                  : this.customHeaderUrls.light.instantPlay.StepOne;
              break;
            case 2:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.light.mobile.StepTwo
                  : this.customHeaderUrls.light.instantPlay.StepTwo;
              break;
            case 3:
              customHeaderSrc =
                clientType === CDK.ClientTypes.Mobile
                  ? this.customHeaderUrls.light.mobile.StepThree
                  : this.customHeaderUrls.light.instantPlay.StepThree;
              break;
          }
        } else {
          customHeaderSrc = this.customHeaderUrls?.light;
        }
      }
      if (!customHeaderSrc || !customHeaderSrc.trim()) {
        this.customHeaderStyle = "width: 90%; display: none;";
        this.hasCustomHeaders = false;
      } else {
        this.customHeaderStyle = "width: 90%; display: block;";
        this.hasCustomHeaders = true;
      }
      return customHeaderSrc;
    },
    showCustomHeader() {
      if (!this.customHeaderUrls) {
        return false;
      } else {
        return this.$vuetify.theme.dark
          ? this.customHeaderUrls.dark
          : this.customHeaderUrls.light;
      }
    },
    onHidden() {
      this.resetForm();
    },
    onShown() {
      this.resetForm();
    },
    resizeCustomHeader() {
      const w = 676;
      const h = 115;

      const width = Number(window.innerWidth);
      if (clientType === CDK.ClientTypes.Mobile) {
        this.customHeaderHeight = width / 4.5;
      } else {
        this.customHeaderHeight = h * (width / w);
      }
    },
    async submit() {
      this.SigningUp = true;

      if (this.validateStep3()) {
        if (this.validatePhoneFields()) {
          try {
            await this.$store.dispatch("session/signUp", this.fields);

            let loginEmail = this.fields.Login;
            if (
              isNullorWhitespace(this.fields.Login) &&
              !isNullorWhitespace(this.fields.EMailAddress)
            ) {
              loginEmail = this.fields.EMailAddress;
            }

            // Handle SignIn Events --
            const SignUpInfoReturn = await CDK.getSignUpInfo();
            if (SignUpInfoReturn.IsAutoLoginEnabled) {
              this.loginValues.login = loginEmail;
              this.loginValues.password = this.fields.Password;
              await this.$store.dispatch("session/signIn", this.loginValues);
              if (this.onSuccessSignIn) {
                launchAction(
                  this.$store,
                  this.onSuccessSignIn.action,
                  this.onSuccessSignIn.params
                );
              }
            } else {
              if (
                SignUpInfoReturn.IsEmailVerificationEnabled &&
                SignUpInfoReturn.ForceEmailVerification
              ) {
                window.LobbyCore.raise(
                  window.LobbyCore.Events.showEmailVerificationPage,
                  {
                    login: loginEmail,
                    password: this.fields.Password
                  }
                );
              } else {
                window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
                  login: loginEmail,
                  password: this.fields.Password,
                  onSuccess: this.onSuccessSignIn,
                  confirmMessage: this.$str("RegistrationSuccessMessage")
                });
              }
            }
            this.close();
            // ---
            this.resetForm();
          } catch (error) {
            this.signupError = error.Message;
          }
        } else {
          this.signupError = this.$str("PhoneFieldsError");
        }
      }
      this.SigningUp = false;
    },
    async checkForExistingUsername() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          this.fields.Login,
          "",
          "",
          "",
          "",
          true
        );
        this.userNameError = "";
      } catch (error) {
        this.userNameError = error.Message;
        return;
      }
    },
    async checkForExistingEmail() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          "",
          this.fields.EMailAddress,
          "",
          "",
          "",
          true
        );
        this.emailError = "";
      } catch (error) {
        this.emailError = error.Message;
        return;
      }
    },
    async step1Next(e) {
      if (this.validateStep1()) {
        await this.checkForExistingPlayer();
      }

      e.preventDefault();
    },
    validateStep1() {
      if (
        (this.$refs.firstName && !this.$refs.firstName?.valid) ||
        (this.$refs.lastName && !this.$refs.lastName?.valid) ||
        (this.$refs.emailAddress && !this.$refs.emailAddress?.valid) ||
        (this.$refs.login && !this.$refs.login?.valid) ||
        (this.hasMultipleCurrencies &&
          this.$refs.currency &&
          !this.$refs.currency.valid) ||
        (this.$refs.password && !this.$refs.password?.valid) ||
        !this.isPasswordValid ||
        (this.$refs.confirmPassword && !this.$refs.confirmPassword.valid)
      ) {
        this.step1Valid = false;
        this.$refs.form?.validate();
        return false;
      }
      this.step1Valid = true;
      this.$refs.form?.resetValidation();
      return true;
    },
    async checkForExistingPlayer() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          this.fields.Login,
          this.fields.EMailAddress,
          this.fields.FirstName,
          this.fields.LastName,
          this.fields.playerCurrencyId
        );
      } catch (error) {
        this.signupError = error.Message;
        return;
      }
      this.signupwizard = 2;
      this.signupError = "";
    },
    step2Next(e) {
      e.preventDefault();

      if (this.validateStep2()) {
        this.signupwizard = 3;
      }
    },
    validateStep2() {
      if (
        (this.$refs.addressLine1 && !this.$refs.addressLine1.valid) ||
        (this.$refs.addressLine2 && !this.$refs.addressLine2.valid) ||
        (this.$refs.city && !this.$refs.city.valid) ||
        (this.$refs.country && !this.$refs.country.valid) ||
        (this.$refs.state && !this.$refs.state.valid) ||
        (this.$refs.zipCode && !this.$refs.zipCode.valid)
      ) {
        this.step2Valid = false;
        this.$refs.form?.validate();
        return false;
      }
      this.step2Valid = true;
      this.$refs.form?.resetValidation();
      return true;
    },
    validateStep3() {
      if (
        (this.$refs.birthdate && !this.$refs.birthdate.valid) ||
        (this.$refs.gender && !this.$refs.gender.valid) ||
        (this.$refs.cellPhone && !this.$refs.cellPhone.valid) ||
        (this.$refs.dayTimePhone && !this.$refs.dayTimePhone.valid) ||
        (this.$refs.eveningPhone && !this.$refs.eveningPhone.valid) ||
        (this.$refs.SMSMessages && !this.$refs.SMSMessages.valid) ||
        (this.$refs.TermsAndConditions &&
          !this.$refs.TermsAndConditions.valid) ||
        (this.$refs.BonusesAndPromotions &&
          !this.$refs.BonusesAndPromotions.valid)
      ) {
        this.step3Valid = false;
        this.$refs.form?.validate();
        return false;
      }

      this.step3Valid = true;
      this.$refs.form?.resetValidation();
      return true;
    },
    updateCustomMessageURL() {
      const param = "page";
      const iframe = document.querySelector(`${this.iframeID} embed`);
      if (iframe) {
        const url = new URL(iframe.src);
        if (
          url.searchParams.get(param)?.toString() !==
          this.signupwizard.toString()
        ) {
          url.searchParams.set(param, this.signupwizard);
          const stepHeader = this.getCustomHeader(this.signupwizard);
          if (stepHeader.trim()) {
            iframe.src = this.getCustomHeader(this.signupwizard);
            this.customHeaderStyle = "width: 90%; display: block;";
          } else {
            this.customHeaderStyle = "width: 90%; display: none;";
          }
        }
        document.querySelector(`${this.iframeID}`).style.display = "";
      }
    },
    handleConditionsClick(e) {
      if (e.target.matches("#showPrivacyPolicy")) {
        this.showPrivacyPolicy();
      }
      if (e.target.matches("#showTermsNConditions")) {
        this.showTermsConditions();
      }
    },
    passwordMount() {
      this.passwordMounted = true;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .privacy-policy-hide {
  display: none !important;
}

::v-deep #iframeCustomMessage {
  order: 3;
  flex: 1 50%;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20%;
  position: relative;
  height: 0;
  width: 100%;
}

::v-deep #iframeCustomMessage embed {
  width: 90%;
  height: 100%;
  position: absolute;
  left: 5%;
}
::v-deep .customMessageLargeLayout {
  width: 370px !important;
  div#signup-form {
    display: block;
    flex-direction: row;
    align-items: stretch;
    flex-flow: row wrap;
  }
  .v-stepper__header {
    flex: 1 100%;
    order: 1;
  }

  .v-stepper__content {
    order: 2;
    flex: 1 50%;
  }
}

::v-deep .v-stepper__step__step {
  width: auto;
  height: auto;
  padding: 12px;
  padding-top: 11px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

::v-deep .v-stepper__step:not(.active) {
  .v-stepper__step__step {
    background-color: transparent !important;
  }
}

::v-deep .v-stepper__step {
  padding: 0px;
  padding-left: 6px;
}

::v-deep .v-stepper__step .success i {
  font-style: normal !important;
  font-size: 14px !important;
}

::v-deep .v-stepper__content {
  padding-left: 16px;
  padding-right: 16px;
}

::v-deep .v-stepper__wrapper {
  padding-top: 8px;
}

::v-deep .v-input--checkbox .v-input__slot .v-label {
  // fixes checkbox label alignment
  position: static !important;
}

::v-deep .v-input--checkbox {
  padding-bottom: 0;
}

@media only screen and (min-width: 600px) {
  // ::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details,
  // ::v-deep.v-text-field.v-text-field--enclosed
  //   .v-text-field__details
  //   .v-messages {
  //   display: contents !important;
  // }

  // ::v-deep .v-messages__wrapper {
  //   text-align: center;
  //   margin-bottom: 5px;
  // }

  // ::v-deep .v-messages__wrapper .v-messages__message::before {
  //   content: "\F0026";
  //   padding-right: 5px;
  // }
  // ::v-deep .v-messages__wrapper .v-messages__message:before {
  //   display: inline-block;
  //   font: normal normal normal 24px/1 "Material Design Icons";
  //   font-size: inherit;
  //   text-rendering: auto;
  //   line-height: inherit;
  //   -webkit-font-smoothing: antialiased;
  // }

  #password-strength {
    text-align: center;
    top: -7px;
    height: 13px;
  }
}
@media screen and (max-width: 600px) {
  #password-strength {
    top: -15px;
    height: 0;
  }
}
::v-deep .v-input__append-inner .mdi-check.v-icon:not(.success--text) {
  display: none;
}

::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 2px !important;
}

::v-deep .v-input--selection-controls__input .v-icon {
  color: var(--v-popupTxt-base);
}

.level-0 {
  display: none;
}
#password-strength {
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
  white-space: nowrap;
  margin: 0;
  position: relative;
}

.theme--dark .password-strength {
  color: rgba(255, 255, 255, 0.7);
}

.theme--light .password-strength {
  color: rgba(0, 0, 0, 0.54);
}
#password-strength.level-1,
#password-strength.level-1 .v-icon.v-icon {
  color: #fe7878 !important;
  text-shadow: 1px 1px 2px rgb(254 120 120 / 0.5);
}

#password-strength.level-2,
#password-strength.level-2 .v-icon.v-icon {
  color: #d7a204 !important;
  text-shadow: 1px 1px 2px rgb(215 162 4 / 0.5);
}

#password-strength.level-3 {
  color: #70bf74 !important;
  text-shadow: 1px 1px 2px rgb(112 191 116 / 0.5);
}
::v-deep .error--text.v-messages {
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .v-messages__wrapper {
    margin-top: 0 !important;
  }
}
</style>
