export const EventNotificationTypes = {
  None: 0,
  PopUp: 1,
  SlideIn: 2
};

export const EventNotificationContentTypes = {
  None: 0,
  HTML: 1,
  URL: 2
};
