<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    content-class="pendingGamesDialog"
  >
    <!-- v-if="dialog" so we don't create the body of this until it is going to be shown -->
    <v-card
      v-if="showDialog"
      class="elevation-12"
    >
      <DialogTitleBar
        color="transparent"
        :title="$str('PendingGamesTitle')"
        @close="close"
      />
      <v-card-text>
        <v-container>
          <v-row
            v-if="!isSingleMode"
            class="mb-1"
          >
            <v-spacer></v-spacer>
            <span class="game-detail-title v-dialog-text">{{
              $str("PendingGamesMessage")
            }}</span>
            <v-spacer></v-spacer>
            <br />
          </v-row>

          <v-row class="mb-2">
            <v-item-group
              v-if="!isSingleMode"
              class="item-group"
            >
              <v-container>
                <v-row>
                  <v-col
                    v-for="(pendingGame, index) in pendingGames"
                    :key="index"
                    cols="12"
                    md="4"
                    class="col-pending-game"
                    @click="launchGame(pendingGame)"
                  >
                    <v-item>
                      <v-card class="d-flex align-center"><v-img
                          :src="gameIcon(pendingGame)"
                          :lazy-src="lazyLoadingImage"
                          :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
                          width="24vw"
                          @click="launchGame(pendingGame)"
                        >
                        </v-img>
                      </v-card>
                    </v-item>
                    <p>{{ pendingGame.name }}</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>
            <v-card-text v-if="isSingleMode">
              <v-row>
                <v-col align="center">
                  {{
                    $str("NonHtml5PendingGameActiveMessage").replace(
                      "[pendingMachDescription]",
                      gameName
                    )
                  }}
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <v-btn
                    color="button buttonTxt--text"
                    width="49%"
                    @click="close"
                  ><span class="text-uppercase text-center">{{
                    $str("OKButton")
                  }}</span></v-btn></v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- eslint-disable vue/no-v-html -->
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import DialogTitleBar from "@/components/Dialogs/DialogTitleBar";
import { mapGetters } from "vuex";
export default {
  components: { DialogTitleBar },
  mixins: [dialogToggle],
  data() {
    return {
      name: "pendingGames"
    };
  },
  computed: {
    ...mapGetters("games", ["games", "gameIcon"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    pendingGames() {
      const pendingGames = this.$store.getters["dialogs/pendingGames"].data
        .pendingGames;
      // Only show the first 3 elements at most
      const pendingGamesFiltered = pendingGames.slice(0, 3);

      return pendingGamesFiltered;
    },
    isSingleMode() {
      return this.$store.getters["dialogs/pendingGames"].data.showForSingleMode;
    },
    game() {
      return this.pendingGames[0];
    },
    gameName() {
      return this.pendingGames[0].name;
    },
    gameDescription() {
      return this.pendingGames[0].description;
    },
    isSquareIcon() {
      return this.lobbyIconType === "square";
    }
  },
  methods: {
    launchGame(game) {
      this.$store.dispatch("games/launchGame", { gameId: game.uniqueId });
      this.showDialog = false;
    },
    close() {
      this.showDialog = false;
    },
    onHidden() {
      if (this.isSingleMode) {
        this.launchGame(this.game);
      }
      Object.assign(this.$data, this.$options.data.apply(this));
    }
  }
};
</script>

<style scoped>
.pendingGamesDialog {
  margin: 0;
}

.item-group {
  width: 100%;
}

.col-pending-game {
  float: none;
  margin: 0 auto;
  text-align: center;
}
</style>
