/**
 * Information about messages sent directly from the admin playersheet
 */

import { parseAdminPlayerMessage } from "@/services/casino";

const state = {
  messages: []
};

const getters = {
  currentMessage: state => state.messages[0]
};

const actions = {
  initialize(context) {
    CDK.on(CDK.Events.adminPlayerMessagesReceived, messages => {
      context.commit("updateMessages", messages);
    });
  },
  messageClosed(context, { messageId, playerResponse, masterId }) {
    CDK.acknowledgeAdminPlayerMessage(messageId, playerResponse, masterId);
    context.commit("removeMessage", messageId);
  }
};

const mutations = {
  updateMessages: (state, messages) => {
    const currentMessagesLength = messages.length;
    const newMessages = messages.map(parseAdminPlayerMessage);
    state.messages = state.messages.concat(newMessages);
    for (let i = 0; i < currentMessagesLength; i++) {
      CDK.getNextAdminPlayerMessage();
    }
  },
  removeMessage: (state, messageId) => {
    const index = state.messages.findIndex(m => m.messageId === messageId);
    if (index > -1) {
      state.messages.splice(index, 1);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
