<template>
  <v-dialog
    v-model="showDialog"
    overlay-opacity="0.9"
    width="500"
    :fullscreen="smallLayout"
  >
    <v-card class="elevation-12" style="overflow: hidden">
      <DialogTitleBar :title="title" @close="close" />
      <CouponSequenceGraph
        v-if="showCouponSequenceGraph"
        :graph-info="couponSequenceGraphInfo"
      />
      <v-card-actions v-if="!emailVerified">
        <v-alert type="warning" dense style="text-align: center"
          >{{ $str("emailNotVerified")
          }}<v-btn color="button buttonTxt--text" @click="showVerifyEmail">{{
            $str("verifyEmail")
          }}</v-btn></v-alert
        >
      </v-card-actions>
      <v-card-text v-if="activeCoupon" class="couponheader">
        <h1>{{ $str("couponCode") }}</h1>
        <p>{{ activeCoupon.CouponCode }}</p>
      </v-card-text>
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        style="text-align: center"
        v-html="this.$store.state.coupons.couponMessage"
      ></v-card-text>
      <v-card-text
        v-if="activeCoupon && !!activeCoupon.TermsAndConditionsUri"
        style="text-align: center"
      >
        <a class="hyperlink--text" @click.stop.prevent="showTermsandConditions">
          {{ $str("TermsNConditionsLink") }}
        </a>
      </v-card-text>
      <div v-if="!activeCoupon">
        <v-container>
          <v-row dense>
            <v-text-field
              v-model="couponCode"
              :placeholder="defaultCoupon"
              name="CouponCode"
              class="px-2"
              style="padding: 0; width: 50%"
              @input="updateRedeemCouponIsDisable"
            >
              <template v-slot:label>
                <span style="padding-left: 12px">{{
                  $str("EnterCouponCode")
                }}</span>
              </template>
            </v-text-field>
            <v-btn
              color="button buttonTxt--text"
              type="submit"
              width="30%"
              :disabled="redeemCouponIsDisable"
              @click="redeem"
              >{{ $str("Redeem") }}</v-btn
            >
          </v-row>
        </v-container>

        <div
          v-if="
            availableCouponsTournaments &&
              availableCouponsTournaments.length > 0
          "
          style="margin-left: 17px"
        >
          {{ $strResourceService("TournamentCoupons") }}:
        </div>
        <v-spacer></v-spacer>
        <v-container>
          <v-row dense style="width: inherit">
            <CouponTile
              v-for="availableCoupon in availableCouponsTournaments"
              :key="availableCoupon.CouponCode"
              :coupon="availableCoupon"
            />
          </v-row>
        </v-container>

        <div
          v-if="filteredAvailableCoupons && filteredAvailableCoupons.length > 0"
          style="margin-left: 17px"
        >
          {{ $str("Coupons") }}:
        </div>
        <v-spacer></v-spacer>
        <v-container>
          <v-row dense style="width: inherit">
            <CouponTile
              v-for="availableCoupon in filteredAvailableCoupons"
              :key="availableCoupon.CouponCode"
              :coupon="availableCoupon"
            />
          </v-row>
        </v-container>
      </div>
      <div v-if="activeCouponValidForDeposit" style="margin-bottom: 2vh">
        <span style="padding-left: 2%; padding-right: 1%">
          <v-btn
            color="buttonSecondary buttonSecondaryTxt--text"
            type="submit"
            style="width: 46%"
            @click="cancelCoupon"
          >
            {{ $str("Cancel") }}
          </v-btn>
        </span>
        <span style="padding-left: 2%; padding-right: %">
          <v-btn
            color="button buttonTxt--text"
            type="submit"
            style="width: 46%"
            @click="showDepositDialog"
          >
            {{ $str("CouponsDeposit") }}
          </v-btn>
        </span>
      </div>
      <v-data-table
        v-if="isDepositCoupon"
        dense
        disable-filtering
        disable-pagination
        disable-sort
        :headers="getDepositHeaders()"
        hide-default-footer
        :items="depositMethodItems"
        :mobile-breakpoint="null"
        class="elevation-1"
      >
      </v-data-table>
      <v-card-text v-if="isPercentageCoupon" style="margin-top: 2vh">
        {{ maxCouponAmount }}
      </v-card-text>
      <v-card-text
        v-if="
          activeCoupon &&
          !activeCouponValidForDeposit &&
          isMaxBetAllowedUnlimited === false
        "
        style="margin-top: 2vh"
      >
        {{ getMaxBetAllowedMessage }}
      </v-card-text>
      <!-- Bonus type section -->
      <v-card-text v-if="isPercentageCoupon">
        {{ $str("BonusType") + ": " + $str("FixedPercentage") }}
        <v-tooltip bottom color="secondary">
          <template v-slot:activator="{ on }">
            <v-icon color="lighten-1 mx-0" right :size="18" v-on="on"
              >mdi-help-circle
            </v-icon>
          </template>
          <span>{{ $str("FixedPercentageExample") }}</span>
        </v-tooltip>
      </v-card-text>
      <!-- Free Spin Info section-->
      <v-card-text
        v-if="
          activeCoupon &&
          activeCoupon.IsFreeSpin &&
          !activeCoupon.ShowMultipleGames
        "
      >
        {{ freeSpinMsg }}
      </v-card-text>
      <v-container
        v-if="activeCoupon && activeCoupon.IsMultipleGamesFreeSpinSettings"
      >
        <v-row class="mx-auto">
          <v-card-text style="padding: 0px 0px 20px 0px">
            {{ $strResourceService("CouponFSMultiGamesChoiceOneGame") }}
          </v-card-text>
        </v-row>
        <v-row
          v-if="activeCouponValidForDeposit"
          class="mx-auto"
          style="gap: 10px"
        >
          <div
            v-for="(currentGame, index) in activeCoupon.MultiGamesSettingList"
            :key="index"
          >
            <v-col cols="6" style="padding: 0px; margin: 3px">
              <v-container class="gameContainer">
                <div
                  :style="getImageURL(currentGame)"
                  class="gameImageContainer"
                >
                  <p
                    v-if="showGameDescription(currentGame)"
                    class="freeSpinTextSmall"
                  >
                    {{ currentGame.gameDescription }}
                  </p>
                </div>
                <div class="gameInfoContainer">
                  <div
                    v-if="currentGame.isLinesTotalBetGame"
                    class="gameInfoStyle"
                  >
                    <span style="font-weight: bold"
                      >{{
                        $strResourceService("CouponFSMultiGamesLines")
                      }}:</span
                    >
                    {{ currentGame.payLines }} <br />
                    <span style="font-weight: bold"
                      >{{
                        $strResourceService("CouponFSMultiGamesTotalBet")
                      }}:</span
                    >
                    {{ $cur(currentGame.totalBet) }}
                  </div>
                  <div
                    v-if="currentGame.isLinesTotalBetGame == false"
                    class="gameInfoStyle"
                  >
                    <span style="font-weight: bold"
                      >{{
                        $strResourceService("CouponFSMultiGamesLines")
                      }}:</span
                    >
                    {{ currentGame.numOfLinesForDisplay }} <br />
                    <span style="font-weight: bold"
                      >{{
                        $strResourceService("CouponFSMultiGamesBetPerLine")
                      }}:</span
                    >
                    {{ betPerLineAmount(currentGame.denominationForDisplay) }}
                  </div>
                </div>
              </v-container>
            </v-col>
          </div>
        </v-row>
        <v-row v-else class="mx-auto">
          <div class="gamesContainerRedeemed">
            <div
              v-for="(currentGame, index) in activeCoupon.MultiGamesSettingList"
              :key="index"
              class="gameContainerRedeemed"
            >
              <div class="card">
                <div class="content">
                  <div class="details">
                    <div :style="getImageURL(currentGame)" class="bellyart">
                      <p
                        v-if="showGameDescription(currentGame)"
                        class="freeSpinTextSmall"
                      >
                        {{ currentGame.gameDescription }}
                      </p>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1 0 0;
                      "
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                          gap: 5px;
                        "
                      >
                        <div>
                          <span
                            style="
                              color: var(--v-buttonSecondaryTxt-base);
                              font-size: 26px;
                              display: flex;
                              padding: 10px;
                              align-items: flex-start;
                              border-radius: 50px;
                              background: var(--v-buttonSecondary-base);
                              font-style: normal;
                              font-weight: 700;
                              line-height: normal;
                              min-width: 50px;
                              justify-content: center;
                            "
                            >{{ currentGame.spinsNeeded }}</span
                          >
                        </div>
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 5px;
                          "
                        >
                          <span
                            style="
                              color: var(--v-buttonSecondary-base);
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 700;
                              line-height: normal;
                              text-transform: capitalize;
                            "
                          >
                            {{ $str("FreeSpins") }}</span
                          >
                        </div>
                        <p
                          v-if="currentGame.isLinesTotalBetGame"
                          style="
                            color: var(--v-bodyTitle-base);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 0px;
                          "
                        >
                          <span style="font-weight: bold"
                            >{{
                              $strResourceService("CouponFSMultiGamesLines")
                            }}:</span
                          >
                          {{ currentGame.payLines }}<br />
                          <span style="font-weight: bold"
                            >{{
                              $strResourceService("CouponFSMultiGamesTotalBet")
                            }}:</span
                          >
                          {{ $cur(currentGame.totalBet) }}
                        </p>
                        <p
                          v-if="currentGame.isLinesTotalBetGame == false"
                          style="
                            color: var(--v-bodyTitle-base);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 0px;
                          "
                        >
                          <span style="font-weight: bold"
                            >{{
                              $strResourceService("CouponFSMultiGamesLines")
                            }}:</span
                          >
                          {{ currentGame.numOfLinesForDisplay }}<br />
                          <span style="font-weight: bold"
                            >{{
                              $strResourceService(
                                "CouponFSMultiGamesBetPerLine"
                              )
                            }}:</span
                          >
                          {{ $storageCur(currentGame.denominationForDisplay) }}
                        </p>
                      </div>
                      <v-btn
                        style="
                          display: flex;
                          padding: 0px 16px;
                          justify-content: center;
                          align-items: center;
                          border-radius: 4px;
                          background: var(--v-button-base);
                          color: var(--v-buttonTxt-base);
                          text-align: center;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 700;
                          line-height: 40px;
                        "
                        @click.stop="launchGame(currentGame)"
                      >
                        {{ $strResourceService("CouponFSMultiGamesSelect") }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- Playthrough details section -->
      <v-card-text
        v-if="activeCoupon"
        style="padding: 0; text-align: center; font-weight: bold"
      >
        <v-card class="pa-2" outlined color="primary">{{
          $str("PlaythroughDetails")
        }}</v-card>
      </v-card-text>
      <v-card-text v-if="activeCoupon" style="padding: 0">
        <v-row no-gutters>
          <v-col>
            <v-card class="pa-2" outlined
              >{{ $str("PlaythroughPlayed") }}:
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pa-2" outlined style="text-align: end"
              >{{ getAmountPlayed() }}
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-card class="pa-2" height="100%" outlined
              >{{ $str("PlaythroughNeeded") }}:</v-card
            >
          </v-col>
          <v-col>
            <v-card class="pa-2" outlined tile style="text-align: end"
              >{{ getAmountNeeded() }}
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="activeCoupon.RealMoneyWithdrawalsLimit >= 0" no-gutters>
          <v-col>
            <v-card class="pa-2" outlined>
              <v-row
                no-gutters
                class="d-flex flex-row flex-nowrap align-center justify-start mx-0"
              >
                <v-col cols="11">
                  <v-container class="ma-0 pa-0">
                    {{ $strResourceService("MaximumAmountToConvert") }}
                  </v-container>
                </v-col>
                <v-col cols="1">
                  <v-container class="ma-0 pa-0 d-flex justify-end">
                    <v-tooltip bottom color="secondary">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="lighten-1 mx-0"
                          right
                          :size="18"
                          v-on="on"
                          >mdi-help-circle
                        </v-icon>
                      </template>
                      <span>{{
                        $strResourceService("MaximumAmountToConvertTooltip")
                      }}</span>
                    </v-tooltip>
                  </v-container>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              class="pa-2"
              outlined
              tile
              style="text-align: end; height: 100%"
              >{{ activeCoupon.RealMoneyWithdrawalsLimitMessage }}
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="activeCoupon.MinimumBonusBalanceMessage != null"
          no-gutters
        >
          <v-col>
            <v-card class="pa-2" outlined
              >{{ $str("MinimumBonusBalance") }}:</v-card
            >
          </v-col>
          <v-col>
            <v-card
              class="pa-2"
              outlined
              tile
              style="text-align: end; height: 100%"
              >{{ activeCoupon.MinimumBonusBalanceMessage }}
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider
        v-if="activeCoupon && !showGameList"
        style="margin-top: 1vh"
      ></v-divider>
      <v-btn
        v-if="activeCoupon && !showGameList"
        text
        small
        color="buttonSecondary buttonSecondaryTxt--text"
        width="100%"
        @click="showGameList = true"
      >
        {{ $str("MoreDetails") }}
      </v-btn>
      <v-divider v-if="activeCoupon && !showGameList"></v-divider>
      <!-- game lists -->
      <div v-if="activeCoupon && showGameList">
        <!-- Included Games -->
        <v-card-text style="font-weight: bold; padding: 0">
          <v-card
            class="pa-2"
            outlined
            tile
            color="primary"
            style="overflow-x: auto"
          >
            {{ $str("GamesIncluded") }}
            <span style="float: right; width: 50%; text-align: end">
              {{ $str("PlaythroughAmounts").replace("{0}", $cur(1)) }}
            </span>
          </v-card>
        </v-card-text>
        <v-list
          v-for="game in includedTopLevelGames"
          :key="game.Name"
          style="padding: 0"
        >
          <v-list-item v-if="!game.IsParent" style="padding-left: 7vw">
            <v-list-item-content>
              <v-list-item-title>
                {{ game.Name }}
                <span style="float: right">
                  {{ $baseCur(game.Playthrough) }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-if="game.IsParent"
            class="gameFamily"
            no-action
            append-icon="mdi-plus"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ game.Name }}
                  <span style="float: right">
                    {{ $baseCur(game.Playthrough) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="childGame in includedChildGamesByParent(game)"
              :key="childGame.Name"
              style="padding-left: 14vw"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ childGame.Name }}
                  <span style="float: right">
                    {{ $baseCur(childGame.Playthrough) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <!-- excluded games -->
        <v-card-text style="font-weight: bold; padding: 0">
          <v-card
            class="pa-2"
            outlined
            tile
            color="primary"
            style="overflow-x: auto"
          >
            {{ $str("ExcludedGames") }}
          </v-card>
        </v-card-text>
        <v-list
          v-for="game in activeCoupon.GameExclusionList"
          :key="game.GameDescription"
          style="padding: 0"
        >
          <v-list-item style="padding-left: 7vw">
            <v-list-item-content>
              <v-list-item-title>
                {{ game.GameDescription }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-divider
        v-if="activeCoupon && showGameList"
        style="margin-top: 1vh"
      ></v-divider>
      <v-btn
        v-if="activeCoupon && showGameList"
        text
        small
        color="buttonSecondary buttonSecondaryTxt--text"
        width="100%"
        @click="showGameList = false"
      >
        {{ $str("LessDetails") }}
      </v-btn>
      <v-divider v-if="activeCoupon && showGameList"></v-divider>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>
<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import CouponTile from "../CouponTile";
import CouponSequenceGraph from "../CouponSequenceGraph";
import { dialogToggle } from "@/mixins/dialogToggle";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
import displayDepositMethodName from "@/mixins/shared/displayDepositMethodName";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ConfirmDialog,
    CouponTile,
    CouponSequenceGraph,
  },
  mixins: [dialogToggle, displayNameValidator, displayDepositMethodName],
  data() {
    return {
      name: "coupons",
      couponCode: "",
      errorMessage: "",
      showGameList: false,
      redeemCouponIsDisable: false,
    };
  },
  computed: {
    ...mapGetters("coupons", [
      "activeCoupon",
      "availableCoupons",
      "activeCouponValidForDeposit",
      "freeSpinCouponInfo",
      "includedTopLevelGames",
      "includedChildGames",
      "isCouponSequenceEnabled",
      "isCouponSequenceGraphicEnabled",
      "couponSequenceGraphInfo",
    ]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    hasActiveCoupon() {
      return (
        this.showDialog &&
        this.activeCoupon &&
        this.defaultCoupon &&
        this.activeCoupon.CouponCode.toLowerCase().trim() !==
          this.defaultCoupon.toLowerCase().trim() &&
        !this.activeCoupon.IsCouponCodeHidden
      );
    },
    emailVerified() {
      return this.$store.getters["session/emailVerified"];
    },
    defaultCoupon() {
      return this.$store.getters["dialogs/coupons"].data.couponCode;
    },
    depositMethodItems() {
      if (!this.activeCoupon) return [];
      const methodListCount = this.activeCoupon.MethodList.length;
      return this.activeCoupon.MethodList.map((method) => ({
        depositMethod: this.$displayDepositMethodName(
          method.MethodName,
          methodListCount
        ),
        minDeposit: this.$storageCur(method.MinimumDeposit),
        maxDeposit:
          method.MaximumDeposit === 0
            ? this.$str("Unlimited")
            : this.$storageCur(method.MaximumDeposit),
        bonus:
          this.activeCoupon.RedeemType === 1
            ? method.RedeemAmount + "%"
            : this.$storageCur(method.RedeemAmount),
        freeSpins: method.NumOfSpins,
      }));
    },
    maxCouponAmount() {
      if (!this.activeCoupon) return 0;
      return this.$str("MaxCouponAmount").replace(
        "{0}",
        this.$storageCur(this.activeCoupon.MaxCouponAmount)
      );
    },
    isPercentageCoupon() {
      return this.activeCoupon && this.activeCoupon.RedeemType === 1;
    },
    isDepositCoupon() {
      return (
        this.activeCoupon &&
        (this.activeCoupon.RedeemType === 1 ||
          this.activeCoupon.RedeemType === 2)
      );
    },
    freeSpinMsg() {
      return this.activeCoupon.FreeSpinMessage;
    },
    hasCouponSequenceGraphInfo() {
      return (
        this.couponSequenceGraphInfo &&
        (this.couponSequenceGraphInfo.PreviousCoupon ||
          (this.couponSequenceGraphInfo.NextCoupons &&
            this.couponSequenceGraphInfo.NextCoupons.length > 0))
      );
    },
    showCouponSequenceGraph() {
      return (
        this.isCouponSequenceEnabled &&
        this.isCouponSequenceGraphicEnabled &&
        this.hasCouponSequenceGraphInfo
      );
    },
    availableCouponsTournaments() {
      return this.availableCoupons?.filter(
        (coupon) => coupon.IsTournamentCoupon
      );
    },
    filteredAvailableCoupons() {
      return this.availableCoupons?.filter(
        (coupon) => !coupon.IsTournamentCoupon
      );
    },
    getMaxBetAllowedMessage() {
      let maxBetAllowedText = "";
      if (!this.activeCoupon) return maxBetAllowedText;

      const maxBetAllowedAmount = this.$cur(this.activeCoupon.MaxBetAllowed);

      maxBetAllowedText = `${this.$strResourceService(
        "MaxBetAllowed"
      )}: ${maxBetAllowedAmount}`;
      return maxBetAllowedText;
    },
    isMaxBetAllowedUnlimited() {
      return this.activeCoupon.MaxBetAllowed === null;
    },
    title() {
      return this.$validateDisplayName(
        this.$store.getters["dialogs/coupons"].data.title,
        this.$str("Coupons")
      );
    },
  },
  watch: {
    hasActiveCoupon(value) {
      if (value) {
        this.showActiveCouponDialog();
      }
    },
  },
  methods: {
    ...mapActions("dialogs", [
      "showVerifyEmail",
      "showExternalUrl",
      "showActiveCouponMessage",
      "showChooseMultiGame",
    ]),
    ...mapActions("coupons", ["clearCouponMessage"]),
    async launchGame(currentGame) {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("Confirmation"),
        message: this.$strResourceService(
          "CouponFSMultiGamesConfirmMsg"
        ).replace("<Game Name>", currentGame?.gameDescription),
      });
      if (confirmed) {
        this.$store
          .dispatch("coupons/selectMultiGameRedeemedCoupon", {
            multiFreeSpinSettingId: currentGame?.couponMultiGameId.toString(),
          })
          .then(() => {
            const gameId =
              currentGame?.gameid.toString() +
              "-" +
              currentGame?.machid.toString() +
              "-0";
            this.$store.dispatch("games/launchGame", {
              gameId: gameId,
            });
            this.launchedGame = true;
            this.showDialog = false;
          });
      }
    },
    onShown() {
      this.redeemCouponIsDisable = false;
      this.$store.dispatch("coupons/getAvailableCoupons");
      const code = this.$store.getters["dialogs/coupons"].data.couponCode;
      if (code) {
        this.couponCode = code;
      }
    },
    redeem() {
      this.redeemCouponIsDisable = true;
      this.$store.dispatch("coupons/redeem", {
        couponCode: this.couponCode || this.defaultCoupon,
      });
      this.$store.dispatch("dialogs/updateDialogData", {
        name: "coupons",
        data: { couponCode: this.couponCode || this.defaultCoupon },
      });
    },
    async cancelCoupon() {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("CancelCoupon"),
        message: this.$str("ConfirmCancelCoupon"),
      });

      if (confirmed) {
        this.$store.dispatch("coupons/cancelCoupon");
      }
    },
    close() {
      this.clearCouponMessage();
      this.showDialog = false;
    },
    onHidden() {
      this.couponCode = "";
      this.errorMessage = "";
    },
    showDepositDialog() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    getDepositHeaders() {
      const headers = [
        {
          text: "Deposit Method",
          align: "start",
          sortable: false,
          value: "depositMethod",
        },
        { text: this.$str("MinimumDeposit"), value: "minDeposit" },
        { text: this.$str("MaximumDeposit"), value: "maxDeposit" },
        { text: this.$str("Bonus"), value: "bonus" },
      ];
      if (this.activeCoupon.IsFreeSpin) {
        headers.push({ text: this.$str("FreeSpins"), value: "freeSpins" });
      }
      return headers;
    },
    getAmountPlayed() {
      return this.activeCoupon && !this.activeCoupon.IsDepositPending
        ? this.$storageCur(this.activeCoupon.PlaythroughAmountPlayed)
        : this.$str("AwaitingDeposit");
    },
    getAmountNeeded() {
      return this.activeCoupon && !this.activeCoupon.IsDepositPending
        ? this.activeCoupon.IsFreeSpin
          ? this.activeCoupon.FreeSpinPlaythrough
          : this.$cur(this.activeCoupon.PlaythroughAmountNeeded)
        : this.$str("AwaitingDeposit");
    },
    includedChildGamesByParent(parent) {
      return this.includedChildGames.filter(
        (child) => child.GameDescription === parent.GameDescription
      );
    },
    showActiveCouponDialog() {
      this.showActiveCouponMessage({
        title: this.$str("ActiveCoupon"),
        source: this.$str("ActiveCouponMessage").replace(
          "{0}",
          this.defaultCoupon
        ),
      });
    },
    getImageURL(currentGame) {
      const imageUrl = currentGame.imageUrls[this.lobbyIconType];

      return {
        background: imageUrl
          ? `url(${imageUrl}) center / cover no-repeat`
          : "rgba(0, 0, 0, 0.87)",
      };
    },
    showGameDescription(currentGame) {
      return currentGame.imageUrls[this.lobbyIconType] ? false : true;
    },
    betPerLineAmount(value) {
      return this.$storageCur(value);
    },
    updateRedeemCouponIsDisable() {
      this.redeemCouponIsDisable = false;
    },
    showTermsandConditions() {
      this.showExternalUrl({
        title: this.$str("TermsNConditionsLink"),
        source: this.activeCoupon.TermsAndConditionsUri,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: "",
      });
    },
  },
};
</script>

<style scoped>
.v-card__text.couponheader {
  text-align: center;
  padding: 0 !important;
  margin: 3vh 0;
}
.v-card__text.couponheader h1 {
  font-size: medium !important;
  font-weight: 400;
}
.v-card__text.couponheader p {
  font-size: x-large;
  font-weight: 700;
}
.gameFamily
  ::v-deep
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  align-self: center;
  margin: 0;
  margin-left: 0px;
  min-width: 24px;
  justify-content: flex-start;
  order: -1;
}

.gameFamily ::v-deep .v-list-item {
  padding-left: 1vw;
}

.v-tooltip__content {
  max-width: 500px;
}

::v-deep span.comp-points {
  font-weight: bold;
  background-color: var(--v-header-base);
}

::v-deep .gameImageContainer {
  width: 70px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 6px;
  position: relative;
}

::v-deep .gameInfoContainer {
  display: flex;
  width: 170px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

::v-deep .gameInfoStyle {
  flex: 1 0 0;
  color: var(--v-bodyTitle-base);
  font-weight: 700;
  font-family: Figtree Variable, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .gameContainer {
  display: flex;
  width: 170px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border: 1px solid;
  border-radius: 6px;
  border-color: rgba(115, 115, 115, 0.5);
}

::v-deep .gamesContainerRedeemed {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
}
::v-deep .gameContainerRedeemed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  align-self: stretch;
}

::v-deep .card {
  display: flex;
  gap: 10px;
  align-self: stretch;
  position: relative;
  transition: 0.5s;
  border: 1px solid;
  border-color: rgba(115, 115, 115, 0.5);
  border-radius: 4px;
}

::v-deep .card .content {
  width: 100%;
  padding: 1px;
  position: relative;
  display: flex;
  align-self: stretch;
}

::v-deep .card .content .details {
  display: flex;
  padding: 10px;
  width: 100%;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
}

::v-deep .bellyart {
  width: 70px;
  height: 80px;
  border-radius: 6px;
  position: relative;
}

::v-deep .freeSpinTextSmall {
  font-size: 10px;
  -webkit-text-stroke-width: 0.1vw;
  color: var(--v-buttonTxt-base);
  position: absolute;
  text-align: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-transform: uppercase;
  line-height: 1;
}
</style>