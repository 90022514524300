export const TournamentActions = {
  BuyIn: "BuyIn",
  AddOn: "AddOn",
  ReBuy: "ReBuy"
};

export const TournamentStatus = {
  NotAnnounced: 1,
  Announced: 2,
  Registering: 3,
  Running: 4,
  Completed: 5,
  Closed: 6,
  Cancelled: 7
};

export const TournamentMode = {
  Points: 1,
  Credits: 2
};

export const PayoutType = {
  Deposit: 1,
  Coupon: 2
};
