<template>
  <v-card flat light>
    <v-container>
      <v-row>
        <v-col v-if="!instantPlay" align-self="center" cols="5"
          ><v-img
            class="rounded"
            :src="gameIcon(tournament.game)"
            :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
            contain
            ><v-overlay v-if="tournament.restricted" z-index="0" absolute>
              <v-icon x-large>mdi-lock</v-icon>
            </v-overlay></v-img
          ></v-col
        ><v-col class="text-center">
          <p>
            <b>{{ $str("ID") }}:</b>{{ tournament.TournamentId }}
          </p>
          <h4>{{ $str("password") }}</h4>
          <v-text-field
            v-model="password"
            outlined
            :placeholder="$str('InsertPassword')"
            dense
            class="text-uppercase"
          ></v-text-field>
          <v-card-actions>
            <v-btn
              color="teal white--text"
              width="100%"
              depressed
              @click="unlock"
              >{{ $str("Unlock") }}</v-btn
            >
          </v-card-actions>
        </v-col></v-row
      >
    </v-container>
    <ConfirmDialog ref="errorDialog" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";

export default {
  components: {
    ConfirmDialog
  },
  props: {
    tournament: { type: Object, required: true }
  },
  data: function() {
    return {
      password: ""
    };
  },
  computed: {
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    },
    instantPlay() {
      return this.$store.getters["session/instantPlayClient"];
    }
  },
  methods: {
    ...mapActions("tournaments", ["unlockTournament"]),
    async unlock() {
      const success = await this.unlockTournament({
        tournamentId: this.tournament.TournamentId,
        password: this.password.toUpperCase()
      });
      if (!success) {
        this.password = "";
        this.$refs.errorDialog.openAlert({
          message: this.$str("PasswordNotMatch"),
          title: this.$str("Invalid")
        });
      }
    }
  }
};
</script>

<style scoped>
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-bottom-right-radius: 2vw;
  border-bottom-left-radius: 2vw;
}
</style>
<style lang="scss">
.text-uppercase input {
  text-transform: uppercase;
}
::placeholder {
  text-transform: none;
}
</style>
