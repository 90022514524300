import { restoreSessionCompleted } from "@/services/casino";
const INTERVAL_PROJECTED_TIME = 30;
const INTERVAL_TIME = 15;
const INTERVAL_TIME_IN_SECONDS = 1;
const state = {
  casinoJackpot: [],
  casinoJackpotWinners: [],
  casinoJackpotUser: {},
  jackpotPrize: 0,
  jackpotPrizeDisplay: 0,
  costToPlayer: 0,
  playerIsRestricted: true
};

const getters = {
  casinoJackpot: state => state.casinoJackpot,
  casinoJackpotWinners: state => state.casinoJackpotWinners,
  isCasinoJackpotAvailable: state => state.casinoJackpot.Status,
  casinoJackpotUser: state => state.casinoJackpotUser,
  jackpotPrize: state => state.jackpotPrize,
  jackpotPrizeDisplay: state => state.jackpotPrizeDisplay,
  costToPlayer: state => state.costToPlayer,
  playerIsRestricted: state => state.playerIsRestricted
};

const actions = {
  async initialize(context) {
    CDK.on(CDK.Events.lobbyJackpotRestrictionChanges, result =>
      context.commit("updatePlayerRestricted", result)
    );
    await context.dispatch("requestCasinoJackpot");
    await restoreSessionCompleted;
    context.dispatch("setCostToPlayer");

    CDK.on(CDK.Events.loggedIn, () => {
      context.dispatch("setCostToPlayer");
      context.dispatch("requestCasinoJackpotUser");
      context.dispatch("requestCasinoJackpotWinners");
    });
    CDK.on(CDK.Events.loggedOut, () => {
      if (CDK.isConnected()) {
        context.dispatch("clearCasinoJackpotUser");
        context.commit("updatePlayerRestricted", true);
        context.dispatch("requestCasinoJackpotWinners");
      }
    });

    await context.dispatch("requestCasinoJackpot");
    await context.dispatch("requestCasinoJackpotWinners");
    await context.dispatch("requestCasinoJackpotUser");
  },
  async requestCasinoJackpotWinners(context) {
    if (context.getters.isCasinoJackpotAvailable) {
      await CDK.GetCasinoJackpotRecentWinners().then(response => {
        context.commit("updateCasinoJackpotRecentWinners", response);
        window.console.log("LOBBY JACKPOT: Recent Winners updated.");
      });
    }
  },

  async setCostToPlayer(context) {
    try {
      if (CDK.isConnected() && CDK.isLoggedIn()) {
        await CDK.getLobbyJackpotCostToPlayer().then(costToPlayer =>
          context.commit("updateCostToPlayer", costToPlayer)
        );
      }
    } catch (error) {
      window.console.warn(
        `There was an error requesting CostToPlayer: ${error}`
      );
    }
  },

  async requestCasinoJackpot(context) {
    try {
      const casinoJackpotActiveBySkin = casinoJackpot => {
        const skinId = CDK.getSkinID();
        return (
          casinoJackpot.CasinoSkins.IsAllSkinsEnable ||
          casinoJackpot.CasinoSkins.Skins.some(x => x.SkinId === skinId)
        );
      };

      await CDK.getCasinoJackpots().then(response => {
        let casinoJackpot = { Status: false };
        if (response && response.Status) {
          if (casinoJackpotActiveBySkin(response)) {
            casinoJackpot = response;
          }
          context.dispatch("JackpotPrizeIncrementAction", casinoJackpot.Guid);
        }
        context.commit("updateCasinoJackpot", casinoJackpot);
      });
    } catch (error) {
      window.console.warn(
        `There was an error requesting Lobby Jackpot information: ${error}`
      );
    }
  },

  async requestCasinoJackpotUser(context) {
    try {
      if (
        CDK.isConnected() &&
        CDK.isLoggedIn() &&
        context.getters.isCasinoJackpotAvailable
      ) {
        await CDK.getPlayerCasinoJackpot(
          context.getters.casinoJackpot.Guid
        ).then(playerCasinoJackpot => {
          context.commit("updatePlayerCasinoJackpot", playerCasinoJackpot);
        });
      }
    } catch (error) {
      window.console.warn(
        `There was an error requesting Lobby Jackpot User information: ${error}`
      );
    }
  },

  async requestJackpotPrize(context, jackpotGuid) {
    try {
      if (CDK.isConnected()) {
        await CDK.getCasinoJackpotBalance(jackpotGuid).then(prize =>
          context.commit("updateJackpotPrize", prize)
        );
      }
    } catch (error) {
      window.console.warn(
        `There was an error requesting Lobby Jackpot Prize information: ${error}`
      );
    }
  },

  async JackpotPrizeIncrementAction(context, jackpotGuid) {
    await context.dispatch("requestJackpotPrize", jackpotGuid);
    let intervalGamesVerifier = null;
    const clearInternalInterval = () => {
      if (intervalGamesVerifier) {
        window.clearInterval(intervalGamesVerifier);
        intervalGamesVerifier = null;
      }
    };

    if (context.getters.jackpotPrizeDisplay === 0) {
      context.commit("updateJackpotPrizeDisplay", context.getters.jackpotPrize);
    }
    setInterval(async () => {
      clearInternalInterval();
      await context.dispatch("requestJackpotPrize", jackpotGuid);
      const prize = context.getters.jackpotPrize;
      if (
        context.getters.jackpotPrizeDisplay !== prize &&
        context.getters.jackpotPrizeDisplay < prize
      ) {
        const diffJackpotPrize = prize - context.getters.jackpotPrizeDisplay;
        const incremental =
          (INTERVAL_TIME_IN_SECONDS * diffJackpotPrize) /
          INTERVAL_PROJECTED_TIME;

        if (intervalGamesVerifier === null) {
          intervalGamesVerifier = setInterval(() => {
            context.commit(
              "updateJackpotPrizeDisplay",
              parseFloat(
                parseFloat(context.getters.jackpotPrizeDisplay) + incremental
              )
            );
          }, INTERVAL_TIME_IN_SECONDS * 1000);
        }
      } else {
        context.commit(
          "updateJackpotPrizeDisplay",
          parseFloat(context.getters.jackpotPrize)
        );
      }
    }, INTERVAL_TIME * 1000);
  },

  async SetOptIn(context, IsOptIn) {
    try {
      if (CDK.isConnected() && CDK.isLoggedIn()) {
        await CDK.OptPlayer(
          context.getters.casinoJackpot.Guid,
          IsOptIn
        ).then(playerCasinoJackpot =>
          context.commit("updatePlayerCasinoJackpot", playerCasinoJackpot)
        );
      }
    } catch (error) {
      window.console.warn(
        `There was an error requesting Lobby Jackpot User information: ${error}`
      );
    }
  },

  async ClaimJackpotPrize(context) {
    if (
      CDK.isConnected() &&
      CDK.isLoggedIn() &&
      context.getters.isCasinoJackpotAvailable &&
      context.getters.casinoJackpotUser.UnclaimedHitsAmount > 0
    ) {
      await CDK.ClaimJackpotPrize().then(async response => {
        if (!response) {
          window.console.warn("An error occured claiming the jackpot hits");
        }
        context.dispatch("requestCasinoJackpotUser");
        await context.dispatch("requestCasinoJackpotWinners");
      });
    }
  },

  clearCasinoJackpotUser(context) {
    context.commit("updatePlayerCasinoJackpot", {});
  }
};

const mutations = {
  updateCasinoJackpot(state, casinoJackpot) {
    state.casinoJackpot = casinoJackpot;
  },
  updatePlayerCasinoJackpot(state, casinoJackpotUser) {
    state.casinoJackpotUser = casinoJackpotUser;
  },
  updateJackpotPrize(state, prize) {
    state.jackpotPrize = prize;
  },
  updateJackpotPrizeDisplay(state, prize) {
    state.jackpotPrizeDisplay = prize;
  },
  updateCasinoJackpotRecentWinners(state, winners) {
    state.casinoJackpotWinners = winners;
  },
  updateCostToPlayer(state, cost) {
    state.costToPlayer = cost;
  },
  updatePlayerRestricted(state, restricted) {
    state.playerIsRestricted = restricted;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
