/**
 * Information about the current casino session
 */

import {
  clientType,
  GameLaunchMode,
  restoreSessionCompleted,
  skinId
} from "@/services/casino";

const state = {
  connected: CDK.isConnected(),
  loggedIn: false,
  playerLogin: "",
  playerPid: "",
  playForMoney: false,
  multipleGamesAtOnce: false,
  maxGamesAtOnce: 4,
  clientType: null,
  emailVerified: false,
  playerEmail: "",
  liveChatInfo: {},
  restoreSessionCompleted: false,
  switchingToRealFromGame: false,
  skinId: skinId,
  currentMultipleGamesOpened: [],
  onSuccessSignIn: null,
  customScriptAvailability: false,
  customUrlHistory: {
    isVisible: false,
    displayName: '',
    data: {
      title: "",
      source: ""
    }
  },
  refreshTokens: false,
  requestParams: {},
  isWCOVisible: false,
  wcoLobbyUrl: {
    source: "",
    hasErrors: false
  }
};

const forcedGameParamKeys = [
  "forceCDN",
  "forceGamePackVersion",
  "forceModuleOverride",
  "cdnClassification",
  "cdnResourceManifestFolder",
  "cdnCommonResourceManifestName"
];

const getters = {
  connected: state => state.connected,
  loggedIn: state => state.loggedIn,
  playerLogin: state => state.playerLogin,
  playerPid: state => state.playerPid,
  playForMoney: state => state.playForMoney,
  multipleGamesAtOnce: state => state.multipleGamesAtOnce,
  clientType: state => state.clientType,
  mobileClient: state => state.clientType === CDK.ClientTypes.Mobile,
  instantPlayClient: state => state.clientType === CDK.ClientTypes.InstantPlay,
  downloadClient: state => state.clientType === CDK.ClientTypes.Download,
  gameLaunchMode: (state, getters) => {
    if (getters.mobileClient) {
      return GameLaunchMode.ThisWindow;
    } else if (state.multipleGamesAtOnce) {
      return GameLaunchMode.NewWindow;
    } else {
      return GameLaunchMode.Embedded;
    }
  },
  emailVerified: state => state.emailVerified,
  playerEmail: state => state.playerEmail,
  liveChatInfo: state => state.liveChatInfo,
  restoreSessionCompleted: state => state.restoreSessionCompleted,
  switchingToRealFromGame: state => state.switchingToRealFromGame,
  skinId: state => state.skinId,
  onSuccessSignIn: state => state.onSuccessSignIn,
  customScriptAvailability: state => state.customScriptAvailability,
  currentMultipleGamesOpened: state => state.currentMultipleGamesOpened,
  openMultipleGamesAtOnceAllowed: state =>
    state.currentMultipleGamesOpened.length < state.maxGamesAtOnce,
  customUrlHistory: state => state.customUrlHistory,
  refreshTokens: state => state.refreshTokens,
  requestParams: state => state.requestParams,
  forcedGameParams: state => {
    return Object.fromEntries(
      Object.entries(state.requestParams).filter(([key]) =>
        forcedGameParamKeys.includes(key)
      )
    );
  },
  isWCOVisible: state => state.isWCOVisible,
  wcoLobbyUrl: state => state.wcoLobbyUrl
};

const connectionEvents = [
  CDK.Events.connected,
  CDK.Events.connectionDataSet,
  CDK.Events.connectionLost,
  CDK.Events.connectionRestored,
  CDK.Events.disconnected,
  CDK.Events.loggedIn,
  CDK.Events.loggedOut,
  CDK.Events.restoreSessionCompleted,
  CDK.Events.sessionChanged
];

const actions = {
  async initialize(context) {
    context.commit("updateClientType", clientType);
    context.dispatch("updateSessionInfo", "internal initialize");

    connectionEvents.forEach(event =>
      CDK.on(event, () => context.dispatch("updateSessionInfo", event))
    );

    CDK.on(CDK.Events.loggedIn, async () => {
      await context.dispatch("updateWCOVisible");
    });

    const liveChatInfo = await CDK.getLobbyCoreLiveChatInfo();
    context.commit("updateLiveChatInfo", liveChatInfo);

    const liveChatURL = await CDK.getLobbyCoreLiveChatUrl("lobby");
    const data = { title: "LiveChat", source: liveChatURL.Url };
    context.commit("updateCustomUrlPersistentData", data);

    setTimeout(() => context.commit("updateCustomScriptAvailability"), 3000);
  },
  updateSessionPlayerEmail({ commit }, newEmail) {
    CDK.setPlayerEmailAddress(newEmail);
    commit("updatePlayerEmail", CDK.getPlayerEmailAddress());
  },
  updateSessionInfo({ commit }) {
    const connected = CDK.isConnected();
    commit("updateConnected", connected);

    commit("updateMultipleGamesAtOnce", CDK.getMultipleGamesAtOnceEnabled());

    const restoreCompleted = connected
      ? CDK.isRestoreSessionCompleted()
      : false;
    commit("updateRestoreSessionCompleted", restoreCompleted);

    const loggedIn = connected ? CDK.isLoggedIn() : false;
    commit("updateLoggedIn", loggedIn);
    commit("updatePlayerPid", loggedIn ? CDK.getPlayerPID() : null);
    commit("updatePlayerLogin", loggedIn ? CDK.getPlayerLogin() : null);
    commit("updatePlayerEmail", loggedIn ? CDK.getPlayerEmailAddress() : null);
    commit("updatePlayForMoney", CDK.isPlayingForMoney());
    commit(
      "updateEmailVerified",
      loggedIn
        ? CDK.isEmailVerificationEnabled()
          ? CDK.isPlayerEmailVerified()
          : true
        : true
    );
  },
  async updateWCOVisible({ commit }) {
    const isWCOVisible = await CDK.isWCOVisible();
    commit("updateWCOVisible", isWCOVisible);
    
    if(isWCOVisible) {
      const wcoLobbyUrl = await CDK.getWcoLobbyUrl();
      const wcoLobbyUrlInfo = {
        source: wcoLobbyUrl.Message,
        hasErrors: wcoLobbyUrl.HasErrors
      }
      commit("updateWCOLobbyUrl", wcoLobbyUrlInfo);
    }
  },
  setOnSuccessSignInAction(context, { action, params }) {
    context.commit("updateOnSuccessSignIn", { action, params });
  },
  async signIn(context, { login, password, forMoney }) {
    let userLogin = login;
    try {
      userLogin = await CDK.CheckLoginIsActiveEmail(login);
    } catch {
      userLogin = login;
    }
    await CDK.logIn(userLogin, password, forMoney);
  },
  async signInWithToken(context, { login, token, forMoney, terminalid }) {
    let userLogin = login;
    try {
      userLogin = await CDK.CheckLoginIsActiveEmail(login);
    } catch {
      userLogin = login;
    }

    try {
      await CDK.logInWithToken(userLogin, token, forMoney, terminalid).fail(
        () => {
          CDK.on(CDK.Events.restoreSessionCompleted, function (
            sessionWasRestored
          ) {
            if (!sessionWasRestored) {
              context.dispatch("redirectAnonymous");
            }
          });
        }
      );
    } catch (error) {
      window.console.warn(`There was an error ${error.Message}`);
    }
  },
  async requestSignOut(context) {
    await context.dispatch("messageCenter/requestForceOnExitMessages", null, {
      root: true
    });
    const exitMsgs = context.rootGetters["messageCenter/forceOnExitMessages"];
    if (exitMsgs.length === 0) {
      await context.dispatch("signOut");
    }
  },
  async signOut({ dispatch, getters, commit }) {
    await CDK.logOut();
    dispatch("updateSessionInfo");
    commit("updateRefreshTokens", true);
    if (getters.multipleGamesAtOnce) dispatch("closeAllMultipleGamesOpened");
    dispatch("redirectAnonymous");
  },
  async signOutRequestParameter(context) {
    await restoreSessionCompleted;
    const loggedIn =
      CDK.isLoggedIn() && context.getters.loggedIn ? true : false;
    if (loggedIn) {
      await context.dispatch("requestSignOut");
    }
  },
  async signUp(
    context,
    {
      Login,
      Password,
      Birthdate,
      AddressLine1,
      AddressLine2,
      cellPhone,
      City,
      Country,
      countryName,
      DaytimePhone,
      EMailAddress,
      EveningPhone,
      FirstName,
      gender,
      LastName,
      SMSMessages,
      State,
      Zip,
      BonusesAndPromotions,
      playerCurrencyId,
      emailVerified
    }
  ) {
    await CDK.registerPlayer(
      Login,
      Password,
      Birthdate,
      AddressLine1,
      AddressLine2,
      cellPhone,
      City,
      Country,
      countryName,
      DaytimePhone,
      EMailAddress,
      EveningPhone,
      FirstName,
      gender,
      LastName,
      SMSMessages,
      State,
      Zip,
      BonusesAndPromotions,
      playerCurrencyId,
      emailVerified
    );
    context.dispatch("updateSessionInfo");
  },
  async changePassword(context, { login, email, password, token }) {
    await CDK.changePasswordWithToken(login, email, password, token);
  },
  async requestNewCode(context, { userName, email }) {
    const formType = 1; //TODO: This may need to change for 'reset password' case -HB
    return await CDK.requestNewSecurityCode(userName, email, formType);
  },
  async switchToReal(context) {
    context.dispatch("closeAllMultipleGamesOpened");
    await restoreSessionCompleted;
    await context.dispatch("balances/unRegisterBalanceHubListener", null, {
      root: true
    });
    await CDK.switchToReal();
  },
  async switchToRealFromGame(context) {
    context.commit("updateSwitchingToRealFromGame", true);
    await restoreSessionCompleted;
    await CDK.switchToReal();
    const launchingGame = await context.dispatch(
      "games/launchLastPlayedGame",
      null,
      {
        root: true
      }
    );
    if (!launchingGame) {
      context.commit("updateSwitchingToRealFromGame", false);
    }
  },
  async gameLaunchFailed(context) {
    context.commit("updateSwitchingToRealFromGame", false);
  },
  addCurrentMultipleGamesOpened({ commit, getters }, { gameUid, gameWindow }) {
    const found = getters.currentMultipleGamesOpened.some(
      el => el.gameUid === gameUid
    );
    if (!found)
      commit("addCurrentMultipleGamesOpened", {
        gameUid,
        gameWindow
      });
  },
  updateHasBalanceChangeCurrentOpenedGame(
    { commit },
    { gameUid, hasBalanceChange }
  ) {
    commit("updateHasBalanceChangeCurrentOpenedGame", {
      gameUid,
      hasBalanceChange
    });
  },
  removeCurrentMultipleGamesOpened({ commit, getters }, gameUid) {
    const found = getters.currentMultipleGamesOpened.some(
      el => el.gameUid === gameUid
    );
    if (found) commit("removeCurrentMultipleGamesOpened", gameUid);
  },
  closeAllMultipleGamesOpened({ commit, getters }) {
    for (const game of getters.currentMultipleGamesOpened) {
      game.gameWindow?.close();
      commit("removeCurrentMultipleGamesOpened", game.gameUid);
    }
  },
  redirectAnonymous(context) {
    const loggedIn =
      CDK.isLoggedIn() && context.getters.loggedIn ? true : false;
    if (!loggedIn && CDK.getAnonymousRedirect()) {
      window.location.replace(CDK.getAnonymousRedirect());
    }
  },
  showCustomUrlWithHistory(context, { show, displayName }) {
    context.commit("showCustomUrlWithHistory", { isVisible: show, displayName: displayName });
  },
  updateRefreshTokens(context, refreshTokens) {
    context.commit("updateRefreshTokens", refreshTokens);
  },
  saveRequestParams(context, { params }) {
    context.commit("updateRequestParams", { params });
  }
};

const mutations = {
  updateConnected: (state, connected) => {
    state.connected = connected;
  },
  updateLoggedIn: (state, loggedIn) => {
    state.loggedIn = loggedIn;
  },
  updatePlayerPid: (state, pid) => {
    state.playerPid = pid;
  },
  updatePlayerLogin: (state, login) => {
    state.playerLogin = login;
  },
  updatePlayForMoney: (state, forMoney) => {
    state.playForMoney = forMoney;
  },
  updateMultipleGamesAtOnce: (state, multipleGamesAtOnce) => {
    state.multipleGamesAtOnce = multipleGamesAtOnce;
  },
  updateClientType: (state, type) => {
    state.clientType = type;
  },
  updateEmailVerified: (state, verified) => {
    state.emailVerified = verified;
  },
  updatePlayerEmail: (state, email) => {
    state.playerEmail = email;
  },
  updateLiveChatInfo: (state, info) => {
    state.liveChatInfo = info;
  },
  updateRestoreSessionCompleted: (state, completed) => {
    state.restoreSessionCompleted = completed;
  },
  updateSwitchingToRealFromGame: (state, switching) => {
    state.switchingToRealFromGame = switching;
  },
  updateOnSuccessSignIn: (state, dispatchAction) => {
    state.onSuccessSignIn = dispatchAction;
  },
  updateCustomScriptAvailability: state => {
    state.customScriptAvailability = true;
  },
  addCurrentMultipleGamesOpened: (state, { gameUid, gameWindow }) => {
    state.currentMultipleGamesOpened.push({
      gameUid,
      gameWindow,
      hasBalanceChange: false
    });
  },
  updateHasBalanceChangeCurrentOpenedGame: (
    state,
    { gameUid, hasBalanceChange }
  ) => {
    state.currentMultipleGamesOpened.forEach(game => {
      if (game.gameUid === gameUid) {
        game.hasBalanceChange = hasBalanceChange;
      }
    });
  },
  removeCurrentMultipleGamesOpened: (state, gameUid) => {
    state.currentMultipleGamesOpened = state.currentMultipleGamesOpened.filter(
      el => el.gameUid !== gameUid
    );
  },
  showCustomUrlWithHistory: (state, {isVisible, displayName}) => {
    state.customUrlHistory.isVisible = isVisible;
    state.customUrlHistory.displayName = displayName;
  },
  updateCustomUrlPersistentData: (state, data) => {
    state.customUrlHistory.data = data;
  },
  updateRefreshTokens: (state, refreshTokens) => {
    state.refreshTokens = refreshTokens;
  },
  updateRequestParams: (state, { params }) => {
    state.requestParams = params;
  },
  updateWCOVisible: (state, isVisible) => {
    state.isWCOVisible = isVisible;
  },
  updateWCOLobbyUrl: (state, wcoLobbyUrl) => {
    state.wcoLobbyUrl = wcoLobbyUrl
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
