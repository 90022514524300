import { mapGetters } from "vuex";
export const tournaments = {
  computed: {
    ...mapGetters("tournaments", [
      "convertToBaseCurrency",
      "customCurrencySymbolIsEnabledInTournament",
      "tournamentsCurrencyFormat"
    ])
  },
  methods: {
    $playerBalanceCur(amount, revert) {
      // Balances are given to us already converted for display so revert
      //   the value back to storage
      let storageAmt = CDK.convertCurrencyValueForIngestion(amount);

      // Since the balance is always given to us in player amounts, we need
      //  to convert this if we are set to show casino only
      //  (IE Toggle is not active)
      if (this.tournamentsCurrencyFormat === "casino") {
        if (CDK.isConnected() && CDK.isLoggedIn()) {
          storageAmt = storageAmt / (CDK.getCurrencyMultiplier() ?? 1);
        }
      }

      // Now display it properly
      return this.$tournamentCur(storageAmt, revert);
    },
    $tournamentCur(amount, revert) {
      if (
        this.tournamentsCurrencyFormat === "player" ||
        this.tournamentsCurrencyFormat === "both"
      ) {
        if (this.convertToBaseCurrency) {
          return revert ? this.revertedCur(amount) : this.baseCur(amount);
        }
        return revert ? this.cur(amount) : this.convertedCur(amount);
      }
      return this.baseCur(amount);
    },
    $showSwitchToBaseCurrency() {
      return (
        this.baseCur(1) !== this.convertedCur(1) &&
        this.tournamentsCurrencyFormat === "both"
      );
    },
    cur(amount) {
      return CDK.isConnected()
        ? CDK.formatInTournamentCurrency(
            amount,
            this.customCurrencySymbolIsEnabledInTournament
          )
        : amount;
    },
    convertedCur(amount) {
      if (CDK.isConnected() && CDK.isLoggedIn()) {
        amount = amount * (CDK.getCurrencyMultiplier() ?? 1);
      }
      return CDK.isConnected()
        ? CDK.formatInTournamentCurrency(
            amount,
            this.customCurrencySymbolIsEnabledInTournament
          )
        : amount;
    },
    revertedCur(amount) {
      if (CDK.isConnected() && CDK.isLoggedIn()) {
        amount = amount / (CDK.getCurrencyMultiplier() ?? 1);
      }
      return CDK.isConnected()
        ? CDK.formatInTournamentBaseCurrency(
            amount,
            this.customCurrencySymbolIsEnabledInTournament
          )
        : amount;
    },
    baseCur(amount) {
      return CDK.isConnected()
        ? CDK.formatInTournamentBaseCurrency(
            amount,
            this.customCurrencySymbolIsEnabledInTournament
          )
        : amount;
    }
  }
};
