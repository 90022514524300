<template>
  <v-dialog
    v-if="accountFieldsLoaded"
    v-model="showDialog"
    width="390"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
  >
    <v-card class="elevation-12" style="overflow: hidden;">
      <DialogTitleBar :title="title" @close="showDialog = false" />
      <v-card-text style="position: relative">
        <v-overlay v-show="loading || saving" opacity="0.8" z-index="1" absolute
          ><v-progress-circular
            color="grey"
            indeterminate
            style="position: fixed; top: 40%; transform: translateX(-50%);"
          ></v-progress-circular>
        </v-overlay>

        <v-form
          v-if="showDialog"
          id="my-profile-form"
          ref="form"
          style="height: 100%; overflow-y: auto; overflow-x: hidden;"
          class="pt-2"
        >
          <v-text-field
            v-if="isShown(accountCreateFields.Login)"
            id="myProfileLogin"
            ref="login"
            v-model.trim="fields.Login"
            :class="getFieldClass(accountCreateFields.Login)"
            :disabled="!updateable('Login')"
            v-bind="accountCreateFieldsAttributes.Login"
            :rules="accountCreateFieldsRules.Login"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
            autocomplete="off"
          >
          </v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.EMailAddress)"
            id="myProfileEMail"
            ref="emailAddress"
            v-model.trim="fields.EMailAddress"
            :class="getFieldClass(accountCreateFields.EMailAddress)"
            :disabled="!updateable('EMailAddress')"
            v-bind="accountCreateFieldsAttributes.EMailAddress"
            :rules="accountCreateFieldsRules.EMailAddress"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
            autocomplete="off"
          >
          </v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.FirstName)"
            id="myProfileFirstName"
            ref="firstName"
            v-model.trim="fields.FirstName"
            :class="getFieldClass(accountCreateFields.FirstName)"
            :disabled="!updateable('FirstName')"
            v-bind="accountCreateFieldsAttributes.FirstName"
            :rules="accountCreateFieldsRules.FirstName"
            color="inherit"
            outlined
            dense
            hide-details="auto"
            validate-on-blur
          >
          </v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.LastName)"
            id="myProfileLastName"
            ref="lastName"
            v-model.trim="fields.LastName"
            :class="getFieldClass(accountCreateFields.LastName)"
            :disabled="!updateable('LastName')"
            v-bind="accountCreateFieldsAttributes.LastName"
            :rules="accountCreateFieldsRules.LastName"
            color="inherit"
            outlined
            dense
            hide-details="auto"
            validate-on-blur
          >
          </v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.Birthdate)"
            id="myProfileBirthdate"
            ref="birthdate"
            v-model.trim="fields.Birthdate"
            :class="getFieldClass(accountCreateFields.Birthdate)"
            :disabled="!updateable('Birthdate')"
            v-bind="accountCreateFieldsAttributes.Birthdate"
            :rules="accountCreateFieldsRules.Birthdate"
            :max="currentDate"
            color="inherit"
            outlined
            dense
            hide-details="auto"
          />
          <v-select
            v-if="isShown(accountCreateFields.gender)"
            id="myProfileGender"
            ref="gender"
            v-model.trim="fields.gender"
            :disabled="!updateable('gender')"
            :class="getFieldClass(accountCreateFields.gender)"
            v-bind="accountCreateFieldsAttributes.gender"
            :rules="accountCreateFieldsRules.gender"
            :items="getItems(accountCreateFields.gender)"
            item-value="Value"
            item-text="Key"
            class="v-select-custom"
            color="inherit"
            outlined
            dense
            hide-details="auto"
          >
          </v-select>

          <v-text-field
            v-if="isShown(accountCreateFields.AddressLine1)"
            id="myProfileAddressLine1"
            ref="addressLine1"
            v-model.trim="fields.AddressLine1"
            :class="getFieldClass(accountCreateFields.AddressLine1)"
            :disabled="!updateable('AddressLine1')"
            v-bind="accountCreateFieldsAttributes.AddressLine1"
            :rules="accountCreateFieldsRules.AddressLine1"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.AddressLine2)"
            id="myProfileAddressLine2"
            ref="addressLine2"
            v-model.trim="fields.AddressLine2"
            :class="getFieldClass(accountCreateFields.AddressLine2)"
            :disabled="!updateable('AddressLine2')"
            v-bind="accountCreateFieldsAttributes.AddressLine2"
            :rules="accountCreateFieldsRules.AddressLine2"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.City)"
            id="myProfileCity"
            ref="city"
            v-model.trim="fields.City"
            :class="getFieldClass(accountCreateFields.City)"
            :disabled="!updateable('City')"
            autocomplete="off"
            v-bind="accountCreateFieldsAttributes.City"
            :rules="accountCreateFieldsRules.City"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-select
            v-if="isShown(accountCreateFields.Country)"
            id="myProfileCountry"
            ref="country"
            v-model.trim="fields.Country"
            :disabled="!updateable('Country')"
            class="v-select-custom"
            :class="getFieldClass(accountCreateFields.Country)"
            v-bind="accountCreateFieldsAttributes.Country"
            :rules="accountCreateFieldsRules.Country"
            :items="getItems(accountCreateFields.Country)"
            item-value="Value"
            item-text="Key"
            color="inherit"
            outlined
            dense
            hide-details="auto"
            @change="setStateFieldAndPhoneCodes"
          >
          </v-select>

          <v-select
            v-if="isShown(accountCreateFields.State) && showStateDropDown"
            id="myProfileState"
            ref="state"
            v-model.trim="fields.State"
            :disabled="!updateable('State')"
            class="v-select-custom"
            :class="getFieldClass(accountCreateFields.State)"
            v-bind="accountCreateFieldsAttributes.State"
            :rules="accountCreateFieldsRules.State"
            :items="getItems(accountCreateFields.State)"
            item-value="Value"
            item-text="Key"
            color="inherit"
            outlined
            dense
            hide-details="auto"
          >
          </v-select>
          <v-text-field
            v-if="isShown(accountCreateFields.State) && !showStateDropDown"
            id="myProfileState"
            ref="state"
            v-model.trim="fields.State"
            :class="getFieldClass(accountCreateFields.State)"
            :disabled="!updateable('State')"
            v-bind="accountCreateFieldsAttributes.State"
            :rules="accountCreateFieldsRules.State"
            outlined
            dense
            hide-details="auto"
          >
          </v-text-field>

          <v-text-field
            v-if="isShown(accountCreateFields.Zip)"
            id="myProfileZip"
            ref="zipCode"
            v-model.trim="fields.Zip"
            :class="getFieldClass(accountCreateFields.Zip)"
            :disabled="!updateable('Zip')"
            v-bind="accountCreateFieldsAttributes.Zip"
            :rules="accountCreateFieldsRules.Zip"
            color="inherit"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>

          <v-text-field
            v-if="isShown(accountCreateFields.cellPhone)"
            id="myProfileCellPhone"
            ref="cellPhone"
            v-model.trim="fields.cellPhone"
            :class="getFieldClass(accountCreateFields.cellPhone)"
            :disabled="!updateable('cellPhone')"
            v-bind="accountCreateFieldsAttributes.cellPhone"
            :rules="CellPhoneCountryRules"
            color="inherit"
            validate-on-blur
            autocomplete="off"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.DaytimePhone)"
            id="myProfileDaytimePhone"
            ref="dayTimePhone"
            v-model.trim="fields.DaytimePhone"
            :class="getFieldClass(accountCreateFields.DaytimePhone)"
            :disabled="!updateable('DaytimePhone')"
            autocomplete="off"
            v-bind="accountCreateFieldsAttributes.DaytimePhone"
            :rules="DaytimePhoneCountryRules"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-if="isShown(accountCreateFields.EveningPhone)"
            id="myProfileEveningPhone"
            ref="eveningPhone"
            v-model.trim="fields.EveningPhone"
            :class="getFieldClass(accountCreateFields.EveningPhone)"
            :disabled="!updateable('EveningPhone')"
            autocomplete="off"
            v-bind="accountCreateFieldsAttributes.EveningPhone"
            :rules="EveningPhoneCountryRules"
            color="inherit"
            validate-on-blur
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-alert
            v-show="profileError"
            type="error"
            dense
            hide-details="auto"
            :icon="false"
            class="mb-4 text-left"
            style="font-weight: 700;"
          >
            {{ profileError }}
          </v-alert>
          <v-row v-if="allowUpdates" class="pb-4">
            <v-col cols="6" class="pt-0">
              <v-btn
                block
                color="buttonSecondary buttonSecondaryTxt--text"
                @click="close"
              >
                {{ $str("Cancel") }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="pt-0">
              <v-btn
                block
                color="button buttonTxt--text"
                :disabled="!hasChanges"
                @click="submit()"
              >
                {{ $str("Save") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else align="center" justify="space-around" class="pb-4">
            <v-col cols="12" class="pt-0">
              <v-btn block color="button buttonTxt--text" @click="close">
                {{ $str("OKButton") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VTextField } from "vuetify/lib";
import { VSelect } from "vuetify/lib";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions, mapGetters } from "vuex";
import displayNameValidator from "@/mixins/shared/displayNameValidator";

export default {
  components: { VTextField, VSelect },
  mixins: [dialogToggle, displayNameValidator],
  data() {
    return {
      name: "myProfile",
      fields: {
        Login: "",
        Birthdate: null,
        AddressLine1: "",
        AddressLine2: "",
        cellPhone: null,
        City: "",
        Country: 0,
        DaytimePhone: null,
        EMailAddress: "",
        emailVerified: false,
        EveningPhone: null,
        FirstName: "",
        gender: null,
        LastName: "",
        State: "",
        Zip: null
      },
      profileError: "",
      phoneCountryCode: "",
      CellPhoneCountryRule: [],
      DaytimePhoneCountryRule: [],
      EveningPhoneCountryRule: [],
      isEmailVerified: null,
      loading: true,
      saving: false,
      hasChanges: false,
      fieldUpdateable: {
        Login: false,
        Birthdate: true,
        AddressLine1: true,
        AddressLine2: true,
        cellPhone: true,
        City: true,
        Country: true,
        DaytimePhone: true,
        EMailAddress: false,
        emailVerified: false,
        EveningPhone: true,
        FirstName: true,
        gender: true,
        LastName: true,
        State: true,
        Zip: true
      }
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "accountCreateFields",
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules",
      "accountFieldsLoaded"
    ]),
    ...mapGetters("session", ["playerPid"]),
    allowUpdates() {
      return CDK.allowUpdatePersonalInfo();
    },
    updateable() {
      return field => this.allowUpdates && this.fieldUpdateable[field];
    },
    currentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    showStateDropDown() {
      return (
        this.fields.Country === "AU" ||
        this.fields.Country === "CA" ||
        this.fields.Country === "US"
      );
    },
    CellPhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.cellPhone,
        ...this.CellPhoneCountryRule
      ];
    },
    DaytimePhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.DaytimePhone,
        ...this.DaytimePhoneCountryRule
      ];
    },
    EveningPhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.EveningPhone,
        ...this.EveningPhoneCountryRule
      ];
    },
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/myProfile"].data.title, this.$str('MyProfile') );
    }
  },
  watch: {
    accountFieldsLoaded(loaded) {
      if (loaded) {
        // TODO: I don't think we need to listen to this... we just need
        // to make sure these fields are loaded before populating with player info
      }
    },
    fields: {
      handler() {
        // Take note whenever any fields have changed so we can enable the 'save changes' button
        if (!this.loading) this.hasChanges = true;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("dialogs", []),
    close() {
      window.LobbyCore.raise(window.LobbyCore.Events.closeMyProfilePage);
    },
    fieldComponent(field) {
      if (field.field === "Birthdate") {
        return "v-date-picker";
      } else if (field.dropDown || field.field === "State") {
        return "v-select";
      } else {
        return "v-text-field";
      }
    },
    getItems(field) {
      return field.getDropdownValues(this.fields.Country);
    },
    isShown(field) {
      return !field.hidden;
    },
    getFieldClass(field) {
      if (field?.required?.value) {
        return "required";
      }
      return "";
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$refs.form?.reset();
    },
    async refreshEmailVerified() {
      try {
        this.isEmailVerified = await CDK.GetPlayerEmailVerification(
          this.fields.EMailAddress
        );
      } catch (error) {
        this.isEmailVerified = null;
        window.console.warn(
          `Unable to retrieve email verification status, error: ${error.Message}`
        );
      }
    },
    async loadPlayerInfo() {
      try {
        const result = await CDK.GetPlayerSignupInfo(this.playerPid);
        const playerInfo = result.Value;

        // convert gender to a key to match the gender dropdown values
        // ex: [{"Key":"Male","Value":"1"},{"Key":"Female","Value":"0"}]
        const genderValues = this.accountCreateFields.gender.getDropdownValues(
          this.fields.Country
        );

        const genderKey =
          playerInfo.Gender.toLowerCase() === "unknown"
            ? "2"
            : genderValues.find(
                (gv) => gv.Key.toLowerCase() === playerInfo.Gender.toLowerCase()
              )?.Value || null;

        // strip time from birthdate to match expected format for control
        const birthdateOnly = playerInfo.Birthdate.split("T")[0];

        this.fields.Login = playerInfo.Login;
        this.fields.FirstName = playerInfo.Firstname;
        this.fields.LastName = playerInfo.Lastname;
        this.fields.AddressLine1 = playerInfo.Address1;
        this.fields.AddressLine2 = playerInfo.Address2;
        this.fields.City = playerInfo.City;
        this.fields.State = playerInfo.State;
        this.fields.Zip = playerInfo.Zip;
        this.fields.Country = playerInfo.Country;
        this.fields.EMailAddress = playerInfo.Email;
        this.fields.DaytimePhone = playerInfo.Dayphone;
        this.fields.EveningPhone = playerInfo.Evephone;
        this.fields.Birthdate = birthdateOnly;
        this.fields.cellPhone = playerInfo.Cellphone;
        this.fields.gender = genderKey;

        this.refreshEmailVerified();
      } catch (error) {
        // TODO: error popup?
        window.console.error(
          `Unable to retrieve player profile details. Error: ${error.Message}`
        );
        this.close();
      }
    },

    async populateForm() {
      await this.loadPlayerInfo();
      this.loading = false;
    },
    onHidden() {
      this.resetForm();
    },
    onShown() {
      this.resetForm();
      this.populateForm();
    },
    inputChanged() {
      this.hasChanges = true;
      return true;
    },
    async setStateFieldAndPhoneCodes() {
      this.fields.State = "";
      try {
        this.phoneCountryCode = await CDK.getPhoneCountryCode(
          this.fields.Country
        );
      } catch {
        this.phoneCountryCode = "";
      }

      if (this.phoneCountryCode !== "") {
        this.phoneCountryCode = "+" + this.phoneCountryCode + " ";
        this.fields.DaytimePhone = this.phoneCountryCode;
        this.fields.EveningPhone = this.phoneCountryCode;
        this.fields.cellPhone = this.phoneCountryCode;
        if (this.accountCreateFields.cellPhone.required.value) {
          this.CellPhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.cellPhone.required.errorString
          ];
        }
        if (this.accountCreateFields.DaytimePhone.required.value) {
          this.DaytimePhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.DaytimePhone.required.errorString
          ];
        }
        if (this.accountCreateFields.EveningPhone.required.value) {
          this.EveningPhoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.EveningPhone.required.errorString
          ];
        }
      }
    },
    validateForm() {
      this.$refs.form?.validate();

      // map field names to ref names
      const fieldRefs = [
        ["FirstName", "firstName"],
        ["LastName", "lastName"],
        ["EMailAddress", "emailAddress"],
        ["Login", "login"],
        ["AddressLine1", "addressLine1"],
        ["AddressLine2", "addressLine2"],
        ["City", "city"],
        ["Country", "country"],
        ["State", "state"],
        ["Zip", "zipCode"],
        ["Birthdate", "birthdate"],
        ["gender", "gender"],
        ["cellPhone", "cellPhone"],
        ["DaytimePhone", "dayTimePhone"],
        ["EveningPhone", "eveningPhone"]
      ];

      // Consider a field invalid if it is updateable and not valid
      // Don't prevent submitting the form if a non-updateable field is not valid, since the user can't fix that
      const invalidField = (field, ref) =>
        this.updateable(field) && this.$refs[ref] && !this.$refs[ref]?.valid;

      // Check to see if any fields are invalid
      let invalid = false;
      fieldRefs.forEach(fieldRef => {
        const fieldName = fieldRef[0];
        const refName = fieldRef[1];
        if (invalidField(fieldName, refName)) {
          window.console.warn(`invalid field: ${fieldName}`);
          invalid = true;
        }
      });

      if (invalid) {
        this.$refs.form?.validate();
        return false;
      }

      this.$refs.form?.resetValidation();
      return true;
    },
    async submit() {
      if (this.validateForm()) {
        this.saving = true;
        try {
          await CDK.UpdatePlayerSignupInfo(
            this.fields.FirstName,
            this.fields.LastName,
            this.fields.Birthdate,
            this.fields.gender,
            this.fields.AddressLine1,
            this.fields.AddressLine2,
            this.fields.City,
            this.fields.State,
            this.fields.Country,
            this.fields.Zip,
            this.fields.cellPhone,
            this.fields.DaytimePhone,
            this.fields.EveningPhone
          );
          this.close();
        } catch (error) {
          this.profileError = error.Message;
        }
        this.saving = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .v-input__append-inner .mdi-check.v-icon:not(.success--text) {
  display: none;
}

::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 2px !important;
}

::v-deep .v-input--selection-controls__input .v-icon {
  color: var(--v-popupTxt-base);
}

::v-deep *::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}

::v-deep *::-webkit-scrollbar-track {
  background: transparent !important;
  margin: 3px;
}

::v-deep *::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 0.5rem;
}
</style>
