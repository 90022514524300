/**
 * Determines the client type (mobile, instant play, download)
 */

import bowser from "bowser";

const getClientTypeFromUrl = () => {
  return new URL(document.location).searchParams.get("clientType");
};

/**
 * @values
 *   CDK.ClientTypes.Download (1),
 *   CDK.ClientTypes.InstantPlay (3),
 *   CDK.ClientTypes.Mobile (6)
 */
const getClientType = () => {
  // client type can be forced with query string param
  // ex: www.casino.com/lobby?clientType=6
  const forcedClientType = getClientTypeFromUrl();
  if (forcedClientType !== null) {
    return parseInt(forcedClientType);
  }

  // iPad safari 13 requests desktop site by default, so we
  // need to detect that and serve up the mobile site regardless
  // https://github.com/lancedikson/bowser/issues/329
  const browser = bowser.getParser(window.navigator.userAgent);
  const isMobile =
    browser.getPlatformType(true) === "mobile" ||
    browser.getPlatformType(true) === "tablet";
  const isMac = browser.getOSName(true) === "macos";
  const iPadInDesktopMode = isMac && navigator.maxTouchPoints > 2;
  if (window.cefRTG) {
    return CDK.ClientTypes.Download;
  } else if (isMobile || iPadInDesktopMode) {
    return CDK.ClientTypes.Mobile;
  } else {
    return CDK.ClientTypes.InstantPlay;
  }
};

export const clientType = getClientType();
