export default {
  computed: {
    $str() {
      return key => this.$store.getters["stringTable/getStringByKey"](key);
    },
    $strResourceService() {
      return key =>
        this.$store.getters["stringTable/getStringByKeyResourceService"](key);
    }
  },
  methods: {
    $cur(amount) {
      return CDK.isConnected() ? CDK.formatDisplayCurrency(amount) : amount;
    },
    $baseCur(amount) {
      return CDK.isConnected()
        ? CDK.formatInCasinoBaseCurrency(amount)
        : amount;
    },
    $num(amount, opt_decimalDigits) {
      return CDK.isConnected()
        ? CDK.formatInCasinoNumber(amount, opt_decimalDigits)
        : amount;
    },
    $storageCur(amount) {
      return CDK.isConnected() ? CDK.formatStorageCurrency(amount) : amount;
    },
    $decode(str) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(
        "<!doctype html><body>" + str,
        "text/html"
      );
      return dom.body.textContent;
    }
  }
};
