import {
  format,
  differenceInSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  subMinutes
} from "date-fns";
import { clientType } from "@/services/casino";
import {
  TournamentActions,
  TournamentStatus,
  TournamentMode,
  PayoutType
} from "@/services/casino";
import { tournaments } from "@/mixins/tournaments";
import Vue from "vue";

Vue.mixin(tournaments);

const state = {
  playerAlias: null, // NULL = no response yet from server, "" means not set by user yet
  playerTournamentServerPID: null, // NULL = no response yet from server, "" means not set by user yet
  tournaments: [],
  tournamentsFiltered: [],
  availableGames: [],
  selectedTournaments: "available",
  currentServerTime: null,
  serverTimeZone: null,
  displayServerTime: null,
  sortFilters: [
    { property: "startDate", direction: "ASC" },
    { property: "status", direction: "ASC" }
  ],
  tournamentFilters: null,
  showTournamentId: null,
  tournamentsLoaded: false, // false until the player has an alias and tournament data is received
  convertToBaseCurrency: false,
  tournamentsCurrencyFormat: "",
  customCurrencySymbolIsEnabledInTournament: false,
  couponPrizes: []
};

const getters = {
  connected: state => state.playerAlias !== null,
  isVisibleInNavBar: (state, getters, rootState, rootGetters) => {
    const item = rootGetters["lobbyMenus/navBarItemByName"]("Tournaments");
    if (!item) {
      return false;
    }

    const loggedIn = rootGetters["session/loggedIn"];
    return (loggedIn && item.AfterLogin) || (!loggedIn && item.PriorToLogin);
  },
  isVisibleInSandwichMenu: (state, getters, rootState, rootGetters) => {
    const item = rootGetters["lobbyMenus/sandwichMenuItemByName"](
      "Tournaments"
    );
    if (!item) {
      return false;
    }

    const loggedIn = rootGetters["session/loggedIn"];
    return (loggedIn && item.AfterLogin) || (!loggedIn && item.PriorToLogin);
  },
  isVisibleInGamesBar: (state, getters, rootState, rootGetters) => {
    const item = rootGetters["games/menuItemByName"]("Tournaments");
    if (!item) {
      return false;
    }

    const loggedIn = rootGetters["session/loggedIn"];
    return loggedIn;
  },
  isActive: (state, getters, rootGetters) => {
    const isMobile = rootGetters.session.clientType === CDK.ClientTypes.Mobile;
    return isMobile
      ? getters.isVisibleInNavBar || getters.isVisibleInSandwichMenu
      : getters.isVisibleInGamesBar || getters.isVisibleInSandwichMenu;
  },
  displayName: (state, getters, rootState, rootGetters) => {
    const item = rootGetters["lobbyMenus/navBarItemByName"]("Tournaments");
    return item?.DisplayName ?? "Tournaments";
  },
  playerAlias: state => state.playerAlias,
  playerTournamentServerPID: state => state.playerTournamentServerPID,
  tournaments: state => state.tournaments,
  tournamentsFiltered: state =>
    getSortedAndFilteredTournaments(
      [...state.tournaments],
      state.tournamentFilters,
      state.sortFilters
    ),
  availableGames: state => getAvailableGames(state.tournaments),
  selectedTournaments: state => state.selectedTournaments,
  currentServerTime: state => state.currentServerTime,
  serverTimeZone: state => state.serverTimeZone,
  displayServerTime: state => state.displayServerTime,
  sortFilters: state => state.sortFilters,
  tournamentFilters: state => state.tournamentFilters,
  hasEnrolledTournaments: state =>
    state.playerAlias && state.tournaments.some(t => t.IsPlayerRegistered),
  showTournamentId: state => state.showTournamentId,
  tournamentsLoaded: state => state.tournamentsLoaded,
  convertToBaseCurrency: state => state.convertToBaseCurrency,
  tournamentsCurrencyFormat: state => state.tournamentsCurrencyFormat,
  customCurrencySymbolIsEnabledInTournament: state =>
    state.customCurrencySymbolIsEnabledInTournament,
  couponPrizes: state => state.couponPrizes
};

const getAvailableGames = tournaments => {
  const gamesList = tournaments.map(tournament => tournament.GameName);
  return [...new Set(gamesList)].sort();
};

const getSortedAndFilteredTournaments = (tournaments, filters, sortFilters) => {
  if (filters) {
    const queryFilters = getQueryFilters(filters);
    const filterKeys = Object.keys(queryFilters);
    const tournamentsFiltered = tournaments.filter(tournament => {
      // validates all filter criteria
      return filterKeys.every(key => {
        // ignores non-function predicates
        if (typeof queryFilters[key] !== "function") return true;
        return queryFilters[key](tournament[key]);
      });
    });
    return getSortAndSplittedTournaments([...tournamentsFiltered], sortFilters);
  }

  return getSortAndSplittedTournaments([...tournaments], sortFilters);
};

const getSortAndSplittedTournaments = (tournaments, sortFilters) => {
  const completedTournamentsStatus = [5, 6];

  return [
    ...tournaments
      .filter(
        tournament => !completedTournamentsStatus.includes(tournament.StatusId)
      )
      .sort(dynamicSortMultiple(sortFilters)),
    ...tournaments
      .filter(tournament =>
        completedTournamentsStatus.includes(tournament.StatusId)
      )
      .sort(dynamicSortMultiple(sortFilters))
  ];
};

const getQueryFilters = filters => {
  const queryFilters = {};
  if (filters.buyInRange)
    queryFilters.buyIn = buyIn =>
      filters.isFreeRolls
        ? buyIn === 0
        : buyIn >= filters.buyInRange[0] && buyIn <= filters.buyInRange[1];
  if (filters.isAddOnSelected)
    queryFilters.isAddOnEnabled = isAddOnEnabled =>
      isAddOnEnabled === filters.isAddOnSelected;
  if (filters.isReBuySelected)
    queryFilters.isReBuyEnabled = isReBuyEnabled =>
      isReBuyEnabled === filters.isReBuySelected;
  if (filters.isRestrictedEnabled && filters.isRestrictedSelected)
    queryFilters.restricted = restricted =>
      restricted === filters.isRestrictedSelected;
  if (filters.statusSelected && filters.statusSelected.length > 0)
    queryFilters.StatusId = StatusId =>
      filters.statusSelected.includes(StatusId);
  if (filters.selectedGames && filters.selectedGames.length > 0)
    queryFilters.GameName = GameName =>
      filters.selectedGames.includes(GameName);
  if (filters.modeSelected && filters.modeSelected.length > 0)
    queryFilters.Mode = Mode => filters.modeSelected.includes(Mode);
  if (filters.payoutSelected && filters.payoutSelected.length > 0)
    queryFilters.PayoutType = PayoutType =>
      filters.payoutSelected.includes(PayoutType);
  if (filters.isEnrolledSelected) {
    queryFilters.IsPlayerRegistered = IsPlayerRegistered =>
      IsPlayerRegistered === filters.isEnrolledSelected;
  }
  return queryFilters;
};

const canPlay = details =>
  details.PlayerDetails.IsOpenPlayTime && details.PlayerDetails.LocalGameExists;

const addTournamentHelpers = (tournament, context) => {
  const getString = context.rootGetters["stringTable/getStringByKey"];
  const getStringResourceService =
    context.rootGetters["stringTable/getStringByKeyResourceService"];

  Object.defineProperties(tournament, {
    buyIn: {
      get: function() {
        return this.InitialEntryFee + this.InitialRake;
      }
    },
    reBuy: {
      get: function() {
        return this.ReBuyFee + this.ReBuyRake;
      }
    },
    addOn: {
      get: function() {
        return tournament.AddOnFee + tournament.AddOnRake;
      }
    },
    name: {
      get: function() {
        return this.TournamentName;
      }
    },
    enrolledPlayers: {
      get: function() {
        return `${this.RegisteredPlayers}/${this.MaxPlayers}`;
      }
    },
    prize: {
      get: function() {
        return this.TotalPrizePool;
      }
    },
    mode: {
      get: function() {
        return this.Mode === TournamentMode.Points
          ? getString("Points")
          : getString("Credits");
      }
    },
    status: {
      get: function() {
        switch (this.StatusId) {
          case 1:
            return getString("StatusNotAnnounced");
          case 2:
            return getString("StatusAnnounced");
          case 3:
            return getString("StatusRegistering");
          case 4:
            return getString("StatusRunning");
          case 5:
          case 6:
            return getString("StatusCompleted");
          case 7:
            return getString("StatusCancelled");
          default:
            return getString("StatusUpdating");
        }
      }
    },
    startDate: {
      get: function() {
        return format(
          new Date(this.TournamentStartTimeUnixEpoch),
          "MM/dd HH:mm"
        );
      }
    },
    endDate: {
      get: function() {
        return format(new Date(this.TournamentEndTimeUnixEpoch), "MM/dd HH:mm");
      }
    },
    restricted: {
      get: function() {
        return this.PasswordProtected === "Y";
      }
    },
    payoutType: {
      get: function() {
        return this.PayoutType === PayoutType.Deposit
          ? getStringResourceService("CashPrize")
          : getStringResourceService("Coupon");
      }
    },
    actions: {
      get: function() {
        const actions = {};

        //Always have Buy-In
        actions[TournamentActions.BuyIn] = {
          nameKey: "buyIn",
          confirmationTitleKey: "BuyInConfirmationTitle",
          successKey: "tournamentBuyInSuccess",
          details: {
            Available: {
              stringKey: "DetailViewAvailable",
              value: `${this.IsPlayerRegistered ? 0 : 1}/1`
            },
            Credits: { stringKey: "Credits", value: this.Credits },
            Cost: { stringKey: "DetailViewCost", value: this.buyIn },
            Time: {
              stringKey: "DetailViewTime",
              value:
                this.PlayTimeMinutes > 0
                  ? `${this.PlayTimeMinutes} ${context.rootGetters[
                      "stringTable/getStringByKey"
                    ]("Minutes")}`
                  : `${context.rootGetters["stringTable/getStringByKey"](
                      "Unlimited"
                    )}`
            }
          },
          enabled: this.buyInEnabled,
          playEnabled:
            this.playEnabled &&
            !this.DetailData.PlayerDetails.AddOnClaimed &&
            !this.DetailData.PlayerDetails.RebuyClaimed
        };

        if (this.isAddOnEnabled) {
          actions[TournamentActions.AddOn] = {
            nameKey: "TournamentAddOn",
            confirmationTitleKey: "AddOnConfirmationTitle",
            successKey: "tournamentAddOnSuccess",
            details: {
              Available: {
                stringKey: "DetailViewAvailable",
                value:
                  this.MaxAddOn >= 32767
                    ? `${context.rootGetters["stringTable/getStringByKey"](
                        "Unlimited"
                      )}`
                    : `${this.MaxAddOn -
                        this.DetailData.PlayerDetails.AddOnClaimed}/${
                        this.MaxAddOn
                      }`
              },
              Credits: { stringKey: "Credits", value: this.AddOnCredits },
              Cost: { stringKey: "DetailViewCost", value: this.addOn },
              Time: {
                stringKey: "DetailViewTime",
                value:
                  this.AddOnMinutes > 0
                    ? `${this.AddOnMinutes} ${context.rootGetters[
                        "stringTable/getStringByKey"
                      ]("Minutes")}`
                    : `${context.rootGetters["stringTable/getStringByKey"](
                        "Unlimited"
                      )}`
              }
            },
            enabled: this.addOnEnabled,
            playEnabled:
              this.playEnabled && this.DetailData.PlayerDetails.AddOnClaimed
          };
        }

        if (this.isReBuyEnabled) {
          actions[TournamentActions.ReBuy] = {
            nameKey: "TournamentReBuy",
            confirmationTitleKey: "ReBuyConfirmationTitle",
            successKey: "tournamentReBuySuccess",
            details: {
              Available: {
                stringKey: "DetailViewAvailable",
                value:
                  this.MaxReBuy >= 32767
                    ? `${context.rootGetters["stringTable/getStringByKey"](
                        "Unlimited"
                      )}`
                    : `${this.MaxReBuy -
                        this.DetailData.PlayerDetails.RebuyClaimed}/${
                        this.MaxReBuy
                      }`
              },
              Credits: { stringKey: "Credits", value: this.ReBuyCredits },
              Cost: { stringKey: "DetailViewCost", value: this.reBuy },
              Time: {
                stringKey: "DetailViewTime",
                value: this.ReBuyMinutes
                  ? `${this.ReBuyMinutes} ${context.rootGetters[
                      "stringTable/getStringByKey"
                    ]("Minutes")}`
                  : `${context.rootGetters["stringTable/getStringByKey"](
                      "Unlimited"
                    )}`
              }
            },
            enabled: this.reBuyEnabled,
            playEnabled:
              this.playEnabled && this.DetailData.PlayerDetails.RebuyClaimed
          };
        }

        if (this.Mode === TournamentMode.Credits) {
          actions[TournamentActions.BuyIn].details.CreditsQualify = {
            stringKey: "CreditsToQualify",
            value: this.BuyRequirementAmount
          };

          if (actions[TournamentActions.AddOn]) {
            actions[TournamentActions.AddOn].details.CreditsQualify = {
              stringKey: "CreditsToQualify",
              value: this.AddOnRequirementAmount
            };
          }

          if (actions[TournamentActions.ReBuy]) {
            actions[TournamentActions.ReBuy].details.CreditsQualify = {
              stringKey: "CreditsToQualify",
              value: this.ReBuyRequirementAmount
            };
          }
        }

        return actions;
      }
    },
    detailsGrid: {
      get: function() {
        return {
          Players: {
            stringKey: "",
            value: `${this.RegisteredPlayers}/${this.MaxPlayers}`
          },
          ID: { stringKey: "ID", value: this.TournamentId },
          Status: { stringKey: "DetailViewStatus", value: this.status },
          "Prize Pool": {
            stringKey: "DetailViewPrizePool",
            value: this.TotalPrizePool
          },
          Starts: {
            stringKey: "DetailViewStarts",
            value: format(
              new Date(this.TournamentStartTimeUnixEpoch),
              "MM/dd HH:mm"
            )
          },
          Ends: {
            stringKey: "DetailViewEnds",
            value: format(
              new Date(this.TournamentEndTimeUnixEpoch),
              "MM/dd HH:mm"
            )
          },
          PayoutType: {
            stringKey: "Payout",
            value: this.payoutType
          },
          Mode: {
            stringKey: "Mode",
            value: this.mode
          }
        };
      }
    },
    duration: {
      get: function() {
        const end = new Date(this.TournamentEndDate);
        const start = new Date(this.TournamentStartDate);
        const days = differenceInDays(end, start);
        const hours = differenceInHours(end, start) - days * 24;
        const minutes =
          differenceInMinutes(end, start) - days * 1440 - hours * 60;

        return (
          (days > 0 ? days + (hours > 0 || minutes > 0 ? "d," : "d") : "") +
          (hours > 0 ? hours + "h" : "") +
          (minutes > 0 ? (hours > 0 ? ":" : "") + minutes + "m" : "")
        );
      }
    },
    playTime: {
      get: function() {
        return this.PlayTimeMinutes;
      }
    },
    registrationEndDate: {
      get: function() {
        return subMinutes(
          new Date(this.TournamentEndTimeUnixEpoch),
          this.PlayTimeMinutes
        );
      }
    },
    credits: {
      get: function() {
        return this.Credits;
      }
    },
    gameUniqueId: {
      get: function() {
        if (this.restricted) {
          return "";
        }

        return `${this.GameId}-${this.MachId}-0`;
      }
    },
    game: {
      get: function() {
        if (this.restricted) {
          return null;
        }

        return context.rootGetters["games/games"][this.gameUniqueId];
      }
    },
    imageURL: {
      get: function() {
        if (this.restricted) {
          return "";
        }

        return this.DetailData.Details.GameImageURL;
      }
    },
    buyInEnabled: {
      get: function() {
        if (
          this.DetailData.PlayerDetails.IsPlayerRegistered ||
          (this.isRebuyEnabled && this.RebuyClaimed > 0)
        )
          return false;
        return this.DetailData.PlayerDetails.IsOpenRegistration;
      }
    },
    playEnabled: {
      get: function() {
        if (this.DetailData.PlayerDetails.IsPlayerRegistered)
          return canPlay(this.DetailData);
        return false;
      }
    },
    addOnEnabled: {
      get: function() {
        if (this.DetailData.Details.StatusId !== TournamentStatus.Running)
          return false;
        if (
          ((canPlay(this.DetailData) &&
            this.DetailData.Details.Mode === TournamentMode.Credits) ||
            !canPlay(this.DetailData)) &&
          this.DetailData.PlayerDetails.IsPlayerRegistered &&
          this.DetailData.PlayerDetails.isAddOnEnabled &&
          this.DetailData.PlayerDetails.AddOnClaimed <
            this.DetailData.PlayerDetails.MaxAddOn
        ) {
          return true;
        }
        if (
          canPlay(this.DetailData) &&
          !this.DetailData.PlayerDetails.IsPlayerRegistered &&
          this.DetailData.PlayerDetails.AddOnClaimed > 0 &&
          this.DetailData.PlayerDetails.LastSpin !== 0 &&
          this.DetailData.PlayerDetails.StartDate === null &&
          this.DetailData.PlayerDetails.EndDate === null
        ) {
          return true;
        }
        return false;
      }
    },
    reBuyEnabled: {
      get: function() {
        if (this.DetailData.Details.StatusId !== TournamentStatus.Running)
          return false;
        if (
          ((canPlay(this.DetailData) &&
            this.DetailData.Details.Mode === TournamentMode.Credits) ||
            !canPlay(this.DetailData)) &&
          this.DetailData.PlayerDetails.IsPlayerRegistered &&
          this.DetailData.PlayerDetails.isRebuyEnabled &&
          this.DetailData.PlayerDetails.RebuyClaimed <
            this.DetailData.PlayerDetails.MaxRebuys
        ) {
          return true;
        }

        if (
          canPlay(this.DetailData) &&
          !this.DetailData.PlayerDetails.IsPlayerRegistered &&
          this.DetailData.PlayerDetails.RebuyClaimed > 0 &&
          this.DetailData.PlayerDetails.LastSpin === 0 &&
          this.DetailData.PlayerDetails.StartDate === null &&
          this.DetailData.PlayerDetails.EndDate === null
        ) {
          return true;
        }

        return false;
      }
    },
    refundEnabled: {
      get: function() {
        return (
          this.DetailData.PlayerDetails.IsPlayerRegistered &&
          !this.DetailData.PlayerDetails.AddOnClaimed &&
          !this.DetailData.PlayerDetails.RebuyClaimed &&
          this.DetailData.PlayerDetails.IsOpenRegistration &&
          this.DetailData.PlayerDetails.LastSpin === 0
        );
      }
    },
    playerRank: {
      get: function() {
        return this.DetailData.PlayerDetails.Position;
      }
    },
    playerScore: {
      get: function() {
        return this.DetailData.PlayerDetails.CurrentScore;
      }
    },
    statusTimingText: {
      get: function() {
        if (this.StatusId === TournamentStatus.Running) {
          return calculateDuration(
            context.state.currentServerTime,
            this.TournamentEndTimeUnixEpoch
          );
        } else if (this.StatusId === TournamentStatus.Announced) {
          return calculateDuration(
            context.state.currentServerTime,
            this.RegistrationTimeUnixEpoch
          );
        } else if (this.StatusId === TournamentStatus.Registering) {
          return calculateDuration(
            context.state.currentServerTime,
            this.TournamentStartTimeUnixEpoch
          );
        } else {
          return "";
        }
      }
    },
    statusTimingLabelText: {
      get: function() {
        const getString = context.rootGetters["stringTable/getStringByKey"];

        if (this.StatusId === TournamentStatus.Running) {
          return getString("TournamentsTourEndIn");
        } else if (this.StatusId === TournamentStatus.Announced) {
          return getString("TournamentsRegStartIn");
        } else if (this.StatusId === TournamentStatus.Registering) {
          return getString("TournamentsTourStartIn");
        } else {
          return "";
        }
      }
    }
  });
};

const addNotificationTournamentHelpers = (tournament, context) => {
  Object.defineProperties(tournament, {
    name: {
      get: function() {
        return this.Tournament.TournamentName;
      }
    },
    restricted: {
      get: function() {
        return this.Tournament.PasswordProtected === "Y";
      }
    },
    startDate: {
      get: function() {
        return format(
          new Date(this.Tournament.TournamentStartTimeUnixEpoch),
          "MM/dd HH:mm"
        );
      }
    },
    gameUniqueId: {
      get: function() {
        if (this.restricted) {
          return "";
        }

        return `${this.Tournament.GameId}-${this.Tournament.MachId}-0`;
      }
    },
    game: {
      get: function() {
        if (this.restricted) {
          return null;
        }

        return context.rootGetters["games/games"][this.gameUniqueId];
      }
    },
    imageURL: {
      get: function() {
        if (this.restricted) {
          return "";
        }

        return this.game.imageURL;
      }
    },
    TournamentId: {
      get: function() {
        return this.Tournament.TournamentId;
      }
    },
    Mode: {
      get: function() {
        return this.Tournament.Mode;
      }
    }
  });
};

const RegisterStatus = {
  None: 0,
  Succeeded: 1,
  TournamentFull: 2,
  FailedException: 3,
  Failed: 4
};

const GameStatus = {
  Completed: 0,
  Unregister: 1,
  Cancelled: 2,
  UnableToRegister: 3
};

const RegisterStatusMessageKeys = {
  [RegisterStatus.None]: "tournamentSystemError",
  [RegisterStatus.Succeeded]: "",
  [RegisterStatus.TournamentFull]: "tournamentFullError",
  [RegisterStatus.FailedException]: "tournamentSystemError",
  [RegisterStatus.Failed]: "tournamentSystemError"
};

const getRegisterStatusMessage = function(context, registerStatus) {
  const messageKey = RegisterStatusMessageKeys[registerStatus];
  if (messageKey) {
    return context.rootGetters["stringTable/getStringByKey"](messageKey);
  }
  return "";
};
const zeroPadding = function(num, digit) {
  let zero = "";
  for (let i = 0; i < digit; i++) {
    zero += "0";
  }
  return (zero + num).slice(-digit);
};

const processRegisterStatus = function(context, result, registerStatus = 0) {
  if (registerStatus !== RegisterStatus.Succeeded) {
    result.HasErrors = true;
    result.ErrorCode = registerStatus;
    result.Message = getRegisterStatusMessage(context, registerStatus);
  }

  return result;
};
const dynamicSortedData = function(property, sortDirection) {
  return function(obj1, obj2) {
    if (sortDirection === "DESC") {
      return obj1[property] > obj2[property]
        ? -1
        : obj1[property] < obj2[property]
        ? 1
        : 0;
    }
    return obj1[property] > obj2[property]
      ? 1
      : obj1[property] < obj2[property]
      ? -1
      : 0;
  };
};

const dynamicSortMultiple = sortFilters => {
  return function(obj1, obj2) {
    let i = 0,
      result = 0;
    const numberOfProperties = sortFilters.length;
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSortedData(
        sortFilters[i].property,
        sortFilters[i].direction
      )(obj1, obj2);
      i++;
    }
    return result;
  };
};

const calculateDuration = (start, end) => {
  if (start > end) return "";
  const days = differenceInDays(end, start);
  const hours = differenceInHours(end, start) - days * 24;
  const minutes = differenceInMinutes(end, start) - days * 1440 - hours * 60;
  const seconds =
    differenceInSeconds(end, start) -
    days * 86400 -
    hours * 3600 -
    minutes * 60;

  return (
    (days > 0 ? days + (hours > 0 || minutes > 0 ? "d," : "d") : "") +
    (hours > 0 ? hours + "h" : "") +
    (minutes > 0 ? (hours > 0 ? ":" : "") + minutes + "m" : "") +
    (seconds < 10 ? ":0" + seconds + "s" : ":" + seconds + "s")
  );
};

const alertError = (message, rootGetters) => {
  window.console.error(message);
  alert(
    rootGetters["stringTable/getStringByKey"]("TournamentsMaintenanceMessage")
  );
};

const actions = {
  ////////////////////////////////////////////////////////////////
  // initialize
  ////////////////////////////////////////////////////////////////
  async initialize(context) {
    CDK.on(CDK.Events.loggedIn, async () => {
      if (context.getters.isActive) {
        await CDK.setCasinoLocaleInfo();
        context.dispatch("requestCurrentServerTimeAndTimeZone");
        await context.dispatch("requestPlayerAlias");
        context.commit(
          "updateTournamentsCurrencyFormat",
          await CDK.getTournamentsCurrencyFormat()
        );

        context.commit(
          "updateCustomCurrencySymbolIsEnabledInTournament",
          await CDK.getIfCustomCurrencySymbolIsEnabledInTournament()
        );
        context.commit(
          "updateConvertToBaseCurrency",
          sessionStorage.getItem("tournamentsToggleToBaseCurrency") === "true"
            ? true
            : false
        );
        context.dispatch("requestTournamentCouponPrizes");
      }
    });

    CDK.on(CDK.Events.tournamentsStarted, tournaments => {
      const playForMoney = context.rootGetters["session/playForMoney"];
      if (tournaments.length && playForMoney) {
        tournaments.forEach(tournament =>
          addNotificationTournamentHelpers(tournament, context)
        );
        context.dispatch(
          "dialogs/showTournamentReminder",
          { tournaments },
          {
            root: true
          }
        );
      }
    });

    CDK.on(CDK.Events.loggedOut, () => {
      context.dispatch("resetState");
    });
  },
  resetState({ commit }) {
    commit("updatePlayerAlias", null);
    commit("updateTournamentServerPID", null);
    commit("updateTournaments", []);
    commit("updateTournamentServerTime", null);
    commit("updateTournamentServerTimeZone", null);
    commit("updateSelectedTournaments", "available");
    commit("updateTournamentsLoaded", false);
    commit("updateShowTournamentId", null);
    commit("updateTournamentsCurrencyFormat", "");
    commit("updateCustomCurrencySymbolIsEnabledInTournament", false);
    commit("updateCouponPrizes", []);
  },
  ////////////////////////////////////////////////////////////////
  // createPlayerAlias
  ////////////////////////////////////////////////////////////////
  async createPlayerAlias({ rootGetters, commit }, newAlias) {
    let success = false;
    const result = await CDK.createTournamentsPlayerAlias(newAlias);
    if (!result.HasErrors) {
      // Special result meaning failure
      if (result.Value !== "0       ") {
        commit("updatePlayerAlias", newAlias);
        commit("updateTournamentServerPID", result.Value);
        success = true;
      }
    } else {
      alertError(result.message, rootGetters);
    }
    return success;
  },
  async requestPlayerAlias({ rootGetters, commit }) {
    // Get player alias
    const result = await CDK.getTournamentsPlayerAlias();
    if (!result.HasErrors) {
      commit(
        "updatePlayerAlias",
        result.Value.Alias === null ? "" : result.Value.Alias
      );
      commit(
        "updateTournamentServerPID",
        result.Value.TournamentPID === null ? "" : result.Value.TournamentPID
      );
    } else {
      alertError(result.message, rootGetters);
    }
  },
  async requestTournaments(context) {
    const hasAlias = !!context.state.playerAlias;
    const result = await CDK.getLobbyTournamentsWithDetails();

    if (!result.HasErrors) {
      const tournaments = result.TournamentsList;

      // If the player does not have an alias we expect an empty result, so don't consider tournaments as loaded
      // until we either get results or our empty results came after the player had an alias
      if (tournaments.length > 0 || hasAlias) {
        context.commit("updateTournamentsLoaded", true);
      }

      tournaments.forEach(tournament => {
        addTournamentHelpers(tournament, context);
      });
      //tournaments.sort(dynamicSortMultiple(context.state.sortFilters));
      context.commit("updateTournaments", tournaments);
    } else {
      alertError(result.Message, context.rootGetters);
    }
  },
  async requestTournament(context, { tournamentId }) {
    const result = await CDK.getTournamentDetails(tournamentId);
    if (!result.HasErrors) {
      const details = result.Value;
      const tournament = context.state.tournaments.find(
        item => item.TournamentId === tournamentId
      );
      const updatedTournament = { ...tournament, DetailData: details };
      addTournamentHelpers(updatedTournament, context);
      context.commit("updateTournament", updatedTournament);
    } else {
      alertError(result.Message, context.rootGetters);
    }
  },
  async requestCurrentServerTimeAndTimeZone(context) {
    const result = await CDK.getCurrentTournamentsTimeAndTimezone();
    if (!result.HasErrors) {
      context.commit("updateTournamentServerTime", result.unixEpoch);
      context.commit(
        "updateTournamentServerTimeZone",
        result.timeZoneDescription
      );
    } else {
      alertError(result.Message, context.rootGetters);
    }
  },
  async unlockTournament({ dispatch }, { tournamentId, password }) {
    const result = await CDK.unlockTournament(tournamentId, password);
    if (!result.HasErrors && result.Value === true) {
      // Request list of tournaments again to get the unlocked data
      await dispatch("requestTournaments");
      return true;
    } else {
      return false;
    }
  },
  async registerTournamentBuyIn(context, { tournament }) {
    const result = await CDK.registerPlayerForTournament(
      tournament.TournamentId,
      CDK.getSessionID(),
      tournament.TournamentStartDateUtc
    );

    await context.dispatch("requestTournament", {
      tournamentId: tournament.TournamentId
    });

    return processRegisterStatus(context, result, result.Value?.RegisterStatus);
  },
  async launchTournamentGame(context, { tournament }) {
    // Creates session for tournament, required before launching game
    // TODO: Is there anything in the return value we need to validate?
    await CDK.joinPlayerForTournament(clientType, tournament.TournamentId);

    const isDownload = clientType === CDK.ClientTypes.Download;

    if (isDownload) {
      const tournamentURI = `${tournament.GameUrl}&tournament=1&tournamentId=${tournament.TournamentId}&tournamentMode=${tournament.Mode}&tournamentpid=${context.state.playerTournamentServerPID}`;
      window.location = tournamentURI;
      return;
    }

    await context.dispatch(
      "games/launchGame",
      {
        gameId: tournament.gameUniqueId,
        gameLauncherParams: {
          tournamentId: tournament.TournamentId,
          tournamentPid: context.state.playerTournamentServerPID,
          tournamentMode: tournament.Mode
        }
      },
      { root: true }
    );
  },
  async reBuyTournament(context, { tournament }) {
    const result = await CDK.reBuyTournament(
      tournament.TournamentId,
      CDK.getSessionID(),
      tournament.TournamentStartDateUtc
    );

    await context.dispatch("requestTournament", {
      tournamentId: tournament.TournamentId
    });

    return processRegisterStatus(context, result, result.Value?.ReBuyStatus);
  },
  async addOnTournament(context, { tournament }) {
    const result = await CDK.addOnTournament(
      tournament.TournamentId,
      CDK.getSessionID(),
      tournament.TournamentStartDateUtc
    );

    await context.dispatch("requestTournament", {
      tournamentId: tournament.TournamentId
    });

    return processRegisterStatus(context, result, result.Value?.AddOnStatus);
  },
  setSelectedTournaments({ commit, getters }, selectedTournaments) {
    const selected =
      selectedTournaments ||
      (getters.hasEnrolledTournaments ? "enrolled" : "available");

    commit("updateSelectedTournaments", selected);
  },
  async unregisterTournament(context, { tournament }) {
    const result = await CDK.unregisterPlayerForTournament(
      tournament.TournamentId,
      GameStatus.Unregister
    );

    await context.dispatch("requestTournaments");

    return processRegisterStatus(context, result, result.Value);
  },
  async refundAddOn(context, { tournament }) {
    const result = await CDK.refundTournamentAddOn(
      tournament.TournamentId,
      GameStatus.Unregister
    );

    await context.dispatch("requestTournament", {
      tournamentId: tournament.TournamentId
    });

    return processRegisterStatus(context, result, result.Value);
  },
  async refundReBuy(context, { tournament }) {
    const result = await CDK.refundTournamentReBuy(
      tournament.TournamentId,
      GameStatus.Unregister
    );

    await context.dispatch("requestTournament", {
      tournamentId: tournament.TournamentId
    });

    return processRegisterStatus(context, result, result.Value);
  },
  async dismissTournament(context, { tournament }) {
    await CDK.dismissTournamentStartedNotification(tournament);
  },
  updateTime(context) {
    const cd = new Date(context.state.currentServerTime);
    const displayTime =
      zeroPadding(cd.getHours(), 2) + ":" + zeroPadding(cd.getMinutes(), 2);
    const time = cd.setSeconds(cd.getSeconds() + 1);
    context.commit("updateTournamentServerTime", time);
    context.commit("updateServerDisplayTime", displayTime);
  },
  sortAndFilterTournaments(context, { filters, sortFilters }) {
    context.commit("updateSortFilters", sortFilters);
    context.commit("updateTournamentFilters", filters);
  },
  resetSortAndFilter(context) {
    context.commit("updateTournamentFilters", null);
    context.commit("updateSortFilters", [
      { property: "startDate", direction: "ASC" },
      { property: "status", direction: "ASC" }
    ]);
  },
  /*
   * Navigate to the tournaments view, if necessary, and then select the specified tournament
   */
  showTournament(context, { tournamentId }) {
    context.commit("updateShowTournamentId", tournamentId);
  },
  showTournamentDone(context) {
    context.commit("updateShowTournamentId", null);
  },
  switchToBaseCurrency(context, convertToBaseCurrency) {
    context.commit("updateConvertToBaseCurrency", convertToBaseCurrency);
  },
  async requestTournamentCouponPrizes(context) {
    const result = await CDK.getTournamentCouponPrizes();
    if (result) {
      context.commit("updateCouponPrizes", result);
    }
  }
};

const mutations = {
  updatePlayerAlias(state, alias) {
    state.playerAlias = alias;
  },
  updateTournamentServerPID(state, PID) {
    state.playerTournamentServerPID = PID;
  },
  updateTournaments(state, tournaments) {
    state.tournaments = tournaments;
  },
  updateTournament(state, tournament) {
    const index = state.tournaments.findIndex(
      item => item.TournamentId === tournament.TournamentId
    );
    if (index !== -1) state.tournaments.splice(index, 1, tournament);
  },
  updateSelectedTournaments(state, selectedTournaments) {
    state.selectedTournaments = selectedTournaments;
  },
  updateTournamentServerTime(state, serverTime) {
    state.currentServerTime = serverTime;
  },
  updateTournamentServerTimeZone(state, serverTimeZone) {
    state.serverTimeZone = serverTimeZone;
  },
  updateServerDisplayTime(state, displayTime) {
    state.displayServerTime = displayTime;
  },
  updateSortFilters(state, sortFilters) {
    state.sortFilters = sortFilters;
  },
  updateTournamentFilters(state, filters) {
    state.tournamentFilters = filters;
  },
  updateShowTournamentId(state, tournamentId) {
    state.showTournamentId = tournamentId;
  },
  updateTournamentsLoaded(state, loaded) {
    state.tournamentsLoaded = loaded;
  },
  updateConvertToBaseCurrency(state, convertToBaseCurrency) {
    state.convertToBaseCurrency = convertToBaseCurrency;
    sessionStorage.setItem(
      "tournamentsToggleToBaseCurrency",
      convertToBaseCurrency
    );
  },
  updateTournamentsCurrencyFormat(state, format) {
    state.tournamentsCurrencyFormat = format;
  },
  updateCustomCurrencySymbolIsEnabledInTournament(state, isEnable) {
    state.customCurrencySymbolIsEnabledInTournament = isEnable;
  },
  updateCouponPrizes(state, couponPrizes) {
    state.couponPrizes = couponPrizes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
