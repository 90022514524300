<template>
  <svg id="ss" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <line
      x1="40.79"
      y1="19.84"
      x2="24.79"
      y2="19.84"
      fill="none"
      :stroke="iconStroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <polyline
      points="36.09 24.57 40.81 19.85 36.09 15.1"
      fill="none"
      :stroke="iconStroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <polyline
      points="32.3 22.63 32.3 36.65 20.49 36.65"
      fill="none"
      :stroke="iconStroke"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <polyline
      points="7.82 3.64 20.3 11.35 20.3 45 7.2 36.66 7.19 3 32.29 3 32.3 16.86"
      fill="none"
      :stroke="iconStroke"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <line
      x1="16.38"
      y1="23.31"
      x2="16.38"
      y2="27.03"
      fill="none"
      :stroke="iconStroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
  props: {
    iconStroke: {
      type: String,
      default: "currentColor"
    },
    displayName: { type: String, default: "LogoutIcon" }
  }
};
</script>
