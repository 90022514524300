import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import accountFields from "./modules/accountFields";
import adminPlayerMessage from "./modules/adminPlayerMessage";
import balances from "./modules/balances";
import compPoints from "./modules/compPoints";
import connection from "./modules/connection";
import cashier from "./modules/cashier";
import coupons from "./modules/coupons";
import customizations from "./modules/customizations";
import dialogs from "./modules/dialogs";
import games from "./modules/games";
import lobbyComponents from "./modules/lobbyComponents";
import lobbyMenus from "./modules/lobbyMenus";
import messageCenter from "./modules/messageCenter";
import referFriend from "./modules/referFriend";
import marketingPanels from "./modules/marketingPanels";
import session from "./modules/session";
import stringTable from "./modules/stringTable";
import tournaments from "./modules/tournaments";
import externalLobbies from "./modules/externalLobbies";
import casinoJackpot from "./modules/casinoJackpot";
import evolutionGames from "./modules/evolutionGames";
import { casinoConnected } from "@/services/casino";
import { validateCache } from "@/services/vuexCache";

Vue.use(Vuex);

const state = {};

const getters = {};

const actions = {
  async connectToCasino(context) {
    context.dispatch("customizations/initialize");
    await casinoConnected;
    context.dispatch("externalLobbies/initialize");
    context.dispatch("accountFields/initialize");
    context.dispatch("adminPlayerMessage/initialize");
    context.dispatch("balances/initialize");
    context.dispatch("compPoints/initialize");
    context.dispatch("connection/initialize");
    context.dispatch("coupons/initialize");
    context.dispatch("cashier/initialize");
    context.dispatch("games/initialize");
    context.dispatch("lobbyComponents/initialize");
    context.dispatch("lobbyMenus/initialize");
    context.dispatch("messageCenter/initialize");
    context.dispatch("referFriend/initialize");
    context.dispatch("session/initialize");
    context.dispatch("stringTable/initialize");
    context.dispatch("marketingPanels/initialize");
    context.dispatch("tournaments/initialize");
    context.dispatch("evolutionGames/initialize");
    await context.dispatch("casinoJackpot/initialize");
  }
};

const mutations = {};

// Make sure we're on the same build and skin before allowing
// cached vuex data to be restored
validateCache();

// Cache games and marketingPanels stores in localstorage for fast reloads
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["games", "marketingPanels"],
  supportCircular: true
});

const store = new Vuex.Store({
  modules: {
    accountFields,
    adminPlayerMessage,
    balances,
    compPoints,
    connection,
    cashier,
    coupons,
    dialogs,
    games,
    lobbyComponents,
    lobbyMenus,
    messageCenter,
    referFriend,
    marketingPanels,
    session,
    stringTable,
    externalLobbies,
    customizations,
    tournaments,
    casinoJackpot,
    evolutionGames
  },
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexLocal.plugin]
});

export default store;
