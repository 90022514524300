<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" max-width="900">
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar color="transparent" @close="showDialog = false" />
      <v-card-text
        class="text--primary text-center pt-1 pb-3"
        style="font-size: 1.1rem"
        >{{ $str("PopupBlockerWarningCashierText") }}</v-card-text
      >

      <v-card-actions class="d-flex justify-space-around pb-7">
        <v-btn
          style="font-size: 1.1rem"
          min-width="30%"
          color="buttonSecondary buttonSecondaryTxt--text"
          @click="loadCashier"
          >{{ $str("PopupBlockerWarningLoadCashier") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "confirmLoadCashier"
    };
  },
  computed: {
    ...mapGetters("dialogs", ["confirmLoadCashier"])
  },
  methods: {
    loadCashier() {
      this.cashierWindow = window.open(
        "",
        "Cashier",
        `height=${this.confirmLoadCashier.data.height},width=${this.confirmLoadCashier.data.width}, scrollbars=no`
      );
      this.cashierWindow.location = this.confirmLoadCashier.data.url;
      this.showDialog = false;
    }
  }
};
</script>

<style scoped></style>
