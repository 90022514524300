<template>
  <form
    :id="formId"
    ref="hiddenForm"
    :target="uniqueId"
    class="hidden-deposit-form"
    :method="cashierOptions.CashierSettings.Method"
    :action="cashierOptions.CashierSettings.Url"
  >
    <input
      id="Sportsbook"
      name="Sportsbook"
      type="hidden"
      :value="
        cashierOptions.CashierSettings.PlayerSportsbook ||
          cashierOptions.CashierSettings.Sportsbook
      "
    />
    <input
      id="Action"
      name="Action"
      type="hidden"
      :value="cashierOptions.CashierSettings.Action"
    />
    <input
      id="RTG_PID"
      name="RTG_PID"
      type="hidden"
      :value="cashierOptions.RTGPID"
    />
    <input
      id="CasinoID"
      name="CasinoID"
      type="hidden"
      :value="cashierOptions.CasinoID"
    />
    <input
      id="SessionID"
      name="SessionID"
      type="hidden"
      :value="cashierOptions.CashierSessionID"
    />
    <input
      id="SkinID"
      name="SkinID"
      type="hidden"
      :value="cashierOptions.CashierSettings.SkinID"
    />
    <input
      id="HashedPassword"
      name="HashedPassword"
      type="hidden"
      :value="cashierOptions.HashedPassword"
    />
    <input
      id="Lang"
      name="Lang"
      type="hidden"
      :value="cashierOptions.LanguageCode"
    />
    <input
      id="RTGLogin"
      name="RTGLogin"
      type="hidden"
      :value="cashierOptions.RTGLogin"
    />
    <input
      id="RedirectURL"
      name="RedirectURL"
      type="hidden"
      :value="cashierOptions.CashierSettings.RedirectURL"
    />
  </form>
</template>

<script>
export default {
  props: {
    cashierOptions: { type: Object, required: true },
    uniqueId: { type: String, required: true }
  },
  computed: {
    formId() {
      return "cashier-form-" + this.uniqueId;
    }
  },
  methods: {
    submit() {
      this.$refs.hiddenForm.submit();
    }
  }
};
</script>
