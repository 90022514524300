<template>
  <div class="container px-5" style="height: fit-content;">
    <p class="my-0 mlvw-1 text-left recentwinners mb-5">
      {{ $str("RecentWinners") }}
    </p>
    <v-simple-table>
      <thead>
        <tr>
          <th>{{ $str("DateLabel") }}</th>
          <th>{{ $str("TournamentPlayer") }}</th>
          <th>{{ $str("AmountLabel") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(winner, index) in casinoJackpotWinners" :key="index">
          <td>
            {{ formatWonDate(winner.wonDate) }}<br />{{
              formatWonTime(winner.wonDate)
            }}
          </td>
          <td>{{ winner.casinoPlayerId }}</td>
          <td>{{ $cur(winner.Amount) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("casinoJackpot", ["casinoJackpotWinners"])
  },
  methods: {
    formatWonDate(wonDate) {
      const date = new Date(wonDate);
      return date.toLocaleDateString();
    },
    formatWonTime(wonDate) {
      const date = new Date(wonDate);
      return date.toLocaleTimeString();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: block;
  max-width: 440px;
}

.recentwinners {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.recentwinnersList {
  background: none !important;
  border: none;
  overflow-y: auto;
  overflow-x: visible;
  overscroll-behavior-x: none;
  overflow-y: visible;
  min-height: 35vh;
  max-height: 646px;
}

.winplayer:not(:first-child) {
  border-top: 1px solid rgb(134, 134, 134);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #08080880;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-scrollbar-track {
  margin-top: 100px;
  margin-bottom: 100px;
}

.theme--dark.v-data-table,
.theme--light.v-data-table {
  background-color: transparent;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(255, 255, 255, 0.5);
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: thin solid rgba(0, 0, 0, 0.5);
}
</style>
