<template>
  <v-dialog
    v-model="showDialog"
    overlay-opacity="0.9"
    content-class="detailDialog"
    max-width="370px"
  >
    <!-- v-if="showDialog" so we don't create the body of this until it is going to be shown -->
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar :title="gameName" @close="showDialog = false" />
      <v-container class="px-4 pt-0 pb-5">
        <v-container class="d-flex pa-0">
          <v-img
            :src="gameIcon(game)"
            :lazy-src="lazyLoadingImage"
            class="mr-6 gameDetailBA"
            style="border-radius: 6px;"
            :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
            :width="isSquareIcon ? '190px' : '150px'"
            :max-width="isSquareIcon ? '190px' : '150px'"
            :height="isSquareIcon ? '143px' : '200px'"
            @click="launchGameForReal"
          >
            <div
              v-if="jackpot"
              class="game-jackpot-container"
              :style="jackpotContainerStyle"
            >
              <div class="game-jackpot-background"></div>
              <div class="game-jackpot-text">
                {{ $cur(jackpot) }}
              </div>
            </div>
          </v-img>
          <!-- eslint-disable vue/no-v-html -->
          <div class="game-description" v-html="gameDescription" />
        </v-container>
        <v-container class="mt-4 pa-0 d-flex" style="gap: 10px;" width="100%">
          <!-- The favorites/try it button should grow to fill extra space -->
          <v-btn
            v-if="loggedIn"
            class="flex-grow-1"
            width="190"
            color="buttonSecondary buttonSecondaryTxt--text"
            @click="toggleFavorite"
            ><v-icon v-show="!isFavorite" small class="mr-2">$StarEmpty</v-icon>
            <v-icon v-show="isFavorite" small class="mr-2">$StarFilled</v-icon>
            <span v-show="!isFavorite">{{ $str("AddToFavorites") }}</span>
            <span v-show="isFavorite">{{ $str("RemoveFromFavorites") }}</span>
          </v-btn>
          <v-btn
            v-else
            class="flex-grow-1"
            color="buttonSecondary buttonSecondaryTxt--text"
            @click="launchGameAnonymously"
            >{{ $str("TryItButton") }}</v-btn
          >

          <!-- The play button should shrink to make room if the favorites button is too long -->
          <v-btn
            width="190px"
            class="flex-shrink-1"
            color="button buttonTxt--text"
            @click="launchGameForReal"
            ><v-icon small class="mr-2">$Play</v-icon>{{ $str("Play") }}</v-btn
          >
        </v-container>
      </v-container>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { toggleFavorite } from "@/mixins/toggleFavorite";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle, toggleFavorite],
  data() {
    return {
      name: "gameDetail"
    };
  },
  computed: {
    ...mapGetters("coupons", ["activeCoupon"]),
    ...mapGetters("games", ["games", "gameJackpots", "gameIcon"]),
    ...mapGetters("session", ["loggedIn"]),
    ...mapGetters("customizations", ["lazyLoadingImage", "lobbyIconType"]),
    gameId() {
      return this.$store.getters["dialogs/gameDetail"].data.id;
    },
    game() {
      return this.games[this.gameId];
    },
    jackpot() {
      return this.gameJackpots[this.gameId];
    },
    jackpotContainerStyle() {
      return {
        fontSize: "13px",
        lineHeight: "normal",
        height: "25px",
        top: "175px",
        position: "relative"
      };
    },
    gameName() {
      return this.game?.name;
    },
    gameDescription() {
      // The game descriptions are somewhat hacked to display properly in the new layout
      let description = this.game?.description;

      // Some slots games combine the first 2 lines into a single line in order
      // to fit in the previous layout. We will detect this and replace the "-"
      // with a line break, so it will look OK in our taller layout.
      // Ex: "Volatility: VERY HIGH - Theme: Arabian Nights" becomes
      //     "Volatility: VERY HIGH<br>Theme: Arabian Nights"
      description = description?.replace(" - ", "<br>");

      // The new layout also calls for more spacing between paragraphs, but the
      // text is not structured as paragraphs. To accomplish this, we will replace
      // all instances of "<br>" with "<br><br>" to add an extra line break
      description = description?.replaceAll("<br>", "<br><br>");

      return description;
    },
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    ...mapActions("dialogs", ["showChooseMultiGame"]),
    launchGameAnonymously() {
      this.showDialog = false;
      this.$store.dispatch("games/launchGame", { gameId: this.gameId });
    },
    async launchGameForReal() {
      this.showDialog = false;
      if (this.activeCoupon && this.activeCoupon.ShowMultipleGames) {
        const confirmed = await this.$refs.confirm.open({
          title: this.$str("Confirmation"),
          message: this.$strResourceService(
            "CouponFSMultiGamesConfirmMsg"
          ).replace("<Game Name>", this.gameName)
        });
        if (!confirmed) return;
        const matchingGame = this.activeCoupon.MultiGamesSettingList.find(
          child =>
            child.gameid === this.game.gameId &&
            child.machid === this.game.machId
        );
        this.$store.dispatch("coupons/selectMultiGameRedeemedCoupon", {
          multiFreeSpinSettingId: matchingGame?.couponMultiGameId.toString()
        });
      }
      this.$store.dispatch("games/launchGame", {
        gameId: this.gameId,
        externalLobbyId: this.game.externalLobbyId,
        forceReal: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) and (orientation: landscape) {
  .gameDetailBA {
    height: 150px !important;
    max-width: 113px !important;
    width: 113px !important;
  }
}

.v-image {
  position: relative;
  display: block;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.game-description {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;

  /* Offset slightly so the line-height doesn't create a margin at the top */
  margin-top: -3px;
}

.game-jackpot-text {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.4px;
}
</style>
