import { render, staticRenderFns } from "./MobileMarketingPanel.vue?vue&type=template&id=6630ceec&scoped=true&"
import script from "./MobileMarketingPanel.vue?vue&type=script&lang=js&"
export * from "./MobileMarketingPanel.vue?vue&type=script&lang=js&"
import style0 from "./MobileMarketingPanel.vue?vue&type=style&index=0&id=6630ceec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6630ceec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VContainer})
