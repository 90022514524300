export default {
  methods: {
    $displayDepositMethodName(methodName, methodListCount) {
      if(methodListCount === 1) {
        return methodName === null || methodName === "Default" ? this.$strResourceService("AllDepositMethods") : this.$formatDepositMethodName(methodName);
      } else {
        return methodName === null || methodName === "Default" ? this.$strResourceService("AllOtherDepositMethods") : this.$formatDepositMethodName(methodName);
      }
    },
    $formatDepositMethodName(methodName) {
      if (methodName && (methodName.startsWith("DF") || methodName.startsWith("TAG"))) {
        return methodName.substring(methodName.indexOf("-", 0) + 1).trimStart();
      }
      return methodName;
    }
  }
};