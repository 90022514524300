<template>
  <v-card color="tournamentSuccess--text tournamentDetail">
    <v-card-title class="tournamentSuccess--text justify-center">
      <h4>{{ $str("CreateAliasTitle") }}</h4>
      <v-icon
        id="closeAlias"
        color="tournamentSuccess"
        x-large
        @click.stop="goHome"
      >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text
      ><v-container class="tournamentDetailTxt--text caption text-center"
        ><v-row justify="center" class="mb-2">
          {{ $str("CreateAliasMainText1") }}
        </v-row>
        <v-row justify="center">
          {{ $str("CreateAliasMainText2") }}
        </v-row></v-container
      ></v-card-text
    >
    <v-form v-model="isValid">
      <v-container class="py-0 my-0"
        ><v-row justify="center"
          ><v-col cols="8"
            ><v-text-field
              v-model="alias"
              class="required"
              dense
              outlined
              light
              :rules="[rules.minlength]"
              :error-messages="aliasErrors"
              :placeholder="$str('CreateAliasSelectMsg')"
              @input="onAliasChanged"
            ></v-text-field></v-col></v-row
      ></v-container>
    </v-form>
    <v-card-actions
      ><v-container class="pa-8 pt-0"
        ><v-row justify="space-around"
          ><v-btn
            to="/"
            width="45%"
            color="buttonSecondary buttonSecondaryTxt--text"
            @click="goHome"
            >{{ $str("Cancel") }}</v-btn
          ><v-btn
            :disabled="waiting || !isValid"
            width="45%"
            color="button buttonTxt--text"
            light
            @click="createAlias(alias)"
            >{{ $str("CreateAliasCreate") }}</v-btn
          ></v-row
        ></v-container
      ></v-card-actions
    ></v-card
  >
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      alias: "",
      aliasErrors: [],
      isValid: false,
      lastBadAlias: "",
      rules: {
        required: value =>
          !!value || this.getStringByKey("CreateAliasCharLimits"),
        minlength: value =>
          (value.length >= 6 && value.length <= 20) ||
          this.getStringByKey("CreateAliasCharLimits")
      },
      waiting: false
    };
  },
  computed: {
    ...mapGetters("stringTable", ["getStringByKey"])
  },
  methods: {
    ...mapActions("dialogs", ["showSuccess"]),
    async createAlias() {
      this.waiting = true;
      const success = await this.$store.dispatch(
        "tournaments/createPlayerAlias",
        this.alias
      );
      this.waiting = false;
      if (success) {
        this.showSuccess({
          successMessage: this.getStringByKey("CreateAliasSuccessMessage")
        });
        this.lastBadAlias = "";
      } else {
        this.aliasErrors = this.getStringByKey("CreateAliasErrorMessage");
        this.lastBadAlias = this.alias;
      }
    },
    onAliasChanged() {
      if (this.lastBadAlias !== "" && this.alias === this.lastBadAlias) {
        this.aliasErrors = this.getStringByKey("CreateAliasErrorMessage");
      } else {
        this.aliasErrors = [];
      }
    }
  }
};
</script>
<style scoped>
#closeAlias {
  position: absolute;
  right: 1rem;
}
</style>
