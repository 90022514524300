<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <line class="st0" x1="23.9" y1="25.7" x2="18.4" y2="30.4" />
    <line class="st0" x1="25.7" y1="11.4" x2="25.7" y2="21.7" />
    <path class="st1" d="M24.4,44.3c0.4,0,0.9,0,1.3,0c2.9,0,5.7-0.6,8.3-1.8" />
    <path class="st1" d="M16,41.9c1.8,1,3.7,1.7,5.7,2.1" />
    <path class="st1" d="M10.6,37.7c1,1.1,2.2,2.1,3.4,3" />
    <path
      class="st2"
      d="M28,24c0,1.3-1.1,2.4-2.4,2.4s-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4l0,0C26.9,21.7,28,22.7,28,24L28,24z"
    />
    <path
      class="st1"
      d="M36.2,41.5c9.6-5.8,12.7-18.4,6.9-28s-18.4-12.7-28-6.9C9,10.2,5.3,16.9,5.3,24v0.8H2l5.4,8.1l5.4-8.1H9.4v-0.3
	c0-0.2,0-0.3,0-0.5c0-9,7.3-16.3,16.3-16.3S41.9,15,41.9,24s-7.3,16.3-16.3,16.3c-5.3,0-10.3-2.6-13.3-6.9"
    />
  </svg>
</template>

<script>
export default {
  name: "RecentGames",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Recent Games" }
  }
};
</script>
<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st2 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
</style>
