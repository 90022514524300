<template>
  <v-dialog
    v-model="showDialog"
    :width="smallLayout ? 340 : 500"
    content-class="tournament"
  >
    <CouponPrizes @close="hideTournamentsCouponPrizes" />
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import CouponPrizes from "@/components/TournamentsLobby/CouponPrizes";
import { mapActions } from "vuex";
export default {
  components: { CouponPrizes },
  mixins: [dialogToggle],
  data() {
    return {
      name: "couponPrizes",
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions("dialogs", ["hideTournamentsCouponPrizes"]),
  },
};
</script>
