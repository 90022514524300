<template>
  <v-dialog
    v-model="dialog"
    overlay-opacity="0.9"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card v-if="dialog" class="pb-10">
      <DialogTitleBar
        :title="options.title"
        color="transparent"
        @close="cancel"
      />

      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        v-show="options.message"
        class="text--primary text-left px-4 pt-1 pb-3"
        style="font-size: 12px"
        >{{ options.message }}</v-card-text
      >
      <!--eslint-enable-->
      <v-card-actions class="d-flex justify-space-around px-4">
        <!-- alerts only have an "OK" button -->
        <v-btn
          v-if="options.alert"
          color="button buttonTxt--text"
          width="100%"
          height="40px"
          @click="agree"
          >{{ options.buttonTxt }}</v-btn
        >

        <!-- default is to have "YES" and "NO" buttons -->
        <v-btn
          v-if="!options.alert"
          color="button buttonTxt--text"
          width="49%"
          height="40px"
          @click="agree"
          >{{ $str("YesLabel") }}</v-btn
        >
        <v-btn
          v-if="!options.alert"
          color="buttonSecondary buttonSecondaryTxt--text"
          width="49%"
          height="40px"
          @click="cancel"
          >{{ $str("NoLabel") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * const options = {
 *   title: this.$str("someTitleKey"),
 *   message: this.$str("someMessageKey")
 * }
 *
 * const confirmed = await this.$refs.confirm.open(options);
 * if (confirmed) {
 *   // confirmed (YES)
 * } else {
 *   // canceled (NO)
 * }
 *
 * For an alert ("OK" button only), use .openAlert()
 */
import DialogTitleBar from "@/components/Dialogs/DialogTitleBar";

export default {
  components: { DialogTitleBar },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      message: null,
      title: null,
      width: 370,
      zIndex: 200,
      alert: false,
      buttonTxt: null
    }
  }),
  methods: {
    default() {
      this.options.buttonTxt = this.$str("OKButton");
    },
    open(options) {
      this.dialog = true;
      this.default();
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    openAlert(options) {
      return this.open({ ...options, ...{ alert: true } });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
