<template>
  <v-dialog
    v-model="showDialog"
    class="elevation-12 "
    content-class="freespin"
    :width="largeLayout ? '80%' : '100%'"
  >
    <v-img
      :src="freeSpinBackgroundUrl || require('@/assets/freespinbackground.png')"
      width="100%"
      :max-height="largeLayout ? '600' : '680'"
      class="white--text align-center"
    >
      <div style="position: absolute; top: 10px; right: 12px;z-index: 1;">
        <v-icon class="pa-0" :dark="true" @click.stop="showDialog = false">
          mdi-close</v-icon
        >
      </div>
      <div style="text-align: center;position:relative;">
        <div style="padding: 2vw;">
          <v-container style="width: fit-content; margin-right: 5%;">
            <v-row>
              <v-col>
                <div>
                  <span class="freeSpinText freeSpinTextSmall">
                    {{ this.$str("FreeSpinsCompletedTitle") }}
                  </span>
                  <br />
                  <span class="freeSpinText freeSpinTextSmall">
                    <!-- eslint-disable vue/no-v-html -->
                    <span v-html="freeSpinWinningsMessage"></span>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pa-0"
                style="text-align: left;font-size: x-small;max-width: 425px;margin-left: auto;margin-right: auto;text-shadow: 2px 2px 5px black;"
              >
                <!-- eslint-disable vue/no-v-html -->
                <!-- ____________________________HAS WINNINGS____________________________ -->
                <div
                  v-if="
                    completedFreeSpinCouponInfo &&
                      completedFreeSpinCouponInfo.FreeSpinWinnings > 0
                  "
                >
                  <v-card-subtitle
                    v-if="
                      completedFreeSpinCouponInfo &&
                        completedFreeSpinCouponInfo.FreeSpinBonusAdjustment > 0
                    "
                    :style="
                      largeLayout
                        ? 'padding-top: 16px !important;'
                        : 'padding-top: 10px !important;'
                    "
                    v-html="
                      $str('FreeSpinsCompletedAlongYourWinnings').replace(
                        '{0}',
                        completedFreeSpinCouponInfo.FreeSpinBonusAdjustmentFormatted
                      )
                    "
                  >
                  </v-card-subtitle>
                  <v-card-subtitle
                    v-if="
                      completedFreeSpinCouponInfo.FreeSpinRequiredDeposit > 0 ||
                        completedFreeSpinCouponInfo.PlaythroughTotalAmount > 0
                    "
                    style="padding-top: 0;"
                    v-html="$str('FreeSpinsCompletedGetFreeSpinWinnings')"
                  >
                  </v-card-subtitle>
                  <ul>
                    <li
                      v-if="
                        completedFreeSpinCouponInfo &&
                          completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                            0
                      "
                      v-html="
                        $str('FreeSpinsCompletedMakeMinimumDeposit').replace(
                          '{0}',
                          completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                        )
                      "
                    ></li>
                    <li
                      v-if="
                        completedFreeSpinCouponInfo &&
                          completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment >
                            0
                      "
                      v-html="playthroughAdjustText"
                    ></li>
                  </ul>
                </div>
                <!-- __________________________DOES NOT HAVE WINNINGS______________________________ -->
                <div v-else>
                  <div
                    v-if="
                      completedFreeSpinCouponInfo &&
                        completedFreeSpinCouponInfo.FreeSpinBonusAdjustment > 0
                    "
                  >
                    <v-card-subtitle
                      style="padding-top: 16px;"
                      v-html="$str('FreeSpinsCompletedGetBonus')"
                    ></v-card-subtitle>
                    <ul
                      v-if="
                        completedFreeSpinCouponInfo &&
                          completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                            0
                      "
                    >
                      <li
                        v-html="
                          $str('FreeSpinsCompletedMakeMinimumDeposit').replace(
                            '{0}',
                            completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                          )
                        "
                      ></li>
                      <li
                        v-html="
                          $str('FreeSpinsCompletedWagerX').replace(
                            '{0}',
                            completedFreeSpinCouponInfo.FreeSpinBonusAdjustmentFormatted
                          )
                        "
                      ></li>
                    </ul>
                    <ul v-else>
                      <li
                        v-html="
                          $str('FreeSpinsCompletedWager').replace(
                            '{0}',
                            completedFreeSpinCouponInfo.PlaythroughTotalAmountFormated
                          )
                        "
                      ></li>
                    </ul>
                  </div>
                  <div v-else>
                    <v-card-title
                      v-html="$str('FreeSpinsCompletedHeaderNoWinnings')"
                    ></v-card-title>
                    <div
                      v-if="
                        completedFreeSpinCouponInfo &&
                          completedFreeSpinCouponInfo.FreeSpinRequiredDeposit >
                            0
                      "
                    >
                      <v-card-subtitle
                        v-html="$str('FreeSpinsCompletedContinuePlaying')"
                      ></v-card-subtitle>
                      <ul>
                        <li
                          v-html="
                            $str(
                              'FreeSpinsCompletedMakeMinimumDeposit'
                            ).replace(
                              '{0}',
                              completedFreeSpinCouponInfo.FreeSpinRequiredDepositFormatted
                            )
                          "
                        ></li>
                        <li
                          v-html="
                            $str(
                              'FreeSpinsCompletedWagerFreeSpinWinnings'
                            ).replace(
                              '{0}',
                              completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment
                            )
                          "
                        ></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- ________________________THANK YOU MESSAGE & DISCLAIMERS________________________ -->
                <v-card-subtitle>{{
                  $str("FreeSpinsCompletedThankYou")
                }}</v-card-subtitle>
                <v-card-text
                  v-if="
                    completedFreeSpinCouponInfo &&
                      completedFreeSpinCouponInfo.ConversionLimit > 0
                  "
                  v-html="
                    $str('FreeSpinsCompletedTransferLimit').replace(
                      '{0}',
                      completedFreeSpinCouponInfo.ConversionLimitFormatted
                    )
                  "
                >
                </v-card-text>
                <v-card-text
                  v-if="
                    completedFreeSpinCouponInfo &&
                      completedFreeSpinCouponInfo.FreeSpinRequiredDeposit > 0
                  "
                  v-html="$str('FreeSpinsCompletedClickingDiscard')"
                >
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <v-btn
                  class="ma-0 pa-0 mt-4"
                  style=" width: 18%; margin-bottom: 3vh;min-width: 120px;"
                  color="buttonSecondary buttonSecondaryTxt--text"
                  @click.stop="openDeposit"
                >
                  {{ $str("CouponsDeposit") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                <v-btn
                  x-small
                  class="ma-0 pa-0 mt-4"
                  style=" width: 10%; margin-bottom: 3vh;min-width: 80px;border-radius: 0.3vw;padding-top: 3px !important;"
                  color="buttonFreeSpins buttonFreeSpinsTxt--text"
                  @click.stop="discardCoupon"
                >
                  {{ $str("FreeSpinsDiscard") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <ConfirmDialog ref="confirm" />
    </v-img>
  </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "completedFreeSpin",
      errorMessage: "",
      showConfirmation: false
    };
  },
  computed: {
    ...mapGetters("customizations", ["freeSpinBackgroundUrls"]),
    ...mapGetters("coupons", ["completedFreeSpinCouponInfo"]),
    freeSpinWinningsMessage() {
      if (this.completedFreeSpinCouponInfo?.FreeSpinWinnings > 0) {
        /* freespinWinningsAmount */
        return this.$str("FreeSpinsCompletedHeaderWinnings").replace(
          "{0}",
          `<br />
           <span class="freeSpinText freeSpinTextLarge">
              ${this.completedFreeSpinCouponInfo?.FreeSpinWinningsFormatted}
           </span>
           <br />`
        );
      } else {
        /* bonusWinningsMessage */
        return `<span class="freeSpinText freeSpinTextLarge">
                      ${this.$str(
                        "FreeSpinsCompletedSubHeaderBonusNoWinnings"
                      ).replace(
                        "{0}",
                        this.bonusWinncompletedFreeSpinCouponInfo
                          ?.FreeSpinBonusAdjustmentFormattedingsAmount
                      )}
                </span>`;
      }
    },

    playthroughAdjustText() {
      if (this.completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment > 0) {
        if (this.completedFreeSpinCouponInfo.FreeSpinRequiredDeposit > 0) {
          return this.$str("FreeSpinsCompletedWagerFreeSpinWinnings").replace(
            "{0}",
            this.completedFreeSpinCouponInfo.FreeSpinPlaythroughAdjustment
          );
        } else {
          return this.$str("FreeSpinsCompletedWager").replace(
            "{0}",
            this.completedFreeSpinCouponInfo.PlaythroughTotalAmountFormated
          );
        }
      }
      return null;
    },
    freeSpinBackgroundUrl() {
      return this.$vuetify.theme.dark
        ? this.freeSpinBackgroundUrls?.dark
        : this.freeSpinBackgroundUrls?.light;
    }
  },
  methods: {
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    async discardCoupon() {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("Confirmation"),
        message: this.$str("FreeSpinsCompletedClickingDiscard")
      });

      if (confirmed) {
        this.$store.dispatch("coupons/cancelCoupon");
        this.informDiscard();
      }
    },
    async informDiscard() {
      const informed = await this.$refs.confirm.openAlert({
        title: this.$str("Information"),
        message: this.$str("FreeSpinsCouponDiscarded")
      });
      if (informed) {
        this.showDialog = false;
      }
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  ::v-deep .v-card__subtitle,
  .v-card__text,
  .v-card__title {
    padding: 5px !important;
  }
}

::v-deep .v-dialog {
  border-radius: 12px;
}
::v-deep .freespintitle {
  padding: 0;
  -webkit-text-fill-color: white;
  text-shadow: 2px 2px 5px black;
  cursor: default;
}

.v-application .buttonFreeSpins {
  background-image: linear-gradient(
    to bottom,
    var(--v-buttonFreeSpins-darken1),
    var(--v-buttonFreeSpins-darken4)
  ) !important;
}
::v-deep .freeSpinTextSmall {
  font-weight: 400;
  font-family: "CarterOne-Regular";
  -webkit-text-stroke-color: var(--v-buttonFreeSpins-base);
  font-size: 30px;
  margin-bottom: 0 !important;
  line-height: 1.3;
  -webkit-text-stroke-width: 0.09vw;
}
::v-deep .freeSpinTextLarge {
  font-family: "CarterOne-Regular";
  -webkit-text-stroke-color: #fff;
  font-size: 48px;
  background: -webkit-linear-gradient(
    var(--v-buttonFreeSpins-darken1),
    var(--v-buttonFreeSpins-lighten5)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0 !important;
  line-height: 1.3;
  -webkit-text-stroke-width: 0.02vw;
  font-weight: 700;
}
::v-deep .freespin {
  background-color: inherit !important;
  max-height: 99% !important;
}
</style>
