/* eslint-disable vue/this-in-template */
<template>
  <v-dialog
    v-model="showDialog"
    max-width="550"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar
        :title="title"
        color="transparent"
        @close="showDialog = false"
      />
      <v-card-text>
        <v-form ref="formReferFriend" v-model="validateForm">
          <div v-if="!emailsSended">
            <span>{{ $str("ReferAFriendMobileDescription") }}</span>
            <v-select
              v-model.trim="programId"
              class="v-select-custom required"
              :filled="largeLayout"
              :label="$str('Program')"
              :items="programs"
              item-value="ProgramID"
              item-text="ProgramName"
              @change="getlink()"
            ></v-select>

            <div v-if="playerReferralSetting != 0">
              <v-divider></v-divider>
              <span>{{ $str("ReferLinkHeading") }}</span>
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="lighten-1 mx-0"
                    right
                    :size="18"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle
                  </v-icon>
                </template>
                <span> {{ $str("ReferLinkHelpBubble") }}</span>
              </v-tooltip>
              <v-text-field
                id="programReferralLink"
                v-model="programReferralLink"
                outlined
                readonly
                class="spaceTop"
              >
              </v-text-field>
              <v-row justify="center" class="removeSpaceTop spaceBottom">
                <v-btn
                  depressed
                  small
                  :disabled="isDisabledToCopy() ? true : false"
                  @click="copyText()"
                >
                  {{ $str("CopyLink") }}
                </v-btn>
              </v-row>
            </div>
            <div v-if="playerReferralSetting != 1">
              <v-divider></v-divider>
              <span> {{ $str("ReferEmailsHeading") }}</span>
              <v-tooltip bottom color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="lighten-1 mx-0"
                    right
                    :size="18"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-help-circle
                  </v-icon>
                </template>
                <span> {{ $str("ReferEmailsHelpBubble") }}</span>
              </v-tooltip>
              <v-row
                v-for="email in emails"
                :key="email.index"
                no-gutters
                class="spaceTop"
              >
                <v-text-field
                  v-model="email.model"
                  class="required"
                  dense
                  :label="email.label.replace('{0}', email.index + 1)"
                  v-bind="accountCreateFieldsAttributes.EMailAddress"
                  :rules="emailOrEmptyRule"
                  validate-on-blur
                  autocomplete="off"
                ></v-text-field>

                <v-btn
                  v-if="!lastEmail(email.index)"
                  depressed
                  @click="removeEmail(email)"
                >
                  <v-icon dark> mdi-close </v-icon>
                </v-btn>
                <v-btn
                  v-if="lastEmail(email.index)"
                  depressed
                  :disabled="isDisabledToAdd(email.model) ? false : true"
                  @click="addEmail()"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-row>
              <v-row justify="center">
                <v-btn
                  depressed
                  small
                  :disabled="isDisabledToSend() ? false : true"
                  @click="sendEmailsVerification()"
                >
                  {{ $str("SendEmails") }}
                </v-btn>
              </v-row>
            </div>
          </div>

          <div v-if="emailsSended">
            <span>{{ $str("EmailConfimationReferAFriend") }}</span>
            <br />
            <span>{{ $str("EmailSentTo") }}</span>
            <ul id="array-rendering">
              <li v-for="email in emails" :key="email.index">
                {{ email.model }}
              </li>
            </ul>
          </div>
          <v-row justify="center" class="mt-2">
            <div v-if="success" @click="cleanForm()">
              <v-alert dense type="success">
                {{ $str(success) }}
              </v-alert>
            </div>
            <v-alert v-else-if="error" dense type="warning">{{
              error
            }}</v-alert>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [dialogToggle, displayNameValidator],
  data() {
    return {
      name: "referFriend",
      items: this.programs,
      programId: -1,
      maxEmails: 5,
      emails: [],
      valid: false,
      success: "",
      error: "",
      validateForm: false,
      programReferralLink: "",
      emailsSended: false
    };
  },
  computed: {
    ...mapGetters("referFriend", [
      "programs",
      "referralLink",
      "playerReferralSetting"
    ]),
    ...mapGetters("accountFields", [
      "accountCreateFields",
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ]),
    emailOrEmptyRule() {
      const field = this.accountCreateFields.EMailAddress;
      const rules = [];

      const regExp = RegExp(field.regExp.value);

      rules.push(v => {
        if (v && v.length > 0) {
          return regExp.test(v) || field.useRegExp.errorString;
        } else {
          return true;
        }
      });

      return rules;
    },
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/referFriend"].data.title, this.$str('ReferAFriend') );
    }
  },
  methods: {
    ...mapActions("referFriend", [
      "requestPrograms",
      "generateReferAFriendLink",
      "getPlayerReferralSetting"
    ]),
    async getlink() {
      await this.generateReferAFriendLink(this.programId);
      this.programReferralLink = this.referralLink;
    },
    copyText() {
      const input = document.getElementById("programReferralLink");
      input.select();
      document.execCommand("copy");
    },
    addEmail() {
      if (this.emails.length < this.maxEmails) {
        const index = this.emails.length;
        this.emails.push({
          index: index,
          label: this.$str("FriendNumber"),
          model: ""
        });
      }
    },
    lastEmail(index) {
      if (
        index + 1 === this.emails.length &&
        this.emails.length !== this.maxEmails
      )
        return true;
      else return false;
    },
    removeEmail(email) {
      const indexRemove = this.emails.indexOf(email);
      this.emails.splice(indexRemove, 1);
      for (let i = indexRemove; i < this.emails.length; i++) {
        this.emails[i].index = this.emails[i].index - 1;
      }
    },
    getEmailData(index) {
      if (this.emails.length <= index) return this.emails[index - 1].model;
      else return "";
    },
    async sendEmailsVerification() {
      const emailsTest = this.emails;
      const programIdValue = this.programId;
      if (programIdValue > 0) {
        try {
          if (emailsTest !== undefined) {
            const email1 =
              emailsTest[0] !== undefined ? emailsTest[0].model : "";
            const email2 =
              emailsTest[1] !== undefined ? emailsTest[1].model : "";
            const email3 =
              emailsTest[2] !== undefined ? emailsTest[2].model : "";
            const email4 =
              emailsTest[3] !== undefined ? emailsTest[3].model : "";
            const email5 =
              emailsTest[4] !== undefined ? emailsTest[4].model : "";

            const ret = await CDK.referAFriendsbyEmails(
              programIdValue,
              email1,
              email2,
              email3,
              email4,
              email5
            );
            // Have to wait a frame after enabling validation before calling .validate()
            await new Promise(r => setTimeout(r, 0));

            if (ret) {
              this.success = "emailSentMsg";
              this.emailsSended = true;
              this.error = "";
            } else this.error = "ERROR";
          }
        } catch (error) {
          this.error = "ERROR" + error.Message;
        }
      } else
        this.error = this.$str("isRequired").replace(
          "{0}",
          this.$str("Program")
        );
    },
    isDisabledToCopy() {
      return this.programReferralLink === "";
    },
    isDisabledToAdd(emailValue) {
      return emailValue?.length > 0 && this.$refs.formReferFriend?.validate();
    },
    isDisabledToSend() {
      return (
        this.emails.length > 0 &&
        this.emails[0].model !== "" &&
        this.$refs.formReferFriend?.validate()
      );
    },
    async cleanForm() {
      this.emailsSended = false;
      this.success = "";
      await new Promise(r => setTimeout(r, 0));
      this.$refs.formReferFriend?.resetValidation();
      this.$refs.formReferFriend?.reset();
      this.emails = [
        {
          index: 0,
          label: this.$str("FriendNumber"),
          model: ""
        }
      ];
    },
    onHidden() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    onShown() {
      this.emails = [
        {
          index: 0,
          label: this.$str("FriendNumber"),
          model: ""
        }
      ];
    }
  }
};
</script>

<style scoped>
::v-deep .v-toolbar__content {
  height: 60px !important;
}
.spaceTop {
  margin-top: 10px;
}
.spaceBottom {
  margin-bottom: 15px;
}
.removeSpaceTop {
  margin-top: -15px;
}
</style>
