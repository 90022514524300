<template>
  <v-dialog v-model="showDialog" width="370" overlay-opacity="0.9">
    <v-card align="center">
      <DialogTitleBar :title="title" @close="close" />
      <v-img :src="casinoLogo" max-width="50%" />

      <v-card-text class="pt-5 pb-12">
        <v-form v-if="accountFieldsLoaded" id="signin-form" ref="form" class="mt-3 mb-4" style="min-height: 118px;"
          @submit="submit">
          <v-alert v-show="confirmMessage" type="success" dense :icon="false" class="text-left mb-10">
            {{ confirmMessage }}
          </v-alert>
          <v-text-field id="loginFormloginFld" ref="login" v-model.trim="fieldValues.login" class="required" outlined
            dense hide-details="auto" v-bind="showEmailUsernameField
    ? loginFieldsAttributes.LoginEMailAddress
    : loginFieldsAttributes.Login
    " :rules="validateFields
    ? showEmailUsernameField
      ? loginFieldsRules.LoginEMailAddress
      : loginFieldsRules.Login
    : []
    " color="inherit" :placeholder="requestedFromParam ? ' ' : ''" :label="signupFormType.toLowerCase() === 'crypto'
    ? $strResourceService('EmailAddress')
    : showEmailUsernameField
      ? $str('UserName') + ' / ' + $strResourceService('EmailAddress')
      : $str('UserName')
    "></v-text-field>

          <v-text-field id="loginFormPasswordFld" v-model="fieldValues.password" class="required" outlined dense
            hide-details="auto" v-bind="loginFieldsAttributes.Password"
            :rules="validateFields ? loginFieldsRules.Password : []" :type="showPassword ? 'text' : 'password'"
            color="inherit" :placeholder="requestedFromParam ? ' ' : ''" :label="$str('password')">
            <template v-slot:append>
              <v-icon v-if="showPassword" small class="mt-1" @click="showPassword = !showPassword">mdi-eye-off</v-icon>
              <v-icon v-else small class="mt-1" @click="showPassword = !showPassword">mdi-eye</v-icon>
            </template>
          </v-text-field>

          <v-alert v-show="loginError.length > 0" id="loginFormErrorMsgValert" type="error" dense :icon="false"
            class="text-left" style="font-weight: 700;">
            {{ loginError }}
          </v-alert>
        </v-form>
        <v-row>
          <v-col v-if="funEnabled" cols="6" class="py-0" style="padding-right: 5px">
            <v-btn id="loginFormPlayFunBtn" color="buttonSecondary buttonSecondaryTxt--text" block
              @click="loginPracticeMode">{{
    $str("PracticeMode") }}</v-btn>
          </v-col>
          <v-col :cols="funEnabled ? '6' : '12'" class="py-0" :style="funEnabled ? 'padding-left: 5px' : ''">
            <v-btn id="loginFormPlayRealBtn" block color="button buttonTxt--text" form="signin-form" type="submit"
              @click="fieldValues.forMoney = true">{{ $str("LoginPage") }}</v-btn>
          </v-col>
        </v-row>

        <v-overlay absolute :value="!accountFieldsLoaded || loggingIn">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="showEmailUsernameField" class="mt-3" style="text-align: center; font-size: 12px; font-weight: 700;">
          <v-btn v-if="forgotPasswordEnabled" text x-small color="hyperlink" style="font-size: inherit;"
            @click="showForgotPassword">
            {{ $strResourceService("ForgotPasswordHeader") }}
          </v-btn>
        </div>
        <div v-if="!showEmailUsernameField" class="mt-3" style="text-align: center; font-size: 12px; font-weight: 700;">
          <v-btn v-if="forgotPasswordEnabled" text x-small color="hyperlink" style="font-size: inherit;"
            @click="showForgotPassword">
            {{ $strResourceService("ForgotPasswordHeader") }}
          </v-btn>
          <span v-if="forgotPasswordEnabled && forgotUsernameEnabled" class="mx-n1 align-center"
            style="text-transform: lowercase; line-height: 16px; vertical-align: middle;">
            {{ $str("Or") }}
          </span>
          <v-btn v-if="forgotUsernameEnabled" text x-small color="hyperlink" style="font-size: inherit;"
            @click="showForgotUsername">
            {{ $str("UserNameQuestion") }}
          </v-btn>
        </div>
        <div v-if="!confirmMessage" class="mt-2" style="text-align: center; font-size: 12px; font-weight: 700;">
          <span class="mx-n1 align-center" style="line-height: 16px; vertical-align: middle;">{{
    $str("DontHaveAnAccount")
  }}</span>
          <v-btn text x-small color="hyperlink" style="font-size: inherit;" @click="showSignUpDialog">
            {{ $str("SignUp") }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";
import { launchAction } from "@/services/casino";
import displayNameValidator from "@/mixins/shared/displayNameValidator";

export default {
  mixins: [dialogToggle, displayNameValidator],
  data() {
    return {
      name: "signIn",
      fieldValues: {
        login: "",
        password: "",
        forMoney: true
      },
      loggingIn: false,
      loginError: "",
      validateFields: false,
      isEmailVerified: false,
      ForceEmailVerification: false,
      showPassword: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "loginFields",
      "loginFieldsAttributes",
      "loginFieldsRules",
      "forgotUsernameEnabled",
      "forgotPasswordEnabled",
      "rememberPasswordEnabled",
      "funEnabled",
      "accountFieldsLoaded",
      "signupFormType",
      "accountCreateFields",
      "showEmailUsernameField"
    ]),
    ...mapGetters("customizations", ["casinoLogo"]),
    onSuccess() {
      return this.$store.getters["dialogs/signIn"].data.onSuccess;
    },
    confirmMessage() {
      return this.$store.getters["dialogs/signIn"].data.confirmMessage;
    },
    requestedFromParam() {
      return this.$store.getters["dialogs/signIn"].data.requestedFromParam;
    },
    title() {
      return this.$validateDisplayName(
        this.$store.getters["dialogs/signIn"].data.title,
        this.$str("LoginPage")
      );
    }
  },
  methods: {
    onShown() {
      this.$refs.form?.reset();

      const login = this.$store.getters["dialogs/emailVerification"].data.login
        ? this.$store.getters["dialogs/emailVerification"].data.login
        : this.$store.getters["dialogs/signIn"].data.login;
      if (login) this.fieldValues.login = login;

      const password = this.$store.getters["dialogs/emailVerification"].data
        .password
        ? this.$store.getters["dialogs/emailVerification"].data.password
        : this.$store.getters["dialogs/signIn"].data.password;
      if (password) this.fieldValues.password = password;
    },
    onHidden() {
      this.loginError = "";
      this.validateFields = false;
      this.$refs.form?.reset();
    },
    checkIsEmail(text) {
      const regExp = RegExp(this.accountCreateFields.EMailAddress.regExp.value);
      return regExp.test(text);
    },
    showForgotPassword() {
      this.showDialog = false;
      const isEmail = this.checkIsEmail(this.fieldValues.login);

      this.$store.dispatch("dialogs/showForgotPassword", {
        login: isEmail ? "" : this.fieldValues.login,
        email: isEmail ? this.fieldValues.login : ""
      });
    },
    showForgotUsername() {
      this.$store.dispatch("dialogs/showForgotUsername");
      this.showDialog = false;
    },
    showSignUpDialog() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
      this.showDialog = false;
    },
    showChangePasswordDialog(login) {
      this.showDialog = false;

      this.$store.dispatch("dialogs/showChangePassword", {
        confirmMessage: "",
        login: login
      });
    },
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form?.validate();
    },
    async login() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }

      try {
        await CDK.GetPlayerEmailVerification(this.fieldValues.login).then(
          result => {
            this.isEmailVerified = result;
          }
        );

        await CDK.GetForceEmailVerification().then(result => {
          this.ForceEmailVerification = result;
        });

        this.loggingIn = true;
        this.loginError = "";
        await this.$store.dispatch("session/signIn", this.fieldValues);

        if (
          this.ForceEmailVerification === true &&
          this.isEmailVerified === false
        ) {
          const loginEmail = (this.fieldValues.login);
          const password = this.fieldValues.password;
          const hashVerification = await CDK.generateHashEmailVerification();
          const PID = await CDK.getPlayerPID();
          const playerSignUpData = await CDK.GetPlayerSignupInfo(PID);
          await CDK.logOut();
          window.LobbyCore.raise(window.LobbyCore.Events.closeSignInPage);
          window.LobbyCore.raise(
            window.LobbyCore.Events.showEmailVerificationPage,
            {
              login: loginEmail,
              password: password,
              hash: hashVerification,
              playerData: playerSignUpData
            }
          );
        } else {
          this.$store.getters["dialogs/emailVerification"].data.login = "";
          this.$store.getters["dialogs/emailVerification"].data.password = "";
          if (this.onSuccess && typeof this.onSuccess !== "function") {
            launchAction(
              this.$store,
              this.onSuccess.action,
              this.onSuccess.params
            );
          } else {
            if (typeof this.onSuccess === "function") {
              this.onSuccess();
            }
          }
          this.showDialog = false;
        }
      } catch (error) {
        if (error.ErrorCode === 15) {
          this.showChangePasswordDialog(this.fieldValues.login);
        } else {
          this.loginError = error.Message;
        }
      }

      this.loggingIn = false;
    },
    loginPracticeMode() {
      this.fieldValues.forMoney = false;
      this.login();
    },
    async submit(e) {
      // Don't actually submit the form and cause the browser to navigate
      e.preventDefault();
      this.login();
    },
    close() {
      window.LobbyCore.raise(window.LobbyCore.Events.closeSignInPage);
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {

  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
  .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-disabled-base) !important;
    color: var(--v-disabledTxt-base) !important;
  }
}

::v-deep.v-alert--text:before {
  background-color: transparent !important;
}

::v-deep.v-alert--text>.v-alert__wrapper {
  display: inline-flex !important;
}

.white-space-error {
  white-space: pre-wrap;
}
</style>
