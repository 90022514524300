<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
  >
    <g style="isolation:isolate">
      <g id="ss">
        <path
          d="M35.67,38.21h7.78v3A4.49,4.49,0,0,1,39,45.69H7.55A4.49,4.49,0,0,1,3.06,41.2V18.76a4.49,4.49,0,0,1,4.49-4.48h3v3H8.67c-1.5,0-1.5,1.49,0,1.49H39a4.49,4.49,0,0,1,4.49,4.49v3H35.67a6,6,0,1,0,0,12Zm9.27-9v6a1.5,1.5,0,0,1-1.49,1.5H36.12a4.49,4.49,0,1,1,0-9h7.33A1.5,1.5,0,0,1,44.94,29.24Zm-5.76,3A1.72,1.72,0,1,0,37.46,34,1.72,1.72,0,0,0,39.18,32.23Zm-7-18.64c-2.76-1.17-5.81-.2-6.81,2.17A4.18,4.18,0,0,0,25,17.27H35.48A5.68,5.68,0,0,0,32.13,13.59Zm-9.64,3.68,3.2-7.58a4.64,4.64,0,0,0,6.09-2.47L36.06,9a4.66,4.66,0,0,0,2.48,6.1l-.91,2.14h3.22l2.35-5.22A3.09,3.09,0,0,0,41.55,8L28.7,2.55A3.11,3.11,0,0,0,24.64,4.2L19.12,17.27Zm-8.9,0L16.46,6.39a6.39,6.39,0,0,1,.38-1,3.1,3.1,0,0,0-3.41,2.67L12.2,17.26Zm3.58,0L22.64,4.33l.15-.27-.44-.12a3.1,3.1,0,0,0-3.79,2.21L15.62,17.26Z"
          fill="currentColor"
        />
        <path
          d="M35.67,38.21h7.78v3A4.49,4.49,0,0,1,39,45.69H7.55A4.49,4.49,0,0,1,3.06,41.2V18.76a4.49,4.49,0,0,1,4.49-4.48h3v3H8.67c-1.5,0-1.5,1.49,0,1.49H39a4.49,4.49,0,0,1,4.49,4.49v3H35.67a6,6,0,1,0,0,12Zm9.27-9v6a1.5,1.5,0,0,1-1.49,1.5H36.12a4.49,4.49,0,1,1,0-9h7.33A1.5,1.5,0,0,1,44.94,29.24Zm-5.76,3A1.72,1.72,0,1,0,37.46,34,1.72,1.72,0,0,0,39.18,32.23Zm-7-18.64c-2.76-1.17-5.81-.2-6.81,2.17A4.18,4.18,0,0,0,25,17.27H35.48A5.68,5.68,0,0,0,32.13,13.59Zm-9.64,3.68,3.2-7.58a4.64,4.64,0,0,0,6.09-2.47L36.06,9a4.66,4.66,0,0,0,2.48,6.1l-.91,2.14h3.22l2.35-5.22A3.09,3.09,0,0,0,41.55,8L28.7,2.55A3.11,3.11,0,0,0,24.64,4.2L19.12,17.27Zm-8.9,0L16.46,6.39a6.39,6.39,0,0,1,.38-1,3.1,3.1,0,0,0-3.41,2.67L12.2,17.26Zm3.58,0L22.64,4.33l.15-.27-.44-.12a3.1,3.1,0,0,0-3.79,2.21L15.62,17.26Z"
          fill="none"
          :stroke="iconStroke"
          stroke-miterlimit="10"
          stroke-width="1.44"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BalanceIcon",
  props: {
    iconColor: {
      type: String,
      default: "currentColor"
    },
    iconStroke: {
      type: String,
      default: "button"
    },
    displayName: { type: String, default: "BalanceIcon" }
  }
};
</script>
