<template>
  <main :class="{ mobile: mobileClient }" class="my-5 mx-4 mx-md-7">
    <div class="gamesmenu">
      <ul style="background: var(--v-header-base);">
        <li
          v-for="(menu, index) in menuTabs"
          :id="`gamesNavBar${menu.name.split(' ').join('')}Btn`"
          :key="index"
          :class="
            `${
              menu.id === selectedTab ? 'tab--selected ' : ''
            }${!mobileClient && 'hover'}`
          "
          @click="
            () => {
              updateTab(menu.id);
              menu.name === 'Home' ? goHome() : goTo(menu.id, null, menu.name);
            }
          "
        >
          <span
            ><v-icon v-if="gameFamilyIcons[menu.name]" class="mr-2"
              >{{ gameFamilyIcons[menu.name] }}
            </v-icon>
            <!-- eslint-disable vue/no-v-html -->
            <i
              v-else
              class="mr-2 "
              v-html="updatedCustomIconSvg(menu.Image)"
            ></i>
            <!--eslint-enable-->
            <span>{{ menu.displayName }}</span></span
          >
        </li>
      </ul>
    </div>
    <div>
      <v-snackbar :value="raiseAlert" vertical color="error">
        <div class="text-subtitle-1 pb-2">
          {{ $strResourceService("Error") }}
        </div>
        <p>{{ $strResourceService("LiveDealerNotAvailable") }}</p>
      </v-snackbar>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      gameNavSelected: null,
      tab: null,
      selectedTab: null,
      raiseAlert: false
    };
  },

  computed: {
    ...mapGetters("games", ["populatedGameFamilyMenus", "gameFamilyIcons"]),
    ...mapGetters("session", [
      "mobileClient",
      "isWCOVisible",
      "wcoLobbyUrl",
      "playForMoney"
    ]),
    menuTabs() {
      const home = {
        name: "Home",
        displayName: this.$str("HomePage"),
        id: -1
      };

      const wcoLiveDealerTab = {
        name: "Live Dealer",
        displayName: this.$str("MoreGames"),
        id: -3
      };

      if (CDK.isLoggedIn() && this.isWCOVisible) {
        return [home, ...this.populatedGameFamilyMenus, wcoLiveDealerTab];
      } else {
        return [
          home,
          ...this.populatedGameFamilyMenus.filter(menu =>
            this.playForMoney ? true : menu.name !== "Live Dealer EVO"
          )
        ];
      }
    }
  },
  watch: {
    // Update the tab whenever the route changes
    $route: {
      immediate: false,
      handler(to) {
        this.updateTab(to.params.menuId || -1);
      }
    }
  },
  created() {
    this.selectedTab = +sessionStorage.getItem("menuId") || -1;
  },
  methods: {
    ...mapActions("dialogs", ["showTournamentsLobby", "showCustomUrl"]),
    async goTo(menuId, subMenuId = null, name = null, url = null) {
      if (
        this.$route.name === "games" &&
        this.$route.params.menuId === menuId &&
        this.$route.params.subMenuId === subMenuId
      ) {
        return;
      }
      if (name === "Tournaments") {
        this.showTournamentsLobby();
        return;
      } else if (name === "Sportsbook" && url !== null) {
        if (CDK.isLoggedIn() && url.includes("<Token>")) {
          await CDK.generateGameToken();
        }
        this.showCustomUrl({
          title: name,
          source: url,
          newTab: true
        });
        return;
      } else if (menuId === -3) {
        if (!this.wcoLobbyUrl.hasErrors) {
          this.$router.push({
            name: "livedealer",
            params: { url: this.wcoLobbyUrl.source }
          });
        } else {
          this.raiseAlert = this.wcoLobbyUrl.hasErrors;
        }
        return;
      }
      this.$router.push({
        name: "games",
        params: { menuId, subMenuId }
      });
    },
    updateTab(id) {
      this.selectedTab = id;
      sessionStorage.setItem("menuId", id);
    },
    updatedCustomIconSvg(svgCode) {
      // if custom SVG has incompatible built in sizing, update it to the correct icon size
      const width = 20;
      const height = 20;

      const parser = new DOMParser();
      const doc = parser.parseFromString(svgCode, "image/svg+xml");

      const svgElement = doc.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute("width", width);
        svgElement.setAttribute("height", height);
      }

      //Check for inline style and update 'width' and 'height' properties
      let styleAttribute = svgElement?.getAttribute("style");
      if (styleAttribute) {
        styleAttribute = styleAttribute.replace(
          /width\s*:\s*[^;]+/i,
          `width:${width}px`
        );
        styleAttribute = styleAttribute.replace(
          /height\s*:\s*[^;]+/i,
          `height:${height}px`
        );
        svgElement.setAttribute("style", styleAttribute);
      }

      const serializer = new XMLSerializer();
      return serializer.serializeToString(doc);
    }
  }
};
</script>
<style scoped lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::v-deep *::-webkit-scrollbar {
  background: var(--v-header-base);
  height: 8px;
  width: 10px;

  .mobile & {
    display: none;
    height: 12px;
  }
}

::v-deep *::-webkit-scrollbar-track {
  background: transparent !important;
}

::v-deep *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
}

body {
  font-family: "Figtree", sans-serif;
}

.gamesmenu {
  width: fit-content;
  max-width: 100%;
  height: 50px;
  margin-bottom: 20px;
  overflow-y: hidden;
  overflow-x: auto;
}
.gamesmenu ul {
  width: max-content;
  padding: 5px;
  border-radius: 50px;
}
.gamesmenu li {
  height: 40px;
  padding: 0 20px;
  display: inline-block;
  border-radius: 50px;
}
.gamesmenu li span {
  line-height: 40px;
  color: var(--v-bodyTitle-base);
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.gamesmenu li.hover:hover {
  background-color: var(--v-body-base);
}
.gamesmenu li .v-icon,
.gamesmenu li i {
  width: 20px;
  height: 20px;
  float: left;
  color: var(--v-bodyTitle-base);
}

.tab--selected {
  background: var(--v-body-base) !important;
}
</style>
