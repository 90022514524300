<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" width="500">
    <v-card v-if="showDialog" class="elevation-12">
      <v-card-title
        class="justify-center"
        :class="largeLayout ? 'transparent' : 'navbar'"
      >
        <v-spacer></v-spacer>
        <span
          :style="largeLayout ? 'margin-top: 5%;' : ''"
          style="text-transform: uppercase;"
          >{{ $strResourceService("VerifyEmail") }}</span
        >
        <v-spacer></v-spacer>
        <v-icon
          class="pa-0"
          :style="largeLayout ? 'margin-top: -5%;' : ''"
          @click.stop="showDialog = false"
        >
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
        >
      </v-card-title>
      <!-- eslint-disable vue/no-v-html -->
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row class="ma-1 mt-4" align="center" justify="center">
            <v-text-field
              v-model="email"
              dense
              class="required"
              hide-details="auto"
              autofocus
              :label="$strResourceService('ConfirmEmail')"
              v-bind="accountCreateFieldsAttributes.EMailAddress"
              :rules="
                validateFields ? accountCreateFieldsRules.EMailAddress : []
              "
              @focus="autoFillEmail()"
              @blur="autoFillEmail()"
            ></v-text-field>
          </v-row>
          <p class="mb-1 pa-0 caption text-center">
            {{ $strResourceService("ChangeEmailMessage") }}
          </p>
          <v-card-text
            class="ma-0 mb-1 pa-2"
            :min-height="largeLayout ? '405' : ''"
            v-html="$strResourceService('CheckVerificationEmailMessage')"
          ></v-card-text>
          <v-row justify="center"
            ><v-btn
              color="button buttonTxt--text"
              :disabled="!valid"
              @click="sendEmailVerification"
              >{{ $str("resend") }}</v-btn
            ></v-row
          >
          <v-row justify="center" class="mt-4">
            <v-alert v-if="success" dense type="success">{{
              $strResourceService(success)
            }}</v-alert>
            <v-alert v-else-if="error" dense type="warning">{{
              error
            }}</v-alert>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "verifyEmail",
      email: "",
      valid: false,
      success: "",
      error: "",
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("session", ["playerEmail"]),
    ...mapGetters("accountFields", [
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ])
  },
  methods: {
    ...mapActions("session", ["updateSessionPlayerEmail"]),
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form.validate();
    },
    async sendEmailVerification() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }
      try {
        await CDK.sendEmailVerification(this.email);
        this.updateSessionPlayerEmail(this.email);
        this.success = "EmailSentMessage";
      } catch (error) {
        this.error = error.Message;
      }
    },
    onHidden() {
      this.success = "";
      this.error = "";
      this.validateFields = false;
      this.email = "";
      this.$refs.form.resetValidation();
    },
    autoFillEmail() {
      if (this.email === "") {
        this.email = this.playerEmail;
      }
    }
  }
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}
</style>
