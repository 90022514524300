<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="smallLayout && !isLandscape"
    :max-width="
      smallLayout && isLandscape ? '95%' : smallLayout ? '100%' : '800px'
    "
    :content-class="
      largeLayout
        ? 'msgLstLargeLayout'
        : isLandscape
        ? 'msgLstLandscapeLayout rounded-0'
        : 'rounded-0'
    "
  >
    <v-card v-if="showDialog" flat class="messageCenterDialog">
      <v-toolbar class="msgNavBar px-0 mb-5" color="popupBackground" flat>
        <v-toolbar-title class="font-weight-bold">{{
          $str("MessageCenterPage")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-container
          v-if="playerMessages.length"
          class="ma-0 pa-0"
          style="width: 100px; height: 40px"
        >
          <v-btn
            :disabled="!isCheckedMessages"
            icon
            style="width: 50px; height: 40px; border: 1px solid rgba(255,255,255,.25);
  border-radius: .25rem 0 0 .25rem;"
            @click="onDelete()"
          >
            <v-icon small class="leftIcon">$Delete</v-icon>
          </v-btn>
          <v-btn
            icon
            style="width: 50px; height: 40px;  border: 1px solid rgba(255,255,255,.25);
  border-radius: 0 .25rem .25rem 0;"
            @click="onRefresh()"
          >
            <v-icon small class="leftIcon">$Refresh</v-icon>
          </v-btn></v-container
        >
        <v-icon
          id="msgCenterListCloseBtn"
          class="ml-3"
          @click.stop="showDialog = false"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-container
        v-if="playerMessages.length === 0"
        class="justify-center "
        :style="$vuetify.breakpoint.xsOnly ? 'height: 90vh;' : 'height: 25vh;'"
      >
        <div justify="center" class="nomessages" tile>
          <v-icon
            style="font-size: 24px; width: 24px !important; height: 24px !important"
            >$Warning</v-icon
          >
          <div class="font-weight-bold">{{ $str("NoMessages") }}</div>
        </div>
      </v-container>
      <v-container
        v-else
        fluid
        class="d-flex flex-column justify-center py-0 my-5 mx-0 px-4"
        ><v-container ma-0 pa-0 fluid class="message-list-container">
          <div class="showedListMsg" :class="messageListClasses">
            <v-row
              v-for="(message, index) in playerMessages"
              :key="message.ID"
              no-gutters
              align="center"
              :style="
                index !== playerMessages.length - 1 &&
                  'border-bottom: 1px solid rgba(255,255,255,.25);'
              "
            >
              <v-container ma-0 pa-0 style="max-width: 100%;">
                <v-row
                  style="height: 40px"
                  no-gutters
                  align="center"
                  :style="
                    !message.Read
                      ? 'font-weight: bolder;font-size: calc(100% - 2.4px);'
                      : 'font-size: calc(100% - 2.8px);'
                  "
                >
                  <v-col class="checkMsg mx-2" :cols="1">
                    <v-checkbox
                      v-model="checkboxes[message.ID]"
                      color="button"
                      hide-details="false"
                    ></v-checkbox>
                  </v-col>
                  <v-col class="messageContainer">
                    <v-card
                      flat
                      @click="showPreviewMessage(message.ID, message.Title)"
                      >{{ message.Title }}</v-card
                    >
                  </v-col>
                  <v-col class="readIcon " :cols="1">
                    <v-icon
                      v-show="!message.Read"
                      x-small
                      color="buttonSecondary"
                    >
                      mdi-checkbox-blank-circle
                    </v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-row>
          </div>
        </v-container>
        <v-card v-if="!smallLayout" class="currentMessage">
          <div>
            <MessageDisplay
              v-if="isShowedCurrentMessage"
              :id="selectedMessage"
              :height="messageHeight"
            /></div></v-card
      ></v-container>
    </v-card>
    <ConfirmDialog ref="confirm" />
  </v-dialog>
</template>

<script>
import Vue from "vue";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import MessageDisplay from "@/components/MessageDisplay";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ConfirmDialog,
    MessageDisplay
  },
  mixins: [dialogToggle],
  data() {
    return {
      checkboxes: {},
      name: "messageCenterList",
      currentMessageCenterHeight: "",
      selectedMessage: "",
      messageHeight: "100%"
    };
  },
  computed: {
    ...mapGetters("messageCenter", [
      "playerMessages",
      "forceOnEntryMessages",
      "forceOnExitMessages",
      "supressForceOnEntrance"
    ]),
    ...mapGetters("coupons", ["freeSpinNotificationStatus"]),
    ...mapGetters("session", ["switchingToRealFromGame", "mobileClient"]),
    ...mapGetters("casinoJackpot", ["casinoJackpotUser"]),
    showForceOnEntryMessage() {
      return (
        (!this.casinoJackpotUser.UnclaimedHitsAmount > 0 &&
          this.forceOnEntryMessages?.length > 0 &&
          this.freeSpinNotificationStatus === 0 &&
          !this.switchingToRealFromGame &&
          !this.supressForceOnEntrance) ??
        false
      );
    },
    isShowedCurrentMessage() {
      return this.selectedMessage !== "";
    },
    messageListClasses() {
      return this.largeLayout
        ? `${this.playerMessages.length > 4 ? " limited" : ""} bordered`
        : "";
    },
    isCheckedMessages() {
      return Object.values(this.checkboxes).some(checked => checked === true);
    }
  },
  watch: {
    showForceOnEntryMessage(newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        // For right now, the casino assumes only 1 force on entry message
        this.showMessageCenterMessage({ id: this.forceOnEntryMessages[0].ID });
      }
    },
    forceOnExitMessages(newMsgs, oldMsgs) {
      if (newMsgs.length > 0 && newMsgs.length !== oldMsgs.length) {
        // For right now, the casino assumes only 1 force on entry message
        this.showMessageCenterMessage({
          id: newMsgs[0].ID,
          isForceOnExit: true
        });
      }
    }
  },
  methods: {
    ...mapActions("messageCenter", [
      "deletePlayerMessage",
      "requestPlayerMessages",
      "requestUnreadMessagesCount",
      "markPlayerMessageAsRead"
    ]),
    ...mapActions("dialogs", ["showMessageCenterMessage"]),
    async onDelete() {
      const anyChecked = Object.values(this.checkboxes).some(
        checked => checked === true
      );

      if (anyChecked) {
        const confirmed = await this.$refs.confirm.open({
          title: this.$str("Confirmation"),
          message: this.$str("DeleteMessageConfirmation")
        });

        if (confirmed) {
          Object.entries(this.checkboxes).forEach(([msgId, checked]) => {
            if (checked) {
              this.deletePlayerMessage(msgId);
              this.checkboxes[msgId] = false;
            }
          });
        }
      }
      Vue.nextTick(() => {
        this.syncShowMessageHeight();
      });
    },
    onShown() {
      this.addListener();
      Vue.nextTick(() => {
        if (!this.smallLayout && this.playerMessages.length > 0) {
          this.showPreviewMessage(
            this.playerMessages[0].ID,
            this.playerMessages[0].Title
          );
        }
      });
    },
    onHidden() {
      this.removeListener();
      this.checkboxes = {};
    },
    onRefresh() {
      this.requestPlayerMessages();
      this.requestUnreadMessagesCount();
      Vue.nextTick(() => {
        this.syncShowMessageHeight();
      });
    },
    showPreviewMessage(id, title = "") {
      this.markPlayerMessageAsRead(id);
      if (this.smallLayout) {
        this.showMessageCenterMessage({ id, title });
      } else {
        this.$set(this, "selectedMessage", id);
        this.syncShowMessageHeight();
      }
    },
    addListener() {
      window.addEventListener("resize", this.syncShowMessageHeight, {
        passive: true
      });
    },
    removeListener() {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", this.syncShowMessageHeight, {
          passive: true
        });
      }
    },
    syncShowMessageHeight() {
      const msgCount = this.playerMessages.length;
      if (msgCount > 0) {
        document.getElementsByClassName("currentMessage")[0].style.height =
          "0px";
        const getHeight = className => {
          return document.getElementsByClassName(className)[0].offsetHeight;
        };
        let height =
          getHeight("messageCenterDialog") -
          getHeight("msgNavBar") -
          getHeight("showedListMsg");
        if (height <= 0 && msgCount <= 5) {
          height = 696 - msgCount * 25;
        }
        document.getElementsByClassName(
          "currentMessage"
        )[0].style.height = `${height}px`;
        this.messageHeight = `${height}px`;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.leftIcon {
  width: 16px !important;
  height: 16px !important;
}

::v-deep .v-toolbar__content {
  margin-top: 16px;
  margin-bottom: 16px;
  height: 40px !important;
}

::v-deep .v-toolbar__title {
  font-size: 18px;
}

.titleCentered {
  width: 100%;
  display: inline-block;
  text-align: center;
}

.message-list-container {
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 0.25rem;
}

.nomessages {
  text-align: center;
}

.nomessages div {
  width: 100%;
  font-size: 16px;
  margin: 10px 0 10px 0;
}

.nomessages i {
  margin: 0 auto 10px auto;
  font-size: 60px;
}

.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.v-input--selection-controls__ripple {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.message-list-container .showedListMsg.limited {
  max-height: 30vh;
  overflow-y: auto;
}

// .message-list-container .showedListMsg.bordered {
//   border-bottom: 3px solid black;
//   border-top: 3px solid black;
// }

.message-list-container > div:first-child {
  padding-top: 2px;
}

.message-list-container .v-divider {
  margin-top: 0px;
  margin-bottom: 0px;
}

.message-list-container .col.col-1.checkMsg {
  max-width: 24px;
  max-height: 24px;
}

.message-list-container .col.col-1.readIcon {
  max-width: 10px;
  margin-right: 10px;
}

.currentMessage {
  width: 100%;
  min-height: 290px !important;
  border-top: 3px solid var(--v-button-base);
  background: transparent;
}

::v-deep .msgLstLargeLayout {
  overflow: hidden;
  min-height: 583px;
}

::v-deep .msgLstLandscapeLayout {
  min-height: 95%;
  max-height: 95%;
}

.messageContainer {
  width: 75%;
  .v-card {
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
  }
}
</style>
