<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M11.9,31.9c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.5-0.5C11.7,31.3,11.9,31.6,11.9,31.9
	C11.9,31.9,11.9,31.9,11.9,31.9z"
    />
    <ellipse class="st0" cx="19.3" cy="31.9" rx="0.5" ry="0.6" />
    <path
      class="st0"
      d="M27.8,31.9c0,0.3-0.3,0.5-0.6,0.5c-0.3,0-0.5-0.3-0.5-0.6c0-0.3,0.3-0.5,0.5-0.5C27.6,31.3,27.8,31.6,27.8,31.9
	C27.8,31.9,27.8,31.9,27.8,31.9z"
    />
    <path
      class="st1"
      d="M35.6,44.7V10.3c0-4.2-3.2-7.6-7-7.6H9.8c-3.9,0-7,3.4-7,7.6v34.3"
    />
    <path
      class="st1"
      d="M31.6,10.3c0.1-1.7-1.3-3.2-3-3.3H9.8c-1.7,0.1-3.1,1.5-3,3.3"
    />
    <ellipse class="st1" cx="42.7" cy="15.1" rx="2.7" ry="2.9" />
    <polyline class="st1" points="39.4,33.4 42.7,33.4 42.7,18 " />
    <polyline class="st1" points="35.7,30.2 39.2,30.2 39.2,36.5 35.7,36.5 " />
    <polyline class="st2" points="9.8,17.9 13,17.9 10.8,23.7 " />
    <polyline class="st2" points="17.6,17.9 20.8,17.9 18.5,23.7 " />
    <polyline class="st2" points="25.3,17.9 28.5,17.9 26.3,23.7 " />
    <rect x="6.7" y="14.3" class="st1" width="24.9" height="12.8" />
    <line class="st2" x1="15" y1="22.2" x2="15" y2="27" />
    <line class="st2" x1="23.3" y1="22.2" x2="23.3" y2="27" />
    <polyline
      class="st3"
      points="15,36.5 31.6,36.5 31.6,41.7 6.8,41.7 6.8,36.5 11.6,36.5 "
    />
  </svg>
</template>

<script>
export default {
  name: "Slots",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Slots" }
  }
};
</script>

<style scoped type="text/css">
.st0 {
  fill: #394553;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st2 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.st3 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
</style>
