<template>
  <v-dialog
    v-if="accountFieldsLoaded"
    v-model="showDialog"
    persistent
    width="370"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
    :content-class="contentClass"
  >
    <v-card>
      <DialogTitleBar :title="$str('SignUpPage')" @close="close" />

      <v-card-text class="text-center px-4 pb-10" style="">
        <v-container id="signup-form" class="pa-0">
          <div id="iframeCustomMessage">
            <embed
              v-if="showCustomHeader()"
              :src="getCustomHeader()"
              :style="customHeaderStyle"
              :height="customHeaderHeight"
              scrolling="no"
              frameborder="0"
              @load="resizeCustomHeader"
            />
          </div>
          <br />
          <v-form id="short-signup-form-1" ref="form" v-model="step1Valid">
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.FirstName
                )
              "
              id="shortSignUpFirstName"
              v-model.trim="fields.FirstName"
              :class="getFieldClass(accountCreateFields.FirstName)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.FirstName"
              :rules="accountCreateFieldsRules.FirstName"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('FirstName')"
              :append-icon="iconToAppend(accountCreateFields.FirstName)"
              @blur="focused['FirstName'] = false"
              @focus="focused['FirstName'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.LastName
                )
              "
              id="shortSignUpLastName"
              v-model.trim="fields.LastName"
              :class="getFieldClass(accountCreateFields.LastName)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.LastName"
              :rules="accountCreateFieldsRules.LastName"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('LastName')"
              :append-icon="iconToAppend(accountCreateFields.LastName)"
              @blur="focused['LastName'] = false"
              @focus="focused['LastName'] = true"
            >
            </v-text-field>
            <v-text-field
              id="shortSignUpEmail"
              v-model.trim="fields.EMailAddress"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.EMailAddress"
              :class="getFieldClass(accountCreateFields.EMailAddress)"
              :rules="accountCreateFieldsRules.EMailAddress"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('EMailAddress')"
              :append-icon="iconToAppend(accountCreateFields.EMailAddress)"
              :error-messages="emailError"
              @blur="focused['EMailAddress'] = false"
              @focus="focused['EMailAddress'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShown(accountCreateFields.Login) && !showEmailUsernameField
              "
              id="shortSignUpLogin"
              v-model.trim="fields.Login"
              :class="getFieldClass(accountCreateFields.Login)"
              outlined
              dense
              hide-details="auto"
              autocomplete="off"
              v-bind="accountCreateFieldsAttributes.Login"
              :rules="accountCreateFieldsRules.Login"
              color="inherit"
              validate-on-blur
              :success="success('Login')"
              :append-icon="iconToAppend(accountCreateFields.Login)"
              :error-messages="userNameError"
              @blur="focused['Login'] = false"
              @focus="focused['Login'] = true"
            >
            </v-text-field>
            <v-text-field
              id="shortSignUpPassword"
              v-model.trim="fields.Password"
              :class="getFieldClass(accountCreateFields.Password)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.Password"
              :rules="accountCreateFieldsRules.Password"
              color="inherit"
              validate-on-blur
              autocomplete="new-password"
              :success="success('Password')"
              :type="showPassword['Password'] ? 'text' : 'password'"
              :append-icon="appendIconOnPassword(accountCreateFields.Password)"
              @click:append="
                focused['Password']
                  ? (showPassword['Password'] = !showPassword['Password'])
                  : (showPassword['Password'] = false)
              "
              @blur="focused['Password'] = false"
              @focus="focused['Password'] = true"
            >
              ></v-text-field
            >
            <div
              v-if="
                passwordStrength > 0 &&
                  strengthEnable &&
                  !featurePasswordStrengthEnabled
              "
              id="password-strength"
              :class="'level-' + passwordStrength"
            >
              <span> {{ passwordLevelName }} </span>
              <v-tooltip v-if="passwordStrength < 3" bottom color="secondary">
                <template v-slot:activator="{ on }">
                  <v-icon color="lighten-1 mx-0" right :size="13" v-on="on"
                    >mdi-help-circle
                  </v-icon>
                </template>
                <!-- eslint-disable vue/no-v-html -->
                <span v-html="recomendationsPassword"> </span>
              </v-tooltip>
            </div>
            <PasswordValidation
              v-if="strengthEnable && featurePasswordStrengthEnabled"
              ref="passwordValidation"
              :password="fields.Password"
              :min-password="minPaswwordLength"
              @passwordMounted="passwordMount"
            ></PasswordValidation>
            <v-text-field
              id="shortSignUpConfirmPassword"
              v-model.trim="confirmPassword"
              :class="getFieldClass(accountCreateFields.Password)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.Password"
              :label="$strResourceService('ConfirmationPassword')"
              name="ConfirmPassword"
              :rules="confirmPasswordRules"
              color="inherit"
              autocomplete="off"
              :type="showPassword['confirmPassword'] ? 'text' : 'password'"
              :success="
                !!confirmPassword && confirmPassword === fields.Password
              "
              :append-icon="
                !!confirmPassword && confirmPassword === fields.Password
                  ? showEyeIconOnPassword('confirmPassword', '$Check')
                  : ''
              "
              @click:append="
                focused['confirmPassword']
                  ? (showPassword['confirmPassword'] = !showPassword[
                      'confirmPassword'
                    ])
                  : (showPassword['confirmPassword'] = false)
              "
              @blur="focused['confirmPassword'] = false"
              @focus="focused['confirmPassword'] = true"
            >
            </v-text-field>
            <v-select
              v-if="hasMultipleCurrencies"
              id="shortSignUpCurrency"
              v-model.trim="fields.playerCurrencyId"
              outlined
              dense
              hide-details="auto"
              class="v-select-custom"
              :success="!!fields.playerCurrencyId"
              :label="$strResourceService('Currency')"
              :items="currencyList"
              :rules="currencyRules"
              item-value="Key"
              item-text="Value"
            >
            </v-select>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.AddressLine1
                )
              "
              id="shortSignUpAddressLine1"
              v-model.trim="fields.AddressLine1"
              :class="getFieldClass(accountCreateFields.AddressLine1)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.AddressLine1"
              :rules="accountCreateFieldsRules.AddressLine1"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('AddressLine1')"
              :append-icon="iconToAppend(accountCreateFields.AddressLine1)"
              @blur="focused['AddressLine1'] = false"
              @focus="focused['AddressLine1'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.AddressLine2
                )
              "
              id="shortSignUpAddressLine2"
              v-model.trim="fields.AddressLine2"
              :class="getFieldClass(accountCreateFields.AddressLine2)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.AddressLine2"
              :rules="accountCreateFieldsRules.AddressLine2"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('AddressLine2')"
              :append-icon="iconToAppend(accountCreateFields.AddressLine2)"
              @blur="focused['AddressLine2'] = false"
              @focus="focused['AddressLine2'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.City
                )
              "
              id="shortSignUpCity"
              v-model.trim="fields.City"
              :class="getFieldClass(accountCreateFields.City)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.City"
              :rules="accountCreateFieldsRules.City"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('City')"
              :append-icon="iconToAppend(accountCreateFields.City)"
              @blur="focused['City'] = false"
              @focus="focused['City'] = true"
            >
            </v-text-field>
            <v-select
              v-if="isShown(accountCreateFields.Country)"
              id="shortSignUpCountry"
              v-model.trim="fields.Country"
              :class="getFieldClass(accountCreateFields.Country)"
              outlined
              dense
              hide-details="auto"
              class="v-select-custom"
              :success="!!fields.Country"
              v-bind="accountCreateFieldsAttributes.Country"
              :rules="accountCreateFieldsRules.Country"
              :items="getItems(accountCreateFields.Country)"
              item-value="Value"
              item-text="Key"
              @change="setPhoneCodes"
            >
            </v-select>
            <v-select
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.State
                ) && showStateDropDown
              "
              id="shortSignUpState"
              v-model.trim="fields.State"
              :class="getFieldClass(accountCreateFields.State)"
              outlined
              dense
              hide-details="auto"
              :success="!!fields.State"
              class="v-select-custom"
              v-bind="accountCreateFieldsAttributes.State"
              :rules="accountCreateFieldsRules.State"
              :items="getItems(accountCreateFields.State)"
              :append-icon="iconToAppend(accountCreateFields.State)"
              item-value="Value"
              item-text="Key"
            >
            </v-select>
            <v-text-field
              v-else-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.State
                )
              "
              id="shortSignUpState"
              v-model.trim="fields.State"
              :class="getFieldClass(accountCreateFields.State)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.State"
              :rules="accountCreateFieldsRules.State"
              :success="!!fields.State"
              :append-icon="iconToAppend(accountCreateFields.State)"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.Zip
                )
              "
              id="shortSignUpZip"
              v-model.trim="fields.Zip"
              :class="getFieldClass(accountCreateFields.Zip)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.Zip"
              :rules="accountCreateFieldsRules.Zip"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('Zip')"
              :append-icon="iconToAppend(accountCreateFields.Zip)"
              @blur="focused['Zip'] = false"
              @focus="focused['Zip'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="isShown(accountCreateFields.Birthdate)"
              id="shortSignUpBirthdate"
              v-model.trim="fields.Birthdate"
              :class="getFieldClass(accountCreateFields.Birthdate)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.Birthdate"
              :rules="accountCreateFieldsRules.Birthdate"
              :max="currentDate"
              :success="success('Birthdate')"
              :append-icon="iconToAppend(accountCreateFields.Birthdate)"
              @blur="focused['Birthdate'] = false"
              @focus="focused['Birthdate'] = true"
            >
            </v-text-field>
            <v-select
              v-if="isShown(accountCreateFields.gender)"
              id="shortSignUpGender"
              v-model.trim="fields.gender"
              :class="getFieldClass(accountCreateFields.gender)"
              outlined
              dense
              hide-details="auto"
              :success="!!fields.gender"
              v-bind="accountCreateFieldsAttributes.gender"
              :rules="accountCreateFieldsRules.gender"
              :items="getItems(accountCreateFields.gender)"
              item-value="Value"
              item-text="Key"
              class="v-select-custom"
            >
            </v-select>
            <v-text-field
              v-if="isShown(accountCreateFields.cellPhone)"
              id="shortSignUpCellPhone"
              v-model.trim="fields.cellPhone"
              :class="getFieldClass(accountCreateFields.cellPhone)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.cellPhone"
              :rules="PhoneCountryRules"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="phoneRuleSuccess('cellPhone')"
              :append-icon="phoneIconAppend(accountCreateFields.cellPhone)"
              @blur="focused['cellPhone'] = false"
              @focus="focused['cellPhone'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.DaytimePhone
                )
              "
              id="shortSignUpDaytimePhone"
              v-model.trim="fields.DaytimePhone"
              :class="getFieldClass(accountCreateFields.DaytimePhone)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.DaytimePhone"
              :rules="accountCreateFieldsRules.DaytimePhone"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('DaytimePhone')"
              :append-icon="iconToAppend(accountCreateFields.DaytimePhone)"
              @blur="focused['DaytimePhone'] = false"
              @focus="focused['DaytimePhone'] = true"
            >
            </v-text-field>
            <v-text-field
              v-if="
                isShownFormType(
                  signupFormType.toLowerCase(),
                  accountCreateFields.EveningPhone
                )
              "
              id="shortSignUpEveningPhone"
              v-model.trim="fields.EveningPhone"
              :class="getFieldClass(accountCreateFields.EveningPhone)"
              outlined
              dense
              hide-details="auto"
              v-bind="accountCreateFieldsAttributes.EveningPhone"
              :rules="accountCreateFieldsRules.EveningPhone"
              color="inherit"
              validate-on-blur
              autocomplete="off"
              :success="success('EveningPhone')"
              :append-icon="iconToAppend(accountCreateFields.EveningPhone)"
              @blur="focused['EveningPhone'] = false"
              @focus="focused['EveningPhone'] = true"
            >
            </v-text-field>
            <v-overlay absolute :value="SigningUp">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
            <div>
              <v-checkbox
                v-if="isShown(accountCreateFields.SMSMessages)"
                id="shortSignUpSMSMessages"
                ref="SMSMessages"
                v-model="fields.SMSMessages"
                :class="getFieldClass(accountCreateFields.SMSMessages)"
                dense
                v-bind="accountCreateFieldsAttributes.SMSMessages"
                :rules="checkboxRules('SMSMessages')"
                class="ml-1 mt-0 pt-0"
                color="popupTxt popupTxt--text"
                :success="!!fields.SMSMessages"
                hide-details="auto"
                :disabled="isDefault(accountCreateFields.SMSMessages)"
                @blur="focused['SMSMessages'] = false"
                @focus="focused['SMSMessages'] = true"
              >
                <template v-slot:label>
                  <div>
                    {{ $strResourceService("SmsMessages") }}
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                v-if="isShown(accountCreateFields.BonusesAndPromotions)"
                id="shortSignUpPromotions"
                ref="BonusesAndPromotions"
                v-model="fields.BonusesAndPromotions"
                dense
                class="ml-1 mt-0 pt-0"
                color="popupTxt popupTxt--text"
                :class="getFieldClass(accountCreateFields.BonusesAndPromotions)"
                v-bind="accountCreateFieldsAttributes.BonusesAndPromotions"
                :rules="checkboxRules('BonusesAndPromotions')"
                :success="!!fields.BonusesAndPromotions"
                hide-details="auto"
                :disabled="isDefault(accountCreateFields.BonusesAndPromotions)"
                @blur="focused['BonusesAndPromotions'] = false"
                @focus="focused['BonusesAndPromotions'] = true"
              >
                <template v-slot:label>
                  <div>
                    {{ $strResourceService("AcceptPromotions") }}
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                v-if="isShown(accountCreateFields.TermsAndConditions)"
                id="shortSignUpConditions"
                ref="TermsAndConditions"
                v-model="fields.TermsAndConditions"
                color="popupTxt popupTxt--text"
                class="ml-1 mt-0 pt-0"
                dense
                :class="getFieldClass(accountCreateFields.TermsAndConditions)"
                v-bind="accountCreateFieldsAttributes.TermsAndConditions"
                :rules="checkboxRules('TermsAndConditions')"
                :success="!!fields.TermsAndConditions"
                hide-details="auto"
                :disabled="isDefault(accountCreateFields.TermsAndConditions)"
                @blur="focused['TermsAndConditions'] = false"
                @focus="focused['TermsAndConditions'] = true"
              >
                <template v-slot:label>
                  <div
                    @click.stop.prevent="handleConditionsClick"
                    v-html="termsNConditionsText"
                  ></div>
                </template>
              </v-checkbox>
            </div>
            <v-alert
              v-show="signupError.length > 0"
              type="error"
              dense
              :icon="false"
              class="text-left error errorTxt--text"
              style="font-weight: 700;"
            >
              {{ signupError }}
            </v-alert>
            <v-btn
              id="shortSignUpRegisterButton"
              width="100%"
              class="mt-3 mb-2"
              :disabled="!step1Valid || !isPasswordValid"
              color="button buttonTxt--text"
              @click="submit()"
            >
              {{ $strResourceService("Register") }}
            </v-btn>
          </v-form>
          <!-- TODO: in the next release, these strings will need translations for ZH-CN and ZH-TW, 
        but there are none available right now.-->
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="formFooter text-left"
            style="font-size: 12px; font-weight: 700;"
          >
            <span v-html="alreadyHaveAccountMessagePt1"> </span>
            <a
              class="hyperlink--text ma-0"
              @click.stop.prevent="login"
              v-html="alreadyHaveAccountMessagePt2"
            >
            </a>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { VTextField } from "vuetify/lib";
import { VSelect } from "vuetify/lib";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions, mapGetters } from "vuex";
import { launchAction } from "@/services/casino";
import { clientType, isNullorWhitespace } from "@/services/casino";
import PasswordValidation from "@/components/common/PasswordValidation";
export default {
  components: { VTextField, VSelect, PasswordValidation },
  mixins: [dialogToggle],
  data() {
    return {
      name: "signUp",
      fields: {
        Login: "",
        Password: "",
        Birthdate: null,
        AddressLine1: "",
        AddressLine2: "",
        cellPhone: null,
        City: "",
        Country: null,
        countryName: "",
        DaytimePhone: null,
        EMailAddress: "",
        EveningPhone: null,
        FirstName: "",
        gender: null,
        LastName: "",
        SMSMessages: null,
        State: "",
        Zip: null,
        BonusesAndPromotions: null,
        playerCurrencyId: "",
        emailVerified: false,
        TermsAndConditions: null
      },
      SigningUp: false,
      confirmPassword: "",
      signupError: "",
      signupwizard: 1,
      step1Valid: false,
      userNameError: "",
      emailError: "",
      customHeaderStyle: "width: 100%; display: none;",
      customHeaderHeight: "27%",
      phoneCountryCode: "",
      phoneCountryRule: [],
      loginValues: {
        login: "",
        password: "",
        forMoney: true
      },
      focused: {
        Login: false,
        Password: false,
        confirmPassword: false,
        Birthdate: false,
        AddressLine1: false,
        AddressLine2: false,
        cellPhone: false,
        City: false,
        Country: false,
        countryName: false,
        DaytimePhone: false,
        EMailAddress: false,
        EveningPhone: false,
        FirstName: false,
        gender: false,
        LastName: false,
        SMSMessages: false,
        State: false,
        Zip: false,
        BonusesAndPromotions: false,
        playerCurrencyId: false,
        emailVerified: false,
        TermsAndConditions: false
      },
      showPassword: {
        Password: false,
        confirmPassword: false
      },
      isEmailVerified: false,
      passwordMounted: false,
      hasCustomHeaders: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "accountCreateFields",
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules",
      "accountFieldsLoaded",
      "currencyList",
      "strengthEnable",
      "signupFormType",
      "featurePasswordStrengthEnabled",
      "showEmailUsernameField"
    ]),
    ...mapGetters("lobbyComponents", [
      "termsConditionsURL",
      "privacyPolicyURL"
    ]),
    ...mapGetters("customizations", ["customHeaderUrls"]),
    ...mapGetters("session", ["onSuccessSignIn"]),
    alreadyHaveAccountMessage() {
      return this.$strResourceService("AlreadyHaveAccount").replace(
        "{0}",
        this.$str("LoginPage")
      );
    },
    alreadyHaveAccountMessagePt1() {
      // New design does not have a linebreak, and makes everything after the
      // old linebreak into a link. So split the message up into two parts.
      return this.alreadyHaveAccountMessage.split("<br />")[0] + " ";
    },
    alreadyHaveAccountMessagePt2() {
      // This part of the messages will be a link.
      return this.alreadyHaveAccountMessage.split("<br />")[1] || "";
    },
    confirmPasswordRules() {
      return [
        v => !!v || this.$strResourceService("FieldRequired"),
        v =>
          v === this.fields.Password ||
          this.$strResourceService("PasswordNoMatch")
      ];
    },
    currencyRules() {
      return [v => !!v || this.$strResourceService("FieldRequired")];
    },
    checkboxRules() {
      return field => [
        v =>
          !v && this.accountCreateFields[field].required.value
            ? this.accountCreateFields[field].field === "TermsAndConditions"
              ? this.privacyPolicyURL
                ? this.$strResourceService("SignUpTermsCheckboxError")
                : this.$strResourceService("TermsNConditionsError")
              : this.$str("isRequired").replace(
                  "{0}",
                  this.accountCreateFields[field].display
                )
            : true
      ];
    },
    currentDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0");
      const yyyy = today.getFullYear();

      return yyyy + "-" + mm + "-" + dd;
    },
    hasMultipleCurrencies() {
      return this.currencyList && this.currencyList.length > 1;
    },
    success() {
      return field => !!this.fields[field] && !this.focused[field];
    },
    phoneRuleSuccess() {
      return field =>
        !!this.fields[field] &&
        !this.focused[field] &&
        this.fields[field].trim() !== this.phoneCountryCode.trim();
    },
    customHeaderUrl() {
      return this.$vuetify.theme.dark
        ? this.customHeaderUrls?.dark
        : this.customHeaderUrls?.light;
    },
    showStateDropDown() {
      return (
        this.fields.Country === "AU" ||
        this.fields.Country === "CA" ||
        this.fields.Country === "US"
      );
    },
    passwordStrength() {
      if (this.fields.Password === "" || this.fields.Password === undefined)
        return 0;

      if (this.StrengthPasswordValidation[0].test(this.fields.Password))
        return 3;
      if (this.StrengthPasswordValidation[1].test(this.fields.Password))
        return 2;
      if (this.StrengthPasswordValidation[2].test(this.fields.Password))
        return 1;
      return 0;
    },
    passwordLevelNames() {
      return [
        "",
        this.$str("Password_Weak"),
        this.$str("Password_Medium"),
        this.$str("Password_Strong")
      ];
    },
    passwordLevelName() {
      return this.passwordLevelNames[this.passwordStrength];
    },
    recomendationsPassword() {
      return (
        this.$str("Password_Recomendations") +
        "<ul><li>" +
        this.$str("RecomendationNCharacters").replace(
          "{0}",
          this.minPaswwordLength
        ) +
        "</li><li>" +
        this.$str("RecomendationSmallLetter") +
        "</li><li>" +
        this.$str("RecomendationCapitalLetter") +
        "</li><li>" +
        this.$str("RecomendationNumber_Symbol") +
        "</li></ul>"
      );
    },
    minPaswwordLength() {
      return this.accountCreateFields.Password.minLength.value;
    },
    StrengthPasswordValidation() {
      const strongRegex = RegExp(
        // eslint-disable-next-line no-useless-escape
        `^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*/()]))(?=.{${this.minPaswwordLength},})`
      );
      const mediumRegex = RegExp(
        // eslint-disable-next-line no-useless-escape
        `^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[!@#$%^&*/()])(?=.*[a-z]))|((?=.*[!@#$%^&*/()])(?=.*[A-Z])))(?=.{${this.minPaswwordLength},})`
      );
      const weakRegex = RegExp(`^(?=.{${this.minPaswwordLength},})`);
      return [strongRegex, mediumRegex, weakRegex];
    },
    privacyPolicyVisible() {
      return this.privacyPolicyURL;
    },
    termsNConditionsText() {
      const privacyPolicyVisible = this.privacyPolicyURL
        ? ""
        : "privacy-policy-hide";

      let text = this.$strResourceService("SignUpTermsCheckbox")
        .replace("{0}", this.$strResourceService("TermAndConditions"))
        .replace("{1}", this.$str("PrivacyPolicyLink"))
        .replace(
          "{a1}",
          '<a class="hyperlink--text" id="showTermsNConditions">'
        )
        .replace("{span1}", '</a> <span class="{class}">')
        .replace(
          "{a2}",
          '</span> <a class="hyperlink--text {class}" id="showPrivacyPolicy">'
        )
        .replace("{a3}", "</a>");

      text = text.replace(/{class}/g, privacyPolicyVisible);

      return text;
    },
    PhoneCountryRules() {
      return [
        ...this.accountCreateFieldsRules.cellPhone,
        ...this.phoneCountryRule
      ];
    },
    isPasswordValid() {
      return this.strengthEnable && this.featurePasswordStrengthEnabled
        ? this.passwordMounted && this.$refs.passwordValidation?.isPasswordValid
        : true;
    },
    contentClass() {
      return this.largeLayout
        ? this.hasCustomHeaders
          ? "customMessageLargeLayout"
          : "custom-dialog"
        : "";
    }
  },
  watch: {
    accountFieldsLoaded(loaded) {
      if (loaded) {
        this.loadPlayerCurrencyId();
      }
    }
  },
  updated() {
    // Ensure our computed properties for height and width change if the screen
    //  is resized (ie phone rotated, etc)
    window.addEventListener("resize", () => {
      this.resizeCustomHeader();
    });
  },
  methods: {
    ...mapActions("dialogs", [
      "showExternalUrl",
      "showPrivacyPolicy",
      "showTermsConditions"
    ]),
    close() {
      window.LobbyCore.raise(window.LobbyCore.Events.closeSignUpPage);
    },
    fieldComponent(field) {
      if (field.field === "Birthdate") {
        return "v-date-picker";
      } else if (field.dropDown || field.field === "State") {
        return "v-select";
      } else {
        return "v-text-field";
      }
    },
    getCustomHeader() {
      let customHeaderSrc = "";
      if (this.$vuetify.theme.dark) {
        if (
          this.customHeaderUrls.dark.hasOwnProperty("instantPlay") &&
          this.customHeaderUrls.dark.hasOwnProperty("mobile")
        ) {
          customHeaderSrc =
            clientType === CDK.ClientTypes.Mobile
              ? this.customHeaderUrls.dark.mobile.StepOne
              : this.customHeaderUrls.dark.instantPlay.StepOne;
        } else {
          customHeaderSrc = this.customHeaderUrls.dark;
        }
      } else {
        if (
          this.customHeaderUrls.light.hasOwnProperty("instantPlay") &&
          this.customHeaderUrls.light.hasOwnProperty("mobile")
        ) {
          customHeaderSrc =
            clientType === CDK.ClientTypes.Mobile
              ? this.customHeaderUrls.light.mobile.StepOne
              : this.customHeaderUrls.light.instantPlay.StepOne;
        } else {
          customHeaderSrc = this.customHeaderUrls.light;
        }
      }
      if (!customHeaderSrc.trim()) {
        this.customHeaderStyle = "width: 100%; display: none;";
        this.hasCustomHeaders = false;
      } else {
        this.customHeaderStyle = "width: 100%; display: block;";
        this.hasCustomHeaders = true;
      }
      return customHeaderSrc;
    },
    loadPlayerCurrencyId() {
      if (!this.hasMultipleCurrencies) {
        this.playerCurrencyId = this.currencyList[0]?.Key;
      }
    },
    getItems(field) {
      return field.getDropdownValues(this.fields.Country);
    },
    isShown(field) {
      return !field.hidden;
    },
    isDefault(accountField) {
      const { field, default: defaultValue } = accountField;

      this.fields[field] = defaultValue ? defaultValue : !!this.fields[field];
      return defaultValue;
    },
    isShownFormType(formType, field) {
      if (formType === "crypto" && !field.hidden) {
        return true;
      }
      return false;
    },
    getFieldClass(field) {
      if (field?.required?.value) {
        return "required";
      }
      return "";
    },
    iconToAppend(field) {
      if (this.success(field.field)) {
        return "mdi-check";
      } else if (field.required.value) {
        // TODO: display an asterisk in the field label if the field is required
        //return "*";
      }
      return "";
    },
    appendIconOnPassword(field) {
      if (this.success(field.field)) {
        return "mdi-check";
      } else if (field.required.value) {
        if (this.focused[field.field] === true) {
          return this.showPassword[field.field] ? "mdi-eye" : "mdi-eye-off";
        }
        return "*";
      }

      return "";
    },
    showEyeIconOnPassword(field, iconDefault) {
      return this.focused[field]
        ? this.showPassword[field]
          ? "mdi-eye"
          : "mdi-eye-off"
        : iconDefault;
    },
    phoneIconAppend(field) {
      if (
        (field.required.value && !this.fields[field.field]) ||
        (field.required.value &&
          this.fields[field.field]?.trim() === this.phoneCountryCode.trim())
      ) {
        return "*";
      } else if (this.success(field.field)) {
        return "mdi-check";
      }
      return "";
    },
    login() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
      this.close();
    },
    resetForm() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$refs.form1?.reset();

      if (!this.hasMultipleCurrencies) {
        this.fields.playerCurrencyId = this.currencyList[0]?.Key;
      }
      this.loadPlayerCurrencyId();
    },
    onHidden() {
      this.resetForm();
    },
    onShown() {
      this.resetForm();
    },
    showCustomHeader() {
      if (!this.customHeaderUrls) {
        return false;
      } else {
        return this.$vuetify.theme.dark
          ? this.customHeaderUrls.dark
          : this.customHeaderUrls.light;
      }
    },
    resizeCustomHeader() {
      const w = 1076;
      const h = 50;

      const width = Number(window.innerWidth);
      if (clientType === CDK.ClientTypes.Mobile) {
        this.customHeaderHeight = width / 4.5;
      } else {
        this.customHeaderHeight = h * (width / w);
      }
    },
    async setPhoneCodes() {
      this.fields.State = "";

      try {
        this.phoneCountryCode = await CDK.getPhoneCountryCode(
          this.fields.Country
        );
      } catch {
        this.phoneCountryCode = "";
      }
      if (this.phoneCountryCode !== "") {
        this.phoneCountryCode = "+" + this.phoneCountryCode + " ";
        this.fields.cellPhone = this.phoneCountryCode;
        if (this.accountCreateFields.cellPhone.required.value) {
          this.phoneCountryRule = [
            v =>
              (v && v.trim() !== this.phoneCountryCode.trim()) ||
              this.accountCreateFields.cellPhone.required.errorString
          ];
        }
      }
    },
    validatePhoneFields() {
      let arePhonesValid = true;
      if (this.phoneCountryCode === "") return arePhonesValid;

      if (this.fields.cellPhone.trim() === this.phoneCountryCode.trim()) {
        if (this.accountCreateFields.cellPhone.required.value) {
          this.signupError = this.$str("isRequired").replace(
            "{0}",
            "Mobile/Cell Phone"
          );
          arePhonesValid = false;
        } else {
          this.fields.cellPhone = "";
        }
      }

      return arePhonesValid;
    },
    async submit() {
      const useCrypto = this.signupFormType.toLowerCase() === "crypto";
      const loginName = useCrypto
        ? this.fields.EMailAddress
        : this.fields.Login;

      this.SigningUp = true;
      if (this.validatePhoneFields()) {
        try {
          await this.$store.dispatch("session/signUp", this.fields);

          let loginEmail = this.fields.Login;
          if (
            isNullorWhitespace(this.fields.Login) &&
            !isNullorWhitespace(this.fields.EMailAddress)
          ) {
            loginEmail = this.fields.EMailAddress;
          }

          // Handle SignIn Events --
          const SignUpInfoReturn = await CDK.getSignUpInfo();
          if (SignUpInfoReturn.IsAutoLoginEnabled) {
            this.loginValues.login = useCrypto ? this.fields.Login : loginEmail;
            this.loginValues.password = this.fields.Password;
            await this.$store.dispatch("session/signIn", this.loginValues);

            if (this.onSuccessSignIn) {
              launchAction(
                this.$store,
                this.onSuccessSignIn.action,
                this.onSuccessSignIn.params
              );
            }
          } else {
            if (
              SignUpInfoReturn.IsEmailVerificationEnabled &&
              SignUpInfoReturn.ForceEmailVerification
            ) {
              window.LobbyCore.raise(
                window.LobbyCore.Events.showEmailVerificationPage,
                {
                  login: useCrypto ? loginName : loginEmail,
                  password: this.fields.Password
                }
              );
            } else {
              window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
                login: useCrypto ? loginName : loginEmail,
                password: this.fields.Password,
                onSuccess: this.onSuccessSignIn,
                confirmMessage: this.$str("RegistrationSuccessMessage")
              });
            }
          }

          this.close();
          this.resetForm();
        } catch (error) {
          this.signupError = error.Message;
        }
      } else {
        this.signupError = this.$str("PhoneFieldsError");
      }
      this.SigningUp = false;
    },
    async checkForExistingUsername() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          this.fields.username,
          "",
          "",
          "",
          "",
          true
        );
        this.userNameError = "";
      } catch (error) {
        this.userNameError = error.Message;
        return;
      }
    },
    async checkForExistingEmail() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          "",
          this.fields.EMailAddress,
          "",
          "",
          "",
          true
        );
        this.emailError = "";
      } catch (error) {
        this.emailError = error.Message;
        return;
      }
    },
    async checkForExistingPlayer() {
      try {
        await CDK.registerIncompleteSignupWithValidation(
          this.fields.username,
          this.fields.EMailAddress,
          this.fields.FirstName,
          this.fields.LastName,
          this.fields.playerCurrencyId
        );
      } catch (error) {
        this.signupError = error.Message;
        return;
      }
      this.signupwizard = 2;
      this.signupError = "";
    },
    handleConditionsClick(e) {
      if (e.target.matches("#showPrivacyPolicy")) {
        this.showPrivacyPolicy();
      }
      if (e.target.matches("#showTermsNConditions")) {
        this.showTermsConditions();
      }
    },
    passwordMount() {
      this.passwordMounted = true;
    }
  }
};
</script>
<style></style>
<style scoped lang="scss">
::v-deep .privacy-policy-hide {
  display: none !important;
}
::v-deep .customMessageLargeLayout {
  width: 80vw;
  max-height: 98% !important;

  .v-card__title {
    padding-bottom: 0px;
  }

  div#signup-form {
    display: block;
    flex-direction: row;
    align-items: stretch;
    flex-flow: row wrap;
  }

  .formFooter {
    flex: 1 100%;
    order: 3;
  }

  #iframeCustomMessage {
    order: 2;
    flex: 1 50%;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }

  #iframeCustomMessage iframe {
    height: 100%;
  }

  #short-signup-form-1 {
    order: 1;
    flex: 1 50%;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 600px) {
  // ::v-deep.v-text-field.v-text-field--enclosed .v-text-field__details,
  // ::v-deep.v-text-field.v-text-field--enclosed
  //   .v-text-field__details
  //   .v-messages {
  //   display: contents !important;
  // }

  // ::v-deep .v-messages__wrapper {
  //   text-align: center;
  //   margin-bottom: 5px;
  // }

  // ::v-deep .v-messages__wrapper .v-messages__message::before {
  //   content: "\F0026";
  //   padding-right: 5px;
  // }
  // ::v-deep .v-messages__wrapper .v-messages__message:before {
  //   display: inline-block;
  //   font: normal normal normal 24px/1 "Material Design Icons";
  //   font-size: inherit;
  //   text-rendering: auto;
  //   line-height: inherit;
  //   -webkit-font-smoothing: antialiased;
  // }

  // ::v-deep .v-stepper__step .primary {
  //   width: 30px !important;
  //   height: 30px !important;
  //   font-size: large;
  // }

  // ::v-deep .v-stepper__step .success i {
  //   font-style: normal !important;
  //   font-size: 13px !important;
  // }
  #password-strength {
    top: -7px;
    height: 14px;
  }
}

@media screen and (max-width: 600px) {
  #password-strength {
    height: 0;
    text-align: left;
    top: -15px;
  }
}
::v-deep .v-input__append-inner .mdi-check.v-icon:not(.success--text) {
  display: none;
}

.scale {
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
}

::v-deep .custom-dialog {
  max-height: 98% !important;
  border-radius: 12px;
  width: 500px;
}

::v-deep .v-input--selection-controls__input .v-icon {
  color: var(--v-popupTxt-base);
}

.level-0 {
  display: none;
}
#password-strength {
  font-size: 13px;
  font-weight: bold;
  margin-top: 4px;
  white-space: nowrap;
  margin: 0;
  position: relative;
}

.theme--dark .password-strength {
  color: rgba(255, 255, 255, 0.7);
}

.theme--light .password-strength {
  color: rgba(0, 0, 0, 0.54);
}

#password-strength.level-1,
#password-strength.level-1 .v-icon.v-icon {
  color: #fe7878 !important;
  text-shadow: 1px 1px 2px rgb(254 120 120 / 0.5);
}

#password-strength.level-2,
#password-strength.level-2 .v-icon.v-icon {
  color: #d7a204 !important;
  text-shadow: 1px 1px 2px rgb(215 162 4 / 0.5);
}

#password-strength.level-3 {
  color: #70bf74 !important;
  text-shadow: 1px 1px 2px rgb(112 191 116 / 0.5);
}
::v-deep .error--text.v-messages {
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  .v-messages__wrapper {
    margin-top: 0 !important;
  }
}
</style>
