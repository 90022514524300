<template>
  <v-dialog
    v-model="reconnecting"
    fullscreen
    content-class="rounded-0 downTimeDialog"
  >
    <!-- Overlay -->
    <v-overlay v-show="largeLayout" opacity="0.3" z-index="1"></v-overlay>

    <v-card height="100%" style="z-index: 2;">
      <v-container fill-height>
        <v-row class="center-card">
          <v-col cols="12" align="center" justify="center">
            <iframe
              :src="downtimeNotificationMessageURL"
              style="width: 100%; min-height: 10vh;"
              scrolling="no"
              frameborder="0"
              class="downtimeframe"
            >
            </iframe>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("connection", [
      "reconnecting",
      "downtimeNotificationMessageURL"
    ])
  }
};
</script>
<style scoped>
.center-card {
  margin: auto;
}
.v-card {
  background-color: rgba(0, 0, 0, 0.6);
}

iframe {
  width: 80vw;
  height: 30vh;
  border: none;
  overflow: hidden;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
@media all and (orientation: portrait) {
  iframe {
    height: 20vh;
    max-width: none;
  }
}
@media only screen and (min-width: 600px) {
  .downTimeShadow > {
    background-color: rgba(0, 0, 0, 0.6) !important;
    opacity: 0.9 im !important;
  }
  .downTimeDialog > .v-card {
    background-color: transparent !important;
  }
}
</style>
