<template>
  <v-dialog
    v-model="showdialog"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
    max-width="70%"
  >
    <v-card
      v-model="showdialog"
      class="elevation-12"
      height="100%"
      :style="smallLayout ? '' : 'min-height:600px'"
    >
      <DialogTitleBar :title="title" @close="close" />
      <v-card-text
        class="pa-0"
        style="height: 100%;"
        :style="smallLayout ? '' : 'min-height:600px;'"
      >
        <v-container v-if="showLoadingPanel" fill-height white>
          <v-row justify="center"
            ><v-progress-circular
              color="grey"
              indeterminate
            ></v-progress-circular
          ></v-row>
        </v-container>
        <iframe
          v-show="showiFrame"
          width="100%"
          height="92%"
          :src="sourceUrl"
          style="background-color: white;"
          :style="smallLayout ? '' : 'min-height:600px;'"
          @load="iFrameLoaded"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { dialogToggle } from "@/mixins/dialogToggle";
import { checkUrlProtocol } from "@/services/casino";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
export default {
  // props: {
  //   value: Boolean
  // },
  mixins: [dialogToggle, displayNameValidator],
  data() {
    return {
      name: "customUrlHistory",
      isIframeLoaded: false
    };
  },
  computed: {
    ...mapGetters("session", ["customUrlHistory"]),
    showdialog: {
      get() {
        return this.customUrlHistory.isVisible;
      },
      set(value) {
        this.showCustomUrlWithHistory(value);
      }
    },
    title() {
      return this.$validateDisplayName(this.customUrlHistory.displayName, this.$store.getters["session/customUrlHistory"].data.title );
    },
    sourceUrl() {
      return checkUrlProtocol(
        CDK.replaceParameters(
          this.$store.getters["session/customUrlHistory"].data.source
        )
      );
    },
    showLoadingPanel() {
      return !this.isIframeLoaded;
    },
    showiFrame() {
      return this.isIframeLoaded;
    },
    showDialog: {
      // Override getter to prevent warning, since this dialog uses a custom showdialog state
      get() {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("session", ["showCustomUrlWithHistory"]),
    close() {
      this.showdialog = false;
    },
    onHidden() {
      this.isIframeLoaded = false;
    },
    iFrameLoaded() {
      this.isIframeLoaded = true;
    }
  }
};
</script>
