<template>
  <v-dialog
    v-model="showDialog"
    :retain-focus="false"
    :width="smallLayout ? 340 : 500"
    content-class="tournament"
  >
    <v-stepper v-if="showDialog" v-model="step">
      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <TournamentCostDetails
            :tournament="tournament"
            :action="action"
            :title="title"
          >
            <v-card-actions class="justify-center pa-3"
              ><v-btn
                depressed
                width="50%"
                color="buttonSecondary buttonSecondaryTxt--text"
                light
                @click="showDialog = false"
                >{{ $str("Cancel") }}</v-btn
              ><v-btn
                depressed
                :disabled="waiting"
                width="50%"
                light
                color="button buttonTxt--text"
                @click="verifyAction"
                >{{ $str(action.nameKey) }}</v-btn
              ></v-card-actions
            >
          </TournamentCostDetails>
        </v-stepper-content>
        <v-stepper-content class="pa-0" step="2">
          <InsufficientFunds
            :tournament="tournament"
            :action="action"
            :title="title"
            @goBack="() => (step = 1)"
          >
          </InsufficientFunds>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <ConfirmDialog ref="errorDialog" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { dialogToggle } from "@/mixins/dialogToggle";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import InsufficientFunds from "@/components/InsufficientFunds";
import TournamentCostDetails from "@/components/TournamentCostDetails";
import { TournamentActions } from "@/services/casino";

export default {
  components: {
    ConfirmDialog,
    InsufficientFunds,
    TournamentCostDetails
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "tournamentReBuyAddOn",
      step: 1,
      scrollFix: false,
      waiting: false
    };
  },
  computed: {
    ...mapGetters("balances", ["withdrawableRaw"]),
    isVisible() {
      return this.$store.getters[`dialogs/${this.name}`].isVisible;
    },
    actionType() {
      return this.$store.getters[`dialogs/${this.name}`].data.actionType;
    },
    tournament() {
      return this.$store.getters[`dialogs/${this.name}`].data.tournament;
    },
    action() {
      return this.tournament.actions[this.actionType];
    },
    title() {
      return this.$str(this.action.confirmationTitleKey);
    },
    cost() {
      return this.action.details.Cost.value;
    },
    hasSufficientFunds() {
      return this.withdrawableRaw >= this.cost;
    },
    actionHandler() {
      switch (this.actionType) {
        case TournamentActions.AddOn:
          return this.addOnTournament;
        case TournamentActions.ReBuy:
          return this.reBuyTournament;
        default:
        case TournamentActions.BuyIn:
          return this.registerTournamentBuyIn;
      }
    }
  },
  watch: {
    withdrawableRaw() {
      if (this.step > 1 && this.hasSufficientFunds) this.step = 1;
    }
  },
  methods: {
    ...mapActions("dialogs", ["showSuccess"]),
    ...mapActions("tournaments", [
      "reBuyTournament",
      "addOnTournament",
      "registerTournamentBuyIn"
    ]),
    showInsufficientFunds() {
      this.step = 2;
    },
    onShown() {
      if (!this.hasSufficientFunds) {
        this.showInsufficientFunds();
      } else {
        this.step = 1;
      }
    },
    async verifyAction() {
      this.waiting = true;
      if (this.hasSufficientFunds) {
        await this.doAction();
      } else {
        this.showInsufficientFunds();
      }
      this.waiting = false;
    },
    async doAction() {
      const registerResult = await this.actionHandler({
        tournament: this.tournament
      });

      this.showDialog = false;

      if (registerResult.HasErrors) {
        this.$refs.errorDialog.openAlert({
          message: registerResult.Message
        });
      } else {
        this.showSuccess({
          successMessage: this.$str(this.action.successKey),
          scrollFix: false
        });
      }
    }
  }
};
</script>
<style scoped>
::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0.5rem;
}

.v-chip {
  width: 100%;
  text-align: right;
}
</style>
