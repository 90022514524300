<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="smallLayout"
    max-width="30%"
    content-class="casinoDialog"
    overlay-opacity="0.9"
  >
    <v-card v-if="showDialog" class="elevation-12">
      <DialogTitleBar :title="title" @close="showDialog = false" />
      <v-container v-if="casinoJackpot.Status" no-gutters fluid class="py-0">
        <v-card
          class="CasinoJackpotBanner rounded px-1 py-0 d-flex align-center"
          :style="backgroundStyle"
        >
          <v-row
            no-gutters
            class="d-flex flex-row flex-nowrap align-center justify-start mx-0"
          >
            <v-img
              width="65px"
              max-width="65px"
              :src="require('@/assets/lobbyjackpot.png')"
            />

            <v-container
              class="d-flex flex-column align-start justify-center pl-1 mx-0"
              style="width: 100px; max-width: 100px"
            >
              <p
                class="text-left jackpotPrizeFormatted casinoJackpotAmount--text  mb-0 mx-0"
              >
                <span key="x">{{ $cur(jackpotPrizeDisplay) }}</span>
              </p>
            </v-container>
            <v-spacer />
            <CasinoJackpotOptOutButton v-if="casinoJackpotUser.IsOptIn" />
            <CasinoJackpotOptInButton v-else-if="canOptIn" />
            <v-container
              class="d-flex justify-center pa-0 mr-1 ml-3 infoButton"
              style="max-width: 28px"
            >
              <CasinoJackpotInfoButton v-if="!playerIsRestricted" />
            </v-container>
          </v-row>
        </v-card>
      </v-container>
      <CasinoJackpotRecentWinnerList v-if="hasRecentWinners" />
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import CasinoJackpotInfoButton from "@/components/casinoJackpot/CasinoJackpotInfoButton.vue";
import CasinoJackpotOptInButton from "@/components/casinoJackpot/CasinoJackpotOptInButton.vue";
import CasinoJackpotOptOutButton from "@/components/casinoJackpot/CasinoJackpotOptOutButton.vue";
import CasinoJackpotRecentWinnerList from "@/components/casinoJackpot/CasinoJackpotRecentWinnerList";
import { mapGetters } from "vuex";

export default {
  components: {
    CasinoJackpotInfoButton,
    CasinoJackpotOptInButton,
    CasinoJackpotOptOutButton,
    CasinoJackpotRecentWinnerList
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "casinoJackpot"
    };
  },
  computed: {
    ...mapGetters("customizations", ["specialThemes"]),
    ...mapGetters("session", ["loggedIn", "playForMoney"]),
    ...mapGetters("casinoJackpot", [
      "casinoJackpot",
      "casinoJackpotUser",
      "casinoJackpotWinners",
      "jackpotPrizeDisplay",
      "playerIsRestricted"
    ]),
    canOptIn() {
      return (
        this.loggedIn &&
        this.playForMoney &&
        !this.casinoJackpotUser.IsOptIn &&
        !this.playerIsRestricted
      );
    },
    hasRecentWinners() {
      return this.casinoJackpotWinners.length > 0;
    },
    title() {
      return this.$store.getters["dialogs/casinoJackpot"].data.title;
    },
    backgroundStyle() {
      const specialTheme = this.specialThemes && this.specialThemes[this.$mode];
      const imageUrl = this.hasRecentWinners
        ? specialTheme?.specialThemeLobbyJackpotWithRecentWinnersURL
        : specialTheme?.specialThemeLobbyJackpotNoRecentWinnersURL;

      if (imageUrl) {
        return {
          backgroundImage: `url(${imageUrl}), linear-gradient(var(--v-casinoJackpotBackgroundGradientColor1-base),
      var(--v-casinoJackpotBackgroundGradientColor2-base))`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        };
      } else {
        return `background-image: linear-gradient(var(--v-casinoJackpotBackgroundGradientColor1-base), var(--v-casinoJackpotBackgroundGradientColor2-base))`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .CasinoJackpotBanner {
  height: 100%;
  width: 100%;
  min-height: 60px;
  height: 60px;
  background: var(--v-header-base);

  .row {
    height: 100%;
    width: 100%;
  }
}

.v-card {
  border: none !important;
}

::v-deep .casinoDialog:not(.v-dialog--fullscreen) {
  max-height: 95vh !important;
  min-width: 400px !important;
  overflow: hidden;
}
</style>
