<template>
  <v-dialog
    v-model="showDialog"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
    max-width="70%"
  >
    <v-card
      v-if="showDialog"
      class="elevation-12"
      height="100%"
      :style="smallLayout ? '' : 'min-height:600px'"
    >
      <DialogTitleBar :title="title" @close="showDialog = false" />
      <v-card-text
        class="pa-0"
        style="height: 100%;"
        :style="smallLayout ? '' : 'min-height:600px;'"
      >
        <v-container v-if="showLoadingPanel" fill-height white>
          <v-row justify="center"
            ><v-progress-circular
              color="grey"
              indeterminate
            ></v-progress-circular
          ></v-row>
        </v-container>
        <iframe
          v-show="showiFrame"
          width="100%"
          height="92%"
          :src="sourceUrl"
          style="background-color: white;"
          :style="smallLayout ? '' : 'min-height:600px;'"
          :allow="properties"
          @load="iFrameLoaded"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { checkUrlProtocol } from "@/services/casino";
import { mapGetters } from "vuex";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "customUrl",
      isIframeLoaded: false
    };
  },
  computed: {
    ...mapGetters("lobbyMenus", ["allowProperties"]),
    title() {
      return this.$store.getters["dialogs/customUrl"].data.title;
    },
    sourceUrl() {
      return checkUrlProtocol(
        CDK.getReplacedURLParameters(
          this.$store.getters["dialogs/customUrl"].data.source
        )
      );
    },
    showLoadingPanel() {
      return !this.isIframeLoaded;
    },
    showiFrame() {
      return this.isIframeLoaded;
    },
    properties() {
      if (this.$store.getters["dialogs/customUrl"].data.isSandwichMenuRequest) {
        return this.allowProperties;
      }
      return "";
    }
  },
  methods: {
    onHidden() {
      this.isIframeLoaded = false;
    },
    iFrameLoaded() {
      this.isIframeLoaded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border: none;
}
</style>
