<template>
  <v-container class="pa-0">
    <SandwichMenu ref="sandwichMenu" />

    <v-app-bar
      v-if="this.$route.name === 'tournaments' || isTournamentsOnly"
      id="mainViewHeader"
      height="80%"
      flat
      app
      class="header"
    >
      <v-toolbar-title v-if="!isTournamentsOnly" class="align-self-center">
        <v-img
          :src="tournamentLogo"
          class="mx-1"
          max-height="56"
          max-width="160"
          contain
          @click="goHome"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-show="loggedIn && playForMoney"
        class="align-self-center ml-2"
        color="button buttonTxt--text"
        small
        max-width="25vw"
        @click="openDeposit"
        >{{ $str("DepositPage") }}</v-btn
      >
      <v-btn icon @click="closeTournaments"><v-icon>mdi-close</v-icon></v-btn>
    </v-app-bar>

    <v-app-bar
      v-else
      id="mainViewHeader"
      height="59px"
      flat
      app
      class="header"
      :style="headerStyle"
    >
      <v-toolbar-title class="align-self-center">
        <v-img
          :src="this.$vuetify.theme.dark ? casinoLogo : casinoLogoLight"
          max-height="56"
          max-width="160"
          contain
          @click="goHome"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-show="signupEnabled && !loggedIn && restoreSessionCompleted"
        id="mainViewSignUpBtn"
        class="align-self-center mx-2 pa-0"
        color="button"
        height="40px"
        max-width="25vw"
        outlined
        :style="signUpStyle"
        @click="openSignUp"
        >{{ $str("SignUpPage") }}</v-btn
      >
      <v-btn
        v-show="!loggedIn && restoreSessionCompleted"
        id="mainViewLoginBtn"
        class="align-self-center"
        color="button buttonTxt--text"
        height="40px"
        max-width="25vw"
        :style="loginStyle"
        @click="openSignIn"
        >{{ $str("LoginPage") }}</v-btn
      >
      <v-btn
        v-show="loggedIn && playForMoney && isMobileCashierEnabled"
        id="mainViewCashierBtn"
        class="align-self-center mx-2"
        color="button buttonTxt--text"
        height="40px"
        max-width="25vw"
        @click="openDeposit"
        >{{ $str("DepositPage") }}</v-btn
      >
      <v-btn
        v-if="loggedIn && !playForMoney"
        id="mainViewPlayForRealBtn"
        class="align-self-center mr-2"
        color="button buttonTxt--text"
        height="40px"
        max-width="25vw"
        style="font-size: 14px"
        @click="switchToReal"
        >{{ $strResourceService("PlayForReal") }}</v-btn
      >
      <v-btn
        v-show="loggedIn"
        id="mainViewMessagesBtn"
        x-small
        icon
        value="messages"
        class="caption align-self-center mx-2"
        @click="showMessageCenterList"
      >
        <v-badge
          :content="unreadMessagesCount"
          :value="unreadMessagesCount"
          color="red"
          offset-x="10"
          offset-y="10"
        >
          <v-icon small>{{ "$Messages" }}</v-icon>
        </v-badge>
      </v-btn>

      <v-btn
        id="mainViewSearchBtn"
        class="mx-2"
        x-small
        icon
        @click.stop="showSearch"
      >
        <v-icon>$Search</v-icon>
      </v-btn>

      <v-app-bar-nav-icon
        id="mainViewMenuBtn"
        x-small
        class="align-self-center mx-2"
        @click.stop="$refs.sandwichMenu.show"
      >
      </v-app-bar-nav-icon>
    </v-app-bar>
  </v-container>
</template>

<script>
import SandwichMenu from "@/components/SandwichMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { SandwichMenu },
  computed: {
    ...mapGetters("session", [
      "connected",
      "loggedIn",
      "playForMoney",
      "restoreSessionCompleted"
    ]),
    ...mapGetters("accountFields", ["signupEnabled"]),
    ...mapGetters("messageCenter", ["unreadMessagesCount"]),
    ...mapGetters("cashier", ["isMobileCashierEnabled"]),
    ...mapGetters("customizations", [
      "casinoLogo",
      "casinoLogoLight",
      "tournamentLogo",
      "isTournamentsOnly",
      "specialThemes"
    ]),
    signUpStyle() {
      return {
        fontSize: "14px"
      };
    },
    loginStyle() {
      return {
        fontSize: "14px"
      };
    },
    headerStyle() {
      const background =
        this.specialThemes &&
        this.specialThemes[this.$mode] &&
        this.specialThemes[this.$mode].specialThemeHeaderURL
          ? {
              backgroundImage: `url(${
                this.specialThemes[this.$mode].specialThemeHeaderURL
              })`,
              backgroundSize: "cover"
            }
          : {};
      return background;
    }
  },
  methods: {
    ...mapActions("dialogs", ["showMessageCenterList", "showSearch"]),
    ...mapActions("session", ["switchToReal"]),
    ...mapActions("tournaments", ["resetSortAndFilter"]),
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    openSignIn() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
    },
    openSignUp() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
    },
    closeTournaments() {
      this.resetSortAndFilter();
      this.goHome();
    }
  }
};
</script>

<style scoped>
.v-btn--contained {
  box-shadow: none;
}
</style>
