/**
 * Processes a special format of URL parameter used to launch actions
 *
 * @see CDK.js - CDK.LobbyURLCommands
 */

import { LobbyActions } from "./lobbyActions";

const commandAction = {
  [CDK.LobbyURLCommands.exitGame]: LobbyActions.ExitGame,
  [CDK.LobbyURLCommands.switchToRealFromGame]:
    LobbyActions.SwitchToRealFromGame,
  [CDK.LobbyURLCommands.reloadGame]: LobbyActions.ReloadGame,
  [CDK.LobbyURLCommands.loadCashier]: LobbyActions.Deposit,
  [CDK.LobbyURLCommands.loadWithdraw]: LobbyActions.Withdraw,
  [CDK.LobbyURLCommands.loadDeposit]: LobbyActions.Deposit,
  [CDK.LobbyURLCommands.loginUser]: LobbyActions.SignIn,
  [CDK.LobbyURLCommands.signUpForm]: LobbyActions.SignUp,
  [CDK.LobbyURLCommands.showMessageCenter]: LobbyActions.MessageCenter,
  [CDK.LobbyURLCommands.couponRedeem]: LobbyActions.RedeemCoupon,
  [CDK.LobbyURLCommands.showCompPoints]: LobbyActions.CompPoints,
  [CDK.LobbyURLCommands.loadGame]: LobbyActions.LaunchGame,
  [CDK.LobbyURLCommands.signOut]: LobbyActions.SignOut,
  [CDK.LobbyURLCommands.showTournament]: LobbyActions.ShowTournament,
  [CDK.LobbyURLCommands.loadWithdrawWithRedirect]:
    LobbyActions.WithdrawWithRedirect,
  [CDK.LobbyURLCommands.loadDepositWithRedirect]:
    LobbyActions.DepositWithRedirect
};

const getCommandParams = (command, queryString) => {
  if (!queryString) {
    return {};
  }

  switch (command) {
    case CDK.LobbyURLCommands.loginUser:
      return { login: queryString.get("pID") };
    case CDK.LobbyURLCommands.couponRedeem:
      return { couponCode: queryString.get("coupon") };
    case CDK.LobbyURLCommands.loadGame:
      return { gameId: queryString.get("gameId") };
    case CDK.LobbyURLCommands.showTournament:
      return { tournamentId: parseInt(queryString.get("tournamentId")) };
    case CDK.LobbyURLCommands.loadWithdrawWithRedirect:
    case CDK.LobbyURLCommands.loadDepositWithRedirect: {
      const param = queryString.get("redirectUrl")?.replaceAll("'", "");

      if (param && typeof param !== "undefined") {
        return { redirectUrl: param };
      } else {
        return {};
      }
    }
  }
};

export const getActionFromUrlCommand = (urlCommand, queryString) => {
  // Example urlCommand: "#/couponRedeem&coupon=THECODE"
  const type = commandAction[urlCommand];
  const params = getCommandParams(urlCommand, queryString);

  return { type, params };
};
