/**
 * Casino Lobby Components, configured in the Admin
 * @see Admin -> Configuration -> Lobby -> Lobby Settings -> Components
 */

const state = {
  /**
   * A map of component names to component data
   *
   * Component data structure:
   * { Hidden, ID, Name, URL, Value }
   */
  components: {},

  legalNoticesURL:
    "https://www.gamehelponline.com/A/EN/_common/LegalNotices.htm",
  termsConditionsURL:
    "https://www.gamehelponline.com/A/EN/_common/LegalNotices.htm",

  privacyPolicyURL: null
};

const getters = {
  components: state => state.components,
  componentByName: state => name => {
    assertValidComponent("componentByName", name);
    return state.components[name];
  },
  componentIsHidden: state => name => {
    assertValidComponent("ComponentIsHidden", name);
    return state.components[name] ? state.components[name].Hidden : true;
  },
  componentValue: state => name => {
    assertValidComponent("componentValue", name);
    return state.components[name]?.Value;
  },
  componentUrl: state => name => {
    assertValidComponent("componentUrl", name);
    return state.components[name]?.URL;
  },
  legalNoticesUrl: state => state.legalNoticesURL,
  termsConditionsURL: state => state.termsConditionsURL,
  privacyPolicyURL: state => state.privacyPolicyURL
};

const actions = {
  async initialize({ commit }) {
    CDK.getLobbyComponents().then(components => {
      // Check result
      if (!components || Object.keys(components).length === 0) {
        window.console.warn(
          "lobbyComponents: received empty list of components from server!"
        );
        return;
      }

      // Update components in our store
      commit("updateComponents", components);

      // Use the custom legal notices URL if we have one, otherwise
      //  we need to get one from the CDK server
      if (
        components["Custom Legal Notices"]?.URL &&
        !components["Custom Legal Notices"].Hidden
      ) {
        commit("updateLegalNoticesURL", components["Custom Legal Notices"].URL);
      } else {
        CDK.invokeHubMethod(
          CDK.getLobbyHub(),
          CDK.LobbyHubMethods.GetHelpUrl
        ).then(response => {
          const url = String.format(
            "{0}{1}/{2}/{3}/LegalNotices.htm",
            response,
            "A",
            CDK.getLanguageCode(),
            "_common"
          );
          commit("updateLegalNoticesURL", url);
        });
      }

      // Use the custom terms and conditions URL if we have one
      // otherwise legal notices is used
      if (
        components["*Menu-Terms and Conditions"]?.URL &&
        !components["*Menu-Terms and Conditions"].Hidden
      ) {
        commit(
          "updateTermsConditionsURL",
          components["*Menu-Terms and Conditions"].URL
        );
      } else commit("updateTermsConditionsURL", this.legalNoticesURL);

      if (
        components["Privacy Policy"]?.URL &&
        !components["Privacy Policy"].Hidden
      ) {
        commit("updatePrivacyPolicyURL", components["Privacy Policy"].URL);
      }
    });
  }
};

const mutations = {
  updateComponents(state, components) {
    state.components = components;
  },
  updateLegalNoticesURL(state, url) {
    state.legalNoticesURL = url;
  },
  updateTermsConditionsURL(state, url) {
    state.termsConditionsURL = url;
  },
  updatePrivacyPolicyURL(state, url) {
    state.privacyPolicyURL = url;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

//////////////////////////////////////////////////////////////////////////////
// Module Helpers
//////////////////////////////////////////////////////////////////////////////
const assertValidComponent = function(method, name) {
  if (
    Object.keys(state.components).length > 0 &&
    !state.components.hasOwnProperty(name)
  ) {
    window.console.warn(
      "lobbyComponents:" + method + " called with unknown component: " + name
    );
  }
};
