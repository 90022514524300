/**
 * Player balances
 */

const state = {
  balance: 0,
  bonus: 0,
  compPoints: 0,
  playthrough: 0,
  withdrawable: 0,
  withdrawableRaw: 0,
  compPointsDisabled: false,
  currenciesToDisplay: [],
  defaultCurrencyToDisplay: {}
};

const getters = {
  balance: state => state.balance,
  bonus: state => state.bonus,
  compPoints: state => Math.trunc(state.compPoints),
  playthrough: state => state.playthrough,
  withdrawable: state => state.withdrawable,
  withdrawableRaw: state => state.withdrawableRaw,
  compPointsDisabled: state => state.compPointsDisabled,
  currenciesToDisplay: state => state.currenciesToDisplay,
  defaultCurrencyToDisplay: state => state.defaultCurrencyToDisplay
};

const actions = {
  async initialize({ commit, dispatch }) {
    CDK.on(CDK.Events.balanceChanged, b => commit("updateBalance", b));
    CDK.on(CDK.Events.bonusBalanceChanged, b => commit("updateBonus", b));
    CDK.on(
      CDK.Events.compPointsChanged,
      async () =>
        await CDK.getCompPoints(true, true).then(compPoints => {
          commit("updateCompPoints", compPoints);
        })
    );
    CDK.on(CDK.Events.playthroughBalanceChanged, b =>
      commit("updatePlaythrough", b)
    );
    CDK.on(CDK.Events.withdrawableBalanceChanged, b =>
      commit("updateWithdrawable", b)
    );
    CDK.on(CDK.Events.redeemCompPointsDisabled, () =>
      commit("updateCompPointsDisabled", true)
    );
    CDK.on(CDK.Events.redeemCompPointsEnabled, () =>
      commit("updateCompPointsDisabled", false)
    );
    //initialize listeners database notify hub
    CDK.on(CDK.Events.loggedIn, async () => {
      dispatch("registerBalanceHubListener");
      await dispatch("getCurrenciesToDisplay");
    });
    CDK.on(CDK.Events.loggedOut, () => {
      dispatch("unRegisterBalanceHubListener");
    });
  },
  async registerBalanceHubListener() {
    const balanceHub = CDK.getBalanceHub();
    CDK.invokeHubMethodWithRetryEx(
      {
        hub: balanceHub,
        method: "Register",
        cache: false
      },
      await CDK.getPlayerPID(),
      await CDK.isPlayingForMoney(),
      await CDK.getSessionID(),
      false
    ).fail(function() {
      window.console.log("Error registering balance hub.");
    });
  },
  unRegisterBalanceHubListener({ commit }) {
    const balanceHub = CDK.getBalanceHub();
    if (CDK.isConnected()) {
      CDK.invokeHubMethodWithRetryEx({
        hub: balanceHub,
        method: "UnRegister",
        cache: false
      }).fail(function() {
        window.console.log("Error registering balance hub.");
      });
    }
    commit("clearAllBalances");
  },
  async getCurrenciesToDisplay({ commit }) {
    let response = await CDK.getCurrenciesToDisplay();
    const defaultCurrency = await CDK.getPlayerCurrencyToDisplay();
    const playerCurrency = CDK.getCurrencyCode();
    response = response.filter(currency => currency.Key !== playerCurrency);
    const currencyToDisplay = response.find(
      currency => currency.Key === defaultCurrency
    );

    commit("updateCurrenciesToDisplay", response);
    commit("updateDefaultCurrencyToDisplay", currencyToDisplay);
  },
  updatePlayerCurrency({ commit, getters }, currencyToUpdate) {
    const currencyToDisplay = getters.currenciesToDisplay.find(
      currency => currency.Value === currencyToUpdate
    );
    commit("updateDefaultCurrencyToDisplay", currencyToDisplay);
    CDK.UpdatePlayerCurrencyToDisplay(getters.defaultCurrencyToDisplay.Key);
  }
};

const mutations = {
  updateBalance(state, balance) {
    state.balance = balance;
  },
  updateBonus(state, bonus) {
    state.bonus = bonus;
  },
  updateCompPoints(state, compPoints) {
    state.compPoints = compPoints;
  },
  updatePlaythrough(state, playthrough) {
    state.playthrough = playthrough;
  },
  updateWithdrawable(state, withdrawable) {
    state.withdrawable = withdrawable;
    state.withdrawableRaw = CDK.convertCurrencyValueForIngestion(withdrawable);
  },
  updateCompPointsDisabled(state, compPointsDisabled) {
    state.compPointsDisabled = compPointsDisabled;
  },
  updateCurrenciesToDisplay(state, fiatCurrencies) {
    state.currenciesToDisplay = fiatCurrencies;
  },
  updateDefaultCurrencyToDisplay(state, defaultCurrencyToDisplay) {
    state.defaultCurrencyToDisplay = defaultCurrencyToDisplay;
  },
  clearAllBalances(state) {
    state.balance = 0;
    state.bonus = 0;
    state.compPoints = 0;
    state.playthrough = 0;
    state.withdrawable = 0;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
