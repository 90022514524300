<template>
  <v-dialog
    v-model="showDialog"
    :width="smallLayout ? 340 : 500"
    content-class="tournament"
  >
    <v-card v-if="showDialog" class="pa-2" color="tournamentDetail">
      <v-card-title
        class="tournamentSuccess--text text-uppercase font-weight-bold"
        style="flex-wrap: nowrap; word-break: break-word;"
        ><v-icon color="tournamentSuccess" x-large class="ml-n2 mr-2"
          >mdi-check-box-outline</v-icon
        >
        {{ $str("RefundConfirmationTitle") }}</v-card-title
      >
      <v-card-subtitle
        class="tournamentDetailTxt--text text-left pt-2 pb-0 font-weight-bold"
        >{{ tournament.name }}</v-card-subtitle
      >
      <v-card-text class="tournamentDetailTxt--text py-0 caption">
        <v-container class="pt-0"
          ><v-row align="center">
            <v-col class="pl-0" cols="6"
              ><v-img
                class="rounded"
                :src="gameIcon(tournament.game)"
                :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
                contain
              ></v-img
            ></v-col>
            <v-col class="pa-0" align="center"
              ><h4>{{ $str("RefundAmount") }}</h4>
              <v-chip
                class="justify-end"
                label
                color="tournamentInfo tournamentInfoTxt--text"
                >{{ $tournamentCur(tournament.buyIn) }}</v-chip
              ></v-col
            >
          </v-row>
          <v-row class="body-2"
            >{{ $str("TournamentRefundCompleted") }}.</v-row
          ></v-container
        >
      </v-card-text>
      <v-card-actions class="justify-center px-4 pt-0"
        ><v-btn
          depressed
          width="50%"
          color="buttonSecondary buttonSecondaryTxt--text"
          light
          @click="showDialog = false"
          >{{ $str("Cancel") }}</v-btn
        ><v-btn
          depressed
          :disabled="waiting"
          width="50%"
          light
          color="button buttonTxt--text"
          @click.stop="refund"
          >{{ $str("Refund") }}</v-btn
        ></v-card-actions
      >
    </v-card>
    <ConfirmDialog ref="errorDialog" />
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "refund",
      tournament: {},
      scrollFix: false,
      waiting: false
    };
  },
  computed: {
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    ...mapActions("dialogs", ["showSuccess"]),
    ...mapActions("tournaments", ["unregisterTournament"]),
    onShown() {
      const tournament = this.$store.getters["dialogs/refund"].data.tournament;
      if (tournament) {
        this.tournament = tournament;
      }
    },
    async refund() {
      this.waiting = true;
      const result = await this.unregisterTournament({
        tournament: this.tournament
      });
      this.waiting = false;
      this.showDialog = false;

      if (result.HasErrors) {
        this.$refs.errorDialog.openAlert({
          message: result.Message
        });
      } else {
        this.showSuccess({
          successMessage: this.$str("tournamentRefundSuccess"),
          scrollFix: false
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 1vw;
}
</style>
