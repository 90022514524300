<template>
  <div class="text-align">
    <div>
      <v-icon
        size="small"
        :color="isPasswordValid ? 'green' : password.length > 0 ? 'red' : ''"
        class="icon"
        >{{ isPasswordValid ? "mdi-check" : "mdi-alert" }}</v-icon
      >
      <span
        class="font-size"
        :style="{
          color: !isPasswordValid && password.length > 0 ? 'red' : ''
        }"
        >{{ $strResourceService("PasswordRequirements") }}</span
      >
    </div>
    <ul>
      <li>
        <v-icon size="small" :color="atLeastCharacters ? 'green' : ''">
          {{
            atLeastCharacters
              ? " mdi-check-circle-outline"
              : "mdi-circle-outline"
          }}</v-icon
        >{{ passwordCharacters }}
      </li>
      <li>
        <v-icon size="small" :color="isLower ? 'green' : ''">
          {{
            isLower ? " mdi-check-circle-outline" : "mdi-circle-outline"
          }}</v-icon
        >{{ $strResourceService("OneLowerCaseLetter") }}
      </li>
      <li>
        <v-icon size="small" :color="isCapital ? 'green' : ''">
          {{
            isCapital ? " mdi-check-circle-outline" : "mdi-circle-outline"
          }}</v-icon
        >{{ $strResourceService("OneUpperCaseLetter") }}
      </li>
      <li>
        <v-icon size="small" :color="isSpecial ? 'green' : ''">
          {{
            isSpecial ? " mdi-check-circle-outline" : "mdi-circle-outline"
          }}</v-icon
        >{{ $strResourceService("OneSpecialCharacter") }}
      </li>
      <li>
        <v-icon size="small" :color="isNumber ? 'green' : ''">
          {{
            isNumber ? " mdi-check-circle-outline" : "mdi-circle-outline"
          }}</v-icon
        >{{ $strResourceService("OneNumber") }}
      </li>
    </ul>
    <br />
  </div>
</template>
<script>
export default {
  props: {
    password: { type: String, required: true },
    minPassword: { type: Number, required: true }
  },
  computed: {
    isCapital() {
      const regex = /[A-Z]/g;
      return regex.test(this.password);
    },
    isLower() {
      const regex = /[a-z]/g;
      return regex.test(this.password);
    },
    atLeastCharacters() {
      return this.password.length >= this.minPassword;
    },
    isSpecial() {
      const regex = /[^A-Za-z0-9]/g;
      return regex.test(this.password);
    },
    isNumber() {
      const regex = /\d/;
      return regex.test(this.password);
    },
    isPasswordValid() {
      return (
        this.isCapital &&
        this.isLower &&
        this.atLeastCharacters &&
        this.isSpecial &&
        this.isNumber
      );
    },
    passwordCharacters() {
      return this.$strResourceService("AtLeastMinCharacters").replace(
        "{0}",
        this.minPassword
      );
    }
  },
  mounted() {
    this.$emit("passwordMounted");
  }
};
</script>
<style lang="scss" scoped>
ul {
  li {
    list-style: none;
    font-size: 0.8rem;
    i {
      padding-right: 0.8rem;
    }
  }
}
.font-size {
  font-size: 0.8rem;
}
.icon {
  padding-right: 0.5rem;
}
.text-align {
  text-align: initial !important;
}
</style>
