/**
 * Options needed to open cashier dialogs (deposit, withdraw, comp points)
 */

const state = {
  depositOptions: {},
  withdrawOptions: {},
  couponOptions: {},
  compPointSettings: {},
  isCashierEnabled: false,
  isMobileCashierEnabled: false,
  forceSmartPhoneCashierInTablet: false,
  error: "",
  allowProperties: ""
};

const getters = {
  depositOptions: state => state.depositOptions,
  withdrawOptions: state => state.withdrawOptions,
  couponOptions: state => state.couponOptions,
  compPointSettings: state => state.compPointSettings,
  isCashierEnabled: state => state.isCashierEnabled,
  isMobileCashierEnabled: state => state.isMobileCashierEnabled,
  forceSmartPhoneCashierInTablet: state => state.forceSmartPhoneCashierInTablet,
  error: state => state.error,
  allowProperties: state => state.allowProperties
};

const actions = {
  async initialize(context) {
    CDK.on(CDK.Events.loggedIn, async () => {
      try {
        const depositResponse = await CDK.getCashierActionSettings("deposit");
        context.commit("updateDepositOptions", depositResponse);
        const withdrawResponse = await CDK.getCashierActionSettings("payout");
        context.commit("updateWithdrawOptions", withdrawResponse);
        const CouponResponse = await CDK.getCashierActionSettings("coupon");
        context.commit("updateCouponOptions", CouponResponse);
        const compPointResponse = await CDK.getCompPointSettings();
        context.commit("updateCompPointSettings", compPointResponse);
        const isCashierEnabledResponse = await CDK.isCashierEnabled();
        context.commit(
          "updateIsCashierEnabledResponse",
          isCashierEnabledResponse
        );
        const isMobileCashierEnabledResponse = await CDK.isMobileCashierEnabled();
        context.commit(
          "updateIsMobileCashierEnabledResponse",
          isMobileCashierEnabledResponse
        );
        const forceSmartPhoneCashierInTabletResponse = await CDK.forceSmartPhoneCashierInTablet();
        context.commit(
          "updateforceSmartPhoneCashierInTabletResponse",
          forceSmartPhoneCashierInTabletResponse
        );
        context.commit(
          "updateAllowProperties",
          await CDK.getAllowPropertiesForCustomPage(
            context.rootGetters["session/playForMoney"]
          )
        );
      } catch (error) {
        context.commit("updateError", error.Message);
      }
    });
  }
};

const mutations = {
  updateDepositOptions(state, depositOptions) {
    state.depositOptions = depositOptions;
  },
  updateWithdrawOptions(state, withdrawOptions) {
    state.withdrawOptions = withdrawOptions;
  },
  updateCouponOptions(state, couponOptions) {
    state.couponOptions = couponOptions;
  },
  updateCompPointSettings(state, compPointSettings) {
    state.compPointSettings = compPointSettings;
  },
  updateIsCashierEnabledResponse(state, isCashierEnabled) {
    state.isCashierEnabled = isCashierEnabled;
  },
  updateIsMobileCashierEnabledResponse(state, isMobileCashierEnabled) {
    state.isMobileCashierEnabled = isMobileCashierEnabled;
  },
  updateforceSmartPhoneCashierInTabletResponse(
    state,
    forceSmartPhoneCashierInTablet
  ) {
    state.forceSmartPhoneCashierInTablet = forceSmartPhoneCashierInTablet;
  },
  updateError(state, error) {
    state.error = error;
  },
  updateAllowProperties(state, allowProperties) {
    state.allowProperties = allowProperties;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
