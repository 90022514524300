/**
 * Message Center messages, force on entry, force on exit, etc.
 */

import Vue from "vue";

const state = {
  unreadMessagesCount: 0,
  playerMessages: [],
  playerMessageText: {},
  forceOnEntryMessages: [],
  forceOnExitMessages: [],
  supressForceOnEntrance: false
};

const getters = {
  unreadMessagesCount: state => state.unreadMessagesCount,
  playerMessages: state => state.playerMessages,
  playerMessageText: state => state.playerMessageText,
  forceOnEntryMessages: state => state.forceOnEntryMessages,
  forceOnExitMessages: state => state.forceOnExitMessages,
  supressForceOnEntrance: state => state.supressForceOnEntrance
};

const actions = {
  initialize(context) {
    CDK.on(CDK.Events.loggedIn, () => {
      context.dispatch("requestPlayerMessages");
      context.dispatch("requestUnreadMessagesCount");
    });

    CDK.on(CDK.Events.loggedOut, () => context.commit("clearPlayerMessages"));

    CDK.on(CDK.Events.unreadMessagesCountChanged, count =>
      context.commit("updateUnreadMessagesCount", count)
    );

    CDK.on(CDK.Events.forceOnEntryMessagesReceived, messages =>
      context.commit("updateForceOnEntryMessages", messages)
    );
  },
  async deletePlayerMessage(context, id) {
    // Just assume delete worked to keep the UI "snappy"
    context.commit("deletePlayerMessage", id);
    await CDK.deletePlayerMessage(id);
  },
  markPlayerMessageAsRead(context, id) {
    context.commit("markPlayerMessageAsRead", id);
  },
  async requestPlayerMessages(context) {
    const messages = await CDK.getPlayerMessages();
    context.commit("updatePlayerMessages", messages);
  },
  async requestUnreadMessagesCount(context) {
    const count = await CDK.getUnreadMessagesCount();
    context.commit("updateUnreadMessagesCount", count);
  },
  async requestForceOnEntryMessages(context) {
    const messages = await CDK.getForceOnEntryMessages();
    context.commit("updateForceOnEntryMessages", messages);
  },
  async requestForceOnExitMessages(context) {
    const messages = await CDK.getForceOnExitMessages();
    context.commit("updateForceOnExitMessages", messages);
  },
  async requestPlayerMessageText(context, id) {
    // NOTE: Requesting the text of a player message automatically marks it as read,
    // so we shouldn't do it until we are about to actually display the message
    if (!context.state.playerMessageText[id]) {
      const messageText = await CDK.getPlayerMessage(id);
      context.commit("updatePlayerMessageText", { id, messageText });
    }
  },
  setSupressForceOnEntrance(context, supressForceOnEntrance) {
    context.commit("updateSupressForceOnEntrance", supressForceOnEntrance);
  }
};

const mutations = {
  deletePlayerMessage(state, id) {
    const index = state.playerMessages.findIndex(
      msg => msg.ID === parseInt(id)
    );
    if (index > -1) {
      if (!state.playerMessages[index].Read) {
        if (state.unreadMessagesCount > 0) state.unreadMessagesCount--;
      }
      state.playerMessages.splice(index, 1);
    } else {
      window.console.warn(
        "messageCenter.js: Attempted to delete unknown message! ID = " + id
      );
    }
  },
  markPlayerMessageAsRead(state, id) {
    const index = state.playerMessages.findIndex(
      msg => msg.ID === parseInt(id)
    );
    if (index > -1) {
      if (!state.playerMessages[index].Read) {
        state.playerMessages[index].Read = true;
        if (state.unreadMessagesCount > 0) state.unreadMessagesCount--;
      }
    } else {
      window.console.warn(
        "messageCenter.js: Attempted to mark as read unknown message! ID = " +
          id
      );
    }
  },
  updateUnreadMessagesCount(state, count) {
    state.unreadMessagesCount = count;
  },
  updatePlayerMessages(state, messages) {
    state.playerMessages = messages;
  },
  updatePlayerMessageText(state, { id, messageText }) {
    state.playerMessages;
    Vue.set(state.playerMessageText, id, messageText);
  },
  updateForceOnEntryMessages(state, messages) {
    state.forceOnEntryMessages = messages;
    if (messages.length === 1) {
      const messageText = messages[0].Text;
      const id = messages[0].ID;
      state.playerMessages;
      Vue.set(state.playerMessageText, id, messageText);
    }
  },
  updateForceOnExitMessages(state, messages) {
    state.forceOnExitMessages = messages;
    if (messages.length === 1) {
      const messageText = messages[0].Text;
      const id = messages[0].ID;
      state.playerMessages;
      Vue.set(state.playerMessageText, id, messageText);
    }
  },
  clearPlayerMessages(state) {
    state.unreadMessagesCount = 0;
    state.playerMessages = [];
    state.playerMessageText = {};
    state.forceOnEntryMessages = [];
    state.forceOnExitMessages = [];
    state.supressForceOnEntrance = false;
  },
  updateSupressForceOnEntrance(state, supressForceOnEntrance) {
    state.supressForceOnEntrance = supressForceOnEntrance;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
