<template>
  <v-dialog
    v-model="showDialog"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
    width="390"
  >
    <v-card
      v-if="showDialog"
      class="elevation-12"
      min-height="680px"
      :height="smallLayout && '100vh'"
    >
      <DialogTitleBar :title=title @close="showDialog = false" />
      <v-card-text class="pa-0">
        <!-- Search Controls Block -->
        <v-container class="pb-0 px-5 mt-n5">
          <v-row>
            <v-col :cols="6" class="pb-0">
              <v-text-field
                v-model.trim="startDate"
                class="required"
                :label="$str('FromLabel').replace(':', '')"
                color="inputLabelFocused"
                outlined
                dense
                hide-details="auto"
                type="date"
                @mousedown="dateChanged"
              >
                <template slot="append">
                  <v-icon size="20">$Calendar</v-icon>
                </template>
              </v-text-field>
              <div class="ml-1 days-max">{{ $str("DaysMaximum") }}</div>
            </v-col>
            <v-col :cols="6" class="pb-0">
              <v-text-field
                v-model="endDate"
                class="required"
                :label="$str('ToLabel').replace(':', '')"
                color="inputLabelFocused"
                outlined
                dense
                hide-details="auto"
                type="date"
                @mousedown="dateChanged"
              >
                <template slot="append">
                  <v-icon size="20" style="padding-top: 3px;">$Calendar</v-icon>
                </template></v-text-field
              >
            </v-col>
            <v-row align="center" justify="space-around" class="ma-0">
              <v-col cols="12">
                <v-btn
                  :disabled="!rangeValid"
                  color="buttonSecondary buttonSecondaryTxt--text py-1"
                  style="width:100% !important;"
                  @click="onSearch"
                  >{{ $str("SearchLabel") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-row>
        </v-container>

        <!-- Wait Cursor Block-->
        <v-container v-show="state === 0" class="mt-12">
          <v-row justify="center"
            ><v-progress-circular
              color="grey"
              indeterminate
            ></v-progress-circular
          ></v-row>
        </v-container>

        <!-- "No Transactions" Block -->

        <div v-if="!rangeValid">
          <h3 class="text-center pb-12">
            {{ $str("SelectDateRangeMessage") }}
          </h3>
        </div>
        <div v-else>
          <!-- Data Table Block -->
          <v-container
            v-show="records.length > 0 && state === 1"
            class="mt-0 pt-0"
          >
            <v-row class="header-row pa-0 pb-1 ma-0">
              <v-col cols="3" class="header-col text-left px-0 py-2 ma-0">{{
                $str("DateLabel")
              }}</v-col>
              <v-col class="header-col text-left px-0 py-2 ma-0">{{
                $str("TransactionLabel")
              }}</v-col>
              <v-col cols="3" class="header-col text-left px-0 py-2 ma-0">{{
                $str("AmountLabel")
              }}</v-col>
            </v-row>
            <v-row
              v-for="(record, index) in records"
              :key="index"
              class="data-row pa-0 ma-0"
            >
              <v-col cols="3" class="data-col pl-0 py-2 ma-0">{{
                record.TimeStamp
              }}</v-col>
              <v-col class="data-col pl-0 py-2 ma-0">{{
                record.Description
              }}</v-col>
              <v-col cols="3" class="data-col pl-0 py-2 ma-0">{{
                record.Amount
              }}</v-col>
            </v-row>
          </v-container>
          <v-container
            v-show="records.length === 0 && state === 1"
            class="mt-12"
          >
            <h3 class="text-center mb-12">
              {{ $str("NoTransactions") }}
            </h3>
          </v-container>
        </div>

        <!-- End Data Table Block -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { add, format, parseISO } from "date-fns";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
import displayDepositMethodName from "@/mixins/shared/displayDepositMethodName";

const formatDescriptionAndAmount = (caller, record) => {
  const recordRequestMethodName = caller.$formatDepositMethodName(record.RequestMethodName);
  const recordLedgerMethodName = caller.$formatDepositMethodName(record.LedgerMethodName);

  if (record.Reason === 7 && record.RequestAmount) {
    return {
      Description: `${recordRequestMethodName} ${
        record.RequestLedgerDescription
      } ${caller.$str("RequestedUppercase")}`,
      Amount: caller.$storageCur(record.RequestAmount)
    };
  } else if (record.Reason === 8 && record.LedgerAmount) {
    return {
      Description: `${recordLedgerMethodName} ${
        record.LedgerLedgerDescription
      } ${caller.$str("Approved")}`,
      Amount: caller.$storageCur(record.LedgerAmount)
    };
  } else if (record.Reason === 9 && record.LedgerAmount) {
    return {
      Description: `${recordLedgerMethodName} ${
        record.LedgerLedgerDescription
      } ${caller.$str("Declined")}`,
      Amount: caller.$storageCur(record.LedgerAmount)
    };
  } else if (record.Reason === 11 && record.CouponCodeRequested) {
    const couponCode = record.CouponPublishedRequested
      ? record.CouponCodeRequested
      : "";
    return {
      Description: `${caller.$str("CouponPage")} ${couponCode} ${caller.$str(
        "RequestedLowercase"
      )}`,
      Amount: ""
    };
  } else if (record.Reason === 12 && record.CouponCodeRedeemed) {
    const couponCode = record.CouponPublishedRedeemed
      ? record.CouponCodeRedeemed
      : "";
    return {
      Description: `${caller.$str("CouponPage")} ${couponCode} ${caller.$str(
        "Redeemed"
      )}`,
      Amount: caller.$storageCur(record.CouponAmount)
    };
  } else if (record.Reason === 15) {
    return {
      Description: caller.$str("CouponDeclined"),
      Amount: ""
    };
  } else if (record.Reason === 1000) {
    return {
      Description: recordRequestMethodName.replace(
        "Session Start",
        caller.$str("SessionStart")
      ),
      Amount: ""
    };
  } else if (record.Reason === 1001) {
    return {
      Description: recordRequestMethodName.replace(
        "Session End",
        caller.$str("SessionEnd")
      ),
      Amount: ""
    };
  }

  return {};
};

export default {
  mixins: [dialogToggle, displayNameValidator, displayDepositMethodName],

  data() {
    return {
      name: "accountHistory",
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      records: [],
      state: -1, // -1 initial state, 0 waiting on server, 1 received data
      rangeValid: true
    };
  },
  computed: {
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/accountHistory"].data.title, "Account History");
    }
  },
  methods: {
    async onSearch() {
      this.rangeValid = true;
      const starDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      const diffTime = endDate - starDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (starDate <= endDate && diffDays <= 31) {
        // Set state to "waiting on server"
        this.state = 0;
        // Request account history from server
        const response = await CDK.getAccountHistory(
          this.startDate,
          add(parseISO(this.endDate), { hours: 23, minutes: 59, seconds: 59 })
        );
        // Set state to "recieved data"
        this.state = 1;
        // Convert into our data array
        this.records = response.Value.Records.map(record => {
          return {
            TransactionId: record.TransactionId,
            TimeStamp: format(parseISO(record.TimeStamp), "Pp"),
            ...formatDescriptionAndAmount(this, record)
          };
        }).reverse();
      } else {
        this.rangeValid = false;
        this.records = [];
        this.state = -1; // Initial State
        return;
      }
    },
    onHidden() {},
    onShown() {
      this.startDate = format(new Date(), "yyyy-MM-dd");
      this.endDate = format(new Date(), "yyyy-MM-dd");
      this.records = [];
      this.state = -1; // Initial State
      this.rangeValid = true;
    },
    dateChanged() {
      this.rangeValid = true;
    }
  }
};
</script>

<style scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.days-max {
  font-size: 0.7rem;
  margin-top: -5px;
}
.range-max {
  font-size: 1rem;
}
.data-row {
  font-size: 0.75rem;
}

.header-row {
  font-size: 0.75rem;
  font-weight: bold;
  border-style: solid;
  border-width: 0 0 2px 0;
}

.data-col {
  border-style: solid;
  border-width: 0 0 1px 0;
}

.header-col {
  text-align: center;
}

::v-deep input[type="date"]::-webkit-inner-spin-button {
  opacity: 0;
  appearance: none;
}
::v-deep input[type="date"]::-webkit-clear-button {
  margin-right: 5px;
}

::v-deep input[type="date"]::-webkit-calendar-picker-indicator {
  right: -35px;
  position: absolute;
  width: 78px;
  height: 40px;
  color: rgba(204, 204, 204, 0);
  opacity: 0.00001;
  cursor: pointer;
  z-index: 100;
}
</style>
