<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" width="400">
    <v-card v-if="showDialog" class="elevation-12">
      <v-card-title class="justify-center" :class="largeLayout ? 'transparent' : 'navbar'">
        <v-spacer></v-spacer>
        <span :style="largeLayout ? 'margin-top: 5%;' : ''" style="text-transform: uppercase;">{{
    $strResourceService("VerifyEmail") }}</span>
        <v-spacer></v-spacer>
        <v-icon class="pa-0" :style="largeLayout ? 'margin-top: -5%;' : ''" @click.stop="showDialog = false">
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon>
      </v-card-title>
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text class="pt-2" style="text-align: justify" :min-height="largeLayout ? '405' : ''"
        v-html="VerifyOTP"></v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-text-field v-model="otp" class="required" :filled="largeLayout" :dense="largeLayout"
              color="inputLabelFocused" :placeholder="requestedFromParam ? ' ' : ''"
              v-bind="accountCreateFieldsAttributes.Otp" :label="$str('EnterOtp')"></v-text-field>
          </v-row>
          <v-row justify="center">
            <v-col cols="5">
              <v-btn class="col-11" color="button buttonTxt--text" style="margin: 0; margin-right: 1%;"
                :style="smallLayout ? 'border-radius: 0.6vh !important;' : ''" :disabled="!valid"
                @click="verifyEmailFromToken"><b style="color: white;">{{ $str("Verify") }}</b></v-btn>
            </v-col>
            <v-col cols="5">
              <v-btn class="col-11" color="buttonSecondary buttonSecondaryTxt--text"
                :style="smallLayout ? 'border-radius: 0.6vh !important;' : ''" :disabled="!valid"
                @click="closeDialog"><b style="color: white;">{{ $str("DoItLater") }}</b></v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-5">
            <a href="#" style="text-decoration: none; font" @click="resendToken">Resend OTP</a>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "emailVerification",
      login: "",
      password: "",
      otp: "",
      valid: false,
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("session", ["playerEmail"]),
    ...mapGetters("accountFields", [
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ]),
    VerifyOTP() {
      const vlogin = this.$store.getters["dialogs/emailVerification"].data
        .login;

      return `<span>
      ${this.$str("VerifyOTP")
          .replace("<Email>", vlogin)
          .replace("<Change>", "<a href='#'>(Change)</a>")}
      </span>`;
    }
  },
  methods: {
    ...mapActions("session", ["updateSessionPlayerEmail"]),
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form.validate();
    },
    isEmail(text) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(text);
    },
    isDateOlderThanOneWeek(dateString) {
      // Parse the input date string
      const inputDate = new Date(dateString);

      // Get the current date and subtract one week (7 days)
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      // Compare the dates
      return inputDate < oneWeekAgo;
    },
    async verifyEmailFromToken() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }
      this.login = this.$store.getters["dialogs/emailVerification"].data.login;
      this.password = this.$store.getters[
        "dialogs/emailVerification"
      ].data.password;
      try {
        await CDK.verifyEmailFromToken(this.otp);

        window.LobbyCore.raise(
          window.LobbyCore.Events.hideEmailVerificationPage
        );
        window.LobbyCore.raise(window.LobbyCore.Events.showConfirmOTPPage, {
          optResult: true,
          login: this.login,
          password: this.password
        });
      } catch (error) {
        window.LobbyCore.raise(
          window.LobbyCore.Events.hideEmailVerificationPage
        );
        window.LobbyCore.raise(window.LobbyCore.Events.showConfirmOTPPage, {
          optResult: false,
          login: this.login,
          password: this.password
        });
      }
    },
    async closeDialog() {
      this.$store.getters["dialogs/emailVerification"].data.login = "";
      this.$store.getters["dialogs/emailVerification"].data.password = "";
      this.$store.getters["dialogs/emailVerification"].data.hash = "";
      window.LobbyCore.raise(window.LobbyCore.Events.hideEmailVerificationPage);
    },
    async resendToken() {
      this.login = this.$store.getters["dialogs/emailVerification"].data.login;
      window.LobbyCore.raise(
        window.LobbyCore.Events.showResendOTPVerificationEmailPage,
        {
          login: this.login
        }
      );
    },
    onHidden() {
      this.validateFields = false;
      this.login = "";
      this.password = "";
      this.otp = "";
      this.$refs.form.resetValidation();
    },
    async onShown() {
      const playerData = this.$store.getters["dialogs/emailVerification"].data.playerData;
      const hash = this.$store.getters["dialogs/emailVerification"].data.hash;
      try {
        if (this.isEmail(this.$store.getters["dialogs/emailVerification"].data.login) && hash !== "") {
          await CDK.sendEmailVerificationWithHash(hash, this.$store.getters["dialogs/emailVerification"].data.login);
        }
        else if (playerData !== null  && hash !== "") {
          if (this.isDateOlderThanOneWeek(playerData.Value.SignupDate)) {
            await CDK.sendEmailVerificationWithHash(hash, playerData.Value.Email);
          }
        }
      }
      catch (error) {
        const expirationError = 33;
        if (error.ErrorCode === expirationError) {
          this.$store.dispatch("dialogs/hideAll");
        }
        this.error = error.Message;
      }
    }
  }
};
</script>

<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}
</style>
