<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <g id="favorites">
      <polygon
        class="st0"
        points="24,13.4 27.3,20.9 35.4,21.7 29.4,27.2 31.1,35.2 24,31.1 16.9,35.2 18.6,27.2 12.6,21.7 20.7,20.9 	
		"
      />
      <polyline
        class="st0"
        points="16.2,18.4 12.8,18.1 10.4,12.8 8.1,18.1 2.4,18.7 6.7,22.5 5.5,28.1 10.4,25.2 14.3,27.5 	"
      />
      <polyline
        class="st0"
        points="31.8,18.4 35.2,18.1 37.5,12.8 39.9,18.1 45.6,18.7 41.3,22.5 42.5,28.1 37.5,25.2 33.7,27.5 	"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Favorites",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Favorites" }
  }
};
</script>
<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
