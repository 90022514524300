<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
  >
    <path
      d="M44,12.4a5.16,5.16,0,0,0-7.3-7.3h0L35.4,6.4,34.1,5.1a5.16,5.16,0,0,0-7.3,7.3L35.4,21Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
    <path
      d="M3.9,10.6a4.77,4.77,0,1,0,6.8,6.7l1.2-1.2,1.2,1.2a4.74,4.74,0,0,0,6.7-6.7h0L11.9,2.7Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
    <line
      x1="8"
      y1="21.9"
      x2="15.6"
      y2="21.9"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
    <path
      d="M11.8,45.2A26.15,26.15,0,0,0,2.6,36a26.15,26.15,0,0,0,9.2-9.2A26.15,26.15,0,0,0,21,36,27.9,27.9,0,0,0,11.8,45.2Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
    <line
      x1="31.6"
      y1="45.3"
      x2="39.1"
      y2="45.3"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
    <path
      d="M39.5,33.5a2,2,0,0,0-.7.1,4.3,4.3,0,0,0-7-5A3.85,3.85,0,0,0,31,31a5,5,0,0,0,.8,2.6c-.2,0-.4-.1-.7-.1A4.3,4.3,0,1,0,35.2,39a4.33,4.33,0,1,0,8.3-2.5,4.11,4.11,0,0,0-4-3Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.16"
    />
  </svg>
</template>

<script>
export default {
  name: "TableGames",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Table Games" }
  }
};
</script>
