<template>
  <svg id="ss" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <rect
      x="15.44"
      y="6.92"
      width="17.11"
      height="24.21"
      rx="2.23"
      fill="none"
      :stroke="iconColor"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <line
      x1="18.54"
      y1="9.56"
      x2="18.54"
      y2="12.07"
      fill="none"
      :stroke="iconColor"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <line
      x1="29.46"
      y1="25.99"
      x2="29.46"
      y2="28.49"
      fill="none"
      :stroke="iconColor"
      stroke-miterlimit="10"
      stroke-width="1.44"
    />
    <path
      d="M28.48,16a2.71,2.71,0,0,0-3.82,0l-.66.66L23.34,16a2.68,2.68,0,1,0-3.78,3.79L24,24.21l4.44-4.43A2.72,2.72,0,0,0,28.48,16Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <path
      d="M32.84,12.93h6.39a2.61,2.61,0,0,1,2.6,2.61V34.76"
      fill="none"
      :stroke="iconColor"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <path
      d="M6.16,34.76V15.54a2.62,2.62,0,0,1,2.61-2.61h3.79"
      fill="none"
      :stroke="iconColor"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <path
      d="M2,37.63s.76,3.45,3.28,3.45H42.71c2.52,0,3.28-3.45,3.28-3.45Z"
      fill="none"
      :stroke="iconColor"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
  </svg>
</template>

<script>
export default {
  name: "GameMenuIcon",
  props: {
    iconColor: {
      type: String,
      default: "currentColor"
    },
    displayName: { type: String, default: "Games" }
  }
};
</script>
