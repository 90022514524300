<template>
  <v-container v-if="menuIsVisible(menuId)" fluid class="pa-0 ma-0">
    <v-container
      no-gutters
      class="d-flex pa-0 pb-4 container--fluid justify-center"
    >
      <v-row class="gamesTitleRow d-flex align-center mx-4 mx-md-8">
        <router-link
          :id="menuName"
          class="d-flex align-center"
          :to="`games/${menuId}`"
          tag="button"
          justify-self="start"
          align-self="center"
        >
          <v-icon v-if="gameFamilyIcons[menu.name]" class="mr-2 gameFamilyIcon"
            >{{ gameFamilyIcons[menu.name] }}
          </v-icon>
          <!-- eslint-disable vue/no-v-html -->
          <i v-else class="mr-2 " v-html="updatedCustomIconSvg(menu.Image)"></i>
          <!--eslint-enable-->
          <span> {{ menu.displayName }}</span
          ><v-icon medium>mdi-chevron-right</v-icon></router-link
        >
        <v-spacer />
        <v-container v-if="hasPrev || hasNext" class="btn--group ma-0 pa-0">
          <v-btn
            :class="{ active: hasPrev, inactive: !hasPrev }"
            class="gamesBackBtn"
            :disabled="!hasPrev"
            icon
            @click="prev"
            ><v-icon small>mdi-arrow-left</v-icon></v-btn
          >
          <v-btn
            :class="{ active: hasNext, inactive: !hasNext }"
            class="gamesNextBtn"
            icon
            :disabled="!hasNext"
            @click="next"
            ><v-icon small>mdi-arrow-right</v-icon></v-btn
          ></v-container
        >
      </v-row>
    </v-container>
    <v-container
      class="container--fluid d-flex justify-start pa-0 pb-4 px-4 px-md-8"
      style="position: relative;"
      no-gutters
    >
      <div
        v-if="hasNext"
        id="gamesRowOverlay"
        ref="gamesRowOverlay"
        class="fade"
      ></div>
      <!-- hack for prototype - replace "new games" with evolution games when we are logged in -->
      <vue-horizontal
        v-if="replaceMenuWithEvolution"
        ref="horizontal"
        class="horizontal"
        :button="false"
        @scroll-debounce="onScrollDebounce"
      >
        <EvolutionGameTile
          v-for="(table, index) in tablesList"
          :key="index"
          :table="table"
          :class="index === sortedGameList.length - 1 ? 'pr-0' : ''"
          class="pl-0 mr-2"
        />
      </vue-horizontal>
      <vue-horizontal
        v-else
        ref="horizontal"
        class="horizontal"
        :button="false"
        @scroll-debounce="onScrollDebounce"
      >
        <GameTile
          v-for="(gameId, index) in sortedGameList"
          :key="index"
          :game-id="gameId"
          :class="index === sortedGameList.length - 1 ? 'pr-0' : ''"
          class="pl-0 mr-2"
        />
      </vue-horizontal>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import GameTile from "./GameTile.vue";
import EvolutionGameTile from "./EvolutionGameTile.vue";
import staggeredItems from "@/mixins/staggeredItems";

export default {
  components: {
    GameTile,
    EvolutionGameTile
  },
  mixins: [staggeredItems],
  props: {
    menuId: { type: Number, required: true }
  },
  data() {
    return {
      gamesRow: null,
      gamesRowOverlay: null,
      gamesListPosition: 0,
      isScrolling: false,
      hasPrev: false,
      hasNext: true
    };
  },
  computed: {
    ...mapGetters("games", [
      "menuIsVisible",
      "gameFamilyIcons",
      "gameIsExcluded"
    ]),
    ...mapGetters("evolutionGames", ["isEvolutionVisible", "tablesList"]),
    ...mapGetters("session", ["loggedIn"]),
    gamesList() {
      return this.$store.getters["games/menuGamesLists"][this.menuId];
    },
    menuName() {
      return this.menu.name?.toLowerCase().replace(" ", "-");
    },
    menu() {
      return this.$store.getters["games/menus"][this.menuId];
    },
    menuIcon() {
      return this.$store.getters["games/gameFamilyIcons"][
        this.menu.displayName
      ];
    },
    showSeeAll() {
      return this.$store.getters["games/showSeeAll"](this.menuId);
    },
    sortedGameList() {
      return [
        ...this.gamesList.filter(game => !this.gameIsExcluded[game]),
        ...this.gamesList.filter(game => this.gameIsExcluded[game])
      ];
    },
    replaceMenuWithEvolution() {
      // display evolution specific components for this menu
      return this.menu.name === "Live Dealer EVO" && this.isEvolutionVisible;
    }
  },
  mounted() {
    this.gamesRow = this.$refs.gamesRow;
    this.gamesRowOverlay = this.$refs.gamesRowOverlay;
  },
  methods: {
    prev() {
      this.$refs.horizontal.prev();
    },
    next() {
      this.$refs.horizontal.next();
    },
    onScrollDebounce({ hasPrev, hasNext }) {
      this.hasPrev = hasPrev;
      this.hasNext = hasNext;
    },
    updatedCustomIconSvg(svgCode) {
      // if custom SVG has incompatible built in sizing, update it to the correct icon size
      const width = 20;
      const height = 20;

      const parser = new DOMParser();
      const doc = parser.parseFromString(svgCode, "image/svg+xml");

      const svgElement = doc.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute("width", width);
        svgElement.setAttribute("height", height);
      }

      //Check for inline style and update 'width' and 'height' properties
      let styleAttribute = svgElement?.getAttribute("style");
      if (styleAttribute) {
        styleAttribute = styleAttribute.replace(
          /width\s*:\s*[^;]+/i,
          `width:${width}px`
        );
        styleAttribute = styleAttribute.replace(
          /height\s*:\s*[^;]+/i,
          `height:${height}px`
        );
        svgElement.setAttribute("style", styleAttribute);
      }

      const serializer = new XMLSerializer();
      return serializer.serializeToString(doc);
    }
  }
};
</script>

<style scoped lang="scss">
.horizontal {
  width: 100%;
}
.btn--group {
  width: 100px;
  opacity: 0.75;
}
.gamesrow {
  // The negative margin and positive padding allows hovered
  // game tiles to appear outside of the top of the container
  margin-top: -10px;
  padding-top: 10px;
  overflow-x: auto;
}

.gamesBackBtn {
  border: 1px solid;
  border-radius: 20px 0 0 20px;
  height: 40px;
  width: 50px;
}

.gamesNextBtn {
  border: 1px solid;
  border-radius: 0 20px 20px 0;
  height: 40px;
  width: 50px;
}

.gamesrow::-webkit-scrollbar {
  display: none;
}

.slide-x-transition {
  transition: transform 0.3s ease;
}

.gameFamilyIcon {
  width: 20px !important;
  height: 20px !important;
}

.fade {
  background: linear-gradient(to right, transparent, var(--v-body-base));
  height: calc(100% + 20px);
  width: 10%;
  position: absolute;
  visibility: visible;
  z-index: 1;
  top: -10px;
  right: -1px;
  pointer-events: none;
}

// Styles for the game tiles when they are in horizontal list mode
::v-deep .gametile.square {
  flex-shrink: 0;
  width: 18%;
  max-width: 220px;
  min-width: 180px;

  & .v-image {
    border-radius: 6px 6px 0 0;
  }
}

::v-deep .gametile.tall {
  flex-shrink: 0;
  width: 12%;
  max-width: 150px;
  min-width: 120px;
}
</style>
