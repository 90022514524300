<template>
  <v-dialog v-model="showDialog" overlay-opacity="0.9" width="500">
    <v-card v-if="showDialog" class="elevation-12">
      <v-card-title
        class="justify-center"
        :class="largeLayout ? 'transparent' : 'navbar'"
      >
        <v-spacer></v-spacer>
        <span
          :style="largeLayout ? 'margin-top: 5%;' : ''"
          style="text-transform: uppercase;"
          >{{ $str("resend") }} {{ $strResourceService("VerifyEmail") }}</span
        >
        <v-spacer></v-spacer>
        <v-icon
          class="pa-0"
          :style="largeLayout ? 'margin-top: -5%;' : ''"
          @click.stop="showDialog = false"
        >
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
        >
      </v-card-title>
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        class="pt-4"
        :min-height="largeLayout ? '405' : ''"
        v-html="$strResourceService('CheckVerificationEmailMessage')"
      ></v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row class="pa-0 ma-0" align="center" justify="center">
            <v-text-field
              v-model="email"
              class="required"
              :filled="largeLayout"
              :dense="largeLayout"
              color="inputLabelFocused"
              :placeholder="playerEmail"
              :label="$strResourceService('ConfirmEmail')"
              v-bind="accountCreateFieldsAttributes.EMailAddress"
              :rules="
                validateFields ? accountCreateFieldsRules.EMailAddress : []
              "
            ></v-text-field>
          </v-row>
          <p class="mb-2 caption text-center">{{ $strResourceService("ChangeEmailMessage") }}</p>

          <v-row justify="center"
            ><v-btn
              color="buttonSecondary buttonSecondaryTxt--text"
              :disabled="!valid"
              @click="resendOTPVerificationEmail"
              >{{ $str("resend") }}</v-btn
            ></v-row
          >
          <v-row justify="center" class="mt-2">
            <v-alert v-if="error" dense type="warning">{{ error }}</v-alert>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "resendOTPVerificationEmail",
      email: "",
      login: "",
      valid: false,
      error: "",
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("session", ["playerEmail"]),
    ...mapGetters("accountFields", [
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ])
  },
  methods: {
    ...mapActions("session", ["updateSessionPlayerEmail"]),
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form.validate();
    },
    async resendOTPVerificationEmail() {
      this.login = this.$store.getters["dialogs/emailVerification"].data.login;
      const hash = this.$store.getters["dialogs/emailVerification"].data.hash;
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }
      try {
        await CDK.sendEmailVerificationWithHash(hash, this.email);
        window.LobbyCore.raise(
          window.LobbyCore.Events.hideResendOTPVerificationEmailPage
        );
      } catch (error) {
        const expirationError = 33;
        if (error.ErrorCode === expirationError) {
          this.$store.dispatch("dialogs/hideAll");
        }
        this.error = error.Message;
      }
    },
    onHidden() {
      this.success = "";
      this.error = "";
      this.validateFields = false;
      this.email = "";
      this.$refs.form.resetValidation();
    }
  }
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}
</style>
