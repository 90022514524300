<template>
  <v-card flat>
    <v-card-title
      class="tournamentGameName tournamentGameNameTxt--text tournamentGameName--break"
    >
      {{ tournament.name }}</v-card-title
    >

    <v-card-text class="tournamentDetail tournamentDetailTxt--text">
      <RestrictedTournament
        v-if="tournament.restricted"
        :tournament="tournament"
      ></RestrictedTournament>
      <v-container v-else-if="!tournament" fill-height>
        <v-row justify="center">
          <v-progress-circular
            color="white"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row align="center">
          <v-col class="d-flex justify-center" :md="6" :lg="7" :xl="8">
            <v-img
              class="rounded"
              :max-width="isSquareIcon ? '100%' : '150px'"
              :src="gameIcon(tournament.game)"
              :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
              :lazy-src="isSquareIcon ? lazyLoadingImage : null"
              contain
            ></v-img
          ></v-col>
          <v-col :md="6" :lg="5" :xl="4" class="py-0">
            <v-container class="pl-1"
              ><v-row align="center"
                ><v-icon class="tournamentDetailTxt--text mr-1 details-header"
                  >mdi-trophy-variant</v-icon
                ><span class="red--text font-weight-bold details-header">{{
                  $tournamentCur(tournament.prize)
                }}</span></v-row
              ></v-container
            >
            <v-container class="pa-1">
              <v-row
                ><span class="font-weight-bold mr-1 ">{{
                  `${$str("ID")}:`
                }}</span
                ><span>{{ ` ${tournament.TournamentId}` }}</span></v-row
              >
              <v-row
                ><span class="font-weight-bold mr-1 ">{{
                  `${$strResourceService("Payout")}:`
                }}</span
                ><span>{{ ` ${tournament.payoutType}` }}</span></v-row
              >
              <v-row
                ><span class="font-weight-bold mr-1 ">{{
                  `${$str("Mode")}:`
                }}</span
                ><span>{{ ` ${tournament.mode}` }}</span></v-row
              >
              <v-row>
                <span class="font-weight-bold mr-1">{{
                  `${$str("DetailViewStatus")}:`
                }}</span
                ><span>{{ ` ${tournament.status}` }}</span>
              </v-row>
              <v-row
                ><span class="font-weight-bold mr-1">{{
                  `${$str("DetailViewStarts")}:`
                }}</span
                ><span>{{ ` ${tournament.startDate}` }}</span></v-row
              >
              <v-row
                ><span class="font-weight-bold mr-1">{{
                  `${$str("DetailViewEnds")}:`
                }}</span
                ><span>{{ ` ${tournament.endDate}` }}</span></v-row
              >
              <v-row v-if="tournament.statusTimingText"
                ><span class="font-weight-bold mr-1">{{
                  `
                  ${tournament.statusTimingLabelText}`
                }}</span
                ><span>{{ ` ${tournament.statusTimingText}` }}</span></v-row
              ></v-container
            >
            <v-container
              ><v-row justify="center" align="center"
                ><v-icon class="tournamentDetailTxt--text mr-4"
                  >mdi-account-group</v-icon
                >{{
                  `${tournament.RegisteredPlayers}/${tournament.MaxPlayers}`
                }}</v-row
              ></v-container
            >
          </v-col></v-row
        >
        <TournamentActions :tournament="tournament"></TournamentActions>
        <v-expansion-panels accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header color="tournamentTabInactive">{{
              $str("TournamentLeaderboard")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <TournamentLeaderboard
                :details="tournament.DetailData"
              ></TournamentLeaderboard>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="tournamentTabInactive">{{
              $str("TournamentPrizes")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <TournamentPrizes
                :details="tournament.DetailData"
              ></TournamentPrizes>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="tournament.refundEnabled">
            <v-expansion-panel-header color="tournamentTabInactive">{{
              $str("Refund")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Refund :tournament="tournament"></Refund>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TournamentLeaderboard from "@/components/TournamentLeaderboard.vue";
import TournamentPrizes from "@/components/TournamentPrizes.vue";
import Refund from "@/components/Refund";
import TournamentActions from "@/components/TournamentActions.vue";
import RestrictedTournament from "@/components/RestrictedTournament.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TournamentLeaderboard,
    TournamentPrizes,
    Refund,
    TournamentActions,
    RestrictedTournament
  },
  props: {
    tournament: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  }
};
</script>

<style scoped>
.v-card__text {
  height: 64vh;
  max-height: 64vh;
  overflow-y: scroll;
}

::v-deep .v-expansion-panels {
  z-index: 0 !important;
}

::v-deep .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 1vw;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 2vw;
  margin-bottom: 1vw;
}

.theme--dark.v-expansion-panels .v-expansion-panel,
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header {
  border-radius: 1vw;
  margin-bottom: 0.25rem;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  margin-bottom: 0;
}

.v-item-group.theme--dark.v-expansion-panels.v-expansion-panels--accordion.v-expansion-panels--flat,
.v-item-group.theme--light.v-expansion-panels.v-expansion-panels--accordion.v-expansion-panels--flat {
  padding: 1vmin;
}

.tournamentGameName--break {
  word-break: break-word;
}

.v-card__text {
  font-size: calc(0.3rem + 0.75vw);
}

.details-header {
  font-size: calc(0.45rem + 0.75vw);
}

.details-header.v-icon {
  font-size: calc(1.25rem + 0.75vw);
}
</style>
