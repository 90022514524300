<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <g id="newgames">
      <polyline
        class="st0"
        points="24,45.1 45.3,20.9 39.8,11.7 8.2,11.7 2.7,20.9 24,45.1 	"
      />
      <line class="st0" x1="3.1" y1="20.9" x2="14.8" y2="20.9" />
      <line class="st0" x1="20.2" y1="20.9" x2="44.9" y2="20.9" />
      <line class="st0" x1="14.6" y1="20.9" x2="24" y2="44.8" />
      <line class="st0" x1="14.6" y1="20.8" x2="10.9" y2="15.5" />
      <line class="st0" x1="23.5" y1="11.9" x2="14.8" y2="20.8" />
      <line class="st0" x1="33.3" y1="20.9" x2="24" y2="44.8" />
      <line class="st0" x1="33.4" y1="20.8" x2="39.7" y2="11.9" />
      <line class="st0" x1="24.5" y1="11.9" x2="33.2" y2="20.8" />
      <line class="st0" x1="24" y1="6.2" x2="24" y2="2.9" />
      <line class="st0" x1="34.5" y1="6.2" x2="36.8" y2="3.3" />
      <line class="st0" x1="13.5" y1="6.2" x2="11.2" y2="3.3" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "NewGames",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "New Games" }
  }
};
</script>
<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
