<template>
  <v-card
    id="coupon-sequence-graph-container"
    color="header"
    style="
      overflow-x: auto;       
      border-top-style: solid; 
      border-top-color: grey; 
      border-radius: 0; 
      border-top-width: thin;width: inherit;
      text-align: center;"
    :ripple="false"
    :class="hasMoreThanOneNextCoupons ? 'multipleRows' : 'oneRow'"
    flat
  >
    <div
      style="      
      text-align: center;
      height:auto;"
    >
      <canvas
        id="sequenceGraph"
        @click="event => handleCanvasClick(event)"
      ></canvas>
    </div>
    <div id="tooltiptext"></div>
  </v-card>
</template>

<script>
export default {
  props: {
    graphInfo: { type: Object, required: true }
  },
  data() {
    return {
      element: null,
      vueCanvas: null,
      width: 0,
      height: 0,
      radious: 0,
      limit: 5,
      isPortrait: true,
      dots: []
    };
  },
  computed: {
    graphColors() {
      return {
        current: this.$getColorByKey("couponSequenceCurrent"),
        previous: this.$getColorByKey("couponSequencePrevious"),
        next: this.$getColorByKey("couponSequenceNext"),
        labels: this.$getColorByKey("couponSequenceLabels"),
        hints: this.$getColorByKey("couponSequenceHints"),
        icon: this.$getColorByKey("successTxt")
      };
    },
    graphInfoChanged() {
      return this.graphInfo;
    },
    hasParent() {
      return this.graphInfo.PreviousCoupon;
    },
    hasNextCoupons() {
      return (
        this.graphInfo.NextCoupons && this.graphInfo.NextCoupons.length > 0
      );
    },
    hasMoreThanOneNextCoupons() {
      return (
        this.graphInfo.NextCoupons && this.graphInfo.NextCoupons.length > 1
      );
    },
    fontSize() {
      return this.width && this.isPortrait >= 600 ? 20 : 15;
    },
    iconSize() {
      return this.width && this.isPortrait >= 600 ? 30 : 20;
    }
  },
  watch: {
    graphInfoChanged(value) {
      this.graphInfo = value;
      this.initializeGraph();
    }
  },
  created() {
    window.addEventListener("resize", this.reziseCanvas);
  },
  destroyed() {
    window.removeEventListener("resize", this.reziseCanvas);
  },
  mounted() {
    //update admin preview if colors changed
    const onLiveCustomizations = msg => {
      if (msg?.data?.type === "liveCustomizations") {
        const customizations = msg.data.customizations;
        const theme = customizations.themes[this.$mode];
        this.graphColors.current =
          theme.couponSequenceCurrent ?? this.graphColors.current;
        this.graphColors.previous =
          theme.couponSequencePrevious ?? this.graphColors.previous;
        this.graphColors.next =
          theme.couponSequenceNext ?? this.graphColors.next;
        this.graphColors.labels =
          theme.couponSequenceLabels ?? this.graphColors.labels;
        this.graphColors.hints =
          theme.couponSequenceHints ?? this.graphColors.hints;
        this.initializeGraph();
      }
    };

    window.addEventListener("message", onLiveCustomizations);

    this.initializeGraph();
  },
  methods: {
    initializeGraph() {
      this.dots = [];
      this.setOrientation();
      this.element = document.getElementById("sequenceGraph");

      this.vueCanvas = this.element.getContext("2d");

      this.setUpCanvas(window.innerWidth, window.innerHeight);

      this.drawGraph();
    },
    reziseCanvas() {
      this.setOrientation();

      this.element = document.getElementById("sequenceGraph");
      this.vueCanvas = this.element.getContext("2d");
      if (this.vueCanvas) {
        this.vueCanvas.clearRect(0, 0, window.innerWidth, window.innerHeight);
        this.vueCanvas.width = window.innerWidth;
        this.vueCanvas.height = window.innerHeight;

        if (!this.isPortrait) {
          this.vueCanvas.width = window.innerWidth;
          this.vueCanvas.height = window.innerWidth;
        }

        this.setUpCanvas(this.vueCanvas.width, this.vueCanvas.height);
        this.drawGraph();
      }
    },
    setOrientation() {
      this.isPortrait = window.innerWidth > window.innerHeight ? false : true;
    },
    drawGraph() {
      const min = Math.min(this.height, this.width);
      const max = Math.max(window.innerHeight, window.innerWidth);
      const paddingX = min * (30 / 100);

      const paddingY = min * (10 / 100);
      const lastPointY = (this.isPortrait ? this.height : max) - paddingY * 2;
      this.radious = min * (this.isPortrait ? 0.04 : 0.03);

      const eachLineLongX = min - paddingX;

      const eachLineLongY = lastPointY * (30 / 100);
      let startPointX = paddingX;
      let endPointX = paddingX;
      let startPointY = paddingY;
      let endPointY = paddingY;
      let circle = null;

      if (this.graphInfo.PreviousCoupon) {
        circle = this.drawCircle(
          startPointX,
          startPointY,
          this.radious,
          this.graphColors.previous
        );
        this.drawText(
          startPointX,
          startPointY,
          this.radious,
          this.graphInfo.PreviousCoupon.Code
        );
        this.addDotTooltip(this.graphInfo.PreviousCoupon.Code, circle);
        this.drawIcon(startPointX, startPointY);
        startPointX = startPointX + this.radious;
        endPointX = startPointX + eachLineLongX + this.radious;
        this.drawLine(
          startPointX,
          endPointX,
          startPointY,
          startPointY,
          this.graphColors.previous
        );

        if (!this.hasNextCoupons) {
          startPointX = endPointX;
          endPointX = startPointX + this.radious + eachLineLongX;

          this.drawLine(
            startPointX,
            endPointX,
            startPointY,
            startPointY,
            this.graphColors.current
          );
        }
      }

      if (this.graphInfo.Current) {
        startPointX = endPointX;

        circle = this.drawCircle(
          startPointX,
          startPointY,
          this.radious,
          this.graphColors.current
        );
        this.drawText(
          startPointX,
          startPointY,
          this.radious,
          this.graphInfo.Current.Code
        );
        this.addDotTooltip(this.graphInfo.Current.Code, circle);
        if (this.hasNextCoupons) {
          endPointX = startPointX + this.radious + eachLineLongX;

          if (this.hasParent)
            endPointX = startPointX + this.radious + eachLineLongX / 2;

          this.drawLine(
            startPointX,
            endPointX,
            startPointY,
            startPointY,
            this.graphColors.current
          );
        }
      }

      if (this.hasNextCoupons) {
        startPointX = endPointX;

        //draw each next coupon
        if (this.hasParent) endPointX = endPointX + eachLineLongX / 2;
        else endPointX = endPointX + eachLineLongX;

        this.graphInfo.NextCoupons.forEach(coupon => {
          this.drawLine(startPointX, startPointX, startPointY, endPointY);

          this.drawLine(
            startPointX,
            endPointX,
            endPointY,
            endPointY,
            this.graphColors.next
          );
          circle = this.drawCircle(
            endPointX,
            endPointY,
            this.radious,
            this.graphColors.next
          );
          this.drawText(endPointX, endPointY, this.radious, coupon.Code);
          this.addDotTooltip(coupon.Code, circle);
          startPointY = endPointY;
          endPointY = startPointY + eachLineLongY - this.radious;
        });
      }
    },
    setUpCanvas(width, height) {
      // Feed the size back to the canvas.
      if (width === 0 && height === 0) {
        width = window.innerWidth;
        height = window.innerHeight;
      }

      if (!this.isPortrait) height = width;
      this.width = width;
      this.height = height;
      this.element.width = width;
      this.element.height = height;
    },
    drawCircle(x, y, radious, color) {
      const circle = new Path2D();
      circle.arc(x / 2, y / 2, radious, 0, 2 * Math.PI);
      this.vueCanvas.fillStyle = color;
      this.vueCanvas.fill(circle);

      return circle;
    },
    drawLine(startX, endX, startY, endY, color) {
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(startX / 2, startY / 2);
      this.vueCanvas.lineTo(endX / 2, endY / 2);
      this.vueCanvas.lineWidth = 4;
      this.vueCanvas.strokeStyle = color;
      this.vueCanvas.stroke();
    },
    drawIcon(x, y) {
      this.vueCanvas.fillStyle = this.graphColors.icon;
      this.vueCanvas.font = `${this.iconSize}px Material Design Icons`;
      this.vueCanvas.textAlign = "center";
      this.vueCanvas.textBaseline = "middle";
      this.vueCanvas.fillText(String.fromCodePoint(0xf012c), x / 2, y / 2);
    },
    drawText(x, y, radious, text) {
      this.vueCanvas.fillStyle = this.graphColors.labels;
      this.vueCanvas.font = `${this.fontSize}px Figtree Variable`;

      this.vueCanvas.textBaseline = "top";
      this.vueCanvas.textAlign = "center";

      this.vueCanvas.fillText(
        this.addDotsTextOverflow(text),
        x / 2,
        y / 2 + radious * 1.3
      );
    },
    addDotsTextOverflow(text) {
      const dots = "...";
      if (text.trim().length > this.limit)
        return text.substring(0, this.limit) + dots;
      return text.trim();
    },
    addDotTooltip(text, circle) {
      const dot = {
        tip: text.trim(),
        circle: circle
      };
      this.dots.push(dot);
    },
    handleCanvasClick(e) {
      e.preventDefault();
      e.stopPropagation();

      const tip = document.getElementById("tooltiptext");
      tip.style.display = "none";
      tip.style.color = this.graphColors.hints;

      for (let i = 0; i < this.dots.length; i++) {
        const dot = this.dots[i];

        if (this.vueCanvas.isPointInPath(dot.circle, e.offsetX, e.offsetY)) {
          tip.innerText = dot.tip;
          tip.style.display = "block";
        }
        setTimeout(() => {
          tip.style.display = "none";
        }, 1000);
      }
    }
  }
};
</script>
<style scoped>
canvas#sequenceGraph {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.oneRow {
  max-height: 10vh;
  overflow: hidden;
}
.multipleRows {
  max-height: 15vh;
  overflow: auto;
}

@media all and (orientation: landscape) {
  .oneRow {
    max-height: 25vh;
  }
  .multipleRows {
    max-height: 30vh;
  }
}

#tooltiptext {
  position: sticky;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  font-size: 12px;
  padding: 2px 5px 0 5px;
  overflow-wrap: break-word;
  width: 25%;
  left: 40%;
  bottom: 0;
}
</style>
