<template>
  <v-container
    v-if="panelData && panelData.length > 0"
    id="MarketingPanelCarousel"
    :key="ckey"
    no-gutters
    class="pa-0"
  >
    <v-carousel
      cycle
      :show-arrows="false"
      :width="panelWidth"
      :height="panelHeight"
      hide-delimiter-background
      :hide-delimiters="panelCount == 1 ? true : false"
    >
      <v-carousel-item
        v-for="(item, i) in panelData.filter(panel => panel.URL.length > 0)"
        :key="i"
      >
        <embed
          id="marketingPanelIframe"
          :src="item.URL"
          :width="panelWidth"
          :height="panelHeight"
          scrolling="no"
          frameborder="0"
        />
        <div
          v-if="item.LinkType > 0"
          :style="clickCatcherStyle"
          @click="launchAction(item)"
        ></div>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { LaunchLobbyAction, LobbyActions } from "@/services/casino";
import { externalLobby } from "@/mixins/externalLobby";

export default {
  mixins: [externalLobby],
  data() {
    return {
      actions: {
        1: this.launchGame,
        2: this.openSignUp,
        3: this.redeemCoupon,
        4: this.openDeposit,
        5: this.showExternalPage,
        6: this.showTournament,
        7: this.launchExternal
      },
      screenWidth: window.innerWidth,
      ckey: 0
    };
  },
  computed: {
    panelData() {
      return this.$store.getters["marketingPanels/panels"]?.MarketingPanels;
    },
    panelCount() {
      return this.panelData.filter(panel => panel.URL.length > 0).length;
    },
    panelWidth() {
      return this.screenWidth;
    },
    panelHeight() {
      // Default banner size
      let w = 1920;
      let h = 717;

      // check for size passed in on the URL
      // expected format:  size=640x140
      if (this.panelData.length > 0) {
        const params = new URLSearchParams(
          this.panelData[0].URL?.split("?")?.[1]
        );
        const size = params.get("size")?.split("x");
        if (size?.length === 2) {
          w = size[0];
          h = size[1];
        }
      }
      // Banner width should be scaled to screen width
      //  so scale height appropriately
      return h * (this.screenWidth / w);
    },
    clickCatcherStyle() {
      return (
        "position: absolute; top: 0px; " +
        "width: 100%; height: " +
        this.panelHeight +
        "px;"
      );
    }
  },
  watch: {
    panelData: function(newVal, oldVal) {
      this.markViewed();
      if (newVal && newVal.length > 0 && oldVal && oldVal.length > 0) {
        if (!this.mktPanelAreEqual(newVal, oldVal)) {
          this.ckey += 1;
        }
      }
    }
  },
  updated() {
    // Ensure our computed properties for height and width change if the screen
    //  is resized (ie phone rotated, etc)
    window.addEventListener("resize", () => {
      if (navigator.userAgent.match("CriOS")) {
        setTimeout(() => (this.screenWidth = window.innerWidth), 300);
      } else {
        this.screenWidth = window.innerWidth;
      }
    });
  },
  methods: {
    ...mapActions("dialogs", ["openWebsiteInNewWindow"]),
    markViewed() {
      for (const i in this.panelData) {
        CDK.raise(
          CDK.Events.marketingPanelViewed,
          this.panelData[i].MessageCode
        );
      }
    },
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    openSignUp() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
    },
    mktPanelAreEqual(newVal, oldVal) {
      let isEqual = true;
      for (const i in newVal) {
        if (newVal[i] && oldVal[i] && newVal[i].URL !== oldVal[i].URL) {
          isEqual = false;
          break;
        }
      }
      return isEqual;
    },
    launchAction(item) {
      this.actions[item.LinkType](item);
      if (item) CDK.raise(CDK.Events.marketingPanelClicked, item.MessageCode);
    },
    showExternalPage(item) {
      this.openWebsiteInNewWindow({ url: item.ExternalURL });
    },
    redeemCoupon(item) {
      window.LobbyCore.raise(window.LobbyCore.Events.showRedeemCouponPage, {
        couponCode: item.CouponCode
      });
    },
    showTournament(item) {
      LaunchLobbyAction(this.$store, LobbyActions.ShowTournament, {
        tournamentId: parseInt(item.TournamentID)
      });
    },
    launchGame(item) {
      this.$store.dispatch("games/launchGame", {
        gameId: item.GameUniqueID,
        forceReal: true
      });
    },
    launchExternal(item) {
      if (this.isExternalLobbyVisible(item.ExternalLobbyID)) {
        LaunchLobbyAction(this.$store, LobbyActions.LaunchGame, {
          externalLobbyId: item.ExternalLobbyID
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep
  .v-carousel__controls__item.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
  display: none;
}

::v-deep
  .v-carousel__controls__item.v-carousel__controls__item.v-btn.v-btn--icon {
  color: transparent;
  border-radius: 25px;
  border: 1px solid #ffffff;
  height: 10px;
  width: 10px;
}

::v-deep
  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn.v-btn--icon.v-size--small {
  color: #ffffff;
}

::v-deep .v-carousel__controls__item .v-icon {
  opacity: 1;
}

::v-deep .v-carousel__controls__item .mdi-circle:before {
  font-size: 11px;
}

.container {
  max-width: max-content;
}

::v-deep .v-carousel__controls__item > .v-btn__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
