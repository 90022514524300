<template>
  <v-container class="ma-0 pa-0">
    <v-simple-table
      light
      class="mb-2 text-center"
      style="width:101% !important"
    >
      <thead class="buttonSecondary buttonSecondaryTxt--text ">
        <th class="text-center px-2" colspan="2">
          {{ $str("TournamentPosition") }}
        </th>
        <th class="text-center">{{ $str("TournamentWinner") }}</th>
        <th class="text-center">{{ $str("TournamentAward") }}</th>
      </thead>
      <tbody>
        <tr
          v-for="(prize, index) in details.PrizesLeaderboard"
          :key="index"
          :class="
            prize.Rank === details.PlayerDetails.Position ||
            prize.RankAward === details.PlayerDetails.Position
              ? 'active'
              : ''
          "
        >
          <td class="pl-2 pr-0 text-left">
            <v-icon
              v-if="
                prize.Rank === details.PlayerDetails.Position ||
                  prize.RankAward === details.PlayerDetails.Position
              "
              color="tournamentTabInactive"
              medium
              >mdi-navigation mdi-rotate-90</v-icon
            >
          </td>
          <td class="pa-0 text-left">
            {{ prize.Rank ? prize.Rank : prize.RankAward }}
          </td>

          <td>{{ prize.Player }}</td>
          <td class="text-right">{{ $tournamentCur(prize.Award) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  props: {
    details: { type: Object, required: true }
  }
};
</script>

<style scoped>
@media (min-width: 960px) {
  .container {
    max-width: 900px !important;
  }
}

.theme--dark.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: thin solid var(--v-buttonSecondary-base);
}

.theme--dark.v-data-table tbody tr:hover:not(.v-data-table__expanded__content),
.theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content) {
  background: var(--v-tournamentInfo-base);
  color: var(--v-tournamentInfoTxt-base);
}
.active {
  background: var(--v-tournamentInfo-base) !important;
  color: var(--v-tournamentInfoTxt-base) !important;
}
::v-deep.v-data-table table {
  width: 101% !important;
}
</style>
