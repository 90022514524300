<template>
  <v-dialog id="activeCoponDialog" v-model="showDialog" persistent>
    <v-card v-if="showDialog" class="elevation-12" style="height: 100%;">
      <DialogTitleBar :title="title" @close="showDialog = false" />
      <!-- eslint-disable vue/no-v-html -->
      <v-card-text
        v-show="source"
        class="text--primary text-center pt-1 pb-3"
        v-html="source"
      >
      </v-card-text>
      <v-card-actions class="d-flex justify-space-around">
        <!-- alerts only have an "OK" button -->
        <v-btn color="button buttonTxt--text" width="49%" @click="agree">{{
          $str("OKButton")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "activeCoupon",
      isIframeLoaded: false
    };
  },
  computed: {
    title() {
      return this.$store.getters["dialogs/activeCoupon"].data.title;
    },
    source() {
      return this.$store.getters["dialogs/activeCoupon"].data.source;
    }
  },
  methods: {
    agree() {
      this.showDialog = false;
    }
  }
};
</script>
<style scoped>
#activeCoponDialog {
  z-index: 210;
}
</style>
