<template>
  <svg id="ss" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <g id="progressive">
      <line
        x1="2"
        y1="24"
        x2="4.3"
        y2="24"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="7"
        y1="24"
        x2="9.3"
        y2="24"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="5.7"
        y1="20.3"
        x2="5.7"
        y2="22.7"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="5.7"
        y1="25.3"
        x2="5.7"
        y2="27.7"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="10.7"
        y1="13.8"
        x2="6.1"
        y2="10.6"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="10.7"
        y1="34.2"
        x2="6.1"
        y2="37.4"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="46"
        y1="24"
        x2="43.7"
        y2="24"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="41"
        y1="24"
        x2="38.7"
        y2="24"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="42.3"
        y1="27.7"
        x2="42.3"
        y2="25.3"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="42.3"
        y1="22.7"
        x2="42.3"
        y2="20.3"
        fill="none"
        :stroke="iconColor"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="37.3"
        y1="34.2"
        x2="41.9"
        y2="37.4"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <line
        x1="37.3"
        y1="13.8"
        x2="41.9"
        y2="10.6"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <path
        d="M25.9,39.65a8.84,8.84,0,0,0,7.2-8.79c0-5-4.3-7-7.7-8.78l-1.5-.64c-2.3-1.15-5.2-2.55-5.2-4.2,0-1.91,2.2-4.08,5.3-4.08s5.3,2.17,5.3,4.08h3.8a8.94,8.94,0,0,0-7.2-8.79V3.87H22"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
      <path
        d="M22.1,8.58a8.84,8.84,0,0,0-7.2,8.78c0,5,4.2,7.14,7.7,8.79l1.5.77c2.3,1.14,5.2,2.54,5.2,4.2,0,1.91-2.2,4.07-5.3,4.07s-5.3-2-5.3-4.07H14.9a8.93,8.93,0,0,0,7.2,8.78v4.33H26"
        fill="none"
        :stroke="iconColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.16"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Progressives",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Progressives" }
  }
};
</script>
