<template>
  <v-btn
    id="LobbyJackpotOptOut"
    class="font-weight-bold"
    color="button buttonTxt--text"
    height="40px"
    @click="OptOut"
  >
    {{ $str("OptOut") }}
    <ConfirmDialog ref="confirm" />
  </v-btn>
</template>

<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { mapActions } from "vuex";

export default {
  components: {
    ConfirmDialog
  },
  methods: {
    ...mapActions("casinoJackpot", ["SetOptIn"]),
    async OptOut() {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("JackpotOptOut").replace(
          "<JackpotName>",
          this.$str("LobbyJackpot")
        ),
        message: this.$str("OptOutConfirmationMessage").replace(
          "<JackpotName>",
          this.$str("LobbyJackpot")
        )
      });

      if (confirmed) {
        this.SetOptIn(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
