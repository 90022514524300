<template>
  <v-card :id="scrollContainerId" :ripple="false" class="card-container" flat>
    <div
      class="d-flex flex-no-wrap"
      :class="{ imageSize: isEnableNewImageSize }"
    >
      <v-avatar class="align-self-center ma-0" tile size="100">
        <v-img
          v-if="coupon.ImageUrl"
          :src="coupon.ImageUrl"
          :lazy-src="lazyLoadingImage"
          eager
          contain
          width="200px"
          height="200px"
        >
        </v-img>
      </v-avatar>

      <v-container class="ma-0 pa-0" style="min-width: 55%;">
        <!-- eslint-disable vue/no-v-html -->
        <v-row>
          <v-col>
            <v-card-title
              :id="scrollTitleId"
              style="word-break: break-word;"
              v-html="couponTitle"
            ></v-card-title>
            <!-- Link to bug report page w/ suggested fix: https://github.com/vuetifyjs/vuetify/issues/9130 -->
            <!-- eslint-disable vue/no-v-html -->
            <v-card-subtitle
              style="word-break: break-word;"
              v-html="couponDescription"
            ></v-card-subtitle>
            <v-card-text
              v-if="coupon.TermsAndCoditionsUrl"
              class="text-left pb-0"
              ><a
                class="hyperlink--text"
                @click.stop.prevent="showTermsandConditions"
              >
                {{ $str("TermsNConditionsLink") }}
              </a></v-card-text
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col class="text-right mr-5">
            <div style="position: relative; display: inline-block;">
              <v-btn
                :id="scrollTargetId"
                color="button buttonTxt--text"
                :disabled="coupon.CompPointsToRedeem > userCompPoints"
                class="btn-redeem"
                @click="redeem"
              >
                <span class="comp-points-number">
                  {{ coupon.CompPointsToRedeem | formatNumber }}
                </span>
                <span class="comp-points-title">
                  {{ $strResourceService("CompPoints") }}
                </span>
              </v-btn>
              <div class="circle-icon">
                <img
                  src="@/assets/icons/star.png"
                  width="43"
                  height="43"
                  transform="rotate(13 11.0972 2.09909)"
                />
              </div>
            </div>
            <br />
            <v-btn
              v-if="coupon.MillisecondsToDisappear > 0"
              text
              disabled
              style="font-size:12px;"
            >
              <v-icon small left>
                $vuetify.icons.countDown
              </v-icon>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style=" text-transform: lowercase !important"
                v-html="coupon.scrollTimer ? coupon.scrollTimer : '--:--:--'"
              ></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
export default {
  components: {
    ConfirmDialog
  },
  filters: {
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  props: {
    coupon: { type: Object, required: true },
    userCompPoints: { type: Number, required: true }
  },
  data() {
    return {
      name: "coupons",
      couponCode: "",
      errorMessage: "",
      isScrolling: false,
      isEnableNewImageSize: false
    };
  },
  computed: {
    ...mapGetters("customizations", ["lazyLoadingImage"]),
    couponTitle() {
      return "<span>" + this.$decode(this.coupon.Title) + "</span>";
    },
    couponDescription() {
      return "<span>" + this.$decode(this.coupon.Description) + "</span>";
    },
    scrollTargetId() {
      return "redeemAvailable" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollContainerId() {
      return "coupon" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollTitleId() {
      return "title" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollTimerId() {
      return "timer" + this.coupon.CouponCode.replace(/\s+/g, "");
    }
  },
  async beforeMount() {
    this.isEnableNewImageSize = await this.getIsEnableNewImageSize();
  },
  methods: {
    ...mapActions("dialogs", ["showExternalUrl"]),
    async redeem() {
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("Confirmation"),
        message: this.$strResourceService("ConfirmCompPointCoupon")
          .replace("{0}", this.$decode(this.coupon.Title))
          .replace("{1}", this.coupon.CompPointsToRedeem)
      });

      if (confirmed) {
        this.$store.dispatch("coupons/redeem", {
          couponCode: this.coupon.CouponCode
        });
        this.$store.dispatch("dialogs/updateDialogData", {
          name: "coupons",
          data: { couponCode: this.coupon.CouponCode }
        });
        this.$store.dispatch("dialogs/hideAll");
        window.LobbyCore.raise(window.LobbyCore.Events.showCouponsPage);
      }
    },
    showTermsandConditions() {
      this.showExternalUrl({
        title: this.$str("TermsNConditionsLink"),
        source: this.coupon.TermsAndCoditionsUrl,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: ""
      });
    },
    async getIsEnableNewImageSize() {
      return (await CDK.GetIfNewImageSizingIsEnabledInCouponSet()) || false;
    }
  }
};
</script>
<style lang="scss" scoped>
.icon-wrapper svg {
  fill: white;
}

.card-container {
  overflow-x: auto;
  overflow: hidden;
  border-top-style: solid;
  border-top-color: grey;
  border-radius: 0;
  border-top-width: thin;
  width: inherit;
}

.btn-redeem {
  position: relative;
  z-index: 1;
  filter: brightness(150%);
  width: 190px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-icon {
  position: absolute;
  left: -24px;
  top: 48%;
  transform: translateY(-43%);
  z-index: 2;
}

.comp-points-title {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  text-transform: capitalize;
}

.comp-points-number {
  font-size: 16px;
  font-weight: 900;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 15px;
}

.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}

@media all and (orientation: portrait) {
  .imageSize {
    margin: 1em auto !important;
    flex-wrap: wrap;
    > div:first-child {
      height: auto !important;
      min-width: 90% !important;
      margin: 0.6em auto !important;
    }
  }
}

@media all and (orientation: landscape) {
  .imageSize {
    margin: 1em auto !important;
    > div:first-child {
      height: auto !important;
      min-width: 35% !important;
    }
  }
}
</style>
