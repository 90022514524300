/**
 * The signalR (CDK Server) url
 */

import { qaServers } from "./qaServers";

/**
 * Determine the CDK Server/signalR url
 *
 * Production Builds:
 *   Find url in window.LC object, injected by the server
 *
 * Development Builds:
 *   Check for "server" parameter in the lobby's query string
 *   Otherwise, use environment variable VUE_APP_LOCAL_SIGNALR
 *
 * Server parameter can be a key for qaServers, or the actual signalr URL
 *   localhost:8080?server=dev17
 *   localhost:8080?server=https://ddcemdev17app.itsinternal.com:2072/signalr
 */
const getSignalRUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return window.LC.signalRUrl;
  }

  const serverParam = new URL(document.location).searchParams.get("server");
  if (serverParam) {
    // Try using param as a key, otherwise return param itself
    return qaServers[serverParam] || serverParam;
  }

  return process.env.VUE_APP_LOCAL_SIGNALR;
};

export const signalRUrl = getSignalRUrl();
