import Vue from "vue";
import Vuetify from "vuetify/lib";
import store from "@/store";

import Progressives from "@/components/icons/ProgressiveIcon";
import LiveDealer from "@/components/icons/LiveDealerIcon";
import Slots from "@/components/icons/SlotsIcon.vue";
import Specialty from "@/components/icons/SpecialtyIcon.vue";
import TableGames from "@/components/icons/TableGamesIcon.vue";
import VideoPoker from "@/components/icons/VideoPokerIcon.vue";
import NewGames from "@/components/icons/NewGamesIcon.vue";
import RecentGames from "@/components/icons/RecentGamesIcon.vue";
import Favorites from "@/components/icons/FavoritesIcon.vue";
import GameMenu from "@/components/icons/GameMenuIcon.vue";
import Balance from "@/components/icons/BalanceIcon.vue";
import CrashGames from "@/components/icons/CrashGamesIcon.vue";
//import Chat from "@/components/icons/ChatIcon.vue";
import Message from "@/components/icons/MessageIcon.vue";
//import Search from "@/components/icons/SearchIcon.vue";
//import TournamentsIcon from "@/components/icons/TournamentsIcon.vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
//import AccountHistory from "@/components/icons/AccountHistoryIcon.vue";
//import Contact from "@/components/icons/ContactIcon.vue";
import DarkLightMode from "@/components/icons/DarkLightModeIcon.vue";
//import LegalNotices from "@/components/icons/LegalNoticesIcon.vue";
import Logout from "@/components/icons/LogoutIcon.vue";
//import Promotions from "@/components/icons/PromotionsIcon.vue";
//import Withdraw from "@/components/icons/WithdrawIcon.vue";
//import Deposit from "@/components/icons/DepositIcon.vue";
//import PrivacyPolicy from "@/components/icons/PrivacyPolicyIcon.vue";
import AllGames from "@/components/icons/AllGamesIcon.vue";
import CountDown from "@/components/icons/CountDownIcon.vue";
//import LobbyJackpotIcon from "@/components/icons/LobbyJackpotIcon.vue";
//import MyProfileIcon from "@/components/icons/MyProfileIcon.vue";
import "@flaticon/flaticon-uicons/css/all/all.css";
import GamesGuruIcon from "@/components/icons/GamesGuruIcon.vue";

import Popular from "@/components/icons/Popular.vue";

Vue.use(Vuetify);

function getInitialDarkModeSetting() {
  const enabledThemes = store.getters["customizations/enabledThemes"];

  if (enabledThemes.length === 1) {
    const theOnlyEnabledTheme = enabledThemes[0];
    return theOnlyEnabledTheme === "dark";
  } else {
    return localStorage.allSparkTheme
      ? localStorage.allSparkTheme === "true"
      : window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
}

const defaultThemes = {
  light: {
    inputLabelFocused: "#757575",
    body: "#fafafa",
    header: "#fafafa",
    headerElemScrollDown: "#2f2f2f",
    headerElemScrollDownStroke: "#dddddd",
    bodyTitle: "#212121",
    navbar: "#eeeeee",
    button: "#2e7d32",
    buttonTxt: "#fafafa",
    buttonSecondary: "#0d47a1",
    buttonSecondaryTxt: "#fafafa",
    buttonFreeSpins: "#f3ce50",
    buttonFreeSpinsTxt: "#fff",
    balance: "#1e88e5",
    hyperlink: "#2196f3",
    disabled: "#424242",
    disabledTxt: "#757575",
    jackpot: "#800000",
    jackpotTxt: "#fff",
    input: "#06274d",
    inputTxt: "#ffffff",
    inputFocus: "#ffffff",
    inputFocusTxt: "#000000",
    success: "#39aa4c",
    successTxt: "#ffffff",
    error: "#e32626",
    errorTxt: "#ffffff",
    progressTracker: "#4f7298",
    progressTrackerTxt: "#ffffff",
    newGamesTitle: "#ffffff",
    newGamesBackgroundColor: "#000000",
    popupBackground: "#ffffff",
    popupTxt: "#000000",
    headerSubsectionBackground: "#000000",
    bellyArtBorder: "#FFD700",
    casinoJackpotBackgroundGradientColor1: "#ff86af",
    casinoJackpotBackgroundGradientColor2: "#c9255c",
    casinoJackpotRecentWinnersTitle: "#ffff00",
    casinoJackpotRecentWinnersText: "#8b0000",
    casinoJackpotAmount: "#fff",
    casinoJackpotInfoText: "#ffffff",
    casinoJackpotInfoButton: "#2c2c2c",
    casinoJackpotInfoBackground: "#2c2c2c",
    casinoJackpotInfoButtonBackground: "#c1c1c1",
    couponSequencePrevious: "#008000",
    couponSequenceCurrent: "#0000FF",
    couponSequenceNext: "#808080",
    couponSequenceLabels: "#000",
    couponSequenceHints: "#fff",
    tileGameNameTxt: "#fff",
    gradientColor: "#FFF"
  },
  dark: {
    // Theme colors from PlayCroco casino
    inputLabelFocused: "#56ee53",
    body: "#093631",
    header: "#004d37",
    headerElemScrollDown: "#ffffff",
    headerElemScrollDownStroke: "#000000",
    bodyTitle: "#ffffff",
    navbar: "#ff6000",
    button: "#ff6000",
    buttonTxt: "#ffffff",
    buttonSecondary: "#48a145",
    buttonSecondaryTxt: "#ffffff",
    buttonFreeSpins: "#ff6000",
    buttonFreeSpinsTxt: "#ffffff",
    balance: "#48a145",
    hyperlink: "#48a145",
    disabled: "#f9863e",
    disabledTxt: "#ffffff",
    jackpot: "#093631",
    jackpotTxt: "#e6c20f",
    input: "#545454",
    inputTxt: "#56ee53",
    inputFocus: "#545454",
    inputFocusTxt: "#56ee53",
    success: "#48a145",
    successTxt: "#ffffff",
    error: "#ff5252",
    errorTxt: "#ffffff",
    progressTracker: "#6ee53d",
    progressTrackerTxt: "#f02828",
    newGamesTitle: "#ff6000",
    newGamesBackgroundColor: "#016f50",
    popupBackground: "#093631",
    popupTxt: "#ffffff",
    headerSubsectionBackground: "#016f50",
    bellyArtBorder: "#48a145",
    casinoJackpotBackgroundGradientColor1: "#48a145",
    casinoJackpotBackgroundGradientColor2: "#093631",
    casinoJackpotRecentWinnersTitle: "#ffe01a",
    casinoJackpotRecentWinnersText: "#ff6000",
    casinoJackpotAmount: "#ffffff",
    casinoJackpotInfoText: "#ffffff",
    casinoJackpotInfoButton: "#ffffff",
    casinoJackpotInfoBackground: "#093631",
    casinoJackpotInfoButtonBackground: "#48a145",
    couponSequencePrevious: "#008000",
    couponSequenceCurrent: "#0000ff",
    couponSequenceNext: "#808080",
    couponSequenceLabels: "#ffffff",
    couponSequenceHints: "#ffffff",
    tileGameNameTxt: "#ffffff",
    gradientColor: "#000"
  }
};

const defaultTournamentThemes = {
  light: {
    tournamentTab: "#00479f",
    tournamentTabInactive: "#022856",
    tournamentTabTxt: "#ffffff",
    tournament: "#bd4428",
    tournamentTxt: "#ffffff",
    tournamentDetail: "#ffffff",
    tournamentDetailTxt: "#000000",
    tournamentInfo: "#4c7ebc",
    tournamentInfoTxt: "#ffffff",
    tournamentSuccess: "#a3c654",
    tournamentError: "#9a0707",
    tournamentTime: "#b80000",
    tournamentFilterTxt: "#c7c7c7",
    tournamentFilter: "#0259be",
    tournamentFilterIcon: "#c7c7c7",
    tournamentSearch: "#fff",
    tournamentSearchTxt: "#000",
    tournamentHeader: "#4f4f4f",
    tournamentHeaderTxt: "#c7c7c7",
    tournamentGameName: "#eeeeee",
    tournamentGameNameTxt: "#000",
    tournamentDeposit: "#0b4a03",
    tournamentDepositTxt: "#c7c7c7",
    tournamentListBackground: "#DEDEDE",
    tournamentBackground: "#000"
  },
  dark: {
    tournamentTab: "#00479f",
    tournamentTabInactive: "#022856",
    tournamentTabTxt: "#ffffff",
    tournament: "#bd4428",
    tournamentTxt: "#ffffff",
    tournamentDetail: "#ffffff",
    tournamentDetailTxt: "#000000",
    tournamentInfo: "#4c7ebc",
    tournamentInfoTxt: "#ffffff",
    tournamentSuccess: "#a3c654",
    tournamentError: "#9a0707",
    tournamentTime: "#b80000",
    tournamentFilterTxt: "#c7c7c7",
    tournamentFilter: "#0259be",
    tournamentFilterIcon: "#c7c7c7",
    tournamentSearch: "#fff",
    tournamentSearchTxt: "#000",
    tournamentHeader: "#4f4f4f",
    tournamentHeaderTxt: "#c7c7c7",
    tournamentGameName: "#4f4f4f",
    tournamentGameNameTxt: "#c7c7c7",
    tournamentDeposit: "#0b4a03",
    tournamentDepositTxt: "#c7c7c7",
    tournamentListBackground: "#DEDEDE",
    tournamentBackground: "#000"
  }
};

const customThemes = store.getters["customizations/themes"];
const customTournamentThemes = store.getters["customizations/tournamentThemes"];

const applyCustomThemes = (defaultThemes, customThemes = {}) => {
  return {
    dark: {
      ...defaultThemes.dark,
      ...(customThemes.dark || {}),
      ...defaultTournamentThemes.dark,
      ...(customTournamentThemes?.dark || {})
    },
    light: {
      ...defaultThemes.light,
      ...(customThemes.light || {}),
      ...defaultTournamentThemes.light,
      ...(customTournamentThemes?.light || {})
    }
  };
};

const themes = applyCustomThemes(defaultThemes, customThemes);
const tournamentThemes = applyCustomThemes(
  defaultTournamentThemes,
  customTournamentThemes
);

export default new Vuetify({
  theme: {
    dark: getInitialDarkModeSetting(),
    options: {
      customProperties: true
    },
    themes,
    tournamentThemes
  },
  icons: {
    values: {
      // flaticon values for nav bar, sandwich menu and others
      AccountHistory: "fi-rr-time-past",
      AllGames: "fi-rr-search",
      Calendar: "fi-rr-calendar",
      Close: "fi-rr-cross",
      Check: "fi-rr-check-circle",
      ContactUs: "fi-rr-envelope-plus",
      Coupons: "fi-rr-ticket",
      Deposit: "fi-rr-wallet",
      Dropdown: "fi-rr-angle-small-down",
      Delete: "fi-rr-trash",
      Refresh: "fi-rr-refresh",
      Warning: "fi-rr-exclamation",
      // lowercase "dropdown" is the default icon for vuetify dropdowns
      dropdown: "fi-rr-angle-small-down",
      Home: "fi-rr-home",
      Info: "fi-rr-info",
      LegalNotices: "fi-rr-document",
      LiveChat: "fi-rr-comment",
      LobbyJackpot: "fi-rr-star-comment-alt",
      Login: "fi-rr-user",
      Logout: "fi-rr-exit",
      Messages: "fi-rr-envelope",
      MyProfile: "fi-rr-user",
      Phone: "fi-rr-phone-flip",
      Play: "fi-rr-play",
      PrivacyPolicy: "fi-rr-document",
      Promotions: "fi-rr-badge-percent",
      ReferAFriend: "fi-rr-user-add",
      Search: "fi-rr-search",
      SignUp: "fi-rr-user",
      StarEmpty: "fi-rr-star",
      StarFilled: "fi-sr-star",
      TermsAndConditions: "fi-rr-document",
      Tournaments: "fi-rr-trophy",
      Withdraw: "fi-rr-money-bill-wave",
      Grid: "fi fi-rr-apps",
      List: "fi fi-rr-ballot",
      progressives: { component: Progressives },
      liveDealer: { component: LiveDealer },
      slots: { component: Slots },
      specialty: { component: Specialty },
      tableGames: { component: TableGames },
      crashGames: { component: CrashGames },
      videoPoker: { component: VideoPoker },
      newGames: { component: NewGames },
      favorites: { component: Favorites },
      //Tournaments: { component: TournamentsIcon },
      recentGames: { component: RecentGames },
      gameMenu: { component: GameMenu },
      balance: { component: Balance },
      //LiveChat: { component: Chat },
      message: { component: Message },
      //Search: { component: Search },
      home: { component: HomeIcon },
      //AccountHistory: { component: AccountHistory },
      //ContactUs: { component: Contact },
      darkLightMode: { component: DarkLightMode },
      //LegalNotices: { component: LegalNotices },
      logout: { component: Logout },
      //Promotions: { component: Promotions },
      //Withdraw: { component: Withdraw },
      //PrivacyPolicy: { component: PrivacyPolicy },
      //Deposit: { component: Deposit },
      allGames: { component: AllGames },
      countDown: { component: CountDown },
      //LobbyJackpot: { component: LobbyJackpotIcon },
      //MyProfile: { component: MyProfileIcon }
      GamesGuru: { component: GamesGuruIcon },
      popular: { component: Popular }
    }
  }
});
