<template>
  <MobileMarketingPanel />
</template>

<script>
import MobileMarketingPanel from "@/components/MobileMarketingPanel";
export default {
  components: {
    MobileMarketingPanel
  }
};
</script>
