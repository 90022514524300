<template>
  <v-dialog v-model="showDialog" width="370" overlay-opacity="0.9">
    <v-card v-if="showDialog">
      <DialogTitleBar
        :title="$str('ResetPasswordHeader')"
        @close="showDialog = false"
      />
      <v-card-text class="px-4 pb-5 pt-1" style="font-size: 12px;">
        <!-- eslint-disable vue/no-v-html -->
        <v-alert
          v-show="displayMessage"
          :type="messageType"
          dense
          :icon="false"
          class="text-left mb-4"
          style="font-weight: 700;"
        >
          {{ displayMessage }}
        </v-alert>
        <div class="text-left mb-8" v-html="$strResourceService('ResetPasswordInfo')" />
        <v-form id="change-password-form" ref="form" v-model="valid">
          <v-text-field
            v-model.trim="fieldValues.securityCode"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            :label="$str('securityCode')"
            :rules="validateFields ? securityCodeRules : []"
          ></v-text-field>

          <v-text-field
            v-model="fieldValues.password"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="accountCreateFieldsAttributes.Password"
            :label="$str('password')"
            :rules="validateFields ? accountCreateFieldsRules.Password : []"
            autocomplete="new-password"
          ></v-text-field>

          <PasswordValidation
            v-if="strengthEnable && featurePasswordStrengthEnabled"
            ref="passwordValidation"
            :password="fieldValues.password"
            :min-password="minPaswwordLength"
            @passwordMounted="passwordMount"
          ></PasswordValidation>

          <v-text-field
            v-model="fieldValues.confirmPassword"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="loginFieldsAttributes.Password"
            :label="$strResourceService('ConfirmationPassword')"
            name="ConfirmPassword"
            :rules="validateFields ? confirmPasswordRules : []"
            autocomplete="new-password"
          ></v-text-field>
        </v-form>
        <v-row class="pt-1">
          <v-col cols="6" style="padding-right: 5px;">
            <v-btn
              color="buttonSecondary buttonSecondaryTxt--text"
              block
              @click="requestNewCode"
            >
              {{ $str("RequestNewCode") }}</v-btn
            >
          </v-col>
          <v-col cols="6" style="padding-left: 5px;">
            <v-btn
              class="mb-3"
              color="button buttonTxt--text"
              :disabled="!valid"
              form="form"
              type="submit"
              block
              @click="changePassword"
              >{{ $str("OKButton") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";
import PasswordValidation from "@/components/common/PasswordValidation";
export default {
  components: { PasswordValidation },
  mixins: [dialogToggle],
  data() {
    return {
      name: "changePassword",
      fieldValues: {
        securityCode: "",
        password: "",
        confirmPassword: ""
      },
      message: "",
      messageType: "success",
      valid: false,
      validateFields: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordMounted: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "loginFieldsAttributes",
      "loginFieldsRules",
      "strengthEnable",
      "featurePasswordStrengthEnabled",
      "accountCreateFields",
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ]),
    login() {
      return this.$store.getters["dialogs/changePassword"].data.login;
    },
    email() {
      return this.$store.getters["dialogs/changePassword"].data.email;
    },
    confirmMessage() {
      return this.$store.getters["dialogs/changePassword"].data.confirmMessage;
    },
    confirmPasswordRules() {
      return [
        v => !!v || this.$str("FieldRequieredMessage"),
        v =>
          v === this.fieldValues.password ||
          this.$strResourceService("PasswordNoMatch")
      ];
    },
    securityCodeRules() {
      return [v => !!v || this.$str("FieldRequieredMessage")];
    },
    displayMessage() {
      return this.message || this.confirmMessage;
    },
    instructionsMessage() {
      return this.$str("ResetPasswordAddInfo");
    },
    minPaswwordLength() {
      return this.accountCreateFields.Password.minLength.value;
    },
    isPasswordValid() {
      return this.strengthEnable && this.featurePasswordStrengthEnabled
        ? this.passwordMounted && this.$refs.passwordValidation?.isPasswordValid
        : true;
    }
  },
  methods: {
    onShown() {
      this.$refs.form?.reset();
      const securityCode = this.$store.getters["dialogs/changePassword"].data
        .securityCode;
      if (securityCode) {
        this.fieldValues.securityCode = securityCode;
      }
    },
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form?.validate();
    },
    async changePassword() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }

      try {
        await this.$store.dispatch("session/changePassword", {
          login: this.login,
          email: this.email,
          password: this.fieldValues.password,
          token: this.fieldValues.securityCode
        });
        this.showDialog = false;
        window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
      } catch (error) {
        this.message = error.Message;
        this.messageType = "error";
        window.console.error(
          `There was an error changing password: ${error.Message}`
        );
      }
    },
    async requestNewCode() {
      try {
        const response = await this.$store.dispatch("session/requestNewCode", {
          email: this.email,
          userName: this.login
        });
        this.message = response;
        this.messageType = "success";
      } catch (error) {
        this.message = error.Message;
        this.messageType = "error";
        window.console.error(
          `There was an error getting a new code: ${error.Message}`
        );
      }
    },
    onHidden() {
      this.message = "";
      this.messageType = "success";
      this.validateFields = false;
      this.$refs.form?.resetValidation();
      this.$refs.form?.reset();
    },
    passwordMount() {
      this.passwordMounted = true;
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 600px) {
  ::v-deep.v-alert--text:before {
    background-color: transparent !important;
  }
  ::v-deep.v-alert--text > .v-alert__wrapper {
    display: inline-flex !important;
  }
}
</style>
