<template>
  <v-dialog v-model="showDialog" width="370" overlay-opacity="0.9">
    <v-card v-if="showDialog">
      <DialogTitleBar :title="$str('ForgotUsernameHeader')" @close="close" />
      <v-card-text class="px-4" style="font-size: 12px;">
        <div class="text-left mb-8">
          {{ $strResourceService("ForgotUserName") }}
        </div>
        <v-form ref="form">
          <v-text-field
            v-model="email"
            class="required"
            outlined
            dense
            hide-details="auto"
            color="inherit"
            v-bind="accountCreateFieldsAttributes.EMailAddress"
            :rules="validateFields ? accountCreateFieldsRules.EMailAddress : []"
            :label="$strResourceService('EmailAddress')"
          ></v-text-field>
        </v-form>
        <v-row class="pt-1">
          <v-col cols="6" style="padding-right: 5px;">
            <v-btn
              color="buttonSecondary buttonSecondaryTxt--text"
              block
              @click="close()"
            >
              {{ $strResourceService("Cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" style="padding-left: 5px;">
            <v-btn
              class="mb-3"
              color="button buttonTxt--text"
              type="submit"
              block
              @click="retrieveUsername()"
              >{{ $str("OKButton") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters } from "vuex";

export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "forgotUsername",
      email: "",
      errorMessage: "",
      validateFields: false
    };
  },
  computed: {
    ...mapGetters("accountFields", [
      "accountCreateFieldsAttributes",
      "accountCreateFieldsRules"
    ])
  },
  methods: {
    async validate() {
      this.validateFields = true;

      // Have to wait a frame after enabling validation before calling .validate()
      await new Promise(r => setTimeout(r, 0));

      return this.$refs.form?.validate();
    },
    async retrieveUsername() {
      const fieldsValid = await this.validate();
      if (!fieldsValid) {
        return;
      }

      try {
        await CDK.retrieveUsername(this.email);
        this.showDialog = false;
        window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
          confirmMessage: this.$strResourceService("ForgotUsernameSucceed")
        });
      } catch (error) {
        this.errorMessage = error.Message;
        window.console.error(error);
      }
    },
    onHidden() {
      this.errorMessage = "";
      this.validateFields = false;
      this.$refs.form?.resetValidation();
      this.$refs.form?.reset();
    },
    close() {
      this.showDialog = false;
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
    }
  }
};
</script>
<style scoped>
@media only screen and (min-width: 600px) {
  ::v-deep.v-alert--text:before {
    background-color: transparent !important;
  }
  ::v-deep.v-alert--text > .v-alert__wrapper {
    display: inline-flex !important;
  }
}
</style>
