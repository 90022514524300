/**
 * Helpers for managing cached store modules
 */

import { skinId } from "@/services/casino";

const currentBuild =
  document.querySelector('meta[name="buildinfo"]')?.content || "";
const currentSkinId = skinId;

const hasCache = () => {
  // Sometimes accessing localStorage can throw security exceptions when
  // it is not accessible, so use a try/catch to be safe
  try {
    return !!localStorage?.vuex;
  } catch (e) {
    return false;
  }
};

const cacheIsValid = () => {
  return (
    currentBuild === localStorage?.vuexbuild &&
    currentSkinId === Number(localStorage?.vuexskinid)
  );
};

const clearCache = () => {
  localStorage.removeItem("vuex");
  localStorage.removeItem("vuexbuild");
  localStorage.removeItem("vuexskinid");
};

const updateMetadata = () => {
  localStorage.setItem("vuexbuild", currentBuild);
  localStorage.setItem("vuexskinid", currentSkinId);
};

export const validateCache = () => {
  if (!hasCache) {
    return;
  }

  if (!cacheIsValid()) {
    clearCache();
  }

  updateMetadata();
};
