/**
 * Data about third-party marketing panels
 *
 * @see Admin -> Configuration -> Mobile -> Marketing Panels
 * @see @/components/MobileMarketingPanel.vue
 */
import { restoreSessionCompleted } from "@/services/casino";
const state = {
  panels: []
};

const getters = {
  panels: state => state.panels
};

const actions = {
  async initialize(context) {
    await restoreSessionCompleted;
    CDK.on(CDK.Events.loggedIn, () => {
      context.dispatch("requestMarketingPanels");
    });
    CDK.on(CDK.Events.loggedOut, () => {
      if (CDK.isConnected()) {
        context.dispatch("requestMarketingPanels");
      }
    });
    context.dispatch("requestMarketingPanels");
  },
  async requestMarketingPanels(context) {
    try {
      await CDK.getLobbyMarketingPanels().then(panels =>
        context.commit("updatePanels", panels)
      );
    } catch (error) {
      window.console.warn(
        `There was an error requesting marketing panels: ${error}`
      );
    }
  }
};

const mutations = {
  updatePanels(state, panels) {
    state.panels = panels;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
