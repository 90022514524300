<template>
  <v-fade-transition>
    <v-bottom-navigation
      v-show="showNavbar"
      id="bottom-nav-menu"
      v-scroll="onScroll"
      :value="selectedBtn"
      app
      :height="navBarHeight"
      class="navbar align-end"
      :style="footerStyle"
    >
      <v-subheader
        v-if="loggedIn"
        class="bottom-menu-bar-balance pr-3"
        @click="showBalanceDetail"
      >
        <v-btn
          class="bottom-menu-bar-balance-text font-weight-bold body-2 rounded"
          :height="40"
        >
          <v-icon small>$Deposit</v-icon>
          <span id="mainViewBalanceAmount" class="mb-0 ml-2">
            {{ $cur(balanceAmount) }}
          </span></v-btn
        >
      </v-subheader>

      <!-- TODO: Component for these buttons? They are fairly repeated -->
      <!-- or at least a v-for, based on a computed array? Then the contents can changed in computed handler? -->

      <v-btn
        v-for="item in navBarItems"
        :id="`mainView${item.Name}Btn`"
        :key="item.ID"
        :value="item.Name"
        class="caption pa-0"
        @click="item.Type === 6 ? showOption(item) : showLink(item)"
      >
        <span>{{
          item.DisplayName === "Tournaments"
            ? $str(item.DisplayName)
            : item.DisplayName
        }}</span>
        <v-icon v-if="!item.CustomIcon" small> {{ `mdi-${item.Icon}` }}</v-icon>
        <v-icon v-else small>{{ getIcon(item.Name) }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { customScriptHandlers } from "@/services/casino";

export default {
  data() {
    return {
      // The height (in pixels) of the navbar, not considering the balance tab
      navBarHeight: 50,
      lastScrollPosition: window.pageYOffset,
      showNavbar: true,
      dialogs: ["search", "coupons", "chat", "signIn"],
      activeOption: "home"
    };
  },
  computed: {
    ...mapGetters("session", [
      "playForMoney",
      "loggedIn",
      "liveChatInfo",
      "restoreSessionCompleted",
      "customScriptAvailability"
    ]),
    ...mapGetters("lobbyComponents", [
      "componentIsHidden",
      "componentValue",
      "componentUrl"
    ]),
    ...mapGetters("lobbyMenus", ["navBar"]),
    ...mapGetters("balances", ["balance", "withdrawable"]),
    ...mapGetters("dialogs", ["isAnyDialogOpen"]),
    ...mapGetters("customizations", ["specialThemes"]),
    balanceAmount() {
      return this.$route.name === "tournaments"
        ? this.withdrawable
        : this.balance;
    },
    promotionsVisible() {
      return (
        !this.componentIsHidden("*Menu/Lobby-Promotions") &&
        this.componentUrl("*Menu/Lobby-Promotions")
      );
    },
    promotionsDisplayName() {
      return this.componentValue("*Menu/Lobby-Promotions");
    },
    tournamentsVisible() {
      return this.loggedIn && this.$store.getters["tournaments/isActive"];
    },
    tournamentsDisplayName() {
      return this.$store.getters["tournaments/displayName"];
    },
    selectedBtn() {
      return this.isAnyDialogOpen
        ? this.activeOption
        : this.$route.name === "customhome"
        ? "home"
        : "AllGames";
    },
    navBarItems() {
      const navbar = this.navBar
        .filter(
          item =>
            (this.loggedIn &&
              item.AfterLogin &&
              (item.SessionGame === 1 ||
                (item.SessionGame === 2 && this.playForMoney) ||
                (item.SessionGame === 3 && !this.playForMoney))) ||
            (!this.loggedIn && item.PriorToLogin)
        )
        .filter(item => !(!this.hasCustomHome() && item.Name === "AllGames"))
        .slice(0, 5);
      return navbar;
    },
    footerStyle() {
      const background =
        this.specialThemes &&
        this.specialThemes[this.$mode] &&
        this.specialThemes[this.$mode].specialThemeNavbarMobileURL
          ? {
              backgroundImage: `url(${
                this.specialThemes[this.$mode].specialThemeNavbarMobileURL
              })`,
              backgroundSize: "cover"
            }
          : {};
      return background;
    }
  },
  methods: {
    ...mapActions("dialogs", [
      "showBalanceDetail",
      "showExternalUrl",
      "showLiveChat",
      "showSearch",
      "showCustomUrl"
    ]),
    onScroll() {
      const currentScrollPosition = window.pageYOffset;

      if (currentScrollPosition < 0) return;

      this.showNavbar = currentScrollPosition < this.lastScrollPosition;

      this.lastScrollPosition = currentScrollPosition;
    },
    showOption(item) {
      this.activeOption = item.Name;
      const hasCustomScriptHandler = name => {
        return (
          customScriptHandlers.customNavBarOptionHandler &&
          customScriptHandlers.customNavBarOptionHandler.find(
            handler => handler.key === name
          )
        );
      };
      if (hasCustomScriptHandler(item.Name)) {
        const customHandler = customScriptHandlers.customNavBarOptionHandler.find(
          handler => handler.key === item.Name
        );
        return customHandler.handler();
      }

      if (item.URL) {
        this.showLink(item);
      } else this["option" + item.Name]();
    },
    optionHome() {
      this.goHome();
    },
    optionAllGames() {
      this.goHome(true);
    },
    optionSearch() {
      this.showSearch();
    },
    async optionCoupons() {
      await this.$store.dispatch("coupons/getActiveCoupon");
      this.$store.dispatch("coupons/updateActiveCouponMessage");
      window.LobbyCore.raise(window.LobbyCore.Events.showCouponsPage);
    },
    optionLiveChat() {
      this.showLiveChat({location: "lobby"});
    },
    optionLogin() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
    },
    optionTournaments() {
      this.$router.push("/tournaments");
    },
    optionSignUp() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
    },
    async showLink(option) {
      if (CDK.isLoggedIn() && option.URL.includes("<Token>")) {
        await CDK.generateGameToken();
      }
      this.showCustomUrl({
        title: option.DisplayName,
        source: option.URL,
        newTab: option.NewTab
      });
    },
    getIcon(itemName) {
      return "$" + itemName;
    }
  }
};
</script>

<style scoped>
::v-deep .v-item-group.v-bottom-navigation > .v-btn {
  border-radius: 20px !important;
  padding: 5px 10px !important;
  margin-bottom: 5px;
  min-width: 50px;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 54px !important;
}

::v-deep .v-item-group.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: var(--v-bodyTitle-base) !important;
}

.theme--light.v-bottom-navigation
  .bottom-menu-bar-balance-text.v-btn:not(.v-btn--active),
.theme--dark.v-bottom-navigation
  .bottom-menu-bar-balance-text.v-btn:not(.v-btn--active) {
  color: var(--v-buttonTxt-base) !important;
}

::v-deep .v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: var(--v-bodyTitle-base) !important;
}

::v-deep
  .v-item-group.v-bottom-navigation
  .bottom-menu-bar-balance-text.v-btn.v-btn--active {
  color: var(--v-buttonTxt-base) !important;
}
::v-deep
  .v-item-group.v-bottom-navigation
  .bottom-menu-bar-balance-text.v-btn.v-btn:not(.v-btn--active) {
  color: var(--v-buttonTxt-base) !important;
}

/* Prevent selected button from being highlighted */
::v-deep .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}

::v-deep .v-item-group.v-bottom-navigation .v-subheader .v-btn {
  background-color: var(--v-button-base) !important;
}

::v-deep .v-item-group.v-bottom-navigation .v-subheader .v-btn .v-btn__content {
  flex-direction: row;
}

::v-deep
  .v-item-group.v-bottom-navigation
  .v-subheader
  .v-btn
  .v-btn__content
  > *:not(.v-icon) {
  line-height: normal;
}

.v-item-group.v-bottom-navigation {
  justify-content: space-evenly;
}

.bottom-menu-bar-balance-text {
  color: var(--v-buttonTxt-base) !important;
  background-color: var(--v-button-base) !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5) !important;
}

::v-deep .v-btn__content {
  display: flex;
  flex-direction: column-reverse;
}

::v-deep .bottom-menu-bar-balance-text .v-btn__content {
  flex-direction: row !important;
}

.bottom-menu-bar-balance {
  height: 0;
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 999;
}
</style>
