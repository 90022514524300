<template>
  <v-btn
    id="LobbyJackpotOptIn"
    class="font-weight-bold px-2"
    color="button buttonTxt--text"
    form="form"
    height="40px"
    min-width="60px"
    @click="OptIn"
  >
    {{ $str("OptIn") }}
    <ConfirmDialog ref="confirm" />
  </v-btn>
</template>

<script>
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ConfirmDialog
  },
  computed: {
    ...mapGetters("casinoJackpot", ["costToPlayer"])
  },
  methods: {
    ...mapActions("casinoJackpot", ["SetOptIn"]),
    async OptIn() {
      const costToPlayer = await CDK.getLobbyJackpotCostToPlayer();
      const confirmed = await this.$refs.confirm.open({
        title: this.$str("JackpotOptIn").replace(
          "<JackpotName>",
          this.$str("LobbyJackpot")
        ),
        message: this.$str("OptInConfirmationMessage").replace(
          "<AMOUNT>",
          this.$cur(costToPlayer)
        )
      });

      if (confirmed) {
        this.SetOptIn(true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 60px;
}
</style>
