import { restoreSessionCompleted } from "@/services/casino";

/**
 * Actions triggered by URL parameters, etc.
 */
export const LobbyActions = {
  CompPoints: {
    cmd: "dialogs/showCompPoints",
    event: null,
    loginRequired: true
  },
  Cashier: {
    cmd: "dialogs/showDeposit",
    event: "showDepositPage",
    loginRequired: true
  },
  Deposit: {
    cmd: "dialogs/showDeposit",
    event: "showDepositPage",
    loginRequired: true
  },
  DepositWithRedirect: {
    cmd: "dialogs/showDepositWithRedirect",
    event: "showDepositWithRedirectPage",
    loginRequired: true
  },
  ExitGame: {
    cmd: "dialogs/exitGame",
    event: null,
    loginRequired: false
  },
  LaunchExternalLobby: {
    cmd: "externalLobbies/launchLobby",
    event: null,
    loginRequired: true
  },
  LaunchGame: {
    cmd: "games/launchGame",
    event: null,
    loginRequired: true
  },
  LiveChat: {
    cmd: "dialogs/showLiveChat",
    event: null,
    loginRequired: false
  },
  MessageCenter: {
    cmd: "dialogs/showMessageCenterList",
    event: null,
    loginRequired: true
  },
  RedeemCoupon: {
    cmd: "dialogs/showRedeemCoupon",
    event: "showRedeemCouponPage",
    loginRequired: true
  },
  ReloadGame: {
    cmd: "games/reloadGame",
    event: null,
    loginRequired: false
  },
  SignIn: {
    cmd: "dialogs/showSignIn",
    event: "showSignInPage",
    loginRequired: false
  },
  SignUp: {
    cmd: "dialogs/showSignUp",
    event: "showSignUpPage",
    loginRequired: false
  },
  SwitchToRealFromGame: {
    cmd: "session/switchToRealFromGame",
    event: null,
    loginRequired: true
  },
  Website: {
    cmd: "dialogs/openWebsiteInNewWindow",
    event: null,
    loginRequired: false
  },
  Withdraw: {
    cmd: "dialogs/showWithdraw",
    event: "showWithdrawPage",
    loginRequired: true
  },
  WithdrawWithRedirect: {
    cmd: "dialogs/showWithdrawWithRedirect",
    event: "showWithdrawWithRedirectPage",
    loginRequired: true
  },
  SignOut: {
    cmd: "session/signOutRequestParameter",
    event: null,
    loginRequired: false
  },
  ShowTournament: {
    cmd: "tournaments/showTournament",
    event: null,
    loginRequired: true
  }
};

export const LaunchLobbyAction = async (store, action, params) => {
  await restoreSessionCompleted;
  const loggedIn = store.getters["session/loggedIn"];

  const openedSignUpDialog =
    store.getters["dialogs/openedSignUpDialog"].isVisible;
  if (action.loginRequired && !loggedIn) {
    if (openedSignUpDialog) {
      store.dispatch("session/setOnSuccessSignInAction", { action, params });
    } else {
      launchSignInAction(action, params);
    }
  } else {
    if (openedSignUpDialog) {
      store.dispatch("session/setOnSuccessSignInAction", { action, params });
    } else {
      setLaunchAction(store, action, params);
    }
  }
};

const launchSignInAction = (action, params) => {
  window.LobbyCore.raise(
    window.LobbyCore.Events.showSignInPage,
    {
      onSuccess: { action, params }
    },
    { root: true }
  );
};

const setLaunchAction = (store, action, params) => {
  if (action.event !== null) {
    window.LobbyCore.raise(action.event, params);
  } else {
    store.dispatch(action.cmd, params);
  }
};

export const launchAction = (store, action, params) => {
  setLaunchAction(store, action, params);
};
