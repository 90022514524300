export const generateUuid = function() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isSafari = function() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iP(ad|od|hone)/i);
  const hasSafariInUa = !!ua.match(/Safari/i);
  const noOtherBrowsersInUa = !ua.match(
    /Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i
  );
  let result = false;

  if (iOS) {
    //detecting Safari in IOS mobile browsers
    const webkit = !!ua.match(/WebKit/i);
    result = webkit && hasSafariInUa && noOtherBrowsersInUa;
  } else if (window.safari !== undefined) {
    //detecting Safari in Desktop Browsers
    result = true;
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa;
  }
  return result;
};

export const openPopup = function(url, height, weight, indexPopupsOpened) {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top;
  const offsetLeft = 25 * (indexPopupsOpened - 1) + dualScreenLeft;
  const offsetTop = 25 * (indexPopupsOpened - 1) + dualScreenTop;
  const windowParams = `resizable=1,width=${weight}, height=${height}, top=${offsetTop}, left=${offsetLeft}`;
  const newWindow = window.open(url, "", windowParams);

  if (window.focus && newWindow) newWindow.focus();

  return newWindow;
};

export const requestFullScreen = function(elem) {
  if (!document.fullscreenElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
};

export const cancelFullScreen = function() {
  if (document.fullscreenElement) {
    if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
};

const getFileExtensionFromUrl = function(url) {
  return url
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
};

const getMediaTypeFromExtension = function(extension) {
  switch (extension.toLowerCase()) {
    case "apng":
      return "image/apng";
    case "bpm":
      return "image/bmp";
    case "gif":
      return "image/gif";
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjpeg":
    case "pjp":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "svg":
      return "image/svg+xml";
    case "webp":
      return "image/webp";
    case "mp4":
      return "video/mp4";
    case "ogg":
      return "video/ogg";
    case "webm":
      return "video/webm";
    default:
      return "unknown";
  }
};

/**
 * Returns the media type for a file URL. Currently supports
 * images and videos.
 */
export const getMediaTypeFromUrl = function(url) {
  const extension = getFileExtensionFromUrl(url);
  return getMediaTypeFromExtension(extension);
};

export const objectKeysToLowerCase = obj =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
  );

export const isString = function(val) {
  return typeof val === "string" || val instanceof String;
};

export const checkUrlProtocol = function(url) {
  let valid = false;
  if (isString(url) && url !== "") {
    const protocols = ["http://", "https://", "rtg:///"];
    valid = protocols.some(prot => url.startsWith(prot));
  }
  return valid ? url : "http://" + url;
};

export const urlSearchParams = function(url) {
  const queryString = new URL(url).search;
  return Object.fromEntries(new URLSearchParams(queryString));
};

export const isNullorWhitespace = function(text) {
  return text === null || text.match(/^ *$/) !== null;
};

export const getStyleText = customScript => {
  const parsedDoc = new DOMParser().parseFromString(customScript, "text/html");
  const parsedScript = parsedDoc.getElementsByTagName("style");
  return Array.from(parsedScript);
};

export const createStyleElement = scriptText => {
  const script = document.createElement("style");
  if (scriptText.src) {
    script.src = scriptText.src;
  }
  script.appendChild(document.createTextNode(scriptText.textContent));
  return script;
};
