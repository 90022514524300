<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="smallLayout"
    :height="height"
    :width="width"
    :persistent="showTitleBar === false"
    max-width="70%"
    style="padding-bottom: 10px;"
    :content-class="contentClass"
  >
    <v-card
      v-if="showDialog"
      color="popupBackground"
      height="100%"
      :style="smallLayout ? '' : 'min-height:600px'"
    >
      <DialogTitleBar v-show="showTitleBar" :title="title" @close="close" />
      <v-btn
        v-show="!showTitleBar"
        id="dialogTitleBarCloseBtn"
        small
        icon
        class="pa-0"
        style="position: absolute; right: 15px; top: 10px;"
        :style="smallLayout ? '' : 'color: #757775 !important;'"
        @click.stop="showDialog = false"
      >
        <v-icon small>$Close</v-icon>
      </v-btn>
      <v-card-text
        :style="
          smallLayout
            ? 'padding: 0;'
            : 'padding: 0 16px 4px 16px; min-height:600px;'
        "
        style="height: 100%"
      >
        <v-container v-if="showLoadingPanel" fill-height white>
          <v-row justify="center"
            ><v-progress-circular
              color="grey"
              indeterminate
            ></v-progress-circular
          ></v-row>
        </v-container>
        <iframe
          v-show="showiFrame"
          width="100%"
          height="100%"
          :srcdoc="getHTML"
          :src="sourceUrl"
          style="background-color: white;"
          :style="
            smallLayout
              ? ''
              : 'min-height:600px; border: 1px solid rgba(255, 255, 255, 0.25)'
          "
          @load="iFrameLoaded"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { checkUrlProtocol } from "@/services/casino";
export default {
  mixins: [dialogToggle],
  data() {
    return {
      name: "externalUrl",
      isIframeLoaded: false
    };
  },
  computed: {
    title() {
      return this.$store.getters["dialogs/externalUrl"].data.title;
    },
    contentClass() {
      const classes = [];

      if (this.title === "Promotions") {
        classes.push("promotionsModal");
      }

      if (this.smallLayout) {
        classes.push("smallLayout");
      } else {
        classes.push("largeLayout");
      }

      return classes.join(" ");
    },
    sourceUrl() {
      return checkUrlProtocol(
        CDK.replaceParameters(
          this.$store.getters["dialogs/externalUrl"].data.source || ""
        )
      );
    },
    getHTML() {
      return this.$store.getters["dialogs/externalUrl"].data.HTML;
    },
    showLoadingPanel() {
      return !this.isIframeLoaded;
    },
    showiFrame() {
      return this.isIframeLoaded;
    },
    showTitleBar() {
      return this.$store.getters["dialogs/externalUrl"].data.showTitleBar;
    },
    height() {
      return this.$store.getters["dialogs/externalUrl"].data.height;
    },
    width() {
      return this.$store.getters["dialogs/externalUrl"].data.width;
    },
    closeEvent() {
      return (
        this.$store.getters["dialogs/externalUrl"].data.onClose ||
        window.LobbyCore.Events.closeExternalDialogs
      );
    },
    closeCallback() {
      return this.$store.getters["dialogs/externalUrl"].data.onCloseCallback;
    }
  },
  methods: {
    close() {
      window.LobbyCore.raise(this.closeEvent);
    },
    onShown() {
      window.addEventListener("beforeunload", this.beforeWindowUnload);
    },
    onHidden() {
      window.removeEventListener("beforeunload", this.beforeWindowUnload);
      this.isIframeLoaded = false;

      if (this.closeCallback instanceof Function) {
        this.closeCallback();
      }
    },
    iFrameLoaded() {
      this.isIframeLoaded = true;
    },
    beforeWindowUnload() {
      this.onHidden();
    },
    beforeRouteLeave(to, from, next) {
      this.onHidden();
      next();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  border: none;
}

::v-deep .v-dialog.v-dialog--active.promotionsModal {
  overflow-y: hidden;
}

::v-deep .v-dialog.v-dialog--active.promotionsModal.smallLayout {
  iframe {
    border: none !important;
  }
}
</style>
