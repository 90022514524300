/**
 * Debugging/testing utilities
 * Intended to run in development builds only
 *
 * example:
 *   if (process.env.NODE_ENV !== "production") {
 *     checkForMissingTranslations(state.strings);
 *   }
 */

/**
 * Try to detect missing translations by checking to see if a string
 * has the same translation for almost every language.
 */
export const checkForMissingTranslations = async stringKeys => {
  // If a language doesn't have at least this many unique translations, write a warning/error
  const uniqueTreshold = 5;

  const languages = [
    "EN",
    "DE",
    "ES",
    "FR",
    "IT",
    "JA",
    "KO",
    "PL",
    "PT",
    "RU",
    "SV",
    "TH",
    "TR",
    "VI",
    "ZH-CH",
    "ZH-TW"
  ];

  const translations = await Promise.all(
    languages.map(async lang => {
      const translated = await CDK.getStringsForLanguage(stringKeys, lang);
      return [lang, translated];
    })
  );

  const byLanguage = Object.fromEntries(translations);

  // For each string, check and see how many unique translations it has. If a string has
  // mostly the same translation for every language, it's a sign that it is still missing translations
  stringKeys.forEach(key => {
    const translationsForKey = languages.map(lang => byLanguage[lang][key]);
    const uniqueTranslations = new Set(translationsForKey);
    if (uniqueTranslations.size < uniqueTreshold) {
      const logger =
        uniqueTranslations.size > 1
          ? window.console.warn
          : window.console.error;
      logger(
        `String "${key}" only has ${uniqueTranslations.size} unique translations - other languages might need to be translated.`
      );
    }
  });
};

const languages = [
  "EN",
  "DE",
  "ES",
  "FR",
  "IT",
  "JA",
  "KO",
  "PL",
  "PT",
  "RU",
  "SV",
  "TH",
  "TR",
  "VI",
  "ZH-CN",
  "ZH-TW"
];

export const setLanguage = async langCode => {
  await CDK.setLobbyLanguage(langCode);
  window.console.log(`Lobby language set to: ${langCode}`);
};

export const cycleLanguage = async () => {
  const currentIndex = languages.indexOf(CDK.getLanguageCode());
  const nextLanguage = languages[(currentIndex + 1) % languages.length];
  if (nextLanguage) {
    return setLanguage(nextLanguage);
  }
};
