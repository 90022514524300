import { mapGetters } from "vuex";
export const externalLobby = {
  computed: {
    ...mapGetters("externalLobbies", ["lobbies"])
  },
  methods: {
    isExternalLobbyVisible(externalLobbyId) {
      return Object.keys(
        Object.fromEntries(
          Object.entries(this.lobbies).filter(
            ([key]) =>
              this.lobbies[key].isVisible === true &&
              this.lobbies[key].externalLobbyId === parseInt(externalLobbyId)
          )
        )
      ).length > 0
        ? true
        : false;
    }
  }
};
