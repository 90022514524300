<template>
  <v-container>
    <v-dialog v-model="showDialog" fullscreen>
      <v-card
        v-if="showDialog"
        style="position: absolute; top: 0; left: 0;"
        class="elevation-12 pa-4"
      >
        <v-card-title class="mb-5 pb-0">
          {{ title }}<v-spacer></v-spacer>
          <v-icon id="searchDialogCloseBtn" @click.stop="showDialog = false">
            mdi-close</v-icon
          ></v-card-title
        >
        <p class="label mb-2 mx-5">Game Name</p>
        <v-text-field
          id="gameSearchTxtInput"
          v-model="search"
          :search-input.sync="search"
          hide-details
          autofocus
          color="inherit"
          outlined
          dense
          class="my-0 mx-5 pb-5 rounded"
        >
        </v-text-field>
        <template v-for="(game, index) in items">
          <GameRow
            :key="index"
            :game-id="game.gameId"
            class="px-5"
            @close="showDialog = false"
          ></GameRow>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import searchDialog from "@/mixins/shared/searchDialog";
import GameRow from "@/components/GameRow.vue";
import displayNameValidator from "@/mixins/shared/displayNameValidator";
export default {
  components: {
    GameRow
  },
  mixins: [searchDialog, displayNameValidator],
  computed: {
    items() {
      return this.searchGames();
    },
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/search"].data.title, this.$str('SearchLabel'));
    }
  }
};
</script>
<style scoped>
.v-list {
  padding: 0 !important;
}

.label {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
