/**
 * Field data used to create the signup and login forms
 */

// see: https://developers.google.com/web/fundamentals/design-and-ux/input/forms#recommended_input_name_and_autocomplete_attribute_values
const autocompleteAttributes = {
  Login: "username",
  Password: "current-password",
  FirstName: "given-name",
  LastName: "family-name",
  AddressLine1: "address-line1",
  AddressLine2: "address-line2",
  City: "address-level2",
  State: "address-level1",
  Zip: "postal-code",
  DaytimePhone: "tel",
  EveningPhone: "tel",
  cellPhone: "tel",
  EMailAddress: "email",
  Birthdate: "bday",
  Country: "country",
  gender: "sex",
  Otp: "otp"
};

const inputTypes = {
  Password: "password",
  DaytimePhone: "tel",
  EveningPhone: "tel",
  cellPhone: "tel",
  EMailAddress: "email",
  Birthdate: "date"
};

const state = {
  loginFields: {},
  loginFieldsAttributes: {},
  loginFieldsRules: {},
  accountCreateFields: {},
  accountCreateFieldsAttributes: {},
  accountCreateFieldsRules: {},
  playerRestrictions: {},
  signupFormType: "Long",
  signupEnabled: false,
  forgotUsernameEnabled: false,
  forgotPasswordEnabled: false,
  rememberPasswordEnabled: false,
  funEnabled: false,
  accountFieldsLoaded: false,
  currencyList: [],
  strengthEnable: 0,
  featurePasswordStrengthEnabled: false,
  showEmailUsernameField: true
};

const getters = {
  loginFields: state => state.loginFields,
  loginFieldsAttributes: state => state.loginFieldsAttributes,
  loginFieldsRules: state => state.loginFieldsRules,
  accountCreateFields: state => state.accountCreateFields,
  accountCreateFieldsAttributes: state => state.accountCreateFieldsAttributes,
  accountCreateFieldsRules: state => state.accountCreateFieldsRules,
  playerRestrictions: state => state.playerRestrictions,
  signupFormType: state => state.signupFormType,
  signupEnabled: state => state.signupEnabled,
  forgotUsernameEnabled: state => state.forgotUsernameEnabled,
  forgotPasswordEnabled: state => state.forgotPasswordEnabled,
  rememberPasswordEnabled: state => state.rememberPasswordEnabled,
  funEnabled: state => state.funEnabled,
  accountFieldsLoaded: state => state.accountFieldsLoaded,
  currencyList: state => state.currencyList,
  strengthEnable: state => state.strengthEnable,
  featurePasswordStrengthEnabled: state => state.featurePasswordStrengthEnabled,
  showEmailUsernameField: state => state.showEmailUsernameField
};

const actions = {
  async initialize(context) {
    await context.dispatch("getCurrencyList");
    await context.dispatch("getPasswordStrengthEnabled");
    await context.dispatch("getFeaturePasswordEnabled");
    await context.dispatch("getShowEmailUsernameField");
    context.dispatch("loadAccountFields");
  },
  async loadAccountFields(context) {
    await CDK.getAccountFields().then(
      (
        loginFields,
        accountCreateFields,
        playerRestrictions,
        signupFormType,
        signupEnabled,
        forgotUsernameEnabled,
        forgotPasswordEnabled,
        rememberPasswordEnabled,
        funEnabled
      ) => {
        const accountFields = {
          loginFields,
          accountCreateFields,
          playerRestrictions,
          signupFormType,
          signupEnabled,
          forgotUsernameEnabled,
          forgotPasswordEnabled,
          rememberPasswordEnabled,
          funEnabled
        };
        context.commit("updateAccountFields", accountFields);
      }
    );
  },
  async getCurrencyList(context) {
    const response = await CDK.getCurrencies();
    context.commit("updateCurrencyList", response);
  },
  async getPasswordStrengthEnabled(context) {
    const response = await CDK.getPasswordStrengthEnabled();
    context.commit("updatePasswordStrengthEnabled", response);
  },
  async getFeaturePasswordEnabled(context) {
    const response = await CDK.getFeaturePasswordEnabled();
    context.commit("updateFeaturePasswordStrengthEnabled", response);
  },
  async getShowEmailUsernameField(context) {
    const response = await CDK.UseRandomUsername();
    context.commit("updateShowEmailUsernameField", response);
  }
};

const mutations = {
  updateAccountFields(state, accountFields) {
    // map arrays of fields into dictionary-like objects in in state
    state.loginFields = Object.fromEntries(
      accountFields.loginFields.map(item => [item.field, item])
    );

    // generate input attributes
    state.loginFieldsAttributes = Object.fromEntries(
      accountFields.loginFields.map(item => [item.field, makeAttributes(item)])
    );

    state.loginFieldsRules = Object.fromEntries(
      accountFields.loginFields.map(item => [
        item.field,
        makeValidationRules(item)
      ])
    );

    state.accountCreateFields = Object.fromEntries(
      accountFields.accountCreateFields.map(item => [item.field, item])
    );

    state.accountCreateFieldsAttributes = Object.fromEntries(
      accountFields.accountCreateFields.map(item => [
        item.field,
        makeAttributes(item)
      ])
    );
    state.accountCreateFieldsRules = Object.fromEntries(
      accountFields.accountCreateFields.map(item => [
        item.field,
        makeValidationRules(item, accountFields.playerRestrictions)
      ])
    );
    state.playerRestrictions = accountFields.playerRestrictions;
    state.signupFormType = accountFields.signupFormType;
    state.signupEnabled = accountFields.signupEnabled;
    state.forgotUsernameEnabled = accountFields.forgotUsernameEnabled;
    state.forgotPasswordEnabled = accountFields.forgotPasswordEnabled;
    state.rememberPasswordEnabled = accountFields.rememberPasswordEnabled;
    state.funEnabled = accountFields.funEnabled;
    state.accountFieldsLoaded = true;
  },
  updateCurrencyList: (state, list) => {
    state.currencyList = list;
  },
  updatePasswordStrengthEnabled: (state, strengthEnable) => {
    state.strengthEnable = strengthEnable;
  },
  updateFeaturePasswordStrengthEnabled: (
    state,
    featurePasswordStrengthEnabled
  ) => {
    state.featurePasswordStrengthEnabled = featurePasswordStrengthEnabled;
  },
  updateShowEmailUsernameField: (state, showEmailUsernameField) => {
    state.showEmailUsernameField = showEmailUsernameField;
  }
};

const makeAttributes = function(field) {
  return {
    autocomplete: autocompleteAttributes[field.field],
    label: field.display,
    name: field.field,
    type:
      inputTypes[field.field] || (field.numbersOnly.value ? "number" : "text"),
    minlength: field.minLength && field.minLength.value,
    maxlength: field.maxLength && field.maxLength.value
  };
};

const makeValidationRules = function(field, playerRestrictions) {
  const rules = [];

  if (field.required && field.required.value) {
    rules.push(v => !!v || field.required.errorString);
  }

  if (field.allowQuotes && !field.allowQuotes.value) {
    rules.push(
      v => (v || "").indexOf('"') < 0 || field.allowQuotes.errorString
    );
  }

  if (field.allowSpaces && !field.allowSpaces.value) {
    rules.push(
      v => (v || "").indexOf(" ") < 0 || field.allowSpaces.errorString
    );
  }

  if (
    field.maxLength &&
    field.minLength &&
    field.maxLength.value > 0 &&
    field.maxLength.value !== field.minLength.value &&
    field.field !== "Birthdate"
  ) {
    rules.push(
      v =>
        (v || "").length <= field.maxLength.value || field.maxLength.errorString
    );
  }

  if (field.minLength && field.minLength.value > 0 && field.required.value) {
    rules.push(
      v =>
        (v || "").length >= field.minLength.value || field.minLength.errorString
    );
  }

  if (field.numbersOnly && field.numbersOnly.value) {
    rules.push(v => /^\d*$/.test(v || "") || field.numbersOnly.errorString);
  }

  if (field.useRegExp && field.useRegExp.value && field.regExp.value) {
    const regExp = RegExp(field.regExp.value);
    rules.push(v => regExp.test(v || "") || field.useRegExp.errorString);
  }

  if (field.field === "Birthdate") {
    if (playerRestrictions.minAge > 0 && playerRestrictions.appliesToSignup) {
      rules.push(
        v =>
          testDate(v || "", playerRestrictions.minAge) ||
          "You must be " +
            +playerRestrictions.minAge +
            " years or older to signup"
      );
    } else {
      rules.push(
        v => testDate(v || "", "0") || "Birthdate should not be in the future."
      );
    }
  }
  return rules;
};
const testDate = function(date, minAge) {
  if (date === "") {
    return true;
  }
  const input = date.split("-");
  const inputDate = new Date(input[0], input[1], input[2]);
  inputDate.setMonth(inputDate.getMonth() - 1);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - minAge);
  return inputDate <= maxDate;
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
