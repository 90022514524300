<template>
  <v-dialog
    v-model="showDialog"
    width="390"
    overlay-opacity="0.9"
    :fullscreen="smallLayout"
  >
    <v-card v-if="showDialog" height="90vh">
      <DialogTitleBar
        :title="title"
        color="transparent"
        @close="showDialog = false"
      />
      <v-card
        outlined
        class="mx-4 mt-2 mb-4"
        style="border-color: rgba(0,0,0,0.5); box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.50);"
      >
        <v-list
          color="popupBackground"
          class="pb-2"
          dense
          style="font-size:12px;"
        >
          <v-list-item v-if="customerCareTitleVisible">{{
            customerCareTitle
          }}</v-list-item>
          <v-list-item
            v-if="customerCare247Visible"
            :disabled="!customerCare247Url"
            @click="showCustomerCare247"
          >
            <v-icon small>$Messages</v-icon>
            <span class="ml-2">{{ customerCare247 }}</span>
          </v-list-item>
          <v-list-item
            v-if="customerCareLiveChatVisible"
            :disabled="!liveChatInfo.isEnabled"
            @click="showLiveChat"
          >
            <v-icon small>$LiveChat</v-icon>
            <span class="ml-2">{{ customerCareLiveChat }}</span>
          </v-list-item>
          <v-list-item
            v-if="customerCareOtherVisible"
            :disabled="!customerCareOtherUrl"
            @click="showCustomerCareOther"
          >
            <v-icon small>$Messages</v-icon>
            <span class="ml-2">{{ customerCareOther }}</span>
          </v-list-item>
        </v-list>
        <v-list
          v-if="hasPhoneSupport"
          color="popupBackground"
          class="pt-0 pb-3"
          style="font-size:12px;"
          dense
        >
          <v-list-item>{{ phoneSupportTitle }}</v-list-item>
          <v-list-item v-if="phoneSupport1">
            <v-icon small>$Phone</v-icon>
            <span class="ml-2">{{ phoneSupport1 }}</span>
          </v-list-item>
          <v-list-item v-if="phoneSupport2">
            <v-icon small>$Phone</v-icon>
            <span class="ml-2">{{ phoneSupport2 }}</span>
          </v-list-item>
          <v-list-item v-if="phoneSupport3">
            <v-icon small>$Phone</v-icon>
            <span class="ml-2">{{ phoneSupport3 }}</span>
          </v-list-item>
        </v-list>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions, mapGetters } from "vuex";
import displayNameValidator from "@/mixins/shared/displayNameValidator";

export default {
  mixins: [dialogToggle,displayNameValidator],
  data() {
    return {
      name: "contactUs"
    };
  },
  computed: {
    ...mapGetters("session", ["liveChatInfo"]),
    customerCareTitle() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Customer Care Title"
      );
    },
    customerCareTitleVisible() {
      return !this.$store.getters["lobbyComponents/componentIsHidden"](
        "Customer Care Title"
      );
    },
    customerCare247() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Customer Care - 24/7 Support"
      );
    },
    customerCare247Url() {
      return this.$store.getters["lobbyComponents/componentUrl"](
        "Customer Care - 24/7 Support"
      );
    },
    customerCare247Visible() {
      return !this.$store.getters["lobbyComponents/componentIsHidden"](
        "Customer Care - 24/7 Support"
      );
    },
    customerCareLiveChat() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Customer Care - Live Chat"
      );
    },
    customerCareLiveChatVisible() {
      return !this.$store.getters["lobbyComponents/componentIsHidden"](
        "Customer Care - Live Chat"
      );
    },
    customerCareOther() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Customer Care - Other"
      );
    },
    customerCareOtherUrl() {
      return this.$store.getters["lobbyComponents/componentUrl"](
        "Customer Care - Other"
      );
    },
    customerCareOtherVisible() {
      return !this.$store.getters["lobbyComponents/componentIsHidden"](
        "Customer Care - Other"
      );
    },
    phoneSupportTitle() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Phone Support Title"
      );
    },
    phoneSupport1() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Phone Support 1"
      );
    },
    phoneSupport2() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Phone Support 2"
      );
    },
    phoneSupport3() {
      return this.$store.getters["lobbyComponents/componentValue"](
        "Phone Support 3"
      );
    },
    hasPhoneSupport() {
      return this.phoneSupport1 || this.phoneSupport2 || this.phoneSupport3;
    },
    title() {
      return this.$validateDisplayName(this.$store.getters["dialogs/contactUs"].data.title, this.$str('ContactUs'));
    }
  },
  methods: {
    ...mapActions("dialogs", ["showExternalUrl", "showLiveChat"]),
    showCustomerCare247() {
      this.showExternalUrl({
        title: this.customerCare247,
        source: this.customerCare247Url,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: ""
      });
    },
    showCustomerCareOther() {
      this.showExternalUrl({
        title: this.customerCareOther,
        source: this.customerCareOtherUrl,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: ""
      });
    }
  }
};
</script>

<style scoped>
::v-deep .v-toolbar__content {
  height: 60px !important;
}
</style>
