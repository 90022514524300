<template>
  <div>
    <v-dialog
      v-if="largeLayout"
      v-show="manualShow"
      v-model="showDialog"
      :retain-focus="false"
      content-class="gamesGuruDialog"
    >
      <v-card v-if="showDialog" style="height: 100%; overflow: hidden;">
        <div class="gamesguru-welcome">
          <v-icon large class="mb-2">$vuetify.icons.GamesGuru</v-icon><br />
          <span class="gamesguru-welcome-text">
            {{ $str("GamesGuruWelcome") }}
          </span>
        </div>
        <v-icon
          id="dialogTitleBarCloseBtn"
          class="pa-0"
          style="position: absolute; right: 25px; top: 15px;"
          @click.stop="showDialog = false"
        >
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
        >
        <v-card-text style="height: 100%; padding: 0">
          <v-row no-gutters>
            <v-col>
              <v-card class="pa-2 gamesGuruColumns left"
                ><div class="recommendedTitle">
                  {{ $str("RecommendedGames") }}
                </div>
                <!-- eslint-disable vue/attribute-hyphenation -->
                <div class="gamesGuruList">
                  <GameGuruContainer
                    v-for="game in recommendedGames"
                    :key="game.machId"
                    :game="game"
                  />
                </div>
                <!-- eslint-enable vue/attribute-hyphenation -->
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 gamesGuruColumns right">
                <v-tabs v-model="tab" dark color="red">
                  <v-tab v-for="tabname in tabnames" :key="tabname">
                    {{ tabname }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="tabname in tabnames" :key="tabname">
                    <v-flex
                      v-if="tabname === 'Trending Games'"
                      transparent-body
                    >
                      <div class="gamesGuruList">
                        <GameGuruContainer
                          v-for="game in trendingGames"
                          :key="game.machId"
                          :game="game"
                        />
                      </div>
                    </v-flex>
                    <v-flex v-if="tabname == 'Now Playing'" transparent-body>
                      <div class="gamesGuruList">
                        <GameGuruContainer
                          v-for="game in nowPlayingGames"
                          :key="game.machId"
                          :game="game"
                        />
                      </div>
                    </v-flex>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
          <v-checkbox
            v-show="!adminOption"
            v-model="disableGamesGuruMessage"
            color="popupTxt popupTxt--text"
            class="text-right gamesguru-disablemessage"
          >
            <template v-slot:label>
              <label>
                {{ $str("TemporarilyDisableThisMessage") }}
              </label>
            </template>
          </v-checkbox>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="smallLayout"
      v-show="manualShow"
      v-model="showDialog"
      :retain-focus="false"
      content-class="gamesGuruDialog gamesGuruSmallDialog"
    >
      <v-card
        v-if="showDialog"
        style="height: 100%; overflow: hidden;"
        :color="smallLayout ? 'header' : ''"
      >
        <v-icon
          id="dialogTitleBarCloseBtn"
          class="pa-0"
          style="position: absolute; right: 25px; top: 15px;"
          @click.stop="showDialog = false"
        >
          {{ smallLayout ? " mdi-close-circle" : " mdi-close" }}</v-icon
        >
        <v-card-text style="height: 100%; padding: 0">
          <v-row no-gutters>
            <v-card class="pa-2 gamesGuruColumns left"
              ><div class="recommendedTitle">
                {{ $str("RecommendedGames") }}
              </div>
              <!-- eslint-disable vue/attribute-hyphenation -->
              <div class="gamesGuruList">
                <GameGuruContainer
                  v-for="game in recommendedGames"
                  :key="game.machId"
                  :game="game"
                />
              </div>
              <!-- eslint-enable vue/attribute-hyphenation -->
            </v-card>
          </v-row>
          <v-row no-gutters>
            <v-card class="pa-2 gamesGuruColumns right">
              <v-tabs v-model="tab" centered dark color="red">
                <v-tab v-for="tabname in tabnames" :key="tabname">
                  {{ tabname }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="tabname in tabnames" :key="tabname">
                  <v-flex v-if="tabname === 'Trending Games'" transparent-body>
                    <div class="gamesGuruList">
                      <GameGuruContainer
                        v-for="game in trendingGames"
                        :key="game.machId"
                        :game="game"
                      />
                    </div>
                  </v-flex>
                  <v-flex v-if="tabname == 'Now Playing'" transparent-body>
                    <div class="gamesGuruList">
                      <GameGuruContainer
                        v-for="game in nowPlayingGames"
                        :key="game.machId"
                        :game="game"
                      />
                    </div>
                  </v-flex>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapActions } from "vuex";
import GameGuruContainer from "@/components/GameGuruContainer";
import "hooper/dist/hooper.css";
export default {
  components: {
    GameGuruContainer
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "gamesGuru",
      tab: null,
      tabnames: ["Trending Games", "Now Playing"],
      manualShow: false,
      cookie: {
        name: "enableGameRecommendations",
        defaultSecondsAfterTempDisableCookieExpires: 3600
      },
      gamesGuruRecommendedGames: {},
      settings: {},
      disableGamesGuruMessage: false,
      hooperSettings: {
        vertical: true,
        itemsToShow: 1.8,
        trimWhiteSpace: true
      }
    };
  },
  computed: {
    gameId() {
      // The gameId is set by the dialog system before the dialog is opened
      return this.$store.getters["dialogs/gamesGuru"].data.gameId;
    },
    balanceChanged() {
      // The balanceChanged is set by the dialog system before the dialog is opened
      return this.$store.getters["dialogs/gamesGuru"].data.balanceChanged;
    },
    adminOption() {
      // The adminOption is set by the dialog system before the dialog is opened
      return this.$store.getters["dialogs/gamesGuru"].data.adminOption;
    },
    recommendedGames() {
      return this.gamesGuruRecommendedGames.RecommendedGames;
    },
    pendingGamesIds() {
      return this.gamesGuruRecommendedGames.PendingGamesIds;
    },
    nowPlayingGames() {
      return this.gamesGuruRecommendedGames.NowPlayingGames;
    },
    trendingGames() {
      return this.gamesGuruRecommendedGames.TrendingGames;
    }
  },
  methods: {
    ...mapActions("games", ["showGamesGuru"]),
    async onShown() {
      const isGamesGuruEnabled = this.isGamesGuruEnabled();
      this.disableGamesGuruMessage = !isGamesGuruEnabled;
      const isConnectionStateConnected = CDK.isConnectionStateConnected();
      if (
        isConnectionStateConnected &&
        (this.adminOption ||
          (isGamesGuruEnabled && this.gameId && this.balanceChanged))
      ) {
        this.settingsPromise = await CDK.getGamesGuruSettings();
        if (this.adminOption) {
          this.gamesGuruRecommendedGames = await CDK.getRecommendedGames(
            null,
            null,
            true
          );
        } else {
          const gameIdDecomposed = this.gameId.split("-");
          this.gamesGuruRecommendedGames = await CDK.getRecommendedGames(
            gameIdDecomposed[0],
            gameIdDecomposed[1]
          );
        }
        if (this.validateGamesGuruData() && !this.adminOption) {
          this.showDialog = false;
          return;
        }
        this.manualShow = true;
      } else {
        window.console.warn(
          isGamesGuruEnabled
            ? isConnectionStateConnected
              ? this.balanceChanged
                ? "Games Guru: Game uniqueGameId is null, closing Games Guru"
                : "Games Guru: Game didn't change the Balance"
              : "Games Guru: SignalR is disconnected,returning to lobby"
            : "Games Guru: Games Guru is temporary disabled"
        );
        this.showDialog = false;
        this.manualShow = false;
      }
    },
    onHidden() {
      this.setEnableGamesGuru(!this.disableGamesGuruMessage);
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    setEnableGamesGuru(enabled) {
      sessionStorage.setItem(this.cookie.name, enabled);
    },
    isGamesGuruEnabled() {
      const settingValue = sessionStorage.getItem(this.cookie.name);
      if (!settingValue) {
        this.setEnableGamesGuru(true);
        return true;
      }
      return sessionStorage.getItem(this.cookie.name) === "true";
    },
    validateGamesGuruData() {
      const hasPendingGames = this.pendingGamesIds?.length > 0;
      const isInstantPlayEnabled = this.settingsPromise.ClientTypes.some(
        client => client.clientID === CDK.ClientTypes.InstantPlay
      );
      const isEmpty =
        !this.trendingGames && !this.nowPlayingGames && !this.recommendedGames;
      return !isInstantPlayEnabled || hasPendingGames || isEmpty;
    }
  }
};
</script>

<style lang="scss">
.v-dialog.gamesGuruDialog.v-dialog--active {
  width: 55vw;
  height: 75vh;
  background-color: var(--v-popupBackground-base) !important;
  border-radius: 20px !important;
  opacity: 0.95;
  font-size: 14px !important;

  .recommendedTitle {
    background: var(--v-navbar-base) !important;
    display: inline-block;
    border-radius: 5px !important;
    padding: 2px 10px;
  }

  .v-card {
    background: none !important;
    box-shadow: none !important;
    padding: 3vh 2vw;
  }

  #dialogTitleBarCloseBtn {
    color: #666 !important;
    z-index: 10;
  }

  .gamesguru-glasses {
    height: 15px;
    margin-right: 5px;
  }

  .gamesguru-welcome {
    font-size: 1em;
    text-align: center;
    margin: 15px auto 15px auto;
  }

  .gamesguru-welcome-text {
    display: inline-block;
  }

  .gamesGuruColumns {
    height: 55vh;
    text-align: center;

    min-width: 320px;
    max-width: 350px;
    margin: 0 auto;
    .gamesGuruList {
      height: 47vh;
      overflow-y: auto;
      padding-top: 2vh;
      overflow-x: hidden;
    }
    .v-tab {
      text-transform: none;
    }
    .v-tabs-bar {
      height: 20px;
    }

    .v-tabs-items,
    .v-tabs-bar {
      background-color: transparent;
    }
  }
  .gamesguru-disablemessage {
    padding-right: 1em;
    margin-top: 3px;

    .v-input__control {
      justify-content: flex-end;
      .v-input__slot {
        justify-content: flex-end;
        label {
          flex: none;
          font-size: 13px;
          line-height: 10px;
        }
      }
    }
  }
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--v-popupBackground-base) !important;
    opacity: 0.5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--v-popupBackground-lighten1) !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--v-popupBackground-lighten2) !important;
  }
}

.v-dialog.gamesGuruSmallDialog.v-dialog--active {
  height: 100%;
}

@media only screen and (max-width: 1254px) {
  .v-dialog.gamesGuruDialog.v-dialog--active {
    padding-bottom: 2vh;
    .gamesguru-welcome {
      display: none;
    }

    .gamesGuruColumns {
      height: 30vh !important;
    }
    .gamesGuruList {
      height: 25vh !important;
    }
    .gamesGuruColumns {
      margin-top: 10px;
      .v-tabs,
      .gamesGuruList {
        max-width: 70vw;
        margin: auto;
      }
    }
  }

  .v-dialog.gamesGuruSmallDialog.v-dialog--active {
    .gamesGuruColumns {
      height: 40vh !important;
      min-width: 100%;
      max-width: 100%;
      .v-slide-group__prev {
        display: none !important;
      }
    }
    .gamesGuruList {
      height: 35vh !important;
      .games-guru-container {
        margin-bottom: 12%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .v-dialog.gamesGuruSmallDialog.v-dialog--active {
    width: 100%;

    .gamesGuruColumns .v-tabs {
      max-width: 90%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .v-dialog.gamesGuruSmallDialog.v-dialog--active
    .gamesGuruColumns
    .gamesGuruList {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 401px) and (max-width: 1254px) {
  .v-dialog.gamesGuruSmallDialog.v-dialog--active
    .gamesGuruColumns
    .gamesGuruList {
    max-width: 300px !important;
  }
}

@media only screen and (min-height: 401px) and (max-height: 700px) {
  .v-dialog.gamesGuruSmallDialog.v-dialog--active .gamesGuruColumns {
    height: 35vh !important;
    .gamesGuruList {
      height: 30vh !important;
    }
  }
}

@media only screen and (max-height: 400px) {
  .v-dialog.gamesGuruSmallDialog.v-dialog--active .gamesGuruColumns {
    height: 30vh !important;
    .gamesGuruList {
      height: 25vh !important;
    }
  }
}
</style>
