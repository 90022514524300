/**
 * Responsive Layout Mixin
 *
 * Helpers for determining which responsive layout should be displayed
 *
 * Responsive layout values that can be shared between components so they
 * can coordinate positioning
 */
import bowser from "bowser";

export default {
  computed: {
    largeLayout() {
      const mobileClient = this.$store.getters["session/mobileClient"];
      return this.$vuetify.breakpoint.smAndUp && !mobileClient;
    },
    smallLayout() {
      return !this.largeLayout;
    },
    tabletMode() {
      const browser = bowser.getParser(window.navigator.userAgent);
      return browser.getPlatformType(true) === "tablet";
    },
    /**
     * Height of the TopNavLarge component, in pixels.
     * Used by other components, such as GamesNavBar, to coordinate positioning.
     */
    topNavLargeHeight() {
      return 60;
    },
    /**
     * Responsive height of the top carousel element.
     */
    topCarouselHeight() {
      // For large desktop browser windows
      const largeDesktop = 600;

      // For smaller desktop browser windows, tablet-sized windows, etc
      const smallDesktop = 400;

      // Browser width 960px or more
      return this.$vuetify.breakpoint.mdAndUp ? largeDesktop : smallDesktop;
    },
    isLandscape() {
      const mql = window.matchMedia("(orientation: portrait)");
      if (mql.matches) {
        return false;
      }
      return true;
    }
  }
};
