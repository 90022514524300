<template>
  <div class="games-guru-container vue-games-guru-container">
    <img :src="game.imageURL" class="gamesguru-game-thumbnail" />
    <div class="gamesguru-game-parallelogram">
      <div class="gamesguru-game-text">
        <div
          :class="[
            playForMoney ? 'gamesguru-game-title-for-real' : '',
            'gamesguru-game-title'
          ]"
        >
          {{ game.name }}
        </div>
        <!--<div class="gamesguru-game-data">5x5 | 25lines | Free Spins</div>   {{=it.game.imageURL}}-->
        <div
          v-if="!playForMoney"
          class="gamesguru-game-switch align-self-center theme--dark buttonSecondary buttonSecondaryTxt--text"
          @click="switchToReal(game)"
        >
          {{ $str("GuruPlayForReal")
          }}<i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
      </div>
      <div
        class="gamesguru-game-play align-self-center theme--dark button buttonTxt--text"
        @click="play(game)"
      >
        {{ $str("GuruPlay")
        }}<i class="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { LobbyActions, LaunchLobbyAction } from "@/services/casino";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    game: { type: Object, required: true }
  },
  computed: {
    ...mapGetters("session", ["loggedIn", "playForMoney"])
  },
  methods: {
    ...mapActions("dialogs", ["hideAll"]),
    play(game) {
      const gameId = `${game.gameId}-${game.machId}-${game.hands}`;
      this.hideAll();
      LaunchLobbyAction(this.$store, LobbyActions.LaunchGame, { gameId });
    },
    switchToReal(game) {
      CDK.switchToReal().done(function() {
        this.play(game);
      });
    }
  }
};
</script>

<style lang="scss">
.games-guru-container.vue-games-guru-container:last-child {
  margin-bottom: 0vh;
}
.games-guru-container.vue-games-guru-container {
  position: relative;
  margin-bottom: 5vh;

  .gamesguru-game-parallelogram {
    border-radius: 5px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    transform: skew(-20deg);
    background: var(--v-popupBackground-base) !important;
    display: block;
    height: 7vh;
    margin: 0 auto;
    width: 15vw;
    min-width: 210px;

    & > * {
      -webkit-transform: skew(20deg);
      -moz-transform: skew(20deg);
      -o-transform: skew(20deg);
      transform: skew(20deg);
    }
  }

  .gamesguru-game-parallelogram .gamesguru-game-play {
    position: absolute !important;
    top: 12px;
    right: -10px;
  }

  .gamesguru-game-parallelogram .gamesguru-game-switch {
    position: absolute !important;
    top: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80px;
    font-size: 0.7em !important;
    padding: 0px !important;
    margin-left: 55%;
  }

  .gamesguru-game-parallelogram .gamesguru-game-play,
  .gamesguru-game-parallelogram .gamesguru-game-switch {
    border-radius: 5px;
    -webkit-box-shadow: 5.3px 5.3px 12.5px 0 rgb(0 0 0 / 35%);
    -moz-box-shadow: 5.3px 5.3px 12.5px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 5.3px 5.3px 12.5px 0 rgb(0 0 0 / 35%);
    display: inline-block;
    font-size: 0.8em;
    padding: 0.3em 0.5em;
    position: relative;
    cursor: pointer;
  }

  .gamesguru-game-thumbnail {
    width: 80px;
    height: auto;
    position: absolute;
    left: 0vw !important;
    top: 0vh;
    display: inline-block;
    z-index: 1;
    border-radius: 1vw !important;
  }
  .gamesguru-game-title {
    font-size: 0.8em;
    width: 100px;
    margin: 0 auto;
    word-wrap: normal;
    margin-left: 10vh;
    display: block;
  }

  .gamesguru-game-text {
    align-items: center;
    display: table-cell;
    height: 7vh;
  }
}

.gamesGuruSmallDialog {
  .gamesguru-game-parallelogram .gamesguru-game-play {
    right: 0px !important;
  }

  .gamesguru-game-title {
    margin-left: 70% !important;
  }

  .gamesguru-game-title-for-real {
    margin-top: 10% !important;
  }

  .gamesguru-game-switch {
    margin-left: 80% !important;
  }
}
</style>
