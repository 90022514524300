<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path
      d="M17.5,17.21c0-1.26,1-1.78,2.25-1.15l13.12,6.73c1.24.64,1.24,1.68,0,2.31L19.75,31.82c-1.24.64-2.25.12-2.25-1.15Z"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
    <circle
      cx="24"
      cy="24"
      r="21.57"
      fill="none"
      :stroke="iconColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.44"
    />
  </svg>
</template>

<script>
export default {
  name: "AllGames",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "All Games" }
  }
};
</script>
<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-miterlimit: 10;
}
</style>
