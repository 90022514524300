/**
 * Handles the connection to CDK Server and injected lobby core properties
 */

import { clientType } from "./clientType";
import { signalRUrl } from "./signalRUrl";

/**
 * CDK server embeds the lobbyCore data object @ window.LC
 * window.LC = {
 *   baseUrl: the CDN base path for assets,
 *   signalRUrl: signalR server address
 *   skinId
 * }
 */
const lobbyCoreData = window.LC || {};

const getSkinIdFromUrl = () =>
  new URLSearchParams(window.location.search).get("skinId");

export const baseUrl = lobbyCoreData.baseUrl || "";
export const skinId =
  lobbyCoreData.landingParameters?.skinId || getSkinIdFromUrl() || 1;
const lobbyPackageName = "LobbyCore";
const casinoID = 1;

const clientKeys = {
  [CDK.ClientTypes.Download]: "{4FFB2EEE-C103-4677-A006-53FCEDFCFAB3}",
  [CDK.ClientTypes.InstantPlay]: "{4FFB2EEE-C103-4677-A006-53FCEDFCFAB3}",
  [CDK.ClientTypes.Mobile]: "{9D279EE0-AF6D-4462-A079-7881DB0E23EF}"
};

export const connectToCasino = async () => {
  CDK.initialize(signalRUrl, casinoID, skinId, {
    lobby: true,
    verbose: true,
    tournaments: true
  });
  await CDK.connect(clientType, clientKeys[clientType], lobbyPackageName);
  CDK.tryRestoreLastSession();
};

export const casinoConnected = new Promise(function(resolve /*, reject*/) {
  if (CDK.isConnected()) {
    resolve();
  } else {
    CDK.on(CDK.Events.connected, () => {
      if (CDK.isConnected()) {
        resolve();
      }
    });
    CDK.on(CDK.Events.connectionDataSet, resolve);
    CDK.on(CDK.Events.connectionDataLost, function() {
      window.location.reload();
    });
  }
});

export const restoreSessionCompleted = new Promise(function(
  resolve /*, reject*/
) {
  if (CDK.isRestoreSessionCompleted()) {
    resolve();
  } else {
    CDK.on(CDK.Events.restoreSessionCompleted, resolve);
  }
});
