<template>
  <v-container class="ma-0 pa-0">
    <v-tabs
      v-model="currentTab"
      :grow="Object.keys(actions).length === maxTabs"
      hide-slider
      color="tournamentTxt"
    >
      <v-tab
        v-for="(action, actionType) in actions"
        :key="actionType"
        class="tournament"
        >{{ $str(action.nameKey) }}</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="currentTab" class="tournamentDetail">
      <v-tab-item v-for="(action, actionType) in actions" :key="actionType">
        <v-card style="border-radius: 0 0 1vw 1vw" flat color="tournament">
          <v-card-text class="tournament tournamentTxt--text">
            <v-row v-if="mobileClient" dense>
              <v-col
                v-for="(detail, detailName) in action.details"
                :key="detailName"
                class="d-flex align-center justify-center"
                cols="6"
              >
                <span class="mobile-cost-detail"
                  >{{ $str(detail.stringKey) }}
                </span>
                <span class="caption text-center cost-detail-chip mobile-chip">
                  {{
                    detailName === "Cost"
                      ? $tournamentCur(detail.value)
                      : detail.value
                  }}
                </span>
              </v-col>
            </v-row>
            <v-container v-else class="pa-0 ma-0">
              <v-row dense>
                <v-col
                  v-for="(detail, detailName) in action.details"
                  :key="detailName"
                  class="d-flex justify-center"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-row justify="center"
                        ><span class="cost-detail"
                          >{{ $str(detail.stringKey) }}
                        </span></v-row
                      >
                      <v-row justify="center"
                        ><span
                          class="caption text-center cost-detail-chip IP-chip"
                        >
                          {{
                            detailName === "Cost"
                              ? $tournamentCur(detail.value)
                              : detail.value
                          }}
                        </span></v-row
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tournamentDetail px-0 justify-space-between">
            <v-btn
              color="buttonSecondary buttonSecondaryTxt--text"
              depressed
              width="48%"
              light
              :disabled="!action.enabled"
              @click.stop="showActionDialog(actionType)"
              >{{ $str(action.nameKey) }}</v-btn
            ><v-btn
              depressed
              width="48%"
              light
              color="button buttonTxt--text"
              :disabled="!action.playEnabled"
              @click.stop="play"
              >{{ $str("Play") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    tournament: { type: Object, required: true }
  },
  data() {
    return {
      currentTab: null,
      maxTabs: 3
    };
  },
  computed: {
    ...mapGetters("session", ["mobileClient"]),
    actions() {
      return this.tournament.actions;
    }
  },
  methods: {
    ...mapActions("dialogs", ["showTournamentReBuyAddOn"]),
    ...mapActions("tournaments", ["launchTournamentGame"]),

    showActionDialog(actionType) {
      return this.showTournamentReBuyAddOn({
        tournament: this.tournament,
        actionType
      });
    },
    play() {
      this.launchTournamentGame({ tournament: this.tournament });
    }
  }
};
</script>
<style scoped>
.v-tab {
  filter: brightness(0.8);
}

.v-tab {
  border-radius: 0.5rem 0.5rem 0 0;
}

.v-tab.v-tab--active {
  border-radius: 0.5rem 0.5rem 0 0;
  z-index: 1;
  filter: none;
}

.v-tabs-items .col > * {
  flex-grow: 0.25;
}

.v-list-item__content {
  overflow: visible;
}

.cost-detail {
  width: 5rem;
  text-align: center;
  font-size: 0.85rem;
}

.mobile-cost-detail {
  width: 50%;
  text-align: left;
  font-size: 0.85rem;
}

.cost-detail-chip {
  background: var(--v-tournamentDetail-base);
  color: var(--v-tournamentDetailTxt-base);
  border-radius: 0.25rem;
  padding: 0.25em 0;
}

.IP-chip {
  width: 5rem;
}

.mobile-chip {
  width: 7vh;
}

::v-deep .theme--dark.v-tabs > .v-tabs-bar {
  background-color: transparent;
}

::v-deep
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none;
}

::v-deep .v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-radius: 0;
}
</style>
