<template>
  <v-card
    :id="scrollContainerId"
    style="
      overflow-x: auto;
      overflow: hidden;
      border-top-style: solid;
      border-top-color: grey;
      border-radius: 0;
      border-top-width: thin;
      width: inherit;
    "
    :ripple="false"
    flat
  >
    <div
      class="d-flex flex-no-wrap"
      :class="{ imageSize: isEnableNewImageSize }"
    >
      <v-avatar class="align-self-center ma-0" tile size="100">
        <v-img
          v-if="coupon.ImageUrl"
          :src="coupon.ImageUrl"
          :lazy-src="lazyLoadingImage"
          eager
          contain
        >
        </v-img>
      </v-avatar>

      <v-container class="ma-0 pa-0" style="min-width: 55%">
        <!-- eslint-disable vue/no-v-html -->
        <v-row>
          <v-col>
            <v-card-title
              :id="scrollTitleId"
              style="word-break: break-word"
              v-html="couponTitle"
            ></v-card-title>
            <!-- Link to bug report page w/ suggested fix: https://github.com/vuetifyjs/vuetify/issues/9130 -->
            <!-- eslint-disable vue/no-v-html -->
            <v-card-subtitle
              style="word-break: break-word"
              v-html="couponDescription"
            ></v-card-subtitle>
            <v-card-text
              v-if="coupon.TermsAndCoditionsUrl"
              class="text-left pb-0"
              ><a
                class="hyperlink--text"
                @click.stop.prevent="showTermsandConditions"
              >
                {{ $str("TermsNConditionsLink") }}
              </a></v-card-text
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col class="text-right mr-5">
            <v-btn
              :id="scrollTargetId"
              style="align-self: left"
              color="button buttonTxt--text"
              :disabled="coupon.ShowAsDisabled || redeemCouponIsDisable"
              @click="redeem"
            >
              <img
                v-if="isTournamentCoupon"
                class="image-tournaments"
                width="42"
                height="42"
                src="@/assets/icons/trophy.png"
              />
              <label>{{ $str("Redeem") }}</label>
            </v-btn>
            <br />
            <v-btn
              v-if="coupon.MillisecondsToDisappear > 0"
              text
              disabled
              style="font-size: 12px"
            >
              <v-icon small left> $vuetify.icons.countDown </v-icon>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style="text-transform: lowercase !important"
                v-html="coupon.scrollTimer ? coupon.scrollTimer : '--:--:--'"
              ></span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="coupon.CouponSequenceRestrictionMessage && coupon.CouponSequenceRestrictionMessage !== ''"
          class="ma-5 mt-1 couponSequenceMessage"
        >
          <v-col class="align-center d-flex justify-center pa-2 v-col">
            <span
              class="text-left pb-0"
              style="font-size: 11px;"
            >
              {{ coupon.CouponSequenceRestrictionMessage }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    coupon: { type: Object, required: true },
  },
  data() {
    return {
      name: "coupons",
      couponCode: "",
      errorMessage: "",
      isScrolling: false,
      isEnableNewImageSize: false,
      redeemCouponIsDisable: false,
    };
  },
  computed: {
    ...mapGetters("customizations", ["lazyLoadingImage"]),
    couponTitle() {
      return "<span>" + this.$decode(this.coupon.Title) + "</span>";
    },
    couponDescription() {
      return "<span>" + this.$decode(this.coupon.Description) + "</span>";
    },
    scrollTargetId() {
      return "redeemAvailable" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollContainerId() {
      return "coupon" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollTitleId() {
      return "title" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    scrollTimerId() {
      return "timer" + this.coupon.CouponCode.replace(/\s+/g, "");
    },
    isTournamentCoupon() {
      return this.coupon.IsTournamentCoupon;
    },
  },
  async beforeMount() {
    this.isEnableNewImageSize = await this.getIsEnableNewImageSize();
  },
  methods: {
    ...mapActions("dialogs", ["showExternalUrl"]),
    redeem() {
      this.redeemCouponIsDisable = true;
      this.$store.dispatch("coupons/redeem", {
        couponCode: this.coupon.CouponCode,
      });
      this.$store.dispatch("dialogs/updateDialogData", {
        name: "coupons",
        data: { couponCode: this.coupon.CouponCode },
      });
    },
    showTermsandConditions() {
      this.showExternalUrl({
        title: this.$str("TermsNConditionsLink"),
        source: this.coupon.TermsAndCoditionsUrl,
        HTML: null,
        onClose: "",
        showTitleBar: true,
        height: "",
        width: "",
      });
    },
    async getIsEnableNewImageSize() {
      return (await CDK.GetIfNewImageSizingIsEnabledInCouponSet()) || false;
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ),
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}

@media all and (orientation: portrait) {
  .imageSize {
    margin: 1em auto !important;
    flex-wrap: wrap;
    > div:first-child {
      height: auto !important;
      min-width: 90% !important;
      margin: 0.6em auto !important;
    }
  }
}

@media all and (orientation: landscape) {
  .imageSize {
    margin: 1em auto !important;
    > div:first-child {
      height: auto !important;
      min-width: 35% !important;
    }
  }
}
.image-tournaments {
  position: absolute;
  right: 52px;
}

.couponSequenceMessage {
  background: rgba(var(--v-theme-on-surface), 0.1);
  border-radius: 4px;
}
</style>
