/**
 * Helpers to grant an object the ability to host events via .on, .off, and .raise methods.
 */
export const eventify = function(obj) {
  const result = Object.create(null);
  result.isBoolean = function(val) {
    return typeof val === "boolean" || val instanceof Boolean;
  };
  result.isString = function(val) {
    return typeof val === "string" || val instanceof String;
  };
  result.isFunction = function(val) {
    return typeof val === "function" || val instanceof Function;
  };
  result.isNumber = function(val) {
    return typeof val === "number" || val instanceof Number;
  };
  result.isPlainObject = function(val) {
    return (
      typeof val === "object" &&
      !result.isBoolean(val) &&
      !result.isFunction(val) &&
      !result.isNumber(val) &&
      !result.isString(val) &&
      !(val instanceof Date)
    );
  };

  if (obj.on || obj.off || obj.raise) {
    if (
      result.isFunction(obj.on) &&
      result.isFunction(obj.off) &&
      result.isFunction(obj.raise)
    ) {
      return obj;
    } else {
      throw new Error("Could not eventify object");
    }
  }

  let listeners = [];
  const deferrals = [];

  /**
   * Register an event handler for all events of this object.
   */
  obj.on = function() {
    const firstArgument = arguments[0];
    if (result.isPlainObject(firstArgument)) {
      Object.keys(firstArgument).forEach(function(key) {
        obj.on(key, firstArgument[key]);
      });
      return obj;
    } else {
      let event, handler;
      for (let a = 0, aa = arguments.length; a < aa; ++a) {
        const argument = arguments[a];
        if (result.isString(argument) && !event) {
          event = argument;
        } else if (result.isFunction(argument) && !handler) {
          handler = argument;
        }
        if (event && handler) {
          break;
        }
      }
      if (!result.isFunction(handler)) {
        throw new Error("A handler function is required");
      }
      listeners.push({
        event: event,
        handler: handler
      });
      if (event) {
        deferrals
          .filter(function(deferral) {
            return deferral.event === event;
          })
          .forEach(function(deferral) {
            handler.apply(obj, deferral.eventData);
          });
      } else {
        deferrals.forEach(function(deferral) {
          handler.apply(obj, [event].concat(deferral.eventData));
        });
      }
    }
    return obj;
  };

  /**
   * Unregister all event handlers for a specified event of this object.
   */
  obj.off = function() {
    const firstArgument = arguments[0];
    if (result.isPlainObject(firstArgument)) {
      Object.keys(firstArgument).forEach(function(key) {
        obj.off(key, firstArgument[key]);
      });
    } else if (Array.isArray(firstArgument)) {
      if (
        firstArgument.every(function(element) {
          return result.isString(element);
        }) ||
        firstArgument.every(function(element) {
          return result.isFunction(element);
        })
      ) {
        firstArgument.forEach(function(element) {
          obj.off(element);
        });
      } else if (firstArgument.length) {
        throw new Error(
          "When this function is called with the sole argument of an array, all its elements must be strings or all its elements must be functions"
        );
      }
    } else {
      let event, handler;
      for (let a = 0, aa = arguments.length; a < aa; ++a) {
        const argument = arguments[a];
        if (result.isString(argument) && !event) {
          event = argument;
        } else if (result.isFunction(argument) && !handler) {
          handler = argument;
        }
        if (event && handler) {
          break;
        }
      }
      if (event && handler) {
        listeners = listeners.filter(function(listener) {
          return listener.event !== event || listener.handler !== handler;
        });
      } else if (handler) {
        listeners = listeners.filter(function(listener) {
          return listener.handler !== handler;
        });
      } else if (event) {
        listeners = listeners.filter(function(listener) {
          return listener.event !== event;
        });
      } else {
        listeners = [];
      }
    }
    return obj;
  };

  /**
   * Raise an event on this object.
   */
  obj.raise = function() {
    const event = arguments[0];
    if (!event || !result.isString(event)) {
      throw new Error("Event is not correctly specified");
    }
    const eventData = Array.prototype.slice.call(arguments, 1);
    listeners
      .filter(function(listener) {
        return listener.event === event;
      })
      .forEach(function(listener) {
        listener.handler.apply(obj, eventData);
      });
    const fullEventData = [event].concat(eventData);
    listeners
      .filter(function(listener) {
        return !listener.event;
      })
      .forEach(function(listener) {
        listener.handler.apply(obj, fullEventData);
      });
    return obj;
  };

  /**
   * Raise an event on this object now and invokes each subsequently added, matching handler with the same event data when they are added.
   */
  obj.raiseWithDeferral = function() {
    const event = arguments[0];
    if (!event || !result.isString(event)) {
      throw new Error("Event is not correctly specified");
    }
    obj.raise.apply(obj, Array.prototype.slice.call(arguments));
    deferrals.push({
      event: arguments[0],
      eventData: Array.prototype.slice.call(arguments, 1)
    });
    return obj;
  };

  return obj;
};

export const mapArray = function(array) {
  const result = Object.create(null);
  array.forEach(function(element) {
    const str = String(element);
    result[
      str.substr(0, 1).replace(/[^a-z\d_]/i, "") +
        str.substr(1).replace(/^[^a-z_]+/i, "")
    ] = str;
  });
  return result;
};
