/**
 * Processes URL parameters and dispatch associated actions
 */

import store from "@/store";
import { casinoConnected, objectKeysToLowerCase } from "@/services/casino";

const handleLoginEvents = async ({
  login,
  token,
  forreal = true,
  terminalid = 0,
  show,
  ltrackingid,
  laffid,
  lgaid,
  lbannerid,
  lsubgid,
  lsubaid,
  securitycode,
  username
}) => {
  await casinoConnected;
  addTrackingIdAffiliate({
    ltrackingid,
    laffid,
    lgaid,
    lbannerid,
    lsubgid,
    lsubaid
  });
  if (login?.length > 0) {
    // Check for login with token
    if (token?.length > 0) {
      // Optional "forReal" param
      if (typeof forreal === "string") {
        forreal =
          forreal === "0" || forreal.toLowerCase() === "false" ? false : true;
      }

      // handle the case of a string param passed in for term id
      terminalid = parseInt(terminalid);

      // Pause until connected to the casino, then login
      //  with the token
      await casinoConnected;
      await store.dispatch("session/signInWithToken", {
        login,
        token,
        forMoney: forreal,
        terminalid
      });
    } else {
      await casinoConnected;
      store.dispatch("session/redirectAnonymous");
      // No token passed, so just display the login window
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, { login });
    }
  } else if (show?.length > 0) {
    await casinoConnected;
    switch (show) {
      case "login":
        window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
          login,
          requestedFromParam: true
        });
        break;
      case "signup":
        window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
        break;
      case "forgotpassword":
        window.LobbyCore.raise(window.LobbyCore.Events.showForgotPassword);
        break;
      case "forgotusername":
        window.LobbyCore.raise(window.LobbyCore.Events.showForgotUsername);
        break;
      case "resetpassword":
        window.LobbyCore.raise(window.LobbyCore.Events.showChangePassword, {
          securityCode: securitycode,
          login: username
        });
        break;
    }
  } else {
    await casinoConnected;
    CDK.on(CDK.Events.restoreSessionCompleted, function(sessionWasRestored) {
      if (!sessionWasRestored) {
        store.dispatch("session/redirectAnonymous");
      }
    });
  }
};

let _game;
let _coupon;
let _urlCommand;

const loggedInHandler = () => {
  CDK.off(CDK.Events.loggedIn, loggedInHandler);
  if (_game?.length > 0) {
    store.dispatch("games/launchGame", { gameId: _game });
  } else if (_coupon?.length > 0) {
    window.LobbyCore.raise(window.LobbyCore.Events.showRedeemCouponPage, {
      couponCode: _coupon
    });
    const urlCouponParameter = ["coupon"];
    clearParams(urlCouponParameter);
  }
  _game = null;
  _coupon = null;
  _urlCommand = null;
};

const handlePostLoginEvents = ({ game, coupon }) => {
  if (!_urlCommand) _urlCommand = CDK.checkForURLCommand();
  if (_urlCommand !== CDK.LobbyURLCommands.couponRedeem) {
    _game = game;
    _coupon = coupon;
    if (game?.length > 0 || coupon?.length > 0) {
      if (CDK.isLoggedIn()) {
        loggedInHandler();
      } else {
        CDK.on(CDK.Events.loggedIn, loggedInHandler);
      }
    }
  }
  return;
};

export const clearRequestParams = function() {
  const urlParameters = [];
  urlParameters.push("show");
  urlParameters.push("ltrackingid");
  urlParameters.push("laffid");
  urlParameters.push("lgaid");
  urlParameters.push("lbannerid");
  urlParameters.push("lsubgid");
  urlParameters.push("lsubaid");
  urlParameters.push("game");

  clearParams(urlParameters);
};

export const clearParams = function(urlParameters) {
  const params = new URLSearchParams(window.location.search);
  const paramsToDelete = new URLSearchParams(window.location.search);
  for (const value of paramsToDelete) {
    if (value !== null) {
      const paramsLowerCase = value[0]?.toLowerCase();
      if (urlParameters.find(filter => filter === paramsLowerCase)) {
        params.delete(value[0]);
      }
    }
  }
  const url = `${window.location.pathname}?${params}`;
  window.history.replaceState({}, null, url);
};

const addTrackingIdAffiliate = ({
  ltrackingid,
  laffid,
  lgaid,
  lbannerid,
  lsubgid,
  lsubaid
}) => {
  const ipAddress = CDK.getClientIP();

  if (typeof lgaid !== "undefined") {
    if (typeof lbannerid !== "undefined") {
      if (typeof lsubgid !== "undefined") {
        addAffiliateGlobalAidCheck(
          lgaid,
          lbannerid,
          lsubgid,
          ipAddress,
          ltrackingid
        );
      } else {
        addAffiliateGlobalAidCheck(
          lgaid,
          lbannerid,
          null,
          ipAddress,
          ltrackingid
        );
      }
    }
  } else {
    if (typeof lsubaid !== "undefined") {
      addAffiliateSubCheck(laffid, lsubaid);
    }
    if (
      JSON.parse(localStorage.getItem("lobbyTracking")) === null &&
      typeof ltrackingid !== "undefined"
    ) {
      generateDownloadId(ipAddress, laffid, ltrackingid);
    } else if (
      JSON.parse(localStorage.getItem("lobbyTracking")) !== null &&
      typeof ltrackingid !== "undefined"
    ) {
      if (
        JSON.parse(localStorage.getItem("lobbyTracking"))["ltrackingid"] !==
        ltrackingid
      ) {
        generateDownloadId(ipAddress, laffid, ltrackingid);
      }
    }
    if (typeof laffid !== "undefined") {
      addAffiliateClickThrough(laffid, ipAddress);
    }
  }
};

const generateDownloadId = (ipAddress, laffid, ltrackingid) => {
  CDK.generateDownloadId("", "", ipAddress, "", 0, laffid, ltrackingid)
    .done(response => {
      addToLocalStorageObject("lobbyTracking", "ldownloadid", response.Value);
      addToLocalStorageObject("lobbyTracking", "laffid", laffid);
      addToLocalStorageObject("lobbyTracking", "ltrackingid", ltrackingid);
    })
    .fail(error =>
      window.console.error(
        `Error creating Aid Download on LDK. ${error.Message}`
      )
    );
};

const addAffiliateGlobalAidCheck = (
  gaId,
  bannerId,
  subGid,
  ipAddress,
  trackingId
) => {
  CDK.affiliateGlobalAidCheck(gaId, bannerId, subGid)
    .done(response => {
      addAffiliateClickThrough(response.Value, ipAddress);
      generateDownloadId(ipAddress, response.Value, trackingId);
    })
    .fail(error =>
      window.console.error(
        `Error creating Affiliate GlobalAid Check. ${error.Message}`
      )
    );
};

const addAffiliateSubCheck = (affId, subAid) => {
  CDK.affiliateSubCheck(affId, subAid)
    .done()
    .fail(error =>
      window.console.error(`Error creating Affiliate Sub. ${error.Message}`)
    );
};

const addAffiliateClickThrough = (affId, subAid) => {
  CDK.affiliateClickThrough(affId, subAid, false)
    .done()
    .fail(error =>
      window.console.error(
        `Error creating Affiliate Click Through. ${error.Message}`
      )
    );
};

const addToLocalStorageObject = (name, key, value) => {
  // Get the existing data
  let existing = localStorage.getItem(name);

  // If no existing data, create an array
  // Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {};

  // Add new data to localStorage Array
  existing[key] = value;

  // Save back to localStorage
  localStorage.setItem(name, JSON.stringify(existing));
};

export const handleRequestParams = async params => {
  if (params) {
    store.dispatch("session/saveRequestParams", { params });

    params = objectKeysToLowerCase(params);

    // Check for login related events (token, show signin)
    await handleLoginEvents(params);

    // Handle post login events triggered by the params
    handlePostLoginEvents(params);
  }
};
