<template>
  <v-card flat color="tournamentDetail" class="pa-2">
    <v-card-title
      class="tournamentSuccess--text text-uppercase pt-0 subtitle-1 font-weight-bold"
      style="flex-wrap: nowrap; word-break: break-word;"
      >{{ title }}<v-spacer></v-spacer
      ><v-icon color="tournamentSuccess" x-large class="ml-n2" @click="goBack"
        >mdi-arrow-left-bold-box-outline</v-icon
      ></v-card-title
    >
    <v-card-subtitle
      class="tournamentDetailTxt--text text-left pt-2 pb-0 font-weight-bold"
      >{{ tournament.TournamentName }}</v-card-subtitle
    >
    <v-card-text class="tournamentDetailTxt--text pb-0 caption">
      <v-container class="pb-2">
        <v-row>
          <v-col cols="6" class="pl-0"
            ><v-img
              class="rounded"
              :src="gameIcon(tournament.game)"
              :aspect-ratio="isSquareIcon ? 4 / 3 : 3 / 4"
              contain
            ></v-img
          ></v-col>
          <v-col class="pa-0">
            <p class="text-center mb-0">
              <v-icon color="tournamentError" x-large>mdi-alert</v-icon>
            </p>
            <p class="body-2 text-uppercase text-center mb-2">
              {{ $str("InsufficientFunds") }}
            </p>
            <v-divider class="mb-2" color="tournamentInfo "></v-divider>
            <p class="font-weight-bold text-center">
              {{ $str("Balance") }}
              <span class="tournamentError--text">{{
                $playerBalanceCur(withdrawable, true)
              }}</span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <h1 class="tournamentDetailTxt--text text-center">
      {{ $str(action.nameKey) }}
    </h1>
    <v-divider class="mx-4" color="tournamentInfo "></v-divider>
    <v-container class="caption px-6 tournamentDetailTxt--text">
      <v-row dense>
        <v-col class="d-flex justify-space-around" cols="6">
          {{ $str("DetailViewAvailable") + ":" }}
          <v-chip color="tournamentInfo tournamentInfoTxt--text" small label>
            {{ action.details.Available.value }}</v-chip
          >
        </v-col>
        <v-col class="d-flex justify-space-around" cols="6">
          {{ $str("DetailViewCost") + ":" }}
          <v-chip color="tournamentInfo tournamentInfoTxt--text" small label>
            {{ $tournamentCur(action.details.Cost.value) }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="justify-center mx-4">
      <v-btn
        width="100%"
        color="button buttonTxt--text"
        depressed
        @click="openDeposit"
        >{{ $str("DepositPage") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tournament: { type: Object, required: true },
    action: { type: Object, required: true },
    title: { type: String, required: true }
  },
  computed: {
    ...mapGetters("balances", ["withdrawable"]),
    ...mapGetters("games", ["gameIcon"]),
    ...mapGetters("customizations", ["lobbyIconType"]),
    isSquareIcon() {
      return this.lobbyIconType === CDK.LobbyIconTypes.square;
    }
  },
  methods: {
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    goBack() {
      this.$emit("goBack");
    }
  }
};
</script>
