<template>
  <v-container class="pa-0">
    <SandwichMenu ref="sandwichMenu" />
    <v-app-bar
      id="TopNavLarge"
      v-scroll="onScroll"
      :height="topNavLargeHeight"
      :style="headerStyle"
      flat
      app
      class="header"
    >
      <!-- Logo -->
      <v-toolbar-title class="align-self-center">
        <v-img
          :src="this.$vuetify.theme.dark ? casinoLogo : casinoLogoLight"
          max-height="56"
          max-width="160"
          contain
          style="cursor:pointer;"
          @click="goHome"
        />
      </v-toolbar-title>

      <v-row pt="1" no-gutters>
        <v-spacer></v-spacer>
        <!-- Games Family Menu -->
        <!-- <v-col class="auto mr-auto">
          <v-menu
            v-if="showGamesMenu"
            v-model="isOpened"
            open-on-hover
            content-class="rounded-0 menuContainer"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="gameMenuButton ml-1"
                v-bind="attrs"
                :class="isOpened ? 'activeGameMenuItem' : ''"
                v-on="on"
              >
                <v-icon class="gameMenu-icon">$vuetify.icons.gameMenu</v-icon>
                <span> {{ $str("Games") }}</span>
                <v-icon>{{
                  isOpened ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </template>
            <v-list
              min-width="fit-content"
              class="pt-0 pb-0 mt-0 "
              style="border-radius: 0 !important;"
              color="navbar"
            >
              <v-list-item
                v-for="(item, index) in populatedGameFamilyMenus"
                :key="index"
                class="pa-0"
              >
                <v-list-item-title>
                  <v-btn text class="gameMenuItem" @click="goToMenu(item.id)">
                    <v-icon
                      v-if="gameFamilyIcons[item.name]"
                      class="gameMenuItem-icon"
                      >{{ gameFamilyIcons[item.name] }}</v-icon
                    >
            <span
                      v-else
                      class="gameMenuItem-icon"
                      v-html="item.Image"
                    ></span>
                    <span class="cb-item ml-2">{{ item.displayName }}</span>
                  </v-btn></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>  -->
        <v-col cols="auto">
          <v-row no-gutters class="button-container">
            <v-col v-show="loggedIn" class="mx-2">
              <!-- Wallet -->
              <BalanceButton x-small />
            </v-col>

            <v-col v-show="signupEnabled && !loggedIn" class="mx-2">
              <!-- Buttons on the right -->
              <v-btn
                v-show="restoreSessionCompleted"
                id="mainViewSignUpBtn"
                class="align-self-center pa-0 responsiveButton "
                color="button"
                outlined
                @click="openSingUp"
                >{{ $str("SignUpPage") }}</v-btn
              >
            </v-col>

            <v-col v-show="!loggedIn" class="mx-2">
              <v-btn
                v-show="restoreSessionCompleted"
                id="mainViewLoginBtn"
                class="align-self-center responsiveButton"
                color="button buttonTxt--text"
                style="width:fit-content !important;"
                @click="openSignIn"
                >{{ $str("LoginPage") }}</v-btn
              >
            </v-col>

            <v-col
              v-show="loggedIn && playForMoney && isCashierEnabled"
              class="mx-2"
            >
              <v-btn
                id="mainViewCashierBtn"
                class="align-self-center responsiveButton"
                color="button buttonTxt--text"
                @click="openDeposit"
                >{{ cashierTitle }}</v-btn
              >
            </v-col>
            <v-col v-if="loggedIn && !playForMoney" class="mx-2 ">
              <v-btn
                id="mainViewPlayForRealBtn"
                class="align-self-center responsiveButton"
                color="buttonSecondary buttonSecondaryTxt--text"
                style="width:fit-content !important;max-width: fit-content;"
                @click="switchToReal"
                >{{ $strResourceService("PlayForReal") }}</v-btn
              >
            </v-col>
            <v-col v-if="loggedIn" class="mx-2 d-flex align-center">
              <v-badge
                :content="unreadMessagesCount"
                :value="unreadMessagesCount"
                color="red"
                overlap
                right
                style="display:inline"
              >
                <v-btn
                  id="mainViewMessagesBtn"
                  icon
                  @click="showMessageCenterList"
                >
                  <v-icon id="mainViewMessagesBtn" small>$Messages </v-icon>
                </v-btn>
              </v-badge>
            </v-col>
            <v-col
              v-if="liveChatInfo.isEnabled"
              class="mx-2 d-flex align-center"
            >
              <v-btn
                id="mainViewLiveChatBtn"
                icon
                x-small
                @click="showLiveChat({location: 'lobby'})"
              >
                <v-icon>$LiveChat</v-icon>
              </v-btn>
            </v-col>
            <v-col class="mx-2 d-flex align-center">
              <v-btn
                id="mainViewSearchBtn"
                x-small
                icon
                @click.stop="showSearch"
              >
                <v-icon>$Search</v-icon>
              </v-btn>
            </v-col>
            <!-- SandwichMenu -->
            <v-col class="mx-2 d-flex align-center">
              <v-icon id="mainViewMenuBtn" @click.stop="$refs.sandwichMenu.show"
                >mdi-menu
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-container>
</template>

<script>
import SandwichMenu from "@/components/SandwichMenu";
import { mapGetters, mapActions } from "vuex";
import BalanceButton from "@/components/BalanceButton";

export default {
  components: { SandwichMenu, BalanceButton },
  props: {
    showGamesMenu: { type: Boolean, required: false, default: false },
    closeGame: { type: Function, required: false, default: null }
  },
  data() {
    return {
      navFixed: true,
      dialogs: ["search", "chat"],
      isOpened: false
    };
  },
  computed: {
    ...mapGetters("session", [
      "connected",
      "loggedIn",
      "playForMoney",
      "liveChatInfo",
      "restoreSessionCompleted"
    ]),
    ...mapGetters("accountFields", ["signupEnabled"]),
    ...mapGetters("messageCenter", ["unreadMessagesCount"]),
    ...mapGetters("customizations", [
      "casinoLogo",
      "casinoLogoLight",
      "specialThemes"
    ]),
    ...mapGetters("balances", ["balance"]),
    ...mapGetters("cashier", ["isCashierEnabled"]),
    ...mapGetters("games", ["populatedGameFamilyMenus", "gameFamilyIcons"]),
    ...mapGetters("dialogs", ["isAnyDialogOpen"]),
    cashierTitle() {
      return this.$store.getters["lobbyComponents/componentValue"]("Cashier")
        ? this.$store.getters["lobbyComponents/componentValue"]("Cashier")
        : this.$str("Cashier");
    },
    marketinkPanelData() {
      const panelData = this.$store.getters["marketingPanels/panels"]
        ?.MarketingPanels;
      return panelData && panelData.length > 0;
    },
    headerStyle() {
      const background =
        this.specialThemes &&
        this.specialThemes[this.$mode] &&
        this.specialThemes[this.$mode].specialThemeHeaderURL
          ? {
              backgroundImage: `url(${
                this.specialThemes[this.$mode].specialThemeHeaderURL
              })`,
              backgroundSize: "cover"
            }
          : {};
      return background;
    }
  },

  methods: {
    ...mapActions("dialogs", [
      "showBalanceDetail",
      "showMessageCenterList",
      "showLiveChat",
      "showSearch"
    ]),
    ...mapActions("session", ["switchToReal"]),
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    openSignIn() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage);
    },
    openSingUp() {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage);
    },
    onScroll() {
      if (document.body.getBoundingClientRect().y === 0) {
        this.navFixed = true;
        this.$store.dispatch("games/refreshFavoriteGames");
      } else {
        this.navFixed = false;
      }
    },
    goToMenu(menuId) {
      this.goTo(menuId);
    },
    goTo(menuId, subMenuId = null) {
      if (this.closeGame) {
        this.closeGame();
      }
      if (
        this.$route.name === "games" &&
        this.$route.params.menuId === menuId &&
        this.$route.params.subMenuId === subMenuId
      ) {
        return;
      }

      this.$router.push({
        name: "games",
        params: { menuId, subMenuId }
      });
    }
  }
};
</script>

<style lang="scss">
.topnav-icon {
  width: 16px !important;
  height: 16px !important;
}

@media screen and (min-width: 1150px) {
  .responsiveButton {
    max-height: 40px;
    height: 2.7rem !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .gameMenu-icon,
  .gameMenuItem-icon {
    font-size: 2.3rem;
    width: 2.3rem;
    height: 2.3rem;
  }
  .button-container {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .gameMenuButton {
    max-width: fit-content;
    padding: 0 8px;
    span {
      font-size: 1.1rem !important;
    }
  }
}
@media screen and (max-width: 1149px) and (min-width: 801px) {
  #mainViewLoginBtn,
  #mainViewSignUpBtn {
    min-width: 90px !important;
  }
  .responsiveButton {
    max-height: 40px;
    height: 2.1rem !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .gameMenuItem-icon,
  .gameMenu-icon,
  .responsive-icon {
    font-size: 2rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }
  .button-container {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .button-container {
    .mx-1 {
      margin-left: -1px !important;
    }
  }
  .gameMenuButton {
    max-width: fit-content !important;
    padding: 0 6px !important;

    span {
      font-size: 1rem !important;
    }
  }
}

@media screen and (max-width: 800px) {
  #mainViewLoginBtn,
  #mainViewSignUpBtn {
    min-width: 70px !important;
  }
  .responsiveButton {
    max-height: 40px;
    height: 1.6rem !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .gameMenuItem-icon,
  .gameMenu-icon,
  .responsive-icon {
    font-size: 1.5rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .button-container {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .button-container {
    .mx-1 {
      margin-left: -1pz !important;
    }
  }
  .gameMenuButton {
    max-width: fit-content !important;
    padding: 0 2px !important;

    span {
      font-size: 0.8rem !important;
    }
  }
  .gameMenuItem {
    span {
      font-size: 0.8rem !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.gameMenuButton {
  color: var(--v-buttonSecondaryTxt-base) !important;
  background-color: var(--v-buttonSecondary-base) !important;
  height: 70px !important;
  box-shadow: none;
  border-radius: 1.5vh !important;
}

.gameMenuItem {
  height: 100% !important;
  width: 100% !important;
  justify-content: left !important;
  position: absolute !important;
  top: 0 !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover,
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):before,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):before {
  color: var(--v-buttonSecondaryTxt-base) !important;
  background-color: var(--v-buttonSecondary-base) !important;
  border-top: none;
  border-radius: 1vh;
}
.v-list-item {
  margin: 4px !important;
}

.theme--dark.v-btn:hover::before,
.theme--light.v-btn:hover::before {
  opacity: 0 !important;
}
.activeGameMenuItem,
.activeGameMenuItem:hover,
.activeGameMenuItem:focus {
  color: var(--v-bodyTitle-base) !important;
  background-color: var(--v-navbar-base) !important;
  border-radius: 1.5vh 1.5vh 0 0 !important;
}
</style>
<style scoped>
::v-deep .v-btn--icon.v-size--x-large .v-icon {
  font-size: 5vh;
}
@media only screen and (max-width: 650px) {
  ::v-deep .v-toolbar__content {
    padding: 0 5px;
  }
}
.menuContainer {
  background-color: var(--v-navbar-base) !important;
  border-radius: 0px !important;
  min-width: 250px !important;
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default,
.v-toolbar__extension .v-btn.v-btn--icon.v-size--default {
  height: inherit;
  width: inherit;
}
</style>
