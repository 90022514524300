<template>
  <svg
    id="ss"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    style="enable-background:new 0 0 48 48;"
    xml:space="preserve"
  >
    <g id="specialty">
      <path
        class="st0"
        d="M34.7,14.3c6.4,1.6,10.7,4.7,10.7,8.3c0,5.3-9.6,9.5-21.4,9.5S2.6,27.8,2.6,22.5c0-3.5,4.3-6.6,10.8-8.3"
      />
      <path
        class="st1"
        d="M42.1,32.2c-3.8,2.7-10.5,4.4-18.1,4.4S9.7,34.9,5.9,32.2"
      />
      <path
        class="st0"
        d="M45.4,22.5V32c0,5.3-9.6,9.5-21.4,9.5S2.6,37.3,2.6,32.1V21.7"
      />
      <line class="st0" x1="25.6" y1="13.5" x2="25.6" y2="24.1" />
      <line class="st0" x1="22.4" y1="24.1" x2="22.4" y2="15.7" />
      <rect x="21.6" y="9.9" class="st0" width="4.9" height="3.5" />
      <polyline class="st0" points="21.1,27.6 21.1,24.3 26.9,24.3 26.9,27.6 	" />
      <line class="st0" x1="16.1" y1="11.6" x2="21.6" y2="11.6" />
      <line class="st1" x1="16" y1="10.2" x2="16" y2="13" />
      <line class="st0" x1="31.9" y1="11.6" x2="26.4" y2="11.6" />
      <line class="st1" x1="32" y1="13" x2="32" y2="10.2" />
      <line class="st0" x1="24" y1="6.4" x2="24" y2="9.7" />
      <line class="st1" x1="25.4" y1="6.4" x2="22.6" y2="6.4" />
      <path class="st0" d="M9.9,24.7c0-3.2,5.4-5.9,12.4-6.3" />
      <path class="st0" d="M38.1,24.7c0-3.2-5.4-5.9-12.4-6.3" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Specialty",
  props: {
    iconColor: { type: String, default: "currentColor" },
    displayName: { type: String, default: "Specialty" }
  }
};
</script>

<style scoped type="text/css">
.st0 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linejoin: round;
}
.st1 {
  fill: none;
  stroke: currentColor;
  stroke-width: 2.16;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
