<template>
  <v-container class="ma-0 pa-0">
    <ConfirmSwitchToReal v-if="showTournamentsDialog && loggedIn" />
    <v-dialog
      v-if="playForMoney"
      v-model="showTournamentsDialog"
      content-class="tournament"
      :retain-focus="false"
      :persistent="isTournamentsOnly"
      :fullscreen="isTournamentsOnly"
      :transition="isTournamentsOnly ? '' : 'dialog-transition'"
    >
      <v-card id="tournaments-lobby" color="tournamentBackground">
        <TournamentsLobbyHeader @close="goHome"></TournamentsLobbyHeader>
        <SwitchToBaseCurrency class="d-flex justify-end ma-2 mr-4 pa-2" />
        <v-card-text>
          <v-row>
            <v-col :cols="selectedTournament ? 7 : 12">
              <v-row align="center" dense>
                <v-col cols="8">
                  <v-tabs
                    id="statusTabs"
                    v-model="tab"
                    center-active
                    color="tournamentFilter"
                    background-color="tournamentBackground"
                    :show-arrows="selectedTournament ? true : false"
                  >
                    <v-tab
                      v-for="filter in filterTabs"
                      :key="filter.name"
                      @click="apply(filter)"
                      >{{ $str(filter.name) }}</v-tab
                    >
                  </v-tabs></v-col
                >
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    :placeholder="$str('SearchTournaments')"
                    dense
                    filled
                    class="tournament-search"
                    hide-details="auto"
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field
                ></v-col>
                <v-col
                  cols="1"
                  class="pt-0 pb-0 d-flex justify-center align-center"
                >
                  <v-btn icon @click.stop="openSortandFilters()">
                    <v-icon color="tournamentFilterIcon" large
                      >mdi-filter</v-icon
                    >
                  </v-btn>
                </v-col></v-row
              >
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="filter in filterTabs" :key="filter.name">
                  <v-container
                    v-if="showNoAvailableTournamentsMessage"
                    class="tournamentBackground tournamentTxt--text mx-0"
                  >
                    <span>{{ $str("TournamentsNoAvailableFilter") }} </span>
                  </v-container>
                  <v-container
                    v-else-if="showNoEnrolledTournamentsMessage"
                    class="tournamentBackground tournamentTxt--text mx-0"
                  >
                    <span class="center">{{ $str("NoEnrollmentsText") }}.</span>
                  </v-container>
                  <v-layout
                    v-if="loading"
                    align-center
                    justify-center
                    fill-height
                  >
                    <v-progress-circular
                      color="grey"
                      indeterminate
                    ></v-progress-circular>
                  </v-layout>
                  <v-data-table
                    v-else
                    height="65vh"
                    hide-default-footer
                    disable-pagination
                    item-key="TournamentId"
                    :headers="headers"
                    :items="tournamentsFiltered"
                    :search="search"
                    :hide-default-header="hideHeaders"
                  >
                    <template v-slot:body="{ items }">
                      <tbody v-if="showTable(items)">
                        <tr
                          v-for="(item, key) in items"
                          :key="item.TournamentId"
                          :class="key === selectedRow ? 'selected' : ''"
                          @click="
                            () => {
                              selectedRow = key;
                              selectedTournament = item;
                            }
                          "
                        >
                          <td>{{ item.startDate }}</td>
                          <td>
                            <v-tooltip top color="secondary">
                              <template v-slot:activator="{ on }">
                                <span
                                  style="width: fit-content;"
                                  class="tournament-name"
                                  v-on="on"
                                  >{{
                                    breakTournamentTitleWhenIsTooLong(item.name)
                                  }}</span
                                >
                              </template>
                              <span>{{ item.name }}</span>
                            </v-tooltip>
                          </td>
                          <td>
                            {{ item.payoutType }}
                          </td>
                          <td>
                            {{ item.mode }}
                          </td>
                          <td :class="`${getColor(item.status)}--text`">
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : item.status
                            }}
                          </td>
                          <td>
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : $tournamentCur(item.buyIn)
                            }}
                          </td>
                          <td>
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : $tournamentCur(item.addOn)
                            }}
                          </td>
                          <td>
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : $tournamentCur(item.reBuy)
                            }}
                          </td>
                          <td>
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : item.enrolledPlayers
                            }}
                          </td>
                          <td>
                            {{
                              item.restricted
                                ? $str("StatusRestricted")
                                : $tournamentCur(item.prize)
                            }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else-if="!items.length && search">
                        <tr class="tournamentBackground tournamentTxt--text">
                          <td>
                            {{ $str("TournamentsNoAvailableSearch") }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items></v-col
            ><v-col v-if="tournament" cols="5">
              <TournamentsLobbyDetails
                :tournament="tournament"
              ></TournamentsLobbyDetails> </v-col></v-row
        ></v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="connected && playerAlias === '' && playForMoney"
      v-model="createAliasDialog"
      max-width="500"
      persistent
      content-class="tournament"
      :retain-focus="false"
    >
      <CreateAlias></CreateAlias>
    </v-dialog>
  </v-container>
</template>

<script>
import TournamentsLobbyHeader from "@/components/TournamentsLobby/TournamentsLobbyHeader.vue";
import TournamentsLobbyDetails from "@/components/TournamentsLobby/TournamentsLobbyDetails.vue";
import CreateAlias from "@/components/TournamentsLobby/CreateAlias.vue";
import ConfirmSwitchToReal from "@/components/TournamentsLobby/ConfirmSwitchToReal";

import { TournamentStatus } from "@/services/casino";
import { dialogToggle } from "@/mixins/dialogToggle";
import { mapGetters, mapActions } from "vuex";
import SwitchToBaseCurrency from "@/components/TournamentsLobby/SwitchToBaseCurrency";

export default {
  components: {
    TournamentsLobbyHeader,
    CreateAlias,
    TournamentsLobbyDetails,
    SwitchToBaseCurrency,
    ConfirmSwitchToReal
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "tournamentsLobby",
      search: "",
      tab: null,
      selectedRow: null,
      selectedTournament: null,
      clockInterval: null,
      refreshInterval: null,
      allTournamentsTab: 0,
      hideHeaders: false,
      createAliasDialog: false,
      enrolledTournamentsTab: 1,
      filterTabs: [
        { name: "All", status: [] },
        { name: "TournamentEnrolled", status: [], enrolled: true },
        { name: "TournamentInProgress", status: [4] },
        { name: "TournamentUpcoming", status: [2, 3] },
        { name: "StatusCompleted", status: [5, 6, 7] }
      ],
      filters: {
        statusSelected: []
      }
    };
  },
  computed: {
    ...mapGetters("tournaments", [
      "playerAlias",
      "tournaments",
      "tournamentsFiltered",
      "showTournamentId",
      "tournamentsLoaded",
      "isTournamentsOnly",
      "connected"
    ]),
    ...mapGetters("customizations", [
      "tournamentRefreshRate",
      "isTournamentsOnly"
    ]),
    ...mapGetters("session", ["playForMoney", "loggedIn"]),
    headers() {
      return [
        {
          text: this.$str("DetailViewStarts"),
          value: "startDate",
          filterable: false,
          width: "10vw"
        },
        { text: this.$str("Tournaments"), value: "name", width: "18vw" },
        {
          text: this.$strResourceService("Payout"),
          value: "payoutType"
        },
        {
          text: this.$str("Mode"),
          value: "mode"
        },
        {
          text: this.$str("DetailViewStatus"),
          value: "status",
          filterable: false
        },
        { text: this.$str("buyIn"), value: "buyIn", filterable: false },
        {
          text: this.$str("TournamentAddOn"),
          value: "addOn",
          filterable: false
        },
        {
          text: this.$str("TournamentReBuy"),
          value: "reBuy",
          filterable: false
        },
        {
          text: this.$str("TournamentEnrolled"),
          value: "enrolledPlayers",
          filterable: false
        },
        {
          text: this.$str("DetailViewPrizePool"),
          value: "prize",
          filterable: false,
          width: "15vw"
        }
      ];
    },
    showNoAvailableTournamentsMessage() {
      return (
        (this.tournamentsLoaded &&
          this.tab === this.allTournamentsTab &&
          !this.tournaments.length) ||
        (this.tournamentsLoaded &&
          this.tab > this.enrolledTournamentsTab &&
          !this.tournamentsFiltered.length)
      );
    },
    showNoEnrolledTournamentsMessage() {
      return (
        this.tournamentsLoaded &&
        this.tab === this.enrolledTournamentsTab &&
        !this.tournamentsFiltered.length
      );
    },
    tournament() {
      return this.selectedTournament
        ? this.tournamentsFiltered.find(
            tournament =>
              tournament.TournamentId === this.selectedTournament.TournamentId
          )
        : null;
    },
    showTournamentsDialog: {
      get() {
        return (!this.smallLayout && this.isTournamentsOnly) || this.showDialog;
      },
      set(value) {
        this.showDialog = value;
      }
    },
    loading() {
      return !this.tournamentsLoaded;
    }
  },
  watch: {
    async playerAlias() {
      if (this.playerAlias) {
        this.createAliasDialog = false;
        await this.requestTournaments();
      }
    },
    tab() {
      this.clearSelectedTournament();
    },
    /**
     * This value is set when the showTournament action is triggered, indicating which
     * tournament should be shown.
     */
    showTournamentId(tournamentId) {
      if (tournamentId !== null) {
        // If tournaments are loaded, go ahead and show it. Otherwise, we will check this
        // value once tournaments are loaded, in the tournamentsLoaded watcher below
        if (this.tournamentsLoaded) {
          this.showTournament(tournamentId);
        }
      }
    },
    tournamentsLoaded(loaded) {
      if (loaded) {
        // If showTournamentId has a value, we need to show that tournament
        if (this.showTournamentId !== null) {
          // Wait until next frame, so the tournament panels can be created
          window.setTimeout(() => this.showTournament(this.showTournamentId));
        }
      }
    }
  },
  methods: {
    ...mapActions("tournaments", [
      "requestTournaments",
      "updateTime",
      "sortAndFilterTournaments",
      "requestCurrentServerTimeAndTimeZone",
      "showTournamentDone",
      "requestTournamentCouponPrizes",
      "breakTournamentTitleWhenIsTooLong"
    ]),
    ...mapActions("dialogs", ["showSortAndFilters"]),
    initializeServerTime() {
      this.clockInterval = setInterval(() => {
        this.updateTime();
      }, 1000);
    },
    onShown() {
      if (!this.playerAlias) {
        setTimeout(() => {
          this.createAliasDialog = true;
        }, 50);
      }
      this.refreshTournaments();
      this.initializeServerTime();
    },
    onHidden() {
      this.createAliasDialog = false;
      clearInterval(this.clockInterval);
      clearInterval(this.refreshInterval);
      this.clearSelectedTournament();
      this.clearFilterTabs();
    },
    showTable(items) {
      if (items.length) {
        this.hideHeaders = false;
        return true;
      }
      this.hideHeaders = true;
      return false;
    },
    refreshTournaments() {
      this.refreshInterval = setInterval(() => {
        this.requestTournaments();
        this.requestCurrentServerTimeAndTimeZone();
        this.requestTournamentCouponPrizes();
      }, this.tournamentRefreshRate);
    },
    apply(filter) {
      this.filters.isEnrolledSelected = filter.enrolled;
      this.filters.statusSelected = filter.status;
      this.sortAndFilterTournaments({
        filters: { ...this.filters },
        sortFilters: []
      });
    },
    getColor(status) {
      //TODO : hardcoded until we get the themes properties
      const statusId = TournamentStatus[status];
      switch (statusId) {
        case 2:
          return "red";
        case 3:
          return "blue";
        case 4:
          return "green";
        default:
          return "black";
      }
    },
    openSortandFilters() {
      const statusDisabled = !!this.filters.statusSelected.length;
      this.showSortAndFilters({
        statusDisabled
      });
    },
    clearSelectedTournament() {
      //clear selected tournament
      this.selectedTournament = null;
      this.selectedRow = null;
    },
    clearFilterTabs() {
      this.tab = null;
      this.apply(this.filterTabs[0]);
    },
    /* Select and show a tournament by tournamentId. If the tournament can't
     * be found, or is closed, etc., just display the "available" list
     */
    showTournament(tournamentId) {
      if (tournamentId === null) {
        this.showTournamentDone();
        return;
      }

      const index = this.tournamentsFiltered.findIndex(
        t => t.TournamentId === tournamentId
      );

      if (index === -1) {
        window.console.warn(
          `ShowTournament: Could not find tournament with ID: ${tournamentId}`
        );
        this.showTournamentDone();
        return;
      }

      const tournament = this.tournamentsFiltered[index];
      if (
        tournament.StatusId < TournamentStatus.Registering ||
        tournament.StatusId > TournamentStatus.Running
      ) {
        // Tournament is not registering yet or already complete, don't try to show it, just show available tournaments
        this.showTournamentDone();
        return;
      }

      // Selects the actual selected tournament in the table
      this.selectedTournament = tournament;
      this.selectedRow = tournament.TournamentId;

      // Notify the store that we've shown the tournament so the value can be cleared
      this.showTournamentDone();
    },
    breakTournamentTitleWhenIsTooLong(tournamentName) {
      const maxLengthOnOneLine = 50;
      if (tournamentName.length > maxLengthOnOneLine) {
        const tempTournamentName = tournamentName.split(" ");
        let shortTournamentName = "";
        for (
          let arrayIndex = 0;
          arrayIndex < tempTournamentName.length;
          arrayIndex++
        ) {
          if (
            (shortTournamentName + " " + tempTournamentName[arrayIndex])
              .length < maxLengthOnOneLine
          ) {
            shortTournamentName =
              shortTournamentName + " " + tempTournamentName[arrayIndex];
          } else {
            arrayIndex = tempTournamentName.length + 1;
          }
        }
        if (shortTournamentName !== "") {
          tournamentName = shortTournamentName + "...";
        }
      }

      return tournamentName;
    }
  }
};
</script>

<style scoped>
.containerSwitch {
  display: flex;
  justify-content: space-around;
}

.containerSwitch > div {
  width: 17%;
  margin-left: 14%;
}

#tournaments-lobby {
  height: 89vh;
}

.selected {
  font-weight: 800;
}

.selected td {
  font-size: 0.9rem !important;
}

.tournament-search {
  background-color: var(--v-tournamentSearch-base);
}

::v-deep .theme--dark.v-input input,
::v-deep .theme--light.v-input input,
::v-deep .tournament-search input::placeholder,
::v-deep .tournament-search i::before {
  color: var(--v-tournamentSearchTxt-base) !important;
  caret-color: var(--v-tournamentSearchTxt-base) !important;
}

.theme--dark.v-data-table {
  background: var(--v-tournamentBackground-base);
}

::v-deep
  .theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: var(--v-tournamentHeader-base);
  color: var(--v-tournamentHeaderTxt-base);
}

::v-deep tbody tr {
  background: var(--v-tournamentListBackground-base);
  color: var(--v-tournamentDetailTxt-base);
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: var(--v-tournamentListBackground-lighten2);
}

::v-deep #statusTabs .v-slide-group__wrapper {
  border-bottom: 1px solid;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: var(--v-tournamentFilterTxt-base);
}

.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0.23rem solid var(--v-tournamentListBackground-darken2);
}

::v-deep
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none;
}

.theme--dark.v-tabs-items,
.theme--light.v-tabs-items {
  background: transparent;
}

.tournament-name {
  display: block;
  width: 25em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
