<template>
  <v-navigation-drawer
    v-model="isVisible"
    temporary
    right
    app
    color="popupBackground"
    style="text-align: right"
    overlay-opacity="0.9"
    width="260"
    height="100%"
  >
    <v-layout align-center justify-end>
      <v-btn small icon class="mt-6 mx-4" @click.stop="hide">
        <v-icon small class="mr-n1">$Close</v-icon>
      </v-btn>
    </v-layout>
    <v-list class="sandwichMenu-option" @click.native="hide">
      <v-list-item
        v-for="item in sandwichMenu"
        v-show="
          ((loggedIn &&
            item.AfterLogin &&
            (item.SessionGame === 1 ||
              (item.SessionGame === 2 && playForMoney) ||
              (item.SessionGame === 3 && !playForMoney)) &&
            showAditionalValidationAfterLogin(item.Name)) ||
            (!loggedIn &&
              item.PriorToLogin &&
              showAditionalValidationBeforeLogin(item.Name))) &&
            (item.ClientType === 10 || item.ClientType === getClientType())
        "
        :id="`sandwichMenu${item.Name.split(' ').join('')}Btn`"
        :key="item.ID"
        class="py-0 justify-end"
        @click="item.Type === 6 ? showOption(item) : showLink(item)"
      >
        <span class="mr-2">{{ item.DisplayName }}</span>
        <v-icon small>{{ getIcon(item) }}</v-icon>
      </v-list-item>
      <v-list-item
        v-if="enabledThemesVisible"
        class="py-0 justify-end"
        @click="toggleDarkMode"
      >
        <span class="mr-3">{{ $str("DarkMode") }}</span>
        <v-switch
          v-model="this.$vuetify.theme.dark"
          color="grey"
          readonly
          dense
          inset
          class="ma-0 mr-n4 pa-0 switch"
        ></v-switch>
      </v-list-item>
      <v-list-item
        v-if="loggedIn"
        class="py-0 justify-end"
        @click="requestSignOut"
      >
        <span class="mr-2">{{ $str("LogoutPage") }}</span>
        <v-icon small>$Logout</v-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { customScriptHandlers, clientType } from "@/services/casino";
export default {
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    ...mapGetters("session", ["loggedIn", "liveChatInfo", "playForMoney"]),
    ...mapGetters("accountFields", ["signupEnabled"]),
    ...mapGetters("casinoJackpot", ["casinoJackpot"]),
    ...mapGetters("lobbyComponents", [
      "componentIsHidden",
      "componentValue",
      "componentUrl",
      "legalNoticesUrl",
      "privacyPolicyURL",
      "termsConditionsURL"
    ]),
    ...mapGetters("cashier", ["isCashierEnabled", "isMobileCashierEnabled"]),
    ...mapGetters("customizations", ["enabledThemes"]),
    ...mapGetters("lobbyMenus", ["sandwichMenu", "isGamesGuruEnabled"]),
    ...mapGetters("referFriend", ["programs"]),
    signUpVisible() {
      return !this.loggedIn && this.signupEnabled;
    },
    contactUsVisible() {
      return !this.componentIsHidden("*Menu-Contact Us");
    },
    contactUsDisplayName() {
      return this.componentValue("*Menu-Contact Us");
    },
    enabledThemesVisible() {
      return this.enabledThemes?.length > 1;
    },
    legalNoticesVisible() {
      return this.legalNoticesUrl;
    },
    promotionsVisible() {
      return (
        !this.componentIsHidden("*Menu/Lobby-Promotions") &&
        this.componentUrl("*Menu/Lobby-Promotions")
      );
    },
    isWithdrawVisible() {
      let isVisible = this.loggedIn && this.playForMoney;
      isVisible &= this.smallLayout
        ? this.isMobileCashierEnabled
        : this.isCashierEnabled;

      return isVisible;
    },
    privacyPolicyVisible() {
      return this.privacyPolicyURL;
    }
  },
  methods: {
    ...mapActions("dialogs", [
      "showContactUs",
      "showReferAFriend",
      "showExternalUrl",
      "showLiveChat",
      "showAccountHistory",
      "showSearch",
      "showCustomUrl",
      "showPrivacyPolicy",
      "showTermsConditions",
      "showLegalNotices",
      "showTournamentsLobby",
      "showCasinoJackpot",
      "showMyProfile",
      "showGamesGuru"
    ]),
    ...mapActions("session", ["requestSignOut"]),
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.allSparkTheme = this.$vuetify.theme.dark;
    },
    show() {
      this.isVisible = true;
    },
    hide() {
      this.isVisible = false;
    },
    showOption(item) {
      const hasCustomScriptHandler = name => {
        return (
          customScriptHandlers.customSandwichMenuOptionHandler &&
          customScriptHandlers.customSandwichMenuOptionHandler.find(
            handler => handler.key === name
          )
        );
      };
      if (hasCustomScriptHandler(item.Name)) {
        const customHandler = customScriptHandlers.customSandwichMenuOptionHandler.find(
          handler => handler.key === item.Name
        );
        return customHandler.handler();
      }
      
      if (item.URL) {
        this.showLink(item);
      } else {
        this["option" + item.Name](item.DisplayName);
      }
    },
    optionDeposit(displayName) {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage, {
        displayName: displayName
      });
    },
    optionWithdraw(displayName) {
      window.LobbyCore.raise(window.LobbyCore.Events.showWithdrawPage, {
        displayName: displayName
      });
    },
    optionSearch(displayName) {
      this.showSearch(displayName);
    },
    optionLiveChat(displayName) {
      this.showLiveChat({displayName: displayName});
    },
    optionLogin(displayName) {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignInPage, {
        displayName: displayName
      });
    },
    optionSignUp(displayName) {
      window.LobbyCore.raise(window.LobbyCore.Events.showSignUpPage, {
        displayName: displayName
      });
    },
    async optionCoupons(displayName) {
      this.$store.dispatch("coupons/updateActiveCouponMessage");
      window.LobbyCore.raise(window.LobbyCore.Events.showCouponsPage, {
        displayName: displayName
      });
    },
    optionPromotions(displayName) {
      window.LobbyCore.raise(window.LobbyCore.Events.showPromotionsPage, {
        displayName: displayName
      });
    },
    optionContactUs(displayName) {
      this.showContactUs(displayName);
    },
    optionReferAFriend(displayName) {
      const flag = this.$store.getters[
        "cashier/forceSmartPhoneCashierInTablet"
      ];
      if (
        clientType === CDK.ClientTypes.InstantPlay ||
        (this.tabletMode && !flag)
      ) {
        window.LobbyCore.raise(
          window.LobbyCore.Events.showReferAFriendInCashierPage
        );
      } else {
        this.showReferAFriend(displayName);
      }
    },
    optionGamesGuru() {
      this.showGamesGuru({
        gameId: null,
        balanceChanged: false,
        adminOption: true
      });
    },
    optionTermsAndConditions(displayName) {
      this.showTermsConditions(displayName);
    },
    optionLegalNotices(displayName) {
      this.showLegalNotices(displayName);
    },
    optionPrivacyPolicy(displayName) {
      this.showPrivacyPolicy(displayName);
    },
    optionAccountHistory(displayName) {
      this.showAccountHistory(displayName);
    },
    optionTournaments(displayName) {
      if (this.getClientType() === CDK.ClientTypes.Mobile)
        this.$router.push("/tournaments");
      else this.showTournamentsLobby(displayName);
    },
    optionLobbyJackpot(displayName) {
      this.showCasinoJackpot(displayName);
    },
    optionMyProfile(displayName) {
      this.showMyProfile(displayName);
    },
    showLink(option) {
      this.showCustomUrl({
        title: option.DisplayName,
        source: option.URL,
        newTab: option.NewTab,
        isSandwichMenuRequest: true
      });
    },
    showAditionalValidationAfterLogin(name) {
      switch (name) {
        case "ReferAFriend":
          if (this.programs !== undefined && this.programs.length > 0)
            return true;
          else return false;
        case "LobbyJackpot":
          return this.casinoJackpot.Status;
        case "GamesGuru":
          return this.isGamesGuruEnabled;
        default:
          return true;
      }
    },
    showAditionalValidationBeforeLogin(name) {
      switch (name) {
        case "LobbyJackpot":
          return this.casinoJackpot.Status;
        default:
          return true;
      }
    },
    getIcon(menuItem) {
      if (!menuItem.CustomIcon) return "mdi-" + menuItem.Icon;
      return "$" + menuItem.Name;
    },
    getClientType() {
      return clientType;
    }
  }
};
</script>
<style lang="scss" scoped>
.theme--dark.v-list-item:hover::before,
.theme--light.v-list-item:hover::before {
  opacity: 0 !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):active,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):active,
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):before,
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):before {
  background-color: var(--v-buttonSecondary-base) !important;
  color: var(--v-buttonSecondaryTxt-base) !important;
  border-top: none;
  border-radius: 1vh;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  background-color: var(--v-popopBackground-base) !important;
  color: var(--v-bodyTitle-base) !important;
}
.v-list-item span {
  padding-top: 5px;
}
.switch {
  height: 20px;
}
.v-list-item {
  margin-left: 4px;
  margin-right: 4px;
}

.theme--light.v-icon,
.theme--dark.v-icon {
  color: inherit;
}

.sandwichMenu-option {
  span {
    font-size: 14px !important;
  }
}

// Remove the thin white line on the edge of the drawer
::v-deep .v-navigation-drawer__border {
  display: none;
}
</style>
