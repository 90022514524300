import "./publicPath";
import Vue from "vue";
import "@fontsource-variable/figtree";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import localization from "@/mixins/localization";
import theme from "@/mixins/theme";
import responsiveLayout from "@/mixins/responsiveLayout";
import home from "@/mixins/home";
import VueScrollTo from "vue-scrollto";
import VueMarqueeSlider from "vue-marquee-slider";
import VueHorizontal from "vue-horizontal";

import { connectToCasino } from "@/services/casino";

// Usage: this.$scrollTo(...) (see: CouponTile.vue)
Vue.use(VueScrollTo);

Vue.use(VueMarqueeSlider);

Vue.use(VueHorizontal);

// Start CDK connection
connectToCasino();

// Global config
Vue.config.productionTip = false;

// Global mixins
Vue.mixin(localization);
Vue.mixin(theme);
Vue.mixin(responsiveLayout);
Vue.mixin(home);

// Main Vue object
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
