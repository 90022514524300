<template>
  <v-dialog
    v-if="showMessage"
    v-model="showDialog"
    :fullscreen="smallLayout"
    :content-class="largeLayout ? 'biggerModal' : 'rounded-0'"
  >
    <v-card
      v-if="showDialog"
      class="elevation-12"
      :style="
        `height: calc(100% + 64px); ${smallLayout ? 'overflow: hidden;' : ''}`
      "
    >
      <v-toolbar class="msgNavBar px-0" color="popupBackground" flat>
        <v-btn icon
          ><v-icon id="msgCenterListCloseBtn" @click.stop="showDialog = false"
            >mdi-chevron-left</v-icon
          ></v-btn
        >
        <v-toolbar-title v-if="title" class="pl-2 font-weight-bold">{{
          title
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text
        :class="largeLayout ? 'pa-0 ma-0' : ''"
        style="height: 100%;background-color: var(--v-popupBackground-base);"
        :style="smallLayout ? 'padding: 0' : ''"
      >
        <MessageDisplay
          :id="id"
          :is-specific-id="true"
          :height="smallLayout ? '100%' : '290px'"
          :width="smallLayout ? '100%' : '767px'"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { dialogToggle } from "@/mixins/dialogToggle";
import MessageDisplay from "@/components/MessageDisplay";

export default {
  components: {
    MessageDisplay
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "messageCenterMessage",
      loading: true,
      cancelSignOut: false,
      showMessage: true
    };
  },
  computed: {
    id() {
      return this.$store.getters["dialogs/messageCenterMessage"].data.id;
    },
    title() {
      return this.$store.getters["dialogs/messageCenterMessage"].data.title;
    },
    isForceOnExit() {
      return this.$store.getters["dialogs/messageCenterMessage"].data
        .isForceOnExit;
    },
    messageCss() {
      // Message frame style copied from MessageLESS.less in SparkLobby2
      return `body {
          background: #fff;
          font-family: Arial, sans-serif;
          font-size: 14px;
          color: #646464;
          word-break: break-word;
      }`;
    },
    messageHtml() {
      return this.$store.getters["messageCenter/playerMessageText"][this.id];
    }
  },

  methods: {
    onHidden() {
      this.showMessage = false;
      this.loading = true;
      if (this.isForceOnExit && !this.cancelSignOut) {
        this.$store.dispatch("session/signOut");
      }
    },
    onShown() {
      this.showMessage = true;
      this.cancelSignOut = false;
    }
  }
};
</script>

<style scoped>
::v-deep .v-dialog {
  border: none;
}
::v-deep .v-dialog.v-dialog--active.biggerModal {
  overflow-y: hidden;
  border: none !important;
  width: fit-content;
  height: fit-content !important;
}
.biggerModal .mdi-close {
  color: #666 !important;
}
</style>
