<template>
  <v-dialog v-model="showDialog" width="500" overlay-opacity="0.9">
    <v-card v-if="showDialog" class="elevation-12 pb-10">
      <DialogTitleBar
        :title="balanceTitle"
        color="transparent"
        @close="showDialog = false"
      />
      <v-container class="px-4 py-1">
        <v-row justify="start" class="mx-0">
          <div class="d-flex flex-column balance--text">
            <span class="text-h5">{{ $cur(balance) }}</span>
          </div>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" class="py-2">
            <v-btn
              v-if="playForMoney && isMobileCashierEnabled"
              color="button buttonTxt--text"
              depressed
              height="40px"
              width="100%"
              @click="openDeposit"
              >{{ $str("DepositPage") }}</v-btn
            >
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <v-card-text class="pa-0" style="font-size: 12px;">
          <v-row v-if="playForMoney" class="text-left" no-gutters>
            <v-col cols="5"
              ><h4>{{ $str("Withdrawable") }}:</h4></v-col
            >
            <v-col cols="4">{{ $cur(withdrawable) }}</v-col>
          </v-row>
          <v-row
            v-if="playForMoney && activeCoupon && !isTournamentsView"
            class="text-left"
            no-gutters
          >
            <v-col cols="5"
              ><h4>{{ $str("Bonus") }}:</h4></v-col
            >
            <v-col cols="4">{{ $cur(bonus) }}</v-col>
          </v-row>
          <v-row
            v-if="playForMoney && activeCoupon && !isTournamentsView"
            class="text-left"
            no-gutters
          >
            <v-col cols="5"
              ><h4>{{ $str("Playthrough") }}:</h4></v-col
            >
            <v-col cols="4">{{ $cur(playthrough) }}</v-col>
          </v-row>
          <v-divider
            v-if="compPointSettings.ShowCompPoints && playForMoney"
          ></v-divider>
          <v-row
            v-if="compPointSettings.ShowCompPoints && playForMoney"
            class="text-left"
            align="center"
            no-gutters
          >
            <span>{{ $str("CompPoints") }}:</span>
            <span class="ml-2">{{ $num(compPoints, 0) }}</span>
          </v-row>
          <v-row v-if="compPointSettings.ShowCompPoints && playForMoney">
            <v-col cols="12" class="py-2">
              <v-btn
                color="button buttonTxt--text"
                depressed
                height="40px"
                width="100%"
                :disabled="!(compPoints > 0) || compPointsDisabled"
                @click="openCompPoints()"
                >{{ $str("Redeem") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row
            v-if="
              playForMoney &&
                checkIsCurrencyToDisplayActive(currenciesToDisplay)
            "
            class="text-right"
            no-gutters
          >
            <v-col cols="5"
              ><h4>{{ $str("FiatEquivalent") }}:</h4></v-col
            >
            <v-col cols="4">
              <div class="fiatsContainer">
                <v-select
                  class="fiatsSelectBox"
                  underlined
                  :items="formatDropdownCurrencies(currenciesToDisplay)"
                  :value="
                    defaultCurrencyToDisplay
                      ? defaultCurrencyToDisplay.Value
                      : null
                  "
                  @change="handleCurrencyToDisplay"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
    <ConfirmDialog ref="errorDialog" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import { dialogToggle } from "@/mixins/dialogToggle";

export default {
  components: {
    ConfirmDialog
  },
  mixins: [dialogToggle],
  data() {
    return {
      name: "balanceDetail"
    };
  },
  computed: {
    ...mapGetters("coupons", ["activeCoupon"]),
    ...mapGetters("balances", [
      "balance",
      "withdrawable",
      "bonus",
      "playthrough",
      "compPoints",
      "compPointsDisabled",
      "currenciesToDisplay",
      "defaultCurrencyToDisplay"
    ]),
    ...mapGetters("session", ["loggedIn", "playForMoney"]),
    ...mapGetters("cashier", ["compPointSettings", "isMobileCashierEnabled"]),
    isTournamentsView() {
      return this.$route.name === "tournaments";
    },
    balanceTitle() {
      return this.playForMoney ? this.$str("Balance") : this.$str("FunBalance");
    }
  },
  methods: {
    ...mapActions("dialogs", ["showCompPoints"]),
    ...mapActions("balances", ["updatePlayerCurrency"]),
    async openCompPoints() {
      const canRedeemCompPoints = await CDK.getCanRedeemCompPoints();

      if (canRedeemCompPoints) {
        this.showCompPoints();
      } else {
        const message = await CDK.getCannotRedeemCompPointsMessage();
        this.$refs.errorDialog.openAlert({
          title: this.$strResourceService("Alert"),
          message
        });
      }
    },
    openDeposit() {
      window.LobbyCore.raise(window.LobbyCore.Events.showDepositPage);
    },
    checkIsCurrencyToDisplayActive(currencies) {
      return currencies.length > 0;
    },
    formatDropdownCurrencies(currencies) {
      return currencies.map(currencyInfo => currencyInfo.Value);
    },
    handleCurrencyToDisplay(currencyToUpdate) {
      this.updatePlayerCurrency(currencyToUpdate);
    }
  }
};
</script>

<style scoped>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined),
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: var(--v-disabled-base) !important;
  color: var(--v-disabledTxt-base) !important;
}

.fiatsSelectBox {
  margin: 0;
  padding: 0;
  max-width: 90px;
  font-size: 14px;
}

.fiatsContainer {
  display: flex;
  justify-content: flex-end;
}

.fiatsSelectBox /deep/ .v-select__slot {
  max-height: 28px !important;
}
</style>
